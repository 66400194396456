import React from 'react';
import OrderEventAttribute from "./orderEventSection/OrderEventAttribute";
import {bindActionCreators} from "redux";
import * as actionMaster from "../../../actions/uiAction";
import * as caseOrderAction from "../../../actions/caseOrderAction";
import * as orderAction from "../../../actions/orderAction";
import * as caseAction from "../../../actions/caseAction";
import connect from "react-redux/lib/connect/connect";
import OrderEventCategories from "./orderEventSection/OrderEventCategories";
import OrderCategoryData from "./orderEventSection/OrderCategoryData";
import OnToggleComponent from "../caseOrderSubComponents/toggleShowAndHideContent";
import Utility, {updateObject} from "../../../api/utilLanguage";
import OrderFieldRules from "./OrderFieldRules";
import Button from "../../common/button";
import Icon from "../../common/icon";
import ValidationUtil from "../../common/validationUtil";
import OrderCommonElementComponent from "./OrderCommonElementComponent";
import FieldType from "../../common/fieldType";
import OrderUtil from "../../common/orderUtil";
import Tooltip from "../../common/tooltip";
import AppUtil from "../../common/appUtil";

/**
 * Represents one unique event for an order
 * Sections
 * 1: render event and event attributes
 *      @see OrderEventAttribute
 * 2: render event categories and category data
 *      @see OrderEventCategories
 *      @see OrderCategoryData
 */
class OrderEventComponent extends React.Component {

    constructor(props) {
        super(props);
        this.displayEvent = OrderFieldRules.orderToDisplayEvent(this.props.order.orderType, this.props.event.eventType);
        this.orderType = props.order.orderType;
        this.eventType = props.event.eventType;
        this.orderIndex = props.orderIndex;
        this.data = {
            orderType: this.orderType,
            eventType: this.eventType,
            orderIndex: this.orderIndex
        };
    }

    state = {
        disableToggle: null,
        visibility: {
            display: '',
            fileName: 'collapse'
        }
    };

    UNSAFE_componentWillMount() {
        if (this.displayEvent.eventType !== OrderUtil.eventTypes.anonymous.value && this.displayEvent.display === false && OrderFieldRules.eventHasData(this.props.event)) {
            this.displayEvent.display = true;
            this.displayEvent.loadProductCategories = true;
        }
        if (OrderFieldRules.loadAdditionalProductCategories(this.orderType, this.eventType)) {
            this.props.caseOrderAction.getRemainingProductCategoryForEventType(this.orderType, this.orderIndex, this.eventType, this.props.functionSource);
        }
    }

    onEventAttributeValueChange = (...selection) => {
        const [eventAttribute, selectedId, selectedName, selectedValue] = selection;
        let dataForChange = updateObject(this.data, {
            selectedValue: selectedValue,
            selectedText: selectedName,
            selectedId: selectedId,
            selectedEventAttribute: eventAttribute
        });
        this.props.caseOrderAction.updateEventAttributeData(dataForChange, this.props.functionSource);
    };

    addNewFieldInEventProductCategory = (e, categoryData) => {
        this.props.caseOrderAction.addNewFieldInEventProductCategory(categoryData, this.orderType, this.orderIndex, this.eventType, this.props.functionSource);
    };

    onProductSelect = (...selection) => {
        const [selectedProduct, categoryId, product, productIndex] = selection;
        this.props.caseOrderAction.onProductSelectInOrderEvent(selectedProduct, categoryId, product, productIndex, this.orderType, this.orderIndex, this.eventType, this.props.functionSource);
    };

    onDisbursementPaymentSelect = (...selection) => {
        const [selectedDisbursementPayment, categoryId, product, productIndex] = selection;
        this.props.caseOrderAction.changeDisbursementPaymentInOrderEvent(selectedDisbursementPayment, categoryId, product, productIndex, this.orderType, this.orderIndex, this.eventType, this.props.functionSource);
    };

    removeSingleProductLine = (...selection) => {
        const [categoryId, product, productIndex] = selection;
        this.props.caseOrderAction.removeSingleProductFromProductCategory(categoryId, product, productIndex, this.orderType, this.orderIndex, this.eventType, this.props.functionSource);
    };

    removeSingleChildProductLine = (...selection) => {
        const [categoryId, product, productIndex, childProduct, childProductIndex] = selection;
        this.props.caseOrderAction.removeSingleChildProductFromProductCategory(categoryId, product, productIndex, childProduct, childProductIndex, this.orderType, this.orderIndex, this.eventType, this.props.functionSource);
    };

    changeCustomProductName = (...selection) => {
        const [customProductName, categoryId, product, productIndex] = selection;
        this.props.caseOrderAction.changeCustomProductName(customProductName, categoryId, product, productIndex, this.orderType, this.orderIndex, this.eventType, this.props.functionSource);
    };

    onSelectedProductPriceAndQuantityChange = (...selection) => {
        const [quantity, price, categoryId, product, productIndex] = selection;
        if (this.validateNumber(quantity) || this.validateNumber(price)) {
            this.props.caseOrderAction.onSelectedProductQuantityAndPriceChange(quantity, price, categoryId, product, productIndex, this.orderType, this.orderIndex, this.eventType, this.props.functionSource);
        }
    };

    onSelectedChildProductPriceAndQuantityChange = (...selection) => {
        const [quantity, price, categoryId, product, productIndex, childProduct, childProductIndex] = selection;
        if (this.validateNumber(quantity) || this.validateNumber(price)) {
            this.props.caseOrderAction.onSelectedChildProductQuantityAndPriceChange(quantity, price, categoryId, product, productIndex, childProduct, childProductIndex, this.orderType, this.orderIndex, this.eventType, this.props.functionSource);
        }
    };

    validateNumber = (input) => {
        return input === "" || (parseInt(input, 10) > 0 && ValidationUtil.nonNegativeNumber.test(input));
    };

    toggleClicked = (e) => {
        const visibility = this.state.visibility;
        if (visibility.display === 'none') {
            this.setState({
                visibility: {
                    display: '',
                    fileName: 'collapse'
                }
            });
        }
        if (visibility.display === '') {
            this.setState({
                visibility: {
                    display: 'none',
                    fileName: 'expand'
                }
            })
        }
    };

    onConfirmStatusChange = (isConfirm, eventAttributeType = undefined) => {
        this.props.caseOrderAction.updateEventAttributeStatusData(isConfirm, this.props.order.orderType, this.props.orderIndex, this.props.event.eventType, eventAttributeType, this.props.functionSource)
    };

    onCategoryOrProductConfirmStatusChange = (isConfirm, categoryId, productId, productIndex) => {
        this.props.caseOrderAction.updateEventCategoryStatusData(isConfirm, this.props.order.orderType, this.props.orderIndex, this.props.event.eventType, categoryId, productId, productIndex, this.props.functionSource)
    };

    addEvent = (e) => {
        this.displayEvent.display = true;
        this.props.caseOrderAction.addReceptionEvent(OrderUtil.eventTypes.reception.value, this.props.order, 1, this.props.functionSource);
        setTimeout(function () {
            this.props.caseOrderAction.getRemainingProductCategoryForEventType(this.orderType, this.orderIndex, this.eventType, this.props.functionSource);
        }.bind(this), 0)
    };

    deleteEvent = () => {
        this.displayEvent.display = false;
        this.props.caseOrderAction.removeEvent(this.orderType, this.eventType, this.props.functionSource)
    };

    alternateButtonDisplay() {
        return <React.Fragment>
            <tr className="tr tr--sub tr--add is-active">
                <td className="td td--check">
                    <Icon type="add"/>
                </td>
                <td className="td td--main">
                    <div className="btn-toolbar btn-toolbar--footer">
                        <Button
                            key={this.props.event.eventType}
                            id={this.props.event.eventType}
                            onClick={this.addEvent}>
                            {this.props.event.eventTypeText}
                        </Button>
                    </div>
                </td>
            </tr>
        </React.Fragment>;
    }

    isDeletableEvent() {
        return <React.Fragment>
            <a
                className="link is-destructive"
                onClick={(e) => this.deleteEvent(e)}
                data-tip
            >
                <Icon type="trash"/>
                <Tooltip>
                    {Utility.getLang().common.delete}
                </Tooltip>
            </a>
        </React.Fragment>
    }

    render() {
        const {order, event} = this.props;
        const uniqueId = OrderUtil.uniqueId(order, event);
        return (
            <React.Fragment>
                {
                    this.displayEvent.display ?
                        <React.Fragment>
                            <tr className="tr tr--toggle is-active">
                                <td className="td td--check">
                                    <OrderCommonElementComponent
                                        checkBoxId={uniqueId}
                                        className={"option option--checkbox option--confirm"}
                                        checkedStatus={this.props.event.isConfirm}
                                        tooltip={this.props.event.isConfirm ? Utility.getLang().orders.confirmed : Utility.getLang().orders.notConfirmed}
                                        changeConfirmStatus={this.onConfirmStatusChange}
                                        fieldType={FieldType.NONE}
                                    />
                                </td>
                                <td className={`td td--main ${this.props.event.isConfirm === true ? 'disabledparent' : ''}`}>
                                    <h4 className="title">
                                        <span>{this.props.event.eventTypeText}</span>
                                        {OrderFieldRules.isDeletableEvent(this.orderType, this.eventType) ? this.isDeletableEvent() : null}
                                    </h4>
                                    <div className="temp_order_event_attributes"
                                         style={{display: this.state.visibility.display}}>
                                        {
                                            this.props.event.eventAttributes.map(eventAttribute => (
                                                <OrderEventAttribute key={eventAttribute.attributeType}
                                                                     eventAttribute={eventAttribute}
                                                                     onChange={this.onEventAttributeValueChange}
                                                                     event={this.props.event}
                                                                     orderType={this.props.order.orderType}
                                                                     order={this.props.order}
                                                                     onStatusChange={this.onConfirmStatusChange}
                                                                     case={AppUtil.isAvailable(this.props.order) && AppUtil.isAvailable(this.props.order.case) ? this.props.order.case : this.props.selectedCaseData}
                                                                     orders={this.props.selectedCaseData.orders}
                                                />
                                            ))
                                        }
                                    </div>
                                </td>
                                <td className="td td--detail td--price"></td>
                                <OnToggleComponent fileName={this.state.visibility.fileName}
                                                   disabledToggle={this.state.toggle}
                                                   showAndHide={this.toggleClicked}/>
                            </tr>
                        </React.Fragment>
                        : this.displayEvent.alternateDisplay ? this.alternateButtonDisplay() : null
                }
                <tr className="tr tr--sub_products is-active">
                    <td className="td td--sub_products" colSpan="4">
                        <table
                            className={`fnxtable fnxtable--order ${OrderUtil.lockProducts(this.props.order.stage) ? 'is-locked' : ''}`}>
                            <tbody>
                            {this.props.event.categories.map(category => (
                                <OrderEventCategories key={category.id}
                                                      category={category}
                                                      onChange={this.onEventAttributeValueChange}
                                                      eventType={this.props.event.eventType}
                                                      orderIndex={this.props.orderIndex}
                                                      order={this.props.order}
                                                      functionSource={this.props.functionSource}
                                                      addNewFieldInEventProductCategory={this.addNewFieldInEventProductCategory}
                                                      onProductSelect={this.onProductSelect}
                                                      changeProductVolume={this.onSelectedProductPriceAndQuantityChange}
                                                      changeCustomProductName={this.changeCustomProductName}
                                                      removeSingleProductLine={this.removeSingleProductLine}
                                                      removeSingleChildProductLine={this.removeSingleChildProductLine}
                                                      changeChildProductVolume={this.onSelectedChildProductPriceAndQuantityChange}
                                                      onConfirmStatusChange={this.onCategoryOrProductConfirmStatusChange}
                                                      changeDisbursmentPaymentOption={this.onDisbursementPaymentSelect}
                                />
                            ))}
                            {this.props.event.categoryData !== undefined && this.props.event.categoryData.length > 0 ?
                                <OrderCategoryData categoryData={this.props.event.categoryData}
                                                   eventType={this.props.event.eventType}
                                                   orderIndex={this.props.orderIndex}
                                                   order={this.props.order}
                                                   functionSource={this.props.functionSource}
                                                   onChange={this.onEventAttributeValueChange}
                                /> : null}
                            </tbody>
                        </table>
                    </td>
                </tr>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        selectedCaseData: state.application.selectedCaseData,
        selectedDataById: state.application.selectedDataById,
        orderStatus: state.application.orderStatus
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        caseOrderAction: bindActionCreators(caseOrderAction, dispatch),
        orderAction: bindActionCreators(orderAction, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderEventComponent);
