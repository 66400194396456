import {useCallback, useRef, useState} from "react";
import AppUtil from "../../../../../common/appUtil";
import {ProbateProps} from "../../tabProbateMeeting/util";
import {getExistingProbateCases} from "../../../api";
import {updateCaseProbateProperty} from "../../../../../../actions/caseAction";

const useConnectExistingProbateCase = (dispatch) => {
    const [isLoadingExistingProbateCases, setIsLoadingExistingProbateCases] = useState(false);
    let timer = useRef();

    const updatePropertyValue = (updatedProperty, forList) => {
        const updatedList = forList;
        if (updatedList.length > 0) {
            let customName = "";
            for (let n = 0; n < updatedList.length; n++) {
                const item = updatedList[n];
                const deceasedName = item.deceasedName;
                const personNumber = item.personNumber;

                customName = item.id;
                if (deceasedName !== null) {
                    customName += " " + deceasedName
                }
                if (personNumber !== null) {
                    customName += " - " + personNumber
                }
                item[updatedProperty] = customName;
            }
        }
        return updatedList;
    }

    const DELAY_DURATION_IN_MILLISECONDS = 500;
    /***
     * @description: debounce: setTimeout((), 500ms), to avoid multiple API requests being sent while the user is typing
     * clearTimeout: While typing continuously, the previous request is cancelled
     */
    const getExistingProbateCasesWithDebounce = (inputValue, callback) => {
        if (inputValue) {//Skip initial load
            clearTimeout(timer.current);

            timer.current = setTimeout(() => {// debounce
                setIsLoadingExistingProbateCases(true);
                getExistingProbateCases(inputValue).then((resp) => {
                    if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                        const dataObj = resp.data.object;
                        const updatedCases = updatePropertyValue(ProbateProps.customName, dataObj.cases);
                        callback(updatedCases);
                    }
                }).catch((err) => {
                    callback([]);
                }).finally(() => {
                    setIsLoadingExistingProbateCases(false);
                })
            }, DELAY_DURATION_IN_MILLISECONDS);

        } else {
            callback([]);
        }
    }
    /**
     * @description: Update 'case.probate.probateCaseId = selectedCase.id'
     */
    const onSelectChange = useCallback((selectedCase) => {
        const {id} = selectedCase || {};
        dispatch(updateCaseProbateProperty(ProbateProps.probateCaseId, id));
    }, []);

    return {
        getExistingProbateCasesWithDebounce,
        isLoadingExistingProbateCases,
        onSelectChange
    }
}

export default useConnectExistingProbateCase;