import useBeneficiaries from "../../hooks/useBeneficiaries";
import CaseUtil from "../../../../../../common/caseUtil";
import useProbateUtil from "../../../../util/useProbateUtil";
import {useBeneficiaryFields} from "../../hooks/useBeneficiaryFields";
import React, {useEffect, useState} from "react";
import {ProbateProps} from "../../../tabProbateMeeting/util";
import {TableCheckbox} from "../tableCheckbox";
import {AppSelect} from "../../../../../../common/utils/useCommonElement";
import ProbateDatePicker from "../../../../util/probateDatePicker";
import DocumentAccessView from "../../../../util/documentAccessView";
import Select from "react-select";
import Utility from "../../../../../../../api/utilLanguage";
import {DeleteButton} from "../../../../../memorialPage/ui/memories/buttons/deleteButton";

export default function RowItem({
                                    beneficiary,
                                    index,
                                    noOptionsMessage,
                                    onDeleteClick,
                                    onClickShowDocumentHandlerPopup,
                                    caseId,
                                    dispatch
                                }) {

    const {
        attendedEventOptions,
        onClickCheckbox,
        onChangeDropdownValue,
        onDateChange,
        setupDefaultDateIfEmpty,
    } = useBeneficiaries(dispatch, "RowItem");

    const updatedAttendedEventOptions = CaseUtil.getDropDownOptionsAsValueAndLabel(attendedEventOptions);
    const {documentType, legalStatusOptions} = useProbateUtil();
    const {legalStatusDropdownOptions} = useBeneficiaryFields();
    const [isDocumentHandlerPopupShownOnClick, setIsDocumentHandlerPopupShownOnClick] = useState(false);

    /***
     * @description: 'calledToEvent_DocumentId' changes (ie either new selection or upload) & is done from popup, only then check updating of default date value
     */
    useEffect(() => {
        if (beneficiary.calledToEvent_DocumentId && isDocumentHandlerPopupShownOnClick) {
            setupDefaultDateIfEmpty(beneficiary.calledToEvent_DocumentId, beneficiary.calledToEventDate, beneficiary.id, index, ProbateProps.calledToEventDate);
            setIsDocumentHandlerPopupShownOnClick(false);
        }
    }, [beneficiary.calledToEvent_DocumentId, isDocumentHandlerPopupShownOnClick]);

    const onClickHandleDocumentType = (documentType, beneficiary, index) => {
        setIsDocumentHandlerPopupShownOnClick(true);
        onClickShowDocumentHandlerPopup(documentType, beneficiary, index);
    };

    const {
        legalStatus,
        attendedEstateInventoryProceedings,
        calledToEventDate,
        confirmedCalledToEventDate
    } = beneficiary;

    const selectedLegalStatusValue = (legalStatus > 0) ? CaseUtil.setSelectedValue(legalStatusOptions, legalStatus) : null;
    const selectedValue = CaseUtil.setSelectedValue(attendedEventOptions, attendedEstateInventoryProceedings);
    // console.log("[Debug]:: legalStatus = ", legalStatus, selectedValue, updatedAttendedEventOptions, attendedEstateInventoryProceedings);
    return <tr key={beneficiary.id + beneficiary.memberId + index}>
        <td>{beneficiary.name}</td>
        <td>
            <TableCheckbox id={ProbateProps.isDeceased + index}
                           value={beneficiary.isDeceased}
                           onClick={(e) => onClickCheckbox(e, beneficiary.id, index, ProbateProps.isDeceased)}/>
        </td>
        <td>
            <div className="table--select">
                <AppSelect id={ProbateProps.legalStatus + index}
                           value={selectedLegalStatusValue}
                           options={legalStatusDropdownOptions}
                           onChange={(e) => onChangeDropdownValue(e, beneficiary.id, index, ProbateProps.legalStatus)}
                />
            </div>
        </td>
        <td>
            <TableCheckbox id={ProbateProps.isApplicant + index}
                           value={beneficiary.isApplicant}
                           onClick={(e) => onClickCheckbox(e, beneficiary.id, index, ProbateProps.isApplicant)}/>
        </td>
        <td>
            <div className="beneficiary--btn-group">
                <div className="beneficiary--datepicker">
                    <ProbateDatePicker dateString={calledToEventDate}
                                       onChange={e => onDateChange(e, beneficiary.id, index, ProbateProps.calledToEventDate)}/>
                </div>
                <div className={`beneficiary--document`}>
                    <DocumentAccessView linkableId={beneficiary.calledToEvent_DocumentId}
                                        caseId={caseId}
                                        onClick={() => onClickHandleDocumentType(documentType.calledToEventInvitation, beneficiary, index)}/>
                </div>
            </div>
        </td>
        <td>
            <div className="beneficiary--btn-group">
                <div className="beneficiary--datepicker">
                    <ProbateDatePicker dateString={confirmedCalledToEventDate}
                                       onChange={e => onDateChange(e, beneficiary.id, index, ProbateProps.confirmedCalledToEventDate)}/>
                </div>
                <div className={`beneficiary--document`}>
                    <DocumentAccessView linkableId={beneficiary.confirmedCalledToEvent_DocumentId}
                                        caseId={caseId}
                                        onClick={() => onClickShowDocumentHandlerPopup(documentType.confirmedCallToEvent, beneficiary, index)}/>
                </div>
            </div>
        </td>
        <td>
            <div className="beneficiary--btn-group table--divider">
                <div className="table--select">
                    <Select
                        value={selectedValue}
                        options={updatedAttendedEventOptions}
                        onChange={(e) => onChangeDropdownValue(e, beneficiary.id, index, ProbateProps.attendedEstateInventoryProceedings)}
                        placeholder={Utility.getLang().common.dropDownOptionPleaseSelect}
                        className="multiselect table--select"
                        classNamePrefix="multiselect"
                        noOptionsMessage={noOptionsMessage}
                    />
                </div>
                <div className="beneficiary--document">
                    <DocumentAccessView linkableId={beneficiary.attendedEvent_DocumentId}
                                        caseId={caseId}
                                        onClick={() => onClickShowDocumentHandlerPopup(documentType.attendedEvent, beneficiary, index)}/>
                </div>
            </div>
        </td>
        <td>
            <div className={`beneficiary--document beneficiary--renounced-inheritance`}>
                <DocumentAccessView linkableId={beneficiary.renouncedInheritance_DocumentId}
                                    caseId={caseId}
                                    onClick={() => onClickShowDocumentHandlerPopup(documentType.renounceInheritance, beneficiary, index)}/>
            </div>
        </td>
        <td>
            <TableCheckbox id={ProbateProps.isEstateExecutor + index}
                           value={beneficiary.isEstateExecutor}
                           onClick={(e) => onClickCheckbox(e, beneficiary.id, index, ProbateProps.isEstateExecutor)}/>
        </td>
        <td>
            <TableCheckbox id={ProbateProps.wantsToBeIncludedInInventoryProceedings + index}
                           value={beneficiary.wantsToBeIncludedInInventoryProceedings}
                           onClick={(e) => onClickCheckbox(e, beneficiary.id, index, ProbateProps.wantsToBeIncludedInInventoryProceedings)}/>
        </td>
        <td>
            <DeleteButton
                onClick={() => onDeleteClick(beneficiary, index)}
                disabled={false}
                tooltip={"Delete beneficiary"}
            />
        </td>
    </tr>
}
