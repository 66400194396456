import React from "react";
import {
    hideCreateProbatePopup,
    hideDeleteQuestionnaireBeneficiaryPopup,
    hideProbateCommonNotesPopup,
    hideQuestionnaireProbatePopup
} from "../redux/probateAction";
import {ProbateProps} from "./tabProbateMeeting/util";
import PropTypes from "prop-types";
import CustomPopup from "./documentHandlerPopup/ui/customPopup";

const ProbatePopup = ({
                          id,
                          title,
                          content,
                          show,
                          confirmationButtonTitle,
                          onConfirmation,
                          disableConfirmButton,
                          onCancel,
                          children,
                          modalClassName,
                          dispatch
                      }) => {

    const hidePopup = () => {
        //Disable popup flag
        switch (id) {
            case ProbateProps.createProbatePopup:
                dispatch(hideCreateProbatePopup());
                break;
            case ProbateProps.questionnaireProbatePopup:
                dispatch(hideQuestionnaireProbatePopup());
                break;
            case ProbateProps.deleteQuestionnaireBeneficiaryPopup:
                dispatch(hideDeleteQuestionnaireBeneficiaryPopup());
                break;
            case ProbateProps.probateCommonNotes:
                dispatch(hideProbateCommonNotesPopup());
                break;
            default:
                console.log("Unhandled hide popup action");
        }
    };

    /***
     * @description: hide and control back to callee
     */
    const onCloseAction = () => {
        hidePopup();
        if ((onCancel !== undefined) && (onCancel !== null)) {
            onCancel();
        }
    }

    /***
     * @description: hide and control back to callee
     */
    const onConfirmationAction = () => {
        hidePopup();
        if ((onConfirmation !== undefined) && (onConfirmation !== null)) {
            onConfirmation();
        }
    };

    return <CustomPopup openModal={show}
                        title={title}
                        headerInfo={content}
                        valueOk={confirmationButtonTitle}
                        onConfirmation={onConfirmationAction}
                        closePopup={onCloseAction}
                        modalClassName={modalClassName}
                        disableConfirmButton={disableConfirmButton}
    >
        {children}
    </CustomPopup>
}

ProbatePopup.defaultProps = {
    title: "",
    content: "",
    disableConfirmButton: false,
}

ProbatePopup.propTypes = {
    id: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    onConfirmation: PropTypes.func.isRequired
}
export default ProbatePopup;