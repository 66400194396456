import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {reduxForm} from "redux-form";
import AppUtil from "./appUtil";
import Utility from "../../api/utilLanguage";
import EventTable from "./log/table/eventTable";
import LogUtil, {LogCategory} from "./log/util/logUtil";
import {LogTable} from "./log/table/logTable";
import Spinner from "./spinner";

class LogDisplay extends React.Component {

    getOldStyleLogData = (logCategory, logData, oldLogData) => {
        let resLogData = [];
        if (logCategory === LogCategory.case) {
            resLogData = (AppUtil.isAvailable(oldLogData) && oldLogData.length > 0) ? oldLogData : [];
        } else {//Other log categories
            resLogData = logData;
        }
        return resLogData;
    };

    render = () => {
        const {caseLog, logData, isLoading} = this.props;

        let logCategory = LogCategory.none;
        if (caseLog) {
            logCategory = LogCategory.case;
        }

        if (AppUtil.isEmpty(logData) && isLoading) {
            return LogUtil.getPlaceholder();
        } else {
            const disabled = false;
            const {filterToolBar, oldLogData} = this.props;
            const oldStyleLogData = this.getOldStyleLogData(logCategory, logData, oldLogData);
            return (
                <React.Fragment>
                    {
                        (logCategory === LogCategory.case) &&
                        <React.Fragment>
                            <div className="section__toolbar section__toolbar--filters" disabled={disabled}>
                                <div className="inner">
                                    <div className="btn-toolbar btn-toolbar--head">
                                        {
                                            filterToolBar
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                isLoading && <Spinner message={Utility.getLang().common.loadingLog}/>
                            }
                            <div className="log_item__list">
                                <EventTable {...this.props} currentObject="case"/>
                            </div>
                        </React.Fragment>
                    }
                    {/* Show only if old style log needs*/}
                    {
                        (AppUtil.isAvailable(oldStyleLogData) && oldStyleLogData.length > 0) &&
                        this.getOldStyleLogTable(oldStyleLogData)
                    }
                </React.Fragment>
            )
        }
    };

    /**
     *@description: Old style logs
     */
    getOldStyleLogTable = (logData) => {
        return <div className="table__wrapper">
            <table className="fnxtable fnxtable--flat">
                <thead>
                <tr className="tr">
                    <th className="th" scope="col">
                        {Utility.getLang().calls.logTabContent.user}
                    </th>
                    <th className="th" scope="col">
                        {Utility.getLang().calls.logTabContent.description}
                    </th>
                    <th className="th" scope="col">
                        {Utility.getLang().calls.logTabContent.dateTime}
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    (AppUtil.isAvailable(logData) && logData.length > 0) &&
                    logData.map((logs, i) => {
                        return (
                            <LogTable logs={logs} key={logs.id}/>
                        );
                    })
                }
                </tbody>
            </table>
        </div>
    }
}


function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: "LogDisplay"
})(LogDisplay));