import React, {useEffect} from "react";
import connect from "react-redux/es/connect/connect";
import MeetingDateTime from "./ui/meetingDateTime";
import AppUtil, {Lang} from "../../../../common/appUtil";
import {ProbateProps} from "./util";
import MeetingPlace from "./ui/meetingPlace";
import OwnerReviewAndApprove from "./ui/ownerReviewAndApprove";
import ReviewerReviewAndApprove from "./ui/reviewerReviewAndApprove";
import MemorizedQuestionnaire from "../questionnaire";
import {AppSelect} from "../../../../common/utils/useCommonElement";
import useProbateMeetingUtil from "./hooks/useProbateMeetingUtil";
import ProbateDueDate from "./ui/probateDueDate";
import DocumentAccessView from "../../util/documentAccessView";
import useProbateUtil from "../../util/useProbateUtil";
import useDocumentHandlerPopup from "../documentHandlerPopup/hooks/useDocumentHandlerPopup";
import DocumentHandlerPopup from "../documentHandlerPopup";
import Icon from "../../../../common/icon";
import useDueDateUtil from "./hooks/useDueDateUtil";
import {WarningAlert} from "../../../caseGuestsSubComponents/guestsOverview";
import {updateApprovalChecksIsEditable} from "../../redux/probateAction";
import {ApprovalType} from "./hooks/useApprovalCheck";

function ProbateMeeting({
                            isProbateInfoLoading,
                            meetingInfo,
                            initialMeetingInfo,
                            probateQAs,
                            allUserDetailsForCases,
                            enums,
                            onSaveClick,
                            caseId,
                            showDocumentHandlerPopup,
                            version,
                            dispatch
                        }) {

    const {
        onChangeCheckbox,
        onChangeDropdownValue,
        maritalStatusDropdownValue,
        maritalStatusDropdownOptions,
        selectedReviewer
    } = useProbateMeetingUtil(enums, meetingInfo, dispatch);

    const {
        meetingDateTime,
        meetingPlace,
        dueDateInfo = {},
        ownerName
    } = meetingInfo || {};

    const {documentType} = useProbateUtil();
    const {onClickShowDocumentHandlerPopup, selectedDataTracker} = useDocumentHandlerPopup(dispatch);

    const isActiveEditButton = AppUtil.isAvailable(dueDateInfo.editButton) ? dueDateInfo.editButton.isActive : false;
    const isActiveResetButton = AppUtil.isAvailable(dueDateInfo.resetButton) ? dueDateInfo.resetButton.isActive : false;
    const {dueDateDocumentUploadRequiredInfo} = useDueDateUtil(dueDateInfo, dispatch);

    /***
     * @description: if owner type set/probate saved, only then update isEditable
     */
    useEffect(() => {
        if (ownerName) {
            dispatch(updateApprovalChecksIsEditable());
        }
    }, [meetingInfo?.approvalChecks?.length, ownerName, version]);

    return (
        <>
            <DocumentHandlerPopup
                show={showDocumentHandlerPopup}
                title={Lang().cases.probates.selectOrUploadDocument}
                selectedData={selectedDataTracker}
                caseId={caseId}
                documentTypeId={selectedDataTracker.documentType}
                dispatch={dispatch}
            />
            <div className="section__columns">
                <div className="section__column">
                    <h5>{Lang().cases.probates.meeting}</h5>
                    <div className="form-group">
                        <label htmlFor="caseDeceasedFirstName">
                            {Lang().cases.probates.dateTimeOfProbateMeeting}
                        </label>
                        <div className="d-flex">
                            <MeetingDateTime meetingDateTime={meetingDateTime}
                                             isLoading={isProbateInfoLoading}/>
                        </div>
                    </div>
                    <div className="form-group">
                        <AppSelect id={ProbateProps.maritalStatus}
                                   title={Lang().cases.probates.maritalStatus}
                                   value={maritalStatusDropdownValue}
                                   options={maritalStatusDropdownOptions}
                                   onChange={(e) => onChangeDropdownValue(e, ProbateProps.maritalStatus)}
                                   isLoading={isProbateInfoLoading}
                                   isDisabled={isProbateInfoLoading}
                        />
                    </div>
                    <div className="form-group">
                        <MeetingPlace title={Lang().cases.probates.place}
                                      value={meetingPlace}
                                      isLoading={isProbateInfoLoading}/>
                    </div>
                    <div className="form-group add-border" disabled={isProbateInfoLoading}>
                        <div className="probate--due-date">
                            <ProbateDueDate title={Lang().cases.probates.dueDate}
                                            dueDateInfo={dueDateInfo}
                                            initialDueDateInfo={initialMeetingInfo.dueDateInfo}
                                            isActiveEditButton={isActiveEditButton}
                                            isActiveResetButton={isActiveResetButton}
                                            isLoading={isProbateInfoLoading}
                                            dispatch={dispatch}
                            />
                            <div className={`btn-toolbar image--btn-group`}>
                                <DocumentAccessView linkableId={dueDateInfo ? dueDateInfo.dueDateDocumentId : null}
                                                    caseId={caseId}
                                                    onClick={() => onClickShowDocumentHandlerPopup(documentType.probateDueDate, dueDateInfo, 0)}
                                />
                            </div>
                        </div>
                        <div className="probate--due-description">
                            {
                                isActiveResetButton
                                && <p className="description">
                                    <Icon type="info" small/>
                                    {Lang().cases.probates.resetHelpMsg.replace("{DueDate}", Lang().cases.probates.dueDate).replace("{probate}", Lang().cases.probates.probate)}
                                </p>
                            }
                            {
                                dueDateDocumentUploadRequiredInfo.status
                                && <WarningAlert message={dueDateDocumentUploadRequiredInfo.message}/>
                            }
                        </div>
                    </div>
                </div>
                <div className="section__column">
                    <OwnerReviewAndApprove
                        id={ApprovalType.owner}
                        approvalChecks={meetingInfo?.approvalChecks}
                        title={Lang().cases.probates.owner}
                        typeName={ownerName}
                        onChangeCheckbox={onChangeCheckbox}
                        isLoading={isProbateInfoLoading}
                    />
                    <ReviewerReviewAndApprove
                        id={ApprovalType.reviewer}
                        approvalChecks={meetingInfo?.approvalChecks}
                        title={Lang().cases.probates.reviewer}
                        selectedOption={selectedReviewer}
                        options={allUserDetailsForCases}
                        onChangeCheckbox={onChangeCheckbox}
                        isLoading={isProbateInfoLoading}
                    />
                    {/*<div className="form-group">
                        <AppCheckBox id={ProbateProps.wantsHelpWithEstateAdministration}
                                         title={Lang().cases.probates.wantsHelpWithEstateAdministration}
                                         value={wantsHelpWithEstateAdministration}
                                         onChange={(e) => onChangeCheckbox(e, ProbateProps.wantsHelpWithEstateAdministration)}
                        />
                    </div>*/}
                    <div className="form-group">
                        <MemorizedQuestionnaire probateQAs={probateQAs}
                                                enums={enums}
                                                isProbateInfoLoading={isProbateInfoLoading}
                                                onSaveClick={onSaveClick}/>
                    </div>
                </div>
            </div>
        </>
    );
}

function mapStateToProps(state) {
    return {
        meetingInfo: AppUtil.isAvailable(state.probateReducer.probateInfo) ? state.probateReducer.probateInfo.meetingInfo : {},
        initialMeetingInfo: AppUtil.isAvailable(state.probateReducer.initialProbateInfo) ? state.probateReducer.initialProbateInfo.meetingInfo : {},
        caseId: AppUtil.isAvailable(state.application.selectedCaseData) ? state.application.selectedCaseData.id : null,
        allUserDetailsForCases: state.application.allUserDetailsForCases,
        enums: state.probateReducer.enums,
        probateQAs: AppUtil.isAvailable(state.probateReducer.probateInfo) && AppUtil.isAvailable(state.probateReducer.probateInfo.probateQAs) ? state.probateReducer.probateInfo.probateQAs : [],
        showDocumentHandlerPopup: state.probateReducer.showDocumentHandlerPopup,
        version: AppUtil.isAvailable(state.probateReducer.probateInfo) ? state.probateReducer.probateInfo.version : ""
    };
}


export default connect(mapStateToProps)(ProbateMeeting);
