import {push} from 'react-router-redux';
import {Auth} from "aws-amplify";
import {bottomCenter} from "../components/common/customToastr";
import Utility from "../api/utilLanguage";
import {getTenantKey} from "../components/common/localStorageUtil";
import {clearLockCase} from "../components/case/lockCase/redux/lockCaseAction";
import {HIDE_CASE_TAKE_OVER_POPUP, SHOW_CASE_TAKE_OVER_POPUP} from "../components/case/lockCase/redux/types";
import {clearOrderDeliveryMethods} from "../components/orderDeliveryMethods/orderDeliveryMethodsAction";
import {PopupProps} from "../components/common/appUtil";
import {DocumentProps} from "../components/case/documents/documentUtil/documentUtil";

export function onLoginError(data) {
    return {type: 'ON_LOGIN_ERROR', data};
}

export function onError(data) {
    return {type: 'ON_ERROR', data};
}

export function showDisplayNothingComponent(data) {
    return {type: 'SHOW_DISPLAY_NOTHING_COMPONENT', data};
}

export function clearAllCounts() {
    return {type: 'CLEAR_ALL_COUNTS'};
}

export function getApiTokenFromCookie() {
    return {type: 'GET_API_TOKEN_FROM_COOKIE', data: null};
}

export const ON_HEADER_MENU_ITEM_CLICKED = 'ON_HEADER_MENU_ITEM_CLICKED';

export function onHeaderMenuItemClicked(data, currentPath) {
    return {type: ON_HEADER_MENU_ITEM_CLICKED, data, currentPath};
}

export function setUnreadNotificationCount(data) {
    return {type: 'SET_UNREAD_NOTIFICATION_COUNT', data};
}

export function subHeaderItemClickedMyAccount(data) {
    return {type: 'SUB_HEADER_ITEM_CLICKED_MYACCOUNT', data};
}

export function subHeaderItemClickedSetting(data) {
    return {type: 'SUB_HEADER_ITEM_CLICKED_SETTING', data};
}

export const UPDATE_COMMON_DATA_DETAILS = "UPDATE_COMMON_DATA_DETAILS";

export function updateCommonDataDetails(data) {
    return {type: UPDATE_COMMON_DATA_DETAILS, data};
}

export const CLEAR_INITIAL_VALUES = "CLEAR_INITIAL_VALUES";

export function clearInitialValues(data) {
    return {type: CLEAR_INITIAL_VALUES, data};
}

export const CLEAR_USELESS_VALUES = "CLEAR_USELESS_VALUES";

export function clearUselessValues(data) {
    return {type: CLEAR_USELESS_VALUES, data};
}

export function makeOldAndNewCardValuesSameForEmptyColumn(data) {
    return {type: 'MAKE_OLD_AND_NEW_CARD_VALUES_SAME_FOR_EMPTY_COLUMN', data};
}

/* Authenticate user start */
export function userAuthenticationApi(auth, lastPath) {
    return async function (dispatch) {
        dispatch(onLoginError(""));
        await (Auth.signIn(auth.email, auth.password, {"tenantKey": getTenantKey()}).then(response => {
            console.log("response userAuthentication", response, lastPath);
            if (response.challengeName === "NEW_PASSWORD_REQUIRED") {
                dispatch(loginInfo(response));
                dispatch(push('/confirmuserpage'));
            } else if (response.signInUserSession.idToken.payload.status === "true") {
                dispatch(loginInfo(response));
                dispatch(userSignedIn());
                if (lastPath !== '') {
                    dispatch(push(lastPath));
                }
            } else {
                bottomCenter().error(Utility.getLang().errorMessages.user.userIsNotActive);
            }
        }).catch(error => {
            if (auth.preloader !== undefined) {
                auth.preloader.stop();
            }
            return dispatch(onLoginError(Utility.getLang().errorMessages.user.invalidCredentials + " " + error.message));
        }));
    };
}

export function loginInfo(data) {
    return {type: 'LOGIN_INFO', data};
}

export const USER_SIGNED_IN = "USER_SIGNED_IN";

export function userSignedIn() {
    return {type: USER_SIGNED_IN};
}

export function loginInfoFromAfterConfirm(data) {
    return {type: 'LOGIN_INFO_FROM_AFTER_CONFIRM', data};
}

export const SHOW_OR_HIDE_UNSAVED_POPUP_LAZILY = "SHOW_OR_HIDE_UNSAVED_POPUP_LAZILY";

export function showUnsavedPopupLazily() {
    return showOrHideUnsavedPopupLazily(true);
}

export function hideUnsavedPopupLazily() {
    return showOrHideUnsavedPopupLazily(false);
}

function showOrHideUnsavedPopupLazily(data) {
    return {type: SHOW_OR_HIDE_UNSAVED_POPUP_LAZILY, data};
}

export const SHOW_UNSAVED_POPUP = "SHOW_UNSAVED_POPUP";

export function showUnsavedPopup(data) {
    return {type: SHOW_UNSAVED_POPUP, data};
}

export const ASK_BEFORE_UPGRADE = "ASK_BEFORE_UPGRADE";

export function askBeforeUpgrade(data) {
    return {type: ASK_BEFORE_UPGRADE, data};
}

export function hasUnsavedData(data) {
    return {type: 'HAS_UNSAVED_DATA', data};
}

export function clearRouteCondition(data) {
    return {type: 'CLEAR_ROUTE_CONDITION', data};
}

export function closePopupWithSameRoute(data) {
    return {type: 'CLOSE_POPUP_WITH_SAME_ROUTE', data};
}

export function closeUpgradePopup(data) {
    return {type: 'CLOSE_UPGRADE_POPUP', data};
}

export function showSearchPanel(data) {
    return {type: 'SHOW_SEARCH_PANEL', data};
}

export function showNotificationPanel(data) {
    return {type: 'SHOW_NOTIFICATION_PANEL', data};
}

export const CLEAR_CASE_RELATED_DATA = "CLEAR_CASE_RELATED_DATA";

export function clearCaseRelatedData(data) {
    return {type: CLEAR_CASE_RELATED_DATA, data};
}

/* Authenticate user end */

export function afterRefreshToken(data) {
    return {type: 'AFTER_REFRESH_TOKEN', data};
}

/* emailVerification user start */
export function emailVerification(username) {
    console.log("username", username);
    return function (dispatch) {
        Auth.forgotPassword(username, {"tenantKey": getTenantKey()})
            .then(data => {
                dispatch(codeResponse(data));
            })
            .catch(error => {
                return dispatch(onResetLoginError(error));
            });
    };
}

export function codeResponse(data) {
    return {type: 'CODE_RESPONSE', data};
}

export function onResetLoginError(data) {
    return {type: 'ON_RESET_LOGIN_ERROR', data};
}

export function removeLoginError() {
    return function (dispatch) {
        return dispatch(onRemoveLoginError());
    };
}

export function onRemoveLoginError() {
    return {type: 'REMOVE_LOGIN_ERROR'};
}

/* emailVerification user end */

/* changeUserPassword user start */
export function changeUserPassword(username, code, new_password) {
    console.log("username", username, code, new_password);
    return function (dispatch) {
        Auth.forgotPasswordSubmit(username, code, new_password)
            .then(data => {
                dispatch(changedPasswordResponse(data));
                dispatch(push('/'));
            })
            .catch(error => {
                return dispatch(onResetLoginError(error));
            });
    };
}

export function changedPasswordResponse(data) {
    return {type: 'CHANGED_PASSWORD_RESPONSE', data};
}

/* changeUserPassword user end */

/* registerUser user start (that is user creation to cognito from UI)

export function registerUser(values) {
    let username = values.username;
    let password = values.password;
    let email = values.email;
    //var name = values.name;
    let phone_number = values.phone_number;

    return function (dispatch) {
        Auth.signUp({
            username,
            password,
            attributes: {
                email,
                //name,
                phone_number
            },
            validationData: []
        }).then(data => {
            dispatch(registeredUserInfo(data));
            // dispatch(push('/dashboard'));
        })
            .catch(error => {
                return dispatch(onError(error));
            });
    };
}

export function registeredUserInfo(data) {
    return {type: 'REGISTERED_USER_INFO', data};
}
 End here*/

/* Logout user start */
export function logOutUser() {
    return function (dispatch) {
        Auth.signOut()
            .then(data => {
                dispatch(signOutFromCognito(data));
                dispatch(signOutRemoveWebsocketConnection())
                dispatch(clearLockCase());
                dispatch(clearOrderDeliveryMethods())
                dispatch(push('/'));
            }).catch(error => {
            return dispatch(onError(error));
        });
    };
}

export function signOutFromCognito(data) {
    return {type: 'SIGN_OUT_FROM_COGNITO', data};
}

export const SIGN_OUT_REMOVE_WEBSOCKET_CONNECTION = "SIGN_OUT_REMOVE_WEBSOCKET_CONNECTION";

export function signOutRemoveWebsocketConnection() {
    return {type: SIGN_OUT_REMOVE_WEBSOCKET_CONNECTION};
}

/* Logout user end */
export function showPopup(data) {
    return {type: 'SHOW_POPUP', data};
}

export function showPopupOnDrag(data) {
    return {type: 'SHOW_POPUP_ON_DRAG', data};
}

export const UPDATE_GENERIC_POPUP = 'UPDATE_GENERIC_POPUP'

export function shouldShowStageChangePopup() {
    return updateGenericPopup(PopupProps.showStageChangePopup, true);
}

function updateGenericPopup(key, payload) {
    return {type: UPDATE_GENERIC_POPUP, key, payload};
}

export function showPopupOnOrderDrag(data) {
    return {type: 'SHOW_POPUP_ON_ORDER_DRAG', data};
}

export function showPopupOnSend(data, source) {
    return {type: 'SHOW_POPUP_ON_SEND', data, source};
}

export function showPackageFuneralPopup() {
    return {type: 'SHOW_PACKAGE_FUNERAL_POPUP'};
}

export function showPopupForGuestEmailAction(data) {
    return {type: 'SHOW_POPUP_FOR_GUEST_EMAIL_ACTION', data};
}

export function showPopupForGuestAddingRestriction(data, headerTitle) {
    return {type: 'SHOW_POPUP_FOR_GUEST_ADDING_RESTRICTION', data, headerTitle};
}

export function userRestrictForGuestAddingRestrictionPopup(data) {
    return {type: 'USER_RESTRICTION_FOR_GUEST_ADDING_RESTRICTION_POPUP', data};
}

export function hidePopupForGuestAddingRestriction(data) {
    return {type: 'HIDE_POPUP_FOR_GUEST_ADDING_RESTRICTION', data};
}

export function hidePopupForGuestEmailAction(data) {
    return {type: 'HIDE_POPUP_FOR_GUEST_EMAIL_ACTION', data};
}

export function showPopupForDeletingGuest(index) {
    return {type: 'SHOW_POPUP_FOR_DELETING_GUEST', index};
}

export function hidePopupForDeletingGuest(data) {
    return {type: 'HIDE_POPUP_FOR_DELETING_GUEST', data};
}

export function showCaseTakeOverPopup() {
    return {type: SHOW_CASE_TAKE_OVER_POPUP, payload: true};
}

export function hideCaseTakeOverPopup() {
    return {type: HIDE_CASE_TAKE_OVER_POPUP, payload: false};
}

export function hidePopupSection(data) {
    return {type: 'HIDE_POPUP_SECTION', data};
}

export function showPopupOnDocumentDelete(data) {
    return {type: 'SHOW_POPUP_ON_DOCUMENT_DELETE', data};
}

export function hidePopupOnDocumentDelete(data) {
    return {type: 'HIDE_POPUP_ON_DOCUMENT_DELETE', data};
}

/***
 * @description: Popup handler for visible to customer
 */
export function shouldShowVisibleToCustomerPopup() {
    return updateGenericPopup(DocumentProps.showVisibleToCustomerPopup, true);
}

export function shouldHideVisibleToCustomerPopup() {
    return updateGenericPopup(DocumentProps.showVisibleToCustomerPopup, false);
}

export function hideUi(data) {
    return {type: 'HIDE_UI', data};
}

export function showUi(data) {
    return {type: 'SHOW_UI', data};
}

export function redirectToLogin() {
    return function (dispatch) {
        dispatch(push('/'));
    };
}

//filter applied data
export function saveAllFilterAppliedData(data, name) {
    return {type: 'SAVE_ALL_FILTER_APPLIED_DATA', data, name};
}

export function escClicked(data) {
    return {type: 'ESC_CLICKED', data};
}

export const SHOW_OR_HIDE_REVOKE_ACCESS_LEVEL_POPUP = 'SHOW_OR_HIDE_REVOKE_ACCESS_LEVEL_POPUP';

export function showHideRevokeAccessLevelPopup(payload) {
    return showOrHideRevokeAccessLevelPopup(true);
}

export function hideRevokeAccessLevelPopup(payload) {
    return showOrHideRevokeAccessLevelPopup(false);
}

function showOrHideRevokeAccessLevelPopup(payload) {
    return {type: SHOW_OR_HIDE_REVOKE_ACCESS_LEVEL_POPUP, payload};
}