import Icon from "../../icon";
import Tooltip from "../../tooltip";
import React from "react";
import useMenuItem from "../hooks/useMenuItem";
import Utility from "../../../../api/utilLanguage";

function MenuItem({iconType, title, shortcut, isIconColored, count}) {
    return <>
        {count
            ? <IconWithCount count={count} iconType={iconType} isIconColored={isIconColored}/>
            : <Icon type={iconType} large isColor={isIconColored}/>
        }
        {shortcut && <span className="shortcut">{shortcut}</span>}
        <span className="navbar__item__description">
                {title}
            </span>
    </>;


}

const IconWithCount = ({count, iconType, isIconColored}) => {
    return <span className="navbar__item__count">
            <Icon type={iconType} large isColor={isIconColored}/>
        {
            count ? <span className="count">{count}</span> : null
        }
        </span>
}

function MenuItemWithImage({src, title, shortcut}) {
    return <>
         <span className="avatar avatar--30">
        {src
            ? <img
                src={src}
                width="96"
                height="96"
                alt={Utility.getLang().common.myAvatar}
            />
            : <Icon type="avatar" navAvatar/>
        }
         </span>
        {shortcut && <span className="shortcut">{shortcut}</span>}
        <span className="navbar__item__description">
                {title}
            </span>
    </>
}

const AnchorContainer = ({pathToRoute, skipAnchor, children}) => {
    return skipAnchor
        ? <>{children}</>
        : <a href={pathToRoute} onClick={(e) => e.preventDefault()}>
            {children}
        </a>
}

const HeaderMenuItem = ({
                            title,
                            pathToRoute,
                            onItemClick,
                            isActive,
                            className,
                            additionalClassName = "",
                            toolAlignLeft = false,
                            toolAlignRight = false,
                            imageSource = null,
                            count = null,
                            skipAnchor = false
                        }) => {
    const {getDesignInfo} = useMenuItem()
    const designInfo = getDesignInfo(pathToRoute, className);
    return <li
        onClick={() => onItemClick(pathToRoute)}
        className={`${designInfo?.className} ${isActive ? " is-active" : ""} ${additionalClassName}`}
        data-tip
    >
        <AnchorContainer pathToRoute={pathToRoute} skipAnchor={skipAnchor}>
            {designInfo?.showImage
                ? <MenuItemWithImage src={imageSource}
                                     title={title}
                                     shortcut={designInfo?.shortcut}
                />
                : <MenuItem iconType={designInfo?.iconType}
                            title={title}
                            shortcut={designInfo?.shortcut}
                            isIconColored={designInfo?.isIconColored}
                            count={count}
                />
            }
        </AnchorContainer>
        <Tooltip alignLeft={toolAlignLeft} alignRight={toolAlignRight} shortcut={designInfo.shortcut}>
            {title}
        </Tooltip>
    </li>
}
export default HeaderMenuItem;