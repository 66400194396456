export default {
    Added: "Added",
    Updated: "Updated",
    Deleted: "Deleted",
    CustomerProfile: "CustomerProfile",
    CustomerPackage: "CustomerPackage",
    CaseColumnItemCard: "CaseColumnItemCardProxy",
    ProductColumnItemCard: "ProductColumnItemCardProxy",
    LocationColumnItemCard: "LocationColumnItemCardProxy",
    OrderColumnItemCard: "OrderColumnItemCardProxy",
    CallColumnItemCard: "CallColumnItemCardProxy",
    ContactColumnItemCard: "ContactColumnItemCardProxy",
    CustomerColumnCard: "CustomerColumnCard",
    CaseDeceasedComponent: "CaseDeceased",
    CaseStatusComponent: "CaseStatus",
    CaseRelativesComponent: "CaseRelatives",
    CaseCallsComponent: "CaseCalls",
    CaseProbates: "CaseProbates",
    CaseNotesComponent: "CaseNotes",
    CaseNotesPreview: "CaseNotesPreview",
    CaseGuestsComponent: "CaseGuests",
    CaseDocuments: "CaseDocumentsProxy",
    DocumentDetails: "DocumentDetailsProxy",
    CaseMemorialComponent: "CaseMemorial",
    CaseLogComponent: "CaseLog",
    CaseMessageComponent: "CaseMessages",
    ProductDetailsComponent: "ProductDetails",
    ProductChildrenComponent: "ProductChildren",
    ProductPictureComponent: "ProductPicture",
    LocationCeremonyType: "LocationCeremonyType",
    LocationDetails: "LocationDetails",
    LocationEquipment: "LocationEquipment",
    LocationMap: "LocationMap",
    LocationContact: "LocationContact",
    OrderSpecification: "OrderSpecification",
    OrderNotes: "OrderNotes",
    OrderLog: "OrderLog",
    Call: "Call",
    CallNotes: "CallNotes",
    CallLog: "CallLog",
    ContactDetail: "ContactDetail",
    CaseOrderTenantBasedComponent: "CaseOrder",
    ContactMap: "ContactMap",
    SingleColumnSearch: "singleColumnCall",
    AllColumnSearch: "allColumnCall",
    TagsClear: "filterTagsClear",
    ColumnAttributes: "columnAttributes",
    LoaderStatus: "loaderStatus",
    PaginationStartIndex: "psi",
}
