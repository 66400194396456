import React from "react";
import Icon from "../../../common/icon";
import AppUtil from "../../../common/appUtil";

const DocumentItemContent = ({imgFileName, imgClassName, description = "", freeTextNote = ""}) => {

    return (
        <React.Fragment>
            <Icon
                type={imgFileName}
                className={imgClassName}
                isColor={imgFileName !== "document-na"}
                small
            />
            <span>{description}</span>
            {
                AppUtil.isAvailable(freeTextNote) &&
                <p className="section__subtitle p-wrap">{freeTextNote}</p>
            }
        </React.Fragment>
    );
};

export default DocumentItemContent;
