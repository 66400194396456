import React from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import {bindActionCreators} from "redux";
import * as actionMaster from "../../actions/uiAction";
import * as caseAction from "../../actions/caseAction";
import {reduxForm} from "redux-form";
import CaseHeaderSection from "./caseHeaderSection";
import Utility from "../../api/utilLanguage";
import DisplayNothing from "../common/nothingToDisplay";
import PopupForEmailSend from "../common/popups/popupForEmailSend";
import FormValidator from "../common/formValidator";
import CaseEditGuestComponent from "./caseEditGuestComponent";
import CaseUtil from "../common/caseUtil";
import AsyncSelect from "react-select/lib/Async";
import RefreshToken from "../../api/validateToken";
import config from "../../api/config";
import {getApiToken} from "../common/localStorageUtil";
import AppUtil from "../common/appUtil";
import Button from "../common/button";
import GuestsOverview from "../case/caseGuestsSubComponents/guestsOverview";
import GuestMailManager from "./caseOrderSubComponents/funeralOrderShared/guestMailManager";
import PopupForGuestAddingRestriction from "../common/popups/components/popupForGuestAddingRestriction";
import GuestUtil from "./caseGuestsSubComponents/guestUtil";
import CommonApiCall from "../../api/commonApi";
import Select from 'react-select';
import PopupForGuestDeletion from "./caseGuestsSubComponents/popupForGuestDeletion";
import LockCaseUtil from "./lockCase/util/lockCaseUtil";
import {ValidationErrors} from "../common/validationUtil";

class CaseGuestsComponent extends React.Component {

    constructor(props) {
        super(props);
        this.rsvpMethodForGuest = [];
        this.state = {
            showGuestList: true,
            showAddGuestForm: false,
            showEditGuestForm: false,
            guestIndexForEdit: null,
            resultData: [],
            disableSave: false,
            haveActiveGuests: false,
            selectedGuest: null
        };
    }

    componentDidUpdate = (prevProps) => {
        if (JSON.stringify(prevProps.selectedCaseData) !== JSON.stringify(this.props.selectedCaseData)) {
            const errorsInfo = ValidationErrors.info(this.props.selectedCaseData);
            if (ValidationErrors.isAvailable(errorsInfo)) {
                this.props.caseAction.updateValidationErrorsInfo(errorsInfo);
            } else {
                this.props.caseAction.updateValidationErrorsInfo({});
            }
        }
    };

    componentDidMount = () => {
        this.updateHaveActiveGuests(this.props.selectedCaseData);

        this.props.selectedCaseData.guests.forEach((guest, i) => {
            CaseUtil.getRsvpMethods().forEach((rsvp, index) => {
                if (rsvp.value === guest.rsvp) {
                    this.rsvpMethodForGuest.push(rsvp);
                }
            })
        })
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (AppUtil.isAvailable(nextProps.selectedCaseData) && AppUtil.isAvailable(nextProps.selectedCaseData.guests) && (JSON.stringify(nextProps.selectedCaseData.guests) !== JSON.stringify(this.props.selectedCaseData.guests))) {
            this.updateHaveActiveGuests(nextProps.selectedCaseData);
        }
    };

    /***
     * @description: haveActiveGuests helper methods
     */

    updateHaveActiveGuests = (caseData) => {
        const haveActiveGuests = GuestUtil.haveActiveGuests(caseData);
        this.setHaveActiveGuests(haveActiveGuests);
    };

    setHaveActiveGuests = (value) => {
        this.setState({haveActiveGuests: value});
    };

    showGuestAddingRestrictionAlertForNewGuest = (caseData, actionMaster) => {
        GuestUtil.showAlert(caseData.guestCountInfo, 0, 0, 1, actionMaster);
    };

    addGuest = () => {
        const {selectedCaseData, actionMaster} = this.props;
        if (GuestUtil.isGuestAddingRestricted(selectedCaseData, 1)) {
            this.showGuestAddingRestrictionAlertForNewGuest(selectedCaseData, actionMaster);
        } else {
            const newGuest = CaseUtil.getNewGuest();
            this.props.caseAction.addNewGuest(newGuest);
            this.setState({
                guestIndexForEdit: this.props.selectedCaseData.guests.length,
                showGuestList: false,
                showAddGuestForm: true
            });
        }
    };

    editGuest = (guestDetail, guestIndex) => {
        this.setState({
            showGuestList: false,
            showEditGuestForm: true,
            guestIndexForEdit: guestIndex
        });
    };

    goToGuestList = () => {
        this.setState({showGuestList: true, showAddGuestForm: false, showEditGuestForm: false});
    };

    changeGuestDetails = (event, guestIndex, fieldName, rsvp, additionalGuests) => {
        const {selectedCaseData, actionMaster} = this.props;
        const newAdditionalGuests = (fieldName === 'rsvp') ? event.value : event.target.value;

        if (GuestUtil.onChangeOfAdditionalGuests(fieldName, rsvp) && GuestUtil.isGuestAddingRestrictedOnAdditionalGuestChange(selectedCaseData, additionalGuests, parseInt(newAdditionalGuests, 10))) {
            GuestUtil.showAlert(selectedCaseData.guestCountInfo, additionalGuests, parseInt(newAdditionalGuests, 10), 0, actionMaster);
        }
        this.props.caseAction.changeGuestDetails(newAdditionalGuests, guestIndex, fieldName);
    };

    changeGuestFoodRestrictionDetails = (guestIndex, value) => {
        this.props.caseAction.changeGuestFoodRestrictionDetails(guestIndex, value)
    };

    deleteGuest = (value, guestIndex, fieldName) => {
        this.props.caseAction.changeGuestDetails(value, guestIndex, fieldName)
    };

    deleteGuestFromEdit = (value, guestIndex, fieldName) => {
        this.props.caseAction.changeGuestDetails(value, guestIndex, fieldName);
        this.goToGuestList();
    };

    hidePopupOnCancel = () => {
        this.props.actionMaster.hidePopupSection();
    };

    sendEmailSummary = (relativeId, bodyData) => {
        // Sending the email
        bodyData.caseId = this.props.selectedCaseData.id;
        this.props.caseAction.sendEmailSummary(this.props.selectedCaseData.id, bodyData);
    };

    getDetailsByPersonNumber = (event, relative, index) => {
        console.log("person Number", relative.personNumber);
        event.preventDefault();
        this.props.caseAction.getDetailsByPersonNumberOfRelative(relative, index);
    };

    onClickSelect = (e) => {
        e.stopPropagation();
    };

    /***
     * @description: Guest option search
     */
    promiseOptionForGuests = inputValue =>
        new Promise(resolve => {
            if (AppUtil.jwtTokenIsExpired()) {
                RefreshToken.validateRefreshToken().then(newData => {
                    this.props.actionMaster.afterRefreshToken(newData);
                    setTimeout(() => {
                        resolve(this.loadOptionsForGuests(newData.idToken.jwtToken, inputValue));
                    }, 1000);
                })
            } else {
                setTimeout(() => {
                    resolve(this.loadOptionsForGuests(getApiToken(), inputValue));
                }, 1000);
            }
        });

    loadOptionsForGuests = async (token, inputValue) => {
        let temp = [];
        if (inputValue) {
            await this.loadGuestData(token, this.props.selectedCaseData.id, inputValue).then(data => {
                    temp = data.object.relatives;
                }
            );
            this.setState({
                resultData: temp
            });
            return this.state.resultData;
        } else {
            return [];
        }
    };

    loadGuestData(token, caseId, inputValue) {
        const url = config.baseURL + `api/v1/case/person/list?f_cid=${caseId}&f_tx=${inputValue}`;
        return CommonApiCall.genericApiCall(url, token, "GET").then(data => {
            return new Promise((resolve, reject) => setTimeout(() => resolve(data), 500));
        }).catch(error => {
            return Promise.reject(error);
        });
    };

    handleItemSelectChangeForGuests = (data) => {
        const {selectedCaseData, actionMaster} = this.props;
        if (GuestUtil.isGuestAddingRestricted(selectedCaseData, 1)) {
            this.showGuestAddingRestrictionAlertForNewGuest(selectedCaseData, actionMaster);
        }

        const newGuest = CaseUtil.getNewGuest(
            {
                id: data.id,
                firstName: data.firstName,
                lastName: data.lastName,
                phoneNumber: data.phoneNumber,
                email: data.email
            }
        );
        this.props.caseAction.addNewGuest(newGuest);
        this.setState({resultData: [], selectedGuest: null});
    };

    /***
     * @description: Email specific
     */
    showGuestListEmailPopup = () => {
        this.props.actionMaster.showPopupOnSend(null);
    };

    /**
     * @description: Delete guest popup helper
     */
    guestName = (guestIndex, selectedCaseData) => {
        let name = "";
        if ((guestIndex >= 0) && AppUtil.isAvailable(selectedCaseData.guests)) {
            name = selectedCaseData.guests[guestIndex].firstName;
        }
        return name;
    };

    deleteGuestMessageTitle = (guestIndex, selectedCaseData) => {
        return `${Utility.getLang().infoMessages.popup.deleteGuest} ${this.guestName(guestIndex, selectedCaseData)}?`
    };

    render = () => {
        const {selectedCaseData, guestPopup} = this.props || {};
        const {haveActiveGuests, selectedGuest} = this.state;
        // console.log("GUESTS", selectedCaseData.guests);
        return (
            <React.Fragment>
                <PopupForGuestAddingRestriction/>
                {
                    this.state.showGuestList &&
                    <PopupForGuestDeletion
                        headerInfo={this.deleteGuestMessageTitle(guestPopup.index, selectedCaseData)}
                        onConfirmation={() => this.deleteGuest(false, guestPopup.index, 'isActive')}
                    />
                }
                {
                    this.props.displayNothing === false ?
                        <div className="section section--detail section--scroll" ref={node => this.node = node}>
                            <CaseHeaderSection wsLockCaseData={this.props.wsLockCaseData}
                                               selectedCaseData={selectedCaseData}/>
                            {
                                this.state.showGuestList === true ?
                                    <div className="section__content section__content--guests"
                                         disabled={(LockCaseUtil.isDisabledView(this.props.disabledView))}>
                                        {
                                            AppUtil.isAvailable(selectedCaseData.guestCountInfo) && AppUtil.isAvailable(selectedCaseData.guests) && haveActiveGuests > 0 ?
                                                <GuestsOverview
                                                    guestCountInfo={selectedCaseData.guestCountInfo}/> : null
                                        }
                                        <div className="inner">
                                            <div className="btn-toolbar nowrap">
                                                <div
                                                    className="btn-toolbar btn-toolbar--max_width btn-toolbar--actions">
                                                    <AsyncSelect
                                                        inputId="caseGuestExistingContact"
                                                        getOptionLabel={({name}) => name}
                                                        getOptionValue={({id}) => id}
                                                        placeholder={Utility.getLang().cases.guestTabContent.addGuest}
                                                        loadOptions={this.promiseOptionForGuests}
                                                        onChange={(option) => this.handleItemSelectChangeForGuests(option)}
                                                        defaultOptions
                                                        className={"multiselect multiselect--max_width"}
                                                        classNamePrefix={"multiselect"}
                                                        noOptionsMessage={() => Utility.getLang().common.noOptionMessage}
                                                        loadingMessage={() => Utility.getLang().common.dropDownOptionLoadingMessage}
                                                        value={selectedGuest}
                                                    />
                                                    <Button
                                                        id="newGuestContactButton"
                                                        onClick={this.addGuest}
                                                        noLabel
                                                        icon="add"
                                                    />
                                                </div>
                                                <Button
                                                    icon="email"
                                                    onClick={this.showGuestListEmailPopup}
                                                >
                                                    {Utility.getLang().cases.guestTabContent.sendGuestList}
                                                </Button>
                                            </div>
                                        </div>
                                        <div className="drawer drawer--inline">
                                            {AppUtil.isAvailable(selectedCaseData.guests) && haveActiveGuests > 0 ?
                                                <div className="table__wrapper table__wrapper--guests">
                                                    <table className="fnxtable fnxtable--flat">
                                                        <thead>
                                                        <tr className="tr tr--head">
                                                            <th
                                                                className="th"
                                                                scope="col"
                                                            >
                                                                {Utility.getLang().cases.guestTabContent.name}
                                                            </th>
                                                            <th
                                                                className="th"
                                                                scope="col"
                                                            >
                                                                {Utility.getLang().cases.guestTabContent.diet}
                                                            </th>
                                                            <th
                                                                className="th"
                                                                scope="col"
                                                            >
                                                                {Utility.getLang().cases.guestTabContent.registered}
                                                            </th>
                                                            <th
                                                                className="th"
                                                                scope="col"
                                                            >
                                                                {Utility.getLang().cases.guestTabContent.confirmed}
                                                            </th>
                                                            <th
                                                                className="th th--actions th--rsvp"
                                                                scope="col"
                                                            >
                                                                {Utility.getLang().cases.guestTabContent.rsvp}
                                                            </th>
                                                            <th
                                                                className="th th--actions"
                                                                scope="col"
                                                            ></th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            AppUtil.isAvailable(selectedCaseData.guests) && selectedCaseData.guests.length > 0 ?
                                                                // eslint-disable-next-line
                                                                selectedCaseData.guests.map((guest, i) => {
                                                                    if (guest.isActive === true) {
                                                                        return (
                                                                            <tr
                                                                                key={i}
                                                                                className="tr tr--button"
                                                                                tabIndex="0"
                                                                                onClick={e => {
                                                                                    console.log("target", e.target.value)
                                                                                    if (e.target.value === undefined) {
                                                                                        this.editGuest(guest, i);
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <td className="td td--name td--title">
                                                                                    <h5 className={`title ${!guest.firstName ? 'is-placeholder' : ''}`}>
                                                                                        <span className="count">
                                                                                            {guest.additionalGuests + 1}
                                                                                        </span>
                                                                                        <span>
                                                                                            {guest.firstName ?
                                                                                                `${guest.firstName} ${guest.lastName === null ? "" : guest.lastName} ${guest.additionalGuests > 0 ? `(+${guest.additionalGuests})` : ''}`
                                                                                                :
                                                                                                Utility.getLang().cases.guestTabContent.untitledGuest
                                                                                            }
                                                                                        </span>
                                                                                    </h5>
                                                                                </td>
                                                                                <td className="td td--diet">
                                                                                    <ul className="inline_list">
                                                                                        {
                                                                                            guest.foodRestrictions.map((foodRestriction) => {
                                                                                                let restrictionData = foodRestriction.number.toString();
                                                                                                if (foodRestriction.restrictions.length > 0) {
                                                                                                    // eslint-disable-next-line
                                                                                                    foodRestriction.restrictions.map((restriction, i) => {
                                                                                                        if (i === 0) {
                                                                                                            restrictionData = restrictionData + " " + restriction.name;
                                                                                                        } else {
                                                                                                            restrictionData = restrictionData + ` ${Utility.getLang().common.and} ` + restriction.name;
                                                                                                        }
                                                                                                    });
                                                                                                } else {
                                                                                                    restrictionData = '';
                                                                                                }

                                                                                                return <li
                                                                                                    className="inline_list__item">
                                                                                                        <span
                                                                                                            className="description">
                                                                                                            {restrictionData}
                                                                                                        </span>
                                                                                                </li>
                                                                                            })
                                                                                        }
                                                                                    </ul>
                                                                                </td>
                                                                                <td className="td td--registered">
                                                                                    {AppUtil.isAvailable(guest.registeredDateText) ? guest.registeredDateText : '–'}
                                                                                </td>
                                                                                <td className="td td--confirmed">
                                                                                    {AppUtil.isAvailable(guest.confirmationDateText) ? guest.confirmationDateText : '–'}
                                                                                </td>
                                                                                <td className="td td--actions td--rsvp">
                                                                                    <div
                                                                                        onClick={(e) => this.onClickSelect(e)}
                                                                                        className="btn-toolbar nowrap"
                                                                                    >
                                                                                        <Select
                                                                                            inputId={'rsvp' + i}
                                                                                            options={CaseUtil.getRsvpMethods()}
                                                                                            className={"multiselect multiselect--min_width"}
                                                                                            classNamePrefix={"multiselect"}
                                                                                            onChange={(e) => this.changeGuestDetails(e, i, 'rsvp', guest.rsvp)}
                                                                                            value={GuestUtil.selectedRsvp(CaseUtil.getRsvpMethods(), guest.rsvp)}
                                                                                        />
                                                                                        <GuestMailManager
                                                                                            componentIdentifier={CaseUtil.GuestComponents.guest}
                                                                                            guest={guest}
                                                                                            guestIndexToEdit={i}
                                                                                        />
                                                                                    </div>
                                                                                </td>
                                                                                <td className="td td--actions">
                                                                                    <div
                                                                                        onClick={(e) => this.onClickSelect(e)}
                                                                                        className="btn-toolbar nowrap align_right"
                                                                                    >
                                                                                        <Button
                                                                                            onClick={() => PopupForGuestDeletion.showDeleteGuestPopup(i, this.props.actionMaster)}
                                                                                            tooltip={Utility.getLang().cases.guestTabContent.deleteGuest}
                                                                                            isTertiary
                                                                                            isDestructive
                                                                                            noLabel
                                                                                        />
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    }
                                                                })
                                                                : null
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                : null
                                            }
                                        </div>
                                    </div>
                                    : null
                            }
                            {
                                this.state.showEditGuestForm === true || this.state.showAddGuestForm === true ?
                                    this.state.guestIndexForEdit !== null ?
                                        <CaseEditGuestComponent
                                            guest={selectedCaseData.guests[this.state.guestIndexForEdit]}
                                            backToGuestList={this.goToGuestList}
                                            deleteGuest={(value, field) => this.deleteGuestFromEdit(value, this.state.guestIndexForEdit, field)}
                                            onChangeData={(event, field, rsvp, additionalGuests) => this.changeGuestDetails(event, this.state.guestIndexForEdit, field, rsvp, additionalGuests)}
                                            onChangeFoodRestrictionData={(value) => this.changeGuestFoodRestrictionDetails(this.state.guestIndexForEdit, value)}
                                            guestIndexToEdit={this.state.guestIndexForEdit}
                                        />
                                        : null
                                    : null
                            }
                            <PopupForEmailSend
                                openModal={this.props.openOnSend}
                                onConfirmation={this.onConfirmSend}
                                closePopup={this.hidePopupOnCancel}
                                source={'case'}
                                sendCaseEmailSummary={(relativeId, data) => this.sendEmailSummary(relativeId, data)}
                            />
                        </div> : <DisplayNothing/>
                }
            </React.Fragment>
        )
    }
}

CaseGuestsComponent.propTypes = {
    columnDetailViewTabCollection: PropTypes.array,
};

function mapStateToProps(state) {
    return {
        selectedCaseData: state.application.selectedCaseData,
        initialValues: state.application.selectedCaseData,
        selectedDataById: state.application.selectedDataById,
        openPopupForRelativeOnly: state.application.openPopupForRelativeOnly,

        open: state.application.open,
        openOnSend: state.application.openOnSend,
        openOnSaveCase: state.application.openOnSaveCase,
        openOnOrderDrag: state.application.openOnOrderDrag,
        guestPopup: state.application.guestPopup,

        allUserDetailsForCases: state.application.allUserDetailsForCases,
        caseAllStages: state.application.caseAllStages,
        caseAllLabels: state.application.caseAllLabels,
        allRelationshipInfo: state.application.allRelationshipInfo,
        headerMenuItemClicked: state.application.headerMenuItemClicked,
        displayNothing: state.application.displayNothing,
        caseUpdated: state.application.caseUpdated,
        tempNote: state.application.tempNote,

        wsLockCaseData: state.application.wsLockCaseData,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: "CaseGuestsComponent",
    enableReinitialize: true,
    validate: FormValidator({
        name: {presence: true},
    })
})(CaseGuestsComponent));
