import React from "react";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import Icon from "../common/icon";
import {bindActionCreators} from "redux";
import * as actionMaster from "../../actions/uiAction";
import * as caseAction from "../../actions/caseAction";
import Popup from "../common/popups/popup";
import Button from "../common/button";
import Tooltip from "../common/tooltip";
import {reduxForm} from "redux-form";
import CaseHeaderSection from "./caseHeaderSection";
import Utility from "../../api/utilLanguage";
import DisplayNothing from "../common/nothingToDisplay";
import PopupForEmailSend from "../common/popups/popupForEmailSend";
import FormValidator from "../common/formValidator";
import CaseUtil, {updateCountryOfObject} from "../common/caseUtil";
import CaseGuestFoodRestriction from "./caseGuestFoodRestrictions";
import NPSSurveyTable from "../common/nps/npsSurveyTable";
import AppUtil, {ArrayUtil} from "../common/appUtil";
import ValidationUtil, {
    allowNumberAndDash,
    allowNumberAndSpace,
    checkPersonNumberFormat,
    checkPostalCodeFormat,
    checkValidation,
    getNoSpaceError,
    getPersonNumberFormatError,
    getPostalCodeFormatError,
    ValidationErrors
} from "../common/validationUtil";
import _, {cloneDeep} from "lodash";
import PopupForGuestAddingRestriction from "../common/popups/components/popupForGuestAddingRestriction";
import GuestUtil from "./caseGuestsSubComponents/guestUtil";
import Select from "react-select";
import RelativeUtil, {getNewContact} from "./relative/relativeUtil";
import ValidationAndVisibilityRule, {
    isFieldEnabled,
    sparLinkVisible,
    validationRule
} from "../common/ValidationAndVisibilityRule";
import DatePicker from "react-datepicker";
import DateUtilExt, {TimestampFormat} from "../common/dateUtil/dateUtilExt";
import LockCaseUtil from "./lockCase/util/lockCaseUtil";
import {datePickerCustomInput} from "../common/datePicker/datePickerUtil";
import CreatableSelect from "react-select/lib/Creatable";
import FormFieldForLogin from "../common/formFieldForLogin";
import Field from "redux-form/lib/Field";
import SharedFuneralAccessLevel from "./relative/sharedFuneralAccessLevel";
import ConsigneeCompanyName from "./relative/consigneeCompanyName";

const UK_CONTACT_PROPERTIES = "ukContactProperties";
const UK_PAYMENT_METHOD = "ukPaymentMethod";
const UK_NATIONAL_INSURANCE_NUMBER = "nationalInsuranceNumber";
const UK_FINANCIAL_ASSISTANCE_DETAIL = "financialAssistanceDetails";
const CUSTOMER_ACCOUNT_ID = 0;

function DescriptionWithLink({id, title}) {
    return (id !== null) && (id !== undefined) && (id !== CUSTOMER_ACCOUNT_ID)
        ? <div className="form-group form-group--static">
            <p>{title}</p>
            <p className="description">
                <a target="_blank">{id}</a>
            </p>
        </div>
        : null;
}

class CaseRelativesComponent extends React.Component {

    constructor(props) {
        super(props);
        this.removedRelativeData = {};
        this.removedRelativeIndex = "";
        this.newRelativeId = -1;
        this.backOrDeleteKeyPress = false;
        this.relativeIdModified = null;
        this.ukPaymentMethodOptions = CaseUtil.getDropDownOptionsAsValueAndLabel(CaseUtil.getUKPaymentMethodOptions());
        this.state = {
            disablePersonButton: true,
            relativesDisabledButton: [],
            relativeData: [],
            requiredFieldHasChanged: false,
            orderId: null,
            caseRelativeId: null
        };
    }

    addingNewCaseWithRelative() {
        let stageId = "";
        if (this.props.headerMenuItemClicked === AppUtil.linkPaths.case.pathToRoute) {
            stageId = 0;
        } else {
            stageId = this.props.selectedCard.stage.id;
        }
        if (AppUtil.isEmpty(this.props.selectedCaseData.id)) {
            let contactInitialData = cloneDeep(CaseUtil.contactInitialData);
            updateCountryOfObject(contactInitialData, this.props.countries);
            let data = {
                id: 0,
                todos: [],
                relatives: [getNewContact(contactInitialData, this.newRelativeId)],
                stage: stageId
            };
            this.relativeIdModified = this.newRelativeId;
            let relativesAll = data.relatives;
            this.setState({relativeData: relativesAll});
            this.props.caseAction.newFormForRelative(data);
        }
    }

    componentDidUpdate = (prevProps) => {
        if (JSON.stringify(prevProps.selectedCaseData) !== JSON.stringify(this.props.selectedCaseData)) {
            const errorsInfo = ValidationErrors.info(this.props.selectedCaseData);
            if (ValidationErrors.isAvailable(errorsInfo)) {
                this.props.caseAction.updateValidationErrorsInfo(errorsInfo);
            } else {
                this.props.caseAction.updateValidationErrorsInfo({});
            }
        }
        if ((prevProps.countries.length !== this.props.countries.length)) {
            this.addingNewCaseWithRelative();
        }
    };

    componentDidMount = () => {
        this.addingNewCaseWithRelative();
        this.props.caseAction.getAllRelationshipDetails();
        this.props.caseAction.getAllUserDetails();
        this.props.caseAction.getAllFoodRestrictions();

        if ((this.props.counties.length === 0) && isFieldEnabled(ValidationAndVisibilityRule.common, ValidationAndVisibilityRule.county)) {
            const countyParentId = RelativeUtil.getCountyParentId();
            this.props.caseAction.getAllCounties(countyParentId);
        }

        if (AppUtil.isAvailable(this.props.selectedCaseData.relatives)) {
            const relativesData = cloneDeep(this.props.selectedCaseData.relatives);
            let disabledArray = [];
            for (let i = 0; i < relativesData.length; i++) {
                let checkPersonNumber = ValidationUtil.isValidPersonNumber(this.props.selectedCaseData.relatives[i].personNumber);
                if (checkPersonNumber === true) {
                    let obj = {"disabled": false};
                    disabledArray[i] = obj;
                }
                if (relativesData[i].id < 0) {
                    this.newRelativeId = this.newRelativeId - 1;
                }
            }
            this.setState({
                relativesDisabledButton: disabledArray,
                relativeData: relativesData
            });
        }
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        const newCaseData = JSON.stringify(nextProps.selectedCaseData.relatives);
        const oldCaseData = JSON.stringify(this.props.selectedCaseData.relatives);
        if (newCaseData !== oldCaseData) {
            //Handles UnfilledContact
            this.setState({relativeData: nextProps.selectedCaseData.relatives});
            this.handleRequiredFieldInlineValidation(nextProps, this.props);
        }
    };

    /**
     * @description: Inline validation helper
     * */

    handleRequiredFieldInlineValidation = (nextProps, props) => {
        if (AppUtil.isAvailable(this.relativeIdModified)) {
            let newRelative = _.find(nextProps.selectedCaseData.relatives, (relative) => {
                return relative.id === this.relativeIdModified;
            });

            let oldRelative = _.find(props.selectedCaseData.relatives, (relative) => {
                return relative.id === this.relativeIdModified;
            });

            if (AppUtil.isAvailable(newRelative) && AppUtil.isAvailable(oldRelative) && (JSON.stringify(newRelative) !== JSON.stringify(oldRelative))) {
                //Directly changed redux object
                if (RelativeUtil.isEmptyRelative(newRelative) === false) {
                    newRelative.isModified = true;
                    this.showInlineValidationError(true);
                } else {
                    newRelative.isModified = false;
                    this.showInlineValidationError(false);
                }
            }
        }
    };

    showInlineValidationError = (value) => {
        this.updateRequiredFieldHasChanged(value);
    };

    updateRequiredFieldHasChanged = (value) => {
        this.setState({requiredFieldHasChanged: value});
    };

    addNewRelative = () => {
        let data = cloneDeep(CaseUtil.contactInitialData);
        if (this.props.selectedCaseData.relatives.length === 0) {
            data = getNewContact(data, this.newRelativeId);
            this.relativeIdModified = data.id;
        } else {
            data.id = this.newRelativeId;
        }
        updateCountryOfObject(data, this.props.countries);
        this.newRelativeId = this.newRelativeId - 1;
        let relativesAll = cloneDeep(this.props.selectedCaseData.relatives);
        relativesAll.push(data);
        this.setState({relativeData: relativesAll});
        this.props.caseAction.newFormForRelative(data);
    };

    editRelativeDetails = (data, e, i, fieldName, id) => {
        this.relativeIdModified = data.id;
        switch (fieldName) {
            case "firstName":
                data.firstName = e.target.value;
                this.changeRelName(data, e, i);
                break;
            case "lastName":
                data.lastName = e.target.value;
                this.changeRelName(data, e, i);
                break;
            case "phoneNumber":
                this.changeRelPhoneNumber(data, e, i);
                break;
            case "email":
                this.changeRelEmail(data, e, i);
                break;
            case "relationship":
                this.changeRelationship(data, e, i);
                break;
            case "comment":
                this.changeRelComment(data, e, i);
                break;
            case "personNumber":
                this.changeRelPin(data, e, i);
                break;
            case "address":
            case "address2":
                this.changeRelAddress(data, e, i, fieldName);
                break;
            case "postalCode":
                this.changeRelPostalcode(data, e, i);
                break;
            case "city":
                this.changeRelCityName(data, e, i);
                break;
            case "isBillingContact":
                this.changeIsBillingContactStatus(data, e, i);
                this.props.caseAction.clearUKPaymentDetails(data.id);
                break;
            case "isMainContact":
                this.changeIsMainContactStatus(data, e, i);
                this.props.caseAction.clearUKPaymentDetails(data.id);
                break;
            case "isGuest":
                this.changeIsGuestStatus(data, e, i, 0);
                break;
            case "country":
                this.changeCountry(data, e, i);
                break;
            case "county":
                this.changeCounty(data, e, i);
                break;
            case "mobileNumber":
                this.changeRelMobileNumber(data, e, i);
                break;
            case "dateOfBirth":
                this.dateOfBirthChange(data, e, i);
                break;
            case UK_CONTACT_PROPERTIES:
                this.setUkContactProperties(data, e, i, id);
                break;
            case UK_PAYMENT_METHOD:
                this.changeUKPaymentMethod(data, e, i);
                break;
            case UK_NATIONAL_INSURANCE_NUMBER:
                this.changeNationalInsuranceNumber(data, e, i);
                break;
            case UK_FINANCIAL_ASSISTANCE_DETAIL:
                this.changeFinancialAssistanceDetails(data, e, i);
                break;
            default:
                break;
        }
    };

    changeRelName = (data, e, i) => {
        this.props.caseAction.changeRelName(data, i);
    };

    checkKeys = (e) => {
        if (e.keyCode === 8 || e.keyCode === 46) {
            this.backOrDeleteKeyPress = true;
        }
    };

    changeRelDetailLocal = (data, e, i, valueToChange) => {
        if (valueToChange === "address" || valueToChange === "address2" || valueToChange === "postalCode" || valueToChange === "city") {
            data.address[valueToChange] = valueToChange === "postalCode" ? e.target.value.toUpperCase() : e.target.value;
        } else {
            if (valueToChange === "personNumber") {
                let value = e.target.value;
                if (e.target.value.length === 8 && !this.backOrDeleteKeyPress) {
                    value = value + "-";
                } else {
                    this.backOrDeleteKeyPress = false;
                }
                data[valueToChange] = value;
            } else {
                data[valueToChange] = e.target.value;
            }
        }
        let relativesAll = this.state.relativeData;
        relativesAll.forEach((relatives, index) => {
            if (index === i) {
                relatives = data;
            }
        });
        this.setState({relativeData: relativesAll});
    };

    changeRelPhoneNumber = (data, e, i) => {
        data.phoneNumber = e.target.value;
        this.props.caseAction.changeRelPhoneDetails(data, i);
    };

    changeRelMobileNumber = (data, e, i) => {
        data.mobile = e.target.value;
        this.props.caseAction.changeRelMobileDetails(data, i);
    };

    changeRelEmail = (data, e, i) => {
        data.email = e.target.value;
        this.props.caseAction.changeRelEmailDetails(data, i);
    };

    changeRelationship = (data, e, i) => {
        data.relationshipId = e.value !== "0" ? e.value : e.label;
        this.props.caseAction.setRelationshipId(data, i);
    };

    changeCountry = (data, e, i) => {
        data.address.country = e;
        this.props.caseAction.setCountryId(data, i);
    };

    changeCounty = (data, e, i) => {
        data.address.county = e;
        this.props.caseAction.setCountyId(data, i);
    };

    changeRelComment = (data, e, i) => {
        data.comment = e.target.value;
        this.props.caseAction.setRelComment(data, i);
    };

    changeRelPin = (data, e, i) => {
        data.personNumber = e.target.value;
        let checkPersonNumber = ValidationUtil.isValidPersonNumber(e.target.value);
        let disabledArray = this.state.relativesDisabledButton;
        if (checkPersonNumber === true) {
            let obj = {"disabled": false};
            disabledArray[i] = obj;
            this.setState({relativesDisabledButton: disabledArray});
        } else {
            let obj = {"disabled": true};
            disabledArray[i] = obj;
            this.setState({relativesDisabledButton: disabledArray});
        }
        this.props.caseAction.setRelPin(data, i);
    };

    changeRelAddress = (data, e, i, field) => {
        if (field === "address2") {
            data.address.address2 = e.target.value;
            this.props.caseAction.setRelAddress(data, i, field);
        } else {
            data.address.address = e.target.value;
            this.props.caseAction.setRelAddress(data, i, field);
        }
    };

    changeRelPostalcode = (data, e, i) => {
        data.address.postalCode = e.target.value.toUpperCase();
        this.props.caseAction.setRelpostalCode(data, i);
    };

    dateOfBirthChange = (data, e, i) => {
        const dateStr = DateUtilExt.dateToUTCString(e);
        data.dateOfBirth = dateStr;
        this.props.caseAction.setRelDateOfBirth(data, i);
    };

    changeRelCityName = (data, e, i) => {
        data.address.city = e.target.value;
        this.props.caseAction.setRelplaceName(data, i);
    };

    changeIsBillingContactStatus = (data, e, i) => {
        data.isBillingContact = e.target.checked;
        this.props.caseAction.setRelBillingContactStatus(data, i);
        this.forceUpdate();
    };

    changeIsMainContactStatus = (data, e, i) => {
        data.isPrimary = e.target.checked;
        this.props.caseAction.setRelIsPrimaryContactStatus(data, i);
    };

    changeIsGuestStatus = (data, e, i, additionalGuests) => {
        const {selectedCaseData, actionMaster} = this.props;
        if (e.target.checked && GuestUtil.isGuestAddingRestricted(selectedCaseData, 1)) {
            GuestUtil.showAlert(selectedCaseData.guestCountInfo, additionalGuests, 0, 1, actionMaster);
        } else {
            data.isGuest = e.target.checked;
            data.rsvp = CaseUtil.rsvp.yes;
            data.additionalGuests = 0;
            data.isActive = true;
            this.props.caseAction.setRelIsGuestStatus(data, i);
        }
    };

    removeRelative = (data, i) => {
        this.removedRelativeData = data;
        this.removedRelativeIndex = i;
        this.props.caseAction.showPopupForRemoveRelative();
    };

    changeGuestDetails = (event, guestIndex, fieldName, rsvp, additionalGuests) => {
        const {selectedCaseData, actionMaster} = this.props;
        const newAdditionalGuests = (fieldName === "rsvp") ? event.value : event.target.value;

        if (GuestUtil.onChangeOfAdditionalGuests(fieldName, rsvp) && GuestUtil.isGuestAddingRestrictedOnAdditionalGuestChange(selectedCaseData, additionalGuests, parseInt(newAdditionalGuests, 10))) {
            GuestUtil.showAlert(selectedCaseData.guestCountInfo, additionalGuests, parseInt(newAdditionalGuests, 10), 0, actionMaster);
        }
        this.props.caseAction.changeGuestDetails(newAdditionalGuests, guestIndex, fieldName);
    };

    onRelativeDelete = () => {
        let relativesAll = this.props.selectedCaseData.relatives;
        this.props.selectedCaseData.relatives.forEach((relative, i) => {
            if (relative.id === this.removedRelativeData.id && i === this.removedRelativeIndex) {
                relativesAll.splice(i, 1);
            }
        });
        this.setState({relativeData: relativesAll});
        this.props.caseAction.removeRelativeInfo(this.removedRelativeData, this.removedRelativeIndex);
        this.hidePopup();
    };

    changeGuestFoodRestrictionDetails = (guestIndex, value) => {
        this.props.caseAction.changeGuestFoodRestrictionDetails(guestIndex, value);
    };

    hidePopupOnCancel = () => {
        this.props.actionMaster.hidePopupSection();
    };

    hidePopup = () => {
        this.props.actionMaster.hidePopupSection();
    };

    customerWebAppOrderAccessLevelPopup = () => {
        this.props.actionMaster.hideRevokeAccessLevelPopup();
    };

    sendEmailSummary = (relativeId, bodyData, emailTemplateType) => {
        // Sending the email
        bodyData.caseId = this.props.selectedCaseData.id;
        this.props.caseAction.sendEmailSummary(this.props.selectedCaseData.id, bodyData);
    };

    getDetailsByPersonNumber = (event, relative, index) => {
        event.preventDefault();
        this.props.caseAction.getDetailsByPersonNumberOfRelative(relative, index);
    };

    findRelationshipObject = (relationshipId) => {
        return this.props.allRelationshipInfo.filter(obj => obj.key === relationshipId);
    };

    setUkContactProperties = (data, e, i, id) => {
        data.isUkPropChecked = e.target.checked;
        data.ukPropId = id;
        this.props.caseAction.setUkContactProperties(data, i);
    };

    changeUKPaymentMethod = (data, e, i) => {
        let relatives = this.state.relativeData;
        if (AppUtil.isAvailable(e)) {
            if (e.label === e.value) {
                e.value = "0";
            }
            relatives[i].ukPaymentMethod = e.value !== "0" ? e.value : e.label;
        } else {
            relatives[i].ukPaymentMethod = null;
        }
        this.setState({relativeData: relatives});
        this.props.caseAction.setUKPaymentMethod(data, i);
    };

    changeNationalInsuranceNumber = (data, e, i) => {
        data.nationalInsuranceNumber = e.target.value;
        this.props.caseAction.setRelNationalInsuranceNumber(data, i);
    };

    changeFinancialAssistanceDetails = (data, e, i) => {
        data.financialAssistanceDetails = e.target.value;
        this.props.caseAction.setRelFinancialAssistanceDetails(data, i);
    };

    renderRelativePostalCode(relativeLocal, i, selectedCaseData, property) {
        const relative = selectedCaseData.relatives[i];
        const isPostalCodeValidationRequired = ValidationUtil.isRequiredPostalCodeValidation(relative.address.country.countryCode);
        const postalCode = AppUtil.isAvailable(relative.address) && AppUtil.isAvailable(relative.address.postalCode) ? relative.address.postalCode : null;
        return (
            <div className={`form-group__column`}>
                <label htmlFor={property + i}>
                    {Utility.getLang().cases.relativeTabContent.postalCode}
                </label>
                <input
                    id={property + i}
                    name={property}
                    placeholder={Utility.getLang().cases.relativeTabContent.postalCode}
                    className={`text text--zip ${isPostalCodeValidationRequired ? checkValidation(postalCode) + checkPostalCodeFormat(postalCode) : ""}`}
                    type="text"
                    onKeyPress={(event) =>
                        isPostalCodeValidationRequired
                            ? validationRule(ValidationAndVisibilityRule.common, ValidationAndVisibilityRule.postCode).onlyDigits === "true" ? allowNumberAndSpace(event) : ValidationUtil.allowNumberAndAlphabets(event)
                            : ""
                    }
                    onChange={(e) => this.changeRelDetailLocal(relativeLocal, e, i, property)}
                    onBlur={(e) => this.editRelativeDetails(relativeLocal, e, i, property)}
                    value={AppUtil.isAvailable(relativeLocal.address) && (relativeLocal.address.postalCode !== null) ? relativeLocal.address.postalCode : ""}
                    autoComplete="off"
                    data-lpignore="true"
                />
                {
                    isPostalCodeValidationRequired
                        ? checkValidation(postalCode) !== "" ? getNoSpaceError() : checkPostalCodeFormat(postalCode) !== "" ? getPostalCodeFormatError() : null
                        : ""
                }
            </div>
        );
    }

    renderRelativeCity(relativeLocal, i, selectedCaseData, property) {
        const relative = selectedCaseData.relatives[i];
        const city = AppUtil.isAvailable(relative.address) && AppUtil.isAvailable(relative.address.city) ? relative.address.city : null;
        return (
            <div className={`form-group__column`}>
                <label htmlFor={property + i}>
                    {Utility.getLang().cases.relativeTabContent.city}
                </label>
                <input
                    id={property + i}
                    name={property}
                    placeholder={Utility.getLang().cases.relativeTabContent.city}
                    className={"text text--max_width " + checkValidation(city)}
                    type="text"
                    onChange={(e) => this.changeRelDetailLocal(relativeLocal, e, i, property)}
                    onBlur={(e) => this.editRelativeDetails(relativeLocal, e, i, property)}
                    value={AppUtil.isAvailable(relativeLocal.address) && (relativeLocal.address.city !== null) ? relativeLocal.address.city : ""}
                    autoComplete="off"
                    data-lpignore="true"/>
                {
                    checkValidation(city) !== "" ? getNoSpaceError() : null
                }
            </div>
        );
    }

    renderGuestDetails(guest, i) {
        return (
            <div className="card__content">
                <h3 className="section__title">
                    {Utility.getLang().cases.relativeTabContent.guestDetails}
                </h3>
                <div
                    className="form-group form-group--columns form-group--columns--fluid">
                    <div className="form-group__column">
                        <label htmlFor={"additionalGuests" + i}>
                            {Utility.getLang().cases.guestTabContent.plusOnes}
                        </label>
                        <div
                            className="field field--min_width no_label has-unit">
                            <input
                                id={"additionalGuests" + i}
                                type="number"
                                min={0}
                                className="text text--min_width"
                                onKeyPress={(e) => AppUtil.restrictMinus(e)}
                                value={guest.additionalGuests}
                                onChange={(e) =>
                                    this.changeGuestDetails(e, i, "additionalGuests", guest.rsvp, guest.additionalGuests)}
                                data-tip/>
                            <Tooltip>
                                {Utility.getLang().common.unitFull.people}
                            </Tooltip>
                            <p className="field__unit">
                                {Utility.getLang().common.unit.people}
                            </p>
                        </div>
                    </div>
                    <div className="form-group__column">
                        <label htmlFor={"rsvp" + i}>
                            {Utility.getLang().cases.guestTabContent.rsvp}
                        </label>
                        <Select
                            inputId={"rsvp" + i}
                            options={CaseUtil.getRsvpMethods()}
                            className={"multiselect multiselect--min_width"}
                            classNamePrefix={"multiselect"}
                            onChange={(e) => this.changeGuestDetails(e, i, "rsvp")}
                            value={GuestUtil.selectedRsvp(CaseUtil.getRsvpMethods(), guest.rsvp)}/>
                    </div>
                </div>
                <div className="form-group">
                    <CaseGuestFoodRestriction
                        guestFoodRestrictions={guest.foodRestrictions}
                        editFoodRestriction={(value) => this.changeGuestFoodRestrictionDetails(i, value)}
                        guestIndexToEdit={i}/>
                </div>
            </div>
        );
    }

    changeAccessLevel = () => {
        const {id, orderId, caseRelativeId} = this.state;
        if ((id !== null) && (orderId !== null) && (caseRelativeId !== null)) {
            this.revokeAccess(caseRelativeId, id);
            this.props.caseAction.changeAccessLevel(id, caseRelativeId, orderId);
            this.props.actionMaster.hideRevokeAccessLevelPopup();
        }
    }

    revokeAccessLevel = (id, orderId, caseRelativeId) => {
        this.props.actionMaster.showHideRevokeAccessLevelPopup();
        this.setState({
            id: id,
            orderId: orderId,
            caseRelativeId: caseRelativeId
        });
    }

    render = () => {
        const {selectedCaseData, countries} = this.props;
        const requiredFieldIcon = "field-required";
        let isMainOrBillingContact = false;
        return (
            <React.Fragment>
                <PopupForGuestAddingRestriction/>
                <Popup
                    openModal={this.props.customerWebAppOrderAccessLevelPopup}
                    headerInfo={Utility.getLang().cases.relativeTabContent.deletePopupTitle}
                    onConfirmation={this.changeAccessLevel}
                    closePopup={this.customerWebAppOrderAccessLevelPopup}
                    isDestructive
                />
                {
                    this.props.displayNothing === false ?
                        <div className="section section--detail section--scroll" ref={node => this.node = node}>
                            <CaseHeaderSection wsLockCaseData={this.props.wsLockCaseData}
                                               selectedCaseData={selectedCaseData}/>
                            <div className="section__content section__content--contacts drawer"
                                 disabled={(LockCaseUtil.isDisabledView(this.props.disabledView))}>
                                <div className="inner">
                                    {
                                        selectedCaseData !== undefined && selectedCaseData.relatives !== undefined && selectedCaseData.relatives.length > 0 ?
                                            this.state.relativeData.map((relative, i) => {
                                                isMainOrBillingContact = relative.isPrimary || relative.isBillingContact;
                                                return (
                                                    <div className="card" key={i}>
                                                        <form>
                                                            <div className="card__head">
                                                                <h3 className="card__title">
                                                                    <Icon type="user"/>
                                                                    {AppUtil.hasName(relative) ?
                                                                        <span className="description">
                                                                            {AppUtil.concatenateFullName(relative)}
                                                                        </span>
                                                                        :
                                                                        <span
                                                                            className="description is-placeholder">
                                                                            {Utility.getLang().cases.relativeTabContent.untitledContact}
                                                                        </span>
                                                                    }
                                                                    {relative.personNumber ?
                                                                        <span className="detail">
                                                                            {relative.personNumber}
                                                                        </span>
                                                                        : null}
                                                                </h3>
                                                                <div className="btn-toolbar">
                                                                    <div className="option option--radio">
                                                                        <input
                                                                            id={"isPrimary" + i}
                                                                            name="isPrimary"
                                                                            checked={relative.isPrimary}
                                                                            onChange={(e) => this.editRelativeDetails(relative, e, i, "isMainContact")}
                                                                            type="radio"/>
                                                                        <label
                                                                            htmlFor={"isPrimary" + i}
                                                                            className="option__description option__description--radio">
                                                                            {Utility.getLang().cases.relativeTabContent.mainContact}
                                                                        </label>
                                                                    </div>
                                                                    <div className="option option--checkbox">
                                                                        <input
                                                                            id={"isBillingContact" + i}
                                                                            name="isBillingContact"
                                                                            value={relative.isBillingContact}
                                                                            checked={relative.isBillingContact}
                                                                            onChange={(e) => this.editRelativeDetails(relative, e, i, "isBillingContact")}
                                                                            type="checkbox"
                                                                            disabled={!relative.isDeletable}/>
                                                                        <label
                                                                            htmlFor={"isBillingContact" + i}
                                                                            className="option__description option__description--checkbox">
                                                                            {Utility.getLang().cases.relativeTabContent.useForBillingContact}
                                                                        </label>
                                                                    </div>
                                                                    <div className="option option--checkbox">
                                                                        <input
                                                                            id={"isGuest" + i}
                                                                            name="isGuest"
                                                                            checked={relative.isGuest}
                                                                            onChange={(e) => this.editRelativeDetails(relative, e, i, "isGuest")}
                                                                            type="checkbox"/>
                                                                        <label
                                                                            htmlFor={"isGuest" + i}
                                                                            className="option__description option__description--checkbox">
                                                                            {Utility.getLang().cases.relativeTabContent.guest}
                                                                        </label>
                                                                    </div>
                                                                    {
                                                                        isFieldEnabled(ValidationAndVisibilityRule.relative, ValidationAndVisibilityRule.ukContactProperties) ?
                                                                            <div className="form-group options">
                                                                                {
                                                                                    CaseUtil.getUkContactProperties().map((option, j) => {
                                                                                        return (
                                                                                            <div key={option.id + j}
                                                                                                 className="option option--inline option--checkbox ">
                                                                                                <Field
                                                                                                    id={option.name + i}
                                                                                                    name={option.name}
                                                                                                    checked={(AppUtil.isAvailable(relative.ukContactProperties) && Array.isArray(relative.ukContactProperties)) ? ArrayUtil.includesObject(option.id, "id", relative.ukContactProperties) : false}
                                                                                                    value={option.id}
                                                                                                    onChange={(e) => this.editRelativeDetails(relative, e, i, UK_CONTACT_PROPERTIES, option.id)}
                                                                                                    component={FormFieldForLogin}
                                                                                                    type="checkbox"
                                                                                                    disabled={(AppUtil.isAvailable(relative.ukContactProperties) && Array.isArray(relative.ukContactProperties)) ? RelativeUtil.isEditable(option.id, relative.ukContactProperties) : false}
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor={option.name + i}
                                                                                                    className="option__description option__description--checkbox">
                                                                                                    {option.name}
                                                                                                </label>
                                                                                            </div>
                                                                                        );
                                                                                    })
                                                                                }
                                                                            </div> : null
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="card__content">
                                                                <div className="section__columns">
                                                                    <div className="section__column">
                                                                        {
                                                                            isFieldEnabled(ValidationAndVisibilityRule.relative, ValidationAndVisibilityRule.companyName)
                                                                                ? <ConsigneeCompanyName
                                                                                    relative={relative} index={i}/>
                                                                                : null
                                                                        }
                                                                        <div className="form-group form-group--columns">
                                                                            <div
                                                                                className={`form-group__column ${requiredFieldIcon}`}>
                                                                                <label
                                                                                    htmlFor={Utility.getLang().cases.relativeTabContent.firstName + i}>
                                                                                    {Utility.getLang().cases.relativeTabContent.firstName}
                                                                                </label>
                                                                                <input
                                                                                    id={Utility.getLang().cases.relativeTabContent.firstName + i}
                                                                                    name="firstName"
                                                                                    className={`text text--max_width ${selectedCaseData.relatives[i].isModified && ValidationUtil.isInvalidName(selectedCaseData.relatives[i].firstName) ? "is-error" : ""}`}
                                                                                    type="text"
                                                                                    onChange={(e) => this.changeRelDetailLocal(relative, e, i, "firstName")}
                                                                                    onBlur={(e) => this.editRelativeDetails(relative, e, i, "firstName")}
                                                                                    value={relative.firstName !== null ? relative.firstName : ""}
                                                                                    autoComplete="off"
                                                                                    data-lpignore="true"/>
                                                                                {
                                                                                    selectedCaseData.relatives[i].isModified && ValidationUtil.isInvalidName(selectedCaseData.relatives[i].firstName) && ValidationUtil.getNameError(selectedCaseData.relatives[i].firstName, Utility.getLang().cases.relativeTabContent.firstName)
                                                                                }
                                                                            </div>
                                                                            <div
                                                                                className={`form-group__column ${requiredFieldIcon}`}>
                                                                                <label
                                                                                    htmlFor={Utility.getLang().cases.relativeTabContent.lastName + i}>
                                                                                    {Utility.getLang().cases.relativeTabContent.lastName}
                                                                                </label>
                                                                                <input
                                                                                    id={Utility.getLang().cases.relativeTabContent.lastName + i}
                                                                                    name="lastName"
                                                                                    className={`text text--max_width ${selectedCaseData.relatives[i].isModified && ValidationUtil.isInvalidName(selectedCaseData.relatives[i].lastName) ? "is-error" : ""}`}
                                                                                    type="text"
                                                                                    onChange={(e) => this.changeRelDetailLocal(relative, e, i, "lastName")}
                                                                                    onBlur={(e) => this.editRelativeDetails(relative, e, i, "lastName")}
                                                                                    value={relative.lastName !== null ? relative.lastName : ""}
                                                                                    autoComplete="off"
                                                                                    data-lpignore="true"/>
                                                                                {
                                                                                    selectedCaseData.relatives[i].isModified && ValidationUtil.isInvalidName(selectedCaseData.relatives[i].lastName) && ValidationUtil.getNameError(selectedCaseData.relatives[i].lastName, Utility.getLang().cases.relativeTabContent.lastName)
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            isFieldEnabled(ValidationAndVisibilityRule.relative, ValidationAndVisibilityRule.mobile) ?
                                                                                <div
                                                                                    className={`form-group`}>
                                                                                    <label
                                                                                        htmlFor={"caseRelativeMobile" + i}>
                                                                                        {Utility.getLang().cases.relativeTabContent.mobileNumber}
                                                                                    </label>
                                                                                    <input
                                                                                        id={"caseRelativeMobile" + i}
                                                                                        name="caseRelativeMobile"
                                                                                        className={`text text--max_width ${ValidationUtil.isInvalidMobileWithGivenRegex(selectedCaseData.relatives[i].mobileNumber, new RegExp(validationRule(ValidationAndVisibilityRule.relative, ValidationAndVisibilityRule.mobile).regex)) ? "is-error" : ""}`}
                                                                                        type="text"
                                                                                        onKeyPress={(event) => ValidationUtil.allowNumberPlusAndDash(event)}
                                                                                        onChange={(e) => this.changeRelDetailLocal(relative, e, i, "mobileNumber")}
                                                                                        onBlur={(e) => this.editRelativeDetails(relative, e, i, "mobileNumber")}
                                                                                        value={relative.mobileNumber !== null ? relative.mobileNumber : ""}
                                                                                        autoComplete="off"/>
                                                                                    {
                                                                                        ValidationUtil.isInvalidMobileWithGivenRegex(selectedCaseData.relatives[i].mobileNumber, new RegExp(validationRule(ValidationAndVisibilityRule.relative, ValidationAndVisibilityRule.mobile).regex)) && ValidationUtil.getMobileNumberError()
                                                                                    }
                                                                                </div> : null
                                                                        }
                                                                        <div
                                                                            className={`form-group`}>
                                                                            <label
                                                                                htmlFor={"caseRelativePhoneNumber" + i}>
                                                                                {Utility.getLang().cases.relativeTabContent.phoneNumber}
                                                                            </label>
                                                                            <input
                                                                                id={"caseRelativePhoneNumber" + i}
                                                                                name="caseRelativePhoneNumber"
                                                                                className={`text text--max_width text--number ${ValidationUtil.isInvalidPhoneWithGivenRegex(selectedCaseData.relatives[i].phoneNumber, new RegExp(validationRule(ValidationAndVisibilityRule.relative, ValidationAndVisibilityRule.phone).regex)) ? "is-error" : ""}`}
                                                                                type="text"
                                                                                onKeyPress={(event) => ValidationUtil.allowNumberPlusAndDash(event)}
                                                                                onChange={(e) => this.changeRelDetailLocal(relative, e, i, "phoneNumber")}
                                                                                onBlur={(e) => this.editRelativeDetails(relative, e, i, "phoneNumber")}
                                                                                value={relative.phoneNumber !== null ? relative.phoneNumber : ""}
                                                                                autoComplete="off"
                                                                                data-lpignore="true"/>
                                                                            {
                                                                                ValidationUtil.isInvalidPhoneWithGivenRegex(selectedCaseData.relatives[i].phoneNumber, new RegExp(validationRule(ValidationAndVisibilityRule.relative, ValidationAndVisibilityRule.phone).regex)) && ValidationUtil.getPhoneNumberError()
                                                                            }
                                                                        </div>
                                                                        <div
                                                                            className={`form-group`}>
                                                                            <label
                                                                                htmlFor={"caseRelativeEmail" + i}>
                                                                                {Utility.getLang().cases.relativeTabContent.email}
                                                                            </label>
                                                                            <input
                                                                                id={"caseRelativeEmail" + i}
                                                                                name="caseRelativeEmail"
                                                                                className={`text text--max_width ${ValidationUtil.isInvalidEmail(selectedCaseData.relatives[i].email) ? "is-error" : ""}`}
                                                                                type="text"
                                                                                onChange={(e) => this.changeRelDetailLocal(relative, e, i, "email")}
                                                                                onBlur={(e) => this.editRelativeDetails(relative, e, i, "email")}
                                                                                value={relative.email !== null ? relative.email : ""}
                                                                                autoComplete="off"
                                                                                data-lpignore="true"/>
                                                                            {
                                                                                ValidationUtil.isInvalidEmail(selectedCaseData.relatives[i].email) && ValidationUtil.getEmailError()
                                                                            }
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label
                                                                                htmlFor={"caseRelativeOtherDetails" + i}>
                                                                                {Utility.getLang().cases.relativeTabContent.otherDetails}
                                                                            </label>
                                                                            <textarea
                                                                                name="caseRelativeOtherDetails"
                                                                                id={"caseRelativeOtherDetails" + i}
                                                                                className="textarea textarea--max_width"
                                                                                onChange={(e) => this.changeRelDetailLocal(relative, e, i, "comment")}
                                                                                onBlur={(e) => this.editRelativeDetails(relative, e, i, "comment")}
                                                                                value={relative.comment !== null ? relative.comment : ""}/>
                                                                        </div>
                                                                        {
                                                                            isFieldEnabled(ValidationAndVisibilityRule.relative, ValidationAndVisibilityRule.ukPaymentMethod) ?
                                                                                <div
                                                                                    className={`form-group ${isMainOrBillingContact ? '' : 'hideDiv'}`}
                                                                                    id={"ukPaymentMethodGroup"}>
                                                                                    <div className={`form-group`}>
                                                                                        <label
                                                                                            htmlFor={"ukPaymentMethod" + i}>
                                                                                            {Utility.getLang().cases.relativeTabContent.ukPaymentMethod}
                                                                                        </label>
                                                                                        <CreatableSelect
                                                                                            id={"ukPaymentMethod" + i}
                                                                                            name="ukPaymentMethod"
                                                                                            className={"multiselect multiselect--max_width"}
                                                                                            classNamePrefix={"multiselect"}
                                                                                            isClearable
                                                                                            value={AppUtil.isAvailable(relative.ukPaymentMethod) ? AppUtil.transformArrayIntoCreatableSelectOptions(this.ukPaymentMethodOptions, relative.ukPaymentMethod) : null}
                                                                                            placeholder={Utility.getLang().common.dropDownOptionPleaseSelect}
                                                                                            onChange={(e) => this.editRelativeDetails(relative, e, i, UK_PAYMENT_METHOD)}
                                                                                            formatCreateLabel={e => Utility.getLang().common.dropDownCreateMessage + `"${e}"`}
                                                                                            options={CaseUtil.getDropDownOptionsAsValueAndLabel(CaseUtil.getUKPaymentMethodOptions())}>
                                                                                        </CreatableSelect>
                                                                                    </div>
                                                                                    <div className={`form-group`}>
                                                                                        <label
                                                                                            htmlFor={"nationalInsuranceNumber" + i}>
                                                                                            {Utility.getLang().cases.relativeTabContent.nationalInsuranceNumber}
                                                                                        </label>
                                                                                        <input
                                                                                            id={"nationalInsuranceNumber" + i}
                                                                                            name="nationalInsuranceNumber"
                                                                                            className={`text text--max_width`}
                                                                                            type="text"
                                                                                            onChange={(e) => this.changeRelDetailLocal(relative, e, i, UK_NATIONAL_INSURANCE_NUMBER)}
                                                                                            onBlur={(e) => this.editRelativeDetails(relative, e, i, UK_NATIONAL_INSURANCE_NUMBER)}
                                                                                            value={relative.nationalInsuranceNumber !== null ? relative.nationalInsuranceNumber : ""}
                                                                                            autoComplete="off"
                                                                                            disabled={relative.ukPaymentMethod !== CaseUtil.getUkPaymentMethods().dwpApplication.id}
                                                                                            data-lpignore="true"/>
                                                                                    </div>
                                                                                    <div className={`form-group`}>
                                                                                        <label
                                                                                            htmlFor={"financialAssistanceDetails" + i}>
                                                                                            {Utility.getLang().cases.relativeTabContent.financialAssistanceDetails}
                                                                                        </label>
                                                                                        <textarea
                                                                                            id={"financialAssistanceDetails" + i}
                                                                                            name="financialAssistanceDetails"
                                                                                            className={`textarea textarea--max_width`}
                                                                                            type="text"
                                                                                            onChange={(e) => this.changeRelDetailLocal(relative, e, i, UK_FINANCIAL_ASSISTANCE_DETAIL)}
                                                                                            onBlur={(e) => this.editRelativeDetails(relative, e, i, UK_FINANCIAL_ASSISTANCE_DETAIL)}
                                                                                            value={relative.financialAssistanceDetails !== null ? relative.financialAssistanceDetails : ""}
                                                                                            autoComplete="off"
                                                                                            data-lpignore="true"/>
                                                                                    </div>
                                                                                </div> : null
                                                                        }
                                                                        <SharedFuneralAccessLevel
                                                                            selectedCaseData={selectedCaseData}
                                                                            revokeAccessLevel={this.revokeAccessLevel}
                                                                            relative={relative}
                                                                        />
                                                                    </div>
                                                                    <div className="section__column">
                                                                        {
                                                                            isFieldEnabled(ValidationAndVisibilityRule.common, ValidationAndVisibilityRule.personNumber) ?
                                                                                <div className={`form-group`}>
                                                                                    <label htmlFor={"personNumber" + i}>
                                                                                        {Utility.getLang().cases.relativeTabContent.personalIdentityNumber}
                                                                                    </label>
                                                                                    <div
                                                                                        className="btn-toolbar btn-toolbar--actions nowrap">
                                                                                        <input
                                                                                            id={"personNumber" + i}
                                                                                            name="personNumber"
                                                                                            placeholder={Utility.getLang().cases.relativeTabContent.personalIdentityNumber}
                                                                                            className={"text btn-toolbar__field text--number " + checkValidation(AppUtil.isAvailable(selectedCaseData.relatives[i].personNumber) ? selectedCaseData.relatives[i].personNumber : null) + checkPersonNumberFormat(AppUtil.isAvailable(selectedCaseData.relatives[i].personNumber) ? selectedCaseData.relatives[i].personNumber : null)}
                                                                                            type="text"
                                                                                            onKeyPress={(event) => allowNumberAndDash(event)}
                                                                                            onChange={(e) => this.changeRelDetailLocal(relative, e, i, "personNumber")}
                                                                                            onKeyDown={(e) => this.checkKeys(e)}
                                                                                            onBlur={(e) => this.editRelativeDetails(relative, e, i, "personNumber")}
                                                                                            value={relative.personNumber !== null ? relative.personNumber : ""}
                                                                                            autoComplete="off"
                                                                                            data-lpignore="true"/>
                                                                                        {
                                                                                            sparLinkVisible() ?
                                                                                                <Button
                                                                                                    onClick={(e) => this.getDetailsByPersonNumber(e, relative, i)}
                                                                                                    id="getDetails"
                                                                                                    disabled={this.state.relativesDisabledButton[i] !== undefined ? this.state.relativesDisabledButton[i].disabled : true}
                                                                                                    noLabel
                                                                                                    icon="download"
                                                                                                    tooltip={Utility.getLang().cases.deceasedTabContent.getDetailsBtn}
                                                                                                    tooltipAlignRight/> : null
                                                                                        }
                                                                                    </div>
                                                                                    {
                                                                                        checkValidation(AppUtil.isAvailable(selectedCaseData.relatives[i].personNumber) ? selectedCaseData.relatives[i].personNumber : null) !== "" ?
                                                                                            getNoSpaceError() : checkPersonNumberFormat(AppUtil.isAvailable(selectedCaseData.relatives[i]) && AppUtil.isAvailable(selectedCaseData.relatives[i].personNumber) ? selectedCaseData.relatives[i].personNumber : null) !== "" ?
                                                                                                getPersonNumberFormatError() : null
                                                                                    }
                                                                                </div> : null
                                                                        }
                                                                        {
                                                                            isFieldEnabled(ValidationAndVisibilityRule.relative, ValidationAndVisibilityRule.dateOfBirth) ?
                                                                                <div
                                                                                    className={`form-group`}>
                                                                                    <label
                                                                                        htmlFor="relativeDateOfBirth">
                                                                                        {Utility.getLang().cases.relativeTabContent.dateOfBirth}
                                                                                    </label>
                                                                                    <DatePicker
                                                                                        id="relativeDateOfBirth"
                                                                                        dateFormat={validationRule(ValidationAndVisibilityRule.relative, ValidationAndVisibilityRule.dateOfBirth).dateFormat}
                                                                                        selected={DateUtilExt.pickerDate(relative.dateOfBirth)}
                                                                                        onChange={(e) => this.editRelativeDetails(relative, e, i, "dateOfBirth")}
                                                                                        maxDate={new Date()}
                                                                                        showWeekNumbers
                                                                                        showYearDropdown
                                                                                        showMonthDropdown
                                                                                        dropdownMode="select"
                                                                                        autoComplete="off"
                                                                                        data-lpignore="true"
                                                                                        className="text text--date"
                                                                                        customInput={datePickerCustomInput(TimestampFormat.DATE)}/>
                                                                                </div> : null
                                                                        }
                                                                        <div
                                                                            className={`form-group`}>
                                                                            <label
                                                                                htmlFor={"caseRelativeRelationship" + i}>
                                                                                {Utility.getLang().cases.relativeTabContent.relationship}
                                                                            </label>
                                                                            <CreatableSelect
                                                                                id={"caseRelativeRelationship" + i}
                                                                                name="caseRelativeRelationship"
                                                                                value={AppUtil.isAvailable(relative.relationshipId) && relative.relationshipId !== "0" ? AppUtil.transformArrayIntoCreatableSelectOptions(CaseUtil.getDropDownOptionsAsValueAndLabel(this.props.allRelationshipInfo), relative.relationshipId) : null}
                                                                                placeholder={Utility.getLang().common.dropDownOptionPleaseSelect}
                                                                                formatCreateLabel={e => Utility.getLang().common.dropDownCreateMessage + `"${e}"`}
                                                                                className={"multiselect"}
                                                                                classNamePrefix={"multiselect"}
                                                                                options={CaseUtil.getDropDownOptionsAsValueAndLabel(this.props.allRelationshipInfo)}
                                                                                onChange={(e) => this.editRelativeDetails(relative, e, i, "relationship")}
                                                                                noOptionsMessage={() => Utility.getLang().common.noOptionMessage}/>
                                                                        </div>
                                                                        <div
                                                                            className={`form-group`}>
                                                                            <label
                                                                                htmlFor={"caseRelativeAddress" + i}>
                                                                                {Utility.getLang().cases.relativeTabContent.address}
                                                                            </label>
                                                                            <input
                                                                                id={"caseRelativeAddress" + i}
                                                                                name="caseRelativeAddress"
                                                                                placeholder={Utility.getLang().cases.relativeTabContent.address}
                                                                                className={"text text--max_width " + checkValidation(AppUtil.isAvailable(selectedCaseData.relatives[i].address) && AppUtil.isAvailable(selectedCaseData.relatives[i].address.address) ? selectedCaseData.relatives[i].address.address : null)}
                                                                                type="text"
                                                                                onChange={(e) => this.changeRelDetailLocal(relative, e, i, "address")}
                                                                                onBlur={(e) => this.editRelativeDetails(relative, e, i, "address")}
                                                                                value={AppUtil.isAvailable(relative.address) && (relative.address.address !== null) ? relative.address.address : ""}
                                                                                autoComplete="off"
                                                                                data-lpignore="true"/>
                                                                            {
                                                                                checkValidation(AppUtil.isAvailable(selectedCaseData.relatives[i].address) && AppUtil.isAvailable(selectedCaseData.relatives[i].address.address) ? selectedCaseData.relatives[i].address.address : null) !== "" ?
                                                                                    getNoSpaceError() : null
                                                                            }
                                                                            <input
                                                                                id={"caseRelativeAddressTwo" + i}
                                                                                name="caseRelativeAddressTwo"
                                                                                placeholder={Utility.getLang().cases.relativeTabContent.address2}
                                                                                className={"text text--max_width " + checkValidation(AppUtil.isAvailable(selectedCaseData.relatives[i].address) && AppUtil.isAvailable(selectedCaseData.relatives[i].address.address2) ? selectedCaseData.relatives[i].address.address2 : null)}
                                                                                type="text"
                                                                                onChange={(e) => this.changeRelDetailLocal(relative, e, i, "address2")}
                                                                                onBlur={(e) => this.editRelativeDetails(relative, e, i, "address2")}
                                                                                value={AppUtil.isAvailable(relative.address) && (relative.address.address2 !== null) ? relative.address.address2 : ""}
                                                                                autoComplete="off"
                                                                                data-lpignore="true"/>
                                                                            {
                                                                                checkValidation(AppUtil.isAvailable(selectedCaseData.relatives[i].address) && AppUtil.isAvailable(selectedCaseData.relatives[i].address.address2) ? selectedCaseData.relatives[i].address.address2 : null) !== "" ?
                                                                                    getNoSpaceError() : null
                                                                            }
                                                                        </div>
                                                                        <div className="form-group form-group--columns">
                                                                            {this.renderRelativePostalCode(relative, i, this.props.selectedCaseData, "postalCode")}
                                                                            {this.renderRelativeCity(relative, i, this.props.selectedCaseData, "city")}
                                                                        </div>
                                                                        {
                                                                            isFieldEnabled(ValidationAndVisibilityRule.common, ValidationAndVisibilityRule.county) ?
                                                                                <div
                                                                                    className={`form-group`}>
                                                                                    <label
                                                                                        htmlFor={"county"}>
                                                                                        {Utility.getLang().cases.relativeTabContent.county}
                                                                                    </label>
                                                                                    <Select
                                                                                        id={"county"}
                                                                                        name="county"
                                                                                        getOptionLabel={({name}) => name}
                                                                                        getOptionValue={({id}) => id}
                                                                                        value={AppUtil.isAvailable(relative.address) && AppUtil.isAvailable(relative.address.county.name) ? relative.address.county : null}
                                                                                        placeholder={Utility.getLang().common.dropDownOptionPleaseSelect}
                                                                                        className={"multiselect"}
                                                                                        classNamePrefix={"multiselect"}
                                                                                        options={this.props.counties}
                                                                                        onChange={(e) => this.editRelativeDetails(relative, e, i, "county")}
                                                                                        noOptionsMessage={() => Utility.getLang().common.noOptionMessage}/>
                                                                                </div> : null
                                                                        }
                                                                        <div
                                                                            className={`form-group`}>
                                                                            <label
                                                                                htmlFor={"countries"}>
                                                                                {Utility.getLang().cases.relativeTabContent.country}
                                                                            </label>
                                                                            <Select
                                                                                id={"countries"}
                                                                                name="countries"
                                                                                getOptionLabel={({name}) => name}
                                                                                getOptionValue={({id}) => id}
                                                                                value={AppUtil.isAvailable(relative.address) && AppUtil.isAvailable(relative.address.country.name) ? relative.address.country : null}
                                                                                placeholder={Utility.getLang().common.dropDownOptionPleaseSelect}
                                                                                className={"multiselect"}
                                                                                classNamePrefix={"multiselect"}
                                                                                options={countries}
                                                                                onChange={(e) => this.editRelativeDetails(relative, e, i, "country")}
                                                                                noOptionsMessage={() => Utility.getLang().common.noOptionMessage}/>
                                                                        </div>

                                                                        <DescriptionWithLink id={relative.bcCustomerId}
                                                                                             title={Utility.getLang().common.microsoftNavId}/>
                                                                        <DescriptionWithLink
                                                                            id={relative.customerIdaAccountId}
                                                                            title={Utility.getLang().common.customerAccountId}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {relative.isGuest ?
                                                                selectedCaseData.guests.map((guest, i) => guest.id === relative.id ? this.renderGuestDetails(guest, i) : "")
                                                                : null}
                                                            {
                                                                (relative.npsData !== undefined && relative.npsData.length > 0) &&
                                                                <NPSSurveyTable npsData={relative.npsData}/>
                                                            }
                                                            <div className="card__foot">
                                                                <div className="btn-toolbar btn-toolbar--footer">
                                                                    {selectedCaseData.id !== 0 && RelativeUtil.showDeleteBtn(relative.isDeletable, relative.ukContactProperties) ?
                                                                        <div className="right">
                                                                            <Button
                                                                                onClick={(e) => this.removeRelative(relative, i)}
                                                                                isTertiary
                                                                                isDestructive>
                                                                                {Utility.getLang().cases.relativeTabContent.deleteContact}
                                                                            </Button>
                                                                        </div>
                                                                        : null}
                                                                </div>
                                                            </div>
                                                        </form>
                                                        <Popup
                                                            openModal={this.props.openPopupForRelativeOnly}
                                                            headerInfo={Utility.getLang().infoMessages.popup.deleteRelative + ` ` + AppUtil.concatenateFullName(this.removedRelativeData) + `?`}
                                                            onConfirmation={this.onRelativeDelete}
                                                            closePopup={this.hidePopup}
                                                            isDestructive/>
                                                    </div>
                                                );
                                            })
                                            :
                                            <p>{Utility.getLang().cases.relativeTabContent.noContacts}</p>
                                    }
                                    {selectedCaseData.id === 0 ? null :
                                        <div className="drawer">
                                            <div className="section__toolbar btn-toolbar btn-toolbar--footer">
                                                <Button
                                                    onClick={this.addNewRelative}
                                                    icon="add">
                                                    {Utility.getLang().cases.relativeTabContent.addContact}
                                                </Button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <PopupForEmailSend
                                openModal={this.props.openOnSend}
                                onConfirmation={this.onConfirmSend}
                                closePopup={this.hidePopupOnCancel}
                                source={"case"}
                                sendCaseEmailSummary={
                                    (relativeId, data, emailTemplateType) =>
                                        this.sendEmailSummary(relativeId, data, emailTemplateType)}/>
                        </div>
                        :
                        <DisplayNothing/>
                }
            </React.Fragment>

        );
    };

    /***
     * @description: 'Delete orderSharedAccess from 'orderSharedAccesses' from case.relatives
     */
    revokeAccess(caseRelativeId, id) {
        let relativesAll = this.props.selectedCaseData.relatives;
        let relative = relativesAll.find(element => element.id === caseRelativeId);
        relative.orderSharedAccesses.forEach((access, i) => {
            if (access.id === id) {
                relative.orderSharedAccesses.splice(i, 1);
            }
        });

        this.setState({relativeData: relativesAll});
    }
}

CaseRelativesComponent.propTypes = {
    columnDetailViewTabCollection: PropTypes.array,
};

function mapStateToProps(state) {
    return {
        selectedCaseData: state.application.selectedCaseData,
        initialValues: state.application.selectedCaseData,
        selectedDataById: state.application.selectedDataById,
        openPopupForRelativeOnly: state.application.openPopupForRelativeOnly,
        open: state.application.open,

        openOnSend: state.application.openOnSend,
        allUserDetailsForCases: state.application.allUserDetailsForCases,
        caseAllStages: state.application.caseAllStages,
        caseAllLabels: state.application.caseAllLabels,
        allRelationshipInfo: state.application.allRelationshipInfo,

        customerWebAppOrderAccessLevelPopup: state.application.customerWebAppOrderAccessLevelPopup,

        headerMenuItemClicked: state.application.headerMenuItemClicked,
        displayNothing: state.application.displayNothing,
        caseUpdated: state.application.caseUpdated,
        tempNote: state.application.tempNote,
        openOnSaveCase: state.application.openOnSaveCase,
        openOnOrderDrag: state.application.openOnOrderDrag,
        countries: state.application.countries,
        counties: state.application.counties,
        wsLockCaseData: state.application.wsLockCaseData,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: "CaseRelativesComponent",
    enableReinitialize: true,
    validate: FormValidator({
        name: {presence: true},
    })
})(CaseRelativesComponent));
