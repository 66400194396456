import React from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import CardFooter from "../common/card/cardFooter";
import CardContent from "../common/card/cardContent";
import CardTitle from "../common/card/cardTitle";
import AppUtil from "../common/appUtil";

class OrderColumnItemCard extends React.Component {

    onSelected = () => {
        this.props.onCardSelected(this.props.card);
    };

    render = () => {
        return (
            <div className="item__spacer">
                {
                    this.props.card.id !== undefined ?
                        <div
                            className={`item item--button item--order ${this.props.isActive === true ? 'is-active' : ''}`}
                            tabIndex="0" onClick={this.onSelected}
                        >
                            <CardTitle data={this.props.card} infoType={AppUtil.infoKeys.order}/>
                            <CardContent case={this.props.card.case} infoType={AppUtil.infoKeys.case}/>
                            <CardFooter data={this.props.card}/>
                        </div> : null
                }
            </div>
        )
    }
}

OrderColumnItemCard.propTypes = {
    card: PropTypes.object,
    onCardSelected: PropTypes.func,
    isActive: PropTypes.bool
};

function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps() {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderColumnItemCard);