import useQuestionnaireBeneficiary from "../hooks/useQuestionnaireBeneficiary";
import {useBeneficiaryFields} from "../../tabBeneficiaries/hooks/useBeneficiaryFields";
import React, {useState} from "react";
import {
    AppCheckBox,
    AppCreatableSelect,
    AppSelect,
    AppTextField,
    AppTextFieldWithButton
} from "../../../../../common/utils/useCommonElement";
import {ProbateProps} from "../../tabProbateMeeting/util";
import AppUtil, {Lang} from "../../../../../common/appUtil";

import Button from "../../../../../common/button";
import CaseUtil from "../../../../../common/caseUtil";
import useProbateUtil from "../../../util/useProbateUtil";
import {handleKeyPressedEvent, InlineValidation} from "../../../util/formValidations";
import {OnToggleDiv} from "../../../../caseOrderSubComponents/toggleShowAndHideContent";

export const Animation = {
    collapse: "collapse",
    expand: "expand"
}

export default function QuestionnaireBeneficiary({
                                                     QABeneficiary,
                                                     index,
                                                     questionId,
                                                     probateId,
                                                     allRelationships,
                                                     commonBeneficiaryIdsActions,
                                                     formToggleState,
                                                     showDeleteQuestionnairePopup,
                                                     dispatch
                                                 }) {

    const {
        onTextChange,
        onChangeCheckbox,
        onChangeDropdownValue,
        getPersonNumberDetailsButton,
        isLoadingPersonalNumber,
        removeBeneficiary,
        relationshipOptions,
        selectedRelationshipValue,
        onChange,
        checkKeys,
    } = useQuestionnaireBeneficiary(probateId, QABeneficiary, allRelationships, dispatch, "QuestionnaireBeneficiary");

    /***
     * @description: Legal status properties
     */
    const {legalStatusOptions, userName} = useProbateUtil();
    const selectedLegalStatusValue = (QABeneficiary.legalStatus > 0) ? CaseUtil.setSelectedValue(legalStatusOptions, QABeneficiary.legalStatus) : null;
    const {legalStatusDropdownOptions} = useBeneficiaryFields();

    const [animationType, setAnimationType] = useState(formToggleState);
    /**
     * @description: _showForm and _hideForm are private methods, here callback to Callee is done
     */
    const _showForm = () => {
        setAnimationType(Animation.expand);
    }

    const _hideForm = () => {
        setAnimationType(Animation.collapse);
    }

    const _toggleForm = () => {
        setAnimationType(animationType === Animation.collapse ? Animation.expand : Animation.collapse);
    }

    const onClickShowDeleteQuestionnairePopup = () => {
        showDeleteQuestionnairePopup(QABeneficiary, removeBeneficiary, commonBeneficiaryIdsActions);
    }

    function Head(beneficiary, questionId, index) {
        const caseRelative = beneficiary.caseRelative
        const count = index + 1;
        const debugMode = false;
        const debugLog = debugMode ? `[${beneficiary.id}, ${beneficiary.questionId}] ` : '';
        return (
            <div
                className={`${(animationType === Animation.expand) ? "questionnaire--head hide--questionnaire" : "questionnaire--head show--questionnaire"} ${!beneficiary.isValid ? 'is-error' : ''}`}
                key={`Head${beneficiary.id}_${questionId}`}>
                <div className="userName td">
                    <span className='title'>{`${debugLog}${count}. ${userName(caseRelative)}`}</span>
                </div>
                <div className="quesDesc td">
                    <span
                        className='log_item__details'>{Lang().cases.probates.questionnaire.beneficiaryHeaderEmptyPlaceholder}</span>
                </div>
                <div className="btn-toolbar">
                    <Button id={`${beneficiary.id}_${questionId}`}
                            className="quesEdit"
                            icon={'edit'}
                            onClick={_showForm}
                            isTertiary>{Lang().common.edit}
                    </Button>
                    <Button
                        id={`Delete_${questionId}`}
                        onClick={onClickShowDeleteQuestionnairePopup}
                        isDestructive
                        isTertiary
                        disabled={false}
                    />
                </div>
            </div>
        );
    }

    function Form(beneficiary, questionId, index) {
        const {caseRelative} = beneficiary;
        const key = `${beneficiary.id}_${questionId}`;
        const disableNonEditableFields = (beneficiary.id > 0);
        return <div
            className={`${(animationType === Animation.expand) ? "questionnaire--form show--questionnaire" : "questionnaire--form hide--questionnaire"} ${!beneficiary.isValid ? 'is-error' : ''}`}
            key={`Form_${beneficiary.id}_${questionId}`}>
            <div className="section__columns">
                <div className="section__column">
                    <div className="form-group form-group--columns">
                        <AppTextField id={`${ProbateProps.firstName}_${key}`}
                                      title={Lang().cases.relativeTabContent.firstName}
                                      value={caseRelative.firstName || ''}
                                      onBlur={(e) => onTextChange(e.target.value, beneficiary.id, beneficiary.questionId, ProbateProps.firstName)}
                                      disabled={disableNonEditableFields}
                                      sizeClass={`form-group__column field-required`}
                                      inlineValidation={InlineValidation(ProbateProps.firstName, caseRelative.firstName, Lang().cases.relativeTabContent.firstName)}
                                      placeholder={" "}
                        />
                        <AppTextField id={`${ProbateProps.lastName}_${key}`}
                                      title={Lang().cases.relativeTabContent.lastName}
                                      value={caseRelative.lastName || ''}
                                      onBlur={(e) => onTextChange(e.target.value, beneficiary.id, beneficiary.questionId, ProbateProps.lastName)}
                                      disabled={disableNonEditableFields}
                                      sizeClass={`form-group__column field-required`}
                                      inlineValidation={InlineValidation(ProbateProps.lastName, caseRelative.lastName, Lang().cases.relativeTabContent.lastName)}
                                      placeholder={" "}
                        />
                    </div>
                    <div className="form-group field--max_width">
                        <AppTextField id={`${ProbateProps.address}_${key}`}
                                      title={Lang().cases.relativeTabContent.address}
                                      value={caseRelative.address.address || ''}
                                      onBlur={(e) => onTextChange(e.target.value, beneficiary.id, beneficiary.questionId, ProbateProps.address)}
                                      disabled={false}
                                      sizeClass={"field--max_width"}
                                      inlineValidation={AppUtil.isAvailable(caseRelative.address) ? InlineValidation(ProbateProps.address, caseRelative.address.address) : null}
                                      placeholder={" "}
                        />
                        <AppTextField id={`${ProbateProps.address2}_${key}`}
                                      title={null}
                                      value={caseRelative.address.address2 || ''}
                                      onBlur={(e) => onTextChange(e.target.value, beneficiary.id, beneficiary.questionId, ProbateProps.address2)}
                                      disabled={false}
                                      sizeClass={"field--max_width"}
                                      inlineValidation={AppUtil.isAvailable(caseRelative.address) ? InlineValidation(ProbateProps.address2, caseRelative.address.address2) : null}
                                      placeholder={" "}
                        />
                    </div>
                    <div className="form-group form-group--columns">
                        <AppTextField id={`${ProbateProps.postalCode}_${key}`}
                                      title={Lang().cases.relativeTabContent.postalCode}
                                      value={caseRelative.address.postalCode || ''}
                                      onBlur={(e) => onTextChange(e.target.value, beneficiary.id, beneficiary.questionId, ProbateProps.postalCode)}
                                      onKeyPress={(e) => {
                                          handleKeyPressedEvent(e, ProbateProps.postalCode);
                                      }
                                      }
                                      disabled={false}
                                      sizeClass={"form-group__column field-required"}
                                      inlineValidation={AppUtil.isAvailable(caseRelative.address) ? InlineValidation(ProbateProps.postalCode, caseRelative.address.postalCode) : null}
                                      placeholder={" "}
                        />
                        <AppTextField id={`${ProbateProps.city}_${key}`}
                                      title={Lang().cases.relativeTabContent.city}
                                      value={caseRelative.address.city || ''}
                                      onBlur={(e) => onTextChange(e.target.value, beneficiary.id, beneficiary.questionId, ProbateProps.city)}
                                      disabled={false}
                                      sizeClass={"form-group__column field-required remove--right-pad"}
                                      inlineValidation={AppUtil.isAvailable(caseRelative.address) ? InlineValidation(ProbateProps.city, caseRelative.address.city) : null}
                                      placeholder={" "}
                        />
                        <div className="form-group field--max_width">
                            <AppSelect id={`${ProbateProps.legalStatus}_${key}`}
                                       title={Lang().cases.probates.headerTitle.legalStatus}
                                       value={selectedLegalStatusValue}
                                       options={legalStatusDropdownOptions}
                                       onChange={(e) => onChangeDropdownValue(e, beneficiary.id, beneficiary.questionId, ProbateProps.legalStatus)}
                            />
                        </div>
                    </div>
                </div>
                <div className="section__column">
                    <AppTextFieldWithButton id={`${ProbateProps.personNumber}_${key}`}
                                            title={Lang().cases.relativeTabContent.personalIdentityNumber}
                                            value={caseRelative.personNumber || ''}
                                            onChange={(e) => onChange(e, ProbateProps.personNumber)}
                                            onBlur={(e) => onTextChange(e.target.value, beneficiary.id, beneficiary.questionId, ProbateProps.personNumber)}
                                            onKeyPress={(e) => {
                                                handleKeyPressedEvent(e, ProbateProps.personNumber);
                                            }
                                            }
                                            onKeyDown={checkKeys}
                                            disabled={disableNonEditableFields}
                                            sizeClass={"field--max_width"}
                                            placeholder={Lang().cases.relativeTabContent.personalIdentityNumber}
                                            inlineValidation={InlineValidation(ProbateProps.personNumber, caseRelative.personNumber)}
                                            buttonProps={getPersonNumberDetailsButton((caseRelative.personNumber || ''), beneficiary.id, beneficiary.questionId, isLoadingPersonalNumber || disableNonEditableFields)}
                    />
                    <AppTextField id={`${ProbateProps.phoneNumber}_${key}`}
                                  title={Lang().cases.relativeTabContent.phoneNumber}
                                  value={caseRelative.phoneNumber || ''}
                                  onBlur={(e) => onTextChange(e.target.value, beneficiary.id, beneficiary.questionId, ProbateProps.phoneNumber)}
                                  onKeyPress={(e) => {
                                      handleKeyPressedEvent(e, ProbateProps.phoneNumber);
                                  }
                                  }
                                  disabled={false}
                                  sizeClass={"field--max_width"}
                                  inlineValidation={InlineValidation(ProbateProps.phoneNumber, caseRelative.phoneNumber)}
                                  placeholder={" "}
                    />
                    <AppCreatableSelect id={`${ProbateProps.relationship}_${key}`}
                                        title={Lang().cases.relativeTabContent.relationship}
                                        options={relationshipOptions}
                                        value={selectedRelationshipValue}
                                        onChange={(e) => onChangeDropdownValue(e, beneficiary.id, beneficiary.questionId, ProbateProps.relationship)}
                    />

                    <AppCheckBox id={`${ProbateProps.isDeceased}_${key}`}
                                 title={Lang().cases.probates.deceased}
                                 value={AppUtil.isAvailable(beneficiary.isDeceased) ? Number(beneficiary.isDeceased) : false}
                                 onChange={(e) => onChangeCheckbox(e.target.checked, beneficiary.id, beneficiary.questionId, ProbateProps.isDeceased)}
                    />

                </div>
                <OnToggleDiv fileName={animationType}
                             disabledToggle={false}
                             showAndHide={_toggleForm}/>
            </div>
            <div className="section__columns">
                <div className={`section__column rem-margin`}>
                    <div className="btn-toolbar">
                        <Button
                            id={`${(beneficiary.id > 0) ? 'Done' : 'Add'}_${key}`}
                            onClick={_hideForm}
                            isPrimary
                            disabled={!beneficiary.isValid}
                        >
                            {(beneficiary.id > 0) ? Lang().common.done : Lang().cases.probates.questionnaire.add}
                        </Button>
                        <Button
                            id={`Delete_${key}`}
                            onClick={onClickShowDeleteQuestionnairePopup}
                            isDestructive
                            isTertiary
                            disabled={false}
                        >
                            {Lang().cases.probates.questionnaire.delete}
                        </Button>
                    </div>
                </div>
            </div>
            {/* questionnaire form end */}
        </div>
    }

    return <>
        {Head(QABeneficiary, questionId, index)}
        {Form(QABeneficiary, questionId, index)}
    </>;
}
