import Permalink from "../../../../../common/link/permalink";
import AppUtil, {Lang} from "../../../../../common/appUtil";
import React from "react";
import {Title} from "./title";

export default function ProbateCaseLink({id}) {
    return <>
        <Title content={Lang().cases.probates.probate}/>
        {Lang().cases.probates.case + " "}
        <Permalink
            id={id}
            path1={AppUtil.linkPaths.case.basePath}
            path2={AppUtil.linkPaths.case.details}
        />
    </>;
}