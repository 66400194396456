import Select from "react-select";
import Utility from "../../../../api/utilLanguage";
import React from "react";
import AppUtil from "../../../common/appUtil";
import DocumentUtil from "../documentUtil/documentUtil";
import DocumentDropZone from "./documentDropZone";
import LockCaseUtil from "../../lockCase/util/lockCaseUtil";

export class DocumentGenerateOrUpload extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            groupSelectionOptions: []
        }
    }

    UNSAFE_componentWillMount = () => {
        this.setupGroupSelectionOptions(this.props.options);
    };

    setupGroupSelectionOptions(options) {
        if (AppUtil.isAvailable(options) && options.length > 0) {
            let groupSelectionOptions = DocumentUtil.createGroupSelectionOptions(options, DocumentUtil.docGenerateType.generated);
            this.setState({groupSelectionOptions: groupSelectionOptions});
        } else {
            console.log("%c", 'color: orange;font-size:12px;', Utility.getLang().common.document.categorySubCategoryDocumentTypeNotFound);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.isSelectOptionsChanged(this.props.options, nextProps.options)) {
            //console.log('componentWillReceiveProps, options changed ');
            this.setupGroupSelectionOptions(nextProps.options);
        }
    }

    isSelectOptionsChanged = (currentOptions, newOptions) => {
        return (JSON.stringify(currentOptions) !== JSON.stringify(newOptions));
    };

    onSelectionChange = (documentType) => {
        this.props.onSelectionChange(documentType);
    };

    render() {
        const {isDocumentTypeLoading, selectedDocumentType} = this.props;
        const {groupSelectionOptions} = this.state;
        const isSelectionOptionDisabled = !(AppUtil.isAvailable(groupSelectionOptions) && groupSelectionOptions.length > 0);

        return (
            <React.Fragment>
                <div className="inner" disabled={(LockCaseUtil.isDisabledView(this.props.disabledView))}>
                    <div className="form-group form-group--columns">
                        <div className="form-group__column">
                            <label htmlFor="caseDocumentIdType">
                                {Utility.getLang().common.document.fileGenerateDocument}
                            </label>
                            <Select
                                inputId="caseDocumentIdType"
                                options={groupSelectionOptions}
                                value={selectedDocumentType}
                                isLoading={isDocumentTypeLoading}
                                placeholder={Utility.getLang().common.dropDownOptionPleaseSelect}
                                formatCreateLabel=""
                                className={"multiselect"}
                                classNamePrefix={"multiselect"}
                                noOptionsMessage={() => Utility.getLang().common.noOptionMessage}
                                onChange={this.onSelectionChange}
                                isDisabled={isSelectionOptionDisabled}
                            />
                        </div>
                        <div className="form-group__column">
                            <label htmlFor="caseDocumentIdType">
                                {Utility.getLang().common.document.fileUploadDocument}
                            </label>
                            <DocumentDropZone
                                fileTypeSupported={["application/pdf", "image/jpg", "image/jpeg", "image/png", "image/gif", "image/bmp"]}
                                onUploadFileChange={this.props.onUploadFileChange}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
