import DocumentApi from "../api/documents/documentApi";
import AppUtil from "../components/common/appUtil";
import RefreshToken from "../api/validateToken";
import {getApiToken} from "../components/common/localStorageUtil";
import {afterRefreshToken} from "./uiAction";
import {push} from "react-router-redux";
import {emailCaseSummary, onErrorUnderCase} from "./caseAction";

/**
 * GET Case Documents
 *
 */

export function updateCaseDocuments(data) {
    return {type: 'UPDATE_CASE_DOCUMENTS', data};
}

function getCaseDocumentsApi(token, id, dispatch) {
    return DocumentApi.getCaseDocuments(token, id).then(data => {
        dispatch(updateCaseDocuments(data));
        return new Promise((resolve, reject) => setTimeout(() => resolve(data), 500));
    }).catch(error => {
        dispatch(onErrorUnderCase(error));
        return Promise.reject(error);
    });
}

export function getCaseDocuments(caseId) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            return (RefreshToken.validateRefreshToken().then(responseData => {
                dispatch(afterRefreshToken(responseData));
                return getCaseDocumentsApi(responseData.idToken.jwtToken, caseId, dispatch);
            }).catch(err => {
                dispatch(push('/'));
                return Promise.reject(err);
            }));
        } else {
            return getCaseDocumentsApi(getApiToken(), caseId, dispatch);
        }
    }
}

/***
 *
 * @description: Document types
 */
export function updateCaseDocumentTypes(data) {
    return {type: 'UPDATE_CASE_DOCUMENT_TYPES', data};
}

function getDocumentTypesApi(token, id, dispatch) {
    return DocumentApi.getDocumentTypes(token, id).then(data => {
        dispatch(updateCaseDocumentTypes(data));
        return new Promise((resolve, reject) => setTimeout(() => resolve(data), 500));
    }).catch(error => {
        dispatch(onErrorUnderCase(error));
        return Promise.reject(error);
    });
}

export function getDocumentTypes(caseId) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            return (RefreshToken.validateRefreshToken().then(responseData => {
                dispatch(afterRefreshToken(responseData));
                return getDocumentTypesApi(responseData.idToken.jwtToken, caseId, dispatch);
            }).catch(err => {
                dispatch(push('/'));
                return Promise.reject(err);
            }));
        } else {
            return getDocumentTypesApi(getApiToken(), caseId, dispatch);
        }
    }
}


/***
 *
 * @description: Generate documents
 */
export function updateGeneratedCaseDocument(data) {
    return {type: 'UPDATE_GENERATED_CASE_DOCUMENT', data};
}

export function updateGeneratedCaseDocumentNewVersion(data) {
    return {type: 'UPDATE_GENERATED_CASE_DOCUMENT_NEW_VERSION', data};
}

function generateDocumentApi(token, caseId, documentTypeId, orderId, isNewVersion, dispatch) {
    return (DocumentApi.generateDocument(token, caseId, documentTypeId, orderId).then(data => {
        const responseData = data;
        if (isNewVersion) {
            dispatch(updateGeneratedCaseDocumentNewVersion(responseData));
        } else {
            dispatch(updateGeneratedCaseDocument(responseData));
        }
        return new Promise((resolve, reject) => setTimeout(() => resolve(responseData), 500));
    }).catch(error => {
        dispatch(onErrorUnderCase(error));
        return Promise.reject(error);
    }));
}

export function generateDocument(caseId, documentTypeId, orderId = null, isNewVersion) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            return (RefreshToken.validateRefreshToken().then(responseData => {
                dispatch(afterRefreshToken(responseData));
                return generateDocumentApi(responseData.idToken.jwtToken, caseId, documentTypeId, orderId, isNewVersion, dispatch);
            }).catch(err => {
                dispatch(push('/'));
                return Promise.reject(err);
            }));
        } else {
            return generateDocumentApi(getApiToken(), caseId, documentTypeId, orderId, isNewVersion, dispatch);
        }
    }
}

/***
 *
 * @description: Generate documents
 */
export function updateBase64GeneratedCaseDocument(data) {
    return {type: 'UPDATE_BASE64_GENERATED_CASE_DOCUMENT', data};
}

export function updateBase64GeneratedCaseDocumentNewVersion(data) {
    return {type: 'UPDATE_BASE64_GENERATED_CASE_DOCUMENT_NEW_VERSION', data};
}

function getBase64DocumentApi(token, caseId, documentId, isNewVersion, dispatch) {
    return (DocumentApi.getBase64Document(token, caseId, documentId).then(data => {
        const responseData = data;
        if (isNewVersion) {
            dispatch(updateBase64GeneratedCaseDocumentNewVersion(responseData));
        } else {
            dispatch(updateBase64GeneratedCaseDocument(responseData));
        }
        return new Promise((resolve, reject) => setTimeout(() => resolve(responseData), 500));
    }).catch(error => {
        dispatch(onErrorUnderCase(error));
        return Promise.reject(error);
    }));
}

export function getBase64Document(caseId, documentId, isNewVersion) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            return (RefreshToken.validateRefreshToken().then(responseData => {
                dispatch(afterRefreshToken(responseData));
                return getBase64DocumentApi(responseData.idToken.jwtToken, caseId, documentId, isNewVersion, dispatch);
            }).catch(err => {
                dispatch(push('/'));
                return Promise.reject(err);
            }));
        } else {
            return getBase64DocumentApi(getApiToken(), caseId, documentId, isNewVersion, dispatch);
        }
    }
}

/***
 *
 * @description: Clear Generated document
 */
export function clearBase64GeneratedDocument(data) {
    return {type: 'CLEAR_BASE64_GENERATED_CASE_DOCUMENT', data};
}

/***
 * @description: Update document api
 *
 */


export function updateCaseDocumentsOnSave(data) {
    return {type: 'UPDATE_CASE_DOCUMENTS_ON_SAVE', data};
}

function updateDocumentApi(token, document, documentCategoryId, documentTypeId, dispatch) {
    return DocumentApi.updateDocument(token, document, documentCategoryId, documentTypeId).then(data => {
        dispatch(updateCaseDocumentsOnSave(data));
        return new Promise((resolve, reject) => setTimeout(() => resolve(data), 500));
    }).catch(error => {
        dispatch(onErrorUnderCase(error));
        return Promise.reject(error);
    });
}

export function updateDocument(document, documentTypeId, documentCategoryId) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            return (RefreshToken.validateRefreshToken().then(responseData => {
                dispatch(afterRefreshToken(responseData));
                return updateDocumentApi(responseData.idToken.jwtToken, document, documentCategoryId, documentTypeId, dispatch);
            }).catch(err => {
                dispatch(push('/'));
                return Promise.reject(err);
            }));
        } else {
            return updateDocumentApi(getApiToken(), document, documentCategoryId, documentTypeId, dispatch);
        }
    }
}

export const UPDATE_CASE_DOCUMENT_VISIBLE_TO_CUSTOMER = 'UPDATE_CASE_DOCUMENT_VISIBLE_TO_CUSTOMER';
export function updateCaseDocumentVisibleToCustomer(data) {
    return { type: UPDATE_CASE_DOCUMENT_VISIBLE_TO_CUSTOMER, data };
}

/***
 *
 * @description: Generate documents
 */
export function updateUploadedCaseDocument(data) {
    return {type: 'UPDATE_UPLOADED_CASE_DOCUMENT', data};
}

/***
 * @description: Upload document api
 *
 */


function uploadDocumentApi(token, caseId, fileData, dispatch) {
    return DocumentApi.uploadDocument(token, caseId, fileData).then(data => {
        dispatch(updateUploadedCaseDocument(data));
        return new Promise((resolve, reject) => setTimeout(() => resolve(data), 500));
    }).catch(error => {
        dispatch(onErrorUnderCase(error));
        return Promise.reject(error);
    });
}

export function uploadDocument(caseId, fileData) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            return (RefreshToken.validateRefreshToken().then(responseData => {
                dispatch(afterRefreshToken(responseData));
                return uploadDocumentApi(responseData.idToken.jwtToken, caseId, fileData, dispatch);
            }).catch(err => {
                dispatch(push('/'));
                return Promise.reject(err);
            }));
        } else {
            return uploadDocumentApi(getApiToken(), caseId, fileData, dispatch);
        }
    }
}

/***
 *
 * @description: Generate documents by version
 */
export function updateBase64GeneratedDocumentByVersion(data) {
    return {type: 'UPDATE_BASE64_GENERATED_CASE_DOCUMENT', data};
}

function getBase64DocumentByVersionApi(token, caseId, documentId, versionId, dispatch) {
    return (DocumentApi.getBase64DocumentByVersion(token, caseId, documentId, versionId).then(data => {
        const responseData = data;
        dispatch(updateBase64GeneratedDocumentByVersion(responseData));
        return new Promise((resolve, reject) => setTimeout(() => resolve(responseData), 500));
    }).catch(error => {
        dispatch(onErrorUnderCase(error));
        return Promise.reject(error);
    }));
}

export function getBase64DocumentByVersion(caseId, documentId, versionId) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            return (RefreshToken.validateRefreshToken().then(responseData => {
                dispatch(afterRefreshToken(responseData));
                return getBase64DocumentByVersionApi(responseData.idToken.jwtToken, caseId, documentId, versionId, dispatch);
            }).catch(err => {
                dispatch(push('/'));
                return Promise.reject(err);
            }));
        } else {
            return getBase64DocumentByVersionApi(getApiToken(), caseId, documentId, versionId, dispatch);
        }
    }
}

/***
 *
 * @description: Delete case document
 */

export function caseDocumentDeletedSuccessfully(data, deletedData) {
    return {type: 'CASE_DOCUMENT_DELETED_SUCCESSFULLY', data, deletedData};
}

function deleteCaseDocumentApi(token, caseId, documentId, dispatch) {
    return (DocumentApi.deleteDocument(token, caseId, documentId).then(data => {
        dispatch(caseDocumentDeletedSuccessfully(data, documentId));
        return new Promise((resolve, reject) => setTimeout(() => resolve(data), 0));
    }).catch(error => {
        dispatch(onErrorUnderCase(error));
        return Promise.reject(error);
    }));
}

export function deleteCaseDocument(caseId, documentId) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            return (RefreshToken.validateRefreshToken().then(responseData => {
                dispatch(afterRefreshToken(responseData));
                return deleteCaseDocumentApi(responseData.idToken.jwtToken, caseId, documentId, dispatch);
            }).catch(err => {
                dispatch(push('/'));
                return Promise.reject(err);
            }));
        } else {
            return deleteCaseDocumentApi(getApiToken(), caseId, documentId, dispatch);
        }
    }
}


/***
 * @description: Case document email
 */
function emailDocumentsApi(jwtToken, caseId, bodyData, dispatch) {
    return DocumentApi.emailDocuments(jwtToken, caseId, bodyData).then(responseData => {
        if (responseData.success === true) {
            dispatch(emailCaseSummary(responseData));
        } else {
            dispatch(onErrorUnderCase(responseData));
        }
        return new Promise((resolve, reject) => setTimeout(() => resolve(responseData), 0));
    }).catch(error => {
        dispatch(onErrorUnderCase(error));
        return Promise.reject(error);
    });
}

export function sendDocumentsEmail(caseId, bodyData) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            return (RefreshToken.validateRefreshToken().then(responseData => {
                dispatch(afterRefreshToken(responseData));
                return emailDocumentsApi(responseData.idToken.jwtToken, caseId, bodyData, dispatch);
            }).catch(err => {
                dispatch(push('/'));
                return Promise.reject(err);
            }));
        } else {
            console.log("caseId", caseId);
            return emailDocumentsApi(getApiToken(), caseId, bodyData, dispatch);
        }
    }
}