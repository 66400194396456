import React from "react";
import AppUtil, {Lang} from "../../../../common/appUtil";
import ProbateCaseLink from "./ui/probateCaseLink";
import CreateProbateCaseView from "./ui/createProbateCaseView";
import PropTypes from "prop-types";

function CreateProbateCase({sourceCaseId, probateCaseId, showCreateButton}) {
    return (<>
        {
            AppUtil.isAvailable(probateCaseId)
                ? <ProbateCaseLink id={probateCaseId}/>
                : (showCreateButton === true) &&
                <CreateProbateCaseView
                    title={Lang().cases.probates.probate}
                    sourceCaseId={sourceCaseId}
                />
        }
    </>);
}

CreateProbateCase.propTypes = {
    probateCaseId: PropTypes.any,
    showCreateButton: PropTypes.bool,
    sourceCaseId: PropTypes.number
}
export default CreateProbateCase;