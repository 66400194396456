import React from 'react';
import Modal from "react-responsive-modal";
import Select from 'react-select';
import Utility from "../../../api/utilLanguage";
import {bottomCenter} from "../customToastr";
import OrderUtil, {getOrderDeliveryMethodOptions, matchItem} from "../orderUtil";
import Button from "../button";
import AppUtil from "../appUtil";
import CaseUtil from "../caseUtil";
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as dashboardAction from '../../../actions/dashboardAction';
import * as actionMaster from '../../../actions/uiAction';
import * as caseAction from '../../../actions/caseAction';
import * as orderAction from '../../../actions/orderAction';
import * as orderDeliveryMethodsAction from "../../orderDeliveryMethods/orderDeliveryMethodsAction";
import {getContactAddressView, getLatestBillingContact} from "../../case/relative/relativeUtil";

class PopupForOrderStageChange extends React.Component {

    constructor(props) {
        super(props);
        this.allUsersByRole = [];
        this.deliveryMethods = [];
        this.billingContactRequiredCss = "";
        this.ownerRequiredCss = "";
        this.stages = CaseUtil.getFlatCaseStages();
        this.state = {
            selectedBillingContact: null,
            assignedUser: null,
            lossReason: null,
            selectedDeliveryMethod: null,
            disablePopUpOnClick: false,
            allUsersByRole: []
        }
    }

    billingContacts = (source, billingContactsForCase, allRelativesOfCaseData) => {
        return (source !== 'case') ? billingContactsForCase : allRelativesOfCaseData;
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (AppUtil.isAvailable(nextProps.caseId) && (nextProps.caseId !== this.props.caseId)) {
            this.props.caseAction.getBillingContactsOfCase(nextProps.caseId).then((responseData) => {
            }).catch(error => {
                console.log("Failure: Get billingContacts of Case = ", error);
            })
        }
        if (this.state.allUsersByRole.length === 0) {
            this.getAllUsersByRole(nextProps.assignedId, nextProps.sourceId);
        }

        const billingContacts = this.billingContacts(this.props.source, nextProps.billingContactsForCase, nextProps.allRelativesOfCaseData);
        // console.log("DEBUG: PopupForOrderStageChange componentWillReceiveProps, (Billing contacts,  selected contact, contact Id) = ", billingContacts, this.billingContact, nextProps.billingContactId);
        this.setupSelectedBillingContact(nextProps.billingContactId, billingContacts);

        this.setupCaseOwner(nextProps, this.props);
        this.updateSelectedDeliveryMethod(nextProps.deliveryMethod, this.deliveryMethods);
    };

    updateSelectedDeliveryMethod(selectedDeliveryMethod, deliveryMethods) {
        this.setupSelectedDeliveryMethod(selectedDeliveryMethod, deliveryMethods);
    }

    setupSelectedDeliveryMethod(selectedDeliveryMethod, deliveryMethods) {
        if (AppUtil.isAvailable(deliveryMethods) && selectedDeliveryMethod !== null) {
            const matchedDeliveryMethod = matchItem(deliveryMethods, selectedDeliveryMethod, "value");
            if (AppUtil.isAvailable(matchedDeliveryMethod)) {
                this.setState({selectedDeliveryMethod: selectedDeliveryMethod});
            } else {
                //Default delivery method selected
                this.changeDeliveryMethod(deliveryMethods[0].value);
            }
        } else {
            // console.log("Failure:: setup selectedDeliveryMethod");
        }
    }

    setupCaseOwner = (nextProps, props) => {
        // console.log("nextprops.assignedId", nextProps.assignedId);
        if (AppUtil.isAvailable(nextProps.assignedId)) {
            this.updateCaseOwner(nextProps.assignedId);
        } else if (AppUtil.isAvailable(nextProps.selectedOrderData) && AppUtil.isAvailable(nextProps.selectedOrderData.case)) {
            this.updateCaseOwner(nextProps.selectedOrderData.case.assignedUserId);
        }
    };

    updateCaseOwner = (assignedId) => {
        if (assignedId !== null && assignedId !== "") {
            for (let j = 0; j < this.props.allUserDetailsForCases.length; j++) {
                if (assignedId === this.props.allUserDetailsForCases[j].id) {
                    this.ownerRequiredCss = "is-success";
                    this.setState({assignedUser: this.props.allUserDetailsForCases[j]});
                    break;
                }
            }
        } else {
            this.ownerRequiredCss = "is-error";
            this.setState({assignedUser: null});
        }
    };

    getAllUsersByRole = (stageId, assignedId, sourceId) => {
        this.allUsersByRole = [];
        for (let i = 0; i < this.stages.length; i++) {
            for (let k = 0; k < this.props.allUserDetailsForCases.length; k++) {
                let userMatched = false;
                for (let j = 0; j < this.stages[i].role.length; j++) {
                    if (this.props.allUserDetailsForCases[k].roles.includes(this.stages[i].role[j])) {
                        userMatched = true;
                    }
                }
                if (userMatched === true) {
                    this.allUsersByRole.push(this.props.allUserDetailsForCases[k]);
                }
            }
            break;
        }
        if (assignedId !== null) {
            for (let j = 0; j < this.props.allUserDetailsForCases.length; j++) {
                if (assignedId === this.props.allUserDetailsForCases[j].id) {
                    this.setState({assignedUser: this.props.allUserDetailsForCases[j]});
                    break;
                }
            }
        }

        this.setState({allUsersByRole: this.allUsersByRole})
    };

    componentDidMount() {
        if (this.props.orderDeliveryMethods.length === 0) {
            this.props.orderDeliveryMethodsAction.getOrderDeliveryMethodsApi();
        }
    }

    UNSAFE_componentWillMount = () => {
        this.props.caseAction.clearBillingcontactAndDeliveryMethod();
    };

    onCloseModal = () => {
        this.deliveryMethods = [];
        this.billingContactRequiredCss = '';
        this.ownerRequiredCss = '';
        this.setState({selectedBillingContact: null, selectedDeliveryMethod: null});
        this.props.closePopup();
    };

    onConfirm = () => {
        const {selectedBillingContact, assignedUser, selectedDeliveryMethod} = this.state;
        if (AppUtil.isEmpty(assignedUser) && (this.props.destinationId === OrderUtil.orderStages.invoice.value)) {
            bottomCenter().warning(Utility.getLang().common.changeStagePopup.error.selectOwner);
        } else {
            this.props.caseAction.saveAssignedUserIdForOrders(assignedUser);
        }
        if (selectedBillingContact === null) {
            bottomCenter().warning(Utility.getLang().common.changeStagePopup.error.selectOwner);
        } else if (selectedDeliveryMethod === null 
            && (this.props.destinationId === OrderUtil.orderStages.invoice.value 
                || (this.props.destinationId === OrderUtil.orderStages.order.value 
                    && (this.props.sourceId === OrderUtil.orderStages.invoice.value 
                        || this.props.sourceId === OrderUtil.orderStages.archived.value)))) {
            bottomCenter().warning(Utility.getLang().common.changeStagePopup.error.deliveryMethod);
        } else {
            // console.log("this.billingContact 1 ", selectedBillingContact)
            this.props.caseAction.saveBillingContactOrderDrag(selectedBillingContact);
            this.props.caseAction.saveDeliveryMethodOrderDrag(selectedDeliveryMethod);
            this.setState({disablePopUpOnClick: true});
            setTimeout(function () {
                this.props.onConfirmation();
                this.billingContactRequiredCss = '';
                this.setState({
                    selectedBillingContact: null,
                    lossReason: null,
                    disablePopUpOnClick: false,
                    selectedDeliveryMethod: null
                });
            }.bind(this), 0)
        }
    };

    changeDeliveryMethod = (value) => {
        this.selectedDeliveryMethod = value;//Only used for immediate usage, as setState takes time to update state
        this.setState({selectedDeliveryMethod: value});

        if (AppUtil.isAvailable(this.props.source) && this.props.source === 'case') {
            const matchedIndex = OrderUtil.getMatchedOrderIndex(this.props.sourceOrder, this.props.selectedCaseData.orders);
            if (OrderUtil.hasFoundMatchedIndex(matchedIndex)) {
                this.props.orderAction.changeDeliveryMethodForOrderFromPopup(this.selectedDeliveryMethod, matchedIndex);
            }
        } else {
            this.props.caseAction.saveDeliveryMethodForOrders(this.selectedDeliveryMethod);
        }

        if (this.props.changeDeliveryMethod !== undefined) {
            this.props.changeDeliveryMethod(value);
        }
    };

    onSelectedCaseOwnerChange = (selectedOption) => {
        console.log("SelectedCaseOwner", selectedOption);
        this.ownerRequiredCss = "is-success";
        this.setState({assignedUser: selectedOption});
        if (AppUtil.isAvailable(this.props.selectedOrderData)) {
            this.props.caseAction.addUserToStateInOrder(selectedOption);
            this.props.caseAction.saveBillingContactIdForOrders(selectedOption);
        }

        if (this.props.source === 'case') {
            if (this.props.sourceOrder !== undefined) {
                this.props.caseAction.addUserToState(selectedOption);
                this.props.caseAction.saveAssignedUserIdForOrdersInCases(selectedOption);
            }
        }

        if (AppUtil.isAvailable(this.props.changeUser)) { //Only for case>order tab & bill card drag
            this.props.changeUser(selectedOption);
        }
    };

    onBillingContactSelectionChange = (selectedOption) => {
        console.log("selectedoption", selectedOption)
        this.setDeliveryMethodOptions(selectedOption);
        this.billingContactRequiredCss = "is-success";
        this.setState({selectedBillingContact: selectedOption});
        if (this.props.selectedOrderData.billingContactId !== undefined) {
            this.props.caseAction.saveBillingContactIdForOrders(selectedOption);
        }
        if (this.props.source === 'case') {
            if (this.props.sourceOrder !== undefined) {
                this.props.caseAction.saveBillingContactIdForOrdersInCases(selectedOption, this.props.sourceOrder);
            }
        }
        if (AppUtil.isAvailable(this.props.onSelectedBillingContactChange)) { //Only for case>order tab & bill card drag
            this.props.onSelectedBillingContactChange(selectedOption);
        }
    };

    setDeliveryMethodOptions = (selectedRelative) => {
        this.deliveryMethods = getOrderDeliveryMethodOptions(selectedRelative, this.props.orderDeliveryMethods);
    };

    setupSelectedBillingContact = (billingContactId, billingContacts) => {
        this.billingContactRequiredCss = '';
        if (AppUtil.isAvailable(billingContactId)) {
            const matchedBillingContact = AppUtil.getMatchedObject(billingContactId, billingContacts);
            if (matchedBillingContact) {
                this.billingContactRequiredCss = 'is-success';
                this.setDeliveryMethodOptions(matchedBillingContact);
                this.setupSelectedDeliveryMethod(this.props.deliveryMethod, this.deliveryMethods);//Default delivery method selected

                this.setState({selectedBillingContact: matchedBillingContact});
            }
        } else if (AppUtil.isEmpty(billingContactId) && AppUtil.isEmpty(this.state.selectedBillingContact)) {
            this.billingContactRequiredCss = 'is-error';
            this.setState({selectedBillingContact: null});
        }
    };

    render() {
        const {selectedBillingContact, assignedUser, selectedDeliveryMethod} = this.state;
        // console.log("this.deliveryMethods", this.deliveryMethods, selectedDeliveryMethod);
        let latestBillingContact = selectedBillingContact;
        if (AppUtil.isAvailable(this.props.oldOrder) && AppUtil.isAvailable(this.props.order)) {
             latestBillingContact = getLatestBillingContact(this.props.oldOrder.stage, this.props.order.stage, this.props.oldOrder.salesOrders, selectedBillingContact);
        }
        return (
            <Modal
                open={this.props.openModal}
                showCloseIcon={false}
                onClose={this.onCloseModal}
                container={document.getElementById('app')}
                center
                classNames={{
                    overlay: 'overlay',
                    modal: 'modal__wrapper',
                    closeButton: 'button button-close'
                }}
            >
                <div className="card card--modal">
                    <h3 className="card__title">{Utility.getLang().common.changeStagePopup.changeStage}</h3>
                    {
                        this.props.showCaseOwner === true ?
                            <div className="form-group">
                                {
                                    <p>{Utility.getLang().common.changeStagePopup.infoCaseOrderInvoice}</p>
                                }
                                <div className="form-group">
                                    <label htmlFor="selectOwner">
                                        {Utility.getLang().common.changeStagePopup.owner}
                                    </label>
                                    <Select
                                        getOptionLabel={({name}) => name}
                                        getOptionValue={({id}) => id}
                                        placeholder={Utility.getLang().common.changeStagePopup.ownerPlaceholder}
                                        className={"multiselect " + this.ownerRequiredCss}
                                        classNamePrefix={"multiselect"}
                                        options={this.state.allUsersByRole}
                                        onChange={this.onSelectedCaseOwnerChange}
                                        value={assignedUser}
                                    />
                                </div>
                            </div> : null
                    }
                    <p>{Utility.getLang().common.changeStagePopup.infoOrder}</p>
                    <div className="form-group">
                        <label htmlFor="selectOwner">
                            {Utility.getLang().common.changeStagePopup.billingContact}
                        </label>
                        <Select
                            getOptionLabel={(option) => AppUtil.concatenateFullName(option)}
                            getOptionValue={({id}) => id}
                            placeholder={Utility.getLang().common.changeStagePopup.billingContact}
                            className={"multiselect " + this.billingContactRequiredCss}
                            classNamePrefix={"multiselect"}
                            options={this.billingContacts(this.props.source, this.props.billingContactsForCase, this.props.allRelativesOfCaseData)}
                            onChange={this.onBillingContactSelectionChange}
                            value={selectedBillingContact}
                            noOptionsMessage={() => Utility.getLang().common.noOptionMessage}
                        />
                    </div>
                    {
                        AppUtil.isAvailable(latestBillingContact) && getContactAddressView(latestBillingContact)
                    }
                    {
                        this.props.showDeliveryType === true ?
                            <div className="form-group">
                                {
                                    this.props.sourceId === OrderUtil.orderStages.invoice.value ?
                                        <p>{Utility.getLang().common.changeStagePopup.creditMessage}</p> :
                                        <p>{Utility.getLang().common.changeStagePopup.deliveryMessage}</p>
                                }

                                <label>{Utility.getLang().common.changeStagePopup.selectDeliveryMethod}</label>
                                {
                                    (this.props.showDeliveryType && OrderUtil.hasNoDeliveryMethods(this.deliveryMethods, selectedBillingContact)) &&
                                    <p className={'is-helper'}> {OrderUtil.getOrderDeliveryMethodHelperTextOnTenantBased()}</p>
                                }
                                <select
                                    onChange={(e) => this.changeDeliveryMethod(e.target.value)}
                                    value={selectedDeliveryMethod !== null ? selectedDeliveryMethod : null}
                                    disabled={(selectedBillingContact === null) || OrderUtil.hasNoDeliveryMethods(this.deliveryMethods, selectedBillingContact)}
                                >
                                    {this.deliveryMethods.map((methods, i) => {
                                        return (
                                            <option
                                                key={i}
                                                value={methods.value}
                                            >
                                                {methods.label}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div> : null
                    }
                    {this.props.sendEmailOnSave === true ?
                        <h3>{Utility.getLang().infoMessages.popup.emailWillBeSentAreYouSure}</h3>
                        : null}
                    <div className="btn-toolbar btn-toolbar--modal">
                        {this.props.sendEmailOnSave === true ?
                            <Button
                                onClick={this.onConfirm}
                                isPrimary
                                disabled={(this.props.showCaseOwner ? AppUtil.isEmpty(assignedUser) : false) || AppUtil.isEmpty(selectedBillingContact) || this.state.disablePopUpOnClick || (this.props.showDeliveryType === true ? selectedDeliveryMethod === null : false) || (this.props.showDeliveryType && OrderUtil.hasNoDeliveryMethods(this.deliveryMethods, selectedBillingContact))}
                                icon="email"
                            >
                                {Utility.getLang().infoMessages.popup.saveAndEmailBtn}
                            </Button>
                            :
                            <Button
                                onClick={this.onConfirm}
                                isPrimary
                                disabled={AppUtil.isEmpty(selectedBillingContact) || this.state.disablePopUpOnClick || (this.props.showDeliveryType === true ? selectedDeliveryMethod === null : false) || (this.props.showDeliveryType && OrderUtil.hasNoDeliveryMethods(this.deliveryMethods, selectedBillingContact))}
                            >
                                {Utility.getLang().common.changeStagePopup.changeStageBtn}
                            </Button>
                        }
                        <Button onClick={this.onCloseModal}>
                            {Utility.getLang().common.changeStagePopup.cancelBtn}
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        allUserDetailsForCases: state.application.allUserDetailsForCases,
        selectedCaseData: state.application.selectedCaseData,
        selectedOrderData: state.application.selectedOrderData,
        billingContactsForCase: state.application.billingContactsForCase,
        orderDeliveryMethods: state.orderDeliveryMethods.orderDeliveryMethods
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        dashboardAction: bindActionCreators(dashboardAction, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch),
        orderAction: bindActionCreators(orderAction, dispatch),
        orderDeliveryMethodsAction: bindActionCreators(orderDeliveryMethodsAction, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PopupForOrderStageChange);
