import React from "react";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as actionMaster from "../../actions/uiAction";
import * as caseAction from "../../actions/caseAction";
import * as contactAction from "../../actions/contactAction";
import * as caseOrderAction from "../../actions/caseOrderAction";
import {reduxForm} from "redux-form";
import {withRouter} from "react-router-dom";
import PreLoader from "../common/preloader";
import Popup from "../common/popups/popup";
import FooterWithSave from "../common/footer";
import Icon from "../common/icon";
import Utility from "../../api/utilLanguage";
import Permalink from "../common/link/permalink";
import DisplayNothing from "../common/nothingToDisplay";
import ValidationUtil from "../common/validationUtil";
import {bottomCenter} from "../common/customToastr";
import AppUtil from "../common/appUtil";
import $ from "jquery";

class ContactDetailComponent extends React.Component {

    constructor(props) {
        super(props);
        this.disableSave = false;
        this.state = {
            disableSave: false,
            hasImageChanged: false,
        }
    }

    UNSAFE_componentWillMount = () => {
        this.props.contactAction.getPersonTypes();
    };

    componentDidUpdate = () => {
        if (this.props.selectedDataById.id !== undefined && this.props.selectedContactData.id !== undefined) {
            if (JSON.stringify(this.props.selectedDataById) === JSON.stringify(this.props.selectedContactData)) {
                console.log("Same");
                this.props.actionMaster.hideUnsavedPopupLazily();
            } else {
                this.props.actionMaster.showUnsavedPopupLazily();
                console.log("Different")
            }
        }
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.contactUpdated !== undefined && (nextProps.contactUpdated !== this.props.contactUpdated)) {
            if (nextProps.contactUpdated === true) {
                this.loadingActionByValue(false);
            }
        }
    };

    loadingActionByValue = (value) => {
        this.disableSave = value;
        this.setState({disableSave: value});
    };

    // createAndUpdateContact = () => {
    //     console.log("Button Clicked");
    // };

    createAndUpdateContact = () => {
        this.loadingActionByValue(true);
        this.props.contactAction.updateContactUpdatingStatus(false);
        var validationMessages = ValidationUtil.validateContactPersonType(this.props.selectedContactData);
        if (validationMessages.length > 0) {
            validationMessages.forEach(function (validationMessage) {
                bottomCenter().warning(validationMessage);
            });
            this.loadingActionByValue(false);
            return
        }

        if (this.props.selectedContactData.id !== undefined) {
            let newData = {
                "id": this.props.selectedContactData.id !== undefined ? this.props.selectedContactData.id : 0,
                "name": this.props.selectedContactData.name,
                "email": this.props.selectedContactData.email,
                "phoneNumber": this.props.selectedContactData.phoneNumber,
                "personType": this.props.selectedContactData.personTypeId,
                "isActive": this.props.selectedContactData.isActive,
                "city": this.props.selectedContactData.contactAddress.city,
                "adminPersonId": this.props.selectedContactData.adminPersonId,
                "address": this.props.selectedContactData.contactAddress.address,
                "postalAddress": this.props.selectedContactData.contactAddress.postalAddress,
                "longitude": this.props.selectedContactData.contactAddress.longitude !== "" ? parseFloat(this.props.selectedContactData.contactAddress.longitude) : 0,
                "latitude": this.props.selectedContactData.contactAddress.latitude !== "" ? parseFloat(this.props.selectedContactData.contactAddress.latitude) : 0,
                "placeId": 0,
                "comment": this.props.selectedContactData.comment,
                "mailingAddress": {
                    "address": this.props.selectedContactData.mailingAddress.address !== undefined && this.props.selectedContactData.mailingAddress.address !== null ? this.props.selectedContactData.mailingAddress.address : null,
                    "postalAddress": this.props.selectedContactData.mailingAddress.postalAddress !== undefined && this.props.selectedContactData.mailingAddress.address !== null ? this.props.selectedContactData.mailingAddress.postalAddress : null,
                    "city": this.props.selectedContactData.mailingAddress.city !== undefined && this.props.selectedContactData.mailingAddress.address !== null ? this.props.selectedContactData.mailingAddress.city : null
                }
            };
            this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isUpdating);
            this.props.contactAction.updateContactInfoApiCall(newData, this.props.storingKey).then().finally(() => {
                if (this.state.hasImageChanged === true) {
                    this.props.contactAction.uploadPersonImageApiCall(this.FormData, this.props.selectedContactData.id, this.props.storingKey).then().finally(() => {
                        this.setState({hasImageChanged: false});
                        this.FormData = '';
                    });
                }
                $("#accountProfilePicture").val('');
                setTimeout(() => {
                    this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
                }, 500);
            });
        } else {
            let newData = {
                "id": 0,
                "name": this.props.selectedContactData.name,
                "email": this.props.selectedContactData.email,
                "phoneNumber": this.props.selectedContactData.phoneNumber,
                "personType": this.props.selectedContactData.personTypeId !== undefined && this.props.selectedContactData.personTypeId !== null ? this.props.selectedContactData.personTypeId : 1,
                "isActive": true,
                "adminPersonId": null,
                "address": this.props.selectedContactData.contactAddress !== undefined ? this.props.selectedContactData.contactAddress.address : null,
                "postalAddress": this.props.selectedContactData.contactAddress !== undefined ? this.props.selectedContactData.contactAddress.postalAddress : null,
                "city": this.props.selectedContactData.contactAddress !== undefined ? this.props.selectedContactData.contactAddress.city : null,
                "longitude": 0,
                "latitude": 0,
                "placeId": 0,
                "comment": this.props.selectedContactData.comment,
                "mailingAddress": {
                    "address": this.props.selectedContactData.mailingAddress !== undefined && this.props.selectedContactData.mailingAddress !== null ? this.props.selectedContactData.mailingAddress.address : null,
                    "postalAddress": this.props.selectedContactData.mailingAddress !== undefined && this.props.selectedContactData.mailingAddress !== null ? this.props.selectedContactData.mailingAddress.postalAddress : null,
                    "city": this.props.selectedContactData.mailingAddress !== undefined && this.props.selectedContactData.mailingAddress !== null ? this.props.selectedContactData.mailingAddress.city : null
                }
            };
            this.props.contactAction.addContactInfoApiCall(newData, this.props.storingKey).then().finally();
        }
    };

    onImageChange(event) {
        if (event.target !== undefined) {
            let file = event.target.files[0];
            this.setState({formdataUpload: event.target.files[0]});
            if (event.target.files && event.target.files[0]) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    this.setState({image: e.target.result});
                    this.props.contactAction.changeContactTextField(e.target.result, "imageUrl");
                    this.setState({hasImageChanged: true});
                };
                reader.readAsDataURL(event.target.files[0]);
            }
            this.FormData = new FormData();
            this.FormData.append('file', file);
        } else {
            this.setState({hasImageChanged: false});
        }
    }

    changeContactTextField = (e, id) => {
        this.props.contactAction.changeContactTextField(e.target.value, id);
    };

    onConfirmDelete = (d) => {
        this.toDeleteData = d;
        console.log("this.toDeleteData", this.toDeleteData);
        this.props.actionMaster.showPopup();
    };

    hidePopup = () => {
        this.props.actionMaster.hidePopupSection();
    };

    onDelete = () => {
        this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isDeleting);
        this.props.contactAction.deleteContactInfoApiCall(this.props.selectedContactData, this.props.storingKey).then().finally(() => {
            setTimeout(() => {
                this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
            }, 500);
        });
        this.hidePopup();
    };

    toMoveNewRoute = () => {
        this.props.actionMaster.clearRouteCondition();
        if (this.props.headerMenuItemClicked !== this.props.history.location.pathname) {
            this.props.history.push(this.props.headerMenuItemClicked);
        }
    };
    currentRoute = () => {
        this.props.actionMaster.onHeaderMenuItemClicked(this.props.history.location.pathname);
        this.props.actionMaster.closePopupWithSameRoute();
    };

    render = () => {
        return (
            <React.Fragment>
                <PreLoader onRef={ref => this.preLoader = ref} showPreload={false}
                           container={this.node} blockContainer={true} type="Oval"/>
                <Popup openModal={this.props.showUnsavedPopup}
                       headerInfo={Utility.getLang().infoMessages.popup.unsavedChanges}
                       onConfirmation={this.toMoveNewRoute}
                       closePopup={this.currentRoute}/>
                {
                    (this.props.displayNothing === false && AppUtil.isAvailable(this.props.selectedContactData)) ?
                        <div className="section section--detail section--scroll">
                            <div className="section__head">
                                <div className="inner">
                                    <h3 className="section__title">
                                        <Icon type="contact"/>
                                        <span className="description">
                                            {this.props.selectedContactData.name}
                                        </span>
                                        <Permalink id={this.props.selectedContactData.id}
                                                   path1={AppUtil.linkPaths.contact.basePath}
                                                   path2={AppUtil.linkPaths.contact.details}/>
                                    </h3>
                                </div>
                            </div>
                            <div className="section__content section__content--call_details">
                                <div className="inner">
                                    <form>
                                        <div className="section__columns">
                                            <div className="section__column">
                                                <div className="form-group">
                                                    <label
                                                        htmlFor="#">{Utility.getLang().contacts.detailsTabContent.contactName}</label>
                                                    <input
                                                        id="name"
                                                        name="name"
                                                        placeholder={Utility.getLang().contacts.detailsTabContent.contactName}
                                                        className="text text--max_width"
                                                        type="text"
                                                        defaultValue={this.props.selectedContactData.name}
                                                        onBlur={(e) => this.changeContactTextField(e, "name")}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label
                                                        htmlFor="#">{Utility.getLang().contacts.detailsTabContent.phone}</label>
                                                    <input
                                                        id="phoneNumber"
                                                        name="phoneNumber"
                                                        placeholder={Utility.getLang().contacts.detailsTabContent.phone}
                                                        className="text text--max_width text--number"
                                                        type="text"
                                                        defaultValue={this.props.selectedContactData.phoneNumber}
                                                        onBlur={(e) => this.changeContactTextField(e, "phoneNumber")}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label
                                                        htmlFor="#">{Utility.getLang().contacts.detailsTabContent.email}</label>
                                                    <input
                                                        id="email"
                                                        name="email"
                                                        placeholder={Utility.getLang().contacts.detailsTabContent.email}
                                                        className="text text--max_width"
                                                        type="text"
                                                        defaultValue={this.props.selectedContactData.email}
                                                        onBlur={(e) => this.changeContactTextField(e, "email")}
                                                    />
                                                </div>
                                                <div className="form-group form-group--columns">
                                                    <div className="form-group__column">
                                                        <label
                                                            htmlFor="#">{Utility.getLang().contacts.detailsTabContent.address}</label>
                                                        <input
                                                            id="address"
                                                            name="address"
                                                            placeholder={Utility.getLang().contacts.detailsTabContent.address}
                                                            className="text text--max_width"
                                                            type="text"
                                                            defaultValue={this.props.selectedContactData.contactAddress !== undefined && this.props.selectedContactData.contactAddress !== null ? this.props.selectedContactData.contactAddress.address : ""}
                                                            onBlur={(e) => this.changeContactTextField(e, "address")}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group form-group--columns">
                                                    <div className="form-group__column">
                                                        <label
                                                            htmlFor="#">{Utility.getLang().contacts.detailsTabContent.postalCode}</label>
                                                        <input
                                                            id="postalCode"
                                                            name="postalCode"
                                                            placeholder={Utility.getLang().contacts.detailsTabContent.postalCode}
                                                            className="text text--zip"
                                                            type="text"
                                                            defaultValue={this.props.selectedContactData.contactAddress !== undefined && this.props.selectedContactData.contactAddress !== null ? this.props.selectedContactData.contactAddress.postalAddress : ""}
                                                            onBlur={(e) => this.changeContactTextField(e, "postalAddress")}
                                                        />
                                                    </div>
                                                    <div className="form-group__column">
                                                        <label
                                                            htmlFor="#">{Utility.getLang().contacts.detailsTabContent.city}</label>
                                                        <input
                                                            id="city"
                                                            name="city"
                                                            placeholder={Utility.getLang().contacts.detailsTabContent.city}
                                                            className="text text--max_width"
                                                            type="text"
                                                            defaultValue={this.props.selectedContactData.contactAddress !== undefined && this.props.selectedContactData.contactAddress !== null ? this.props.selectedContactData.contactAddress.city : ""}
                                                            onBlur={(e) => this.changeContactTextField(e, "city")}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label
                                                        htmlFor="#">{Utility.getLang().contacts.detailsTabContent.country}</label>
                                                    <input
                                                        id="County"
                                                        name="County"
                                                        placeholder={Utility.getLang().contacts.detailsTabContent.country}
                                                        className="text text--max_width"
                                                        type="text"
                                                        onChange={(e) => this.changeContactTextField(e, "country")}
                                                    />
                                                </div>
                                                <h4 className="section__title">{Utility.getLang().contacts.detailsTabContent.mailingAddress.mailingAddress}</h4>
                                                <div className="form-group form-group--columns">
                                                    <div className="form-group__column">
                                                        <label
                                                            htmlFor="#">{Utility.getLang().contacts.detailsTabContent.mailingAddress.address}</label>
                                                        <input
                                                            id="mailingAddress"
                                                            name="mailingAddress"
                                                            placeholder={Utility.getLang().contacts.detailsTabContent.mailingAddress.address}
                                                            className="text text--max_width"
                                                            type="text"
                                                            defaultValue={this.props.selectedContactData.mailingAddress !== null && this.props.selectedContactData.mailingAddress !== undefined ? this.props.selectedContactData.mailingAddress.address : ""}
                                                            onBlur={(e) => this.changeContactTextField(e, "mailingAddress")}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group form-group--columns">
                                                    <div className="form-group__column">
                                                        <label
                                                            htmlFor="#">{Utility.getLang().contacts.detailsTabContent.mailingAddress.postalCode}</label>
                                                        <input
                                                            id="mailingPostalAddress"
                                                            name="mailingPostalAddress"
                                                            placeholder={Utility.getLang().contacts.detailsTabContent.mailingAddress.postalCode}
                                                            className="text text--zip"
                                                            type="text"
                                                            defaultValue={this.props.selectedContactData.mailingAddress !== null && this.props.selectedContactData.mailingAddress !== undefined ? this.props.selectedContactData.mailingAddress.postalAddress : ""}
                                                            onBlur={(e) => this.changeContactTextField(e, "mailingPostalAddress")}
                                                        />
                                                    </div>
                                                    <div className="form-group__column">
                                                        <label
                                                            htmlFor="#">{Utility.getLang().contacts.detailsTabContent.mailingAddress.city}</label>
                                                        <input
                                                            id="mailingCity"
                                                            name="mailingCity"
                                                            placeholder={Utility.getLang().contacts.detailsTabContent.mailingAddress.city}
                                                            className="text text--max_width"
                                                            type="text"
                                                            defaultValue={this.props.selectedContactData.mailingAddress !== null && this.props.selectedContactData.mailingAddress !== undefined ? this.props.selectedContactData.mailingAddress.city : ""}
                                                            onBlur={(e) => this.changeContactTextField(e, "mailingCity")}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="section__column section__column--small">
                                                <div className="form-group">
                                                    <label
                                                        htmlFor="#">{Utility.getLang().contacts.detailsTabContent.type}</label>
                                                    <select
                                                        placeholder={Utility.getLang().contacts.detailsTabContent.type}
                                                        disabled={false}
                                                        type="select"
                                                        value={this.props.selectedContactData.personTypeId}
                                                        onChange={(e) => this.changeContactTextField(e, "personTypeId")}
                                                    >
                                                        <option>{Utility.getLang().common.dropDownOptionPleaseSelect}</option>
                                                        {
                                                            this.props.allPersonTypes.map((typeName, i) => {
                                                                    return (<option key={i}
                                                                                    value={typeName.key}>{typeName.value}</option>);
                                                                }
                                                            )
                                                        }
                                                    </select>
                                                </div>
                                                {
                                                    this.props.selectedContactData.id !== undefined ?
                                                        <h5>{Utility.getLang().contacts.detailsTabContent.picture}</h5> : null
                                                }
                                                {
                                                    this.props.selectedContactData.id !== undefined ?
                                                        <div className="assets">
                                                            <div className="asset">
                                                                {
                                                                    this.props.selectedContactData.url !== undefined && this.props.selectedContactData.url !== null ?
                                                                        <div className="asset__preview">
                                                                            <a
                                                                                href={this.props.selectedContactData.url !== undefined && this.props.selectedContactData.url !== null ? this.props.selectedContactData.url : null}
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                            >
                                                                                <img
                                                                                    src={this.props.selectedContactData.url !== undefined && this.props.selectedContactData.url !== null ? this.props.selectedContactData.url : null}
                                                                                    height="300"
                                                                                    className={'image_ratio'}
                                                                                    alt={Utility.getLang().common.avatar}
                                                                                    //alt={this.props.initialValues.locationMedias !== undefined && this.props.initialValues.locationMedias !== null && this.state.image === '' ? this.props.initialValues.locationMedias.altText : this.state.altText}
                                                                                />
                                                                            </a>
                                                                        </div> : null
                                                                }
                                                                <div className="form-group">
                                                                    <label htmlFor="accountProfilePicture">
                                                                        {Utility.getLang().common.uploadPicture}
                                                                    </label>
                                                                    <input
                                                                        id="accountProfilePicture"
                                                                        name="accountProfilePicture"
                                                                        className="text file"
                                                                        type="file"
                                                                        accept="image/png, image/jpeg"
                                                                        onChange={this.onImageChange.bind(this)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div> : null
                                                }

                                                <div className="form-group">
                                                    <label htmlFor="contactDetailsComments">
                                                        {Utility.getLang().contacts.detailsTabContent.comments}
                                                    </label>
                                                    <textarea id="contactDetailsComments"
                                                              className="textarea textarea--max_width textarea--tall"
                                                              defaultValue={this.props.selectedContactData.comment}
                                                              onBlur={(e) => this.changeContactTextField(e, "comment")}>
                                                     </textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div> : <DisplayNothing/>

                }
                <FooterWithSave SaveData={this.createAndUpdateContact} hideFooter={this.props.displayNothing}
                                buttonText={Utility.getLang().contacts.footer.save}
                                deleteButtonText={Utility.getLang().contacts.footer.delete}
                                deleteCase={this.onConfirmDelete}
                                disableSaveButton={this.disableSave}
                                hideRelativeDropdown={true}/>
                <Popup
                    openModal={this.props.open}
                    headerInfo={Utility.getLang().infoMessages.popup.deleteContact + ` ` + this.props.selectedDataById.name + `?`}
                    onConfirmation={this.onDelete}
                    closePopup={this.hidePopup}
                    isDestructive
                    withTimeOut
                />
            </React.Fragment>
        )
    }
}

ContactDetailComponent.propTypes = {
    columnDetailViewTabCollection: PropTypes.array,
};

function mapStateToProps(state) {
    return {
        initialValues: state.application.selectedDataById,
        selectedDataById: state.application.selectedDataById,
        selectedContactData: state.application.selectedContactData,
        showUnsavedPopup: state.application.showUnsavedPopup,
        headerMenuItemClicked: state.application.headerMenuItemClicked,
        open: state.application.open,
        allPersonTypes: state.application.allPersonTypes,
        displayNothing: state.application.displayNothing,
        contactUpdated: state.application.contactUpdated,
    };

}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        caseOrderAction: bindActionCreators(caseOrderAction, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch),
        contactAction: bindActionCreators(contactAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: "ContactDetailComponent  "
})(withRouter(ContactDetailComponent)));
