import React from "react";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import Icon from "../common/icon";
import {bindActionCreators} from "redux";
import * as actionMaster from "../../actions/uiAction";
import * as locationAction from "../../actions/locationAction";
import {getLocationCreateOrUpdateRequest} from "../../actions/locationAction";
import * as caseAction from "../../actions/caseAction";
import Select from "react-select";
import FooterWithSave from "../common/footer";
import Utility from "../../api/utilLanguage";
import Popup from "../common/popups/popup";
import {reduxForm} from "redux-form";
import {withRouter} from "react-router-dom";
import AccessControl from "../../api/accessControl";
import Permalink from "../common/link/permalink";
import DisplayNothing from "../common/nothingToDisplay";
import PreLoader from "../common/preloader";
import Button from "../common/button";
import AppUtil from "../common/appUtil";

class LocationContactComponent extends React.Component {

    constructor(props) {
        super(props);
        this.disableSave = false;
        this.state = {
            selectedOption: null,
            disableSave: false
        }
    }

    componentDidMount = () => {
        this.props.actionMaster.closePopupWithSameRoute();
        this.props.actionMaster.clearRouteCondition();
        this.props.locationAction.getPersonAllInfo();
        if (this.props.selectedLocationData !== undefined) {
            if (this.props.selectedLocationData.adminPerson !== undefined && this.props.selectedLocationData.adminPerson !== null) {
                let data = this.props.selectedLocationData.adminPerson;
                data.label = data.name;
                data.value = data.id;
                this.setState({selectedOption: data});
            }
        }
    };

    componentDidUpdate = () => {
        if (this.props.selectedDataById.id !== undefined && this.props.selectedLocationData.id !== undefined) {
            if (JSON.stringify(this.props.selectedDataById) === JSON.stringify(this.props.selectedLocationData)) {
                console.log("Same");
                this.props.actionMaster.hideUnsavedPopupLazily();
            } else {
                this.props.actionMaster.showUnsavedPopupLazily();
                console.log("Different")
            }
        }
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.locationUpdated !== undefined && (nextProps.locationUpdated !== this.props.locationUpdated)) {
            if (nextProps.locationUpdated === true) {
                this.loadingActionByValue(false);
            }
        }
    };

    loadingActionByValue = (value) => {
        this.disableSave = value;
        this.setState({disableSave: value});
    };

    onConfirmDelete = (d) => {
        this.toDeleteData = d;
        console.log("this.toDeleteData", this.toDeleteData);
        this.props.actionMaster.showPopup();
    };

    hidePopup = () => {
        this.props.actionMaster.hidePopupSection();
    };

    onDelete = () => {
        this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isDeleting);
        this.props.locationAction.deleteLocationApiCall(this.props.initialValues, this.props.storingKey);
        setTimeout(function () {
            this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
        }.bind(this), 1000);
        this.hidePopup();
    };

    onSelected = () => {
        this.props.onCardSelected(this.props.card);
    };

    toMoveNewRoute = () => {
        this.props.actionMaster.clearRouteCondition();
        if (this.props.headerMenuItemClicked !== this.props.history.location.pathname) {
            this.props.history.push(this.props.headerMenuItemClicked);
        }
    };
    currentRoute = () => {
        this.props.actionMaster.onHeaderMenuItemClicked(this.props.history.location.pathname);
        this.props.actionMaster.closePopupWithSameRoute();
    };

    removePerson = () => {
        console.log("removing");
        this.props.locationAction.changeLocationTextField(null, "removeadminPersonId");
        this.setState({selectedOption: null});

        // let data = {
        //     "id": this.props.initialValues.id,
        //     "name": this.props.initialValues.name,
        //     "locationtype": this.props.initialValues.typeId,
        //     "isactive": this.props.initialValues.isActive,
        //     "adminpersonid": null,
        //     "built": this.props.initialValues.built,
        //     "comment": this.props.initialValues.comment,
        //     "slug": this.props.initialValues.slug,
        //     "seoTitle": this.props.initialValues.seoTitle,
        //     "seoMetaDescription": this.props.initialValues.seoMetaDescription,
        //     "address": this.props.initialValues.contactAddress.address,
        //     "postaladdress": this.props.initialValues.contactAddress.postalAddress,
        //     "longitude": this.props.initialValues.contactAddress.longitude,
        //     "latitude": this.props.initialValues.contactAddress.latitude,
        //     "placeid": this.props.initialValues.contactAddress.placeId,
        //     "attributes": this.props.initialValues.attributes,
        //     "medias": this.props.initialValues.locationMedias
        // };
        //
        // this.props.locationAction.updateLocationInfoApiCall(data);

    };

    handleChange = (selectedOption) => {
        this.setState({selectedOption: selectedOption});
        this.props.locationAction.changeLocationTextField(selectedOption.value, "addadminPersonId");

        // let data = {
        //     "id": this.props.initialValues.id,
        //     "name": this.props.initialValues.name,
        //     "locationtype": this.props.initialValues.typeId,
        //     "isactive": this.props.initialValues.isActive,
        //     "adminpersonid": selectedOption.value,
        //     "built": this.props.initialValues.built,
        //     "comment": this.props.initialValues.comment,
        //     "slug": this.props.initialValues.slug,
        //     "seoTitle": this.props.initialValues.seoTitle,
        //     "seoMetaDescription": this.props.initialValues.seoMetaDescription,
        //     "address": this.props.initialValues.contactAddress.address,
        //     "postaladdress": this.props.initialValues.contactAddress.postalAddress,
        //     "longitude": this.props.initialValues.contactAddress.longitude,
        //     "latitude": this.props.initialValues.contactAddress.latitude,
        //     "placeid": this.props.initialValues.contactAddress.placeId,
        //     "attributes": this.props.initialValues.attributes,
        //     "medias": this.props.initialValues.locationMedias
        // };

        //this.props.locationAction.updateLocationInfoApiCall(data);
    };

    updateLocation = () => {
        this.loadingActionByValue(true);
        this.props.locationAction.updateLocationUpdatingStatus(false);

        let data = getLocationCreateOrUpdateRequest(this.props.selectedLocationData);
        this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isUpdating);
        this.props.locationAction.updateLocationInfoApiCall(data, this.props.storingKey).then(() => {
        }).finally(() => {
            this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
        });
    };


    render = () => {
        const options = this.props.allPersonDir;
        return (
            <React.Fragment>
                <PreLoader
                    onRef={ref => this.preLoader = ref}
                    showPreload={false}
                    container={this.node}
                    blockContainer={true}
                    type="Oval"
                />
                <Popup
                    openModal={this.props.showUnsavedPopup}
                    headerInfo={Utility.getLang().infoMessages.popup.unsavedChanges}
                    onConfirmation={this.toMoveNewRoute}
                    closePopup={this.currentRoute}
                />
                {
                    this.props.displayNothing === false ?
                        <div className="section section--detail section--scroll" ref={node => this.node = node}>
                            <div className="section__head">
                                <div className="inner">
                                    <h3 className="section__title">
                                        <Icon type="location"/>
                                        <span className="description">
                                            {this.props.selectedLocationData !== undefined ? this.props.selectedLocationData.name : null}
                                        </span>
                                        <Permalink
                                            id={this.props.selectedLocationData.id}
                                            path1={AppUtil.linkPaths.location.basePath}
                                            path2={AppUtil.linkPaths.location.details}
                                        />
                                    </h3>
                                </div>
                            </div>
                            <div className="section__content section__content--location_contacts">
                                <div className="drawer">
                                    {
                                        this.props.selectedLocationData.adminPerson !== undefined && this.props.selectedLocationData.adminPerson !== null ?
                                            <div
                                                className="item item--button item--product item--product_child"
                                                tabIndex="0"
                                            >
                                                <h4 className="item__meta item__title item__meta--title">
                                                    <Icon type="contact" tiny/>
                                                    <span className="description">
                                                        {this.props.selectedLocationData.adminPerson.name}
                                                    </span>
                                                    {
                                                        this.props.selectedLocationData !== undefined && this.props.selectedLocationData !== null && this.props.selectedLocationData.adminPerson !== undefined && this.props.selectedLocationData.adminPerson !== null ?
                                                            <Permalink
                                                                id={this.props.selectedLocationData.adminPerson.id}
                                                                path1={"/contacts/"}
                                                                path2={"/ContactDetail"}
                                                            /> : null
                                                    }
                                                </h4>
                                                <span className="item__meta item__meta--phone item__meta--number">
                                                    <Icon type="phone" tiny/>
                                                    <a href="tel:07319238142" className="description">
                                                        {this.props.selectedLocationData.adminPerson.phoneNumber}
                                                    </a>
                                                </span>
                                                <span className="item__meta item__meta--phone item__meta--email">
                                                    <Icon type="email" tiny/>
                                                    <a
                                                        href="mailto:josefine.hstrom@svenskakyrkan.se"
                                                        className="description"
                                                    >
                                                        {this.props.selectedLocationData.adminPerson.email}
                                                    </a>
                                                </span>
                                                <div className="item__footer">
                                                    <div className="item__tags">
                                                        <span className="tags tags--types tags--small">
                                                            <span className="tag tag--type tag--role">
                                                                Admin {/* /// i10n */}
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="item__toolbar" onClick={this.removePerson}>
                                                    <div className="btn-toolbar">
                                                        <Button isTertiary isDestructive>
                                                            {Utility.getLang().common.remove}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        AccessControl.revokedAccess().crudLocation === true ?
                                            null :
                                            this.props.selectedLocationData.adminPerson !== undefined && this.props.selectedLocationData.adminPerson !== null ?
                                                null :
                                                <div className="form-group">
                                                    <label
                                                        htmlFor="#">{Utility.getLang().locations.contactTabContent.addContact}</label>
                                                    <Select
                                                        options={options}
                                                        value={this.state.selectedOption}
                                                        placeholder={Utility.getLang().common.dropDownOptionPleaseSelect}
                                                        onChange={this.handleChange}
                                                        className="multiselect"
                                                        classNamePrefix="multiselect"
                                                        noOptionsMessage={() => Utility.getLang().common.noOptionMessage}
                                                    />
                                                </div>
                                    }
                                </div>
                            </div>
                        </div>
                        : <DisplayNothing/>
                }
                {
                    AccessControl.revokedAccess().crudLocation === true ?
                        null :
                        <FooterWithSave
                            SaveData={this.updateLocation}
                            hideFooter={this.props.displayNothing}
                            buttonText={Utility.getLang().locations.detailsTabContent.updateDetails}
                            deleteButtonText={Utility.getLang().locations.detailsTabContent.deletelocation}
                            deleteCase={this.onConfirmDelete}
                            hideRelativeDropdown={true}
                            disableSaveButton={this.disableSave}
                        />
                }

                <Popup
                    openModal={this.props.open}
                    headerInfo={Utility.getLang().infoMessages.popup.deleteLocation + ` ` + this.props.initialValues.name + `?`}
                    onConfirmation={this.onDelete}
                    closePopup={this.hidePopup}
                    isDestructive
                    withTimeOut
                />
            </React.Fragment>
        )
    }
}

LocationContactComponent.propTypes = {
    selectedCard: PropTypes.object,
    columnDetailViewTabCollection: PropTypes.array,
};

function mapStateToProps(state) {
    return {
        initialValues: state.application.selectedDataById,
        selectedLocationData: state.application.selectedLocationData,
        selectedDataById: state.application.selectedDataById,
        allPersonDir: state.application.allPersonDir,
        locationTypes: state.application.locationTypes,
        headerMenuItemClicked: state.application.headerMenuItemClicked,

        open: state.application.open,
        showUnsavedPopup: state.application.showUnsavedPopup,

        displayNothing: state.application.displayNothing,
        locationUpdated: state.application.locationUpdated,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        locationAction: bindActionCreators(locationAction, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: "LocationContactComponent"
})(withRouter(LocationContactComponent)));
