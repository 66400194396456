import React from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import Button from "../common/button";
import Tooltip from "../common/tooltip";
import {bindActionCreators} from "redux";
import * as actionMaster from "../../actions/uiAction";
import * as caseAction from "../../actions/caseAction";
import {reduxForm} from "redux-form";
import FormValidator from "../common/formValidator";
import Utility from "../../api/utilLanguage";
import AppUtil from "../common/appUtil";
import CreatableSelect from "react-select/lib/Creatable";
import GuestUtil from "./caseGuestsSubComponents/guestUtil";
import {cloneDeep} from "lodash";

class CaseGuestFoodRestriction extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            foodRestrictions: [],
            formattedFoodRestrictions: []
        }
    }

    UNSAFE_componentWillMount = () => {
        this.updateFormattedFoodRestrictions(this.props.foodRestrictionList);
    };

    componentDidMount = () => {
        this.setup();
    };

    setup = () => {
        let {guestFoodRestrictions} = this.props || {};
        this.updateFoodRestrictionsByCloning(guestFoodRestrictions);
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (JSON.stringify(this.props.foodRestrictionList) !== JSON.stringify(nextProps.foodRestrictionList)) {
            this.updateFormattedFoodRestrictions(nextProps.foodRestrictionList);
        }
        this.updateFoodRestrictionsIfChanges(this.props, nextProps);
    };

    updateFoodRestrictionsIfChanges = (props, nextProps) => {
        const {guestIndexToEdit} = nextProps;
        const oldGuests = props.selectedCaseData.guests;
        const newGuests = nextProps.selectedCaseData.guests;
        if ((oldGuests.length > guestIndexToEdit) && (newGuests.length > guestIndexToEdit)) {
            const oldGuest = oldGuests[guestIndexToEdit];
            const newGuest = newGuests[guestIndexToEdit];
            if (AppUtil.isAvailable(newGuest) && AppUtil.isAvailable(oldGuest) && (JSON.stringify(oldGuest.foodRestrictions) !== JSON.stringify(newGuest.foodRestrictions))) {
                console.log("componentWillReceiveProps foodRestrictions = ", newGuest.foodRestrictions);
                this.updateFoodRestrictionsByCloning(newGuest.foodRestrictions);
            }
        }
    };

    goToGuestList = () => {
        this.props.backToGuestList();
    };

    editGuestDetails = (e, fieldName) => {
        this.props.onChangeData(e, fieldName);
    };

    deleteGuest = (value, field) => {
        this.props.deleteGuest(value, field);
    };

    /***
     * @description: Data source helpers
     */
    updateFormattedFoodRestrictions = (foodRestrictions) => {
        const formattedFoodRestrictions = GuestUtil.getFormattedFoodRestrictionForCreateSelect(foodRestrictions);
        this.setState({formattedFoodRestrictions: formattedFoodRestrictions});
    };

    updateFoodRestrictionsByCloning = (foodRestrictions) => {
        if (AppUtil.isAvailable(foodRestrictions)) {
            this.updateFoodRestrictions(GuestUtil.getFormatterFoodRestrictions(cloneDeep(foodRestrictions)));
        }
    };

    updateFoodRestrictions = (value) => {
        this.setState({foodRestrictions: value});
    };

    onChangeSingleFoodRestriction = (selectedRestriction) => {
        GuestUtil.updateCustomFoodRestriction(selectedRestriction);

        let newRestriction = {
            id: 0,
            number: 1,
            restrictions: [selectedRestriction]
        };
        let oldFoodRestrictions = this.state.foodRestrictions;
        oldFoodRestrictions.push(newRestriction);
        this.updateFoodRestrictions(oldFoodRestrictions);

        const newFoodRestrictions = GuestUtil.getFilteredFoodRestrictions(oldFoodRestrictions);
        this.props.editFoodRestriction(newFoodRestrictions);//oldFoodRestrictions
    };

    onChangeMultipleFoodRestriction = (selectedRestrictions, foodRestrictionIndex) => {
        if (selectedRestrictions.length > 0) {
            const index = selectedRestrictions.length - 1;
            let selectedRestriction = selectedRestrictions[index];
            GuestUtil.updateCustomFoodRestriction(selectedRestriction);
        }

        let oldFoodRestrictions = this.state.foodRestrictions;
        let foodRestriction = oldFoodRestrictions[foodRestrictionIndex];
        foodRestriction.restrictions = selectedRestrictions;
        this.updateFoodRestrictions(oldFoodRestrictions);

        const newFoodRestrictions = GuestUtil.getFilteredFoodRestrictions(oldFoodRestrictions);
        this.props.editFoodRestriction(newFoodRestrictions);//oldFoodRestrictions
    };

    onInputItemChange = (inputValue, actionMeta) => {
        console.log("onInputItemChange", inputValue);
    };

    onChangeFoodRestrictionNumber = (e, i) => {
        let oldRestriction = this.state.foodRestrictions;
        oldRestriction[i].number = parseInt(e.target.value, 10);
        this.updateFoodRestrictions(oldRestriction);
        this.props.editFoodRestriction(oldRestriction);
    };

    onDeleteFoodRestrictionNumber = (i) => {
        let oldRestriction = this.state.foodRestrictions;
        oldRestriction.splice(i, 1);
        this.updateFoodRestrictions(oldRestriction);
        this.props.editFoodRestriction(oldRestriction);
    };

    render = () => {
        const {foodRestrictions, formattedFoodRestrictions} = this.state || [];
        // console.log("[DEBUG]:: guestFoodRestrictions = ", this.props.guestFoodRestrictions, foodRestrictions);
        return (
            <React.Fragment>
                <label htmlFor="addDietaryRestrictionSelect">
                    {Utility.getLang().cases.guestTabContent.dietaryRestrictions}
                </label>
                {
                    AppUtil.isAvailable(foodRestrictions) && foodRestrictions.map((foodRestriction, foodRestrictionIndex) => {
                        return <div key={foodRestrictionIndex} className="btn-toolbar btn-toolbar--dietary_restriction nowrap">
                            <CreatableSelect
                                isMulti
                                getOptionLabel={({label}) => label}
                                getOptionValue={({id}) => id}
                                options={formattedFoodRestrictions}
                                value={foodRestriction.restrictions}
                                placeholder={Utility.getLang().cases.guestTabContent.addDietaryRestriction}
                                className={"multiselect multiselect--max_width"}
                                onInputChange={this.onInputItemChange}
                                classNamePrefix={"multiselect"}
                                formatCreateLabel={e => Utility.getLang().common.dropDownCreateMessage + `"${e}"`}
                                onChange={(option) => this.onChangeMultipleFoodRestriction(option, foodRestrictionIndex)}
                            />
                            <div className="field field--min_width no_label has-unit">
                                <input
                                    id={"dietGuestCount" + foodRestrictionIndex}
                                    type="number"
                                    min={0}
                                    onKeyPress={(e) => AppUtil.restrictMinus(e)}
                                    className="text text--min_width"
                                    value={foodRestriction.number}
                                    onChange={(e) => this.onChangeFoodRestrictionNumber(e, foodRestrictionIndex)}
                                    data-tip
                                />
                                <Tooltip>
                                    {Utility.getLang().common.unitFull.people}
                                </Tooltip>
                                <p className="field__unit">
                                    {Utility.getLang().common.unit.people}
                                </p>
                            </div>
                            <Button
                                onClick={() => this.onDeleteFoodRestrictionNumber(foodRestrictionIndex)}
                                isTertiary
                                isDestructive
                                noLabel
                            />
                        </div>
                    })
                }
                <div className="btn-toolbar btn-toolbar--dietary_restriction btn-toolbar--dietary_restriction--add nowrap">
                    <CreatableSelect
                        inputId="addDietaryRestrictionSelect"
                        getOptionLabel={({label}) => label}
                        getOptionValue={({id}) => id}
                        options={formattedFoodRestrictions}
                        value={[]}
                        placeholder={Utility.getLang().cases.guestTabContent.addDietaryRestriction}
                        className={"multiselect multiselect--max_width"}
                        onInputChange={this.onInputItemChange}
                        classNamePrefix={"multiselect"}
                        formatCreateLabel={e => Utility.getLang().common.dropDownCreateMessage + `"${e}"`}
                        onChange={(option) => this.onChangeSingleFoodRestriction(option)}
                    />
                </div>
            </React.Fragment>
        )
    }
}

CaseGuestFoodRestriction.propTypes = {
    columnDetailViewTabCollection: PropTypes.array,
};

function mapStateToProps(state) {
    return {
        selectedCaseData: state.application.selectedCaseData,
        initialValues: state.application.selectedCaseData,
        selectedDataById: state.application.selectedDataById,
        openPopupForRelativeOnly: state.application.openPopupForRelativeOnly,
        open: state.application.open,

        openOnSend: state.application.openOnSend,
        allUserDetailsForCases: state.application.allUserDetailsForCases,
        caseAllStages: state.application.caseAllStages,
        caseAllLabels: state.application.caseAllLabels,
        allRelationshipInfo: state.application.allRelationshipInfo,
        headerMenuItemClicked: state.application.headerMenuItemClicked,
        displayNothing: state.application.displayNothing,
        foodRestrictionList: state.application.foodRestrictionList
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: "CaseGuestFoodRestriction",
    enableReinitialize: true,
    validate: FormValidator({
        name: {presence: true},
    })
})(CaseGuestFoodRestriction));
