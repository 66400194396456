export const ADD_NEW_EVENT_CATEGORY = "ADD_NEW_EVENT_CATEGORY";
export const ADD_PRODUCTS_TO_EXISTING_EVENT_PRODUCT_CATEGORY = "ADD_PRODUCTS_TO_EXISTING_EVENT_PRODUCT_CATEGORY";
export const ADD_MORE_EVENT_CATEGORY_FIELD = "ADD_MORE_EVENT_CATEGORY_FIELD";
export const REMOVE_SINGLE_PRODUCT_FROM_EXISTING_PRODUCT_CATEGORY = "REMOVE_SINGLE_PRODUCT_FROM_EXISTING_PRODUCT_CATEGORY";
export const ON_ORDER_EVENT_CATEGORY_PRODUCT_SELECT = "ON_ORDER_EVENT_CATEGORY_PRODUCT_SELECT";
export const ON_ORDER_EVENT_CATEGORY_PRODUCT_QUANTITY_AND_PRICE_CHANGE = "ON_ORDER_EVENT_CATEGORY_PRODUCT_QUANTITY_AND_PRICE_CHANGE";
export const ON_ORDER_EVENT_CATEGORY_DISBURSEMENT_PAYMENT_SELECT = "ON_ORDER_EVENT_CATEGORY_DISBURSEMENT_PAYMENT_SELECT";
export const GET_REMAINING_PRODUCT_CATEGORIES_FOR_AN_EVENT = "GET_REMAINING_PRODUCT_CATEGORIES_FOR_AN_EVENT";
export const UPDATE_EVENT_ATTRIBUTE_DATA = "UPDATE_EVENT_ATTRIBUTE_DATA";
export const CHANGE_ATTRIBUTE_CONFIRM_STATUS = "CHANGE_ATTRIBUTE_CONFIRM_STATUS";
export const CHANGE_CUSTOM_PRODUCT_NAME = "CHANGE_CUSTOM_PRODUCT_NAME";
export const REMOVE_SINGLE_CHILD_PRODUCT_FROM_EXISTING_PRODUCT_CATEGORY = "REMOVE_SINGLE_CHILD_PRODUCT_FROM_EXISTING_PRODUCT_CATEGORY";
export const ON_ORDER_EVENT_CATEGORY_CHILD_PRODUCT_QUANTITY_AND_PRICE_CHANGE = "ON_ORDER_EVENT_CATEGORY_CHILD_PRODUCT_QUANTITY_AND_PRICE_CHANGE";
export const CHANGE_EVENT_CATEGORY_CONFIRM_STATUS = "CHANGE_EVENT_CATEGORY_CONFIRM_STATUS";
export const REMOVE_FULL_ORDER_EVENT_CATEGORY = "REMOVE_FULL_ORDER_EVENT_CATEGORY";
export const REMOVE_ORDER = "REMOVE_ORDER";
export const FETCHED_ALL_CATEGORY_FOR_ANONYMOUS = "FETCHED_ALL_CATEGORY_FOR_ANONYMOUS";
export const GET_PRODUCT_TOTAL_PRICE = "GET_PRODUCT_TOTAL_PRICE";
export const CHANGE_BILLING_STATUS_FOR_CASE_ORDER = "CHANGE_BILLING_STATUS_FOR_CASE_ORDER";
export const CHANGE_BILLING_STATUS_FOR_ORDER_INFO = "CHANGE_BILLING_STATUS_FOR_ORDER_INFO";
export const CHANGE_STAGE_FOR_ORDER_INFO = "CHANGE_STAGE_FOR_ORDER_INFO";
export const SET_ORDER_BILLING_STATUS = "SET_ORDER_BILLING_STATUS";
export const SET_TIME_PERIOD_FOR_ORDER_INVOICE = "SET_TIME_PERIOD_FOR_ORDER_INVOICE";
export const SET_CUSTOM_TIME_PERIOD_FOR_ORDER_INVOICE = "SET_CUSTOM_TIME_PERIOD_FOR_ORDER_INVOICE";
export const SET_CASE_ID_FOR_ORDER_TAB_ONLY = "SET_CASE_ID_FOR_ORDER_TAB_ONLY";
export const FETCHED_RECEPTION_EVENT = "FETCHED_RECEPTION_EVENT";
export const CHANGE_PRODUCT_ITEM_GREETING_TEXT = "CHANGE_PRODUCT_ITEM_GREETING_TEXT";
export const CHANGE_CEREMONY_INSTRUCTION_FOR_ORDER_INFO = "CHANGE_CEREMONY_INSTRUCTION_FOR_ORDER_INFO";
export const SET_CHILD_PRODUCT_CONFIRM_STATUS = "SET_CHILD_PRODUCT_CONFIRM_STATUS";
export const REMOVE_EVENT = "REMOVE_EVENT";
