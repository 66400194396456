import {UPDATE_CASE_DATA_MODE, UPDATE_LOCK_CASE} from "./types";

export function updateCaseDataMode(payload) {
    return {type: UPDATE_CASE_DATA_MODE, payload: payload};
}

export function updateLockCase(payload) {
    return  {type: UPDATE_LOCK_CASE, payload: payload};
}

export function clearLockCase() {
    return  {type: UPDATE_LOCK_CASE, payload: null};
}