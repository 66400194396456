import React from 'react';
import OrderComponent from "./OrderComponent";
import {bindActionCreators} from "redux";
import * as actionMaster from "../../../actions/uiAction";
import connect from "react-redux/lib/connect/connect";
import OrderUtil from "../../common/orderUtil";
import LockCaseUtil from "../lockCase/util/lockCaseUtil";
import AppUtil from "../../common/appUtil";

/**
 * Holds multiple order components(each component represents one unique order)
 */
class OrderContainer extends React.Component {

    render() {
        let contentAvailable = this.props.selectedCaseData !== undefined && this.props.selectedCaseData.orders !== undefined && this.props.selectedCaseData.orders.length !== 0;
        return (
            <React.Fragment>
                {
                    contentAvailable ?
                        <div className="section__content section__content--case_order drawer"
                             disabled={LockCaseUtil.isDisabledView(this.props.disabledView)}>
                            {
                                this.props.selectedCaseData.orders.map((order, orderIndex) => (
                                        <OrderComponent
                                            key={order.id}
                                            order={order}
                                            orderIndex={orderIndex}
                                            originalData={AppUtil.isAvailable(this.props.selectedDataById.orders[orderIndex]) ? this.props.selectedDataById.orders[orderIndex] : {stage: OrderUtil.orderStages.draft.value}}
                                            showEmailAndRelativeButtonInsideComponent={true}
                                            functionSource={OrderUtil.ORDER_DEFAULT_SOURCE}
                                            sendOrderEmailSummary={this.props.sendOrderEmailSummary}
                                            changeEmailTypeToSend={this.props.changeEmailTypeToSend}
                                            disabledView={this.props.disabledView}
                                        />
                                    )
                                )
                            }
                        </div>
                        : null
                }
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        selectedCaseData: state.application.selectedCaseData,
        selectedDataById: state.application.selectedDataById
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderContainer);
