import React from 'react';
import Utility from "../../../api/utilLanguage";
import BillingContactComponent from "../caseOrderSubComponents/billingContact";
import Button from "../../common/button";
import AccessControl from "../../../api/accessControl";
import OrderUtil from "../../common/orderUtil";
import Popup from "../../common/popups/popup";
import {bindActionCreators} from "redux";
import * as actionMaster from "../../../actions/uiAction";
import * as caseOrderAction from "../../../actions/caseOrderAction";
import * as orderAction from "../../../actions/orderAction";
import * as caseAction from "../../../actions/caseAction";
import connect from "react-redux/lib/connect/connect";
import AppUtil from "../../common/appUtil";
import RelativeUtil from "../relative/relativeUtil";

/**
 * Billing component is at the footer of each order
 */
class OrderBillingComponent extends React.Component {

    constructor(props) {
        super(props);
        this.disableEmail = OrderUtil.checkEmailButtonDisableCondition(this.props.order.stage);
        this.state = {
            billingContacts: [],
        };
    }

    componentDidMount() {
        const {selectedCaseData} = this.props;
        if (AppUtil.isAvailable(selectedCaseData) && AppUtil.isAvailable(selectedCaseData.relatives)) {
            this.updateBillingContacts(selectedCaseData.relatives);
        }
    }

    updateBillingContacts(contacts) {
        this.setState({billingContacts: RelativeUtil.getBillingContacts(contacts)});
    }

    UNSAFE_componentWillMount() {

    }

    render() {
        const {billingContacts} = this.state;
        return (
            <React.Fragment>
                <BillingContactComponent
                    contacts={billingContacts}
                    source={this.props.functionSource}
                    orderStage={this.props.order.stage}
                    changeDropdownItemForBillingContact={this.props.changeDropdownItemForBillingContact}
                    selectedItem={this.props.order.billingContactId !== null ? this.props.order.billingContactId : ""}
                    oldOrder={this.props.oldOrder}
                    order={this.props.order}
                />
                {this.props.showEmailAndRelativeButtonInsideComponent === true ?
                    <div className="section__toolbar btn-toolbar">
                        <div className="btn-toolbar">
                            {this.props.checkOrderChange}
                            <Button
                                id="OrderEmailSummary"
                                onClick={this.props.sendEmailSummary}
                                disabled={this.disableEmail}
                                tooltip={this.disableEmail ? Utility.getLang().common.caseNotSaved : null}
                                icon="email"
                            >
                                {Utility.getLang().common.sendEmail}
                            </Button>
                        </div>
                        {(AccessControl.revokedAccess().deleteOrder === true) && (this.props.order.stage !== OrderUtil.orderStages.proposal.value && this.props.order.stage !== OrderUtil.orderStages.draft.value && this.props.order.stage !== OrderUtil.orderStages.order.value) ?
                            null :
                            <div className="right">
                                <Button
                                    id={this.props.orderIndex}
                                    onClick={(e) => this.props.deleteOrder(this.props.order.orderType, this.props.orderIndex, this.props.order.id)}
                                    isTertiary
                                    isDestructive
                                >
                                    {Utility.getLang().cases.orderTabContent.funeralCaseOrderContent.deleteOrder}
                                </Button>
                            </div>
                        }
                    </div>
                    : null}
                <Popup
                    id={this.props.orderIndex}
                    openModal={this.props.orderIndex !== undefined ? (this.props.deletedOrderData.orderIndex === this.props.orderIndex) && this.props.deleteOrderPopup : false}
                    headerInfo={Utility.getLang().infoMessages.popup.deleteOrder + ` ` + this.props.order.orderTypeText + `?`}
                    onConfirmation={this.props.onDelete}
                    closePopup={this.props.hidePopup}
                    isDestructive
                    withTimeOut
                />
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        selectedCaseData: state.application.selectedCaseData,
        selectedOrderData: state.application.selectedOrderData,
        selectedDataById: state.application.selectedDataById,
        initialValues: state.application.selectedCaseData,
        deleteOrderPopup: state.application.deleteOrderPopup,
        deletedOrderData: state.application.deletedOrderData
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        caseOrderAction: bindActionCreators(caseOrderAction, dispatch),
        orderAction: bindActionCreators(orderAction, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderBillingComponent);