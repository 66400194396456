import connect from "react-redux/lib/connect/connect";
import {bindActionCreators} from "redux";
import * as orderEventAttributeAction from "../../orderEventAttributeSubtype/orderEventAttributeAction";
import * as dashboardAction from "../../../actions/dashboardAction";
import * as React from 'react';
import * as caseOrderAction from "../../../actions/caseOrderAction";
import * as caseAction from "../../../actions/caseAction";
import * as convertViewAction from "../../../actions/convertViewAction";
import * as organiseViewAction from "../../../actions/organiseViewAction";
import * as memorialAction from "../../case/memorialPage/redux/memorialAction";
import AppUtil from "../appUtil";
import {getProbateEnums} from "../../case/probates/api";
import {setProbateEnum} from "../../case/probates/redux/probateAction";

class SynchronizeLocalStorage extends React.Component {

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.synchronizeAllLocalStorage(nextProps);
        this.synchronizeAllGlobalVariables(nextProps);
    }

    synchronizeAllLocalStorage = (nextProps) => {
        if ((this.props.shouldSynchronizeLocalStorage !== nextProps.shouldSynchronizeLocalStorage) && nextProps.shouldSynchronizeLocalStorage) {
            //Synchronizing local storage items
            this.props.orderEventAttributeAction.getOrderEventAttributeSubtypeApi().then();
            this.props.caseOrderAction.getTenantBasedOrderTypesApi().then();

            this.props.caseAction.getCaseStagesDetailsApi().then().finally(() => {
                this.refreshVisiblePage();
            });

            this.props.dashboardAction.shouldSynchronizeLocalStorage(false);
        }
    };

    synchronizeAllGlobalVariables = (nextProps) => {
        if ((this.props.shouldSynchronizeGlobalVariables !== nextProps.shouldSynchronizeGlobalVariables) && nextProps.shouldSynchronizeGlobalVariables) {
            this.props.caseAction.getAllCountries();

            getProbateEnums().then(res => {
                const {success, object} = res;
                if (success) {
                    this.props.dispatch(setProbateEnum(object));
                }
            })

            this.props.dashboardAction.shouldSynchronizeGlobalVariables(false);
        }
    };

    refreshVisiblePage() {
        // console.log("headerMenuItemClicked = ", this.props.headerMenuItemClicked);
        if (this.props.headerMenuItemClicked === AppUtil.linkPaths.convert.pathToRoute) {
            this.props.convertViewAction.getPageConfiguration();
        } else if (this.props.headerMenuItemClicked === AppUtil.linkPaths.organise.pathToRoute) {
            this.props.organiseViewAction.getPageConfiguration();
        }
    }

    render() {
        return null;
    }
}

function mapStateToProps(state) {
    return {
        shouldSynchronizeLocalStorage: state.application.shouldSynchronizeLocalStorage,
        headerMenuItemClicked: state.application.headerMenuItemClicked,
        shouldSynchronizeGlobalVariables: state.application.shouldSynchronizeGlobalVariables
    };
}

function mapDispatchToProps(dispatch) {
    return {
        orderEventAttributeAction: bindActionCreators(orderEventAttributeAction, dispatch),
        dashboardAction: bindActionCreators(dashboardAction, dispatch),
        caseOrderAction: bindActionCreators(caseOrderAction, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch),
        convertViewAction: bindActionCreators(convertViewAction, dispatch),
        organiseViewAction: bindActionCreators(organiseViewAction, dispatch),
        memorialAction: bindActionCreators(memorialAction, dispatch),
        dispatch//to access props.dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SynchronizeLocalStorage);