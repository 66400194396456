import React from "react";

const Tag = ({tagClassName, content}) => {
    return (
        <span className = {tagClassName}>
            {content}
        </span>
    );
};
export default  Tag;
