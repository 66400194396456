import React from 'react';
import Utility from "../../../../api/utilLanguage";
import Icon from "../../../common/icon";
import Tooltip from "../../../common/tooltip";
import AppUtil from "../../../common/appUtil";
import Button from "../../../common/button";
import OnToggleComponent from "../../caseOrderSubComponents/toggleShowAndHideContent";
import {bindActionCreators} from "redux";
import * as actionMaster from "../../../../actions/uiAction";
import * as caseOrderAction from "../../../../actions/caseOrderAction";
import connect from "react-redux/lib/connect/connect";
import {checkValidation, getNoSpaceError} from "../../../common/validationUtil";
import OrderCommonElementComponent from "../OrderCommonElementComponent";
import FieldType from "../../../common/fieldType";
import OrderUtil from "../../../common/orderUtil";
import OrderFreeText from "../../caseOrderSubComponents/orderFreeText";
import ValidationAndVisibilityRule, {isCurrencyPlacedOnRight} from "../../../common/ValidationAndVisibilityRule";
import {getTenantCurrency, getTenantCurrencyUnitFullStringValue} from "../../../common/localStorageUtil";
import OrderDisbursementPayment from '../../caseOrderSubComponents/orderDisbursementPayment';
import {ProductTypes} from "../../../common/productUtil";
import {cloneDeep} from "lodash";

const customNameProperty = "customName";

/**
 * Represent Order event categories for an order event
 */
class OrderEventCategories extends React.Component {
    state = {
        disableToggle: null,
        isCurrencyPlacedOnRight: isCurrencyPlacedOnRight(ValidationAndVisibilityRule.validationRules, ValidationAndVisibilityRule.currencyPlacement),
        visibility: {
            display: '',
            fileName: 'collapse'
        },
        toggle: true,
        //State based eventProduct.customName used, for user's smooth typing experience
        eventProducts: this.props.category?.eventProducts ? [...this.props.category.eventProducts] : [],
    };

    UNSAFE_componentWillMount() {
        //Commented below condition, to update list of products  on categories on upgrade call
        // if (!AppUtil.isAvailable(this.props.category.data)) {} 
        this.props.caseOrderAction.getProductsForRequestedCategory(this.props.category, this.props.order.orderType, this.props.orderIndex, this.props.order.packageType, this.props.eventType, this.props.functionSource);
    }

    toggleClicked = (e) => {
        this.setState({toggle: !this.state.toggle});
    };

    customProduct(product, productIndex, updateEventProductsLocallyStored, customName) {
        return <div className="field">
            <label>
                <span>{Utility.getLang().cases.orderTabContent.funeralCaseOrderContent.customProduct}</span>
            </label>
            <input
                style={{display: ''}}
                type="text"
                disabled={product.isConfirm === true ? true : false}
                onBlur={(e) => this.props.changeCustomProductName(e.target.value, this.props.category.id, product, productIndex)}
                onChange={(e) => updateEventProductsLocallyStored(e?.target?.value, customNameProperty, product, productIndex)}
                value={customName || ''}
                data-tip
                className="text"
            />
            <Tooltip>
                {Utility.getLang().cases.orderTabContent.funeralCaseOrderContent.customProduct}
            </Tooltip>
            {
                checkValidation(AppUtil.isAvailable(product.customName) ? product.customName : null) !== "" ?
                    getNoSpaceError() : null
            }
        </div>;
    }

    childProductRenderElement(child, product, productIndex, childProductIndex) {
        return <div
            className={`btn-toolbar nowrap sub_product child_product ${OrderUtil.lockProducts(this.props.order.stage) ? 'is-locked' : ''}`}>
            <Icon type="sub"/>
            <OrderCommonElementComponent
                checkBoxId={this.props.category.id + childProductIndex + this.props.orderIndex}
                className={"option option--confirm_field option--checkbox option--max_width"}
                fieldType={FieldType.REACT_SELECT}
                defaultValue={child}
                data={product.relatedProducts}
                disabled={child.isConfirm === true ? true : false}
                tooltip={child.isConfirm === true ? Utility.getLang().orders.confirmed : Utility.getLang().orders.notConfirmed}
                selectData={(e) => this.selectChildProduct(e, this.props.category, product, productIndex, child, childProductIndex)}
                checkedStatus={child.isConfirm}
                changeCheckedStatusInfo={(e) => this.setConfirmStatusForChildProduct(e, this.props.category, product, productIndex, child, childProductIndex)}
                label={Utility.getLang().cases.orderTabContent.funeralCaseOrderContent.product}
            />
            <div className="field field--min_width has-unit">
                <label>
                    <span>{Utility.getLang().cases.orderTabContent.funeralCaseOrderContent.quantity}</span>
                </label>
                <input
                    type="number"
                    disabled={child.isConfirm === true ? true : false}
                    onChange={(e) => this.props.changeChildProductVolume(e.target.value, undefined, this.props.category.id, product, productIndex, child, childProductIndex)}
                    min={1}
                    value={child.quantity}
                    data-tip
                    className="text text--number"
                />
                <Tooltip>
                    {Utility.getLang().common.unitFull.pieces}
                </Tooltip>
                <p className="field__unit">{Utility.getLang().common.unit.pieces}</p>
            </div>
            <div className="field field--min_width has-unit">
                <label>
                    <span>{Utility.getLang().cases.orderTabContent.funeralCaseOrderContent.finalPrice}</span>
                </label>
                <input
                    type="number"
                    disabled={child.isConfirm === true ? true : false}
                    onChange={(e) => this.props.changeChildProductVolume(undefined, e.target.value, this.props.category.id, product, productIndex, child, childProductIndex)}
                    value={child.finalPrice}
                    data-tip
                    className="text text--number"
                />
                <Tooltip>
                    {getTenantCurrencyUnitFullStringValue()}
                </Tooltip>
                <p className="field__unit">{getTenantCurrency()}</p>
            </div>
            {child.isConfirm !== true ?
                <Button
                    isTertiary
                    isDestructive
                    noLabel
                    onClick={(e) => this.props.removeSingleChildProductLine(this.props.category.id, product, productIndex, child, childProductIndex)}
                />
                : null}
        </div>;
    }

    onStatusChange = (isConfirm, categoryId, productId = undefined, productIndex = undefined) => {
        this.props.onConfirmStatusChange(isConfirm, categoryId, productId, productIndex);
    };

    removeNewCategory = (e) => {
        this.props.caseOrderAction.removeFullOrderEventCategory(this.props.category.id, this.props.order.orderType, this.props.orderIndex, this.props.eventType, this.props.functionSource);
    };

    //TODO: using old order code
    changeGreetingText = (e, categoryInfo, product, productIndex) => {
        this.props.caseOrderAction.changeProductItemGreetingText(e.target.value, categoryInfo, product, productIndex, this.props.order.orderType, this.props.eventType, this.props.functionSource, this.props.orderIndex);
    };

    //TODO: using old order code
    generateNewFieldForChildProduct = (e, newField, order, event, product, productIndex) => {
        this.props.caseOrderAction.addNewProductInExistingParentProduct(newField, this.props.order.orderType, this.props.eventType, this.props.functionSource, this.props.orderIndex, product, productIndex);
    };

    //TODO: using old order code
    selectChildProduct = (e, categoryInfo, product, productIndex, child, childProductIndex) => {
        this.props.caseOrderAction.setChildProductIntoParent(e, categoryInfo, product, productIndex, this.props.order.orderType, this.props.eventType, this.props.functionSource, this.props.orderIndex, child, childProductIndex);
    };

    //TODO: using old order code
    setConfirmStatusForChildProduct = (e, categoryInfo, product, productIndex, child, childProductIndex) => {
        this.props.caseOrderAction.setChildProductConfirmStatus(e, categoryInfo, product, productIndex, this.props.order.orderType, this.props.eventType, this.props.functionSource, this.props.orderIndex, child, childProductIndex);
    };

    /***
     * @description: State based eventProduct.customName used, for user's smooth typing experience
     * UPDATE EventProducts
     */
    updateEventProductsLocallyStored = (value, property, productToMatch, productIndex) => {
        const {eventProducts} = this.state;
        const matchedProductIndex = eventProducts.findIndex((item, index) => (item.id === productToMatch.id) && (index === productIndex))
        let _eventProducts = eventProducts;
        if (matchedProductIndex !== -1) {//Existing
            _eventProducts[matchedProductIndex][property] = value;
            this.updateEventProducts(_eventProducts);
        } else if (productToMatch.id <= 0) {//New
            const productToMatchCopy = cloneDeep(productToMatch);
            productToMatchCopy[property] = value;
            _eventProducts.push(productToMatchCopy)
            this.updateEventProducts(_eventProducts);
        }
    };

    /**
     * @description: REMOVE EventProducts
     */
    removeEventProductsLocallyStored = (productToMatch, productIndex) => {
        const {eventProducts} = this.state;
        const matchedProductIndex = eventProducts.findIndex((item, index) => (item.id === productToMatch.id) && (index === productIndex))
        let _eventProducts = eventProducts;
        _eventProducts.splice(matchedProductIndex, 1);
        this.updateEventProducts(_eventProducts);
    };

    updateEventProducts = (value) => {
        this.setState({eventProducts: value});
    };

    removeSingleProductLine = (categoryId, productToMatch, productIndex) => {
        this.removeEventProductsLocallyStored(productToMatch, productIndex);
        this.props.removeSingleProductLine(categoryId, productToMatch, productIndex);
    }

    render() {
        return (
            <React.Fragment>
                <tr className={`tr tr--sub ${this.state.toggle ? 'is-active' : ''}`}>
                    <td className={`td td--check`}>
                        <OrderCommonElementComponent
                            checkBoxId={this.props.category.id + this.props.category.name + this.props.eventType + this.props.orderIndex}
                            className={"option option--checkbox option--confirm"}
                            reactSelect={false}
                            checkedStatus={this.props.category.isConfirm}
                            tooltip={this.props.category.isConfirm === true ? Utility.getLang().orders.confirmed : Utility.getLang().orders.notConfirmed}
                            changeConfirmStatus={(e) => this.onStatusChange(e, this.props.category.id, undefined, undefined)}
                        />
                    </td>
                    <td className={`td td--main  ${this.props.category.isConfirm ? 'disabledparent' : ''}`}>
                        <h5 className="title">
                            <span>{this.props.category.name}</span>
                            <a
                                className="link is-destructive"
                                onClick={(e) => this.removeNewCategory(e)}
                                data-tip
                            >
                                <Icon type="trash"/>
                                <Tooltip>
                                    {Utility.getLang().common.delete}
                                </Tooltip>
                            </a>
                        </h5>

                        {
                            this.props.category.eventProducts.map((product, productIndex) => {
                                const uniqueId = this.props.category.id + this.props.category.name + this.props.eventType + productIndex + this.props.orderIndex;
                                const customerName = this.state.eventProducts.length > productIndex ? this.state.eventProducts[productIndex]?.customName : "";
                                return (
                                    <React.Fragment key={uniqueId}>
                                        <div
                                            className={`btn-toolbar nowrap sub_product product_field ${OrderUtil.lockProducts(this.props.order.stage) ? 'is-locked' : ''}`}>
                                            <div className="field__group">
                                                <div className="btn-toolbar">
                                                    <OrderCommonElementComponent
                                                        checkBoxId={uniqueId}
                                                        className={"option option--confirm_field option--checkbox option--max_width"}
                                                        fieldType={FieldType.REACT_SELECT}
                                                        defaultValue={product}
                                                        data={this.props.category.data}
                                                        onChange={(e) => this.props.onProductSelect(e, this.props.category.id, product, productIndex)}
                                                        checkedStatus={product.isConfirm}
                                                        tooltip={product.isConfirm === true ? Utility.getLang().orders.confirmed : Utility.getLang().orders.notConfirmed}
                                                        label={Utility.getLang().cases.orderTabContent.funeralCaseOrderContent.product}
                                                        changeConfirmStatus={(e) => this.onStatusChange(e, this.props.category.id, product.productId, productIndex)}
                                                        getOptionLabel={({name}) => name}
                                                        getOptionValue={({id}) => id}
                                                    />
                                                    {product.typeId === ProductTypes.customProduct
                                                        ? this.customProduct(product, productIndex, this.updateEventProductsLocallyStored, customerName)
                                                        : null}
                                                    <div className="field field--min_width has-unit">
                                                        <label>
                                                            <span>{Utility.getLang().cases.orderTabContent.funeralCaseOrderContent.quantity}</span>
                                                        </label>
                                                        <input
                                                            type="number"
                                                            data-tip
                                                            disabled={product.isConfirm === true ? true : false}
                                                            onChange={(e) => this.props.changeProductVolume(e.target.value, undefined, this.props.category.id, product, productIndex)}
                                                            value={product.quantity}
                                                            min={1}
                                                            className="text text--number"
                                                        />
                                                        <Tooltip>
                                                            {Utility.getLang().common.unitFull.pieces}
                                                        </Tooltip>
                                                        <p className="field__unit">{Utility.getLang().common.unit.pieces}</p>
                                                    </div>
                                                    <div className="field field--min_width has-unit">
                                                        <label>
                                                            <span>{Utility.getLang().cases.orderTabContent.funeralCaseOrderContent.finalPrice}</span>
                                                        </label>
                                                        <input
                                                            type="number"
                                                            data-tip
                                                            disabled={product.isConfirm === true ? true : false}
                                                            onChange={(e) => this.props.changeProductVolume(undefined, e.target.value, this.props.category.id, product, productIndex)}
                                                            value={product.finalPrice}
                                                            className="text text--number"
                                                        />
                                                        <Tooltip>
                                                            {getTenantCurrencyUnitFullStringValue()}
                                                        </Tooltip>
                                                        <p className="field__unit">{getTenantCurrency()}</p>
                                                    </div>
                                                </div>
                                                {this.props.eventType === OrderUtil.eventTypes.disbursements.value ?
                                                    <div className="field field--min_width has-unit">
                                                        <OrderDisbursementPayment
                                                            key={productIndex}
                                                            data={product}
                                                            orderType={this.props.order.orderType}
                                                            changeDisbursmentPaymentOption={(e) => this.props.changeDisbursmentPaymentOption(e, this.props.category, product, productIndex)}
                                                        />
                                                    </div> : null}
                                                {AppUtil.isAvailable(product.enableFreeText) && product.enableFreeText === true ?
                                                    <OrderFreeText
                                                        key={productIndex}
                                                        data={product.freeText}
                                                        changeGreetingText={(e) => this.changeGreetingText(e, this.props.category, product, productIndex)}
                                                    />
                                                    : null}
                                            </div>
                                            {product.isConfirm !== true ?
                                                <Button
                                                    isTertiary
                                                    isDestructive
                                                    noLabel
                                                    onClick={(e) => this.removeSingleProductLine(this.props.category.id, product, productIndex)}
                                                />
                                                : null}
                                        </div>
                                        {product.typeId === ProductTypes.parentProduct ?
                                            // eslint-disable-next-line
                                            product.childProducts.map((child, childProductIndex) => {
                                                if (child.productName !== "") {
                                                    child.name = this.state.isCurrencyPlacedOnRight === true
                                                        ? `${child.productName} ${child.price} ${getTenantCurrency()}`
                                                        : `${child.productName} ${getTenantCurrency()} ${child.price}`;
                                                } else {
                                                    child.name = Utility.getLang().common.dropDownOptionPleaseSelect;
                                                }
                                                this.childProductRenderElement(child, product, childProductIndex)
                                            }) : null
                                        }
                                        <div
                                            className={`btn-toolbar add_items ${OrderUtil.lockProducts(this.props.order.stage) ? 'is-locked' : ''}`}
                                            style={product.typeId === ProductTypes.parentProduct ? {display: ''} : {display: 'none'}}
                                        >
                                            <Icon type="sub"/>
                                            <Button
                                                onClick={(e) => this.generateNewFieldForChildProduct(e, this.props.category, product, productIndex)}
                                                icon="add"
                                            >
                                                {Utility.getLang().common.add}
                                            </Button>
                                        </div>
                                    </React.Fragment>
                                )
                            })
                        }
                        <div className={`btn-toolbar add_items`}>
                            <Button
                                onClick={(e) => this.props.addNewFieldInEventProductCategory(e, this.props.category)}
                                icon="add"
                            >
                                {Utility.getLang().common.add + " " + this.props.category.name}
                            </Button>
                        </div>
                    </td>
                    <td className="td td--detail td--price">
                        {this.props.category.catTotal
                            ? `${this.props.category.catTotal
                                ? this.state.isCurrencyPlacedOnRight === true
                                    ? `${this.props.category.catTotal} ${getTenantCurrency()}`
                                    : `${getTenantCurrency()} ${this.props.category.catTotal}`
                                : this.state.isCurrencyPlacedOnRight === true ? `0 ${getTenantCurrency()}` : ` ${getTenantCurrency()} 0`
                            }`
                            : this.state.isCurrencyPlacedOnRight === true ? `0 ${getTenantCurrency()}` : `${getTenantCurrency()} 0`}
                    </td>
                    <OnToggleComponent
                        disabledToggle={false}
                        fileName={this.state.toggle === true ? "collapse" : "expand"}
                        showAndHide={this.toggleClicked}/>
                </tr>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        selectedCaseData: state.application.selectedCaseData,
        selectedDataById: state.application.selectedDataById
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        caseOrderAction: bindActionCreators(caseOrderAction, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderEventCategories);
