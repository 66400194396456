import React, {useEffect} from "react";
import AppUtil, {Lang} from "../../common/appUtil";
import Icon from "../../common/icon";
import {DashboardProps} from "../ui/dbUtil";
import connect from "react-redux/lib/connect/connect";
import {clearFullDashboardDetails} from "../redux/dashboardAction";
import Dashboard from "../index";
import {DashboardListTypeUrl, DB_DETAILS_PAGE_LIMIT} from "../api/dashboardService";

const DashboardDetails = (props) => {
    const {match, history} = props;
    const selectedDashboardType = parseInt(DashboardListTypeUrl[match?.params?.urlId], 10);

    useEffect(() => {
        //Called during unmount
        return () => {
            props.dispatch(clearFullDashboardDetails());
        }
    }, []);

    const goBack = () => {
        history.push(AppUtil.linkPaths.dashboard.pathToRoute)
    };

    return <Dashboard pageId={DashboardProps.dbDetailPage}
                      selectedDashboardType={selectedDashboardType}
                      pageLimit={DB_DETAILS_PAGE_LIMIT}
                      dispatch={props.dispatch}>
        <BackLink title={Lang().header.dashboard}
                  onClick={goBack}
        />
    </Dashboard>
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(DashboardDetails);

const BackLink = ({title, onClick}) => {
    return <a className="link link--nav" onClick={onClick}>
        <Icon type="arrow_left"/>
        <span>{title}</span>
    </a>;
};