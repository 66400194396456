import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const toastPosition = "top-center";
const toastTheme = "colored";
const toastId = "toastMessageId1"
export const UseToastWithOverlay = false;
export const TOAST_CONTAINER_ID = "toastContainerId";

function showToastErrorMessage(message) {
    toast.error(message, {
        position: toastPosition,
        onOpen: () => document.body.style.overflow = "hidden",
        onClose: () => document.getElementById(TOAST_CONTAINER_ID).style.backgroundColor = "none",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: 0,
        theme: toastTheme,
        toastId: toastId
    });
}

export {showToastErrorMessage};
