import Utility from "../../src/api/utilLanguage";
import AppUtil from "../components/common/appUtil";
import SearchUtil from "../components/common/searchPanel/searchUtil";

export default {
    isMemberSwedishChurch: '',
    paceMaker: '',
    subid: '',
    name: '',
    userStatus: '',
    loadingView: '',
    open: false,
    displayNothing: false,
    openOnDrag: false,
    showStageChangePopup: false,
    openOnOrderDrag: false,
    openOnSend: false,
    showVisibleToCustomerPopup: false,
    openOnDocumentDelete: false,
    openEmailPopupOnSend: false,
    openFuneralPackagePopup: false,
    openOnSaveCase: false,
    openPopupForRelativeOnly: false,
    openFuneralPopup: false,
    openFuneralWithoutPopup: false,
    openLegalOrderPopup: false,
    openTombstoneOrderPopup: false,
    opentransportOrderPopup: false,
    openFlowerOrderPopup: false,
    openCleaningOrderPopup: false,
    openEstateOrderPopup: false,
    openCustomOrderPopup: false,
    productViewToAddForm: false,
    altTextInfo: false,
    selectedAuthUser: {},
    backUpAuthUser: {},
    newActiveCallDetails: {},
    personInfo: [],
    componentValidationErrorsInfo: {},
    allPersonDir: [],
    billingContactsForCase: [],
    foodRestrictionList: [],
    productViewToAddFormForChild: false,
    hasCardInfoChanged: false,
    isUserLoggedIn: false,
    hasUnsavedData: false,
    caseUpdated: false,
    showUnsavedPopup: false,
    startUpgradePopup: false,
    headerMenuItemClicked: AppUtil.linkPaths.convert.pathToRoute,
    highLightIconSearch: '',
    highLightIconNotification: '',
    caseDetailsLogs: null,
    caseLogDetails: null,
    caseEvents: [],
    caseAuthors: [],
    orderDetailsLogs: null,
    notificationList: null,
    deletedProductParentId: null,
    orderIdForSendingmail: null,
    orderDataForSendingMail: null,
    subheaderMenuInMyAccount: '',
    subheaderMenuInSetting: '',
    subheaderMenuInProduct: '',
    subheaderMenuInLocation: '',
    subheaderMenuInContact: '',
    tempNote: '',
    authenticatedUserInfoForEdit: {},
    productWithVariantData: {},
    selectedVariantProduct: {},
    deletedProductSuccessfully: {},
    codeResponse: {},
    selectedUser: {},
    updatedSelectedCardItem: {},
    productinitialValues: {},
    showApiResponseWithToken: {},
    selectedProductCategory: {},
    showApiResponseWithoutToken: {},
    selectedProduct: {},
    contactAddress: {},
    particularProductCategoryData: {},
    particularProductData: {},
    showResetPassword: false,
    showSearchPanel: false,
    unreadNotificationCount: 0,
    showEscapeKey: false,
    showNotificationPanel: false,
    userAttributes: {},
    commonListForFilter: {},
    user: {},
    allUserData: [],
    allRoleData: [],
    allPersonTypes: [],
    productTypes: [],
    locationsForContact: [],
    updatedCaseStage: null,
    updatedCaseStageObject: null,
    allRoleDataWithSelectedUserInfo: [],
    productCategoryData: [],
    productData: [],
    particularProductVarient: [],
    filteredProductData: [],
    allLocationData: [],
    particularLocationData: [],
    locationAttrubute: [],
    locationTypes: [],
    allChildDataExceptCurrentChild: [],
    customData: [],
    currentColumnView: {},
    unsavedPopupInfo: {},
    selectedDataById: {},
    selectedParentOfVariantProduct: {},
    sectionData: [],
    eventTypeData: [],
    caseAllStages: [],
    caseAllLabels: [],
    allCityInfo: [],
    allRelationshipInfo: [],
    allUserDetailsForCases: [],
    caseRelativesWithEmailId: [],
    allCeremonyTypes: [],
    updatedCaseById: {},
    selectedCaseData: {},
    caseTodoTemplates: {},
    filterAppliedData: [],
    categoryItemsinfo: [],
    allBurialTypes: [],
    categoryAccToEvent1: [],
    categoryAccToEvent2: [],
    categoryAccToEvent4: [],
    categoryAccToEvent8: [],
    categoryAccToEvent16: [],
    inputItem: "",
    globalSearchPanel: defaultGlobalSearch(),
    selectedProductData: {},
    selectedLocationData: {},
    caseDetailsOnOrderChangeForEmail: [],
    registeredListeners: [],
    selectedOrderData: {},
    selectedCallData: {},
    updatedOrderDetails: {},
    mergedCaseDetails: {},
    callDetailsLogs: [],
    cardSelectedFromGlobalSearch: {},
    orderStatus: [],
    relatives: [],
    locationSearchedSimpleResponse: [],
    casesWithOngoingCalls: [],
    callsThatHaveEnded: [],
    triggerSearch: false,
    escClicked: false,
    deleteOrderPopup: false,
    deletedOrderData: {},
    newNotificationAlert: false,
    orderUpdated: false,
    callUpdated: false,
    productUpdated: false,
    locationUpdated: false,
    contactUpdated: false,
    initialSelectedUser: {},
    copyselectedProductCategory: {},
    parentSearchResponse: [
        {
            id: SearchUtil.searchCategory.case,
            name: Utility.getLang().search.caseSingular,
            card: 'CaseColumnItemCard',
            count: 0,
            nextNavigation: '',
            toggleClick: '',
            loaderStatus: AppUtil.loadingStatus.finishedLoading,
            data: []
        },
        {
            id: SearchUtil.searchCategory.order,
            name: Utility.getLang().search.orderSingular,
            card: 'OrderColumnItemCard',
            count: 0,
            nextNavigation: '',
            toggleClick: '',
            loaderStatus: AppUtil.loadingStatus.finishedLoading,
            data: []
        },
        {
            id: SearchUtil.searchCategory.call,
            name: Utility.getLang().search.callSingular,
            card: 'CallColumnItemCard',
            count: 0,
            nextNavigation: '',
            toggleClick: '',
            loaderStatus: AppUtil.loadingStatus.finishedLoading,
            data: []
        },
        {
            id: SearchUtil.searchCategory.product,
            name: Utility.getLang().search.productSingular,
            card: 'ProductColumnItemCard',
            count: 0,
            nextNavigation: '',
            toggleClick: '',
            loaderStatus: AppUtil.loadingStatus.finishedLoading,
            data: []
        },
        {
            id: SearchUtil.searchCategory.location,
            name: Utility.getLang().search.locationSingular,
            card: 'LocationColumnItemCard',
            count: 0,
            nextNavigation: '',
            toggleClick: '',
            loaderStatus: AppUtil.loadingStatus.finishedLoading,
            data: []
        },
        {
            id: SearchUtil.searchCategory.contact,
            name: Utility.getLang().search.contactSingular,
            card: 'ContactColumnItemCard',
            count: 0,
            nextNavigation: '',
            toggleClick: '',
            data: []
        },
        {
            id: SearchUtil.searchCategory.customer,
            name: Utility.getLang().search.customerSingular,
            card: 'CustomerColumnCard',
            count: 0,
            nextNavigation: '',
            toggleClick: '',
            data: []
        }],
    allDresscodeTypes: [],
    memorialFunds: [],
    openForGuestEmailAction: false,
    openForGuestAddingRestriction: false,
    guestAdditionRestrictionPopupTitle: '',
    guestPopup: {
        index: -1,
        showOnDelete: false
    },
    lostReasons: [],
    noCaseReasons: [],
    orderTypes: [],
    countries: [],
    counties: [],
    loginErrorMessage: "",
    resetLoginErrorMessage: "",
    wsLockCaseData: {
        reloadToSeeLatestBanner: {},
        semiTransparentCard: {},
        outdatedBanner: {},
        currentEditorAndTakeOverBanner: {},
        accessUserListWithOwnerDict: {},
        assigned: {}
    },
    disableCaseSaveButton: false,
    shouldSynchronizeLocalStorage: false,
    shouldSynchronizeGlobalVariables: false,
    counter: {
        caseLogs: 0,
        orderLogs: 0,
        callLogs: 0,
        documents: 0,
        reportedMemoriesCount: 0,
        callNotes: 0,
        orderNotes: 0,
        caseNotes: 0,
        caseCalls: 0,
        caseGuests: 0,
        caseOrders: 0,
        caseRelatives: 0,
        caseProbates: 0
    },
    orderDragDrop: {
        billingContactId: null,
        deliveryMethod: null,
    },
    popupCase: {
        owner: null,
        lossReason: null,
        orderDetails: {},
        collaboratorId: null,
        countyId: null
    },
    relatedCases: [],
    customerWebAppOrderAccessLevelPopup: false,
    manuallySelectedChildComponent: undefined,
    vendors: [],
    hasUserChanged: false,
};

function defaultSearchOption() {
    return {
        value: SearchUtil.searchCategory.case,
        label: Utility.getLang().search.casePlural
    }
}

export function defaultGlobalSearch() {
    return {
        inputItem: "",
        selectedSearchOption: defaultSearchOption(),
        show: false
    }
}
