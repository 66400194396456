import {Lang} from "../../../../../common/appUtil";
import Select from "react-select";
import ValidationUtil from "../../../../../common/validationUtil";
import NumberField, {FormatStyle} from "../../../../../common/currencyUtil/numberField";
import DocumentAccessView from "../../../util/documentAccessView";
import Button from "../../../../../common/button";
import Utility from "../../../../../../api/utilLanguage";
import React, {useState} from "react";
import PropTypes from "prop-types";
import usePropertyView from "./usePropertyView";
import {Placeholder} from "../../../../../common/placeholder";
import {ProbateProps} from "../../tabProbateMeeting/util";

const PropertyView = ({
                          title,
                          propertyGroupKey,
                          propertySubGroupKey,
                          properties,
                          propertyOptions,
                          addNewProperty,
                          onPropertyTypeChange,
                          onPropertyValueChange,
                          onClickShowDocumentHandlerPopup,
                          onNameTextChange,
                          onRemoveProperty,
                          caseId,
                          selectedDocumentType,
                          probateId,
                          orders,
                          onDeductionGetOrderTotal,
                          splitItem
                      }) => {

    const {isOrderTotalButtonDisabled, documentType} = usePropertyView();
    const [orderTotalInProgressInfo, setOrderTotalInProgressInfo] = useState({inProgress: false, index: -1});

    /***
     * @description: Get Order total and update property.value
     */
    const onDeductionOrderTotalBtnClick = (property, probateId, orders, index) => {
        setOrderTotalInProgressInfo({inProgress: true, index: index});
        onDeductionGetOrderTotal(property.propertySubTypeId, probateId, orders).then(orderTotalValue => {
            onPropertyValueChange(orderTotalValue, property.id, index);
        }).finally(() => {
            setOrderTotalInProgressInfo({inProgress: false, index: -1});
        })

    };

    const splitItemToolTip = (_title, _propertyGroupKey) => {
        return Lang().cases.probates.assetsDebtsDetails.splitItemToolTip
            .replace('{typeName}', Lang().cases.probates.type.replace('{Name}', _title))
            .replace('{propertyOwner}', (_propertyGroupKey === ProbateProps.deceasedPropertyGroupedDetails)
                ? Lang().cases.probates.headerTitle.spouseDetails
                : Lang().cases.probates.headerTitle.deceasedDetails)
    }

    return <div className="summary-assets">
        <h5>{title}</h5>
        <table className="table">
            <thead>
            {
                <tr>
                    <th scope="col">{Lang().cases.probates.type.replace('{Name}', title)}</th>
                    {
                        (selectedDocumentType === documentType.deduction)
                        && <th scope="col">{Lang().cases.probates.getOrderTotal}</th>
                    }
                    <th scope="col">{Lang().cases.probates.value}</th>
                    <th scope="col">{Lang().cases.probates.appendix}</th>
                    <th scope="col">{Lang().cases.probates.name}</th>

                </tr>
            }
            </thead>
            <tbody>
            {properties &&
            properties.map((res, index) => (
                <tr key={`${res.id}${res.propertyOwner}${index}`}>
                    <th>
                        <Select
                            options={propertyOptions}
                            onChange={(e) => onPropertyTypeChange(e, res.id, index)}
                            value={
                                propertyOptions &&
                                propertyOptions.filter(
                                    (option) => option.id === res.propertySubTypeId
                                )
                            }
                            getOptionLabel={(propertyOptions) => propertyOptions["name"]}
                            getOptionValue={(propertyOptions) => propertyOptions["id"]}
                            className={`multiselect ${ValidationUtil.isSelectEmpty(res.propertySubTypeId)}`}
                            classNamePrefix={"multiselect"}
                        />
                    </th>
                    {(selectedDocumentType === documentType.deduction)
                    && <th>
                        <Button
                            onClick={() => onDeductionOrderTotalBtnClick(res, probateId, orders, index)}
                            disabled={isOrderTotalButtonDisabled(res.propertySubTypeId) || (orderTotalInProgressInfo.inProgress && (orderTotalInProgressInfo.index === index))}
                            icon="download"
                            isSmall
                        />
                        {(orderTotalInProgressInfo.inProgress && (orderTotalInProgressInfo.index === index)) &&
                        <Placeholder/>}
                    </th>
                    }
                    <th>
                        <NumberField value={res.value || ""}
                                     name={`${title}NumberField`}
                                     onChange={(value) => {
                                         // console.log("NumberField:: onValueChange :: value = ", value, typeof (value));
                                         onPropertyValueChange(value, res.id, index)
                                     }}
                                     className={`text ${ValidationUtil.isInputEmpty(res.value)}`}
                                     formatStyle={FormatStyle.currency}
                        />
                    </th>
                    <th>
                        <div className="probate--document">
                            <div className="btn-toolbar">
                                {<DocumentAccessView
                                    linkableId={res.propertyDocument ? res.propertyDocument.documentId : ''}
                                    caseId={caseId}
                                    onClick={() =>
                                        onClickShowDocumentHandlerPopup(selectedDocumentType, res, index)
                                    }
                                />
                                }
                            </div>
                        </div>

                    </th>
                    <th>
                        <input
                            type="text"
                            value={res.name || ""}
                            onChange={(e) =>
                                onNameTextChange(e.target.value, res.id, index)
                            }
                            className={`text ${ValidationUtil.isInputEmpty(res.name)}`}
                        />
                    </th>
                    <th>
                        <Button
                            isSmall
                            isTertiary
                            isDestructive
                            noLabel={true}
                            onClick={() => onRemoveProperty(index, res)}
                        >
                            {Utility.getLang().common.remove}
                        </Button>
                    </th>
                    {splitItem && <th>
                        <Button
                            isSecondary
                            onClick={() => splitItem(res, propertySubGroupKey, index)}
                            tooltip={splitItemToolTip(title, propertyGroupKey)}
                            tooltipAlignTop>
                            {Lang().cases.probates.assetsDebtsDetails.splitItem}
                        </Button>
                    </th>
                    }
                </tr>
            ))}
            <tr className="summary--add-btn">
                <td>
                    <Button onClick={() => addNewProperty()}
                            icon="add"/>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
}

export default PropertyView;

PropertyView.proptypes = {
    title: PropTypes.string,
    properties: PropTypes.array,
    propertyOptions: PropTypes.array,
    addNewProperty: PropTypes.func,
    onPropertyTypeChange: PropTypes.func,
    onPropertyValueChange: PropTypes.func,
    onClickShowDocumentHandlerPopup: PropTypes.func,
    onNameTextChange: PropTypes.func,
    onRemoveProperty: PropTypes.func,
    caseId: PropTypes.number.isRequired,
    selectedDocumentType: PropTypes.number.isRequired,
    probateId: PropTypes.any
}
