import _ from "lodash";
import CaseUtil from "../../common/caseUtil";
import AppUtil from "../../common/appUtil";
import GuestUtil from "../caseGuestsSubComponents/guestUtil";
import Utility, {updateObject} from "../../../api/utilLanguage";
import OrderUtil from "../../common/orderUtil";
import React from "react";
import ExpandableTable from "../../common/address/expandableTable";
import {LogTraceObjectType} from "../../common/log/util/logUtil";
import ValidationAndVisibilityRule, {
    getNestedPropertyFromValidationRules
} from "../../common/ValidationAndVisibilityRule";

class RelativeUtil {
    static property = {
        name: "name",
    };

    static isEmptyRelative = (contact) => {
        const contactWithPropertiesToCompare = _.pick(contact, ['firstName', 'phoneNumber', 'email', 'comment', 'personNumber', 'address', 'isBillingContact', 'isGuest', 'isPrimary']);
        const initialContactWithPropertiesToCompare = _.pick(CaseUtil.contactInitialData, ['firstName', 'phoneNumber', 'email', 'comment', 'personNumber', 'address', 'isBillingContact', 'isGuest', 'isPrimary']);
        return _.isEqualWith(contactWithPropertiesToCompare, initialContactWithPropertiesToCompare, (name, phoneNumber, email, comment, personNumber) => {
            if ((_.isNull(name) || name === '') &&
                (_.isNull(phoneNumber) || phoneNumber === 0) &&
                (_.isNull(email) || email === '') &&
                (_.isNull(comment) || comment === '') &&
                (_.isNull(personNumber) || personNumber === 0))
                return true;
        });
    };

    /***
     * @description: Find guest from relative
     */
    static getGuestDetailsFromRelative = (idFromRelative, guests) => {
        for (let index = 0; index < guests.length; index++) {
            if (guests[index].id === idFromRelative) {
                return {
                    guest: guests[index],
                    matchedIndex: index
                };
            }
            if (index === (guests.length - 1)) {
                return null;
            }
        }
    };

    static getNewGuestUpdatedFromRelative = (relativeWithGuestInfo) => {
        return CaseUtil.getNewGuest({
            id: relativeWithGuestInfo.id,
            firstName: relativeWithGuestInfo.firstName,
            lastName: relativeWithGuestInfo.lastName,
            phoneNumber: relativeWithGuestInfo.phoneNumber,
            email: relativeWithGuestInfo.email
        });
    };

    static updateNewGuestOfNewRelative = (relativeWithGuestInfo, caseData) => {
        const newGuest = RelativeUtil.getNewGuestUpdatedFromRelative(relativeWithGuestInfo);
        caseData.guests.push(newGuest);
    };

    static updateExistingGuestMatchingRelative = (relativeWithGuestInfo, guest) => {
        guest.isActive = true;
        guest.firstName = relativeWithGuestInfo.firstName;
        guest.email = relativeWithGuestInfo.email;
        guest.phoneNumber = relativeWithGuestInfo.phoneNumber;
        guest.additionalGuests = relativeWithGuestInfo.additionalGuests;
    };

    static updateGuestRelatedToRelative = (relativeWithGuestInfo, caseData) => {
        let result = RelativeUtil.getGuestDetailsFromRelative(relativeWithGuestInfo.id, caseData.guests);
        if (AppUtil.isAvailable(result)) {
            RelativeUtil.updateExistingGuestMatchingRelative(relativeWithGuestInfo, result.guest);
        } else {
            RelativeUtil.updateNewGuestOfNewRelative(relativeWithGuestInfo, caseData);
        }
    };

    static handleRelativeIsAGuest = (relativeWithGuestInfo, caseData) => {
        if (AppUtil.isEmpty(caseData.guests)) {
            caseData.guests = [];
        }
        if (AppUtil.isEmpty(caseData.guestCountInfo)) {
            caseData.guestCountInfo = GuestUtil.getDefaultGuestCountInfo();
        }

        RelativeUtil.updateGuestRelatedToRelative(relativeWithGuestInfo, caseData);

        if (relativeWithGuestInfo.rsvp === CaseUtil.rsvp.yes) {
            GuestUtil.updateAllRSVPYesOfGuestCountInfo(caseData.guestCountInfo, relativeWithGuestInfo, GuestUtil.guestAction.add);
        }

    };

    static handleRelativeIsNotAGuest = (relativeWithGuestInfo, caseData) => {
        let result = RelativeUtil.getGuestDetailsFromRelative(relativeWithGuestInfo.id, caseData.guests);
        if (AppUtil.isAvailable(result)) {
            let guest = result.guest;
            if (guest.id > 0) {
                guest.isActive = false;
            } else {
                caseData.guests.removeAtIndex(result.matchedIndex);
            }

            if (guest.rsvp === CaseUtil.rsvp.yes) {
                GuestUtil.updateAllRSVPYesOfGuestCountInfo(caseData.guestCountInfo, guest, GuestUtil.guestAction.delete);
            }
        }
    };

    static getRelativesWithEmail = (relatives) => {
        let relativesWithEmail = [];
        if (relatives !== undefined && relatives !== null) {
            for (let i = 0; i < relatives.length; i++) {
                if (relatives[i].email !== undefined && relatives[i].email !== null && relatives[i].email !== "") {
                    relativesWithEmail.push(relatives[i]);
                }
            }
        }
        return relativesWithEmail;
    };

    static disableBillingContactButton = (stage, billingContact) => {
        return (stage === OrderUtil.orderStages.invoice.value && AppUtil.isAvailable(billingContact)) || (stage === OrderUtil.orderStages.archived.value) || (stage === OrderUtil.orderStages.deleted.value)
    }

    static isEditable = (content, inArray) => {
        return (inArray.find((element) => {
                if (element.id === content) {
                    return !element.isEditable
                } else {
                    return false;
                }
            })
        )
    }

    static removeObject(contentToRemove, forKey, fromObject, objectKey) {
        fromObject[objectKey] = fromObject[objectKey].filter(item => item[forKey] !== contentToRemove);
    };

    /**
     *
     * @param {*} isDeletable is a property on relative based on funeral billing contact
     * @param {*} inArray  is a array of relative.UkContactProperties
     * @returns true or false
     * To disable the delete button on Contact
     * if Contact is Selected as Billing Contact or Selected as Either Celebrant or Church Service Contact
     */
    static showDeleteBtn(isDeletable, inArray) {
        return (!isDeletable ? isDeletable : this.getIsEditableProperty(inArray));
    }

    static getIsEditableProperty(inArray) {
        return AppUtil.isAvailable(inArray) && Array.isArray(inArray) ? !(inArray.some(element => element.isEditable === false)) : true;
    }

    static getBillingContacts = (contacts) => {
        if (AppUtil.isAvailable(contacts) && contacts.length > 0) {
            return contacts.filter(contact => {
                return contact.isBillingContact === true;
            });
        } else {
            return [];
        }
    }

    static removeLocalParametersBeforeComparison = (relatives) => {
        relatives.forEach(relative => delete relative.isModified);
    }

    static accessLevel = {
        read: 1,
        write: 2,
        none: 0
    };

    static getCountyParentId = () => {
        const {parentId, county} = ValidationAndVisibilityRule;
        const countyParentId = parseInt(getNestedPropertyFromValidationRules(parentId, county), 10);
        return countyParentId;
    }
}

export default RelativeUtil;

export function getNewContact(defaultContact, contactId) {
    return updateObject(defaultContact, {
        id: contactId,
        isPrimary: true,
        isActive: true,
        isBillingContact: true,
        relationshipId: "1"
    });
}

/***
 * @description: Billing contact address helper
 */
export function concatenateAddress(data) {
    const fields = [data.address, data.address2];
    const address = [];
    fields.forEach((field) => {
        if (AppUtil.isAvailable(field)) {
            address.push(field);
        }
    });
    return address.join(", ");
}

export function getContactAddressDetails(data) {
    if (AppUtil.isAvailable(data)) {
        const fields = [data.postalCode, data.city];
        const address = [];
        fields.forEach((field) => {
            if (AppUtil.isAvailable(field)) {
                address.push(field);
            }
        });

        if (AppUtil.isAvailable(data.county) && AppUtil.isAvailable(data.county.name)) {
            address.push(data.county.name);
        }
        if (AppUtil.isAvailable(data.country) && AppUtil.isAvailable(data.country.name)) {
            address.push(data.country.name);
        }
        return address.join(", ");
    } else {
        return "";
    }
}

export function getLatestBillingContact(oldOrderStage, newOrderStage, salesOrders, billingContact) {
    if (((oldOrderStage === OrderUtil.orderStages.invoice.value || oldOrderStage === OrderUtil.orderStages.archived.value) && (oldOrderStage === newOrderStage)) ||
        (newOrderStage === OrderUtil.orderStages.archived.value)) {
        return (AppUtil.isAvailable(salesOrders) && (salesOrders.length > 0)) ? OrderUtil.getActiveSalesOrder(salesOrders) : null;
    } else if ((newOrderStage === OrderUtil.orderStages.invoice.value) || (newOrderStage === OrderUtil.orderStages.order.value)) {
        return billingContact;
    } else {
        return null;
    }
}

/***
 * @description: Contact to log transformer
 */
function transformToLogFromContact(contact) {
    const addressFields = [
        {fieldName: Utility.getLang().cases.relativeTabContent.name, value: AppUtil.concatenateFullName(contact)},
        {
            fieldName: Utility.getLang().cases.relativeTabContent.address,
            value: concatenateAddress(contact.address)
        },
        {fieldName: " ", value: getContactAddressDetails(contact.address)},
        {
            fieldName: Utility.getLang().cases.relativeTabContent.email,
            value: contact.email,
            type: LogTraceObjectType.email
        },
        {fieldName: Utility.getLang().cases.relativeTabContent.mobileNumber, value: contact.mobileNumber},
        {fieldName: Utility.getLang().cases.relativeTabContent.phoneNumber, value: contact.phoneNumber},
    ];

    let nonEmptyAddressFields = [];
    addressFields.forEach((field) => {
        if (AppUtil.isAvailable(field.value)) {
            nonEmptyAddressFields.push(field);
        }
    });
    return getLogsFromAddressFields(nonEmptyAddressFields);
}

function getLogsFromAddressFields(addressFields) {
    let collection = [];
    let result = {};
    addressFields.forEach((addressField) => {
        collection.push({
            "fieldName": addressField.fieldName,
            "type": AppUtil.isAvailable(addressField.type) ? addressField.type : LogTraceObjectType.none,
            "oldValueDetails": [],
            "newValueDetails": [
                {
                    "id": 0,
                    "text": addressField.value,
                    "attribute": null
                }
            ]
        });
    })
    result.logCollection = collection;

    result.traceObjects = [];
    result.fieldCount = addressFields.length;// > 1, To support expandable
    result.fieldNames = Utility.getLang().cases.relativeTabContent.billingAddress;

    return [result];
}

export function getContactAddressView(contact) {
    const data = transformToLogFromContact(contact);

    return (<div className="log_item__list">
        <ExpandableTable logData={data} expanded={true} currentObject="case"/>
    </div>)
}
