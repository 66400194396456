import React from "react";
import connect from "react-redux/es/connect/connect";
import {ProbateProps} from "../tabProbateMeeting/util";
import {useQuestionnaireProbatePopup} from "./hooks/useQuestionnaireProbatePopup";
import PropTypes from "prop-types";
import AppUtil, {Lang} from "../../../../common/appUtil";
import Button from "../../../../common/button";
import ProbatePopup from "../probatePopup";
import QuestionnaireChangeListener from "./questionnaireChangeListener";
import {useQuestionnaireUI} from "./hooks/useQuestionnaireUI";
import useDeleteQuestionnaireBeneficiaryPopup from "./hooks/useDeleteBeneficiaryPopup";
import GetViewByProbateQA from "./getViewByProbateQA";
import QuestionnaireBeneficiaryChangeListener
    from "./questionnaireBeneficiaries/questionnaireBeneficiaryChangeListener";

function Questionnaire({
                           probateQAs,
                           enums,
                           isProbateInfoLoading,
                           showQuestionnairePopup,
                           onSaveClick,
                           isProbateQAsChanged,
                           initialProbateQAs,
                           probateId,
                           beneficiaries,
                           allRelationships,
                           initialBeneficiaries,
                           showDeleteQuestionnaireBeneficiaryPopup,
                           dispatch
                       }) {
    const QUIProps = useQuestionnaireUI(enums, initialProbateQAs);
    const {
        currentProbateQAs,
        initializeQuestionnaires,
        userName
    } = QUIProps;
    const {showPopup, onPopupConfirmation, onPopupCancel} = useQuestionnaireProbatePopup(onSaveClick, dispatch);
    // console.log("Questionnaire :: probateQAs = ", probateQAs, currentProbateQAs, enums);

    const {
        showDeleteQuestionnairePopup,
        onDeleteQuestionnairePopupConfirmation,
        crudBeneficiaryTracker,
        deletePopupContent
    } = useDeleteQuestionnaireBeneficiaryPopup(dispatch);

    const commonProps = {currentProbateQAs, initialProbateQAs, showDeleteQuestionnairePopup};
    return <>
        {/*{Below listener correctly calls, when called from initial callee}*/}
        <QuestionnaireBeneficiaryChangeListener/>
        <QuestionnaireChangeListener probateQAs={currentProbateQAs} initialProbateQAs={initialProbateQAs}/>
        <ProbatePopup id={ProbateProps.deleteQuestionnaireBeneficiaryPopup}
                      show={showDeleteQuestionnaireBeneficiaryPopup}
                      title={Lang().cases.probates.deleteBeneficiaryPopup.header}
                      content={crudBeneficiaryTracker.data ? deletePopupContent(userName(crudBeneficiaryTracker.data?.caseRelative)) : ""}
                      onConfirmation={() => onDeleteQuestionnairePopupConfirmation(crudBeneficiaryTracker)}
                      dispatch={dispatch}
        />
        <ProbatePopup id={ProbateProps.questionnaireProbatePopup}
                      show={showQuestionnairePopup}
                      title={Lang().cases.probates.questionnaire.probateQuestionnaire}
                      confirmationButtonTitle={Lang().cases.probates.questionnaire.confirmBtnTitle}
                      onConfirmation={() => onPopupConfirmation(currentProbateQAs, beneficiaries)}
                      disableConfirmButton={isProbateQAsChanged === false}
                      onCancel={() => onPopupCancel(isProbateQAsChanged, initialProbateQAs, initializeQuestionnaires, initialBeneficiaries)}
                      modalClassName={ProbateProps.modalClassName}
                      dispatch={dispatch}
        >
            {
                currentProbateQAs && currentProbateQAs.map((probateQA, index) => {
                        return <GetViewByProbateQA key={`${probateQA.id} + ${index}`}
                                                id={`${probateQA.id} + ${index}`}
                                                probateQA={probateQA}
                                                disabled={false}
                                                probateId={probateId}
                                                beneficiaries={beneficiaries}
                                                allRelationships={allRelationships}
                                                commonProps={commonProps}
                                                dispatch={dispatch}
                                                QUIProps={QUIProps}
                        />
                    }
                )
            }
        </ProbatePopup>
        <Button onClick={showPopup} disabled={isProbateInfoLoading}>
            {Lang().cases.probates.questionnaire.open}
        </Button>
    </>
}

function mapStateToProps(state) {
    return {
        showQuestionnairePopup: state.probateReducer.showQuestionnairePopup,
        isProbateQAsChanged: state.probateReducer.probateInfo.isProbateQAsChanged,
        initialProbateQAs: AppUtil.isAvailable(state.probateReducer.initialProbateInfo)
        && AppUtil.isAvailable(state.probateReducer.initialProbateInfo.probateQAs) ? state.probateReducer.initialProbateInfo.probateQAs : [],
        probateId: state.probateReducer.probateInfo.id,
        beneficiaries: AppUtil.isAvailable(state.probateReducer.probateInfo) ? state.probateReducer.probateInfo.beneficiaries : [],
        initialBeneficiaries: AppUtil.isAvailable(state.probateReducer.initialProbateInfo) ? state.probateReducer.initialProbateInfo.beneficiaries : [],
        allRelationships: state.application.allRelationshipInfo,
        showDeleteQuestionnaireBeneficiaryPopup: state.probateReducer.showDeleteQuestionnaireBeneficiaryPopup,

    }
}

Questionnaire.propTypes = {
    probateQAs: PropTypes.array.isRequired,
    enums: PropTypes.array.isRequired
}

const MemorizedQuestionnaire = React.memo(connect(mapStateToProps)(Questionnaire));
export default MemorizedQuestionnaire;
