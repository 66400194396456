import {ProbateProps} from "../ui/tabProbateMeeting/util";
import ValidationAndVisibilityRule, {validationRule} from "../../../common/ValidationAndVisibilityRule";
import ValidationUtil, {
    allowNumberAndDash,
    allowNumberAndSpace,
    characterLimitExceededError,
    checkPersonNumberFormat,
    checkPostalCodeFormat,
    checkValidation,
    getNoSpaceError,
    getPersonNumberFormatError,
    getPostalCodeFormatError
} from "../../../common/validationUtil";
import AppUtil from "../../../common/appUtil";

export const handleKeyPressedEvent = (event, fieldName) => {
    switch (fieldName) {
        case ProbateProps.postalCode:
            const rule = validationRule(ValidationAndVisibilityRule.common, ValidationAndVisibilityRule.postCode)
            rule.onlyDigits === "true" ? allowNumberAndSpace(event) : ValidationUtil.allowNumberAndAlphabets(event);
            break;
        case ProbateProps.phoneNumber:
        case 'mobileNumber':
            ValidationUtil.allowNumberPlusAndDash(event);
            break;
        case ProbateProps.personNumber:
            allowNumberAndDash(event);
            break;
        default:
            //Do nothing
            console.log("[Failure]:: handling key pressed for '%s' ", fieldName);
            break;
    }
}

export const InlineValidation = (property, value, messageTitle = '') => {
    const validation = {view: null, className: ''};
    switch (property) {
        case ProbateProps.firstName:
        case ProbateProps.lastName: {
            validation.view = ValidationUtil.isInvalidName(value)
                ? ValidationUtil.getNameError(value, messageTitle)
                : null;
            validation.className = ValidationUtil.isInvalidName(value) ? "is-error" : ""
            break;
        }
        case ProbateProps.address:
        case ProbateProps.address2: {
            validation.view = checkValidation(value) !== ""
                ? getNoSpaceError()
                : value?.length >= ValidationUtil.ADDRESS_CHARACTER_LENGTH_LIMIT
                    ? characterLimitExceededError()
                    : null;

            validation.className = checkValidation(value);
            break;
        }
        case ProbateProps.postalCode: {
            validation.view = checkValidation(value) !== ""
                ? getNoSpaceError()
                : checkPostalCodeFormat(value) !== ""
                    ? getPostalCodeFormatError()
                    : null;

            validation.className = checkValidation(value) + checkPostalCodeFormat(value)
            break;
        }
        case ProbateProps.city: {
            validation.view = checkValidation(value) !== "" ? getNoSpaceError() : null;
            validation.className = checkValidation(value);
            break;
        }
        case ProbateProps.phoneNumber: {
            const rule = validationRule(ValidationAndVisibilityRule.relative, ValidationAndVisibilityRule.phone);
            const isInvalid = ValidationUtil.isInvalidPhoneWithGivenRegex(value, new RegExp(rule.regex));
            validation.view = isInvalid && ValidationUtil.getPhoneNumberError();
            validation.className = isInvalid ? "is-error" : ""
            break;
        }
        case 'mobileNumber': {
            const rule = validationRule(ValidationAndVisibilityRule.relative, ValidationAndVisibilityRule.mobile);
            const isInvalid = ValidationUtil.isInvalidMobileWithGivenRegex(value, new RegExp(rule.regex));
            validation.view = isInvalid && ValidationUtil.getMobileNumberError();
            validation.className = isInvalid ? "is-error" : ""
            break;
        }
        case ProbateProps.personNumber: {
            validation.view = checkValidation(value) !== ""
                ? getNoSpaceError()
                : checkPersonNumberFormat(AppUtil.isAvailable(value) ? value : null) !== ""
                    ? getPersonNumberFormatError() : null;
            validation.className = checkValidation(value) + checkPersonNumberFormat(AppUtil.isAvailable(value) ? value : null)
            break;
        }
        case ProbateProps.email: {
            const isInvalid = ValidationUtil.isInvalidEmail(value);
            validation.view = isInvalid ? ValidationUtil.getEmailError() : null;
            validation.className = isInvalid ? "is-error" : "";
            break;
        }
        default:
            //Do nothing
            console.log("[Failure]:: Inline validation for '%s' ", property);
            break;
    }
    return validation;
}

export const isValid = (contact) => {
    if (!contact) {
        return false;
    }

    if (AppUtil.isEmpty(contact.firstName)
        || ValidationUtil.hasPreOrPostSpace(contact.firstName)
        || ValidationUtil.hasPreOrPostSpace(contact.lastName)
        || AppUtil.isEmpty(contact.lastName)
        || ValidationUtil.isInvalidPhone(contact.phoneNumber)
        || ValidationUtil.hasPreOrPostSpace(contact.personNumber)
        || AppUtil.isAvailable(contact.personNumber) && !ValidationUtil.isValidPersonNumber(contact.personNumber)) {
        return false;
    }

    if (AppUtil.isAvailable(contact.address)) {
        if (ValidationUtil.hasPreOrPostSpace(contact.address.address)
            || ValidationUtil.hasPreOrPostSpace(contact.address.address2)
            || ValidationUtil.hasPreOrPostSpace(contact.address.city)
            || ValidationUtil.hasPreOrPostSpace(contact.address.postalCode)
            || ValidationUtil.checkPostalCode(contact.address.postalCode)
        ) {
            return false;
        }

        if (!ValidationUtil.isValidRelativeAddressLength(contact.address)) {
            return false;
        }
    }
    return true;

}