import Utility from "../../../../api/utilLanguage";
import React from "react";
import {connect} from "react-redux";
import EmailContainer from "./emailContainer";
import OrderUtil from "../../orderUtil";
import Button from "../../button";
import {bindActionCreators} from "redux";
import * as caseAction from "../../../../actions/caseAction";
import PropTypes from "prop-types";
import {cloneDeep} from "lodash";

class EmailCaseOrderChange extends React.Component {

    constructor(props) {
        super(props);
        this.ordersChangedForSendMail = null;
        this.checkedStatus = false;
        this.buttonDisableStatus = true;
        this.state = {
            changeStatus: false
        }
    }

    componentDidMount() {
        this.initialisedOrderChecked(cloneDeep(this.props.orders));
    }

    initialisedOrderChecked = (orders) => {
        this.ordersChangedForSendMail = OrderUtil.changedOrdersForSendMail(orders);
        this.ordersChangedForSendMail.forEach((order) => {
            order.checked = false;
        })
    }
    
    ChangeAllCheckStatus = (e) => {
        this.ordersChangedForSendMail.forEach((order) => {
            order.checked = e.target.checked;
        });
        this.checkedStatus = e.target.checked;
        this.checkDisableStatus();
        this.setState({changeStatus: true})
    };

    changeOrderCheckedStatus = (e, index) => {
        this.ordersChangedForSendMail[index].checked = e.target.checked;

        if (!e.target.checked) {
            this.checkedStatus = false;
        }
        this.checkDisableStatus();
        this.setState({changeStatus: true})
    };

    handleSelectedUser = (selectedOption, index) => {
        this.ordersChangedForSendMail[index].billingContactId = selectedOption.id;
        console.log("selectedOption new", this.ordersChangedForSendMail[index], index);
    };

    changeMessage = (e, index) => {
        this.ordersChangedForSendMail[index].emailMessage = e.target.value;
        console.log("selectedOption 2", this.ordersChangedForSendMail[index], index);
    };

    checkDisableStatus = () => {
        if (this.checkedStatus) {
            this.buttonDisableStatus = false;
        } else {
            let selectedCount = 0;
            this.ordersChangedForSendMail.forEach((order) => {
                if (order.checked) {
                    selectedCount++;
                }
            });
            if (selectedCount !== 0) {
                this.buttonDisableStatus = false;
            } else {
                this.buttonDisableStatus = true;
            }

        }

    };

    sendMailOnConfirm = () => {
        this.ordersChangedForSendMail.forEach((order) => {
            if (order.checked) {
                let emailType = OrderUtil.isProposal(order.stage) === true ? OrderUtil.orderEmailMatrix[2].type : OrderUtil.orderEmailMatrix[4].type;
                let jsonBody = {
                    "caseId": this.props.caseId,
                    "relativeId": order.billingContactId,
                    "orderIds": [order.id],
                    "message": order.emailMessage,
                    "emailIds": "",
                    "emailtype": emailType
                };
                this.props.caseAction.sendEmailSummary(order.id, jsonBody);
            }
        });
        this.props.onConfirm();
    };

    render = () => {

        return (
            <React.Fragment>
                <div className="card card--modal">
                    <h3 className="card__title">{Utility.getLang().common.emailOnSaveCaseChanges.title}</h3>
                    <p>{Utility.getLang().common.emailOnSaveCaseChanges.description}</p>
                    <div className="table__wrapper table__wrapper--change_stage">
                        <table className="fnxtable fnxtable--flat">
                            <thead>
                            <tr className="tr tr--head">
                                <th
                                    className="th th--actions th--select"
                                    scope="col"
                                >
                                    <div className="option option--checkbox option--no_option">
                                        <input
                                            id="emailCaseOrderChangeSelectAllNone"
                                            name="emailCaseOrderChangeSelectAllNone"
                                            type="checkbox"
                                            onChange={this.ChangeAllCheckStatus}
                                            checked={this.checkedStatus}
                                        />
                                        <label
                                            htmlFor="emailCaseOrderChangeSelectAllNone"
                                            className="option__description option__description--checkbox"
                                        >
                                        </label>
                                    </div>
                                </th>
                                <th
                                    className="th"
                                    scope="col"
                                >
                                    {Utility.getLang().common.objectType.order}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <EmailContainer
                                ordersChangedForSendMail={this.ordersChangedForSendMail}
                                changeCheckStatus={(e, index) => this.changeOrderCheckedStatus(e, index)}
                                relatives={this.props.relatives}
                                handleSelectedUser={(selectedOption, index) => this.handleSelectedUser(selectedOption, index)}
                                changeEmailMessage={(e, index) => this.changeMessage(e, index)}
                            />
                            </tbody>
                        </table>
                    </div>
                    <div className="btn-toolbar btn-toolbar--modal">
                        <Button
                            onClick={this.sendMailOnConfirm}
                            isPrimary
                            disabled={this.buttonDisableStatus}
                            icon="email"
                        >
                            {Utility.getLang().common.sendEmail}
                        </Button>
                        <Button onClick={this.props.onCloseModal}>
                            {Utility.getLang().common.dontSend}
                        </Button>
                    </div>
                </div>
            </React.Fragment>
        )
    };
}

function mapStateToProps() {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        caseAction: bindActionCreators(caseAction, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailCaseOrderChange);

EmailCaseOrderChange.propTypes = {
    caseId: PropTypes.number,
    orders: PropTypes.array,
    relatives: PropTypes.array,
    onConfirm: PropTypes.func.isRequired,
    onCloseModal: PropTypes.func.isRequired
}