import $ from 'jquery';
import config from "../config";
import ErrorUtil from "../../components/common/errorUtil";
import AppUtil from "../../components/common/appUtil";
import ApiCollection from "../apiCollections";

class DocumentApi {

    static genericApiCall(url, token, method, data = null, {contentType = "application/json"} = {}) {

        let header = this.getHeader(data, contentType, token);
        let ajaxUrl = this.getAjaxUrl(url, method, header, data);

        return new Promise((resolve, reject) => {
            let request = $.ajax(ajaxUrl);
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    }

    static getHeader(data, contentType, token) {
        let header = {};
        if ((data instanceof FormData) === false) {
            header["Content-Type"] = contentType;
        }
        header["Authorization"] = `Bearer ${token}`;
        return header;
    }

    static getAjaxUrl(url, method, header, data) {
        let ajaxUrl = {};
        ajaxUrl["url"] = url;
        ajaxUrl["method"] = method;
        ajaxUrl["headers"] = header;
        ajaxUrl["data"] = data;

        if (data instanceof FormData) {
            ajaxUrl["processData"] = false;
            ajaxUrl["contentType"] = false;
            // console.log("File data");
        }

        return ajaxUrl;
    }

    /***
     * @description: to get CaseDocuments details
     * @param token
     * @param caseId
     * @returns {Promise<unknown>}
     */
    static getCaseDocuments(token, caseId) {
        const url = ApiCollection.properties.fetchCaseDocuments.replace('{caseId}', caseId);
        return DocumentApi.genericApiCall(url, token, "GET")
    }

    /***
     * @description: to get Documents types "/api/v1/case/documenttypes"
     * @param token
     * @param caseId
     * @returns {Promise<unknown>}
     */
    static getDocumentTypes(token, caseId) {
        const url = ApiCollection.properties.fetchDocumentTypes;
        return DocumentApi.genericApiCall(url, token, "GET");
    }

    /****
     * @description: to generate documents "api/v1/case/{caseId}/document/generate/{documentTypeId}"
     * @param token
     * @param document : holds document related information
     * @param documentCategoryId
     * @param documentTypeId
     * @returns {*|Promise<unknown>}
     */
    static generateDocument(token, caseId, documentTypeId, orderId) {
        const url = config.baseURL + 'api/v1/case/' + caseId + '/document/generate/' + documentTypeId + (AppUtil.isAvailable(orderId) ? ('?orderId=' + orderId) : "");
        return DocumentApi.genericApiCall(url, token, "POST");
    }

    static getBase64Document(token, caseId, documentId) {
        const url = ApiCollection.properties.fetchBase64Document.replace('{caseId}', caseId).replace('{documentId}', documentId);
        return DocumentApi.genericApiCall(url, token, "GET");
    }

    /***
     * @description : "/api/v1/case/{caseId}/document/{documentId}/version/{versionId}/base64"
     * @param token
     * @param caseId
     * @param documentId
     * @param versionId
     * @returns {*|Promise<unknown>|Promise<any>}
     */
    static getBase64DocumentByVersion(token, caseId, documentId, versionId) {
        const url = config.baseURL + 'api/v1/case/' + caseId + '/document/' + documentId + '/version/' + versionId + '/base64';
        return DocumentApi.genericApiCall(url, token, "GET");
    }

    /****
     * @description: to update documents "/api/v1/case/{caseId}/document/generate/{documentTypeId}"
     * @param token
     * @param document : holds document related information
     * @param documentCategoryId
     * @param documentTypeId
     * @returns {*|Promise<unknown>}
     */
    static updateDocument(token, document, documentCategoryId, documentTypeId) {
        const url = ApiCollection.properties.updatedDocument.replace('{caseId}', document.caseId).replace('{documentId}', document.id);
        const data = {
            "id": document.id,
            "orderId": document.orderId,
            "caseId": document.caseId,
            "documentTypeId": documentTypeId,
            "documentCategoryId": documentCategoryId,
            "freeTextNote": document.freeTextNote,
            "isEditable": document.isEditable,
            "url": document.url
        }
        if (AppUtil.isAvailable(document.includeAttachments)) {
            data["includeAttachments"] = document.includeAttachments;
        }
        return DocumentApi.genericApiCall(url, token, "PUT", JSON.stringify(data));
    }

    /***
     * @description: to upload document, Important: here processData = false & contentType is empty
     * "/api/v1/case/{caseId}/document/upload"
     * @param token
     * @param caseId
     * @param fileData : Document data, which is uploaded
     * @returns {*|Promise<unknown>}
     */
    static uploadDocument(token, caseId, fileData) {
        const url = ApiCollection.properties.uploadDocument.replace('{caseId}', caseId);
        return DocumentApi.genericApiCall(url, token, "POST", fileData);
    }

    /***
     * @description: to delete document
     * @param token
     * @param urlInfo
     * @param caseId
     * @param documentId
     * @returns {Promise<unknown>}
     */
    static deleteDocument(token, caseId, documentId) {
        const url = ApiCollection.properties.deleteCaseDocument.replace("{caseId}", caseId) + documentId;
        return DocumentApi.genericApiCall(url, token, "DELETE");
    }

    /***
     * @description: Email documents
     * @param token
     * @param id
     * @param data
     * @returns {*|Promise<unknown>}
     */
    static emailDocuments(token, id, data) {
        const url = config.baseURL + 'api/v1/case/' + id + '/document/sendemail';
        return DocumentApi.genericApiCall(url, token, "POST", JSON.stringify(data));
    }
}

export default DocumentApi;
