import FieldType from "../../common/fieldType";
import DataKey from "../../common/dataKey";
import {LocationTypes} from "../../../api/searchService";
import Utility from "../../../api/utilLanguage";

export const EventAttribute = {
    none: -1,

    ceremonyType: 1,
    ceremonyUrnOrCasket: 2,
    ceremonyLocation: 3,
    ceremonyDate: 4,
    ceremonyTime: 5,
    ceremonyPall: 6,
    ceremonyOfficiant: 107,
    ceremonyOrganist: 108,
    ceremonyRepresentative: 109,
    ceremonyHandleFlowersAfterCeremony: 110,
    ceremonyUrnTransportToCeremony: 111,
    ceremonyDressCode: 112,
    ceremonyCareTaker: 113,
    ceremonyFlorist: 114,

    // Event attributes for Event.Entombment
    entombmentType: 7,
    entombmentLocation: 8,
    entombmentNewOrExistingGrave: 9,
    entombmentGraveNumber: 10,
    entombmentDate: 11,
    entombmentTime: 12,
    entombmentCrematoriumLocation: 213,
    entombmentGraveDeedHolder: 214,
    entombmentUrnTransportToEntombment: 215,
    entombmentRelativesAttendance: 216,

    // Event attributes for Event.Reception
    receptionLocation: 13,
    receptionDate: 14,
    receptionTime: 15,
    receptionHandleRsvp: 16,
    receptionRsvpDate: 17,
    receptionArrangedBy: 318,
    receptionNumberOfGuests: 319,
    receptionMaximumRegistrations: 320,

    // Event attributes for Event.Preparation
    preparationPersonalFarewell: 401,
    preparationPersonalFarewellDate: 402,
    preparationPersonalFarewellTime: 403,
    preparationPersonalFarewellLocation: 404,
    preparationBelongings: 405,
    preparationTransporter: 406,
    preparationMemorialFund: 407,
    preparationMortuaryRemoval: 408,
    preparationMemorialPage: 409,


    // Event attributes for Event.PreFuneral
    preFuneralFuneralPartner: 501,
    preFuneralViewing: 502,
    preFuneralViewingDate: 503,
    preFuneralViewingTime: 504,
    preFuneralViewingLocation: 505,
    preFuneralEmbalming: 506,
    preFuneralDressing: 507,
    preFuneralDateDeceasedBoughtInToCare: 508,
    preFuneralOtherBelongingInCoffin: 509,
    preFuneralCoffinOrderedBy: 510,
    preFuneralCoffinDeliveryDate: 511,
    preFuneralMemorialPage: 512,
    preFuneralMemorialFund: 513,

    // Event attributes for Event.FuneralService
    funeralServiceTypeOfService: 601,
    attendedFuneralServiceTypeOfService: 643,
    funeralServiceCremetorium: 602,
    funeralServiceDateOfCremation: 603,
    funeralServiceRelativesAndExecutorsInformed: 604,
    funeralServiceNotInformedDetails: 605,
    funeralServiceCremationObjections: 606,
    funeralServiceObjectionsDetails: 607,
    funeralServiceCemetery: 608,
    funeralServiceCemeteryPlot: 609,
    funeralServiceCemeteryPlotType: 610,
    funeralServiceCemeteryPlotNumber: 611,
    funeralServiceCelebrantAttendance: 612,
    funeralServiceCelebrantName: 613,
    funeralServiceCelebrantPhoneNumber: 614,
    funeralServiceCelebrantEmailAddress: 615,
    funeralServiceNumberOfBearersRequired: 618,
    funeralServiceNumberOfFamilyBearers: 619,
    funeralServiceTrolley: 620,
    funeralServiceOrderOfService: 621,
    funeralServiceMusic: 622,
    funeralServiceLimousine: 623,
    funeralServiceLimousinePickUpAddress: 624,
    funeralServiceLimousinePickUpTime: 625,
    funeralServiceLimousineDropOffAddress: 626,
    funeralServiceChurchServiceLocation: 627,
    funeralServiceChurchServiceDate: 628,
    funeralServiceChurchServiceTime: 629,
    funeralServiceChurchServiceFeeDetails: 630, //TODO: Unused will be removed
    funeralServiceChurchServiceFeesPaidBy: 631, //TODO: Unused will be removed
    funeralServiceChurchServiceContactName: 632,
    funeralServiceChurchServiceContactTelePhoneNumber: 633,
    funeralServiceChurchServiceContactEmail: 634,
    funeralServiceTimeForCremation: 635,
    funeralServiceWitnessedCharging: 636,
    funeralServiceCrematoriumFee: 637, //TODO: Unused will be removed
    funeralServiceCrematoriumFeeDetails: 638, //TODO: Unused will be removed
    funeralServiceDateForBurial: 639,
    funeralServiceTimeForBurial: 640,
    funeralServiceDeceasedBoroughResidency: 641,
    funeralServicePurchaserBoroughResidency: 642,
    funeralServiceGravemarkerInscription: 644,
    funeralServiceWitnessedBackfill: 645,
    funeralServiceCelebrantContact: 646,
    funeralServiceLimousineHearseViaHouse: 647,
    funeralServiceChurchServiceContact: 648,
    funeralServiceNumberOfMournerExpected: 718,

    // Event attributes for Event.PostFuneral
    postFuneralAshesDisposalTypeForCrem1: 701,
    postFuneralAshesDisposalTypeInternal: 702,
    attendedPostFuneralAshesDisposalTypeInternal: 716,
    postFuneralAshesInstructionsForCrem1: 703,
    postFuneralOverseasCertificateAndSealingRequired: 704,
    postFuneralAlternativeUrnRequired: 705,
    postFuneralAlternativeUrnDetails: 706, //TODO: Unused will be removed
    postFuneralAshesDateOfDelivery: 707,
    postFuneralAshesTimeOfDelivery: 708,
    postFuneralIntermentLocation: 709,
    postFuneralDateForInterment: 710,
    postFuneralTimeForInterment: 711,
    postFuneralIntermentPlotDetails: 712,
    postFuneralIntermentFeeDetails: 713, //TODO: Unused will be removed
    postFuneralIntermentFeePaidBy: 714, //TODO: Unused will be removed
    postFuneralFDAttendanceAtInterment: 715,
    postFuneralAlternativeUrnOrderedBy: 717,
    postFuneralHandoverNotes: 719,

    // Event attributes for Event.Repatriation
    repatriationServiceType: 801,
    repatriationFuneralPartner: 802,
    repatriationDeceasedBroughtIntoCareDate: 803,
    repatriationViewing: 804,
    repatriationViewingDate: 805,
    repatriationViewingTiming: 806,
    repatriationViewingLocation: 807,
    repatriationAttendanceAtViewing: 808,
    repatriationEmbalmingType: 809,
    repatriationDressingType: 810,
    repatriationCoffinUpgrade: 811,
    repatriationFuneralService: 812,
    repatriationMemorialPage: 813,
    repatriationMemorialFund: 814,
    repatriationEmbassyVisitRequired: 815,
    repatriationTranslationDocumentsRequired: 816,
    repatriationLegalisationDocumentsRequired: 817,
    repatriationDestinationCity: 818,
    repatriationDestinationCountry: 819,
    repatriationDestinationAirport: 820,
    repatriationRequestedDateFlight: 821,
    repatriationConfirmedDateFlight: 822,
    repatriationConfirmedTimeFlight: 823,
    repatriationAdditionalInformation: 824,
}

export default {
    [EventAttribute.preFuneralFuneralPartner]: {
        fieldType: FieldType.TEXT,
        sizeCss: "large"
    },
    [EventAttribute.preFuneralViewing]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        sizeCss: "small"
    },
    [EventAttribute.preFuneralViewingDate]: {
        fieldType: FieldType.DATE,
        sizeCss: "small",
        availableOn: [
            {
                attributeType: EventAttribute.preFuneralViewing,
                value: ["1"]
            }
        ]
    },
    [EventAttribute.preFuneralViewingTime]: {
        fieldType: FieldType.TIME,
        sizeCss: "small",
        availableOn: [
            {
                attributeType: EventAttribute.preFuneralViewing,
                value: ["1"]
            }
        ]
    },
    [EventAttribute.preFuneralViewingLocation]: {
        fieldType: FieldType.ASYNC_SELECT,
        data: [],
        dataKey: DataKey.LOCATIONS,
        locationId: LocationTypes.hospital,
        isSearchable: true,
        isClearable: true,
        sizeCss: "large",
        availableOn: [
            {
                attributeType: EventAttribute.preFuneralViewing,
                value: ["1"]
            }
        ]
    },
    [EventAttribute.preFuneralEmbalming]: {
        fieldType: FieldType.CREATE_SELECT,
        data: [],
        dataKey: DataKey.PRE_FUNERAL_EMBALMING,
        sizeCss: "small"
    },
    [EventAttribute.preFuneralDressing]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        dataKey: DataKey.PRE_FUNERAL_DRESSING,
        sizeCss: "small"
    },
    [EventAttribute.preFuneralDateDeceasedBoughtInToCare]: {
        fieldType: FieldType.DATE,
        sizeCss: "small"
    },
    [EventAttribute.preFuneralOtherBelongingInCoffin]: {
        fieldType: FieldType.TEXTAREA,
        sizeCss: "large"
    },
    [EventAttribute.preFuneralCoffinOrderedBy]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        isClearable: true,
        sizeCss: "small"
    },
    [EventAttribute.preFuneralCoffinDeliveryDate]: {
        fieldType: FieldType.DATE,
        availableOn: [
            {
                attributeType: EventAttribute.preFuneralCoffinOrderedBy,
                value: ["1"]
            }
        ],
        sizeCss: "small"
    },
    [EventAttribute.preFuneralMemorialPage]: {
        fieldType: FieldType.CHECKBOX,
    },
    [EventAttribute.preFuneralMemorialFund]: {
        fieldType: FieldType.CREATE_SELECT,
        data: [],
        dataKey: DataKey.MEMORIAL_FUNDS,
        categorizedData: true,
        categorizedDataLabel: Utility.getLang().cases.orderTabContent.funeralCaseOrderContent.allMemorialFunds,
        sizeCss: "large"
    },
    [EventAttribute.attendedFuneralServiceTypeOfService]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        dataKey: DataKey.FS_ATTENDED_TYPE_OF_SERVICE,
        isClearable: true,
        sizeCss: "large"
    },
    [EventAttribute.funeralServiceTypeOfService]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        dataKey: DataKey.FS_TYPE_OF_SERVICE,
        isClearable: true,
        sizeCss: "large"
    },
    [EventAttribute.funeralServiceCremetorium]: {
        fieldType: FieldType.ASYNC_SELECT,
        data: [],
        dataKey: DataKey.LOCATIONS,
        locationId: LocationTypes.crematorium,
        isSearchable: true,
        isClearable: true,
        availableOn: [
            {
                attributeType: EventAttribute.attendedFuneralServiceTypeOfService,
                value: ["1", "2", "4"]
            },
            {
                attributeType: EventAttribute.funeralServiceTypeOfService,
                value: ["1", "3"]
            }
        ],
        sizeCss: "large"
    },
    [EventAttribute.funeralServiceDateOfCremation]: {
        fieldType: FieldType.DATE,
        availableOn: [
            {
                attributeType: EventAttribute.attendedFuneralServiceTypeOfService,
                value: ["1", "2", "4"]
            },
            {
                attributeType: EventAttribute.funeralServiceTypeOfService,
                value: ["1", "3"]
            }
        ],
        sizeCss: "small"
    },
    [EventAttribute.funeralServiceTimeForCremation]: {
        fieldType: FieldType.TIME,
        availableOn: [
            {
                attributeType: EventAttribute.attendedFuneralServiceTypeOfService,
                value: ["1", "2", "4"]
            }
        ],
        sizeCss: "small"
    },
    [EventAttribute.funeralServiceRelativesAndExecutorsInformed]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        dataKey: DataKey.FS_RELATIVES_AND_EXECUTORS_INFORMED,
        sizeCss: "large",
        availableOn: [
            {
                attributeType: EventAttribute.funeralServiceTypeOfService,
                value: ["1", "3"],

            }
        ]
    },
    [EventAttribute.funeralServiceNotInformedDetails]: {
        fieldType: FieldType.TEXTAREA,
        sizeCss: "large",
        everyAvailableOnSatisfied: true,
        availableOn: [
            {
                attributeType: EventAttribute.funeralServiceTypeOfService,
                value: ["1", "3"],

            },
            {
                attributeType: EventAttribute.funeralServiceRelativesAndExecutorsInformed,
                value: ["2"],

            }
        ]
    },
    [EventAttribute.funeralServiceCremationObjections]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        dataKey: DataKey.FS_CREMATION_OBJECTIONS,
        sizeCss: "large",
        availableOn: [
            {
                attributeType: EventAttribute.funeralServiceTypeOfService,
                value: ["1", "3"]
            }
        ],
    },
    [EventAttribute.funeralServiceObjectionsDetails]: {
        fieldType: FieldType.TEXTAREA,
        sizeCss: "large",
        everyAvailableOnSatisfied: true,
        availableOn: [
            {
                attributeType: EventAttribute.funeralServiceTypeOfService,
                value: ["1", "3"]
            },
            {
                attributeType: EventAttribute.funeralServiceCremationObjections,
                value: ["1"]
            }
        ]
    },
    [EventAttribute.funeralServiceCemetery]: {
        fieldType: FieldType.ASYNC_SELECT,
        data: [],
        dataKey: DataKey.LOCATIONS,
        locationId: LocationTypes.graveyards,
        isSearchable: true,
        isClearable: true,
        availableOn: [
            {
                attributeType: EventAttribute.attendedFuneralServiceTypeOfService,
                value: ["3", "5"]
            },
            {
                attributeType: EventAttribute.funeralServiceTypeOfService,
                value: ["2"]
            }
        ],
        sizeCss: "large"
    },
    [EventAttribute.funeralServiceCemeteryPlot]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        dataKey: DataKey.FS_CEMETERY_PLOT,
        isClearable: true,
        sizeCss: "large",
        availableOn: [
            {
                attributeType: EventAttribute.attendedFuneralServiceTypeOfService,
                value: ["3", "5"]
            },
            {
                attributeType: EventAttribute.funeralServiceTypeOfService,
                value: ["2"]
            }
        ],
    },
    [EventAttribute.funeralServiceCemeteryPlotType]: {
        fieldType: FieldType.TEXT,
        sizeCss: "large",
        availableOn: [
            {
                attributeType: EventAttribute.attendedFuneralServiceTypeOfService,
                value: ["3", "5"]
            },
            {
                attributeType: EventAttribute.funeralServiceTypeOfService,
                value: ["2"]
            }
        ]
    },
    [EventAttribute.funeralServiceCemeteryPlotNumber]: {
        fieldType: FieldType.TEXT,
        sizeCss: "medium",
        availableOn: [
            {
                attributeType: EventAttribute.attendedFuneralServiceTypeOfService,
                value: ["3", "5"]
            },
            {
                attributeType: EventAttribute.funeralServiceTypeOfService,
                value: ["2"]
            }
        ]
    },
    [EventAttribute.funeralServiceCelebrantAttendance]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        dataKey: DataKey.FS_CELEBRANT_ATTENDANCE,
        sizeCss: "large"
    },
    [EventAttribute.funeralServiceCelebrantName]: {
        fieldType: FieldType.TEXT,
        sizeCss: "large",
        availableOn: [
            {
                attributeType: EventAttribute.funeralServiceCelebrantAttendance,
                value: ["1"]
            }
        ]
    },
    [EventAttribute.funeralServiceCelebrantPhoneNumber]: {
        fieldType: FieldType.TEXT,
        sizeCss: "medium",
        availableOn: [
            {
                attributeType: EventAttribute.funeralServiceCelebrantAttendance,
                value: ["1"]
            }
        ]
    },
    [EventAttribute.funeralServiceCelebrantEmailAddress]: {
        fieldType: FieldType.TEXT,
        sizeCss: "large",
        availableOn: [
            {
                attributeType: EventAttribute.funeralServiceCelebrantAttendance,
                value: ["1"]
            }
        ]
    },
    [EventAttribute.funeralServiceCelebrantContact]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        isClearable: true,
        sizeCss: "large",
        availableOn: [
            {
                attributeType: EventAttribute.funeralServiceCelebrantAttendance,
                value: ["1"]
            }
        ]
    },
    [EventAttribute.funeralServiceNumberOfBearersRequired]: {
        fieldType: FieldType.NUMBER,
        sizeCss: "small"
    },
    [EventAttribute.funeralServiceNumberOfFamilyBearers]: {
        fieldType: FieldType.NUMBER,
        sizeCss: "small"
    },
    [EventAttribute.funeralServiceTrolley]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        dataKey: DataKey.FS_TROLLEY,
        sizeCss: "large"
    },
    [EventAttribute.funeralServiceOrderOfService]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        dataKey: DataKey.FS_ORDER_OF_SERVICE,
        sizeCss: "large"
    },
    [EventAttribute.funeralServiceMusic]: {
        fieldType: FieldType.TEXTAREA_WITH_COPY,
        sizeCss: "large--textarea",
        placeholder: Utility.getLang().cases.orderTabContent.funeralServiceMusicPlaceholder
    },
    [EventAttribute.funeralServiceLimousine]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        dataKey: DataKey.FS_LIMOUSINE,
        sizeCss: "small"
    },
    [EventAttribute.funeralServiceLimousineHearseViaHouse]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        sizeCss: "small"
    },
    [EventAttribute.funeralServiceLimousinePickUpAddress]: {
        fieldType: FieldType.TEXTAREA,
        sizeCss: "large",
        availableOn: [
            {
                attributeType: EventAttribute.funeralServiceLimousine,
                value: ["1"]
            },
            {
                attributeType: EventAttribute.funeralServiceLimousineHearseViaHouse,
                value: ["1"]
            }
        ]
    },
    [EventAttribute.funeralServiceLimousinePickUpTime]: {
        fieldType: FieldType.TIME,
        availableOn: [
            {
                attributeType: EventAttribute.funeralServiceLimousine,
                value: ["1"]
            },
            {
                attributeType: EventAttribute.funeralServiceLimousineHearseViaHouse,
                value: ["1"]
            }
        ],
        sizeCss: "small"
    },
    [EventAttribute.funeralServiceLimousineDropOffAddress]: {
        fieldType: FieldType.TEXTAREA,
        sizeCss: "large",
        availableOn: [
            {
                attributeType: EventAttribute.funeralServiceLimousine,
                value: ["1"]
            }
        ]
    },
    [EventAttribute.funeralServiceChurchServiceLocation]: {
        fieldType: FieldType.ASYNC_SELECT,
        data: [],
        dataKey: DataKey.LOCATIONS,
        locationId: LocationTypes.chapel,
        isSearchable: true,
        isClearable: true,
        sizeCss: "large",
        availableOn: [
            {
                attributeType: EventAttribute.attendedFuneralServiceTypeOfService,
                value: ["3", "4"]
            }
        ]
    },
    [EventAttribute.funeralServiceChurchServiceDate]: {
        fieldType: FieldType.DATE,
        availableOn: [
            {
                attributeType: EventAttribute.attendedFuneralServiceTypeOfService,
                value: ["3", "4"]
            }
        ],
        sizeCss: "small"
    },
    [EventAttribute.funeralServiceChurchServiceTime]: {
        fieldType: FieldType.TIME,
        availableOn: [
            {
                attributeType: EventAttribute.attendedFuneralServiceTypeOfService,
                value: ["3", "4"]
            }
        ],
        sizeCss: "small"
    },
    [EventAttribute.funeralServiceChurchServiceContact]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        isClearable: true,
        sizeCss: "large",
        availableOn: [
            {
                attributeType: EventAttribute.attendedFuneralServiceTypeOfService,
                value: ["3", "4"]
            }
        ]
    },
    [EventAttribute.funeralServiceWitnessedCharging]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        dataKey: DataKey.FS_WITNESSED_CHARGING,
        isClearable: true,
        sizeCss: "small",
        availableOn: [
            {
                attributeType: EventAttribute.attendedFuneralServiceTypeOfService,
                value: ["1", "2", "4"]
            }
        ]
    },
    [EventAttribute.funeralServiceDateForBurial]: {
        fieldType: FieldType.DATE,
        availableOn: [
            {
                attributeType: EventAttribute.attendedFuneralServiceTypeOfService,
                value: ["3", "5"]
            },
            {
                attributeType: EventAttribute.funeralServiceTypeOfService,
                value: ["2"],
            }
        ],
        sizeCss: "small"
    },
    [EventAttribute.funeralServiceTimeForBurial]: {
        fieldType: FieldType.TIME,
        availableOn: [
            {
                attributeType: EventAttribute.attendedFuneralServiceTypeOfService,
                value: ["3", "5"]
            }
        ],
        sizeCss: "small"
    },
    [EventAttribute.funeralServiceDeceasedBoroughResidency]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        dataKey: DataKey.FS_DECEASED_BOROUGH_RESIDENCY,
        isClearable: true,
        sizeCss: "small",
        availableOn: [
            {
                attributeType: EventAttribute.attendedFuneralServiceTypeOfService,
                value: ["3", "5"]
            }
        ]
    },
    [EventAttribute.funeralServicePurchaserBoroughResidency]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        dataKey: DataKey.FS_PURCHASER_BOROUGH_RESIDENCY,
        isClearable: true,
        sizeCss: "small",
        availableOn: [
            {
                attributeType: EventAttribute.attendedFuneralServiceTypeOfService,
                value: ["3", "5"]
            }
        ]
    },
    [EventAttribute.funeralServiceGravemarkerInscription]: {
        fieldType: FieldType.TEXTAREA,
        sizeCss: "large",
        availableOn: [
            {
                attributeType: EventAttribute.attendedFuneralServiceTypeOfService,
                value: ["3", "5"],

            },
            {
                attributeType: EventAttribute.funeralServiceTypeOfService,
                value: ["2"],
            }
        ]
    },
    [EventAttribute.funeralServiceWitnessedBackfill]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        isClearable: true,
        sizeCss: "small",
        availableOn: [
            {
                attributeType: EventAttribute.attendedFuneralServiceTypeOfService,
                value: ["3", "5"],
            }
        ]
    },
    [EventAttribute.postFuneralAshesDisposalTypeForCrem1]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        dataKey: DataKey.PF_ASHES_DISPOSAL_TYPE_FOR_CREM1,
        availableOn: [
            {
                attributeType: EventAttribute.attendedFuneralServiceTypeOfService,
                value: ["1", "2", "4"]
            },
            {
                attributeType: EventAttribute.funeralServiceTypeOfService,
                value: ["1", "3"]
            }
        ],
        sizeCss: "small"
    },
    [EventAttribute.attendedPostFuneralAshesDisposalTypeInternal]: {
        fieldType: FieldType.CREATE_SELECT,
        data: [],
        dataKey: DataKey.ATTENDED_PF_ASHES_DISPOSAL_TYPE_INTERNAL,
        isClearable: true,
        availableOn: [
            {
                attributeType: EventAttribute.attendedFuneralServiceTypeOfService,
                value: ["1", "2", "4"]
            }
        ],
        sizeCss: "small"
    },
    [EventAttribute.postFuneralAshesDisposalTypeInternal]: {
        fieldType: FieldType.CREATE_SELECT,
        data: [],
        dataKey: DataKey.ASHES,
        isClearable: true,
        availableOn: [
            {
                attributeType: EventAttribute.funeralServiceTypeOfService,
                value: ["1", "3"]
            }
        ],
        sizeCss: "small"
    },
    [EventAttribute.postFuneralAshesInstructionsForCrem1]: {
        fieldType: FieldType.TEXTAREA,
        sizeCss: "large",
        availableOn: [
            {
                attributeType: EventAttribute.attendedFuneralServiceTypeOfService,
                value: ["1", "2", "4"]
            },
            {
                attributeType: EventAttribute.funeralServiceTypeOfService,
                value: ["1", "3"]
            }
        ]
    },
    [EventAttribute.postFuneralOverseasCertificateAndSealingRequired]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        dataKey: DataKey.PF_OVERSEAS_CERTIFICATE_AND_SEALING_REQUIRED,
        isClearable: true,
        availableOn: [
            {
                attributeType: EventAttribute.attendedFuneralServiceTypeOfService,
                value: ["1", "2", "4"]
            },
            {
                attributeType: EventAttribute.funeralServiceTypeOfService,
                value: ["1", "3"]
            }
        ],
        sizeCss: "large"
    },
    [EventAttribute.postFuneralAlternativeUrnRequired]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        isClearable: true,
        availableOn: [
            {
                attributeType: EventAttribute.attendedFuneralServiceTypeOfService,
                value: ["1", "2", "4"]
            },
            {
                attributeType: EventAttribute.funeralServiceTypeOfService,
                value: ["1", "3"]
            }
        ],
        sizeCss: "small"
    },
    [EventAttribute.postFuneralAlternativeUrnOrderedBy]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        isClearable: true,
        sizeCss: "small",
        availableOn: [
            {
                attributeType: EventAttribute.attendedFuneralServiceTypeOfService,
                value: ["1", "2", "4"]
            },
            {
                attributeType: EventAttribute.funeralServiceTypeOfService,
                value: ["1", "3"]
            }
        ]
    },
    [EventAttribute.postFuneralAshesDateOfDelivery]: {
        fieldType: FieldType.DATE,
        availableOn: [
            {
                attributeType: EventAttribute.attendedPostFuneralAshesDisposalTypeInternal,
                value: ["1"]
            },
            {
                attributeType: EventAttribute.postFuneralAshesDisposalTypeInternal,
                value: ["1"]
            }
        ],
        sizeCss: "small"
    },
    [EventAttribute.postFuneralAshesTimeOfDelivery]: {
        fieldType: FieldType.TIME,
        availableOn: [
            {
                attributeType: EventAttribute.attendedPostFuneralAshesDisposalTypeInternal,
                value: ["1"]
            },
            {
                attributeType: EventAttribute.postFuneralAshesDisposalTypeInternal,
                value: ["1"]
            }
        ],
        sizeCss: "small"
    },
    [EventAttribute.postFuneralIntermentLocation]: {
        fieldType: FieldType.ASYNC_SELECT,
        data: [],
        dataKey: DataKey.LOCATIONS,
        locationId: LocationTypes.graveyards,
        isSearchable: true,
        isClearable: true,
        availableOn: [
            {
                attributeType: EventAttribute.attendedPostFuneralAshesDisposalTypeInternal,
                value: ["4"]
            }
        ],
        sizeCss: "large"
    },
    [EventAttribute.postFuneralDateForInterment]: {
        fieldType: FieldType.DATE,
        availableOn: [
            {
                attributeType: EventAttribute.attendedPostFuneralAshesDisposalTypeInternal,
                value: ["4"]
            }
        ],
        sizeCss: "small"
    },
    [EventAttribute.postFuneralTimeForInterment]: {
        fieldType: FieldType.TIME,
        availableOn: [
            {
                attributeType: EventAttribute.attendedPostFuneralAshesDisposalTypeInternal,
                value: ["4"]
            }
        ],
        sizeCss: "small"
    },
    [EventAttribute.postFuneralIntermentPlotDetails]: {
        fieldType: FieldType.TEXTAREA,
        sizeCss: "large",
        availableOn: [
            {
                attributeType: EventAttribute.attendedPostFuneralAshesDisposalTypeInternal,
                value: ["4"]
            }
        ]
    },
    [EventAttribute.postFuneralFDAttendanceAtInterment]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        dataKey: DataKey.PF_FD_ATTENDANCE_AT_INTERMENT,
        isClearable: true,
        availableOn: [
            {
                attributeType: EventAttribute.attendedPostFuneralAshesDisposalTypeInternal,
                value: ["4"]
            }
        ],
        sizeCss: "small"
    },
    [EventAttribute.postFuneralHandoverNotes]: {
        fieldType: FieldType.TEXTAREA,
        sizeCss: "large"
    },
    [EventAttribute.funeralServiceNumberOfMournerExpected]: {
        fieldType: FieldType.NUMBER,
        sizeCss: "large"
    },
    [EventAttribute.repatriationServiceType]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        sizeCss: "large"
    },
    [EventAttribute.repatriationFuneralPartner]: {
        fieldType: FieldType.TEXT,
        sizeCss: "large"
    },
    [EventAttribute.repatriationDeceasedBroughtIntoCareDate]: {
        fieldType: FieldType.DATE,
        sizeCss: "large",
        availableOn: [
            {
                attributeType: EventAttribute.repatriationServiceType,
                value: ["1"]
            }
        ]
    },
    [EventAttribute.repatriationViewing]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        sizeCss: "small",
        availableOn: [
            {
                attributeType: EventAttribute.repatriationServiceType,
                value: ["1"]
            }
        ]

    },
    [EventAttribute.repatriationViewingDate]: {
        fieldType: FieldType.DATE,
        sizeCss: "small",
        availableOn: [
            {
                attributeType: EventAttribute.repatriationViewing,
                value: ["1"]
            }
        ]
    },
    [EventAttribute.repatriationViewingTiming]: {
        fieldType: FieldType.TIME,
        sizeCss: "small",
        availableOn: [
            {
                attributeType: EventAttribute.repatriationViewing,
                value: ["1"]
            }
        ]
    },
    [EventAttribute.repatriationViewingLocation]: {
        fieldType: FieldType.ASYNC_SELECT,
        data: [],
        dataKey: DataKey.LOCATIONS,
        locationId: LocationTypes.hospital,
        isSearchable: true,
        isClearable: true,
        sizeCss: "large",
        availableOn: [
            {
                attributeType: EventAttribute.repatriationViewing,
                value: ["1"]
            }
        ]
    },
    [EventAttribute.repatriationAttendanceAtViewing]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        sizeCss: "small",
        availableOn: [
            {
                attributeType: EventAttribute.repatriationViewing,
                value: ["1"]
            }
        ]
    },
    [EventAttribute.repatriationAttendanceAtViewing]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        sizeCss: "small",
        availableOn: [
            {
                attributeType: EventAttribute.repatriationViewing,
                value: ["1"]
            }
        ]
    },
    [EventAttribute.repatriationEmbalmingType]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        sizeCss: "small",
        availableOn: [
            {
                attributeType: EventAttribute.repatriationServiceType,
                value: ["1"]
            }
        ]
    },
    [EventAttribute.repatriationDressingType]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        sizeCss: "small",
        availableOn: [
            {
                attributeType: EventAttribute.repatriationServiceType,
                value: ["1"]
            }
        ]
    },
    [EventAttribute.repatriationCoffinUpgrade]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        sizeCss: "small",
        availableOn: [
            {
                attributeType: EventAttribute.repatriationServiceType,
                value: ["1"]
            }
        ]
    },
    [EventAttribute.repatriationFuneralService]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        sizeCss: "small"
    },
    [EventAttribute.repatriationMemorialPage]: {
        fieldType: FieldType.CHECKBOX,
        availableOn: [
            {
                attributeType: EventAttribute.repatriationServiceType,
                value: ["1"]
            }
        ],
        disableIfFuneral: true
    },
    [EventAttribute.repatriationMemorialFund]: {
        fieldType: FieldType.CREATE_SELECT,
        data: [],
        dataKey: DataKey.MEMORIAL_FUNDS,
        categorizedData: true,
        categorizedDataLabel: Utility.getLang().cases.orderTabContent.funeralCaseOrderContent.allMemorialFunds,
        sizeCss: "large",
        availableOn: [
            {
                attributeType: EventAttribute.repatriationServiceType,
                value: ["1"]
            }
        ],
        disableIfFuneral: true
    },
    [EventAttribute.repatriationEmbassyVisitRequired]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        sizeCss: "small"
    },
    [EventAttribute.repatriationTranslationDocumentsRequired]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        sizeCss: "small"
    },
    [EventAttribute.repatriationLegalisationDocumentsRequired]: {
        fieldType: FieldType.REACT_SELECT,
        data: [],
        sizeCss: "small"
    },
    [EventAttribute.repatriationDestinationCity]: {
        fieldType: FieldType.TEXT,
        sizeCss: "large"
    },
    [EventAttribute.repatriationDestinationCountry]: {
        fieldType: FieldType.TEXT,
        sizeCss: "large"
    },
    [EventAttribute.repatriationDestinationAirport]: {
        fieldType: FieldType.TEXT,
        sizeCss: "large"
    },
    [EventAttribute.repatriationRequestedDateFlight]: {
        fieldType: FieldType.DATE,
        sizeCss: "large"
    },
    [EventAttribute.repatriationConfirmedDateFlight]: {
        fieldType: FieldType.DATE,
        sizeCss: "large"
    },
    [EventAttribute.repatriationConfirmedTimeFlight]: {
        fieldType: FieldType.TIME,
        sizeCss: "large"
    },
    [EventAttribute.repatriationAdditionalInformation]: {
        fieldType: FieldType.TEXTAREA,
        sizeCss: "large"
    },
    none: {fieldType: FieldType.NONE},

}
