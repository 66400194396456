import DBTableHead from "../dbTableHead";
import DBTableBody from "../dbTableBody";
import React from "react";
import DbFooter from "../dbFooter";

const DBTable = ({
                     id,
                     dataList,
                     columns,
                     dashboardSubview,
                     pageLimit,
                     onInitialClick,
                     onPreviousClick,
                     onNextClick,
                     navigation,
                     filter,
                     showFullList
                 }) => {
    return <>
        <table className="fnxtable fnxtable--flat">
            <DBTableHead columns={columns}/>
            <DBTableBody dataList={dataList}
                         columns={columns}
                         getSubview={dashboardSubview}
            />
        </table>
        <DbFooter id={id}
                  pageLimit={pageLimit}
                  onInitialClick={onInitialClick}
                  onPreviousClick={onPreviousClick}
                  onNextClick={onNextClick}
                  navigation={navigation}
                  filter={filter}
                  showFullList={showFullList}
        />
    </>
}
export default DBTable;

