import React from "react";
import useDashboard from "./hooks/useDashboard";
import connect from "react-redux/es/connect/connect";
import {NoMoreData} from "../common/searchPanel/noMoreData";
import DashboardList from "./ui/dashboardList";
import useDashboardUI from "./hooks/useDashboardUI";
import {DashboardProps} from "./ui/dbUtil";
import DashboardContainer from "./dashboardContainer";
import {DB_DEFAULT_PAGE_LIMIT, DB_DEFAULT_PAGE_START_INDEX} from "./api/dashboardService";
import {AppSelect, AppTextField} from "../common/utils/useCommonElement";
import {Lang} from "../common/appUtil";
import AccessControl from "../../api/accessControl";

/***
 * REUSABLE COMPONENT
 * @description: Based on 'pageId' = dashboardMainPage | dbDetailPage,
 * dynamically changing redux storing mapper to 'dashboard'|'fullDashboard
 */
const Dashboard = ({
                       children,
                       pageId = DashboardProps.dbMainPage,
                       selectedDashboardType = -1,
                       pageStartIndex = DB_DEFAULT_PAGE_START_INDEX,
                       pageLimit = DB_DEFAULT_PAGE_LIMIT,
                       dashboardCasesMapper,
                       dashboard,
                       users,
                       dispatch
                   }) => {

    const selectedFilter = dashboard?.filter?.[selectedDashboardType];
    const selectedUserId = selectedFilter?.userId;

    const {
        isLoadingDashboardList,
        onInitialClick,
        onPreviousClick,
        onNextClick,
        onSelectionChange,
        ownerDropdownSelectedValue,
        ownerDropdownOptions,
        ashesInstructionOptions,
        ashesInstructionDropdownSelectedValue,
        isAshesReturnDashboardListType,
        debounceOnTextChange
    } = useDashboard(pageId, selectedDashboardType, pageStartIndex, pageLimit, users, selectedUserId, dispatch);

    const {RowPlaceholder} = useDashboardUI();
    /***
     * @description: Object.entries converts key data type to string
     * Thus, key conversion to number (ie: parseInt) is required
     */
    const dashboardCasesEntries = dashboardCasesMapper ? Object.entries(dashboardCasesMapper) : [];
    const hasNoDashboardLists = (isLoadingDashboardList === false) && (dashboardCasesEntries?.length === 0);
    return <DashboardContainer>
        {children}
        {
            (AccessControl.revokedAccess().viewDashboardFilter === false) &&
            <div className={'section__toolbar section__toolbar--filters'}>
                <div className={'inner'}>
                    <div className="btn-toolbar btn-toolbar--head">
                        <div className={`field ${pageId === DashboardProps.dbMainPage ? 'field--large' : ''}`}>
                            <AppSelect id={DashboardProps.owner}
                                       title={Lang().staticData.filter.caseAssignedToUser.text}
                                       options={ownerDropdownOptions}
                                       value={ownerDropdownSelectedValue}
                                       isLoading={isLoadingDashboardList}
                                       onChange={(option) => onSelectionChange(DashboardProps.owner, option, selectedDashboardType, selectedFilter)}
                                       isDisabled={isLoadingDashboardList}
                                       isClearable
                            />
                        </div>
                        {
                            isAshesReturnDashboardListType &&
                            <>
                                <div className={"field"}>
                                    <AppSelect id={DashboardProps.ashesInstruction}
                                               title={Lang().staticData.filter.ashesInstruction.text}
                                               options={ashesInstructionOptions}
                                               value={ashesInstructionDropdownSelectedValue}
                                               isLoading={isLoadingDashboardList}
                                               onChange={(option) => onSelectionChange(DashboardProps.ashesInstruction, option, selectedDashboardType, selectedFilter)}
                                               isDisabled={isLoadingDashboardList}
                                               isClearable
                                    />
                                </div>
                                <div className={"field"}>
                                    <AppTextField id={DashboardProps.fdPartner}
                                                  title={Lang().staticData.filter.fdPartner.text}
                                                  onChange={(e) => debounceOnTextChange(e.target.value, selectedDashboardType, selectedFilter)}
                                                  disabled={false}
                                                  sizeClass={"field--max_width"}
                                                  placeholder={Lang().cases.statusTabContent.typeToSearch}
                                                  inputClass={"multiselect__control"}
                                    />
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        }
        {
            (dashboardCasesEntries?.length > 0)
                ? dashboardCasesEntries.map(([key, dataList]) => {
                    const keyInt = parseInt(key, 10);
                    return <DashboardList key={keyInt}
                                          id={keyInt}
                                          dataList={dataList}
                                          pageLimit={pageLimit}
                                          onInitialClick={onInitialClick}
                                          onPreviousClick={onPreviousClick}
                                          onNextClick={onNextClick}
                                          navigation={dashboard?.navigation?.[key]}
                                          filter={dashboard?.filter?.[key]}
                                          showFullList={pageId === DashboardProps.dbMainPage}
                    />

                })
                : isLoadingDashboardList && <RowPlaceholder/>
        }
        {
            hasNoDashboardLists && <NoMoreData huge/>
        }
    </DashboardContainer>
}

function mapStateToProps(state, ownProps) {
    return {
        dashboard: (ownProps.pageId) && (ownProps.pageId === DashboardProps.dbDetailPage)
            ? state.dashboardReducer?.fullDashboard
            : state.dashboardReducer?.dashboard,
        dashboardCasesMapper: (ownProps.pageId) && (ownProps.pageId === DashboardProps.dbDetailPage)
            ? state.dashboardReducer?.fullDashboard?.cases
            : state.dashboardReducer?.dashboard?.cases,
        users: state.application?.allUserDetailsForCases
    };
}

export default connect(mapStateToProps)(Dashboard);
