import React from 'react';
import {bindActionCreators} from "redux";
import * as actionMaster from "../../../actions/uiAction";
import connect from "react-redux/lib/connect/connect";
import OrderHeaderSection from "./OrderHeaderSection";
import OrderBillingComponent from "./OrderBillingComponent";
import OrderUtil from "../../common/orderUtil";
import * as caseOrderAction from "../../../actions/caseOrderAction";
import * as orderAction from "../../../actions/orderAction";
import * as caseAction from "../../../actions/caseAction";
import OrderEventComponent from "./OrderEventComponent";
import HeaderAndStageSection from "../caseOrderSubComponents/headerAndStageSection";
import $ from "jquery";
import config from "../../../api/config";
import ErrorUtil from "../../common/errorUtil";
import Utility from "../../../api/utilLanguage";
import AppUtil from "../../common/appUtil";
import RefreshToken from "../../../api/validateToken";
import {getApiToken, setOrderIdForMessageFilter} from "../../common/localStorageUtil";
import OrderFieldRules from "./OrderFieldRules";
import OrderTally from "../caseOrderSubComponents/orderTally";
import CaseUtil from "../../common/caseUtil";
import ValidationAndVisibilityRule, {isCurrencyPlacedOnRight} from "../../common/ValidationAndVisibilityRule";
import OrderPackageSection from "./OrderPackageSection";
import Popup from "../../common/popups/popup";
import OrderUpgradePlaceholder from './orderUpgradePlaceholder';
import RouteUtil from "../../common/routeUtil";
import createHistory from "history/createBrowserHistory";
import Enum from "../../common/enum";
import * as messageAction from "../../case/message/redux/messageAction";

/**
 * Each component represents one unique order
 * Sections
 *  1: Order header section
 *      @see OrderHeaderSection
 *  2: Order content section
 *      - Iterate through all events in one order and render.
 *          @see OrderEventComponent
 *  3: Order billing details
 *      @see OrderBillingComponent
 */
class OrderComponent extends React.Component {

    static history = null;

    constructor(props) {
        super(props);
        this.orderIdForCleaning = "";
        this.disableEmailButton = false;
        this.hasCompensationAccess = false;
        this.isCurrencyPlacedOnRight = isCurrencyPlacedOnRight(ValidationAndVisibilityRule.validationRules, ValidationAndVisibilityRule.currencyPlacement);
    }

    state = {
        relativeId: null,
        initialData: [],
        check: true,
        disableEmailButton: false,
        allDresscodeTypes: [],
        disablecompensationAccess: false,
        disableToggle: null,
        visibility: {
            display: this.props.originalData.stage === OrderUtil.orderStages.deleted.value ? OrderUtil.DISPLAY_NONE : '',
            fileName: this.props.originalData.stage === OrderUtil.orderStages.deleted.value ? OrderUtil.COLLAPSE_EXPAND_STATUS : OrderUtil.COLLAPSE_CLOSE_STATUS,
        },
        isLoading: false,
    };

    toggleClicked = (visibilityState) => {
        if (visibilityState === OrderUtil.DISPLAY_NONE) {
            this.setState({
                visibility: {
                    display: OrderUtil.DISPLAY_NONE,
                    fileName: OrderUtil.COLLAPSE_EXPAND_STATUS
                }
            });
        }
        if (visibilityState === '') {
            this.setState({
                visibility: {
                    display: '',
                    fileName: OrderUtil.COLLAPSE_CLOSE_STATUS
                }
            })
        }
    };

    sendEmailSummary = (e) => {
        console.log("Order relativeId", this.state.relativeId, this.orderIdForCleaning);
        // Sending the email
        this.props.actionMaster.showPopupOnSend(this.props.order, this.props.functionSource);
        this.props.changeEmailTypeToSend('order');
        e.preventDefault();
    };

    onDelete = () => {
        this.props.caseOrderAction.RemoveOrder(this.props.deletedOrderData);
        this.hidePopup();
    };

    deleteOrder = (orderType, index, id) => {
        this.props.caseOrderAction.confirmationPopupForDelete(orderType, index, id);
    };

    hidePopup = () => {
        this.props.actionMaster.hidePopupSection();
    };

    UNSAFE_componentWillMount() {
        this.props.caseOrderAction.getProductCategoryListForAnonymousEvent(this.props.functionSource, this.props.order.orderType);
        this.props.caseOrderAction.getProductTotalprice(this.props.order.orderType, this.props.orderIndex, this.props.functionSource);

        if (this.props.order.case !== undefined) {
            let deceasedName = this.props.order.case.deceasedName;
            let personNumber = this.props.order.case.personNumber;
            let nameToShow = this.props.order.case.id;
            if (deceasedName !== null) {
                nameToShow += " " + deceasedName
            }
            if (personNumber !== null) {
                nameToShow += " - " + personNumber
            }
            let temp = [];
            temp.push({
                id: this.props.order.case.id,
                deceasedName: nameToShow
            });
            this.setState({
                initialData: temp,
            })
        }
    }

    checkOrderChange = () => {
        this.disableEmailButton = OrderUtil.checkChangeInOrder(this.props.data, this.props.originalData);
    };

    setBillingContact = (e) => {
        this.props.caseOrderAction.changeBillingStatusForCaseOrder(e, this.props.order.orderType, this.props.orderIndex)
    };

    changeBillingStatusForOrder = (e) => {
        this.props.orderAction.changeBillingStatusForOrderInfo(e);
    };

    changeStageForOrder = (e) => {
        this.props.orderAction.changeStageForOrderInfo(e, this.props.functionSource, this.props.orderIndex)
    };

    changeOrderInvoiceStatus = (salesOrder, status) => {
        this.props.caseOrderAction.setOrderBillingStatus(salesOrder, status);
    };

    changeTimeForOrderInvoice = (e, salesId) => {
        this.props.caseOrderAction.setTimePeriodForOrderInvoice(e, salesId);
    };

    setCustomDateForOrderBilling = (e, salesOrder) => {
        this.props.caseOrderAction.setCustomTimePeriodForOrderInvoice(e, salesOrder);
    };

    promiseOptionForOrderOnly = inputValue =>
        new Promise(resolve => {
            if (AppUtil.jwtTokenIsExpired()) {
                RefreshToken.validateRefreshToken().then(newData => {
                    this.props.actionMaster.afterRefreshToken(newData);
                    setTimeout(() => {
                        resolve(this.loadOptionsForCase(newData.idToken.jwtToken, inputValue));
                    }, 1000);
                })
            } else {
                setTimeout(() => {
                    console.log("ELSE", inputValue);
                    resolve(this.loadOptionsForCase(getApiToken(), inputValue));
                }, 1000);
            }
        });

    handleItemSelectChangeForCases = (data) => {
        console.log("data", data);
        let temp = [];
        temp.push({
            id: data.id,
            deceasedName: data.deceasedName
        });
        this.setState({
            initialData: temp
        });
        this.props.orderAction.setCaseIdForOrderTabOnly(data);
    };

    loadOptionsForCase = async (token, inputValue) => {
        console.log("loadOption", inputValue);
        let temp = [];
        if (inputValue) {
            await this.loadCasesData(token, inputValue).then(data =>
                temp = data.object.cases
            );
            if (temp.length > 0) {
                for (let n = 0; n < temp.length; n++) {
                    let deceasedName = temp[n].deceasedName;
                    let personNumber = temp[n].personNumber;
                    temp[n].deceasedName = temp[n].id;
                    if (deceasedName !== null) {
                        temp[n].deceasedName += " " + deceasedName
                    }
                    if (personNumber !== null) {
                        temp[n].deceasedName += " - " + personNumber
                    }
                }
            }
            this.setState({
                resultData: temp
            });
            return this.state.resultData;
        } else {
            return [];
        }
    };

    loadCasesData = (token, inputValue) => {
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: config.baseURL + `/api/v1/case/list/simple?f_tx=${inputValue}`,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    };

    handleItemSelectChangeForCases = (data) => {
        console.log("data", data);
        let temp = [];
        temp.push({
            id: data.id,
            deceasedName: data.deceasedName
        });
        this.setState({
            initialData: temp
        });
        this.props.orderAction.setCaseIdForOrderTabOnly(data);
    };

    setCeremonyInstruction = (e) => {
        this.props.orderAction.changeCeremonyInstructionForOrder(e.target.value, this.props.functionSource, this.props.orderIndex)
    };

    checkCompensationAccess = (e) => {
        let hasCompensationAccess = false;
        hasCompensationAccess = this.props.selectedCaseData.assigned ? CaseUtil.hasCompensationAccess(this.props.selectedCaseData.assigned)
            : this.props.order.case.hasCompensationAccess;
        return hasCompensationAccess;
    }

    handleChangeForlabel = (selectedOption) => {
        this.props.orderAction.addLabelToStateForOrder(selectedOption)
    };

    removeLabel = (labels, i) => {
        this.props.orderAction.removelabelFormOrder(labels, i)
    };

    upgradeFuneralPopup = (orderID) => {
        if (this.props.hasCardInfoChanged) {
            this.props.actionMaster.askBeforeUpgrade();
        } else {
            this.setState({isLoading: true});
            this.props.caseOrderAction.getProductCategoryListForAnonymousEvent(this.props.functionSource);
            this.props.orderAction.upgradeFuneralPackage(orderID, this.props.order.orderType, this.props.orderIndex, this.props.functionSource);
            setTimeout(() => {
                this.setState({isLoading: false});
            }, 1500);

        }
    }

    goToUpgradePackage = (orderID) => {
        this.setState({isLoading: true});
        this.props.actionMaster.closeUpgradePopup();
        this.props.orderAction.upgradeFuneralPackage(orderID, this.props.order.orderType, this.props.orderIndex, this.props.functionSource);
        setTimeout(() => {
            this.setState({isLoading: false});
        }, 1500);
    }

    chooseMessageTab = (orderID) => {
        setOrderIdForMessageFilter(orderID);
        const suffixPathUrl = RouteUtil.getRoutePathFromChildComponent(Enum.CaseMessageComponent);
        const newCard = this.props.selectedCaseData;
        this.props.messageAction.messageGlobalCleanup(orderID);
        const messageTabUrl = `${AppUtil.linkPaths.case.basePath}${RouteUtil.routeCardId(newCard.id)}${suffixPathUrl}`;
        this.props.caseAction.setManuallySelectedChildComponent(Enum.CaseMessageComponent);
        if (OrderComponent.history === null) {
            OrderComponent.history = createHistory();
        }
        OrderComponent.history.push(messageTabUrl);
    }

    currentRoute = () => {
        this.props.actionMaster.closePopupWithSameRoute();
    }

    render() {
        this.hasCompensationAccess = this.checkCompensationAccess();
        return (
            <React.Fragment>
                {this.props.order.id !== OrderUtil.ORDER_INITIAL_VALUE ?
                    <Popup openModal={this.props.startUpgradePopup}
                           headerInfo={Utility.getLang().infoMessages.popup.unsavedChanges}
                           onConfirmation={() => this.goToUpgradePackage(this.props.order.id)}
                           closePopup={this.currentRoute}/> : ''}
                <div className="card">
                    {
                        this.props.functionSource === OrderUtil.ORDER_DEFAULT_SOURCE ?
                            <OrderHeaderSection
                                order={this.props.order}
                                orderOriginalStage={this.props.originalData.stage}
                                orderStatusData={this.props.orderStatus}
                                changeStageForOrder={this.changeStageForOrder}
                                toggleClicked={this.toggleClicked}
                                parentState={this.state}
                                chooseMessageTab={this.chooseMessageTab}
                            /> : null
                    }
                    {this.state.isLoading ? <OrderUpgradePlaceholder/> :
                        <>
                            {this.props.functionSource === OrderUtil.ORDER_DEFAULT_SOURCE && this.props.order.packageType !== OrderUtil.ORDER_INITIAL_VALUE ?
                                <OrderPackageSection loaderStatus={this.state.isLoading}
                                                     orderInfo={this.props.order}
                                                     upgradeFuneralPopup={this.upgradeFuneralPopup}
                                                     inputChangeStatus={this.props.hasCardInfoChanged}
                                                     orderStage={OrderUtil.lockProducts(this.props.order.stage)}/> : null}
                            <div className="section__content section__content--order_specification"
                                 style={{display: this.state.visibility.display}}>
                                <div className="inner">
                                    <form onSubmit={e => {
                                        e.preventDefault();
                                    }}>
                                        {
                                            this.props.functionSource !== OrderUtil.ORDER_DEFAULT_SOURCE ?
                                                <HeaderAndStageSection
                                                    initialCaseData={this.state.initialData}
                                                    salesOrders={this.props.order.salesOrders !== undefined && this.props.order.salesOrders !== null ? this.props.order.salesOrders : null}
                                                    orderStage={this.props.order.stage}
                                                    orderOriginalStage={this.props.originalData.stage}
                                                    promiseOptionForOrderOnly={this.promiseOptionForOrderOnly}
                                                    caseChange={this.handleItemSelectChangeForCases}
                                                    billingData={this.props.order.case !== undefined && this.props.order.case.relatives !== undefined ? this.props.order.case.relatives : null}
                                                    selectedBillingContact={this.props.order.billingContactId}
                                                    changeDropdownItemInfo={this.changeBillingStatusForOrder}
                                                    orderStatusData={this.props.orderStatus}
                                                    changeStageForOrder={this.changeStageForOrder}
                                                    selectedStage={this.props.order.stage}
                                                    labelsData={this.props.order.labels}
                                                    labelsOptions={this.props.caseAllLabels}
                                                    handleChangeForlabel={this.handleChangeForlabel}
                                                    removeLabel={this.removeLabel}
                                                    changeInvoiceStatus={this.changeOrderInvoiceStatus}
                                                    setTimePeriod={this.changeTimeForOrderInvoice}
                                                    dateChange={this.setCustomDateForOrderBilling}
                                                    orderType={this.props.order.orderTypeText}
                                                    npsData={this.props.order.npsData}
                                                    functionSource={this.props.functionSource}
                                                    disableOrderStage={this.props.order.disableOrderStage}
                                                />
                                                : null
                                        }
                                        <div className="table__wrapper">
                                            <table
                                                className={`fnxtable fnxtable--order ${OrderUtil.lockEventAttributes(this.props.order.stage) ? 'is-locked' : ''}`}>
                                                <tbody>
                                                {
                                                    this.props.order.events.map(event => (
                                                        <OrderEventComponent key={event.eventType}
                                                                             event={event}
                                                                             order={this.props.order}
                                                                             orderIndex={this.props.orderIndex}
                                                                             functionSource={this.props.functionSource}

                                                        />
                                                    ))
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                        <OrderTally totalDetails={this.props.order.totalDetails}
                                                    compensationAccess={this.hasCompensationAccess}
                                                    isCurrencyPlacedOnRight={this.isCurrencyPlacedOnRight}/>

                                        {OrderFieldRules.isService(this.props.order.orderType) ?
                                            <div className="form-group">
                                                <label htmlFor="locationInstructions">
                                                    {Utility.getLang().common.instructions}
                                                </label>
                                                <textarea
                                                    className="textarea textarea--max_width"
                                                    placeholder={Utility.getLang().common.writeInstructions}
                                                    autoComplete="off"
                                                    data-lpignore="true"
                                                    defaultValue={AppUtil.isAvailable(this.props.order.ceremonyInstructions) ? this.props.order.ceremonyInstructions : ""}
                                                    onBlur={this.setCeremonyInstruction}
                                                />
                                                <p className="help_text">{Utility.getLang().common.instructionsHelpText}</p>
                                            </div>
                                            : null
                                        }
                                        <OrderBillingComponent {...this.props}
                                                               checkOrderChange={this.checkOrderChange}
                                                               deleteOrder={this.deleteOrder}
                                                               onDelete={this.onDelete}
                                                               hidePopup={this.hidePopup}
                                                               changeDropdownItemForBillingContact={this.setBillingContact}
                                                               sendEmailSummary={this.sendEmailSummary}
                                                               functionSource={this.props.functionSource}
                                                               order={this.props.order}
                                                               oldOrder={this.props.originalData}
                                        />
                                    </form>
                                </div>
                            </div>
                        </>}
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        selectedCaseData: state.application.selectedCaseData,
        selectedDataById: state.application.selectedDataById,
        orderStatus: state.application.orderStatus,
        deleteOrderPopup: state.application.deleteOrderPopup,
        deletedOrderData: state.application.deletedOrderData,
        openFuneralPopup: state.application.openFuneralPopup,
        showUnsavedPopup: state.application.showUnsavedPopup,//TODO: unused, check and remove
        startUpgradePopup: state.application.startUpgradePopup,
        caseAllLabels: state.application.caseAllLabels,
        selectedOrderData: state.application.selectedOrderData,
        initialValues: state.application.selectedCaseData,
        openOnSend: state.application.openOnSend,
        allCityInfo: state.application.allCityInfo,
        allUserDetailsForCases: state.application.allUserDetailsForCases,
        allCeremonyTypes: state.application.allCeremonyTypes,
        allRelationshipInfo: state.application.allRelationshipInfo,
        productData: state.application.productData,
        headerMenuItemClicked: state.application.headerMenuItemClicked,
        categoryItemsinfo: state.application.categoryItemsinfo,
        categoryAccToEvent1: state.application.categoryAccToEvent1,
        categoryAccToEvent2: state.application.categoryAccToEvent2,
        categoryAccToEvent4: state.application.categoryAccToEvent4,
        categoryAccToEvent8: state.application.categoryAccToEvent8,
        allBurialTypes: state.application.allBurialTypes,
        caseRelativesWithEmailId: state.application.caseRelativesWithEmailId,
        allDresscodeTypes: state.application.allDresscodeTypes,
        hasCardInfoChanged: state.application.hasCardInfoChanged
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        caseOrderAction: bindActionCreators(caseOrderAction, dispatch),
        orderAction: bindActionCreators(orderAction, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch),
        messageAction: bindActionCreators(messageAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderComponent);
