import PropTypes from "prop-types";
import DocumentItemContent from "../../case/documents/documentListingTable/documentItemContent";
import React from "react";
import Permalink from "./permalink";

export default class PermalinkWithImage extends Permalink {

    static propTypes = {
        imgFileName: PropTypes.string.isRequired,
        size: PropTypes.number
    };

    doNothing = (e) => {
        e.stopPropagation();
    };

    render() {
        return (
            (this.props.id !== undefined && this.props.id !== null && this.props.id !== 0) &&
            <a
                href={this.props.path1 + this.props.id + this.props.path2}
                onClick={this.doNothing}
                target="_blank"
                className="tag tag--order tag--large tag--button"
            >
                <DocumentItemContent
                    imgFileName={this.props.imgFileName}
                    description={this.props.title}
                    size={this.props.size}
                />
            </a>
        );
    }
}
