import React from "react";
import connect from "react-redux/es/connect/connect";
import ReactPlayer from "react-player";
import moment from "moment";
import Duration from "./duration";
import Utility from "../../api/utilLanguage";
import Button from "./button";

class SoundPlayer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            url: props.url,
            playing: false,
            played: 0,
            loaded: 0,
            duration: 0,
            playbackRate: 1.25,
            playbackRateLabel: Utility.getLang().common.speed125,
            playbackRateIcon: "speed-125"
        };
    }

    playPause = (e) => {
        e.stopPropagation();
        this.setState({playing: !this.state.playing})
    };

    stop = (e) => {
        e.stopPropagation();
        this.setState({url: null, playing: false})
    };

    stopPropagation = (e) => {
        e.stopPropagation();
    };

    togglePlaybackRate = () => {
        switch (this.state.playbackRate) {
            case 1.25:
                this.setState({
                    playbackRate: 1.5,
                    playbackRateLabel: Utility.getLang().common.speed150,
                    playbackRateIcon: "speed-150"
                });
                break;
            case 1.5:
                this.setState({
                    playbackRate: 1.0,
                    playbackRateLabel: Utility.getLang().common.speed100,
                    playbackRateIcon: "speed-100"
                });
                break;
            default:
                this.setState({
                    playbackRate: 1.25,
                    playbackRateLabel: Utility.getLang().common.speed125,
                    playbackRateIcon: "speed-125"
                })
        }
    };

    onPlay = () => {
        this.setState({playing: true})
    };

    onPause = () => {
        this.setState({playing: false})
    };

    onSeekMouseDown = () => {
        this.setState({seeking: true})
    };

    onSeekChange = e => {
        this.setState({played: parseFloat(e.target.value)})
    };

    onSeekMouseUp = e => {
        this.setState({seeking: false});
        this.player.seekTo(parseFloat(e.target.value))
    };

    onProgress = state => {
        // We only want to update time slider if we are not currently seeking
        if (!this.state.seeking) {
            this.setState(state)
        }
    };

    onEnded = () => {
        this.setState({playing: false})
    };

    onDuration = (duration) => {
        this.setState({duration})
    };

    ref = player => {
        this.player = player
    };

    UNSAFE_componentWillMount = () => {
        if (this.props.url === undefined) {
            this.setState({
                url: this.props.url
            })
        }
    };

    static formatTime(date) {
        let eventTime = new Date(date).getTime();
        let currentTime = Date.now();
        let diffTime = currentTime - eventTime;
        let duration = moment.duration(diffTime, 'milliseconds');
        duration = moment.duration(duration + 1000, 'milliseconds');
        //Support Utc format
        const time = moment.utc(duration.asMilliseconds()).format("mm:ss");
        return time;
    }

    render = () => {
        const {url, playing, played, duration, playbackRate} = this.state;
        return (
            <React.Fragment>
                {this.state.url !== null ?
                    <div
                        className={`player ${playing ? 'is-playing' : 'is-paused'} ${this.props.mode === "small" ? 'is-small' : 'is-full'}`}>
                        <div className='player-wrapper' style={{display: 'none'}}>
                            <ReactPlayer
                                ref={this.ref}
                                className='react-player'
                                width='100%'
                                height='100%'
                                url={url}
                                pip={false}
                                playing={playing}
                                controls={false}
                                light={false}
                                loop={false}
                                playbackRate={playbackRate}
                                volume={0.8}
                                muted={false}
                                onReady={() => null}
                                onStart={() => null}
                                onPlay={this.onPlay}
                                onEnablePIP={() => null}
                                onDisablePIP={() => null}
                                onPause={this.onPause}
                                onBuffer={() => null}
                                onSeek={() => null}
                                onEnded={this.onEnded}
                                onError={e => console.log('onError', e)}
                                onProgress={this.onProgress}
                                onDuration={this.onDuration}
                            />
                        </div>
                        {
                            {
                                'full':
                                    <div className="btn-toolbar btn-toolbar--player btn-toolbar--actions">
                                        <Button
                                            onClick={this.playPause}
                                            noLabel
                                            tooltip={playing ? Utility.getLang().common.pause : Utility.getLang().common.play}
                                            tooltipAlignLeft
                                            icon={playing ? 'pause' : 'play'}
                                        />
                                        <div className="btn-toolbar__field btn-toolbar__field--sound_player">
                                            <Duration
                                                seconds={duration * played}
                                                className="time time--elapsed"
                                            />
                                            <div className="timeline">
                                                <input
                                                    type='range' min={0} max={1} step='any'
                                                    value={played}
                                                    onMouseDown={this.onSeekMouseDown}
                                                    onChange={this.onSeekChange}
                                                    onMouseUp={this.onSeekMouseUp}
                                                    id="timelineRange"
                                                    name="timelineRange"
                                                />
                                            </div>
                                            <Duration
                                                seconds={duration}
                                                className="time time--total"
                                            />
                                        </div>
                                        <Button
                                            onClick={this.togglePlaybackRate}
                                            noLabel
                                            icon={this.state.playbackRateIcon}
                                            tooltip={this.state.playbackRateLabel}
                                        />
                                    </div>,
                                'small':
                                    <div className="item__audio_player">
                                        <Button
                                            onClick={this.playPause}
                                            tooltip={playing ? Utility.getLang().common.pause : Utility.getLang().common.play}
                                            icon={playing ? 'pause' : 'play'}
                                            isTiny
                                        >
                                            {played > 0 ? <Duration seconds={duration * played}/> :
                                                <Duration seconds={duration}/>}
                                        </Button>
                                        <div className="timeline">
                                            <input
                                                type='range' min={0} max={1} step='any'
                                                value={played}
                                                onClick={this.stopPropagation}
                                                onMouseDown={this.onSeekMouseDown}
                                                onChange={this.onSeekChange}
                                                onMouseUp={this.onSeekMouseUp}
                                                id="timelineRange"
                                                name="timelineRangeItem"
                                            />
                                        </div>
                                    </div>
                            }[this.props.mode]
                        }
                    </div>
                    : null}
            </React.Fragment>
        )
    }
}

SoundPlayer.propTypes = {};

function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps() {
    return {};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SoundPlayer);
