// probate Meeting Tab
import AppUtil, {Lang} from "../../../common/appUtil";
import {useCallback, useMemo, useState} from "react";
import {ProbateProps} from "../ui/tabProbateMeeting/util";
import {loadLoadingView} from "../../../../actions/caseAction";
import {downloadProbateZipDataApi} from "../api";
import ValidationUtil, {ValidationErrors} from "../../../common/validationUtil";
import {showToastMessagesForMapper} from "../../../common/customToastr";
import useDueDateUtil, {MANUAL_DUEDATE_MUST_HAVE_DOCUMENT} from "../ui/tabProbateMeeting/hooks/useDueDateUtil";
import {isNonPdfFormat, isPdfFormat} from "../../documents/documentFilePreview/util";

export const probateMeetingTab = {
    PLACE: 'place',
    WIDOW_STATUS: "widowStatus",
    OWNER_REVIEWED: "ownerReviewed",
    RELATIVE_REVIEWED: "relativeReviewed",
}

function useProbateUtil() {
    const enumType = useMemo(() => {
        return {
            legalStatus: 1,
            attendedEvent: 2,
            information: 3,
            asset: 4,
            debt: 5,
            deduction: 6,
            propertyOwner: 7,

            //Questionnaire specific
            estateOrCondominium: 8,
            commonOptions: 9,
            yesNoOptionsOnly: 10,

            probateMaritalStatus: 11,
        }
    }, []);

    const generalType = useMemo(() => {
        return {
            checkBox: "CheckBox",
            number: "Number",
            text: "text",
        }
    }, []);

    const documentType = useMemo(() => {
        return {
            renounceInheritance: 115,
            calledToEventInvitation: 116,
            information: 117,
            asset: 118,
            debt: 119,
            deduction: 120,
            probateDocument: 121,
            other: 122,
            attendedEvent: 123,
            confirmedCallToEvent: 124,
            probateNote: 125,
            probateDueDate: 126
        }
    }, []);

    const deductionTypeEnum = useMemo(() => {
        return {
            funeralCosts: {
                text: "Funeral costs",
                value: 201
            },
            estateRegistrationCosts: {
                text: "Estate registration costs",
                value: 202
            },
            otherDeductions: {
                text: "Other deductions",
                value: 203
            }
        }
    }, []);

    const legalStatusOptions = useMemo(() => {
        return [
            {id: 1, name: Lang().cases.probates.options.beneficiaries},
            {id: 2, name: Lang().cases.probates.options.successor}]
    }, []);

    const [isDownloadingProbateZip, setIsDownloadingProbateZip] = useState(false);

    const documentTypePropertyMapper = {
        [documentType.renounceInheritance]: {property: ProbateProps.renouncedInheritance_DocumentId},
        [documentType.calledToEventInvitation]: {property: ProbateProps.calledToEvent_DocumentId},
        [documentType.information]: {property: ProbateProps.documentId},
        [documentType.asset]: {
            rootProperty: ProbateProps.assets,
            property: ProbateProps.propertyDocument,
            childProperty: ProbateProps.documentId
        },
        [documentType.debt]: {
            rootProperty: ProbateProps.debts,
            property: ProbateProps.propertyDocument,
            childProperty: ProbateProps.documentId
        },
        [documentType.deduction]: {
            rootProperty: ProbateProps.deductions,
            property: ProbateProps.propertyDocument,
            childProperty: ProbateProps.documentId
        },
        [documentType.attendedEvent]: {property: ProbateProps.attendedEvent_DocumentId},
        [documentType.confirmedCallToEvent]: {property: ProbateProps.confirmedCalledToEvent_DocumentId},
        [documentType.probateDueDate]: {
            rootProperty: ProbateProps.dueDateInfo,
            property: ProbateProps.dueDateDocumentId
        },
        [documentType.probateNote]: {property: ProbateProps.documentId}
    }
    const getMatchFromList = useCallback((id, fromList) => {
        return fromList.find(element => element.id === id);
    }, []);

    /**
     * @description: fetch matching enum.enumOptions
     */
    const getEnumOptions = useCallback((enums, enumTypeToMatch) => {
        const matchedEnum = AppUtil.isAvailable(enums) ? getMatchFromList(enumTypeToMatch, enums) : null;
        return matchedEnum ? matchedEnum.enumOptions : matchedEnum;
    }, []);

    const downloadAssetsAndDebtsZip = (probateId, dispatch) => {
        setIsDownloadingProbateZip(true);
        dispatch(loadLoadingView(AppUtil.loadingStatus.isLoading));

        downloadProbateZipDataApi(probateId).then(resp => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data)) {
                const url = window.URL.createObjectURL(new Blob([resp.data]));
                AppUtil.downloadFromUrl(url, `Probate_${probateId}_documents.zip`);
            }
        }).finally(() => {
            setTimeout(() => {
                dispatch(loadLoadingView(AppUtil.loadingStatus.finishedLoading));
            }, 1000);
            setIsDownloadingProbateZip(false);
        })
    }

    /***
     * @description: Get username from beneficiary.caseRelative
     */
    const userName = (person) => {
        if (person.firstName && person.lastName) {
            return `${person.firstName} ${person.lastName}`;
        } else if (person.firstName) {
            return person.firstName;
        } else if (person.lastName) {
            return person.lastName;
        } else {
            return Lang().common.unknown;
        }
    }

    const isDropDownType = (type) => {
        return (type === enumType.estateOrCondominium.toString())
            || (type === enumType.commonOptions.toString())
            || (type === enumType.yesNoOptionsOnly.toString())
            || (type === enumType.probateMaritalStatus.toString())
    };

    const includeAttachments = (contentType, selectedDocumentTypeId) => {
        return isPdfFormat(contentType) && (selectedDocumentTypeId === documentType.probateDocument)
    };

    const showWarningForUploadedProbateDocument = (contentType, selectedDocumentTypeId) => {
        return isNonPdfFormat(contentType) && (selectedDocumentTypeId === documentType.probateDocument)

    }

    return {
        enumType,
        generalType,
        documentType,
        documentTypePropertyMapper,
        getMatchFromList,
        getEnumOptions,
        legalStatusOptions,
        deductionTypeEnum,
        downloadAssetsAndDebtsZip,
        isDownloadingProbateZip,
        userName,
        isDropDownType,
        includeAttachments,
        showWarningForUploadedProbateDocument
    }
}

useProbateUtil.getDefaultProbateInfo = {
    notes: [],
    tabTempNotes: null,
    informations: [],
    meetingInfo: {},
    property: {
        assets: [],
        debts: [],
        deductions: [],
    },
};

useProbateUtil.isUpdatedBeneficiariesClassNames = false;

/**
 * @description: Error handling for probate tab
 */
const isValidAssetsAndDebts = (property) => {
    let probateInfoStatus = true;
    if (property !== undefined
        && property.deceasedPropertyGroupedDetails !== undefined
        && property.spousePropertyGroupedDetails !== undefined) {
        probateInfoStatus = ValidationUtil.isAssetsAndDebtsFilled(property.deceasedPropertyGroupedDetails.matrimonialProperties?.propertyDetailsDto)
            && ValidationUtil.isAssetsAndDebtsFilled(property.spousePropertyGroupedDetails.matrimonialProperties?.propertyDetailsDto)
            && ValidationUtil.isAssetsAndDebtsFilled(property.deceasedPropertyGroupedDetails.privateProperties?.propertyDetailsDto)
            && ValidationUtil.isAssetsAndDebtsFilled(property.spousePropertyGroupedDetails.privateProperties?.propertyDetailsDto);
    }
    return probateInfoStatus;
}

const isValidProbateDueDate = (dueDateInfo) => {
    const {isDueDateManual, dueDate, dueDateDocumentId} = dueDateInfo || {};
    return useDueDateUtil.isDueDateDocumentUploadRequired(isDueDateManual, dueDate, dueDateDocumentId).status === false
}

const errorInfoForKey = (componentId, data, errorsInfo) => {
    switch (componentId) {
        case ProbateProps.meetingInfo: {
            const isValid = isValidProbateDueDate(data);
            if (!isValid) {
                errorsInfo[`Probate ${componentId}`] = MANUAL_DUEDATE_MUST_HAVE_DOCUMENT;
            }
            return errorsInfo;
        }
        case ProbateProps.property: {
            const isValid = isValidAssetsAndDebts(data);
            if (!isValid) {
                errorsInfo[`Probate ${componentId}`] = Lang().cases.probates.assetsDebtsInvalidText;
            }
            return errorsInfo;
        }
        default:
            return errorsInfo;
    }
}
/***
 * @description: Only isComponentValidationSuccessful is exposed to be used
 */
useProbateUtil.isComponentValidationSuccessful = (probateInfo) => {
    let errorsInfo = {};
    errorsInfo = errorInfoForKey(ProbateProps.meetingInfo, probateInfo?.meetingInfo?.dueDateInfo, errorsInfo);
    errorsInfo = errorInfoForKey(ProbateProps.property, probateInfo?.property, errorsInfo);

    if (ValidationErrors.isAvailable(errorsInfo)) {
        showToastMessagesForMapper(errorsInfo);
    }
    return ValidationErrors.isEmpty(errorsInfo);
}

export default useProbateUtil;
