import Icon from "../icon";
import React from "react";
import LogUtil, {LogTraceObjectType} from "./util/logUtil";
import AppUtil from "../appUtil";
import PropTypes from "prop-types";
import {clickablePath, mailTo, versionTo} from "./util/logUIUtil";
import {ClickableOrNot} from "../link/clickableOrNot";

class Trace extends React.Component {
    /***
     * @description: To get design details
     */
    getDesignDetails = (traceObjectType, classNames) => {
        switch (traceObjectType) {
            case LogTraceObjectType.case:
                return {
                    className: `${classNames} tag--case--extended`, //FYI: special case where case icon is needed to show connect probate case.
                    iconType: "case"
                };
            case LogTraceObjectType.todo:
                return {
                    className: `${classNames} tag--todo`,
                    iconType: "todo"
                };
            case LogTraceObjectType.todoDone:
                return {
                    className: `${classNames} tag--todo tag--todo--done`,
                    iconType: "todos"
                };
            case LogTraceObjectType.order:
                return {
                    className: `${classNames} tag--order`,
                    iconType: "order-funeral"
                };
            case LogTraceObjectType.call:
                return {
                    className: `${classNames} tag--call`,
                    iconType: "call"
                };
            case LogTraceObjectType.note:
                return {
                    className: `${classNames} tag--note card--note content`.replace("tag--small", ""),
                    iconType: "note"
                };
            case LogTraceObjectType.document:
                return {
                    className: `${classNames} tag--document`,
                    iconType: "document-order"
                };
            case LogTraceObjectType.email:
                return {
                    className: `${classNames} tag--type tag--email`,
                    iconType: "email"
                };
            case LogTraceObjectType.label:
                return {
                    className: `${classNames} tag--label tag--${traceObjectType.attribute}`,
                    iconType: "-"
                };
            case LogTraceObjectType.location:
                return {
                    className: `${classNames} tag--linked tag--location`,
                    iconType: "location"
                };
            case LogTraceObjectType.relative:
            case LogTraceObjectType.owner:
            case LogTraceObjectType.collaborator:
            case LogTraceObjectType.reviewer:
            case LogTraceObjectType.beneficiary:
                return {
                    className: `${classNames} tag--type`,
                    iconType: "user"
                };
            case LogTraceObjectType.registration:
                return {
                    className: `${classNames} tag--type tag--user`,
                    iconType: "registration"
                };
            case LogTraceObjectType.product:
                return {
                    className: `${classNames} tag--type`,
                    iconType: "product"
                };
            default:
                return null;
        }
    };

    getStyleType = (isPrimary, isRemoved, isRemovedWithoutStrikeThrough) => {
        let tag = LogUtil.traceObjectStyleType.isNone;
        if (isPrimary) {
            tag = LogUtil.traceObjectStyleType.isPrimary;
        } else if (isRemoved) {
            tag = LogUtil.traceObjectStyleType.isRemoved;
        } else if (isRemovedWithoutStrikeThrough) {
            tag = LogUtil.traceObjectStyleType.isRemovedWithoutStrikeThrough;
        }
        return tag;
    };

    getClassName = (styleType) => {
        let classNames = "tag tag--small";
        switch (styleType) {
            case LogUtil.traceObjectStyleType.isPrimary:
                classNames += " tag--button tag--button--primary";
                break;
            case LogUtil.traceObjectStyleType.isRemoved:
                classNames += " tag--removed";
                break;
            case LogUtil.traceObjectStyleType.isRemovedWithoutStrikeThrough:
                break;
            default:
                classNames += " tag--button";
                break;
        }
        return classNames;
    };

    render = () => {
        const {
            object,
            isRemoved,
            isRemovedWithoutStrikeThrough,
            isPrimary
        } = this.props;

        const styleType = this.getStyleType(isPrimary, isRemoved, isRemovedWithoutStrikeThrough);
        const classNames = this.getClassName(styleType);

        const designDetails = this.getDesignDetails(object.type, classNames);
        const props = Object.assign({}, this.props, {styleType: styleType});
        return <TraceContent {...props} design={designDetails}/>
    }
}

export default Trace;


export class TraceContent extends React.Component {
    doNothing = e => {
        e.stopPropagation();
    };

    traceComponent = (object, styleType, design) => {
        if (AppUtil.isAvailable(design) && AppUtil.isAvailable(object)) {
            if (object.type === LogTraceObjectType.email) {
                return mailTo(object.text || object.id)
            } else {
                return this.traceTo(object, styleType, design)
            }
        } else if (object.type === LogTraceObjectType.version) {
            return versionTo(object, styleType)
        } else {
            return null;
        }
    };

    render = () => {
        const {
            object,
            styleType,
            design,
        } = this.props;
        // console.log(`[DEBUG]::: Trace Type = ${object.type}, Id = ${object.id}, Text = ${object.text}`);
        return this.traceComponent(object, styleType, design);
    };

    traceTo = (object, styleType, design) => {
        const isRemoved = LogUtil.isRemoved(styleType);
        return (
            <ClickableOrNot isClickable={!isRemoved}
                            link={clickablePath(object.type, object.logType, object.text, object.id, AppUtil.isAvailable(object.logsForObject) ? object.logsForObject.id : null)}
                            className={design.className}
            >
                {this.getTraceChildElement(design.iconType, object.text || object.id, isRemoved)}
            </ClickableOrNot>
        );
    }

    getTraceChildElement(iconType, value, isRemoved) {
        return <React.Fragment>
            <Icon type={iconType} tiny isColor/>
            <span>{value}</span>
            {
                !isRemoved && <Icon type="external" tiny/>
            }
        </React.Fragment>;
    }
}

TraceContent.propTypes = {
    object: PropTypes.any.isRequired,
    design: PropTypes.object,
};
