import React from "react";
import Icon from "../icon";
import PropTypes from "prop-types";
import Tooltip from "../tooltip";

class Button extends React.Component {

    render = () => {
        const {
            children,
            key,
            id,
            type,
            ref,
            onClick,
            onChange,
            disabled,
            title,
            isPrimary,
            isSecondary,
            isTertiary,
            isDestructive,
            noLabel,
            noIcon,
            maxWidth,
            isSmall,
            isTiny,
            isExternal,
            icon,
            iconIsColor,
            className,
            tooltip,
            tooltipInline,
            tooltipAlignRight,
            tooltipAlignLeft,
            tooltipAlignTop,
            tooltipShortcut
        } = this.props

        let [
            classNames = "button",
            iconType = icon,
            buttonType = type ? type : "button",
            tooltipText = tooltip
        ] = ""

        // Until all buttons have been converted
        classNames += " button--flex";

        // Add any custom CSS classes
        if (className !== undefined) classNames += ` ${className}`;

        if (isPrimary) classNames += " is-primary";
        if (isTertiary) classNames += " is-tertiary";
        if (isSecondary || (!isPrimary && !isTertiary)) classNames += " is-secondary";

        if (isDestructive) {
            classNames += " is-destructive"

            if ((iconType === "" || iconType === null || iconType === undefined) && !noIcon) {
                iconType = "trash";
            }
        }

        if (noLabel) {
            classNames += " button--no_label";
        }

        if (maxWidth) {
            classNames += " button--max_width";
        }

        if (isSmall) {
            classNames += " is-small";
        }

        if (isTiny) {
            classNames += " is-tiny";
        }

        return (
            <button
                id={id}
                key={key}
                type={buttonType}
                ref={ref}
                onClick={onClick}
                onChange={onChange}
                disabled={disabled}
                title={title}
                className={classNames}
                data-tip={tooltipText ? true : false}
            >
                <span className="button__content">
                    {iconType ?
                        isTiny ?
                            <Icon
                                type={iconType}
                                isColor={iconIsColor}
                                tiny
                            />
                            :
                            <Icon
                                type={iconType}
                                isColor={iconIsColor}
                            />
                    : null}
                    {!noLabel && children ?
                        <span className="button__description">
                            {children}
                        </span>
                    : null}
                    {isExternal ?
                        <Icon
                            type="external"
                            small
                        />
                    : null}
                    {tooltipText ?
                        <Tooltip
                            inline={tooltipInline}
                            alignRight={tooltipAlignRight}
                            alignLeft={tooltipAlignLeft}
                            alignTop={tooltipAlignTop}
                            shortcut={tooltipShortcut}
                        >
                            {tooltipText}
                        </Tooltip>
                    : null}
                </span>
            </button>
        )
    };
}

Button.propTypes = {
    children: PropTypes.string,
    key: PropTypes.any,
    id: PropTypes.string,
    type: PropTypes.string,
    ref: PropTypes.any,
    onClick: PropTypes.any,
    onChange: PropTypes.any,
    disabled: PropTypes.any,
    title: PropTypes.string,
    isPrimary: PropTypes.bool,
    isSecondary: PropTypes.bool,
    isTertiary: PropTypes.bool,
    isDestructive: PropTypes.bool,
    noLabel: PropTypes.bool,
    maxWidth: PropTypes.bool,
    isTiny: PropTypes.bool,
    isExternal: PropTypes.bool,
    icon: PropTypes.string,
    className: PropTypes.string,
    tooltip: PropTypes.any,
    tooltipInline: PropTypes.bool,
    tooltipAlignRight: PropTypes.bool,
    tooltipAlignLeft: PropTypes.bool,
    tooltipAlignTop: PropTypes.bool,
};

export default Button;
