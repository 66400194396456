import React from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import {bindActionCreators} from "redux";
import * as actionMaster from "../../actions/uiAction";
import * as caseAction from "../../actions/caseAction";
import * as callAction from "../../actions/callAction";
import * as caseOrderAction from "../../actions/caseOrderAction";
import {reduxForm} from "redux-form";
import {Link, withRouter} from "react-router-dom";
import Popup from "../common/popups/popup";
import FooterWithSave from "../common/footer";
import Icon from "../common/icon";
import LabelComponent, {LabelType} from "../common/labelsControl";
import AsyncSelect from "react-select/lib/Async";
import $ from "jquery";
import config from "../../api/config";
import CreatableSelect from "react-select/lib/Creatable";
import Utility from "../../api/utilLanguage";
import Permalink from "../common/link/permalink";
import DisplayNothing from "../common/nothingToDisplay";
import SoundPlayer from '../common/soundPlayer';
import ErrorUtil from "../common/errorUtil";
import AppUtil from "../common/appUtil";
import CallColumnUtil from "../common/callColumnUtil";
import Button from "../common/button";
import {getApiToken} from "../common/localStorageUtil";

class CallComponent extends React.Component {

    constructor(props) {
        super(props);
        this.disableSave = false;
        this.state = {
            userName: '',
            initialData: [],
            initialCaller: [],
            disableSave: false
        };
    }

    UNSAFE_componentWillMount = () => {
        if (this.props.selectedCallData.id === undefined) {
            this.props.actionMaster.showDisplayNothingComponent(true)
        } else {
            this.props.actionMaster.showDisplayNothingComponent(false)
        }
    };

    componentDidUpdate = () => {
        this.shouldShowConfirmationAlert();
    };

    componentDidMount = () => {
        this.props.caseAction.getAllUserDetails();
        if (this.props.selectedCallData.caseId !== undefined && this.props.selectedCallData.caseId !== null) {
            this.props.callAction.getRelativeDetailsAccToCase(this.props.selectedCallData.caseId);
        } else {
            this.props.callAction.clearRelative();
        }
        setTimeout(function () {
            if (this.props.allUserDetailsForCases.length > 0) {
                for (let n = 0; n < this.props.allUserDetailsForCases.length; n++) {
                    if (this.props.allUserDetailsForCases[n].id === this.props.selectedCallData.callTakenBy) {
                        this.setState({
                            userName: this.props.allUserDetailsForCases[n].name
                        })
                    }
                }
            }
        }.bind(this), 100);
        if (this.props.selectedCallData.id !== undefined) {
            let temp = [];
            let newTemp = [];

            let deceasedName = this.props.selectedCallData.deceasedName;
            let personNumber = this.props.selectedCallData.personNumber;
            let nameToShow = this.props.selectedCallData.caseId !== null && this.props.selectedCallData.caseId !== undefined ? this.props.selectedCallData.caseId : "";
            if (deceasedName !== null) {
                nameToShow += " " + deceasedName
            }
            if (personNumber !== null) {
                nameToShow += " - " + personNumber
            }
            if (this.props.selectedCallData.caseId !== undefined && this.props.selectedCallData.caseId !== null) {
                temp.push({
                    id: this.props.selectedCallData.caseId !== undefined && this.props.selectedCallData.caseId !== null ? this.props.selectedCallData.caseId : "",
                    deceasedName: nameToShow
                });
            }
            this.setState({
                initialData: temp,
            });

            if ((this.props.selectedCallData.callerId !== 0 && this.props.selectedCallData.callerId !== null && this.props.selectedCallData.callerId !== undefined) || (this.props.selectedCallData.callerName !== null && this.props.selectedCallData.callerName !== undefined)) {
                newTemp.push({
                    value: this.props.selectedCallData.callerId !== null && this.props.selectedCallData.callerId !== undefined ? this.props.selectedCallData.callerId.toString() : "0",
                    label: this.props.selectedCallData.callerName !== null && this.props.selectedCallData.callerName !== undefined ? this.props.selectedCallData.callerName : ""
                });
            }
            this.setState({
                initialCaller: newTemp
            })
        }
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.callUpdated !== undefined && (nextProps.callUpdated !== this.props.callUpdated)) {
            if (nextProps.callUpdated === true) {
                this.disableSave = false;
                this.setState({disableSave: false});
            }
        }
    };

    shouldShowConfirmationAlert = () => {
        if (this.props.selectedDataById.id !== undefined && this.props.selectedCallData.id !== undefined) {
            const initialDataStr = JSON.stringify(this.props.selectedDataById);
            const newDataStr = JSON.stringify(this.props.selectedCallData);
            (initialDataStr === newDataStr) ? this.props.actionMaster.hideUnsavedPopupLazily() : this.props.actionMaster.showUnsavedPopupLazily();
        }
        if (AppUtil.isAvailable(this.props.tempNote)) {
            this.props.actionMaster.showUnsavedPopupLazily();
        }
    };

    currentRoute = () => {
        this.props.actionMaster.onHeaderMenuItemClicked(this.props.history.location.pathname);
        this.props.actionMaster.closePopupWithSameRoute();
    };

    toMoveNewRoute = () => {
        this.props.actionMaster.clearRouteCondition();
        if (this.props.headerMenuItemClicked !== this.props.history.location.pathname) {
            this.props.history.push(this.props.headerMenuItemClicked);
        }
    };

    handleChangeForlabel = (selectedOption) => {
        this.props.callAction.addLabelToStateForCall(selectedOption)
    };

    removeLabel = (labels, i) => {
        console.log("labels", labels, i);
        this.props.callAction.removeLabelFromCall(labels, i)
    };

    openSearchPanelWithExistingText = (id) => {
        if (id === "Phone") {
            this.props.callAction.openSearchWithText(this.props.selectedCallData.callerNumber)
        } else {
            this.props.callAction.openSearchWithText(this.props.selectedCallData.location)
        }
    };

    promiseOptionForOrderOnly = inputValue =>
        new Promise(resolve => {
            setTimeout(() => {
                resolve(this.loadOptionsForCase(inputValue));
            }, 1000);
        });

    loadOptionsForCase = async (inputValue) => {
        let temp = [];
        if (inputValue) {
            await this.loadCasesData(inputValue).then(data =>
                temp = data.object.cases
            );
            if (temp.length > 0) {
                for (let n = 0; n < temp.length; n++) {
                    let deceasedName = temp[n].deceasedName;
                    let personNumber = temp[n].personNumber;
                    temp[n].deceasedName = temp[n].id;
                    if (deceasedName !== null) {
                        temp[n].deceasedName += " " + deceasedName
                    }
                    if (personNumber !== null) {
                        temp[n].deceasedName += " - " + personNumber
                    }
                }
            }
            this.setState({
                resultData: temp
            });
            return this.state.resultData;
        } else {
            return [];
        }
    };

    loadCasesData = (inputValue) => {
        return new Promise((resolve, reject) => {
            let token = getApiToken();
            let request = $.ajax({
                url: config.baseURL + `/api/v1/case/list/simple?f_tx=${inputValue}`,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    };

    handleItemSelectChangeForCases = (data) => {
        if (data.id !== undefined) {
            this.props.callAction.getRelativeDetailsAccToCase(data.id);
        }
        let temp = [];
        temp.push({
            id: data.id,
            deceasedName: data.deceasedName
        });
        this.setState({
            initialData: temp
        });
        this.props.callAction.setCaseIdForCallTabOnly(data);
    };

    /**
     *@description: Get all label details
     * @param inputValue
     * @returns {Promise<Array|*>}
     */
    loadLabelOptions = async (inputValue) => {
        if (inputValue) {
            await this.props.caseAction.getLabelDetails(LabelType.call, inputValue).then(responseData => {
            }).catch(error => {
                console.log("getLabelDetails LabelType.call = ", error);
            });
            return this.props.caseAllLabels;
        } else {
            return [];
        }
    };

    onSaveCall = () => {
        this.disableSave = true;
        this.props.callAction.updateCallUpdatingStatus(false);
        this.setState({disableSave: true});
        let data = {};
        data = this.props.selectedCallData;
        this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isUpdating);
        setTimeout(function () {
            data = CallColumnUtil.getUpdatedNotes(this.props.tempNote, data);
            this.props.callAction.saveCallDataApi(data, this.props.storingKey);
            setTimeout(function () {
                this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
            }.bind(this), 1000)
        }.bind(this), 0);
    };

    handleChangeForRelative = (e) => {
        console.log("evente", e)
        if (e.length !== 0) {
            this.setState({
                initialCaller: e,
            });
            if (e.label === e.value) {
                e.value = "0";
                this.props.callAction.setCallerInfo(e);
            } else {
                this.props.callAction.setCallerInfo(e);
            }
        }
    };

    onInputItemChange = (e, inputValue, actionMeta) => {
        console.log("onInputItemChange", e, inputValue);
    };

    onDeleteClick = () => {
        this.props.actionMaster.showPopup();
    };

    hidePopup = () => {
        this.props.actionMaster.hidePopupSection();
    };

    onDelete = () => {
        this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isDeleting);
        this.props.callAction.deleteCallDataApi(this.props.selectedCallData, this.props.storingKey);
        setTimeout(function () {
            this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
        }.bind(this), 1000);
        this.hidePopup();
    };

    onInputLocationChange = (event) => {
        //this.setState({location: event.target.value});
        this.props.callAction.setCallerLocationForCall(event.target.value)
    };

    onInputCallerNumberChange = (event) => {
        //this.setState({callerNumber: event.target.value});
        this.props.callAction.setCallerContactForCall(event.target.value)

    };

    render = () => {
        console.log("selectedCallData", this.props.selectedCallData, this.props.displayNothing);
        return (
            <React.Fragment>
                <Popup openModal={this.props.showUnsavedPopup}
                       headerInfo={Utility.getLang().infoMessages.popup.unsavedChanges}
                       onConfirmation={this.toMoveNewRoute}
                       closePopup={this.currentRoute}/>
                {
                    this.props.displayNothing === false ?
                        <div className="section section--detail section--scroll is-live" ref={node => this.node = node}>
                            <div className="section__head">
                                <div className="inner">
                                    <h3 className="section__title ">
                                        <Icon type="call"/>
                                        <span className="description">
                                    {
                                        this.props.selectedCallData.labels !== undefined ?
                                            this.props.selectedCallData.labels.map((label, i) => {
                                                var capitalizedLabelTitle = label.title.charAt(0).toUpperCase() + label.title.slice(1);
                                                return i === 0 ? capitalizedLabelTitle : ", " + capitalizedLabelTitle;
                                            }) : null
                                    }
                                        </span>
                                        <Permalink
                                            id={this.props.selectedCallData.id}
                                            path1={AppUtil.linkPaths.call.basePath}
                                            path2={AppUtil.linkPaths.call.details}
                                        />
                                    </h3>
                                </div>
                            </div>
                            <div className="section__content section__content--call_details">
                                <div className="inner">
                                    <form>
                                        <div className="section__columns">
                                            <div className="section__column">
                                                {this.props.selectedCallData.recording !== null ?
                                                    <div className="form-group">
                                                        <label
                                                            htmlFor="timelineRange">{Utility.getLang().calls.callTabContent.recording}</label>
                                                        <SoundPlayer url={this.props.selectedCallData.recording}
                                                                     mode={'full'}/>
                                                    </div> : null
                                                }
                                                <div className="form-group form-group--columns">
                                                    <div className="form-group__column">
                                                        <label htmlFor="callFieldLocation">
                                                            {Utility.getLang().calls.callTabContent.location}
                                                        </label>
                                                        <div className="btn-toolbar btn-toolbar--actions">
                                                            <input
                                                                type="text"
                                                                placeholder={Utility.getLang().cases.statusTabContent.location}
                                                                className="text btn-toolbar__field"
                                                                value={this.props.selectedCallData.location}
                                                                data-lpignore="true"
                                                                id="callFieldLocation"
                                                                onChange={(e) => this.onInputLocationChange(e)}
                                                                onKeyPress={event => {
                                                                    if (event.key === 'Enter') {
                                                                        this.openSearchPanelWithExistingText("Location");
                                                                        event.preventDefault();
                                                                    }
                                                                }}
                                                            />
                                                            <Button
                                                                id="callFieldLocationSearch"
                                                                onClick={(e) => this.openSearchPanelWithExistingText("Location")}
                                                                icon="search"
                                                                tooltip={Utility.getLang().header.search}
                                                                noLabel
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group__column">
                                                        <label htmlFor="callFieldPhone">
                                                            {Utility.getLang().calls.callTabContent.phone}
                                                        </label>
                                                        <div className="btn-toolbar btn-toolbar--actions">
                                                            <input
                                                                type="text"
                                                                placeholder={Utility.getLang().cases.statusTabContent.phone}
                                                                className="text btn-toolbar__field"
                                                                value={this.props.selectedCallData.callerNumber}
                                                                data-lpignore="true"
                                                                id="callFieldPhone"
                                                                onChange={(e) => this.onInputCallerNumberChange(e)}
                                                                onKeyPress={event => {
                                                                    if (event.key === 'Enter') {
                                                                        this.openSearchPanelWithExistingText("Phone");
                                                                        event.preventDefault();
                                                                    }
                                                                }}
                                                            />
                                                            <Button
                                                                id="callFieldPhoneSearch"
                                                                onClick={(e) => this.openSearchPanelWithExistingText("Phone")}
                                                                icon="search"
                                                                tooltip={Utility.getLang().header.search}
                                                                noLabel
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group form-group--columns">
                                                    <div className="form-group__column">
                                                        <label htmlFor="callTabContentPhone">
                                                            {Utility.getLang().calls.callTabContent.callerId}
                                                        </label>
                                                        <CreatableSelect
                                                            inputId="callTabContentPhone"
                                                            options={this.props.relatives}
                                                            onChange={this.handleChangeForRelative}
                                                            onInputChange={this.onInputItemChange}
                                                            className={"multiselect"}
                                                            classNamePrefix={"multiselect"}
                                                            //defaultValue={this.state.initialCaller}
                                                            value={this.state.initialCaller}
                                                            placeholder={Utility.getLang().common.dropDownOptionPleaseSelect}
                                                            formatCreateLabel={e => Utility.getLang().common.dropDownCreateMessage + `"${e}"`}
                                                            noOptionsMessage={() => Utility.getLang().common.noOptionMessage}
                                                            loadingMessage={() => Utility.getLang().common.dropDownOptionLoadingMessage}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group form-group--columns">
                                                    <div className="form-group__column">
                                                        <label htmlFor="callTabContentCase">
                                                            {Utility.getLang().calls.callTabContent.case}
                                                        </label>
                                                        <AsyncSelect
                                                            inputId="callTabContentCase"
                                                            getOptionLabel={({deceasedName}) => deceasedName}
                                                            getOptionValue={({id}) => id}
                                                            loadOptions={this.promiseOptionForOrderOnly}
                                                            onChange={(option) => this.handleItemSelectChangeForCases(option)}
                                                            defaultOptions
                                                            value={this.state.initialData}
                                                            className={"multiselect"}
                                                            classNamePrefix={"multiselect"}
                                                            placeholder={Utility.getLang().common.dropDownOptionTypeToSearch}
                                                            noOptionsMessage={() => Utility.getLang().common.noOptionMessage}
                                                            loadingMessage={() => Utility.getLang().common.dropDownOptionLoadingMessage}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="section__column section__column--small">
                                                <LabelComponent
                                                    data={this.props.selectedCallData.labels}
                                                    loadOptions={this.loadLabelOptions}
                                                    handleChangeForlabel={this.handleChangeForlabel}
                                                    removeLabel={this.removeLabel}
                                                />
                                                <div className="form-group form-group--static">
                                                    <h5 className="title">{Utility.getLang().calls.callTabContent.source}</h5>
                                                    <p className="description">{this.props.selectedCallData.source !== undefined ? this.props.selectedCallData.source : null}</p>
                                                </div>
                                                <div className="form-group form-group--static">
                                                    <h5 className="title">{Utility.getLang().calls.callTabContent.callCreated}</h5>
                                                    <p className="description">
                                                        <span
                                                            className="number">{this.props.selectedCallData.created !== undefined ? this.props.selectedCallData.createdText : null}</span> {Utility.getLang().common.createdBySystem}
                                                    </p>
                                                </div>
                                                <div className="form-group form-group--static">
                                                    <h5 className="title">{Utility.getLang().calls.callTabContent.callTakenBy}</h5>
                                                    <p className="description">{AppUtil.isAvailable(this.props.selectedCallData.callTakenAgentName) ? this.props.selectedCallData.callTakenAgentName : null}</p>
                                                </div>
                                                <div className="form-group form-group--static">
                                                    <h5 className="title">{Utility.getLang().calls.callTabContent.callVisitorData}</h5>
                                                    {AppUtil.isAvailable(this.props.selectedCallData.visitorDataUrl) ?
                                                        <Link to={this.props.selectedCallData.visitorDataUrl}
                                                              target="_blank">
                                                            {this.props.selectedCallData.visitorDataUrl}
                                                            <Icon type="external" tiny/>
                                                        </Link>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div> : <DisplayNothing/>
                }
                <Popup
                    openModal={this.props.open}
                    headerInfo={Utility.getLang().infoMessages.popup.deleteCall + ` ` + (this.props.selectedCallData.id !== undefined ? this.props.selectedCallData.id : "---") + `?`}
                    onConfirmation={this.onDelete}
                    closePopup={this.hidePopup}
                    isDestructive
                    withTimeOut
                />
                <FooterWithSave SaveData={this.onSaveCall}
                                hideFooter={this.props.displayNothing}
                                buttonText={Utility.getLang().calls.footer.save}
                                disableSaveButton={this.disableSave}
                                deleteButtonText={Utility.getLang().calls.footer.delete}
                                deleteCase={this.onDeleteClick}
                                hideRelativeDropdown={true}/>
            </React.Fragment>
        )
    }
}

CallComponent.propTypes = {
    columnDetailViewTabCollection: PropTypes.array,
};

function mapStateToProps(state) {
    return {
        selectedCallData: state.application.selectedCallData,
        selectedDataById: state.application.selectedDataById,
        initialValues: state.application.selectedCallData,
        caseAllLabels: state.application.caseAllLabels,
        relatives: state.application.relatives,
        allUserDetailsForCases: state.application.allUserDetailsForCases,
        open: state.application.open,
        showUnsavedPopup: state.application.showUnsavedPopup,
        headerMenuItemClicked: state.application.headerMenuItemClicked,
        displayNothing: state.application.displayNothing,
        callUpdated: state.application.callUpdated,
        tempNote: state.application.tempNote
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        callAction: bindActionCreators(callAction, dispatch),
        caseOrderAction: bindActionCreators(caseOrderAction, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: "CallComponent"
})(withRouter(CallComponent)));
