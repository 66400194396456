import Header from "../../common/header";
import SearchComponent from "../../common/searchComponent";
import NotificationComponent from "../../common/notificationComponent";
import React from "react";

const DashboardContainer = ({children}) => {
    return <div className="panel panel--workarea panel--settings panel--organisation">
        <Header/>
        <div className="panel__content panel__content--main">
            <SearchComponent/>
            <main className="columns columns--static">
                <div className="drawer">
                    {children}
                </div>
            </main>
            <NotificationComponent/>
        </div>
    </div>
}
export default DashboardContainer;