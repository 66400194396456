import React from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import {bindActionCreators} from "redux";
import * as actionMaster from "../../../actions/uiAction";
import * as caseAction from "../../../actions/caseAction";
import {bottomCenter} from "../../common/customToastr";
import Popup from "../../common/popups/popup";
import Utility from "../../../api/utilLanguage";
import PopupForEmailSend from "../../common/popups/popupForEmailSend";
import {DocumentListingTable} from "./documentListingTable/documentListingTable";
import {DocumentGenerateOrUpload} from "./documentGenerateOrUpload/documentGenerateOrUpload";
import AddDocumentPreviewOverlay from "./documentGenerateOrUpload/addDocumentPreviewOverlay";
import UploadDocumentPreviewOverlay from "./documentGenerateOrUpload/uploadDocumentPreviewOverlay";
import AppUtil from "../../common/appUtil";
import CaseHeaderSection from "../caseHeaderSection";
import * as documentAction from "../../../actions/documentAction";
import PopupForLogDisplay from "../../common/popups/popupForLogDisplay";
import $ from 'jquery';
import ErrorUtil from '../../common/errorUtil';
import ApiCollection from "../../../api/apiCollections";
import RefreshToken from "../../../api/validateToken";
import {getApiToken} from "../../common/localStorageUtil";
import Spinner from "../../common/spinner";
import DocumentPreviewHOC from "./documentGenerateOrUpload/documentPreviewHOC";
import DocumentUtil, {DocumentAlertUtil} from "../documents/documentUtil/documentUtil";
import {AppElement} from "./documentUtil/appElement";
import {updateDocumentVisibleToCustomerApi} from "./api";
import {withRouter} from "react-router";
import createHistory from "history/createBrowserHistory";
import DocumentDetails from "./documentDetails/documentDetails";

export const DocType = {
    document: 'document',
    docx: 'docx',
    none: 'none'
}

class CaseDocuments extends React.Component {

    static propTypes = {
        columnDetailViewTabCollection: PropTypes.array,
    };

    constructor(props) {
        super(props);
        this.dataSource = {
            selectedDocumentType: null,
            fileData: null,
            selectedDocument: {},
            HOCComponent: DocumentPreviewHOC(AddDocumentPreviewOverlay)
        };
        this.state = {
            isDocumentListLoading: false,
            isDocumentTypeLoading: false,
            isDeleteDocumentLoading: false,
            isDocumentViewLogLoading: false,
            showDocumentsOverview: true,
            showAddDocumentPreviewOverlay: false,
            showUploadDocumentPreviewOverlay: false,
            showLogsPopup: false,
            documentIdToDelete: null,
            logData: [],
            caseDocuments: [],
            documentsToSendAsEmail: [],
            selectAllCheckedStatus: false,
            emailSubject: '',
            nestedComponent: "",
            downloadingDocumentType: DocType.none,
        };
    }

    /***
     * Component Life Cycle
     */
    componentDidMount = () => {
        this.webserviceCalls();
        this.setState({nestedComponent: createHistory().location.pathname});
    };

    webserviceCalls = () => {
        const {selectedCaseData} = this.props;
        if (!(AppUtil.isAvailable(selectedCaseData) && AppUtil.isAvailable(selectedCaseData.id))) {
            console.log("Failure: GetCaseDocuments & getDocumentTypes are not called selectedCaseData = " + selectedCaseData + "caseId = " + selectedCaseData.id);
            return;
        }

        this.setState({isDocumentListLoading: true});
        this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isLoading);
        this.props.documentAction.getCaseDocuments(selectedCaseData.id).then(responseData => {
            this.processCaseDocumentResponse(responseData);
        }).catch(error => {
            console.log(" getCaseDocuments error = ", error);
        }).finally(() => {
            setTimeout(() => {
                this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
            }, 1000);
            this.setState({isDocumentListLoading: false});
        });


        this.setState({isDocumentTypeLoading: true});
        this.props.documentAction.getDocumentTypes(selectedCaseData.id).then(responseData => {
            this.processDocumentTypesResponse(responseData);
        }).catch(error => {
            console.log(" getDocumentTypes error = ", error);
        }).finally(() => {
            this.setState({isDocumentTypeLoading: false});
        });
    };

    // Handlers
    processCaseDocumentResponse = (responseData) => {
        if (responseData.success === true) {
            this.updateCaseDocuments([...responseData.object]);
        } else {
            bottomCenter().error(DocumentAlertUtil.getErrorMessage(responseData, "get documents"));
        }
    };

    processDocumentTypesResponse = (responseData) => {
        if (responseData.success === true) {
        } else {
            bottomCenter().error(DocumentAlertUtil.getErrorMessage(responseData, "get document types"));
        }
    };


    /**
     All Actions: Table row click action
     */
    onClickOfDocumentTableRow = (e, document) => {
        console.log('document =  ' + document.name + ' id = ' + document.id);
        this.dataSource.selectedDocument = document;
        this.routeToPath(document.id);
        // this.gotoDocumentDetails(document);
    };

    routeToPath = (documentId) => {
        const url = `${AppUtil.linkPaths.case.pathToRoute}/${this.props.selectedCaseData.id}${AppUtil.linkPaths.case.documents}/${documentId}`
        this.routeWithoutReload(url);
    }

    /***
     * FYI: https://www.codegrepper.com/code-examples/javascript/react+change+url+without+reload
     * Instead of this.props.history.push(url);
     * @param url
     */
    routeWithoutReload(url) {
        const nextTitle = '';
        const nextState = null;
        // This will create a new entry in the browser's history, without reloading
        window.history.replaceState(nextState, nextTitle, url);
        this.setState({nestedComponent: url});
    }

    onClickOfCheckbox = (e, documentId) => {
        console.log("onClickOfCheckbox = ", e.target.checked, documentId);
        let documentData = this.state.caseDocuments;
        let documentsToSendAsEmail = this.state.documentsToSendAsEmail;
        let selectAllCheckedStatus = this.state.selectAllCheckedStatus;
        documentData.forEach((document) => {
            if (document.id === documentId) {
                document.checked = e.target.checked;
            }
        });
        if (e.target.checked) {
            if (!documentsToSendAsEmail.includes(documentId)) {
                documentsToSendAsEmail.push(documentId);
                if (documentsToSendAsEmail.length === this.state.caseDocuments.length) {
                    selectAllCheckedStatus = true;
                }
            }
        } else {
            if (documentsToSendAsEmail.includes(documentId)) {
                let filteredAry = documentsToSendAsEmail.filter(e => e !== documentId);
                documentsToSendAsEmail = filteredAry;
            }
            selectAllCheckedStatus = false;
        }
        this.setState({
            caseDocuments: documentData,
            documentsToSendAsEmail: documentsToSendAsEmail,
            selectAllCheckedStatus: selectAllCheckedStatus
        });
    };

    onClickOfSelectAllCheckBox = (e) => {
        let documentData = this.state.caseDocuments;
        let documentsToSendAsEmail = [];
        let selectAllCheckedStatus = this.state.selectAllCheckedStatus;
        if (e.target.checked) {
            documentData.forEach((document) => {
                document.checked = e.target.checked;
                documentsToSendAsEmail.push(document.id);
            });
            selectAllCheckedStatus = true;
        } else {
            documentData.forEach((document) => {
                document.checked = e.target.checked;
            });
            documentsToSendAsEmail = [];
            selectAllCheckedStatus = false;
        }
        this.setState({
            caseDocuments: documentData,
            documentsToSendAsEmail: documentsToSendAsEmail,
            selectAllCheckedStatus: selectAllCheckedStatus
        });
    };

    onClickOfEmailSelected = () => {
        console.log("emailSelected", this.state.documentsToSendAsEmail);
        this.showDocumentEmailPopup();
    };

    getEmailSubject(documentIdsToSend) {
        const documentsSelectedToSend = AppUtil.getMatchedList(documentIdsToSend, this.state.caseDocuments);
        return AppUtil.getDocumentEmailSubject(documentsSelectedToSend, this.props.selectedCaseData);
    }

    updateEmailSubject = () => {
        const emailSubject = this.getEmailSubject(this.state.documentsToSendAsEmail);
        this.setState({emailSubject: emailSubject});
    };

    showDocumentEmailPopup = () => {
        console.log("onClickOfEmail new", this.state.documentsToSendAsEmail);
        this.updateEmailSubject();
        this.props.actionMaster.showPopupOnSend(this.state.documentsToSendAsEmail);
    };

    onClickOfEmail = (e, documentId) => {
        e.stopPropagation();
        this.setState({documentsToSendAsEmail: [documentId]}, () => this.showDocumentEmailPopup());
    };

    onClickOfDelete = (e, documentId) => {
        e.stopPropagation();
        this.setState({documentIdToDelete: documentId});
        this.props.actionMaster.showPopupOnDocumentDelete();
    };

    downloadDocument = (e, documentId, versionSelected, docType = DocType.document) => {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                this.props.actionMaster.afterRefreshToken(newData)
                this.downloadDocumentOrDocx(newData.idToken.jwtToken, documentId, versionSelected, docType);
            }).catch(err => {
                this.logOut()
            })
        } else {
            this.downloadDocumentOrDocx(getApiToken(), documentId, versionSelected, docType);
        }
    };

    downloadDocx = (e, documentId, versionSelected) => {
        this.downloadDocument(e, documentId, versionSelected, DocType.docx);
    };

    onClickOfViewLog = (e, documentId) => {
        e.stopPropagation();
        this.setState({isDocumentViewLogLoading: true});

        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                this.props.actionMaster.afterRefreshToken(newData)
                this.fetchLogsData(newData.idToken.jwtToken, ApiCollection.properties.fetchDocumentsLogs, documentId).then(function (data) {
                    if (data !== undefined && data.object !== undefined) {
                        this.setState({logData: data.object});
                    }
                    this.setState({isDocumentViewLogLoading: false});
                }.bind(this))
            }).catch(err => {
                this.logOut()
            })
        } else {
            this.fetchLogsData(getApiToken(), ApiCollection.properties.fetchDocumentsLogs, documentId).then(function (data) {
                if (data !== undefined && data.object !== undefined) {
                    this.setState({logData: data.object});
                }
                this.setState({isDocumentViewLogLoading: false});
            }.bind(this))
        }
        this.setState({showLogsPopup: true});
    };

    fetchLogsData = (token, dataUrl, documentId) => {
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: dataUrl + "/" + documentId,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    };

    downloadDocumentData = (token, dataUrl, documentId, versionSelected, caseId) => {
        return new Promise((resolve, reject) => {
            let newUrl = dataUrl.replace("{caseId}", caseId);
            newUrl = newUrl.replace("{documentId}", documentId);
            newUrl = newUrl.replace("{versionId}", versionSelected);
            let request = $.ajax({
                url: newUrl,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                if (AppUtil.isAvailable(responseData) && AppUtil.isAvailable(responseData.object)) {
                    const url = `${responseData.object.base64Content}`;
                    const fileName = responseData.object.fileName;
                    AppUtil.downloadFromUrl(url, fileName);
                }
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    };

    onSelectionDocumentCategoryTypeChange = (selectedDocumentType) => {
        const {selectedCaseData} = this.props;
        if (DocumentUtil.isGeneratedOrFilledUpWithNoOrder(selectedDocumentType, selectedCaseData.orders)) {
            bottomCenter().warning(Utility.getLang().common.document.orderRequiredForFurtherAction);
        } else {
            this.gotoAddDocumentPreviewOverlay(selectedDocumentType);
        }
    };

    onUploadFileChange = (formData) => {
        this.gotoUploadDocumentPreviewOverlay(formData);
    };

    /**
     * Return action
     */
    gotoDocumentsOverview = () => {
        this.goBack();
        this.props.documentAction.clearBase64GeneratedDocument();
        this.clearSelectedDocumentType();
        this.setState({
            showDocumentsOverview: true,
            showAddDocumentPreviewOverlay: false,
            showUploadDocumentPreviewOverlay: false
        });
    };

    /**
     * @description: replaceState used for route without refresh instead of 'window.history.back()'
     */
    goBack() {
        const url = `${AppUtil.linkPaths.case.pathToRoute}/${this.props.selectedCaseData.id}${AppUtil.linkPaths.case.documents}`
        window.history.replaceState(null, "", url);
        this.setState({nestedComponent: url});

    }

    gotoDocumentDetails = (document) => {
        this.dataSource.selectedDocument = document;
        this.setState({
            // showDocumentDetails: true,
            showDocumentsOverview: false,
            showAddDocumentPreviewOverlay: false,
            showUploadDocumentPreviewOverlay: false
        });
    };

    gotoAddDocumentPreviewOverlay = (selectedDocumentType) => {
        this.dataSource.HOCComponent = DocumentPreviewHOC(AddDocumentPreviewOverlay);
        this.updateSelectedDocumentType(selectedDocumentType);
        this.setState({
            showDocumentsOverview: true,
            showAddDocumentPreviewOverlay: true,
            showUploadDocumentPreviewOverlay: false
        });
    };

    gotoUploadDocumentPreviewOverlay = (fileData) => {
        this.dataSource.HOCComponent = DocumentPreviewHOC(UploadDocumentPreviewOverlay);
        this.dataSource.fileData = fileData;
        this.setState({
            showDocumentsOverview: true,
            showAddDocumentPreviewOverlay: false,
            showUploadDocumentPreviewOverlay: true
        });
    };

    saveClickOnDocGeneration = (responseJson) => {
        this.gotoDocumentsOverview();

        const {caseDocuments} = this.props;
        this.updateCaseDocuments(caseDocuments);
    };

    clearSelectedDocumentType = () => {
        this.updateSelectedDocumentType(null);
    };

    updateSelectedDocumentType = (documentType) => {
        this.dataSource.selectedDocumentType = documentType;
    };

    /***
     * @description: Common 'caseDocuments' update
     */
    updateCaseDocuments = (value) => {
        this.setState({caseDocuments: value});
    };

    saveClickOnDocUpload = (responseJson) => {
        this.gotoDocumentsOverview();

        const {caseDocuments} = this.props;
        this.updateCaseDocuments(caseDocuments);
    };

    hidePopupOnCancel = () => {
        let documentData = this.state.caseDocuments;
        documentData.forEach((document) => {
            document.checked = false;
        });
        this.setState({
            showLogsPopup: false,
            logData: [],
            documentsToSendAsEmail: [],
            selectAllCheckedStatus: false,
            caseDocuments: documentData
        });
        this.props.actionMaster.hidePopupSection();
    };

    hidePopupOnDocumentDelete = () => {
        this.props.actionMaster.hidePopupOnDocumentDelete();
    };

    onDeleteDocument = () => {
        this.setState({isDeleteDocumentLoading: true});
        this.props.documentAction.deleteCaseDocument(this.props.selectedCaseData.id, this.state.documentIdToDelete).then(responseData => {

            const {caseDocuments} = this.props;
            this.updateCaseDocuments(caseDocuments);

            this.setState({documentIdToDelete: null, isDeleteDocumentLoading: false});

            if (responseData.success) {
                const {selectedCaseData, headerMenuItemClicked} = this.props;
                this.getLogsAndDocsCountApiCall(selectedCaseData.id, headerMenuItemClicked);
            }
        }).catch(error => {
            this.setState({documentIdToDelete: null, isDeleteDocumentLoading: false});
            console.log("Delete = ", error);
        });

        this.gotoDocumentsOverview();
        this.hidePopupOnDocumentDelete();
    };

    getLogsAndDocsCountApiCall = (caseId, headerItemClicked) => {
        this.props.caseAction.getLogsAndDocsCount(caseId, headerItemClicked);
    };

    sendEmailSummary = (relativeId, data, emailTemplateType) => {
        // Sending the email
        data.caseId = this.props.selectedCaseData.id;
        if (emailTemplateType === 8) {
            this.props.documentAction.sendDocumentsEmail(this.props.selectedCaseData.id, data).then(responseData => {
                if (responseData.success) {
                    const {selectedCaseData, headerMenuItemClicked} = this.props;
                    this.getLogsAndDocsCountApiCall(selectedCaseData.id, headerMenuItemClicked);
                }
            }).catch(error => {
                console.log("sendDocumentsEmail = ", error);
            });

            let documentData = this.state.caseDocuments;
            documentData.forEach((document) => {
                document.checked = false;
            });
            this.setState({caseDocuments: documentData, documentsToSendAsEmail: [], selectAllCheckedStatus: false})
        } else {
            this.props.caseAction.sendEmailSummary(this.props.selectedCaseData.id, data);
        }
    };

    onClickOfCheckboxVisibleToCustomer = (checked, documentId) => {
        let documentData = this.state.caseDocuments;
        documentData.forEach((document) => {
            if (document.id === documentId) {
                document.isLoading = true;
                document.visibleToCustomer = checked;
                this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isLoading);
            }
        });
        updateDocumentVisibleToCustomerApi(documentId, this.props.selectedCaseData.id, checked).then((response) => {
            if (AppUtil.isAvailable(response.data) && response.data.success) {
                const data = {id: documentId, checked: checked};
                this.props.documentAction.updateCaseDocumentVisibleToCustomer(data);
                bottomCenter().success(Utility.getLang().common.document.documentUpdated);
            }
        }).finally(() => {
            documentData.forEach((document) => {
                if (document.id === documentId) {
                    document.isLoading = false;
                }
            });
            this.setState({caseDocuments: documentData});
            setTimeout(() => {
                this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
            }, 1000);
        });
    };

    render = () => {
        const {emailSubject} = this.state;
        const {HOCComponent, selectedDocumentType} = this.dataSource || {};
        const {
            isDocumentListLoading,
            isDocumentTypeLoading,
            isDeleteDocumentLoading,
            isDocumentViewLogLoading,
            nestedComponent
        } = this.state;

        console.log("createHistoryUrl", nestedComponent)
        return (
            <React.Fragment>
                {
                    this.props.displayNothing === false ?
                        <div className="section section--detail section--scroll" ref={node => this.node = node}>
                            <CaseHeaderSection wsLockCaseData={this.props.wsLockCaseData}
                                               selectedCaseData={this.props.selectedCaseData}/>
                            {this.props.selectedCaseData !== undefined && this.props.selectedCaseData.id !== undefined &&

                            <React.Fragment>

                                {
                                    // Add document preview overlay
                                    // this must be a direct child of .app
                                }
                                {
                                    this.state.showAddDocumentPreviewOverlay && AppUtil.isAvailable(HOCComponent) &&
                                    <AppElement>
                                        <HOCComponent
                                            orders={this.props.selectedCaseData.orders}
                                            selectedDocumentType={selectedDocumentType}
                                            backToScreen={this.gotoDocumentsOverview}
                                            saveBtnAction={this.saveClickOnDocGeneration}
                                            documentScreenType={DocumentUtil.screenType.generated}
                                        />
                                    </AppElement>
                                }
                                {
                                    // Upload document preview overlay, must be a direct child of .app
                                }
                                {
                                    this.state.showUploadDocumentPreviewOverlay &&
                                    <AppElement>
                                        <HOCComponent
                                            options={this.props.caseDocumentTypes}
                                            orders={this.props.selectedCaseData.orders}
                                            fileData={this.dataSource.fileData}
                                            backToScreen={this.gotoDocumentsOverview}
                                            saveBtnAction={this.saveClickOnDocUpload}
                                            documentScreenType={DocumentUtil.screenType.uploaded}
                                        />
                                    </AppElement>
                                }
                                {
                                    // Documents
                                }
                                {
                                    this.state.showDocumentsOverview && nestedComponent === `${AppUtil.linkPaths.case.pathToRoute}/${this.props.selectedCaseData.id}${AppUtil.linkPaths.case.documents}` &&
                                    <div id="documentMain"
                                         className="section__content section__content--case_documents">
                                        {
                                            <DocumentGenerateOrUpload
                                                {...this.props}
                                                options={this.props.caseDocumentTypes}
                                                onSelectionChange={this.onSelectionDocumentCategoryTypeChange}
                                                onUploadFileChange={this.onUploadFileChange}
                                                isDocumentTypeLoading={isDocumentTypeLoading}
                                                selectedDocumentType={selectedDocumentType}
                                            />
                                        }
                                        {
                                            <DocumentListingTable
                                                {...this.props}
                                                documents={this.state.caseDocuments}
                                                onClickOfTableRow={this.onClickOfDocumentTableRow}
                                                onClickOfCheckbox={this.onClickOfCheckbox}
                                                onClickOfCheckboxVisibleToCustomer={this.onClickOfCheckboxVisibleToCustomer}
                                                onClickOfEmail={this.onClickOfEmail}
                                                onClickOfDelete={this.onClickOfDelete}
                                                onClickOfViewLog={this.onClickOfViewLog}
                                                onClickOfSelectAllCheckBox={this.onClickOfSelectAllCheckBox}
                                                documentsToSendAsEmail={this.state.documentsToSendAsEmail}
                                                selectAllCheckedStatus={this.state.selectAllCheckedStatus}
                                                onClickOfEmailSelected={this.onClickOfEmailSelected}
                                                isDocumentListLoading={isDocumentListLoading}
                                                isDeleteDocumentLoading={isDeleteDocumentLoading}
                                                showVisibleToCustomerPopup={this.props.showVisibleToCustomerPopup}
                                                dispatch={this.props.dispatch}
                                            />
                                        }
                                    </div>
                                }
                                {
                                    // Document details
                                    nestedComponent !== `${AppUtil.linkPaths.case.pathToRoute}/${this.props.selectedCaseData.id}${AppUtil.linkPaths.case.documents}` &&
                                    <div id="documentMain"
                                         className="section__content section__content--case_documents">
                                        <DocumentDetails
                                            {...this.props}
                                            document={this.dataSource.selectedDocument}
                                            backToScreen={this.gotoDocumentsOverview}
                                            onClickOfViewLog={this.onClickOfViewLog}
                                            onClickOfEmail={this.onClickOfEmail}
                                            onClickOfDelete={this.onClickOfDelete}
                                            downloadDocument={this.downloadDocument}
                                            downloadDocx={this.downloadDocx}
                                            options={this.props.caseDocumentTypes}
                                            updateDocumentDetails={this.gotoDocumentDetails}
                                            updateDocumentOverview={this.updateCaseDocuments}
                                            downloadingDocumentType={this.state.downloadingDocumentType}
                                        />
                                    </div>
                                }
                            </React.Fragment>
                            }
                        </div>
                        :
                        <Spinner/>
                }
                <Popup
                    openModal={this.props.openOnDocumentDelete}
                    headerInfo={Utility.getLang().infoMessages.popup.deleteDocument + `?`}
                    onConfirmation={this.onDeleteDocument}
                    closePopup={this.hidePopupOnDocumentDelete}
                    isDestructive
                />
                <PopupForEmailSend
                    openModal={this.props.openOnSend}
                    onConfirmation={this.onConfirmSend}
                    closePopup={this.hidePopupOnCancel}
                    source={'case'}
                    documentsToSendAsEmail={this.state.documentsToSendAsEmail}
                    sendCaseEmailSummary={(relativeId, data, emailTemplateType) => this.sendEmailSummary(relativeId, data, emailTemplateType)}
                    emailSubject={emailSubject}
                />
                {
                    this.state.showLogsPopup ?
                        <PopupForLogDisplay
                            openModal={this.state.showLogsPopup}
                            logData={this.state.logData}
                            closePopup={this.hidePopupOnCancel}
                            source={'case'}
                            isLoading={isDocumentViewLogLoading}
                            title={Utility.getLang().common.document.documentLog}
                        />
                        : null
                }
            </React.Fragment>
        )
    }

    downloadDocumentOrDocx(token, documentId, versionSelected, documentType) {
        const url = (documentType === DocType.document) ? ApiCollection.properties.downloadDocument : ApiCollection.properties.downloadDocx;
        this.setState({downloadingDocumentType: documentType});
        this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isLoading);
        this.downloadDocumentData(token, url, documentId, versionSelected, this.props.selectedCaseData.id).then(data => {
            if (AppUtil.isAvailable(data)) {
                if (data.success === true) {
                    bottomCenter().success(Utility.getLang().successMessages.downloadSuccessful);
                } else {
                    bottomCenter().error(data.message);
                }
            }
        }).finally(() => {
            setTimeout(() => {
                this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
            }, 1000);
            this.setState({downloadingDocumentType: DocType.none});
        });
    }
}

function mapStateToProps(state) {
    return {
        selectedCaseData: state.application.selectedCaseData,
        initialValues: state.application.selectedCaseData,
        selectedDataById: state.application.selectedDataById,
        open: state.application.open,

        openOnSend: state.application.openOnSend,
        allUserDetailsForCases: state.application.allUserDetailsForCases,
        headerMenuItemClicked: state.application.headerMenuItemClicked,
        displayNothing: state.application.displayNothing,
        caseDocuments: state.application.caseDocuments,
        caseDocumentTypes: state.application.caseDocumentTypes,
        caseUpdated: state.application.caseUpdated,
        openOnDocumentDelete: state.application.openOnDocumentDelete,
        generatedBase64Document: state.application.generatedBase64Document,
        wsLockCaseData: state.application.wsLockCaseData,
        showVisibleToCustomerPopup: state.application.showVisibleToCustomerPopup,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch),
        documentAction: bindActionCreators(documentAction, dispatch),
        dispatch: dispatch,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CaseDocuments));