import React from "react";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import connect from 'react-redux/es/connect/connect';
import DocumentPreviewManager from "../documentFilePreview/documentPreviewManager";
import AppLoader from "../documentUtil/appLoader";
import Utility from "../../../../api/utilLanguage";
import * as documentAction from "../../../../actions/documentAction";
import AppUtil from "../../../common/appUtil";
import Select from "react-select";
import Button from "../../../common/button";
import DocumentUtil from "../documentUtil/documentUtil";

class AddDocumentPreviewOverlay extends React.Component {

    static propTypes = {
        backToScreen: PropTypes.func.isRequired,
        saveBtnAction: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        //State specific to screenType "generatedNewVersion"
        this.state = {
            selectedDocumentType: null,
            freeTextNote: AppUtil.isAvailable(this.props.versionSelected) && AppUtil.isAvailable(this.props.versionSelected.freeTextNote) ? this.props.versionSelected.freeTextNote : null,
            groupSelectionOptions: [],
            isEditable: false,
            versionUrl: AppUtil.isAvailable(this.props.versionSelected) ? this.props.versionSelected.url : null,
        };
    }

    UNSAFE_componentWillMount = () => {
        const {documentScreenType} = this.props;
        if (documentScreenType === DocumentUtil.screenType.generatedNewVersion || documentScreenType === DocumentUtil.screenType.generatedEdit) {
            this.state.isEditable = documentScreenType === DocumentUtil.screenType.generatedEdit ? true : false;
            this.setupGroupSelectionOptions();
            //Set default selection option
            const defaultSelectedOption = this.getMatchedSelectedDocumentType(this.props.document);
            if (AppUtil.isAvailable(defaultSelectedOption) && defaultSelectedOption.length > 0) {
                this.updateSelectedDocumentTypeOption(defaultSelectedOption[0]);
            }
        }
    };

    /***
     * @description: Only for screenType "generatedNewVersion"
     */
    setupGroupSelectionOptions() {
        if (AppUtil.isAvailable(this.props.options) && this.props.options.length > 0) {
            let groupSelectionOptions = DocumentUtil.createGroupSelectionOptions(this.props.options, DocumentUtil.docGenerateType.generated);
            this.setState({groupSelectionOptions: groupSelectionOptions});
        } else {
            console.log("%c ", 'color: orange;font-size:12px;', Utility.getLang().common.document.categorySubCategoryDocumentTypeNotFound);
        }
    }

    updateSelectedDocumentTypeOption = (selectedOption) => {
        this.setState({selectedDocumentType: selectedOption});
    };

    getMatchedSelectedDocumentType(document) {
        return this.props.options.selectionOption(document);
    }

    onChangeOrder = (e) => {
        this.props.parentOnChangeOrder(e);
    };

    saveBtnAction = (document, selectedDocumentType, selectedOrder, freeTextNote, versionUrl, parentSaveBtnAction) => {
        parentSaveBtnAction(document, AppUtil.isAvailable(selectedOrder) ? selectedOrder.id : null, selectedDocumentType, freeTextNote, this.state.isEditable, {versionUrl: versionUrl});
    };

    updateFreeTextNote = (e) => {
        this.setState({freeTextNote: e.target.value})
    };

    /***
     * @description: Button enable disable  helper
     */
    isSaveBtnDisabled = (documentScreenType, isLoading, isSaveDocumentLoading, generatedDocument, selectedDocumentType, orders) => {
        if (documentScreenType === DocumentUtil.screenType.generatedNewVersion || documentScreenType === DocumentUtil.screenType.generatedEdit) {
            return this.isSaveBtnDisabledForDetailsType(isLoading, isSaveDocumentLoading, selectedDocumentType, orders);
        } else {
            return this.isSaveBtnDisabledForGeneratedType(isLoading, isSaveDocumentLoading, generatedDocument, orders)
        }
    };

    isSaveBtnDisabledForGeneratedType = (isLoading, isSaveDocumentLoading, generatedDocument, orders) => {
        return DocumentUtil.isWebserviceCallInProgress(isLoading, isSaveDocumentLoading) || AppUtil.isEmpty(generatedDocument) || DocumentUtil.isNoOrderCase(orders);
    };

    isSaveBtnDisabledForDetailsType = (isLoading, isSaveDocumentLoading, selectedDocumentType, orders) => {
        return DocumentUtil.isWebserviceCallInProgress(isLoading, isSaveDocumentLoading) || AppUtil.isEmpty(selectedDocumentType) || DocumentUtil.isNoOrderCase(orders);
    };

    getDocument = (documentScreenType) => {
        const {generatedDocument, document} = this.props || {};
        return (documentScreenType === DocumentUtil.screenType.generatedNewVersion || documentScreenType === DocumentUtil.screenType.generatedEdit) ? document : generatedDocument;
    };

    getSelectedDocumentType = (documentScreenType) => {
        const {selectedDocumentType} = this.props || {};
        return (documentScreenType === DocumentUtil.screenType.generatedNewVersion || documentScreenType === DocumentUtil.screenType.generatedEdit) ? this.state.selectedDocumentType : selectedDocumentType;
    };

    render() {
        const {
            orders,
            backToScreen,
            stateParam,
            generatedDocument,
            documentScreenType,
            parentSaveBtnAction
        } = this.props || {};
        const {selectedOrder, responseBase64Document} = stateParam || {};
        const {isLoading, isSaveDocumentLoading} = stateParam;

        const selectedDocumentType = this.getSelectedDocumentType(documentScreenType);
        const isSaveBtnDisabled = this.isSaveBtnDisabled(documentScreenType, isLoading, isSaveDocumentLoading, generatedDocument, selectedDocumentType, orders);

        const isOrderOptionDisabled = (documentScreenType === DocumentUtil.screenType.generatedNewVersion || documentScreenType === DocumentUtil.screenType.generatedEdit);
        const document = this.getDocument(documentScreenType);
        const isSelectionOptionDisabled = true;
        const {groupSelectionOptions, freeTextNote} = this.state;

        return (
            <React.Fragment>
                <div className="overlay overlay--temp">
                    <div className="overlay__toolbar">
                        <div className="inner">
                            {(AppUtil.isAvailable(orders) && orders.length > 0) &&
                            <div className="btn-toolbar btn-toolbar--nowrap btn-toolbar--left">
                                {
                                    (documentScreenType === DocumentUtil.screenType.generatedNewVersion || documentScreenType === DocumentUtil.screenType.generatedEdit) &&
                                    <div className="form-group">
                                        <div className="field field--large">
                                            <label htmlFor="caseDocumentIdType">
                                                {Utility.getLang().common.document.documentType}
                                            </label>
                                            <Select
                                                inputId="caseDocumentIdType"
                                                options={groupSelectionOptions}
                                                placeholder={Utility.getLang().common.dropDownOptionPleaseSelect}
                                                formatCreateLabel=""
                                                className={"multiselect multiselect--max_width"}
                                                classNamePrefix={"multiselect"}
                                                noOptionsMessage={() => Utility.getLang().common.noOptionMessage}
                                                isDisabled={isSelectionOptionDisabled}
                                                value={selectedDocumentType}
                                            />
                                        </div>
                                    </div>
                                }
                                <div className="form-group">
                                    <div className="field field--large">
                                        <label htmlFor="caseDocumentOrderId">
                                            {Utility.getLang().common.document.order}
                                        </label>
                                        <Select
                                            inputId="caseDocumentOrderId"
                                            getOptionLabel={order => {
                                                return `${order.orderTypeText} - ${order.id}`
                                            }}
                                            getOptionValue={({id}) => id}
                                            options={orders}
                                            value={selectedOrder}
                                            placeholder={Utility.getLang().common.dropDownOptionPleaseSelect}
                                            formatCreateLabel=""
                                            className={"multiselect multiselect--max_width"}
                                            classNamePrefix={"multiselect"}
                                            noOptionsMessage={() => Utility.getLang().common.noOptionMessage}
                                            onChange={this.onChangeOrder}
                                            isDisabled={isOrderOptionDisabled}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="field field--large">
                                        <label htmlFor="caseDocumentNote">
                                            {Utility.getLang().warningMessages.call.addNotes}
                                        </label>
                                        <input
                                            name="caseDocumentNote"
                                            id="caseDocumentNote"
                                            placeholder={Utility.getLang().warningMessages.call.addNotes}
                                            className={"text text--max_width"}
                                            onChange={this.updateFreeTextNote}
                                            value={freeTextNote}
                                            type="text"
                                        />
                                    </div>
                                </div>
                            </div>
                            }
                            <div className="btn-toolbar btn-toolbar--right btn-toolbar--header">
                                <Button onClick={backToScreen}>
                                    {Utility.getLang().common.document.cancel}
                                </Button>
                                <Button
                                    isPrimary
                                    onClick={() => this.saveBtnAction(document, selectedDocumentType, selectedOrder, freeTextNote, this.state.versionUrl, parentSaveBtnAction)}
                                    disabled={isSaveBtnDisabled}
                                >
                                    {Utility.getLang().common.document.save}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <DocumentPreviewManager
                        responseBase64Document={responseBase64Document}
                        divComponent={<AppLoader spinnerStyle="white" onRef={this.props.onRef}/>}
                        isLoading={isSaveDocumentLoading || isLoading}
                        className="file_preview--overlay"
                    />
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {
        documentAction: bindActionCreators(documentAction, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddDocumentPreviewOverlay);
