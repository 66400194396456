import RefreshToken from "../api/validateToken";
import {afterRefreshToken} from "./uiAction";
import {push} from "react-router-redux";
import CommonApiCall from "../api/commonApi";
import ApiCollection from "../api/apiCollections";
import {getApiToken} from "../components/common/localStorageUtil";
import AppUtil from "../components/common/appUtil";
import {cloneDeep} from "lodash";
import CaseUtil from "../components/common/caseUtil";
import OrderUtil from "../components/common/orderUtil";
import {
    CHANGE_BILLING_STATUS_FOR_ORDER_INFO,
    CHANGE_CEREMONY_INSTRUCTION_FOR_ORDER_INFO,
    CHANGE_STAGE_FOR_ORDER_INFO,
    SET_CASE_ID_FOR_ORDER_TAB_ONLY
} from "../components/common/orderActionTypes";
import {updateColumnPageDataBySearch} from "../components/common/masterDetailView/column/redux/columnAction";
import {CRUDOperation} from "../components/common/masterDetailView/column/redux/types";

export function updateOrderInfoApiCall(reqOrder, storingKey, isColumnWithUniqueStage) {
    return function (dispatch) {
        let copyOfOrder = cloneDeep(reqOrder);
        reqOrder = CaseUtil.omitParametersFromOrder(reqOrder);
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CommonApiCall.updateDataById_Axios(newData.idToken.jwtToken, ApiCollection.properties.orderSourceUrl, reqOrder).then(responseOrder => {
                        dispatch(updateColumnPageDataBySearch(reqOrder.id, responseOrder, CRUDOperation.UPDATE, storingKey, {isColumnWithUniqueStage: isColumnWithUniqueStage}));
                        return dispatch(updateOrderDetails(responseOrder, copyOfOrder));
                    }).catch(error => {
                        return dispatch(onErrorUnderOrder(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CommonApiCall.updateDataById_Axios(getApiToken(), ApiCollection.properties.orderSourceUrl, reqOrder).then(responseOrder => {
                    dispatch(updateColumnPageDataBySearch(reqOrder.id, responseOrder, CRUDOperation.UPDATE, storingKey, {isColumnWithUniqueStage: isColumnWithUniqueStage}));
                    return dispatch(updateOrderDetails(responseOrder, copyOfOrder));
                }).catch(error => {
                    return dispatch(onErrorUnderOrder(error));
                });
            }, 0);
        }
    }
}

export const UPDATE_ORDER_DETAILS = 'UPDATE_ORDER_DETAILS';

export function updateOrderDetails(payload, oldPayload) {
    return {type: UPDATE_ORDER_DETAILS, payload, oldPayload};
}

export function onErrorUnderOrder(data) {
    return {type: 'ON_ERROR', data};
}

export function updateOrderUpdatingStatus(data) {
    return {type: 'UPDATE_ORDER_UPDATING_STATUS', data};
}

function getOrderStagesCommon(token, dispatch) {
    return (CommonApiCall.fetch_All_Data(token, ApiCollection.properties.fetchOrderStage).then(data => {
        dispatch(fetchedAllStatus(data));
        return new Promise((resolve, reject) => setTimeout(() => resolve(data), 0));
    }).catch(error => {
        dispatch(onErrorUnderOrder(error));
        return Promise.reject(error);
    }));
}

export function getOrderStagesApi() {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            return (RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                return getOrderStagesCommon(newData.idToken.jwtToken, dispatch);
            }).catch(err => {
                dispatch(push('/'));
                return Promise.reject(err);
            }));
        } else {
            return getOrderStagesCommon(getApiToken(), dispatch);
        }
    }

}

export function fetchedAllStatus(data) {
    return {type: 'FETCHED_ALL_STATUS_FOR_ORDER', data};
}

export function deleteOrderInfoApiCall(deletedData, storingKey) {
    return function (dispatch) {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CommonApiCall.deleteItem_Axios(newData.idToken.jwtToken, ApiCollection.properties.orderSourceUrl, deletedData.id).then(respData => {
                        dispatch(updateColumnPageDataBySearch(deletedData.id, respData, CRUDOperation.DELETE, storingKey));
                        return dispatch(orderDeletedSuccessfully(respData, deletedData));
                    }).catch(error => {
                        return dispatch(onErrorUnderOrder(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CommonApiCall.deleteItem_Axios(getApiToken(), ApiCollection.properties.orderSourceUrl, deletedData.id).then(respData => {
                    dispatch(updateColumnPageDataBySearch(deletedData.id, respData, CRUDOperation.DELETE, storingKey));
                    return dispatch(orderDeletedSuccessfully(respData, deletedData));
                }).catch(error => {
                    return dispatch(onErrorUnderOrder(error));
                });
            }, 0);
        }
    }
}

export const ORDER_DELETED_SUCCESSFULLY = 'ORDER_DELETED_SUCCESSFULLY';

export function orderDeletedSuccessfully(payload, deletedData) {
    return {type: ORDER_DELETED_SUCCESSFULLY, payload, deletedData};
}

export function changeStageForOrderInfo(data, source = OrderUtil.ORDER_DEFAULT_SOURCE, index) {
    return {type: CHANGE_STAGE_FOR_ORDER_INFO, data, source, index};
}

export function changeCeremonyInstructionForOrder(data, source, index) {
    return {type: CHANGE_CEREMONY_INSTRUCTION_FOR_ORDER_INFO, data, source, index};
}

export function changeStageForOrderFromPopup(data, index) {
    return {type: 'CHANGE_STAGE_FOR_ORDER_FROM_POPUP', data, index};
}

export function setOrderDataToSendFromPopup(data, assignedUserId) {
    return {type: 'SET_ORDER_DATA_TO_SEND_FROM_POPUP', data, assignedUserId};
}

export const SET_COLLABORATOR = 'SET_COLLABORATOR';

export function setCollaborator(data) {
    return {type: SET_COLLABORATOR, data};
}

export function changeBillingContactForOrderFromPopup(data, index) {
    return {type: 'CHANGE_BILLING_CONTACT_FOR_ORDER_FROM_POPUP', data, index};
}

export function changeBillingContactForOrderFromPopupOnDrag(data, index) {
    return {type: 'CHANGE_BILLING_CONTACT_FOR_ORDER_FROM_POPUP_ON_DRAG', data, index};
}

export function changeDeliveryMethodForOrderFromPopup(data, index) {
    return {type: 'CHANGE_DELIVERY_METHOD_FOR_ORDER_FROM_POPUP', data, index};
}

export function changeDeliveryMethodForOrderFromPopupOnDrag(data, index) {
    return {type: 'CHANGE_DELIVERY_METHOD_FOR_ORDER_FROM_POPUP_ON_DRAG', data, index};
}

export function setCaseIdForOrderTabOnly(data) {
    return {type: SET_CASE_ID_FOR_ORDER_TAB_ONLY, data};
}

export function addLabelToStateForOrder(data) {
    return {type: 'ADD_LABEL_TO_STATE_FOR_ORDER', data};
}

export function removelabelFormOrder(data, index) {
    return {type: 'REMOVE_LABEL_FROM_ORDER', data, index};
}

export function changeBillingStatusForOrderInfo(data) {
    return {type: CHANGE_BILLING_STATUS_FOR_ORDER_INFO, data};
}

export function createNotesforOrder(data) {
    return {type: 'CREATE_NOTES_FOR_ORDER', data};
}

export function saveTempNote(data) {
    return {type: 'SAVE_TEMP_NOTE', data};
}

export function removenotesitemfromOrder(data, index) {
    return {type: 'REMOVE_NOTES_ITEM_FROM_ORDER', data, index};
}

export function fetchFuneralPackage(data, orderType, orderIndex, orderFrom) {
    return {type: 'UPGRADE_FUNERAL_PACKAGE', data, orderType, orderIndex, orderFrom};
}

export function upgradeFuneralPackage(orderID, orderType, orderIndex, orderFrom) {
    const urlSuffix = ApiCollection.properties.upgradeFuneralPackage.replace("{orderId}", orderID);
    return (dispatch) => {
        if (AppUtil.jwtTokenIsExpired()) {
            RefreshToken.validateRefreshToken().then(newData => {
                dispatch(afterRefreshToken(newData));
                setTimeout(function () {
                    CommonApiCall.fetchFuneralPackage(newData.idToken.jwtToken, urlSuffix).then(data => {
                        return dispatch(fetchFuneralPackage(data, orderType, orderIndex, orderFrom));
                    }).catch(error => {
                        return dispatch(onErrorUnderCase(error));
                    });
                }, 0);
            }).catch(err => {
                dispatch(push('/'));
            })
        } else {
            setTimeout(function () {
                CommonApiCall.fetchFuneralPackage(getApiToken(), urlSuffix).then(data => {
                    return dispatch(fetchFuneralPackage(data, orderType, orderIndex, orderFrom));
                }).catch(error => {
                    return dispatch(onErrorUnderCase(error));
                });
            }, 0);
        }
    }
}
