import DocumentAccessView from "../../../util/documentAccessView";
import Button from "../../../../../common/button";
import {ButtonId, NoteType} from "../../../../../common/sidePanel/util/noteUtil";
import Utility from "../../../../../../api/utilLanguage";
import React from "react";
import useProbateUtil from "../../../util/useProbateUtil";
import {EditButton} from "../../../../lockCase/ui/banner/bannerButton";

const ProbateNoteFooter = ({
                               note,
                               index,
                               removeNotes,
                               noteType,
                               caseId,
                               onClickShowDocumentHandlerPopup,
                               noteRowProps,
                               isEditMode,
                               oldNote,
                           }) => {

    const {documentType} = useProbateUtil();
    const {onEditClick, onSaveClick, onCancelClick} = noteRowProps;
    return <>
        <div className={`timestamp btn-toolbar notes--doc-upload`}>
            <DocumentAccessView linkableId={note ? note.documentId : null}
                                caseId={caseId}
                                onClick={() => onClickShowDocumentHandlerPopup(documentType.probateNote, note, index)}
            />
        </div>
        <div className="right btn-toolbar">
            {
                isEditMode && note && isEditMode(note, index)
                    ? <SaveCancelButton onCancelClick={() => onCancelClick(index, oldNote?.id, oldNote?.note)}
                                        onSaveClick={(e) => onSaveClick(e, note?.id, index, note?.note)}
                                        isLoading={false}
                    />
                    : <EditButton title={Utility.getLang().cases.memorial.emails.edit}
                                  onClick={() => onEditClick(note, index)}
                                  disabled={false}
                                  tooltip={Utility.getLang().cases.memorial.emails.editTooltip}
                                  className={"email_subscription__meta__button is-primary-action"}
                    />
            }

            {/* Delete note with confirmation dialog */}
            <Button
                isTiny={noteType === NoteType.sidePanel}
                isTertiary
                isDestructive
                noLabel={noteType === NoteType.sidePanel}
                tooltipAlignRight
                tooltip={Utility.getLang().cases.notes.deleteNoteTooltip}
                onClick={() => removeNotes(note, index)}
            >
                {noteType ? Utility.getLang().common.remove : ""}
            </Button>
        </div>
    </>
}

const SaveCancelButton = ({onCancelClick, onSaveClick, isLoading}) => {
    return <>
        <Button id={ButtonId.CANCEL_PROBATE_NOTE}
                isSecondary
                onClick={onCancelClick}
                disabled={isLoading}>
            {Utility.getLang().common.cancel}
        </Button>
        <Button isPrimary
                onClick={onSaveClick}
                disabled={isLoading}>
            {Utility.getLang().common.save}
        </Button>
    </>
}

export default ProbateNoteFooter;