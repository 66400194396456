import React from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import {bindActionCreators} from "redux";
import * as actionMaster from "../../actions/uiAction";
import * as caseAction from "../../actions/caseAction";
import * as callAction from "../../actions/callAction";
import {reduxForm} from "redux-form";
import {withRouter} from "react-router-dom";
import {bottomCenter} from "../common/customToastr";
import Popup from "../common/popups/popup";
import FooterWithSave from "../common/footer";
import Utility from "../../api/utilLanguage";
import Permalink from "../common/link/permalink";
import Icon from "../common/icon";
import DisplayNothing from "../common/nothingToDisplay";
import AppUtil from "../common/appUtil";
import CallColumnUtil from "../common/callColumnUtil";
import Button from "../common/button";
import {NoteUtil} from "../common/sidePanel/util/noteUtil";

class CallNotesComponent extends React.Component {

    constructor(props) {
        super(props);
        this.disableSave = false;
        this.state = {
            noteText: '',
            disableSave: false,
            notesValue: ""
        }
    }

    componentDidUpdate = () => {
        this.shouldShowConfirmationAlert();
    };

    updateNotes(nextProps) {
        this.setState({
            notesValue: AppUtil.isAvailable(nextProps.tempNote) ?
                nextProps.tempNote : ''
        });
    }

    shouldShowConfirmationAlert = () => {
        if (this.props.selectedDataById.id !== undefined && this.props.selectedCallData.id !== undefined) {
            const initialDataStr = JSON.stringify(this.props.selectedDataById);
            const newDataStr = JSON.stringify(this.props.selectedCallData);
            (initialDataStr === newDataStr) ? this.props.actionMaster.hideUnsavedPopupLazily() : this.props.actionMaster.showUnsavedPopupLazily();
        }
        if (AppUtil.isAvailable(this.props.tempNote)) {
            this.props.actionMaster.showUnsavedPopupLazily();
        }
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.callUpdated !== undefined && (nextProps.callUpdated !== this.props.callUpdated)) {
            if (nextProps.callUpdated === true) {
                this.disableSave = false;
                this.setState({disableSave: false});
            }
        }
        this.updateNotes(nextProps);
    };

    currentRoute = () => {
        this.props.actionMaster.onHeaderMenuItemClicked(this.props.history.location.pathname);
        this.props.actionMaster.closePopupWithSameRoute();
    };

    toMoveNewRoute = () => {
        this.props.actionMaster.clearRouteCondition();
        if (this.props.headerMenuItemClicked !== this.props.history.location.pathname) {
            this.props.history.push(this.props.headerMenuItemClicked);
        }
    };

    createNotes = () => {
        if (this.state.notesValue !== "" && this.state.notesValue !== undefined) {
            const data = NoteUtil.newNote(this.state.notesValue);
            this.props.callAction.createNotesforCall(data);
            this.props.callAction.saveTempNote("");
            setTimeout(function () {
                this.props.destroy();
            }.bind(this), 0)
        } else {
            bottomCenter().warning(Utility.getLang().warningMessages.call.addNotes);
        }
    };

    removeNotes = (notes, i) => {
        this.props.destroy();
        this.props.callAction.removenotesitemfromcall(notes, i);
    };

    onSaveCall = () => {
        this.disableSave = true;
        this.props.callAction.updateCallUpdatingStatus(false);
        this.setState({disableSave: true});
        let data = {};
        data = this.props.selectedCallData;
        this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isUpdating);
        setTimeout(function () {
            data = CallColumnUtil.getUpdatedNotes(this.props.tempNote, data);
            this.props.callAction.saveCallDataApi(data, this.props.storingKey);
            setTimeout(function () {
                this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
            }.bind(this), 1000)
        }.bind(this), 0);
    };

    onDeleteClick = () => {
        this.props.actionMaster.showPopup();
    };

    hidePopup = () => {
        this.props.actionMaster.hidePopupSection();
    };

    onDelete = () => {
        this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isDeleting);
        this.props.callAction.deleteCallDataApi(this.props.selectedCallData, this.props.storingKey);
        setTimeout(function () {
            this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
        }.bind(this), 1000);
        this.hidePopup();
    };

    changeValue = (e) => {
        this.setState({notesValue: e.target.value});
    };

    setTempNoteData = (value) => {
        this.props.callAction.saveTempNote(value);
    };

    resetNote = () => {
        this.setState({notesValue: ""});
        this.props.callAction.saveTempNote("");
    };

    checkKeys = (e) => {
        if (e.keyCode === 13 && e.ctrlKey === true) {
            this.createNotes();
        }
    };

    render = () => {
        const {reset, handleSubmit} = this.props;
        const handleEnterKeyPress = (el) => {
            this.el = el;
        };
        return (
            <React.Fragment>
                <Popup openModal={this.props.showUnsavedPopup}
                       headerInfo={Utility.getLang().infoMessages.popup.unsavedChanges}
                       onConfirmation={this.toMoveNewRoute}
                       closePopup={this.currentRoute}/>
                {
                    this.props.displayNothing === false ?
                        <div className="section section--detail section--scroll" ref={node => this.node = node}>
                            <div className="section__head">
                                <div className="inner">
                                    <h3 className="section__title">
                                        <Icon type="call"/>
                                        <span className="description">
                                    {
                                        this.props.selectedCallData.labels !== undefined ?
                                            this.props.selectedCallData.labels.map((label, i) => {
                                                var capitalizedLabelTitle = label.title.charAt(0).toUpperCase() + label.title.slice(1);
                                                return i === 0 ? capitalizedLabelTitle : ", " + capitalizedLabelTitle;
                                            }) : null
                                    }

                                </span>
                                        <Permalink id={this.props.selectedCallData.id}
                                                   path1={AppUtil.linkPaths.call.basePath}
                                                   path2={AppUtil.linkPaths.call.details}
                                        />
                                    </h3>
                                </div>
                            </div>
                            {
                                this.props.selectedCallData !== undefined && this.props.selectedCallData.id !== undefined ?
                                    <div className="section__content section__content--case_notes">
                                        <div className="inner">
                                            <form onSubmit={handleSubmit(this.createNotes)} onReset={reset}>
                                                <div className="form-group">
                                                   <textarea
                                                       id="noteText"
                                                       name="noteText"
                                                       cols="40"
                                                       rows="5"
                                                       className="textarea textarea--max_width textarea--short"
                                                       onChange={this.changeValue}
                                                       onBlur={() => this.setTempNoteData(this.state.notesValue)}
                                                       onKeyDown={(e) => this.checkKeys(e)}
                                                       value={this.state.notesValue}
                                                   />
                                                    <div className="help_text">
                                                        {Utility.getLang().cases.notesTabContent.shortcutAddNote}
                                                    </div>
                                                </div>
                                                <div className="btn-toolbar">
                                                    <Button
                                                        ref={handleEnterKeyPress}
                                                        isPrimary
                                                        type="submit"
                                                    >
                                                        {Utility.getLang().calls.notesTabContent.addNote}
                                                    </Button>
                                                    <Button
                                                        onClick={this.resetNote}
                                                        type="reset"
                                                        disabled={this.state.notesValue === ''}
                                                    >
                                                        {Utility.getLang().calls.notesTabContent.reset}
                                                    </Button>
                                                </div>
                                            </form>
                                        </div>
                                        {
                                            this.props.selectedCallData.notes.length > 0 && this.props.selectedCallData.notes !== undefined && this.props.selectedCallData.notes !== null ?
                                                <div className="drawer">
                                                    {this.props.selectedCallData.notes.map((notes, i) => {
                                                        return (
                                                            <div className="card card--note">
                                                                <div className="content">
                                                                    <span className="user">
                                                                        <span className="avatar avatar--16">
                                                                            <Icon type="avatar" tiny/>
                                                                        </span>
                                                                        {notes.userName}
                                                                    </span>
                                                                    {notes.note}
                                                                </div>
                                                                <span className="meta meta--timestamp">
                                                                    {notes.createdText}
                                                                </span>
                                                                <a
                                                                    className="link is-destructive"
                                                                    onClick={(e) => this.removeNotes(notes, i)}
                                                                >
                                                                    <Icon type="trash" small/>
                                                                    <span>
                                                                        {Utility.getLang().common.remove}
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                :
                                                <div className="drawer">
                                                    <DisplayNothing/>
                                                </div>
                                        }
                                    </div>
                                    :
                                    <DisplayNothing/>
                            }
                        </div>
                        :
                        <DisplayNothing/>
                }

                <Popup
                    openModal={this.props.open}
                    headerInfo={Utility.getLang().infoMessages.popup.deleteCall + ` ` + (this.props.selectedCallData.id !== undefined ? this.props.selectedCallData.id : "---") + `?`}
                    onConfirmation={this.onDelete}
                    closePopup={this.hidePopup}
                    isDestructive
                    withTimeOut
                />
                <FooterWithSave SaveData={this.onSaveCall}
                                buttonText={Utility.getLang().calls.footer.save}
                                disableSaveButton={this.disableSave}
                                deleteButtonText={Utility.getLang().calls.footer.delete}
                                deleteCase={this.onDeleteClick}
                                hideRelativeDropdown={true}/>
            </React.Fragment>
        )
    }
}

CallNotesComponent.propTypes = {
    columnDetailViewTabCollection: PropTypes.array,
};

function mapStateToProps(state) {
    return {
        selectedCallData: state.application.selectedCallData,
        selectedDataById: state.application.selectedDataById,
        initialValues: state.application.selectedCallData,
        caseAllLabels: state.application.caseAllLabels,
        allUserDetailsForCases: state.application.allUserDetailsForCases,
        open: state.application.open,
        showUnsavedPopup: state.application.showUnsavedPopup,
        headerMenuItemClicked: state.application.headerMenuItemClicked,
        displayNothing: state.application.displayNothing,
        callUpdated: state.application.callUpdated,
        tempNote: state.application.tempNote
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        callAction: bindActionCreators(callAction, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: "CallNotesComponent"
})(withRouter(CallNotesComponent)));
