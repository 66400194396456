import config from "./config";

export default {
    properties: {
        configureCognitoUI: 'api/v1/user/configuration/cognito/ui',
        fetchAllUserUrl: config.userServiceBaseURL + 'api/v1/user/list?psi=0&pl=10000&sort=s_un:asc',
        userSourceUrl: config.userServiceBaseURL + 'api/v1/user/',
        fetchAllRoleUrl: config.userServiceBaseURL + 'api/v1/role',
        fetchVendors: config.baseURL + 'api/v1/vendor',
        caseSourceUrl: config.baseURL + 'api/v1/case/',
        callSourceUrl: config.baseURL + 'api/v1/case/call/',
        orderSourceUrl: config.baseURL + 'api/v1/order/',
        productSourceUrl: config.baseURL + 'api/v1/product/',
        locationSourceUrl: config.baseURL + 'api/v1/location/',
        categorySourceUrl: config.baseURL + 'api/v1/productcategory/',
        fetchAllLabelUrl: config.baseURL + 'api/v1/case/label/list?psi=0&pl=100&sort=s_lt:asc&f_ltp={labelType}',
        fetchAllProductTypesUrl: config.baseURL + 'api/v1/product/producttypes',
        fetchAllChildProductUrl: config.baseURL + 'api/v1/product/list?psi=' + 0 + '&pl=' + 1000 + '&f_pid=' + 16,
        updateProductMediaUrl: config.baseURL + 'api/v1/product/productmedia/{productId}?altText={altText}&isDefault={isDefault}',
        fetchOrderStage: config.baseURL + 'api/v1/order/stages',
        fetchAllLocationTypesUrl: config.baseURL + 'api/v1/location/locationtypes',
        updateLocationMediaUrl: config.baseURL + 'api/v1/location/locationmedia/{locationId}?altText={altText}&isDefault={isDefault}',
        fetchPerson: config.baseURL + 'api/v1/person/',
        fetchPersonTypes: config.baseURL + 'api/v1/person/types',
        updatePersonMediaUrl: config.baseURL + 'api/v1/person/media/{personId}',
        fetchCeremonyTypes: config.baseURL + 'api/v1/location/ceremonytypes',
        fetchCategoryAccToEvent: config.baseURL + 'api/v1/productcategory/list?psi=0&pl=1000&f_pcet=',
        fetchBurialTypes: config.baseURL + 'api/v1/location/burialtypes',
        fetchCaseStage: config.baseURL + 'api/v1/case/stages',
        fetchCaseLogs: config.baseURL + 'api/v1/logs/case/',
        fetchLogs: config.baseURL + 'api/v1/logs/{logCategory}/{logCategoryId}/list?psi={startIndex}&pl={pageLimit}',
        fetchCaseEvents: config.baseURL + 'api/v1/logs/case/{caseId}/events',
        fetchCaseAuthors: config.baseURL + 'api/v1/logs/case/{caseId}/authors',
        fetchOrderLogs: config.baseURL + 'api/v1/logs/order/',
        fetchPersonRelationship: config.baseURL + 'api/v1/case/person/relationship',
        fetchFromSpar: config.baseURL + 'api/v1/spar/personsokning/pn/',
        fetchFromSparPersonalNumber: config.baseURL + 'api/v1/spar/personsokning/pn/{pn}',
        fetchCallLogs: config.baseURL + 'api/v1/logs/call/',
        fetchRelativeForCall: config.baseURL + `api/v1/case/'${0}'/relatives`,
        fetchDummyJsonForOrder: config.baseURL + 'api/v1/order/emptyorderforordertype/{orderTypeId}/?defaultproducts=true',
        fetchDummyJsonForEvent: config.baseURL + 'api/v1/order/emptyeventforeventtype/',
        fetchFoodRestrictions: config.baseURL + 'api/v1/case/person/foodrestrictions',
        fetchDocumentsLogs: config.baseURL + 'api/v1/logs/document',
        deleteCaseDocument: config.baseURL + 'api/v1/case/{caseId}/document/',
        downloadDocument: config.baseURL + 'api/v1/case/{caseId}/document/{documentId}/version/{versionId}/base64',
        downloadDocx: config.baseURL + 'api/v1/case/{caseId}/document/{documentId}/version/{versionId}/docx',
        fetchCaseTodoTemplates: config.baseURL + 'api/v1/case/todotemplate?f_tdty={todoTemplateType}',
        fetchCaseTodoTemplateItems: config.baseURL + 'api/v1/case/{caseId}/template/{id}',
        fetchOrderPreparationMemorialFunds: config.baseURL + 'api/v1/order/memorialfunds',
        sendGuestMail: config.baseURL + 'api/v1/case/{caseId}/sendguestmail/{guestId}',
        lostReasons: config.baseURL + 'api/v1/case/lostreasons',
        noCaseReasons: config.baseURL + 'api/v1/case/nocasereasons',
        orderTypes: config.baseURL + 'api/v1/order/ordertypes',
        productsForCategory: config.baseURL + 'api/v1/product/list?f_pc={categoryId}&f_pt={packageType}&f_ps=' + 1 + '&f_pid=' + 1 + '&f_pid=' + 8 + '&f_pid=' + 32 + '&f_pid=' + 4 + '&psi=0&pl=1000',
        locationSearchUrl: config.baseURL + 'api/v1/location/list/simple?ues=true&psi=0&pl=100&sort=s_ln:asc&f_lid={id}&f_tx={input}',
        fetchOrderEventAttributeSubTypesData: config.baseURL + 'api/v1/order/attributesubtype/{orderType}/{eventType}/{attributeType}',
        fetchValidationRules: config.baseURL + 'api/v1/case/getvalidationrules',
        fetchOrderTypeEventTypesForProductCategory: config.baseURL + 'api/v1/order/ordertypeeventtypes',
        fetchCountries: config.baseURL + 'api/v1/case/country',
        fetchCounties: config.baseURL + 'api/v1/location/county/{parentId}',
        fetchThirdPartySettings: 'api/v1/case/thirdpartysettings',
        caseCloseById: 'api/v1/case/{caseId}/close',
        caseCloseOnBrowserExit: 'api/v1/case/{caseId}/closeonbrowserexit',
        caseTakeOverById: 'api/v1/case/{caseId}/takeover',
        sendCommissionReport: config.baseURL + 'api/v1/case/commissionReports/resendreport?dateOfMonth={dateOfMonth}',
        fetchBoroughs: config.baseURL + "api/v1/location/place/type/64",
        websocketHubUrl: config.baseURL + "ws/v1/notification",
        fetchAttributeSubtype: 'api/v1/order/attributesubtype',
        fetchOrderDeliveryMethods: 'api/v1/order/deliverymethods',
        fetchMemorialById: config.baseURL + 'api/v1/memorial/{memorialId}',
        updateOrUploadMemorialMedia: config.baseURL + 'api/v1/memorial/{memorialId}/media',
        defaultMemorialData: config.baseURL + 'api/v1/memorial/data/default',
        updateObituary: config.baseURL + 'api/v1/memorial/{memorialId}/obituary',
        updateMemory: config.baseURL + '/api/v1/memorial/{memorialId}/memory/{memoryId}',
        deleteMemory: config.baseURL + '/api/v1/memorial/memory/{memoryId}',
        deleteSubscription: config.baseURL + 'api/v1/memorial/{memorialId}/subscribe/{subscriberId}',
        updateSubscription: config.baseURL + 'api/v1/memorial/{memorialId}/subscribe',
        addSubscription: config.baseURL + 'api/v1/memorial/{memorialId}/subscribe',
        publishMemorial: config.baseURL + 'api/v1/memorial/{memorialId}/publish',
        unPublishMemorial: config.baseURL + 'api/v1/memorial/{memorialId}/unpublish',
        countersById: config.baseURL + 'api/v1/{object}/{objectId}/counters',
        updateStage: config.baseURL + 'api/v1/{object}/{objectId}/stage?stageId={newStageId}',
        fetchCelebrantContactsOptions: config.baseURL + 'api/v1/case/{caseId}/celebrantcontacts',
        fetchChurchServiceContactsOptions: config.baseURL + 'api/v1/case/{caseId}/churchservicecontacts',
        fetchProductCategoriesByOrderType: config.baseURL + 'api/v1/order/categoriesgroupedbyeventtypes/{orderType}/{eventType}',
        fetchRelatedCases: config.baseURL + 'api/v1/case/{caseId}/relatedcases',
        addMessageByOrderId: config.baseURL + '/api/v1/message/order/{orderId}/thread',
        fetchOrderMessagesList: config.baseURL + '/api/v1/message/order/{orderId}/thread?seen=true',
        editMessage: config.baseURL + '/api/v1/message/{messageId}',
        deleteMessage: config.baseURL + '/api/v1/message/{messageId}',
        fetchOrderList: config.baseURL + '/api/v1/message/case/{caseId}/thread/list',
        updateContactAccessLevel: config.baseURL + 'api/v1/order/{Id}/share/{caseRelativeId}',
        documentVisibleToCustomer: config.baseURL + 'api/v1/case/{caseId}/document/{documentId}/visibletocustomer/{visibleToCustomer}',
        fetchCaseDocuments: config.baseURL + 'api/v1/case/{caseId}/document/list',
        fetchDocumentTypes: config.baseURL + "api/v1/case/documenttypes",
        fetchBase64Document: config.baseURL + 'api/v1/case/{caseId}/document/{documentId}/base64',
        updatedDocument: config.baseURL + 'api/v1/case/{caseId}/document/{documentId}',
        uploadDocument: config.baseURL + 'api/v1/case/{caseId}/document/upload',
        fetchProbateInfo: config.baseURL + 'api/v1/probate/{probateId}',
        createProbate: '/api/v1/probate/{sourceCaseId}/probate',
        updateProbateInfo: config.baseURL + 'api/v1/probate/{probateId}',
        fetchProbateEnums: config.baseURL + 'api/v1/probate/probateenums',
        fetchDocumentsByType: config.baseURL + 'api/v1/case/{caseId}/document/list?documenttype={documentTypeId}',
        uploadDocumentByType: config.baseURL + 'api/v1/case/{caseId}/document/upload?documenttype={documentTypeId}&isprobate={isProbate}',
        fetchFuneralOrderTotal: config.baseURL + 'api/v1/probate/{probateId}/ordertotal',
        fetchFuneralPackage: config.baseURL + 'api/v1/order/funeralpackage',
        upgradeFuneralPackage: config.baseURL + 'api/v1/order/{orderId}/upgrade',
        downloadProbateZip: config.baseURL + 'api/v1/probate/{probateId}/zip',
        fetchProbateCommonNotes: config.baseURL + 'api/v1/probate/probatenotes',
        dashboardList: config.baseURL + 'api/v1/case/list?view=dashboard&ues=true&psi={psi}&pl={pl}&f_ds={f_ds}&f_dl={f_dl}&sort={sort}',
        customerProfile: config.baseURL + 'api/v1/customer/{CUSTOMER_ID}/profile',
        customerPackages: config.baseURL + 'api/v1/customer/{CUSTOMER_ID}/package',
        fetchOrderPreparationTransporters: config.baseURL + 'api/v1/order/transporters',
        fetchExistingProbateCase: config.baseURL + 'api/v1/case/list/simple?pl=100&f_tx={INPUT}&f_pc=true&sort=s_cd:desc',
        connectExistingProbateCase: config.baseURL + 'api/v1/case/{CASE_ID}/connect/{PROBATE_CASE_ID}',
    }
};
