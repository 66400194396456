import ApiCollection from "../../../../api/apiCollections";
import {getApiToken} from "../../../common/localStorageUtil";
import AxiosErrorUtil from "../../../common/axios/axiosErrorUtil";
import {api} from "../../../common/axios/apiCallerInterceptor";

export const updateDocumentVisibleToCustomerApi = async (documentId, caseId, checked) => {
    const url = ApiCollection.properties.documentVisibleToCustomer.replace("{caseId}", caseId).replace("{documentId}", documentId).replace("{visibleToCustomer}", checked);
    try {
        const config = {headers: {'Authorization': `Bearer ${getApiToken()}`}};
        const response = await api.put(url, null, config);
        AxiosErrorUtil.failureResponseReceived(response);
        return response;
    } catch (error) {
        throw error
    }
};