import React from "react";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as actionMaster from "../../actions/uiAction";
import * as caseAction from "../../actions/caseAction";
import * as caseOrderAction from "../../actions/caseOrderAction";
import {reduxForm} from "redux-form";
import {withRouter} from "react-router-dom";
import PreLoader from "../common/preloader";
import Popup from "../common/popups/popup";
import Utility from "../../api/utilLanguage";
import Icon from "../common/icon";
import FooterWithSave from "../common/footer";
import * as orderAction from "../../actions/orderAction";
import {bottomCenter} from "../common/customToastr";
import Permalink from "../common/link/permalink";
import OrderUtil from "../common/orderUtil";
import DisplayNothing from "../common/nothingToDisplay";
import AccessControl from "../../api/accessControl";
import OrderDraggingUtil from "../common/orderDraggingUtil";
import PopupForOrderStageChange from "../common/popups/popupForOrderStageChange";
import ValidationUtil from "../common/validationUtil";
import AppUtil from "../common/appUtil";
import OrderColumnUtil from "../common/orderColumnUtil";
import PopupForOrderEmail from "../common/popups/popupForOrderEmail";
import PopupAfterCaseSave from "../common/popups/popupAfterCaseSave";
import Button from "../common/button";
import {ProductTypes} from "../common/productUtil";
import {NoteUtil} from "../common/sidePanel/util/noteUtil";

class OrderNotesComponent extends React.Component {

    constructor(props) {
        super(props);
        this.showDeliveryType = false;
        this.showCaseOwner = false;
        this.sendEmailAfterSave = false;
        this.sourceId = null;
        this.destinationId = null;
        this.deliveryMethod = null;
        this.assignedId = null;
        this.billingContactId = null;
        this.caseId = null;
        this.disableSave = false;
        this.disableEmailButton = false;
        this.state = {
            disableSave: false,
            notesValue: "",
            disableEmailButton: false
        }
    }

    /**
     * Component life cycle methods
     */

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.orderUpdated !== undefined && (nextProps.orderUpdated !== this.props.orderUpdated)) {
            if (nextProps.orderUpdated === true) {
                this.disableSave = false;
                this.setState({disableSave: false});
            }
        }
        this.updateNotes(nextProps);
    };

    componentDidUpdate = () => {
        this.shouldShowConfirmationAlert();
    };

    componentDidMount = () => {
        let checkEmailDisableCondition = OrderUtil.checkEmailButtonDisableCondition(this.props.selectedDataById.stage);
        this.setState({disableEmailButton: checkEmailDisableCondition});
    };

    checkOrderChange = () => {
        this.disableEmailButton = OrderUtil.checkChangeInOrder(this.props.selectedOrderData, this.props.selectedDataById);
    };

    updateNotes(nextProps) {
        this.setState({
            notesValue: AppUtil.isAvailable(nextProps.tempNote) ?
                nextProps.tempNote : ''
        });
    }

    shouldShowConfirmationAlert = () => {
        if (this.props.selectedDataById.id !== undefined && this.props.selectedOrderData.id !== undefined) {
            const initialDataStr = JSON.stringify(this.props.selectedDataById);
            const newDataStr = JSON.stringify(this.props.selectedOrderData);
            (initialDataStr === newDataStr) ? this.props.actionMaster.hideUnsavedPopupLazily() : this.props.actionMaster.showUnsavedPopupLazily();
        }

        if (AppUtil.isAvailable(this.props.tempNote)) {
            this.props.actionMaster.showUnsavedPopupLazily();
        }
    };

    currentRoute = () => {
        this.props.actionMaster.onHeaderMenuItemClicked(this.props.history.location.pathname);
        this.props.actionMaster.closePopupWithSameRoute();
    };

    toMoveNewRoute = () => {
        this.props.actionMaster.clearRouteCondition();
        if (this.props.headerMenuItemClicked !== this.props.history.location.pathname) {
            this.props.history.push(this.props.headerMenuItemClicked);
        }
    };

    hidePopupOnCancel = () => {
        this.showDeliveryType = false;
        this.showCaseOwner = false;
        this.sendEmailAfterSave = false;
        this.props.orderAction.updateOrderUpdatingStatus(true);
        this.props.actionMaster.hidePopupSection();
    };

    hidePopupOnSave = () => {
        this.showDeliveryType = false;
        this.showCaseOwner = false;
        this.sendEmailAfterSave = false;
        this.props.actionMaster.hidePopupSection();
    };

    onSaveClick = () => {
        this.disableSave = true;
        this.props.orderAction.updateOrderUpdatingStatus(false);
        this.setState({disableSave: true});
        this.billingContactId = this.props.selectedOrderData.billingContactId;
        this.caseId = this.props.selectedOrderData.case.id;
        this.deliveryMethod = this.props.selectedOrderData.deliveryMethod;
        console.log("on save", this.props.selectedOrderData, this.props.selectedDataById);
        this.sourceId = this.props.selectedDataById.stage;
        if (this.props.selectedOrderData.stage !== this.props.selectedDataById.stage) {
            if (OrderUtil.toValidateDeliveryMethod(this.props.selectedDataById.stage, this.props.selectedOrderData.stage)) {
                this.showDeliveryType = true;
            }
            if (OrderUtil.isInvoice(this.props.selectedOrderData.stage)) {
                this.showCaseOwner = true;
            }
            if (OrderUtil.doesOrderStageChangeRequireAdminRole(this.props.selectedDataById.stage, this.props.selectedOrderData.stage)) {
                if (OrderUtil.verifyIfUserCanMoveOrderToArchivedOrDeletedStageOrCreditPaidInvoice(this.props.selectedDataById.stage, this.props.selectedOrderData.stage)) {
                    let status = OrderDraggingUtil.showPopupOnDragging(this.props.selectedDataById.stage, this.props.selectedOrderData.stage);
                    let emailWillBeSend = OrderDraggingUtil.checkEmailWillBeSent(this.props.selectedDataById.stage, this.props.selectedOrderData.stage);
                    if (emailWillBeSend) {
                        this.sendEmailAfterSave = true;
                    }
                    if (status === true) {
                        // this.resultDragged = result;
                        this.destinationId = this.props.selectedOrderData.stage;

                        if (this.billingContactId === null && this.showDeliveryType === false) {
                            this.props.actionMaster.showPopupOnOrderDrag();
                        } else if (this.billingContactId !== null && this.showDeliveryType === true) {
                            this.props.actionMaster.showPopupOnOrderDrag();
                        } else if (this.billingContactId === null && this.showDeliveryType === true) {
                            this.props.actionMaster.showPopupOnOrderDrag();
                        } else {
                            if (emailWillBeSend) {
                                this.props.actionMaster.showPopupOnOrderDrag();
                            } else {
                                this.updateDataOfOrder();
                            }
                        }
                    } else {
                        this.disableSave = false;
                        this.setState({disableSave: false});
                        this.props.actionMaster.changeDragStatus(null);
                        bottomCenter().error(Utility.getLang().common.changeStagePopup.cannotMoveToStage);
                    }
                } else {
                    this.disableSave = false;
                    this.setState({disableSave: false});
                    bottomCenter().error(Utility.getLang().common.changeStagePopup.notAuthorized);
                }
            } else {
                let status = OrderDraggingUtil.showPopupOnDragging(this.props.selectedDataById.stage, this.props.selectedOrderData.stage);
                let emailWillBeSend = OrderDraggingUtil.checkEmailWillBeSent(this.props.selectedDataById.stage, this.props.selectedOrderData.stage);
                if (emailWillBeSend) {
                    this.sendEmailAfterSave = true;
                }
                console.log("Result status", status);
                if (status === true) {
                    console.log("this.billingContactId ", this.billingContactId, this.showDeliveryType);
                    // this.resultDragged = result;
                    this.destinationId = this.props.selectedOrderData.stage;
                    if (this.billingContactId === null && this.showDeliveryType === false) {
                        this.props.actionMaster.showPopupOnOrderDrag();
                    } else if (this.billingContactId !== null && this.showDeliveryType === true) {
                        this.props.actionMaster.showPopupOnOrderDrag();
                    } else if (this.billingContactId === null && this.showDeliveryType === true) {
                        this.props.actionMaster.showPopupOnOrderDrag();
                    } else {
                        if (emailWillBeSend) {
                            this.props.actionMaster.showPopupOnOrderDrag();
                        } else {
                            this.updateDataOfOrder();
                        }
                    }
                } else {
                    this.disableSave = false;
                    this.setState({disableSave: false});
                    bottomCenter().error(Utility.getLang().common.changeStagePopup.cannotMoveToStage);
                }
            }

        } else {
            this.updateDataOfOrder();
        }

    };

    onConfirm = () => {
        this.updateDataOfOrder();
    };

    updateDataOfOrder = () => {
        let data = {};
        let validateCustom = false;
        data = this.props.selectedOrderData;
        if (this.props.selectedOrderData.case !== undefined && this.props.selectedOrderData.case.id !== undefined) {
            data.caseId = this.props.selectedOrderData.case.id;
        }
        data.billingContactId = this.props.selectedOrderData.billingContactId;
        let orderNewData = new Array(data);
        var orderValidationMessages = ValidationUtil.validateIfProductsExists(orderNewData);
        if (orderValidationMessages.length > 0) {
            orderValidationMessages.forEach(function (validationMessage) {
                bottomCenter().warning(validationMessage);
            });
            this.disableSave = false;
            this.setState({disableSave: false});
            return
        }
        var quantityValidationMessages = ValidationUtil.validateProductQuantity(orderNewData);
        if (quantityValidationMessages.length > 0) {
            quantityValidationMessages.forEach(function (validationMessage) {
                bottomCenter().warning(validationMessage);
            });
            this.disableSave = false;
            this.setState({disableSave: false});
            return
        }
        if (data.events !== undefined) {
            data.events.forEach((event, j) => {
                if (event.categories !== undefined && event.categories !== null) {
                    event.categories.forEach((category, k) => {
                        delete category.catPrice;
                        delete category.catTotal;
                        delete category.data;
                        category.eventProducts.forEach((products, l) => {
                            if (products.typeId === ProductTypes.customProduct) {
                                if (products.customName === "" || products.customName === null || products.customName === undefined) {
                                    validateCustom = true;
                                }
                            }
                        })
                    });
                }
            })
        }
        if (validateCustom === false) {
            setTimeout(function () {
                data = OrderColumnUtil.getUpdatedNotes(this.props.tempNote, data);
                console.log("data", data);
                this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isUpdating);
                this.props.orderAction.updateOrderInfoApiCall(data, this.props.storingKey, this.props.isColumnWithUniqueStage);
                setTimeout(function () {
                    this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
                }.bind(this), 1000);
            }.bind(this), 0);
        } else {
            this.disableSave = false;
            this.setState({disableSave: false});
            bottomCenter().warning(Utility.getLang().warningMessages.case.addCustomName);
        }
        this.sendEmailAfterSave = false;
        this.hidePopupOnSave()
    };

    createNotes = () => {
        if (this.state.notesValue !== "" && this.state.notesValue !== undefined) {
            const data = NoteUtil.newNote(this.state.notesValue);
            this.props.orderAction.createNotesforOrder(data);
            this.props.orderAction.saveTempNote("");
            setTimeout(function () {
                this.props.destroy()
            }.bind(this), 0)
        } else {
            bottomCenter().warning(Utility.getLang().warningMessages.call.addNotes);
        }
    };

    removeNotes = (notes, i) => {
        this.props.destroy();
        this.props.orderAction.removenotesitemfromOrder(notes, i);
    };

    sendOrderEmailSummary = (relativeId, orderId, bodyData) => {
        if (relativeId !== null && parseInt(relativeId, 10) !== 0) {
            let orderIds = [];
            orderIds.push(orderId);
            bodyData.caseId = this.props.selectedOrderData.case.id;
            bodyData.orderIds = orderIds;
            this.props.caseAction.sendEmailSummary(orderId, bodyData);
        } else {
            bottomCenter().warning(Utility.getLang().warningMessages.case.selectRelative);
        }
    };

    onDeleteClick = () => {
        this.props.actionMaster.showPopup();
    };

    hidePopup = () => {
        this.props.actionMaster.hidePopupSection();
    };

    onDelete = () => {
        this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isDeleting);
        this.props.orderAction.deleteOrderInfoApiCall(this.props.selectedOrderData, this.props.storingKey);
        setTimeout(function () {
            this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
        }.bind(this), 1000);
        this.hidePopup();
    };

    changeDeliveryMethod = (value) => {
        this.deliveryMethod = parseInt(value, 10);
    };

    changeValue = (e) => {
        this.setState({notesValue: e.target.value});
    };

    setTempNoteData = (value) => {
        this.props.orderAction.saveTempNote(value);
    };

    resetNote = () => {
        this.setState({notesValue: ""});
        this.props.orderAction.saveTempNote("");
    };

    checkKeys = (e) => {
        if (e.keyCode === 13 && e.ctrlKey === true) {
            this.createNotes();
        }
    };

    render = () => {
        const {reset, handleSubmit} = this.props;
        const handleEnterKeyPress = (el) => {
            this.el = el;
        };
        return (
            <React.Fragment>
                <PreLoader onRef={ref => this.preLoader = ref} showPreload={false}
                           container={this.node} blockContainer={true} type="Oval"/>
                <Popup
                    openModal={this.props.showUnsavedPopup}
                    headerInfo={Utility.getLang().infoMessages.popup.unsavedChanges}
                    onConfirmation={this.toMoveNewRoute}
                    closePopup={this.currentRoute}
                />
                <PopupForOrderStageChange
                    openModal={this.props.openOnOrderDrag}
                    onConfirmation={this.onConfirm}
                    closePopup={this.hidePopupOnCancel}
                    showDeliveryType={this.showDeliveryType}
                    showCaseOwner={this.showCaseOwner}
                    sourceId={this.sourceId}
                    destinationId={this.destinationId}
                    changeDeliveryMethod={this.changeDeliveryMethod}
                    caseId={this.caseId}
                    billingContactId={this.props.selectedOrderData.billingContactId}
                    deliveryMethod={this.deliveryMethod}
                    source={'order'}
                    sendEmailOnSave={this.sendEmailAfterSave}
                    order={this.props.selectedOrderData}
                    oldOrder={this.props.selectedDataById}
                />
                {this.props.displayNothing === false ?
                    <div className="section section--detail section--scroll" ref={node => this.node = node}>
                        {this.props.selectedOrderData !== undefined && this.props.selectedOrderData.id !== undefined ?
                            <div className="section__head">
                                <div className="inner">
                                    <h3 className="section__title">
                                        <Icon type="order"/>
                                        <span className="description">
                                            {OrderUtil.getOrderTextBasedOnOrderType(this.props.selectedOrderData.orderType)}
                                        </span>
                                        <Permalink
                                            id={this.props.selectedCard.id}
                                            path1={AppUtil.linkPaths.order.basePath}
                                            path2={AppUtil.linkPaths.order.details}
                                        />
                                    </h3>
                                </div>
                            </div>
                            : null}
                        {this.props.selectedOrderData !== undefined && this.props.selectedOrderData.id !== undefined ?
                            <div className="section__content section__content--case_notes">
                                <div className="inner">
                                    <form onSubmit={handleSubmit(this.createNotes)} onReset={reset}>
                                        <div className="form-group">
                                            <textarea
                                                id="noteText"
                                                name="noteText"
                                                cols="40"
                                                rows="5"
                                                className="textarea textarea--max_width textarea--short"
                                                onChange={this.changeValue}
                                                onBlur={() => this.setTempNoteData(this.state.notesValue)}
                                                onKeyDown={(e) => this.checkKeys(e)}
                                                value={this.state.notesValue}
                                            />
                                            <div className="help_text">
                                                {Utility.getLang().cases.notesTabContent.shortcutAddNote}
                                            </div>
                                        </div>
                                        <div className="btn-toolbar">
                                            <Button
                                                ref={handleEnterKeyPress}
                                                isPrimary
                                                type="submit"
                                                disabled={this.state.notesValue === ''}
                                            >
                                                {Utility.getLang().calls.notesTabContent.addNote}
                                            </Button>
                                            <Button
                                                type="reset"
                                                onClick={this.resetNote}
                                                disabled={this.state.notesValue === ''}
                                            >
                                                {Utility.getLang().calls.notesTabContent.reset}
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                                {this.props.selectedOrderData.notes.length > 0 && this.props.selectedOrderData.notes !== undefined && this.props.selectedOrderData.notes !== null ?
                                    <div className="drawer">
                                        {this.props.selectedOrderData.notes.map((notes, i) => {
                                            return (
                                                <div className="card card--note">
                                                    <div className="content">
                                                        <span className="user">
                                                            <span className="avatar avatar--16">
                                                                <Icon
                                                                    type="avatar"
                                                                    tiny
                                                                />
                                                            </span>
                                                            {notes.userName}
                                                        </span>
                                                        {notes.note}
                                                    </div>
                                                    <span className="meta meta--timestamp">
                                                        {notes.createdText}
                                                    </span>
                                                    <a className="link is-destructive"
                                                       onClick={(e) => this.removeNotes(notes, i)}>
                                                        <Icon
                                                            type="trash"
                                                            small
                                                        />
                                                        <span>
                                                            {Utility.getLang().common.remove}
                                                        </span>
                                                    </a>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    :
                                    <div className="drawer">
                                        <DisplayNothing/>
                                    </div>
                                }
                            </div>
                            :
                            <DisplayNothing/>
                        }
                    </div>
                    :
                    <DisplayNothing/>
                }
                <Popup
                    openModal={this.props.open}
                    headerInfo={Utility.getLang().infoMessages.popup.deleteOrder + ` ` + OrderUtil.getOrderTextBasedOnOrderType(this.props.selectedOrderData.orderType) + `?`}
                    onConfirmation={this.onDelete}
                    closePopup={this.hidePopup}
                    isDestructive
                    withTimeOut
                />
                <PopupForOrderEmail
                    openModal={this.props.openOnSend}
                    closePopup={this.hidePopup}
                    billingContactIdOrders={this.props.selectedOrderData.billingContactId}
                    relativesOfOrder={this.props.caseRelativesWithEmailId}
                    sendEmailSummary={(relativeID, data) => this.sendOrderEmailSummary(relativeID, this.props.selectedOrderData.id, data)}
                />
                <PopupAfterCaseSave
                    openModal={this.props.openOnSaveCase}
                    closePopup={this.hidePopupOnCancel}
                />
                {this.checkOrderChange()}
                <FooterWithSave SaveData={this.onSaveClick} deleteCase={this.onDeleteClick}
                                hideFooter={this.props.displayNothing}
                                disableSaveButton={this.disableSave}
                                buttonText={Utility.getLang().orders.footerForOrder.saveOrder}
                                disableSendEmailButton={this.state.disableEmailButton ? this.state.disableEmailButton : this.disableEmailButton}
                                textOnOrderChanges={this.disableEmailButton ? true : null}
                                deleteButtonText={Utility.getLang().orders.footerForOrder.deleteOrder}
                                hideRelativeDropdown={false}
                                orderForSendingmail={this.props.selectedOrderData}
                                hideDeleteButton={(AccessControl.revokedAccess().deleteOrder === true) && (this.props.selectedOrderData.stage !== OrderUtil.orderStages.proposal.value && this.props.selectedOrderData.stage !== OrderUtil.orderStages.draft.value && this.props.selectedOrderData.stage !== OrderUtil.orderStages.order.value) ? true : false}

                    // sendCaseEmailSummary={(relativeID) => this.sendOrderEmailSummary(relativeID, this.props.selectedOrderData.id)}
                />
            </React.Fragment>
        )
    }
}

OrderNotesComponent.propTypes = {
    columnDetailViewTabCollection: PropTypes.array,
};

function mapStateToProps(state) {
    return {
        selectedOrderData: state.application.selectedOrderData,
        selectedDataById: state.application.selectedDataById,
        initialValues: state.application.selectedOrderData,
        open: state.application.open,
        openOnSend: state.application.openOnSend,
        openOnOrderDrag: state.application.openOnOrderDrag,
        showUnsavedPopup: state.application.showUnsavedPopup,
        headerMenuItemClicked: state.application.headerMenuItemClicked,
        displayNothing: state.application.displayNothing,
        tempNote: state.application.tempNote,
        orderUpdated: state.application.orderUpdated,
        openOnSaveCase: state.application.openOnSaveCase
    };

}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        caseOrderAction: bindActionCreators(caseOrderAction, dispatch),
        orderAction: bindActionCreators(orderAction, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: "OrderNotesComponent"
})(withRouter(OrderNotesComponent)));
