import React from "react";
import {PictureType} from "./pictureType";
import {
    BACKGROUND_PICTURE_SELECTION,
    MemorialMediaType,
    MemorialProp,
    MemorialUtil,
    PROFILE_PICTURE_SELECTION
} from "../../util/memorialUtil";
import Utility from "../../../../../api/utilLanguage";
import AppUtil from "../../../../common/appUtil";
import {PictureSelectionOptions} from "./pictureSelectionOptions";
import {bindActionCreators} from "redux";
import * as memorialAction from "../../redux/memorialAction";
import connect from "react-redux/lib/connect/connect";
import {readFile} from "../../fileUtil";
import {bottomCenter} from "../../../../common/customToastr";
import {UpdateMemorial, UploadMemorial} from "../../api/memorialApis";
import * as caseAction from "../../../../../actions/caseAction";

class MemorialPicture extends React.Component {
    constructor(props) {
        super(props);
        const {picture} = this.props;
        this.state = {
            isPictureDefault: this.getDefaultValue(picture),
            hasChanged: false,
            isPictureLoading: false,
        }
        this.updateInitialPicture(this.props.picture);
    }

    componentDidUpdate(prevProps, prevState) {
        const {picture} = this.props;
        this.setupRadioBtnSelection(picture, prevProps.picture);

        this.updatesOnGetMemorialResponse(this.props.initialPicture, prevProps.initialPicture);
        this.updateHasChanged(this.initialPictureCopy, picture);
    }

    updatesOnGetMemorialResponse(initialPicture, prevInitialPicture) {
        this.updateInitialPictureAndRadioBtnSelection(initialPicture, prevInitialPicture);
    }

    getDefaultValue(picture) {
        return AppUtil.isAvailable(picture) ? MemorialUtil.isActivePictureRadioBtn(picture.type) : null;
    }

    updateInitialPicture = (value) => {
        this.initialPictureCopy = Object.assign({}, value);
    };

    updateHasChanged(initialPicture, picture) {
        if (AppUtil.isAvailable(initialPicture) && AppUtil.isAvailable(picture)) {
            const isSame = MemorialUtil.isEqualPictureProperty(initialPicture, picture);
            if (!isSame && !this.state.hasChanged) {//Picture HAS_CHANGED
                this.setState({hasChanged: true});
            } else if (isSame && this.state.hasChanged) {//Picture NOT_CHANGED
                this.setState({hasChanged: false});
            }
        }
    }

    setupRadioBtnSelection(picture, prevPicture) {
        if (this.initialLoadCondition(picture, prevPicture)) {
            this.updateInitialPicture(picture);
            this.setState({isPictureDefault: this.getDefaultValue(picture)});
        }
    }

    updateInitialPictureAndRadioBtnSelection(picture, prevPicture) {
        if (AppUtil.isAvailable(picture)) {
            const isSame = AppUtil.isAvailable(prevPicture) && MemorialUtil.isEqualPictureProperty(picture, prevPicture);
            if (isSame === false) {
                this.updateInitialPicture(picture);
                this.setState({isPictureDefault: this.getDefaultValue(picture)});
            }
        }
    }

    initialLoadCondition(picture, prevPicture) {
        return AppUtil.isAvailable(picture) && AppUtil.isEmpty(prevPicture);
    }

    updateFormattedProps = (url, value) => {
        const {id, size, options} = this.props;
        this.imageProps = (id === MemorialProp.DECEASED_PICTURE) ? {
                title: Utility.getLang().cases.memorial.deceasedPicture,
                url: url,
                alt: Utility.getLang().cases.memorial.deceasedPictureAlt,
                size: AppUtil.isAvailable(size) ? size : {"width": "80", "height": "80"}
            } :
            {
                title: Utility.getLang().cases.memorial.backgroundPicture,
                url: url,
                alt: Utility.getLang().cases.memorial.backgroundPictureAlt,
                size: AppUtil.isAvailable(size) ? size : {"width": "192", "height": "80"}
            }

        this.selectProps = (id === MemorialProp.DECEASED_PICTURE) ? {
                id: PROFILE_PICTURE_SELECTION,
                value: value,
                options: options,
                helpText: Utility.getLang().cases.memorial.deceasedPictureHelp,
            } :
            {
                id: BACKGROUND_PICTURE_SELECTION,
                value: value,
                options: options,
                helpText: Utility.getLang().cases.memorial.backgroundPictureHelp,
            }
    };

    /***
     * @description: API calls
     */
    updatePictureApi = (memorialPropAsId, memorialId, selectionId) => {
        switch (memorialPropAsId) {
            case MemorialProp.BACKGROUND_PICTURE:
                this.startLoading();
                UpdateMemorial.defaultBackgroundPicture(selectionId, memorialId).then((resp) => {
                    this.updateMemorialPropertyFromResponse(resp, memorialPropAsId);
                }).finally(() => {
                    this.stopLoading();
                });
                break;
            case MemorialProp.DECEASED_PICTURE:
            default:
                this.startLoading();
                UpdateMemorial.defaultProfilePicture(selectionId, memorialId).then(resp => {
                    this.updateMemorialPropertyFromResponse(resp, memorialPropAsId);
                }).finally(() => {
                    this.stopLoading();
                });
                break;
        }
    };

    startLoading() {
        this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isLoading);
        this.setState({isPictureLoading: true});
    }

    stopLoading() {
        this.setState({isPictureLoading: false});
        setTimeout(() => {
            this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
        }, 1000);
    }

    uploadPictureApi = (memorialPropAsId, memorialId, file) => {
        if (AppUtil.isAvailable(file)) {
            switch (memorialPropAsId) {
                case MemorialProp.BACKGROUND_PICTURE:
                    this.startLoading();
                    UploadMemorial.backgroundPicture(file.name, memorialId, file).then(resp => {
                        this.updateMemorialPropertyFromResponse(resp, memorialPropAsId);
                    }).finally(() => {
                        this.stopLoading();
                    });
                    break;
                case MemorialProp.DECEASED_PICTURE:
                default:
                    this.startLoading();
                    UploadMemorial.profilePicture(file.name, memorialId, file).then(resp => {
                        this.updateMemorialPropertyFromResponse(resp, memorialPropAsId);
                    }).finally(() => {
                        this.stopLoading();
                    });
                    break;
            }
        } else {
            console.log("[Debug]:: Uploading unsuccessful, File not found");
        }
    };

    updateMemorialPropertyFromResponse = (response, memorialPropAsId) => {
        const respData = response.data;
        if (AppUtil.isAvailable(response) && response.status === 200 && respData.success) {
            //Re-initialize  after response
            this.updateInitialPicture(respData.object[memorialPropAsId]);
            this.updateProperty(memorialPropAsId, respData.object[memorialPropAsId]);
        }
    };

    updateProperty = (property, payload) => {
        this.props.memorialAction.updateMemorialProperty(property, payload);
        this.props.memorialAction.updateMemorialProperty(MemorialProp.SHOULD_CALL_COUNTERS_API, true);
    };

    /**
     * @description: Button action
     */
    onChangeOfPictureSelection = (option, memorialPropAsId) => {
        // console.log(`[Debug]:: onChangeOfPictureSelection[${memorialPropAsId}] = %o`, option);
        const property = memorialPropAsId;
        this.props.memorialAction.updateMemorialProperty(property, option);
        this.props.memorialAction.updateMemorialGenerateType(property, MemorialMediaType.default);
    };

    onUpload = (event, memorialPropAsId) => {
        const files = event.target.files;
        if (AppUtil.isAvailable(files) && (files.length > 0)) {
            const file = files[0];
            // console.log("[Debug] :: Uploaded File = ", file);
            readFile(file).then(dataInBase64 => {
                const property = memorialPropAsId;
                this.initialPictureCopy[MemorialProp.FILE] = file;
                this.props.memorialAction.updateMemorialImageUrl(property, dataInBase64);
            }).catch(error => {
                bottomCenter().error(error);
            });
        }
    };

    onSaveBtnClick = (memorialPropAsId, dataType) => {
        // console.log("[Debug]:: Save action = ", memorialPropAsId, dataType);
        const {memorialId, picture} = this.props;
        switch (dataType) {
            case MemorialMediaType.default:
                this.updatePictureApi(memorialPropAsId, memorialId, picture.id);
                break;
            case MemorialMediaType.upload:
                const file = this.initialPictureCopy[MemorialProp.FILE];
                this.uploadPictureApi(memorialPropAsId, memorialId, file);
                break;
            default:
                break;
        }
    };

    onRadioBtnClick = (memorialPropAsId) => {
        this.setState({isPictureDefault: true});
        this.props.memorialAction.updateMemorialGenerateType(memorialPropAsId, MemorialMediaType.default);
    };

    onRadioBtnClick1 = (memorialPropAsId) => {
        this.setState({isPictureDefault: !this.state.isPictureDefault});
        this.props.memorialAction.updateMemorialGenerateType(memorialPropAsId, MemorialMediaType.upload);
    };

    render = () => {
        const {
            id,
            isLoading,
            picture
        } = this.props;

        const url = AppUtil.isAvailable(picture) ? picture[MemorialProp.THUMB_URL] : null;
        const uploadedPictureUrl = AppUtil.isAvailable(picture) ? picture[MemorialProp.URL] : null;
        this.updateFormattedProps(url, picture);

        const {isPictureDefault, hasChanged, isPictureLoading} = this.state;
        const loading = isLoading || isPictureLoading;

        return (<React.Fragment>
            <PictureType id={id}
                         checked={isPictureDefault}
                         title={this.imageProps.title}
                         url={this.imageProps.url}
                         alt={this.imageProps.alt}
                         size={this.imageProps.size}
                         onRadioBtnClick={() => this.onRadioBtnClick(id)}
                         onRadioBtnClick1={() => this.onRadioBtnClick1(id)}
                         isLoading={loading}
                         initialLoading={isLoading}/>
            <PictureSelectionOptions id={this.selectProps.id}
                                     isPictureDefault={isPictureDefault}
                                     value={this.selectProps.value}
                                     options={this.selectProps.options}
                                     helpText={this.selectProps.helpText}
                                     uploadedPictureUrl={uploadedPictureUrl}
                                     onChange={(option) => this.onChangeOfPictureSelection(option, id)}
                                     onUpload={(e) => this.onUpload(e, id)}
                                     onSaveBtnClick={() => this.onSaveBtnClick(id, picture.type)}
                                     isDisabled={loading || !hasChanged}/>
        </React.Fragment>);
    };

    componentWillUnmount() {
        this.updateInitialPicture(null);
    }
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        memorialAction: bindActionCreators(memorialAction, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MemorialPicture);