import AppPopup from "../../../../../common/utils/appPopup";
import {ProbateProps} from "../../tabProbateMeeting/util";
import Button from "../../../../../common/button";
import React from "react";
import ProbateExistingCasesPopupContent from "./probateExistingCasesPopupContent";
import useConnectExistingProbateCasePopup from "../hooks/useConnectExistingProbateCasePopup";
import {Placeholder} from "../../../../../common/placeholder";
import {Lang} from "../../../../../common/appUtil";

const ConnectExistingProbateCaseView = ({
                                            title,
                                            sourceCaseId,
                                            probateCaseId,
                                            shouldShowPopup,
                                            dispatch
                                        }) => {
    const {
        showPopup,
        onPopupConfirmation,
        isLoadingConnectExistingProbateCases,
        hidePopup
    } = useConnectExistingProbateCasePopup(sourceCaseId, dispatch);

    const disableConfirmBtn = probateCaseId ? (probateCaseId <= 0) : true;
    return <>
        <AppPopup id={ProbateProps.showExistingProbateCasePopup}
                  show={shouldShowPopup}
                  title={Lang().cases.probates.connectAnExistingProbateCase.popupInfo.chooseFromExistingProbateCase}
                  onConfirmation={() => onPopupConfirmation(sourceCaseId, probateCaseId)}
                  disableConfirmButton={disableConfirmBtn}
                  confirmationButtonTitle={Lang().cases.probates.connectAnExistingProbateCase.popupInfo.connect}
                  hidePopup={hidePopup}
                  dispatch={dispatch}
        >
            <ProbateExistingCasesPopupContent dispatch={dispatch}/>
        </AppPopup>
        {isLoadingConnectExistingProbateCases
            ? <Placeholder/>
            : <Button onClick={showPopup}>{title}</Button>
        }
    </>;
};
export default ConnectExistingProbateCaseView;
