class English {
    static getTexts = {
        notFound: {
            permissionDeniedMessage: "Nothing to see here",
            instructions: "Please try navigating away or searching. You can also ",
            contactAdmin: "If you think you're seeing this page in error, please contact an administrator.",
            rootPageRedirect: "log out and go back to the start page"
        },
        login: {
            username: "Username",
            password: "Password",
            loginBtn: "Log in",
            forgotPassword: "Forgot password?"
        },
        forgotPassword: {
            loginLink: "Log in",
            header: "Forgot password",
            text: "Enter your email address below and we will send a password reset link to your registered email address.",
            resetBtn: "Reset password",
            resetTextPart1: "We have sent a password reset code by email to ",
            resetTextPart2: ". Enter the code below to reset your password.",
            resetCode: "Password reset code",
            resetCodePlaceholder: "123456",
            newPassword: "New password",
            repeatPassword: "Repeat password",
            changePasswordBtn: "Update password"
        },
        formValidation: {
            lowerCaseCantBeEmpty: "can't be blank"
        },
        passwordInstruction: {
            characterLength: "Minimum 8 characters",
            caseDetails: "Uppercase and lowercase letters",
            numbers: "Numbers",
            specialCharMessage: "Special characters, for example: %, &, _",
            passwordNotMatch: "Passwords do not match",
            passwordRules: "Password does not fulfill password criteria"
        },
        confirmUser: {
            header: "Set new password here",
            newPassword: "New password",
            submit: "Save",
            failedPasswordReset: "Something went wrong – try logging in again!"
        },
        header: {
            convert: "Sell",
            organise: "Deliver",
            bill: "Bill",
            dashboard: "Dashboard",
            customers: "Customers",
            cases: "Cases",
            orders: "Orders",
            calls: "Calls",
            products: "Products",
            locations: "Locations",
            contacts: "Contacts",
            search: "Search",
            notifications: "Notices",
            help: "Help",
            settings: "Settings",
            myAccount: "My account",
            logOut: "Log out",
            delete: "Delete",
        },
        products: {
            products: "Products",
            detailsTabContent: {
                title: "Title",
                description: "Description",
                specification: "Specification",
                slug: "Slug",
                seoTitle: "SEO Title",
                seoDescription: "SEO Description",
                type: "Type",
                category: "Category",
                price: "Price",
                maxorderamount: "Max order amount",
                showproductinpubliclistings: "Show product in public listings",
                deleteproduct: "Delete",
                linkpreview: "Link preview",
                updateDetails: "Update Details",
                saveDetails: "Save Details",
                status: "Status",
                complete: "Complete",
                incomplete: "Incomplete",
                availability: "Availability",
                noVariantsAvailable: "No Variants Available",
                enableFreeText: "Enable freetext",
                enableDisbursement: "Enable Disbursement"
            },
            picturesTabContent: {
                altText: "altText",
                update: "Update",
                default: "Default",
                isDefault: "Default",
                deletepicture: "Delete",
                addpicture: "Add picture",
                product: "Product",
                dropfilehere: "Drop file here",
                oR: "OR",
                save: "Save",
                noVariantsAvailable: "No Variants Available"
            },
            childrenTabContent: {
                addchildproduct: "Lägg till barnprodukt"
            },
            tabs: {
                details: "Details",
                pictures: "Pictures",
                children: "Children"
            }
        },
        locations: {
            locations: "Locations",
            yes: "Yes",
            no: "No",
            unknown: "Unknown",
            detailsTabContent: {
                locationName: "Location name",
                comment: "Comment",
                address: "Address",
                postalCode: "Postal code",
                city: "City",
                slug: "Slug",
                seoTitle: "SEO title",
                seoDescription: "SEO description",
                linkpreview: "Link preview",
                showlocationinpubliclistings: "Show location in public listings",
                type: "Type",
                picture: "Picture",
                place: "Place",
                imageURL: "Image URL",
                altText: "Alt text",
                dropfilehere: "Drop file here",
                update: "Update",
                deletelocation: "Delete",
                updateDetails: "Update details",
                saveDetails: "Save details",
                yearBuilt: "Year built",
                placeholder: "none"
            },
            mapTabContent: {
                latitude: "Latitude",
                longitude: "Longitude",
                latitudeAndLongitude: "Latitude & Longitude",
                saveMap: "Save map"
            },
            typeTabContent: {
                updateDetails: "Update details"
            },
            equipmentTabContent: {
                coffincarriers: "Coffin carriers",
                updateEquipment: "Update equipment"
            },
            contactTabContent: {
                addContact: "Add Contact"
            },
            tabs: {
                details: "Details",
                map: "Map",
                types: "Types",
                equipment: "Equipment",
                contact: "Contact"
            },
            locationTypes: {
                country: "Country",
                county: "County",
                municipality: "Municipality",
                city: "City"
            }
        },
        customers: {
            tabs: {
                details: "Details",
                customerPackage: "Customer package",
            },
            package: {
                name: "Name",
                document: "Document",
                orderDate: "Order date",
                order: "Order",
                product: "Product",
                price: "Price",
                partner: "Partner",
                partnerServices: "Partner services",
                fenixServices: "Fenix services",
                payments: "Payments",
                noPackage: "Package not found"
            }
        },
        cases: {
            cases: "Cases",
            newCardForAdd: {
                mainContact: "(Unknown)",
                deceased: "(Unknown deceased)",
                new: "New"
            },
            notes: {
                addNote: "Add note",
                addNoteTooltip: "Add note",
                notePlaceholder: "Type note here...",
                shortNotePlaceholder: "Note...",
                reset: "Reset",
                resetTooltip: "Reset textarea",
                deleteNoteTooltip: "Delete note",
                emptyNotesMessage: "Notes added will show here"
            },
            statusTabContent: {
                newCase: "New case",
                mergeWithCase: "Merge with case",
                merge: "Merge",
                typeToSearch: "Type to search...",
                createOrder: "Create order",
                create: "Create",
                callSingular: "Call",
                callPlural: "Calls",
                location: "Location",
                phone: "Phone",
                caller: "Caller",
                contactSingular: "Contact",
                contactPlural: "Contacts",
                contactName: "Contact name",
                contactRelationship: "Contact relationship",
                deceased: "Deceased",
                deceasedName: "Deceased name",
                personalIdentityNumber: "Personal identity number",
                status: "Status",
                labels: "Labels",
                owner: "Owner",
                collaborator: "Collaborator",
                caseCreated: "Case created",
                relatedCases: "Related cases",
                caseDeadline: "Deadline",
                caseDeadlineHelpCaseCreated: "Date created",
                caseDeadlineHelpCeremonyDate: "Ceremony date",
                caseDeadlineHelp30DaysOverDateOfPassing: "Date of passing +30 days",
                caseDeadlineHelpNone: "No deadline yet",
                deadline: {
                    resetHelpMsg: "Deadline date will be calculated, when case is saved",
                    reset: "Reset",
                    edit: "Edit",
                    cancel: "Cancel"
                },
                cid: "CID",
                noLocation: "No location",
                noCaller: "No caller",
                averageScore: "Average score",
                responses: "Responses",
                pending: "Pending",
                todoSingular: "Todo",
                todoPlural: "Todos",
                customerTodoSingular: "Customer Todo",
                customerTodoPlural: "Customer Todos",
                cardTodoSingular: "todo",
                cardTodoPlural: "todos",
                todoButtonAdd: "Custom todo",
                todoDoneSingular: "Done",
                todoDonePlural: "Done",
                todoDelete: "Delete todo",
                todoStatusNotdone: "Mark done",
                todoStatusDone: "Mark not done",
                todoTemplate: "Todo templates",
                compensationPrel: "Prel. earnings",
                compensation: "Earnings",
                compensationInvoice: "Earnings to invoice",
                aboutCompensation: "Earnings info"
            },
            deceasedTabContent: {
                name: "Name",
                firstName: "First name",
                lastName: "Last name",
                dateOfBirth: "Date of birth",
                country: "Country",
                county: "County",
                referredToCoroner: "Referred to coroner",
                readyForCollection: "Ready for collection",
                deathRegistered: "Death registered",
                greenFormWith: "Green form status",
                address: "Address",
                address2: "Address 2",
                postalCode: "Postal code",
                city: "City",
                memberOfSwedishChurch: "Member of the Swedish Church",
                pacemaker: "Pacemaker",
                personalIdentityNumber: "Personal identity number",
                getDetailsBtn: "Get details",
                dateOfPassing: "Date of passing",
                locationOfBody: "Location of body",
                useResidentialAddress: "Use residential address",
                parishName: "Parish Name",
                greenFormWithOptions: {
                    family: "Family",
                    registrar: "Registrar",
                    notIssued: "Not registered",
                    unknown: "Unknown",
                    notRequired: "Not required",
                    fenix: "Fenix",
                    partnerFD: "Partner FD"
                },
                sex: "Sex",
                genderOptions: {
                    male: "Male",
                    female: "Female"
                },
                maritalStatus: "Marital Status",
                maritalStatusOptions: {
                    marriedOrCivilPartnership: "Married or Civil Partnership",
                    widowOrWidowerOrCivilPartner: "Widow or Widower or Surviving Civil Partner",
                    single: "Single"
                },
                infectionStatus: "Infection Status",
                infectionStatusOptions: {
                    notInfectious: "Not Infectious",
                    infectiousCOVID: "Infectious COVID",
                    infectiousOther: "Infectious Other",
                    unknown: "Unknown"
                },
                lastOccupation: "Last occupation",
                timeOfPassing: "Time of passing",
                locationOfPassing: "Location of passing",
                locationOfPassingOptions: {
                    home: "Home",
                    hospital: "Hospital",
                    nursingHome: "Nursing Home",
                    hotel: "Hotel",
                },
                furtherExaminationRequired: "Further examination required",
                detailsOfFurtherExamination: "Details of further examination",
                releaseForm: "Release form",
                releaseFormOptions: {
                    unknown: "Unknown",
                    required: "Required",
                    notRequired: "Not Required",
                },
                causeOfDeath: "Cause of death",
                causeOfDeathOptions: {
                    natural: "Natural",
                    unnatural: "Unnatural",
                },
                cremationPapers: "Cremation papers",
                cremationPaperOptions: {
                    unknown: "Unknown",
                    notRequired: "Not Required",
                    fourOnly: "4 only",
                    cremationPaperFourAndFive: "4 & 5",
                    sixCoroner: "6 (coroner)",
                },
                cremationPaperPaidBy: "Cremation papers paid by",
                cremationPaperPaidByOptions: {
                    customer: "Customer",
                    funeralPartnerByCheque: "Funeral Partner by cheque",
                    funeralPartnerOther: "Funeral Partner Other",
                    invoicedToFenix: "Invoiced to Fenix",
                    fenixByPhone: "Fenix by phone",
                    notApplicable: "Not applicable",
                },
                paymentDetailsForCremationPapers: "Payment details for cremation papers",
                pacemakerOptions: {
                    yes: "Yes",
                    no: "No",
                    unknown: "Unknown",
                    yesRemoved: "Yes removed",
                    yesNotRemoved: "Yes, not removed",
                },
                otherDetails: "Other details",
                residentialAddress: "Residential address",
                addressOfPassing: "Address of passing",
                postalCodeOfPassing: "Postal code of passing",
                cityOfPassing: "City of passing",
                countyOfPassing: "County of passing",
                countryOfPassing: "Country of passing",
                phoneOfPassing: "Phone number of location where passed",
                arrangementDetails: "Arrangement details",
                cremation1Details: "Cremation 1 Details",
                relativesAndExecutorsInformed: "Relatives and executors informed",
                relativesAndExecutorsInformedSelectOptions: {
                    allInformed: "All informed",
                    allNotInformed: "All not informed"
                },
                notInformedDetails: "Not informed details",
                cremationObjections: "Cremation objections",
                objectionsDetails: "Objections details",
                ashesDisposalTypeForCrem1: "Ashes disposal type for cremation1",
                ashesDisposalTypeForCrem1SelectOptions: {
                    ashesToScattered: "Ashes to be scattered or interred",
                    dealtByCrematorium: "Otherwise dealt with by the crematorium",
                    collectedFromCrematorium: "Ashes to be collected from the crematorium",
                    awaitingForDecision: "Ashes to be held awaiting your decision"
                },
                ashesInstructionsForCrem1: "Ashes instructions for cremation1",
                bodySize: "Body size",
                bodySizeOptions: {
                    standard: "Standard",
                    overSize: "Oversize",
                    unknown: "Unknown"
                },
                bodySizeNotes: "Body size notes",
            },
            relativeTabContent: {
                contactName: "Contact name",
                name: "Name",
                firstName: "First name",
                lastName: "Last name",
                dateOfBirth: "Date of birth",
                country: "Country",
                county: "County",
                phoneNumber: "Phone number",
                mobileNumber: "Mobile number",
                email: "Email",
                billingContact: "Billing contact",
                billingAddress: "Billing address",
                useForBillingContact: "Use for funeral billing",
                otherDetails: "Other details",
                relationship: "Relationship",
                personalIdentityNumber: "Personal identity number",
                address: "Address",
                address2: "Address 2",
                postalCode: "Postal code",
                city: "City",
                mainContact: "Main contact",
                guest: "Guest",
                deleteContact: "Delete contact",
                noContacts: "No Contacts",
                addContact: "Add Contact",
                guestDetails: "Guest details",
                untitledContact: "(Untitled)",
                ukContactProperties: {
                    ashesReceiver: "Ashes receiver",
                    celebrant: "Officiant",
                    usualDoctor: "Usual doctor",
                    lastDoctor: "Last doctor",
                    cremCertificateInspector: "Crem certificate inspector",
                    cremApplicant: "Crem 1 applicant",
                    hearseViaAddress: "Hearse via address",
                    churchServiceContact: "Church service contact",
                    consignee: "Consignee",
                },
                ukPaymentMethod: "Payment method",
                ukPaymentMethodOptions: {
                    customerPaying: "Customer paying",
                    deceasedBankAccount: "Deceased bank account",
                    dwpApplication: "DWP application"
                },
                nationalInsuranceNumber: "National Insurance Number",
                financialAssistanceDetails: "Payment Notes & Status",
                read: "Read",
                write: "Write",
                none: "None",
                invitedOrders: "Customer Web App Access Order List",
                notInvitedMessage: "Order not invited for this contact",
                deletePopupTitle: "Are you sure you want to revoke the customer's access for this order ?",
                companyName: "Company name",
            },
            orderTabContent: {
                funeralCaseOrderContent: {
                    addOrder: "Add order",
                    billingContact: "Billing contact",
                    case: "Case",
                    status: "Status",
                    addContact: "Add contact",
                    saveOrder: "Save order",
                    selectRelative: "Select relative...",
                    emailSummary: "Email",
                    deleteOrder: "Delete",
                    finalPrice: "Price",
                    quantity: "Quantity",
                    customProduct: "Custom product",
                    allMemorialFunds: "All memorial funds",
                    product: "Product",
                },
                ukCaseOrderTabContent: {
                    disbursementPayment: "Disbursement payment"
                },
                funeralServiceMusicPlaceholder: "Please add the chosen tracks and when they will be played",
            },
            callsTabContent: {
                header: "Associate call",
                search: "Search calls...",
                removeCall: "Remove from case"
            },
            notesTabContent: {
                addNote: "Add note",
                reset: "Reset",
                shortcutAddNote: "Add note with Ctrl+Enter"
            },
            guestTabContent: {
                firstName: "First name",
                lastName: "Last name",
                guests: "Registrations",
                totalGuests: "Total guests",
                rsvpYes: "RSVP Yes",
                rsvpNo: "RSVP No",
                registrationDate: "Registration date",
                maxRegistrations: "Max registrations",
                name: "Name",
                diet: "Diet",
                email: "Email",
                phone: "Phone",
                registered: "Registered",
                confirmed: "Confirmed",
                notConfirmed: "Not confirmed yet",
                rsvp: "RSVP",
                addNewGuest: "Add new guest",
                addGuest: "Add guest...",
                newContact: "New contact",
                existingContact: "Existing contact",
                deleteGuest: "Delete guest",
                dietaryRestrictions: "Dietary restrictions",
                addDietaryRestriction: "Add dietary restriction...",
                yes: "Yes",
                no: "No",
                unknown: "Unknown",
                plusOnes: "(+1's)",
                untitledGuest: "(Untitled)",
                sendGuestList: "Send guest list",
                emailSendConfirmationTitle: "Do you want to {send} mail to {guest}?",
                tooManyRegistered: "More registered guests than allowed",
                guestRestrictionPopup: {
                    continue: "Do you want to continue?",
                    exceedsMaxGuestAndRSVPDate: "You have exceeded number of allowed guests, and the registration date has passed.",
                    exceedsMaxGuest: "Max number of allowed guests are {limit}.",
                    exceedsRSVPDate: "Registration date of {limit} has passed.",
                    leftBtnTitle: "Ok"
                }
            },
            memorial: {
                viewMemorial: "View memorial page",
                slug: "Slug",
                published: "Published",
                fund: "Fund",
                visitFund: "Visit",
                default: "Default",
                custom: "Custom",
                picture: "Picture",
                pictureURL: "Picture URL",
                pictureURLPlaceholder: "Add picture URL...",
                updatePicture: "Update picture",
                deceasedPicture: "Deceased picture",
                deceasedPictureAlt: "Selected deceased picture",
                deceasedPictureHelp: "Square JPG or PNG, at least 275x275px",
                backgroundPicture: "Background picture",
                backgroundPictureAlt: "Selected background picture",
                backgroundPictureHelp: "JPG or PNG, at least 1440x600px",
                obituary: "Obituary",
                obituaryPlaceholder: "Add obituary...",
                memories: {
                    empty: "No memories shared yet",
                    emptyReported: "No reported memories yet",
                    title: "Memory",
                    titlePlural: "Memories",
                    reportedOnly: "Reported",
                    all: "All",
                    reported: "Reported",
                    pictureAlt: "Memorial picture",
                    edit: "Edit",
                    editTooltip: "Edit memory",
                    delete: "Delete memory",
                    editMemory: "Edit memory",
                    memoryStatus: "Status",
                    memoryStatusNormal: "Not reported",
                    memoryStatusReported: "Reported",
                    memoryAuthor: "Memory author",
                    memoryAuthorPlaceholder: "Add memory author...",
                    memoryText: "Memory text",
                    memoryTextPlaceholder: "Add memory text...",
                    memoryMediaURL: "Media URL",
                    memoryMediaURLPlaceholder: "Add media URL...",
                    numberOfLikes: "Number of likes",
                    deleteMemoryPopup: {
                        header: "Memory will be deleted",
                        content: "Are you sure want to delete '{name}'?",
                    },
                    deleteEmailSubscriptionPopup: {
                        header: "Subscription will be deleted",
                        content: "Are you sure want to delete '{name}'?",
                    },
                    editWarning: {
                        memory: "Unsaved changes available, save or cancel to edit other memories",
                    }
                },
                emails: {
                    empty: "No subscriptions yet",
                    title: "Email subscription",
                    titlePlural: "Email subscriptions",
                    typeEmail: "Type email address...",
                    addSubscription: "Add subscription",
                    edit: "Edit",
                    editTooltip: "Edit subscription",
                    delete: "Delete subscription"
                }
            },
            dashboard: {
                case: "Case",
                deceasedName: "Deceased name",
                deceasedPersonalNo: "Dec. personal no.",
                deceasedDOB: "Dec. DOB.",
                customerName: "Customer name",
                fsArranger: "FS arranger",
                funeralServiceDate: "Funeral service date",
                balanceDue: "Balance due",
                ukCurrencySign: "£",
                returnDeadline: "Return deadline",
                fdPartner: "FD partner",
                ashesInstruction: "Ashes instruction",
                overdueTodoTitle: "Overdue todo",
                overdueDate: "Overdue date",
                numberOfOverdueDays: "No. days overdue",
                todos: "Todos",
                orders: "Order(s)",
                listType: {
                    invoiceNotIssuedCases: "Invoice not issued",
                    invoiceNotPaidCases: "Invoice not paid",
                    upcomingFuneralCases: "Upcoming funeral",
                    passedAndNonInvoicedCases: "Passed ceremony date and non-invoiced cases",
                    attendedAshesReturn: "Attended ashes return",
                    unattendedAshesReturn: "Unattended ashes return",
                    overdueTodo: "Overdue todo",
                },
                button: {
                    initial: "Initial {COUNT}",
                    next: "Next {COUNT}",
                    previous: "Previous {COUNT}",
                    fullList: "Full list",
                },
                of: "of",
            },
            card: {
                callFrom: "Call from: ",
                callerUnknown: "unknown"
            },
            footerForCase: {
                saveCase: "Save",
                selectRelative: "Select relative...",
                emailSummary: "Email",
                deleteCase: "Delete",
                showNotes: "Show notes"
            },
            tabs: {
                status: "Status",
                deceased: "Deceased",
                relative: "Contact",
                relativePluralized: "Contacts",
                order: "Order",
                neworder: "OrderNew",
                orderPluralized: "Orders",
                calls: "Call",
                callsPluralized: "Calls",
                notes: "Note",
                notesPluralized: "Notes",
                guests: "Registration",
                guestsPluralized: "Registrations",
                documents: "Document",
                documentsPluralized: "Documents",
                memorial: "Memorial",
                memorialPluralized: "Memorial",
                probate: "Probate",
                probatePluralized: "Probates",
                log: "Log",
                logPluralized: "Logs",
                message: "Message",
                messagePluralized: "Messages"
            },
            messages: {
                notFound: "Message not found",
                addMessage: "Send",
                updateMessage: "Edit",
                deletePopupTitle: "Message will be deleted",
                deletePopupContent: "Are you sure you want to delete?",
                messagePlaceholder: "Type message here...",
                messageTooltip: "See list of messages",
                refreshMessageList: "Refresh list",
                messageEditedText: "edited",
                shortcutAddMessage: "Add Message with Ctrl+Enter",
                sendButtonEnable: "Send button enable when order in order stage and customer has signed up"
            },
            probates: {
                probate: "Probate",
                createNewProbateCase: {
                    buttonText: "Create probate case",
                    popupInfo: {
                        createProbateCase: "Create probate case",
                        createCaseHeaderInfo: "Clicking on \"Yes\" will create a new Probate case, information within the \"Deceased\" and \"contacts\" tabs for this case will be copied into the new case. Do you want to continue?"
                    }
                },
                connectAnExistingProbateCase: {
                    buttonText: "Connect an existing probate case",
                    popupInfo: {
                        chooseFromExistingProbateCase: "Choose from existing probate case",
                        probateCases: "Probate cases",
                        connect: "Connect"
                    }
                },
                checkTiming: "Checks / Timing",
                checksEstate: "Probate Checks / Estate administration",
                beneficiaries: "Beneficiaries",
                information: "Information",
                notes: "Notes",
                assetsDebts: "Assets/Debts",
                assetsDebtsDetails: {
                    splitItem: "Split item",
                    splitItemToolTip: "It splits {typeName} item with {propertyOwner}"
                },
                probateMeeting: "Probate Meeting",
                meetingInfo: {
                    manualDueDateMustHaveDocument: "Manual due date must have supporting document"
                },
                probateChecks: "Probate Checks",
                meeting: "Meeting",
                dateTimeOfProbateMeeting: "Date/time of probate meeting",
                widowOrWidower: "Widow/Widower",
                maritalStatus: "Marital status",
                place: "Place",
                dueDate: "Due Date",
                owner: "Owner",
                reviewedAndApproved: "Reviewed & Approved",
                reviewedDateAndTime: "Reviewed Date and Time",
                reviewer: "Reviewer",
                wantsHelpWithEstateAdministration: "Wants help with estate administration",
                questionnaire: {
                    probateQuestionnaire: "Probate Questionnaire",
                    open: "Open Questionnaire",
                    confirmBtnTitle: "Save",
                    addNewPerson: "Add new person",
                    add: "Add",
                    delete: "Delete",
                    beneficiaryHeaderPlaceholder: "Beneficiary in the will",
                    beneficiaryHeaderEmptyPlaceholder: "",
                },
                deleteBeneficiaryPopup: {
                    header: "Beneficiary will be deleted",
                    content: "Are you sure you want to delete '{name}'?"
                },
                updated: "Updated",
                datePlaceholder: "None",
                beneficiaryAlreadyExists: "Beneficiary already exists",
                headerTitle: {
                    name: "Name",
                    applicant: "Applicant?",
                    legalStatus: "Legal Status",
                    attendEvent: "Attend Event?",
                    wantToParticipateInventory: "Wants to participate Inventory?",
                    calledToEvent: "Called to event?",
                    confirmedCalledOfEvent: "Confirmed called of event?",
                    renounceInheritance: "Renounce inheritance",
                    isEstateExecutor: "Is estate executor",
                    deceasedDetails: "Deceased Details",
                    spouseDetails: "Spouse Details",
                    notes: "It is noted that:"
                },
                commonNote: {
                    existingNotes: "Choose existing notes",
                    selectNote: "Select note/s",
                    selectBtn: "Select"
                },
                options: {
                    yes: "Yes",
                    yesViaRepresentative: "Yes via representative",
                    no: "No",
                    beneficiaries: "Beneficiaries",
                    successor: "Successor",
                },
                isDeceased: "Deceased?",
                selectOrUploadDocument: "Select or upload document",
                selectDocument: "Select document",
                assetsDebtsInvalidText: "Fill required fields in Probate Asset/Debts Tab",
                assetsDebtsPopup: {
                    title: "{popupTitle} will be deleted",
                    body: "Are you sure you want to delete {CONTENT}?",
                    ofValue: "of value",
                },
                case: "Case",
                type: "{Name} Type",
                value: "Value",
                appendix: "Appendix",
                name: "Name",
                getOrderTotal: "Get order total",
                assets: "Assets",
                debts: "Debts",
                deductions: "Deductions",
                net: "Net",
                deceased: "Deceased",
                matrimonialProperty: "Matrimonial property",
                privateProperty: "Private property",
                estateHolding: "Estate holding",
                spouse: "Spouse",
                summary: "Summary",
                resetHelpMsg: "{DueDate} will be calculated, when {probate} is saved",
                downloadAssetsAndDebts: "Download Assets & Debts Zip"
            }
        },
        orders: {
            orders: "Orders",
            specificationTab: "Specifications",
            footerForOrder: {
                saveOrder: "Save Order",
                deleteOrder: "Delete"
            },
            defaultOrderTypeFilter: {
                funeral: "Funeral",
                legal: "Legal"
            },
            allOrderTypes: "All order types",
            tabs: {
                specification: "Specification",
                notes: "Note",
                log: "Log",
                notesPluralized: "Notes",
                logPluralized: "Logs"
            },
            invoice: {
                invoiceDate: "Invoice date",
                dueDate: "Due date",
                invoiceHelpText: "Set when order is moved to Invoiced.",
                invoiceSentBy: "Invoice was sent by Email",
                pauseInvoice: "Pause invoice",
                unPauseInvoice: "Unpause invoice",
                invoicePauseUntil: "Invoice paused until",
                invoiceNotPaused: "Invoice is not paused",
                billing: "Billing",
                custom: "Custom",
                days: "days",
                notInvoicedYet: "Not invoiced yet",
                notSetYet: "Not set yet"
            },
            disbursementPaymentOptions: {
                customer: "Customer",
                funeralPartnerByCheque: "Funeral Partner by cheque",
                funeralPartnerOther: "Funeral Partner Other",
                invoicedToFenix: "Invoiced to Fenix",
                fenixByPhone: "Fenix by phone",
                fDChequeAtFuneral: "FD cheque at funeral"
            },
            disbursementPayment: "DisbursementPayment",
            confirmed: "Confirmed",
            notConfirmed: "Not confirmed",
            sumExVat: "Total ex. VAT",
            vat: "VAT",
            sum: "Total",
            compensation: "Compensation",
            tags: {
                pausedUntil: "Paused until"
            },
            funeralPopupPackage: {
                funeralLabel: "Funeral",
                cremation: "Cremation",
                ceremony: "Ceremony",
                ceremonyOutsideHomeCongregation: "Outside home congregation",
                entombmentOutsideHomeCongregation: "Entombment outside home congregation",
                personalFarewell: "Personal farewell",
                personalMeeting: "In-personal planning meeting",
                yes: "Yes",
                no: "No",
                cantDecide: "Can't decide",
                upgrade: "Upgrade"
            }
        },
        calls: {
            caller: "(caller)",
            calls: "Calls",
            unknownCallLabel: "(Unknown)",
            callTabContent: {
                recording: "Recording",
                location: "Location",
                phone: "Phone",
                callerId: "Caller id",
                case: "Case",
                source: "Source",
                callCreated: "Call created",
                callTakenBy: "Call taken by",
                callVisitorData: "Visitor data"
            },
            notesTabContent: {
                addNote: "Add note",
                reset: "Reset"
            },
            logTabContent: {
                dateTime: "Timestamp",
                event: "Event",
                description: "Description",
                user: "User"
            },
            footer: {
                save: "Save call",
                delete: "Delete call"
            },
            tabs: {
                call: "Call",
                notes: "Note",
                log: "Log",
                notesPluralized: "Notes",
                logPluralized: "Logs"
            }
        },
        contacts: {
            contacts: "Contacts",
            detailsTabContent: {
                contactName: "Contact name",
                phone: "Phone",
                email: "Email",
                address: "Address",
                postalCode: "Postal code",
                city: "City",
                country: "Country",
                mailingAddress: {
                    mailingAddress: "Mailing address",
                    address: "Address",
                    postalCode: "Postal code",
                    city: "City"
                },
                type: "Type",
                picture: "Picture",
                comments: "Comments"
            },
            footer: {
                save: "Save contact",
                delete: "Delete contact"
            },
            tabs: {
                details: "Details",
                map: "Map",
                locations: "Locations"
            }
        },
        help: {
            sectionTitle: "Help",
            getHelp: "Report bug/get help/suggest new feature",
            idaKnowledgeBase: "IDA Knowledge Base"
        },
        settings: {
            settings: "Settings",
            organisation: {
                organisation: "Organisation",
                contact_email: "Contact email",
                update_logo: "Update logo",
                atleast_96_px_wide: "At least 96px wide in JPG or PNG format.",
                save_organisation: "Save organisation"
            },
            users: {
                users: "Users",
                add_user: "Add user",
                picture: "Picture",
                email: "Email",
                externalEmail: "External email",
                initials: "Initials",
                name: "Name",
                culture: "Locale",
                add_user_form: {
                    add_user: "Add user",
                    edit_user: "Edit user",
                    email: "Email",
                    externalEmail: "External email",
                    initials: "Initials",
                    name: "Name",
                    set_culture: "Set locale",
                    select_culture: "Select locale",
                    two_characters_displayed: "2 characters, displayed in tight spaces.",
                    phone_number: "Phone number",
                    enable_user: "Enable user",
                    roles: "Roles",
                    update_user: "Update user"
                }
            },
            productCategory: {
                productCategory: "Product category",
                add_product_category: "Add product category",
                name: "Name",
                slug: "Slug",
                description: "Description",
                product_category_view: "Product category view",
                product_category_name: "Product category name",
                product_category_form: {
                    add_product_category: "Add product category",
                    edit_product_category: "Edit product category",
                    product_category_name: "Product category name",
                    description: "Description",
                    slug: "Slug",
                    select_product_category_name: "Parent category",
                    select_parent_product_category_name: "Select parent category (optional)",
                    enable_category: "Enable category",
                    sections: "Sections",
                    eventTypes: "Set event types",
                    update_product_category: "Update product category",
                    showInFunnel: "Show on website categories list"
                },
                grossMargin: "Default gross margin",
                commission: "Default commission"
            },
            product: {
                product: "Product",
                add_product: "Add product",
                product_category_name: "Product category name",
                name: "Name",
                id: "Id",
                type: "Type",
                typeId: "TypeId",
                description: "Description",
                price: "Price",
                product_detail_view: "Product detail view",
                product_name: "Product name",
                max_order_amount: "Maxorderamount",
                product_category_details: "Product category details",
                category_name: "Category name",
                product_variants_details: "Product variants details",
                product_child_details: "Product child details",
                add_product_form: {
                    add_product_variants: "Add product variants",
                    add_child: "Add child",
                    product_name: "Product name",
                    category_name: "Category name",
                    select_product_category_name: "Select product category name",
                    please_select_category: "Select category"
                },
                productGrossMargin: "Gross margin",
                productCommission: "Commission",
                productStandardMargin: "Use default gross margin",
                productStandardCommission: "Use default commission"
            },
            location: "Location",
            export: "Export",
            token: "API token",
            copyToken: "Copy token",
            sendNotification: "Send message to all users",
            sendNotificationButton: "Send",
            sendNotificationPlaceholder: "Type message..."
        },
        myAccount: {
            myAccount: "My account",
            profile: {
                profile: "Profile",
                email: "Email",
                externalEmail: "External email",
                name: "Name",
                initials: "Initials",
                phone_number: "Phone number",
                two_characters: "2 characters, displayed in tight spaces.",
                go_to: "Go to ",
                security: "Security",
                to_change_your_password: " to change your password",
                square_jpg_png: "Square JPG or PNG, at least 96x96px.",
                save_profile: "Save profile",
                update_profile_picture: "Update profile picture",
                update_picture: "Update picture",
                vendor: "Vendor",
            },
            security: {
                security: "Security",
                change_password: "Change password",
                new_password: "New password",
                new_password_again: "Repeat new password",
                current_password: "Current password",
                enter_your_current_password: "Enter your current password to confirm changes.",
                update_password: "Update password"
            },
            notifications: {
                notifications: "Notices",
                notifications_empty: "No new notices",
                notifications_empty_message: "You are all caught up!",
                notifications_empty_message_2: "Nice work! Time for some tea?",
                notifications_empty_message_3: "Look at you! Woot woot!",
                notifications_empty_message_4: "Time to stretch a bit and look away from the screen!",
                notifications_empty_message_5: "You look great today. Did you do something with your hair?",
                older: "Older",
                mark_all_as_read: "Mark all as read",
                cases: {
                    cases: "Cases",
                    allNewCases: "All new cases",
                    newCaseFromCall: "New case from call",
                    newCaseFromWeb: "New case from web",
                    caseAssignedToMe: "Case assigned to me",
                    caseStatusUpdated: "Case status updated",
                    caseLabelsAddedRemoved: "Case labels added/removed",
                    caseNoteAdded: "Case note added"
                },
                orders: {
                    orders: "Orders",
                    newOrderAddedToMyCase: "New order added to my case",
                    orderStatusUpdated: "Order status updated",
                    orderLabelsAddedRemoved: "Order labels added/removed",
                    orderNoteAdded: "Order note added"
                },
                save_notifications: "Save notices"
            },
            settings: {
                settings: "Settings",
                role: "Role",
                roles: "Roles",
                contact_your_administrator: "Contact the administrator to change your role.",
                automatic_log_out: "Automatic log out",
                automatic_log_out_after_a_period_of_inactivity: "Automatically log out after a period of inactivity.",
                save_settings: "Save settings",
                darkMode: "Dark mode",
                enableDarkMode: "Enable dark mode",
                saveTheEnvoiroment: "Save both your eyes and the environment",
                locale: "Language"
            },
            compensation: {
                compensation: "Compensation",
                month: "Monthly summary",
                sendSummary: "Send"
            }
        },
        search: {
            placeholder: "Search...",
            map: "Map",
            enterToSearch: "Press Enter to search",
            clearSearch: "Clear search",
            caseSingular: "Case",
            casePlural: "Cases",
            orderSingular: "Order",
            orderPlural: "Orders",
            callSingular: "Call",
            callPlural: "Calls",
            productSingular: "Product",
            productPlural: "Products",
            contactSingular: "Contact",
            contactPlural: "Contacts",
            customerSingular: "Customer",
            locationSingular: "Location",
            locationPlural: "Locations",
            mapLegendCeremony: "Ceremony",
            mapLegendGraveyard: "Graveyard",
            mapLegendParish: "Parish",
            mapLegendHospital: "Hospital",
            all: "All"
        },
        common: {
            save: "Save",
            create: "Create",
            cancel: "Cancel",
            done: "Done",
            expandAll: "Expand all",
            collapseAll: "Collapse all",
            dropDownOptionPleaseSelect: "Select...",
            dropDownOptionTypeToSearch: "Type to search",
            dropDownOptionLoadingMessage: "Searching...",
            dropDownCreateMessage: "Create ",
            noOptionMessage: "No options",
            dropfilehere: "Drop file here",
            applyFilter: "Filter",
            resetFilter: "Reset",
            allColumnReset: "Reset all columns",
            textIs: "Freeform search:",
            ownerIs: "Owner:",
            unknown: "Unknown",
            labelIs: "Label:",
            sortingIs: "Sort by:",
            noOwner: "No owner",
            edit: "Edit",
            delete: "Delete",
            reset: "Reset",
            type: "Type",
            locked: "Locked",
            pickColor: "Pick colour",
            removeLabel: "Remove label",
            emailAddress: "Email address",
            loading: "Loading...",
            copyText: "Copy text",
            greeting: "Note",
            writeGreeting: "Write note...",
            writePlaceholder: "Write...",
            instructions: "Ceremony instructions",
            writeInstructions: "Write ceremony instructions...",
            instructionsHelpText: "Text added here is shown in instructions documents for this case.",
            yesDelete: "Yes, delete",
            noGoBack: "No, go back",
            version: "Version",
            emailSend: "Send",
            emailResend: "Resend",
            emailSendInvitation: "Send invitation",
            emailSendInvitationAgain: "Send invitation again",
            avatar: "Avatar",
            myAvatar: "My avatar",
            easterEgg: "Easter egg",
            close: "Close",
            closePanel: "Close panel",
            link: "Link",
            changeStagePopup: {
                changeStage: "Change status",
                infoCaseOrderInvoice: "The case must have an owner before moving it to Invoice",
                info: "The case must have an owner to be marked as ",
                infoSalesExternal: "As external sales you can't assign yourself as owner on the stage ",
                infoOrder: "The order must have a billing contact to change the status",
                infoCaseOrder1: "At least one order needs to be marked Order",
                infoCaseOrder2: "At least one order needs to be marked Invoiced",
                infoCaseOrder3: "All orders need to be marked Archived or Deleted",
                infoCaseOrder4: "All orders must be marked as Invoiced, Archived, or Deleted",
                infoCaseOrder5: "All orders must be marked as Deleted",
                owner: "Owner",
                ownerPlaceholder: "Select owner",
                billingContact: "Billing Contact",
                selectDeliveryMethod: "Delivery Method",
                deliveryMessage: "An invoice will be sent to the customer.",
                toShowDeliveryMethodsContactMandatoryInfo: "The billing contact must have first name, last name, address, postal code and city to be able to pay by invoice. Only UK residents can pay with Paypal.",
                creditMessage: "A credit invoice will be sent to the customer.",
                cannotMoveToStage: "Can't move to this stage",
                cannotMoveToStageWithoutOrders: "Can't move to this stage without order",
                notAuthorized: "Not Authorized",
                lossReason: "Loss reason",
                cause: "Cause",
                changeStageBtn: "Change status",
                changeStageBtnAndEmail: "Change status & send email",
                cancelBtn: "Cancel",
                error: {
                    selectOwner: "Select an owner...",
                    lossReason: "Enter loss reason...",
                    deliveryMethod: "Select delivery Method",
                    selectCounty: "Select a county..."
                }
            },
            escKeyboardShortcut: {
                text: "ESC",
                title: "Close panel (ESC)"
            },
            showNewItems: "Show new items",
            loadMoreBtn: "Load more",
            noData: "The end",
            nothingToDisplay: "Nothing to display",
            createdBy: "by",
            createdBySystem: "by IDA",
            reload: "Reload",
            add: "Add",
            remove: "Remove",
            filter: "Filter",
            searchAndFilter: "Search/Filter",
            emailSummary: "Email Summary",
            emailOnSaveCaseChanges: {
                title: "Customer needs to be notified",
                description: "You have made changes to one or more orders. Choose to communicate the changes to the customer."
            },
            sendEmail: "Send email",
            caseNotSaved: "Case not saved",
            orderNotSaved: "Order not saved",
            dontSend: "Don't send",
            emailTemplate: "Email template",
            summary: "Summary",
            guestList: "Guest list",
            documents: "Documents",
            caseEmail: {
                summary: "Summary",
                guestList: "Guest list",
                documents: "Documents"
            },
            orderEmail: {
                summary: "Summary",
                proposal: "Quote",
                proposalUpdated: "Quote update",
                confirmed: "Order confirmation",
                orderUpdated: "Order update",
                invoiceCreated: "Invoice created",
                manualSignup: "Signup"
            },
            existingContact: "Existing contact",
            custom: "Custom",
            recipient: "Recipient",
            message: "Message",
            messageOptional: "Message (optional)",
            subject: "Subject",
            microsoftNavId: "Microsoft NAV-ID",
            customerAccountId: "Customer Account Id",
            mapLocation: "Current location",
            uploadPicture: "Upload picture",
            automaticallySaved: "Saved automatically",
            play: "Play",
            pause: "Pause",
            speed100: "100% playback speed",
            speed125: "125% playback speed",
            speed150: "150% playback speed",
            unit: {
                pieces: "pcs",
                kronor: "kr",
                gbp: "GBP",
                people: "ppl",
                percent: "%",
                sek: "SEK"
            },
            unitFull: {
                pieces: "Number of pieces",
                kronor: "Price in kronor",
                gbp: "Price in gbp",
                people: "Number of people",
                percent: "Percent"
            },
            objectType: {
                case: "Case",
                order: "Order",
                call: "Call",
                product: "Product",
                location: "Location",
                contact: "Contact",
                todo: "Todo",
                document: "Document"
            },
            sending: "Sending...",
            nps: {
                title: "Net Promoter Score",
                class: "Class",
                score: "Score",
                comment: "Comment",
                contact: "Contact",
                classPromoter: "Promoter",
                classPassive: "Passive",
                classDetractor: "Detractor",
                classUnknown: "Unknown",
                commentEmpty: "No comment",
                requestSent: "Review request sent"
            },
            document: {
                document: "Document",
                documents: "Documents",
                order: "Order",
                created: "Created",
                status: "Status",
                visibleToCustomer: "Visible To Customer",
                documentUpdated: "Document Updated",
                emailSelected: "Email selected",
                email: "Email",
                cancel: "Cancel",
                save: "Save",
                documentType: "Document type",
                fileGenerateDocument: "Generate document",
                fileUploadDocument: "Upload document",
                fileUploadDocumentOr: "or",
                fileDragDropToUpload: "Drag and drop file here to upload",
                fileTypeAllowed: "Allowed: pdf, jpg, jpeg, png, gif, bmp",
                download: "Download",
                edit: "Edit",
                newVersion: "New version",
                viewLog: "View log",
                delete: "Delete",
                categorySubCategoryDocumentTypeNotFound: "Not found: Category>SubCategory>DocumentType",
                chooseFile: "Choose file",
                rejectedFile: "File couldn't be uploaded",
                dropReleaseHelpText: "Release to upload file",
                pageXOfXPage: "Page",
                pageXOfXOf: "of",
                loader: {
                    docGeneration: "Generating document...",
                    docGenerationSuccess: "Document generated",
                    docFetching: "Fetching document...",
                    docFetchingFailure: "File can't be previewed, unknown format",
                    docLoading: "Loading document...",
                    generatedDocSavedSuccess: "Saved",
                    docUploading: "Uploading document...",
                    docUploadingSuccess: "Uploaded",
                    docDeleting: "Deleting document...",
                    docPreview: "Document preview"
                },
                selectOrderDropDown: "Select order",
                optional: "optional",
                documentLog: "Log",
                orderRequiredForFurtherAction: "To proceed, please add an order.",
                downloadDocx: "Download Docx",
                includeAttachments: "Include attachments",
                probateDocumentUploadWarning: "Only pdf can be uploaded for this type"
            },
            timestamp: "Timestamp",
            and: "and",
            todo: {
                save: "Save",
                cancel: "Cancel",
                remove: "Remove",
                edit: "Edit",
                description: "Description",
                descriptionPlaceholder: "Type to create new or add template...",
                descriptionCreatePlaceholder: "Type to create new template...",
                deadline: "Deadline",
                deadlinePlaceholder: "None",
                timeStamp: "Marked done",
                showAll: "Show all",
                showLatestThree: "Show three latest",
                showDetails: "Show details",
                hideDetails: "Hide details",
                status: "Status",
                statusNormal: "Normal",
                statusDueSoon: "Due soon",
                statusDue: "Due",
                statusOverDue: "Overdue",
                statusDone: "Done",
                selectedTemplateWarning: "You have selected an already selected todo template - do you want to continue?"
            },
            loadingLog: "Fetching log...",
            somethingWentWrong: "Something went wrong!",
            updateProfile: "Profile updated"
        },
        lockPage: {
            banner: {
                messages: {
                    reloadToSeeLatestData: "Reload to see latest data",
                    dataOutdated: "Data outdated",
                    currentlyBeingEditedBy: "This case is currently being edited by"
                },
                buttons: {
                    takeOver: "Take over editing",
                    takeOverToolTip: "Take over from current editor",
                    reload: "Reload",
                    reloadToolTip: "Fetch latest changes"
                }
            },
            accessType: {
                owner: "owner",
                viewing: "viewing",
                editing: "editing"
            },
            takeOverPopup: {
                header: "The current editor may lose data",
                content: "may be editing this document right now and could lose their changes. Are you sure want to continue?",
                okBtn: "Yes, take over",
                editor: "Editor"
            }
        },
        staticData: {
            stages: {
                order: {
                    all: {
                        text: "All"
                    }
                },
                orderType: {
                    funeralWithCeremony: {
                        textShort: "With ceremony",
                    },
                    funeralWithoutCeremony: {
                        textShort: "Without ceremony",
                    }
                },
                eventType: {
                    all: {
                        text: "All",
                        description: "All"
                    },
                    ceremony: {
                        text: "Ceremony",
                        description: "Ceremony"
                    },
                    entombment: {
                        text: "Entombment",
                        description: "Entombment"
                    },
                    reception: {
                        text: "Reception",
                        description: "Reception"
                    },
                    preparation: {
                        text: "Preparation",
                        description: "Preparation"
                    }
                }
            },
            contacts: {
                personType: {
                    all: {
                        text: "All",
                        description: "Alla description"
                    },
                    admin: {
                        text: "Admin",
                        description: "Admin description"
                    },
                    ambassador: {
                        text: "Ambassador",
                        description: "Ambassador description"
                    },
                    agent: {
                        text: "Agent",
                        description: "Agent description"
                    }
                }
            },
            productTypes: {
                normalProduct: {
                    text: "Normal product",
                    description: "Normal product, ie products that can be sold separately"
                },
                productWithVariants: {
                    text: "Product with variants",
                    description: "Product with variants, ie the main product"
                },
                variantProduct: {
                    text: "Variant product",
                    description: "Variant product, always part of a product with variants"
                },
                parentProduct: {
                    text: "Parent product",
                    description: "Parent product, ie a product that can be sold separately but has possible child products"
                },
                childProduct: {
                    text: "Child product",
                    description: "Child product, ie a product that can only be sold with a parent product"
                },
                customProduct: {
                    text: "Custom product",
                    description: "Custom product, ie a product that is sold separately with a custom name and price"
                }
            },
            locationTypes: {
                ceremonyLocation: {
                    text: "Ceremony location",
                    description: "Ceremony locations, such as church and chapels"
                },
                graveyard: {
                    text: "Graveyards",
                    description: "Graveyards"
                },
                parishHome: {
                    text: "Parish home",
                    description: "Parish home"
                },
                hospital: {
                    text: "Hospital",
                    description: "Hospitals and morgues"
                },
                custom: {
                    text: "Custom",
                    description: "Custom locations"
                },
                crematorium: {
                    text: "Crematorium",
                    description: "Crematorium"
                },
                naturalBurialGround: {
                    text: "Natural burial ground",
                    description: "Natural burial ground"
                },
                funeralDirector: {
                    text: "Funeral director",
                    description: "Funeral director"
                },
                chapel: {
                    text: "Chapel",
                    description: "Chapel"
                }
            },
            filter: {
                add: "Apply to column",
                allColumnSearch: "Apply to all columns",
                freeText: {
                    text: "Search for...",
                    description: "Freeform search",
                    tooltip: "Search"
                },
                stage: {
                    text: "Status",
                    description: "Select which statuses to show in the column"
                },
                orderStage: {
                    text: "Order status",
                    description: "Select which order statuses to show in the column"
                },
                label: {
                    text: "Label",
                    description: "Select which labels to show in the column"
                },
                type: {
                    text: "Type",
                    description: "Select which types to show in the column"
                },
                orderType: {
                    text: "Order type",
                    description: "Select which order types to show in the column"
                },
                productCategory: {
                    text: "Product category",
                    description: "Select which product category to show in the column"
                },
                productType: {
                    text: "Product type",
                    description: "Select which product types to show in the column"
                },
                withOrWithoutCase: {
                    text: "With or without case",
                    description: "Select calls with or without case",
                    values: {
                        withCase: {
                            text: "With case",
                            description: "With case"
                        },
                        withoutCase: {
                            text: "Without case",
                            description: "Without case"
                        }
                    }
                },
                callTakenByUser: {
                    text: "Call taken by",
                    description: "Select calls taken by a specific user"
                },
                caseAssignedToUser: {
                    text: "Owner",
                    description: "Select cases assigned to a specific user"
                },
                locationPlace: {
                    text: "Location County or City",
                    description: "Select which County or City to show in the column"
                },
                locationType: {
                    text: "Location type",
                    description: "Select which location types to show in the column"
                },
                personType: {
                    text: "Person type",
                    description: "Select which person types to show in the column"
                },
                ashesInstruction: {
                    text: "Ashes instruction",
                    description: "Select which Ashes instructions to show in the dashboard"
                },
                fdPartner: {
                    text: "FD partner",
                    description: "Select funeral partner to show in the dashboard"
                }
            },
            sort: {
                idAsc: {
                    text: "Id, ascending",
                    description: "Sort on id, ascending"
                },
                idDesc: {
                    text: "Id, descending",
                    description: "Sort on id, descending"
                },
                typeAsc: {
                    text: "Type, ascending",
                    description: "Sort on type, ascending"
                },
                typeDesc: {
                    text: "Type, descending",
                    description: "Sort on type, descending"
                },
                dateAsc: {
                    text: "Date, ascending",
                    description: "Sort on date, ascending"
                },
                dateDesc: {
                    text: "Date, descending",
                    description: "Sort on date, descending"
                },
                deadlineAsc: {
                    text: "Deadline, ascending",
                    description: "Sort on deadline, ascending"
                },
                deadlineDesc: {
                    text: "Deadline, descending",
                    description: "Sort on deadline, descending"
                },
                deceasedNameAsc: {
                    text: "Deceased name, alphabetically",
                    description: "Sort on deceased name alphabetically"
                },
                deceasedNameDesc: {
                    text: "Deceased name, alphabetically reversed",
                    description: "Sort on deceased name alphabetically in reversed order"
                },
                productNameAsc: {
                    text: "Product name, alphabetically",
                    description: "Sort on product name alphabetically"
                },
                productNameDesc: {
                    text: "Product name, alphabetically reversed",
                    description: "Sort on product name alphabetically in reversed order"
                },
                productTypeAsc: {
                    text: "Product type, alphabetically",
                    description: "Sort on product type alphabetically"
                },
                productTypeDesc: {
                    text: "Product type, alphabetically reversed",
                    description: "Sort on product type alphabetically in reversed order"
                },
                productCategoryAsc: {
                    text: "Product category, alphabetically",
                    description: "Sort on product category alphabetically"
                },
                productCategoryDesc: {
                    text: "Product category, alphabetically reversed",
                    description: "Sort on product category alphabetically in reversed order"
                },
                locationNameAsc: {
                    text: "Location name, alphabetically",
                    description: "Sort on location name alphabetically"
                },
                locationNameDesc: {
                    text: "Location name, alphabetically reversed",
                    description: "Sort on location name alphabetically in reversed order"
                },
                contactNameAsc: {
                    text: "Contact name, alphabetically",
                    description: "Sort on contact name alphabetically"
                },
                contactNameDesc: {
                    text: "Contact name, alphabetically reversed",
                    description: "Sort on contact name alphabetically in reversed order"
                },
                customNameAsc: {
                    text: "{CUSTOM_NAME}, alphabetically",
                    description: "Sort on {CUSTOM_NAME} alphabetically"
                },
                customNameDesc: {
                    text: "{CUSTOM_NAME}, alphabetically reversed",
                    description: "Sort on {CUSTOM_NAME} alphabetically in reversed order"
                }
            },
            webSocketNotificationTypes: {
                newCaseFromCall: {
                    message: "New case created from call"
                },
                newCaseFromWeb: {
                    message: "New case created from web"
                },
                caseAssignedUser: {
                    message: "Case assigned"
                },
                caseStageUpdate: {
                    message: "Case status updated"
                },
                caseLabelAdded: {
                    message: "New case label"
                },
                caseLabelRemoved: {
                    message: "Case label removed"
                },
                caseNoteAdded: {
                    message: "New case note"
                },
                orderAddedToCase: {
                    message: "New order"
                },
                orderStageUpdated: {
                    message: "Order status updated"
                },
                orderLabelAdded: {
                    message: "New order label"
                },
                orderLabelRemoved: {
                    message: "Order label removed"
                },
                orderNoteAdded: {
                    message: "New order note"
                },
                newCaseFromIdaUi: {
                    message: "New case created from IDA"
                },
                adminMessageFromIdaUi: {
                    message: "Message from Admin"
                },
                npsResponseNotification: {
                    message: "NPS response"
                },
                overdueTodo: {
                    message: "Todo overdue"
                },
                orderConfirmedFromPublicWeb: {
                    message: "Order confirmed from public web"
                },
                orderUpdated: {
                    message: "Order updated"
                },
                callAddedToCase: {
                    message: "Call added to case"
                },
                memorialMemoryReported: {
                    message: "A memory is reported"
                },
                customerMessage: {
                    message: "New message received on order"
                },
                caseCollaborator: {
                    message: "Case collaborator updated"
                }
            }
        },
        successMessages: {
            success: "Success",
            deleted: "Deleted",
            passwordUpdated: "Password changed. Please log in again.",
            productUpdated: "Product updated",
            locationUpdated: "Location updated",
            contactAdded: "Contact added",
            contactUpdated: "Contact updated",
            caseAdded: "Case added",
            caseUpdated: "Case updated",
            callUpdated: "Call updated",
            notificationsSaved: "Notifications settings saved",
            orderUpdated: "Order updated",
            downloadSuccessful: "Download complete"
        },
        infoMessages: {
            popup: {
                unsavedChanges: "You have unsaved changes, are you sure you want to leave?",
                deleteRelative: "Are you sure you want to delete the relative",
                deleteCall: "Are you sure you want to delete the call",
                deleteCase: "Are you sure you want to delete the case",
                deleteOrder: "Are you sure you want to delete the order",
                deleteContact: "Are you sure you want to delete the contact",
                deleteLocation: "Are you sure you want to delete the location",
                deleteProduct: "Are you sure you want to delete the product",
                deleteProductCategory: "Are you sure you want to delete the product category",
                deleteUser: "Are you sure you want to delete the user",
                deleteDocument: "Are you sure you want to delete the document",
                delete: "Are you sure you want to delete?",
                emailWillBeSent: "Email will be sent",
                emailWillBeSentAreYouSure: "Email will be sent, are you sure?",
                okBtn: "Ok",
                saveBtn: "Save",
                saveAndEmailBtn: "Save and send email",
                cancelBtn: "Cancel",
                closeBtn: "Close",
                deleteGuest: "Are you sure want to delete guest?",
                unsavedMessageChanges: "You have unsaved message changes. Are you sure you want to leave?",
                visibleToCustomer: {
                    title: "{SHARE_CONDITIONALLY} this with the customer?",
                    content: "\"{DOCUMENT_NAME}\" created at {CREATED_TEXT} {VISIBLE_CONDITIONALLY} to the customer",
                    noBtn: "No",
                    share: "Want to share",
                    unShare: "Do not want to share",
                    visible: "will be visible",
                    notVisible: "will not be visible",
                },
            }
        },
        warningMessages: {
            call: {
                addNotes: "Add a note"
            },
            case: {
                addPhoneNumber: "Add a phone number",
                addGuestPhoneNumber: "Add a phone number for the guest",
                addName: "Add a name",
                addCorrectEmail: "Add a valid email address",
                addCorrectEmailGuest: "Add a valid email address for the guest",
                addCorrectPhone: "Add a valid phone number",
                addCorrectPhoneGuest: "Add a valid phone number for the guest",
                addGuestName: "Add a name for the guest",
                addNotes: "Write a note",
                selectRelative: "Select a relative",
                noProductsForCategory: "There are no products in this category",
                addCustomName: "Add a name for the custom product without spaces before or after text",
                addFoodRestriction: "Added food restriction does not have any data",
                addNameWithNoSpaces: "Add name with no spaces",
                addPersonNumberWithNoSpaces: "Add person number with no spaces",
                addAddressWithNoSpaces: "Add address with no spaces",
                addCityWithNoSpaces: "Add city with no spaces",
                addPostalCodeWithNoSpacesAndNumbersOnly: "Add Postal code in numbers with no spaces",
                onlyNumberAndNoSpaceError: "Only numbers 0-9 allowed without spaces",
                noSpaceError: "No spaces allowed before or after text",
                requiredField: "{fieldName} is a required field",
                emailError: "Email format required (example@gmail.com) without spaces",
                postalCodeError: "Postal code format required (12345, 123 45)",
                personNumberError: "Person number format required (YYYYMMDD-XXXX)",
                addressLengthRestriction: "Address can not exceed 90 characters",
                mobileNumberError: "Mobile number not valid",
                ukPostalCodeError: "Postal code format required (PE 284P,L18JQ,PE28 4PW)",
            },
            location: {
                selectLocationType: "Select a location type"
            },
            locationName: {
                selectLocationName: "Add a location name"
            },
            placeId: {
                selectPlace: "Add a location"
            },
            contact: {
                selectPersonType: "Select contact type"
            },
            noPermissionToDoChanges: "You don't have permission to do the changes",
            cannotSetAlphabets: "Can't set alphabets",
            noOrdersSelected: "At least one order is required to send an email",
            imageSizeWarning: "Image height and width must be at least 96px",
            productMissingInOrder: "Product missing in order",
            quantityMissing: "Quantity of product can't be empty",
            priceMissing: "Price of product can't be empty",
            invalidPercentage: "Percent value should be between 0 to 100"
        },
        errorMessages: {
            user: {
                userIsNotActive: "User is not active. Contact the system administrator.",
                passwordsDoNotMatch: "Passwords don't match",
                invalidEmailAddress: "That doesn't look like a valid email address",
                selectUserRole: "Select user role",
                invalidCredentials: "Could not log in. Check your user credentials!"
            },
            product: {
                selectProductType: "Select product type",
                specifyProductName: "Select product name",
                selectProductCategory: "Select product category"
            },
            case: {
                parishNameNotFound: "Parish name not found"
            }
        },
        log: {
            showDetails: "Show details",
            hideDetails: "Hide details",
            unknown: "The log item could not be displayed",
            caseAdded: "New case",
            caseRemoved: "Case removed",
            statusUpdated: "Status updated",
            caseAssigned: "Case assigned",
            caseCollaborator: "Case collaborator",
            caseOwnerUpdated: "Case owner updated",
            labelAdded: "New label",
            labelsAdded: "New labels",
            labelRemoved: "Label removed",
            labelsRemoved: "Labels removed",
            deceasedUpdated: "Deceased updated",
            contactAdded: "New contact",
            contactUpdated: "Contact updated",
            mainContactUpdated: "Main contact updated",
            contactRemoved: "Contact removed",
            beneficiaryRemoved: "Beneficiary Removed",
            registrationAdded: "New registration",
            registrationUpdated: "Registration updated",
            registrationRemoved: "Registration removed",
            emailSent: "Email sent",
            todoAdded: "New todo",
            todoUpdated: "Todo updated",
            todoDone: "Todo done",
            todoRemoved: "Todo removed",
            orderAdded: "New order",
            orderUpdated: "Order updated",
            orderRemoved: "Order removed",
            callAdded: "New call",
            callRemoved: "Call removed",
            noteAdded: "New note",
            noteRemoved: "Note removed",
            documentAdded: "New document",
            documentUpdated: "Document updated",
            documentRemoved: "Document removed",
            documentUploaded: "Document uploaded",
            documentEmailSent: "Document email sent",
            bcUpdated: "BC updated",
            bcError: "BC Error",
            probateReviewer: "Probate Reviewer",
            probateCaseConnected: "Probate case connected",
            lossReason: "Loss reason",
            messageAdded: "Message Added",
            updatedMessage: "Message Updated",
            messageRemoved: "Message Removed",
            filter: {
                event: "Event",
                person: "Person",
                dateFrom: "Dates",
                dateTo: "",
                search: "Details",
                placeholder: {
                    event: "None selected",
                    person: "None selected",
                    dateFrom: "From...",
                    dateTo: "To...",
                    search: "Type to search..."
                }
            },
            column: {
                timestamp: "Date/time",
                event: "Event",
                details: "Details",
                user: "User"
            },
            key: {
                unknown: "–",
                type: "Type",
                order: "Order",
                recipient: "Recipient",
                email: "Email",
                message: "Message",
                deadline: "Deadline",
                version: "Version"
            },
            value: {
                none: "–",
                unknown: "–"
            }
        },
        yesNoUnknownSelectOptions: {
            yes: "Yes",
            no: "No",
            unknown: "Unknown"
        },
        stageValidation: {
            generalMessage: "Case stage cannot be changed. Please action the below:",
            deliverStageRequiresServiceDate: "Committal/Burial date has not been entered in the Order tab. Please complete this field before proceeding."
        }
    }
}

export default English;
