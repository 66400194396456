import PropTypes from "prop-types";
import connect from "react-redux/lib/connect/connect";
import React from "react";
import ConnectExistingProbateCaseView from "./connectExistingProbateCaseView";
import {Lang} from "../../../../common/appUtil";

function ConnectExistingProbateCase({sourceCaseId, probateCaseId, showCreateButton, showExistingProbateCasePopup, dispatch}) {
    return (<>
        {
            showCreateButton
            && <ConnectExistingProbateCaseView
                title={Lang().cases.probates.connectAnExistingProbateCase.buttonText}
                sourceCaseId={sourceCaseId}
                probateCaseId={probateCaseId}
                shouldShowPopup={showExistingProbateCasePopup}
                dispatch={dispatch}
            />
        }
    </>);
}

ConnectExistingProbateCase.propTypes = {
    showCreateButton: PropTypes.bool,
    sourceCaseId: PropTypes.number,
    probateCaseId: PropTypes.any
}

function mapStateToProps(state) {
    return {showExistingProbateCasePopup: state.probateReducer.showExistingProbateCasePopup}
}

export default connect(mapStateToProps)(ConnectExistingProbateCase);
