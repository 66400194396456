import {
    ADD_BENEFICIARY,
    ADD_TO_PROBATE_LIST_PROPERTY,
    REMOVE_PROBATE_ITEM,
    REMOVE_QA_BENEFICIARIES_BY_ID,
    REMOVE_QA_BENEFICIARIES_BY_SEARCH,
    REORDER_NOTES,
    SAVE_PROBATE_TEMP_NOTE,
    SET_PROBATE_PROPERTIES,
    SPLIT_PROBATE_PROPERTY,
    UPDATE_APPROVAL_CHECKS,
    UPDATE_APPROVAL_CHECKS_IS_EDITABLE,
    UPDATE_DOCUMENT_ID_FOR_PROPERTIES,
    UPDATE_DUE_DATE_BUTTON,
    UPDATE_HAS_PROBATE_CHANGED,
    UPDATE_MEETING_INFO_REVIEWER_INFO,
    UPDATE_ORDER_CHANGE_AFTER_SAVE_POPUP_INFO,
    UPDATE_PROBATE,
    UPDATE_PROBATE_COMMON_NOTES,
    UPDATE_PROBATE_GENERIC_PROPERTY,
    UPDATE_PROBATE_PROPERTY,
    UPDATE_PROBATE_PROPERTY_BY_SEARCH,
    UPDATE_PROBATE_ROOT_PROPERTY,
    UPDATE_QA_BENEFICIARIES_BY_SEARCH,
    UPDATE_QA_BENEFICIARIES_FOR_PERSONAL_NUMBER
} from "./types";
import {ProbateProps} from "../ui/tabProbateMeeting/util";


export function updateProbate(payload, isServerResponse = true) {
    return {type: UPDATE_PROBATE, payload: payload, isServerResponse: isServerResponse};
}

export function clearProbate() {
    return {type: UPDATE_PROBATE, payload: {}, isServerResponse: false};
}

export function updateOrderChangeAfterSavePopupInfo(caseId, relatives, respOrders) {
    return {type: UPDATE_ORDER_CHANGE_AFTER_SAVE_POPUP_INFO, caseId, relatives, respOrders};
}

export function updateMeetingInfoProbateProperty(childProperty, payload) {
    return updateProbateProperty(ProbateProps.meetingInfo, childProperty, payload);
}

export function updateProbateRootProperty(property, payload) {
    return {type: UPDATE_PROBATE_ROOT_PROPERTY, property, payload};
}

export function updateProbateProperty(rootProperty, childProperty, payload) {
    return {type: UPDATE_PROBATE_PROPERTY, rootProperty: rootProperty, childProperty: childProperty, payload: payload};
}

/***
 * @description: beneficiaries helper
 */
export function updateBeneficiaryProperty(childProperty, id, index, payload) {
    return updateProbatePropertyBySearch(ProbateProps.beneficiaries, childProperty, id, index, payload);
}

export function resetProbateBeneficiaries(payload) {
    return {type: UPDATE_PROBATE_ROOT_PROPERTY, property: ProbateProps.beneficiaries, payload};
}

export function updateInformationProperty(childProperty, id, index, payload) {
    return updateProbatePropertyBySearch(ProbateProps.informations, childProperty, id, index, payload);
}

export function updateNoteProperty(childProperty, id, index, payload) {
    return updateProbatePropertyBySearch(ProbateProps.notes, childProperty, id, index, payload);
}

export function updatePropertyDocumentId(childProperty, subParentProperty, parentProperty, id, index, payload) {
    return {
        type: UPDATE_DOCUMENT_ID_FOR_PROPERTIES,
        rootProperty: ProbateProps.property,
        parentProperty,
        subParentProperty,
        childProperty,
        id,
        index,
        payload
    }
}

export function updateProbatePropertyBySearch(rootProperty, childProperty, id, index, payload) {
    return {type: UPDATE_PROBATE_PROPERTY_BY_SEARCH, rootProperty, childProperty, id, index, payload};
}

export function updateMeetingInfoReviewerInfo(keyValues) {
    return {type: UPDATE_MEETING_INFO_REVIEWER_INFO, rootProperty: ProbateProps.meetingInfo, keyValues: keyValues};
}

export function showCreateProbatePopup() {
    return showProbatePopup(ProbateProps.showCreateProbatePopup);
}

export function hideCreateProbatePopup() {
    return hideProbatePopup(ProbateProps.showCreateProbatePopup);
}

export function showQuestionnaireProbatePopup() {
    return showProbatePopup(ProbateProps.showQuestionnairePopup);
}

export function hideQuestionnaireProbatePopup() {
    return hideProbatePopup(ProbateProps.showQuestionnairePopup);
}

export function showDeleteQuestionnaireBeneficiaryPopup() {
    return showProbatePopup(ProbateProps.showDeleteQuestionnaireBeneficiaryPopup);
}

export function hideDeleteQuestionnaireBeneficiaryPopup() {
    return hideProbatePopup(ProbateProps.showDeleteQuestionnaireBeneficiaryPopup);
}

export function hideOrderChangeAfterSavePopup() {
    return hideProbatePopup(ProbateProps.showOrderChangeAfterSavePopup);
}

function showProbatePopup(key) {
    return {type: UPDATE_PROBATE_GENERIC_PROPERTY, payload: true, key};
}

function hideProbatePopup(key) {
    return {type: UPDATE_PROBATE_GENERIC_PROPERTY, payload: false, key};
}

export function showProbateCommonNotesPopup() {
    return showProbatePopup(ProbateProps.showCommonNotesPopup);
}

export function hideProbateCommonNotesPopup() {
    return hideProbatePopup(ProbateProps.showCommonNotesPopup);
}

export function showExistingProbateCasePopup() {
    return showProbatePopup(ProbateProps.showExistingProbateCasePopup);
}

export function hideExistingProbateCasePopup() {
    return hideProbatePopup(ProbateProps.showExistingProbateCasePopup);
}

export function updateCommonNotes(payload) {
    return {type: UPDATE_PROBATE_GENERIC_PROPERTY, payload: payload, key: ProbateProps.probateCommonNotes};
}

export function updateProbateCommonNotes(payload, noteId) {
    return {type: UPDATE_PROBATE_COMMON_NOTES, payload: payload, noteId: noteId};
}

export function createProbateNotesContent(payload, onTop = true) {
    return addToProbateListProperty(ProbateProps.notes, payload, onTop);
}

export function addToProbateListProperty(property, payload, onTop = false) {
    return {type: ADD_TO_PROBATE_LIST_PROPERTY, property, payload, onTop};
}

export function saveProbateTempNote(payload) {
    return {type: SAVE_PROBATE_TEMP_NOTE, payload: payload};
}

export function removeProbateNotesItem(payload, index) {
    return removeProbateItem(ProbateProps.notes, payload, index);
}

export function removeProbateItem(property, payload, index) {
    return {type: REMOVE_PROBATE_ITEM, property, payload, index}
}

export const updateProbateNote = (id, index, payload) => {
    return updateProbatePropertyBySearch(ProbateProps.notes, ProbateProps.note, id, index, payload);
}

export function updateHasProbateChanged(payload) {
    return {type: UPDATE_HAS_PROBATE_CHANGED, payload};
}

export function updateIsProbateQAsChanged(payload) {
    return updateProbateRootProperty(ProbateProps.isProbateQAsChanged, payload);
}

export function updateIsValidProbateQA(beneficiaryId, questionId, payload) {
    return updateQABeneficiariesProperty(ProbateProps.isValid, beneficiaryId, questionId, payload);
}

export const updateIsQABeneficiariesChanged = (payload) => {
    return {type: UPDATE_PROBATE_GENERIC_PROPERTY, key: ProbateProps.isQABeneficiariesChanged, payload: payload};
}

export function setProbateEnum(payload) {
    return {type: UPDATE_PROBATE_GENERIC_PROPERTY, key: ProbateProps.enums, payload: payload};
}

/**
 * @description: Show or hide popup
 */
export function showBeneficiaryDeletePopup() {
    return {type: UPDATE_PROBATE_GENERIC_PROPERTY, key: ProbateProps.showDeleteProbateBeneficiaryPopup, payload: true};
}

export function hideBeneficiaryDeletePopup() {
    return {type: UPDATE_PROBATE_GENERIC_PROPERTY, key: ProbateProps.showDeleteProbateBeneficiaryPopup, payload: false};
}


export function showDeceasedPropertyDeletePopup() {
    return showAssetsDebtsDeletePopup(ProbateProps.showDeleteProbateDeceasedPropertyPopup);
}

export function showSpousePropertyDeletePopup() {
    return showAssetsDebtsDeletePopup(ProbateProps.showDeleteProbateSpousePropertyPopup);
}

export function hideDeceasedPropertyDeletePopup() {
    return hideAssetsDebtsDeletePopup(ProbateProps.showDeleteProbateDeceasedPropertyPopup);
}

export function hideSpousePropertyDeletePopup() {
    return hideAssetsDebtsDeletePopup(ProbateProps.showDeleteProbateSpousePropertyPopup);
}

function showAssetsDebtsDeletePopup(key) {
    return {type: UPDATE_PROBATE_GENERIC_PROPERTY, key: key, payload: true};
}

function hideAssetsDebtsDeletePopup(key) {
    return {type: UPDATE_PROBATE_GENERIC_PROPERTY, key: key, payload: false};
}

export function setProbateProperty(keyValues, parentProperty) {
    return {type: SET_PROBATE_PROPERTIES, rootProperty: ProbateProps.property, parentProperty, keyValues: keyValues};
}

/**
 * @description: Common document handler popup
 */

export function showDocumentHandlerPopup() {
    return {type: UPDATE_PROBATE_GENERIC_PROPERTY, key: ProbateProps.showDocumentHandlerPopup, payload: true};
}

export function hideDocumentHandlerPopup() {
    return {type: UPDATE_PROBATE_GENERIC_PROPERTY, key: ProbateProps.showDocumentHandlerPopup, payload: false};
}

/**
 * @description: Handle enable/disable of due date buttons (ie: edit, cancel and reset)
 */
export function updateDueDateEditButton(payload) {
    return updateDueDateButton(ProbateProps.editButton, ProbateProps.resetButton, payload);
}

export function updateDueDateResetButton(payload) {
    return updateDueDateButton(ProbateProps.resetButton, ProbateProps.editButton, payload);
}

function updateDueDateButton(buttonProperty1, buttonProperty2, payload) {
    return {
        type: UPDATE_DUE_DATE_BUTTON,
        rootProperty: ProbateProps.meetingInfo,
        property: ProbateProps.dueDateInfo,
        payload,
        buttonProperty1,
        buttonProperty2
    };
}

export function addQABeneficiary(payload) {
    return {type: ADD_BENEFICIARY, payload, property: ProbateProps.beneficiaries};
}

export const updateQABeneficiariesCaseRelative = (property, beneficiaryId, questionId, payload) => {
    return updateQABeneficiariesBySearch(ProbateProps.caseRelative, property, beneficiaryId, questionId, payload);
}

export const updateQABeneficiariesProperty = (property, beneficiaryId, questionId, payload) => {
    return updateQABeneficiariesBySearch(property, null, beneficiaryId, questionId, payload);
}


//beneficiary properties ie: name, id,
function updateQABeneficiariesBySearch(rootProperty, childProperty, beneficiaryId, questionId, payload) {
    return {
        type: UPDATE_QA_BENEFICIARIES_BY_SEARCH,
        parentRootProperty: ProbateProps.beneficiaries,
        rootProperty,
        childProperty,
        beneficiaryId,
        questionId,
        payload
    };
}

export function updateQABeneficiariesForPersonalNumber(beneficiaryId, questionId, payload) {
    return {
        type: UPDATE_QA_BENEFICIARIES_FOR_PERSONAL_NUMBER,
        rootProperty: ProbateProps.beneficiaries,
        property: ProbateProps.caseRelative,
        payload,
        beneficiaryId,
        questionId
    };
}

export function removeQABeneficiariesBySearch(beneficiaryId, questionId) {
    return {
        type: REMOVE_QA_BENEFICIARIES_BY_SEARCH,
        property: ProbateProps.beneficiaries,
        beneficiaryId,
        questionId
    };
}

/***
 * @description: When removing from QABeneficiary where questionId = questionId && memberId = 0
 memberId = 0 as only beneficiaries added from QA popup should be removed without altering beneficiaries tab addition
 */
export const removeQABeneficiariesById = (questionId, memberId = 0) => {
    return {
        type: REMOVE_QA_BENEFICIARIES_BY_ID,
        property: ProbateProps.beneficiaries,
        questionId,
        key: ProbateProps.questionId,
        key2: ProbateProps.memberId,
        memberId,
    }
};

export const splitProbateProperty = (sourceItem, propertySubGroup, atIndex) => {
    return {type: SPLIT_PROBATE_PROPERTY, sourceItem, propertySubGroup, atIndex};
}

/***
 * - copyList = [...list]
 * - get 'source_Data' = from copyList[e.source.index];
 * - add 'source_Data' to copyList[e.destination.index]
 * - update list = copyList
 */
export const reorderNotes = (srcIndex, destIndex) => {
    return {
        type: REORDER_NOTES,
        rootProperty: ProbateProps.notes,
        property: ProbateProps.sortOrder,
        srcIndex,
        destIndex
    };
}

export const updateApprovalCheck = (childProperty, id, payload) => {
    return {
        type: UPDATE_APPROVAL_CHECKS,
        rootProperty: ProbateProps.meetingInfo,
        parentProperty: ProbateProps.approvalChecks,
        childProperty,
        id,
        payload
    }
}

export const updateApprovalChecksIsEditable = () => {
    return {
        type: UPDATE_APPROVAL_CHECKS_IS_EDITABLE,
        rootProperty: ProbateProps.meetingInfo,
        parentProperty: ProbateProps.approvalChecks,
        childProperty: ProbateProps.isEditable,
    }
}