import React from "react";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as actionMaster from "../../actions/uiAction";
import * as orderAction from "../../actions/orderAction";
import * as caseAction from "../../actions/caseAction";
import * as caseOrderAction from "../../actions/caseOrderAction";
import {reduxForm} from "redux-form";
import {withRouter} from "react-router-dom";
import PreLoader from "../common/preloader";
import Popup from "../common/popups/popup";
import Utility from "../../api/utilLanguage";
import FooterWithSave from "../common/footer";
import {bottomCenter} from "../common/customToastr";
import OrderUtil from "../common/orderUtil";
import DisplayNothing from "../common/nothingToDisplay";
import AccessControl from "../../api/accessControl";
import OrderDraggingUtil from "../common/orderDraggingUtil";
import PopupForOrderStageChange from "../common/popups/popupForOrderStageChange";
import ValidationUtil from "../common/validationUtil";
import AppUtil from "../common/appUtil";
import OrderColumnUtil from "../common/orderColumnUtil";
import PopupForOrderEmail from "../common/popups/popupForOrderEmail";
import PopupAfterCaseSave from "../common/popups/popupAfterCaseSave";
import OrderComponent from "../case/caseTenantBasedOrder/OrderComponent";
import CaseOrderHeaderTitle from "../case/caseOrderSubComponents/caseOrderHeaderTitle";
import {ProductTypes} from "../common/productUtil";

class OrderSpecificationComponent extends React.Component {

    constructor(props) {
        super(props);
        this.showDeliveryType = false;
        this.showCaseOwner = false;
        this.sendEmailAfterSave = false;
        this.disableSave = false;
        this.sourceId = null;
        this.destinationId = null;
        this.assignedUserId = null;
        this.billingContactId = null;
        this.deliveryMethod = null;
        this.caseId = null;
        this.disableEmailButton = false;
        this.state = {
            assignedUserId: null,
            billingContactid: null,
            disableSave: false,
            disableEmailButton: false
        }
    }

    UNSAFE_componentWillMount = () => {
        this.props.caseAction.handleSalesOrder();
    };

    componentDidUpdate = () => {
        this.shouldShowConfirmationAlert();
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.orderUpdated !== undefined && (nextProps.orderUpdated !== this.props.orderUpdated)) {
            console.log("nextProps.orderUpdated", nextProps.orderUpdated)
            if (nextProps.orderUpdated === true) {
                this.disableSave = false;
                this.setState({disableSave: false});
            }
        }
    };

    componentDidMount = () => {
        this.props.caseAction.saveOrderRelative(this.props.selectedOrderData);
        let checkEmailDisableCondition = OrderUtil.checkEmailButtonDisableCondition(this.props.selectedDataById.stage);
        this.setState({disableEmailButton: checkEmailDisableCondition});
    };

    checkOrderChange = () => {
        this.disableEmailButton = OrderUtil.checkChangeInOrder(this.props.selectedOrderData, this.props.selectedDataById);
    };

    changeUser = (userData) => {
        this.props.changeUser(userData);
    };

    shouldShowConfirmationAlert = () => {
        if (this.props.selectedDataById.id !== undefined && this.props.selectedOrderData.id !== undefined) {
            const initialDataStr = JSON.stringify(this.props.selectedDataById);
            const newDataStr = JSON.stringify(this.props.selectedOrderData);
            (initialDataStr === newDataStr) ? this.props.actionMaster.hideUnsavedPopupLazily() : this.props.actionMaster.showUnsavedPopupLazily();
        }

        if (AppUtil.isAvailable(this.props.tempNote)) {
            this.props.actionMaster.showUnsavedPopupLazily();
        }
    };

    onDeleteClick = () => {
        this.props.actionMaster.showPopup();
    };

    hidePopup = () => {
        this.props.actionMaster.hidePopupSection();
    };

    onDelete = () => {
        this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isDeleting);
        this.props.orderAction.deleteOrderInfoApiCall(this.props.selectedOrderData, this.props.storingKey);
        setTimeout(function () {
            this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
        }.bind(this), 1000);
        // this.props.orderAction.deleteOrderInfoApiCall(this.props.selectedOrderData);
        this.hidePopup();
    };

    sendOrderEmailSummary = (relativeId, orderId, bodyData) => {
        if (relativeId !== null && parseInt(relativeId, 10) !== 0) {
            let orderIds = [];
            orderIds.push(orderId);
            bodyData.caseId = this.props.selectedOrderData.case.id;
            bodyData.orderIds = orderIds;
            this.props.caseAction.sendEmailSummary(orderId, bodyData);
        } else {
            bottomCenter().warning(Utility.getLang().warningMessages.case.selectRelative);
        }
    };

    currentRoute = () => {
        this.props.actionMaster.onHeaderMenuItemClicked(this.props.history.location.pathname);
        this.props.actionMaster.closePopupWithSameRoute();
    };

    toMoveNewRoute = () => {
        this.props.actionMaster.clearRouteCondition();
        if (this.props.headerMenuItemClicked !== this.props.history.location.pathname) {
            this.props.history.push(this.props.headerMenuItemClicked);
        }
    };
    hidePopupOnCancel = () => {
        this.showDeliveryType = false;
        this.showCaseOwner = false;
        this.sendEmailAfterSave = false;
        this.props.orderAction.updateOrderUpdatingStatus(true);
        this.props.actionMaster.hidePopupSection();
    };

    hidePopupOnSave = () => {
        this.showDeliveryType = false;
        this.showCaseOwner = false;
        this.sendEmailAfterSave = false;
        this.props.actionMaster.hidePopupSection();
    };

    changeDeliveryMethod = (value) => {
        this.deliveryMethod = parseInt(value, 10);
    };

    onSaveClick = () => {
        this.disableSave = true;
        this.props.orderAction.updateOrderUpdatingStatus(false);
        this.setState({disableSave: true});
        if (AppUtil.isAvailable(this.props.selectedOrderData) && AppUtil.isAvailable(this.props.selectedOrderData.case)) {
            this.assignedUserId = AppUtil.isAvailable(this.props.selectedOrderData.case.assignedUserId) ? this.props.selectedOrderData.case.assignedUserId : "";
            this.collaboratorUserId = AppUtil.isAvailable(this.props.selectedOrderData.case.collaboratorUserId) ? this.props.selectedOrderData.case.collaboratorUserId : "";
        }
        this.billingContactId = this.props.selectedOrderData.billingContactId;
        this.caseId = this.props.selectedOrderData.case.id;
        this.deliveryMethod = this.props.selectedOrderData.deliveryMethod;
        console.log("on save", this.props.selectedOrderData, this.props.selectedDataById);
        this.sourceId = this.props.selectedDataById.stage;
        if (this.props.selectedOrderData.stage !== this.props.selectedDataById.stage) {
            if (OrderUtil.toValidateDeliveryMethod(this.props.selectedDataById.stage, this.props.selectedOrderData.stage)) {
                this.showDeliveryType = true;
            }
            if (OrderUtil.isInvoice(this.props.selectedOrderData.stage)) {
                this.showCaseOwner = true;
            }
            if (OrderUtil.doesOrderStageChangeRequireAdminRole(this.props.selectedDataById.stage, this.props.selectedOrderData.stage)) {
                if (OrderUtil.verifyIfUserCanMoveOrderToArchivedOrDeletedStageOrCreditPaidInvoice(this.props.selectedDataById.stage, this.props.selectedOrderData.stage)) {
                    let status = OrderDraggingUtil.showPopupOnDragging(this.props.selectedDataById.stage, this.props.selectedOrderData.stage);
                    let emailWillBeSend = OrderDraggingUtil.checkEmailWillBeSent(this.props.selectedDataById.stage, this.props.selectedOrderData.stage);
                    if (emailWillBeSend) {
                        this.sendEmailAfterSave = true;
                    }
                    if (status === true) {
                        // this.resultDragged = result;
                        this.destinationId = this.props.selectedOrderData.stage;

                        if (this.billingContactId === null && this.showDeliveryType === false) {
                            this.props.actionMaster.showPopupOnOrderDrag();
                        } else if (this.billingContactId !== null && this.showDeliveryType === true) {
                            this.props.actionMaster.showPopupOnOrderDrag();
                        } else if (this.billingContactId === null && this.showDeliveryType === true) {
                            this.props.actionMaster.showPopupOnOrderDrag();
                        } else {
                            if (emailWillBeSend) {
                                this.props.actionMaster.showPopupOnOrderDrag();
                            } else {
                                this.updateDataOfOrder();
                            }
                            //this.updateDataOfOrder();
                        }
                    } else {
                        this.disableSave = false;
                        this.setState({disableSave: false});
                        this.props.actionMaster.changeDragStatus(null);
                        bottomCenter().error(Utility.getLang().common.changeStagePopup.cannotMoveToStage);
                    }
                } else {
                    this.disableSave = false;
                    this.setState({disableSave: false});
                    bottomCenter().error(Utility.getLang().common.changeStagePopup.notAuthorized);
                }
            } else {
                let status = OrderDraggingUtil.showPopupOnDragging(this.props.selectedDataById.stage, this.props.selectedOrderData.stage);
                console.log("Result status", status);
                let emailWillBeSend = OrderDraggingUtil.checkEmailWillBeSent(this.props.selectedDataById.stage, this.props.selectedOrderData.stage);
                if (emailWillBeSend) {
                    this.sendEmailAfterSave = true;
                }
                if (status === true) {
                    console.log("this.billingContactId ", this.billingContactId, this.showDeliveryType);
                    // this.resultDragged = result;
                    this.destinationId = this.props.selectedOrderData.stage;
                    if (this.billingContactId === null && this.showDeliveryType === false) {
                        this.props.actionMaster.showPopupOnOrderDrag();
                    } else if (this.billingContactId !== null && this.showDeliveryType === true) {
                        this.props.actionMaster.showPopupOnOrderDrag();
                    } else if (this.billingContactId === null && this.showDeliveryType === true) {
                        this.props.actionMaster.showPopupOnOrderDrag();
                    } else {
                        if (emailWillBeSend) {
                            this.props.actionMaster.showPopupOnOrderDrag();
                        } else {
                            this.updateDataOfOrder();
                        }
                        //this.updateDataOfOrder();
                    }
                } else {
                    this.disableSave = false;
                    this.setState({disableSave: false});
                    bottomCenter().error(Utility.getLang().common.changeStagePopup.cannotMoveToStage);
                }
            }

        } else {
            this.updateDataOfOrder();
        }
    };

    onConfirm = () => {
        this.updateDataOfOrder();
    };

    updateDataOfOrder = () => {
        let data = {};
        let validateCustom = false;
        data = this.props.selectedOrderData;
        if (this.props.selectedOrderData.case !== undefined && this.props.selectedOrderData.case.id !== undefined) {
            data.caseId = this.props.selectedOrderData.case.id;
            data.assignedUserId = this.props.selectedOrderData.case.assignedUserId;
            data.collaboratorUserId = this.props.selectedOrderData?.case?.collaboratorUserId;
        }
        data.billingContactId = this.props.selectedOrderData.billingContactId;
        let orderNewData = new Array(data);
        var orderValidationMessages = ValidationUtil.validateIfProductsExists(orderNewData);
        if (orderValidationMessages.length > 0) {
            orderValidationMessages.forEach(function (validationMessage) {
                bottomCenter().warning(validationMessage);
            });
            this.disableSave = false;
            this.setState({disableSave: false});
            return
        }
        var quantityValidationMessages = ValidationUtil.validateProductQuantity(orderNewData);
        if (quantityValidationMessages.length > 0) {
            quantityValidationMessages.forEach(function (validationMessage) {
                bottomCenter().warning(validationMessage);
            });
            this.disableSave = false;
            this.setState({disableSave: false});
            return
        }

        if (data.events !== undefined) {
            data.events.forEach((event, j) => {
                if (event.categories !== undefined && event.categories !== null) {
                    event.categories.forEach((category, k) => {
                        // delete category.catPrice;
                        // delete category.catTotal;
                        // delete category.data;
                        category.eventProducts.forEach((products, l) => {
                            if (products.typeId === ProductTypes.customProduct) {
                                if (products.customName === "" || products.customName === null || products.customName === undefined) {
                                    validateCustom = true;
                                }
                            }
                        })
                    });
                }
            })
        }
        if (validateCustom === false) {
            setTimeout(function () {
                data = OrderColumnUtil.getUpdatedNotes(this.props.tempNote, data);
                this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isUpdating);
                this.props.orderAction.updateOrderInfoApiCall(data, this.props.storingKey, this.props.isColumnWithUniqueStage);
                setTimeout(function () {
                    this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
                }.bind(this), 1000);
            }.bind(this), 0);
        } else {
            this.disableSave = false;
            this.setState({disableSave: false});
            bottomCenter().warning(Utility.getLang().warningMessages.case.addCustomName);
        }
        this.sendEmailAfterSave = false;
        this.hidePopupOnSave()
    };

    render = () => {
        // console.log("OrderInitialValue", this.props.selectedOrderData, AccessControl.revokedAccess().deleteOrder, this.props.selectedOrderData.stage, OrderUtil.orderStages.proposal.value);
        return (
            <React.Fragment>
                <PreLoader
                    onRef={ref => this.preLoader = ref}
                    showPreload={false}
                    container={this.node}
                    blockContainer={true}
                    type="Oval"
                />
                <Popup
                    openModal={this.props.showUnsavedPopup}
                    headerInfo={Utility.getLang().infoMessages.popup.unsavedChanges}
                    onConfirmation={this.toMoveNewRoute}
                    closePopup={this.currentRoute}
                />
                <PopupForOrderStageChange
                    openModal={this.props.openOnOrderDrag}
                    onConfirmation={this.onConfirm}
                    closePopup={this.hidePopupOnCancel}
                    showDeliveryType={this.showDeliveryType}
                    showCaseOwner={this.showCaseOwner}
                    changeDeliveryMethod={this.changeDeliveryMethod}
                    sourceId={this.sourceId}
                    destinationId={this.destinationId}
                    caseId={this.caseId}
                    assignedUserId={this.assignedUserId}
                    billingContactId={this.props.selectedOrderData.billingContactId}
                    deliveryMethod={this.deliveryMethod}
                    source={'order'}
                    sendEmailOnSave={this.sendEmailAfterSave}
                    order={this.props.selectedOrderData}
                    oldOrder={this.props.selectedDataById}
                />
                {this.props.displayNothing === false ?
                    <div className="section section--detail section--scroll" ref={node => this.node = node}>
                        {
                            this.props.selectedOrderData !== undefined && this.props.selectedOrderData.orderType !== undefined ?
                                <React.Fragment>
                                    <div className="section__head">
                                        <div className="inner">
                                            <CaseOrderHeaderTitle
                                                headerText={this.props.selectedOrderData.orderTypeText}
                                                orderTypeId={this.props.selectedOrderData.id}
                                                functionSource={'order'}
                                            />
                                        </div>
                                    </div>
                                    <OrderComponent
                                        order={this.props.selectedOrderData}
                                        originalData={this.props.selectedDataById}
                                        showEmailAndRelativeButtonInsideComponent={false}
                                        showCaseSelectDetailsView={true}
                                        functionSource={'order'}
                                        orderIndex={OrderUtil.ORDER_DEFAULT_INDEX}
                                        sendOrderEmailSummary={this.props.sendOrderEmailSummary}
                                        changeEmailTypeToSend={this.props.changeEmailTypeToSend}
                                    />
                                </React.Fragment>
                                : null
                        }
                    </div> : <DisplayNothing/>
                }
                <Popup
                    openModal={this.props.open}
                    headerInfo={Utility.getLang().infoMessages.popup.deleteOrder + ` ` + OrderUtil.getOrderTextBasedOnOrderType(this.props.selectedOrderData.orderType) + `?`}
                    onConfirmation={this.onDelete}
                    closePopup={this.hidePopup}
                    isDestructive
                    withTimeOut
                />
                <PopupAfterCaseSave
                    openModal={this.props.openOnSaveCase}
                    closePopup={this.hidePopupOnCancel}
                />
                <PopupForOrderEmail
                    openModal={this.props.openOnSend}
                    closePopup={this.hidePopup}
                    billingContactIdOrders={this.props.selectedOrderData.billingContactId}
                    relativesOfOrder={this.props.caseRelativesWithEmailId}
                    sendEmailSummary={(relativeID, data) => this.sendOrderEmailSummary(relativeID, this.props.selectedOrderData.id, data)}
                />
                {this.checkOrderChange()}
                <FooterWithSave
                    SaveData={this.onSaveClick}
                    deleteCase={this.onDeleteClick}
                    disableSaveButton={this.disableSave}
                    hideFooter={this.props.displayNothing}
                    buttonText={Utility.getLang().orders.footerForOrder.saveOrder}
                    disableSendEmailButton={this.state.disableEmailButton ? this.state.disableEmailButton : this.disableEmailButton}
                    textOnOrderChanges={this.disableEmailButton ? true : null}
                    deleteButtonText={Utility.getLang().orders.footerForOrder.deleteOrder}
                    hideRelativeDropdown={false}
                    orderForSendingmail={this.props.selectedOrderData}
                    hideDeleteButton={(AccessControl.revokedAccess().deleteOrder === true) && (this.props.selectedOrderData.stage !== OrderUtil.orderStages.proposal.value && this.props.selectedOrderData.stage !== OrderUtil.orderStages.draft.value && this.props.selectedOrderData.stage !== OrderUtil.orderStages.order.value) ? true : false}
                    // sendCaseEmailSummary={(relativeID) => this.sendOrderEmailSummary(relativeID, this.props.selectedOrderData.id)}
                />
            </React.Fragment>
        )
    }
}

OrderSpecificationComponent.propTypes = {
    columnDetailViewTabCollection: PropTypes.array,
};

function mapStateToProps(state) {
    return {
        selectedOrderData: state.application.selectedOrderData,
        selectedDataById: state.application.selectedDataById,
        initialValues: state.application.selectedOrderData,
        open: state.application.open,
        openOnSend: state.application.openOnSend,
        openOnOrderDrag: state.application.openOnOrderDrag,
        showUnsavedPopup: state.application.showUnsavedPopup,
        headerMenuItemClicked: state.application.headerMenuItemClicked,
        displayNothing: state.application.displayNothing,
        tempNote: state.application.tempNote,
        orderUpdated: state.application.orderUpdated,
        openOnSaveCase: state.application.openOnSaveCase

    };
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        caseOrderAction: bindActionCreators(caseOrderAction, dispatch),
        orderAction: bindActionCreators(orderAction, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: "OrderSpecificationComponent"
})(withRouter(OrderSpecificationComponent)));
