import AppUtil from "../../common/appUtil";
import _ from "underscore";
import DataKey from "../../common/dataKey";
import ApiCollection from "../../../api/apiCollections";
import swedishTenantOrderAttributes from "./swedishTenantOrderAttributes";
import ukTenantOrderAttributes, {EventAttribute} from "./ukTenantOrderAttributes";
import OrderUtil from "../../common/orderUtil";
import Utility from "../../../api/utilLanguage";

/**
 * @see FieldType
 * @see DataKey
 * @see swedishTenantOrderAttributes
 * @see ukTenantOrderAttributes
 */
class OrderFieldRules {

    /**
     * @requires all Order event attributes should be mentioned in here
     * @requires selectOptions = {label and value}
     */
    static orderEventTypeAttributesRules = {
        ...swedishTenantOrderAttributes,
        ...ukTenantOrderAttributes
    };

    /**
     * @requires OrderFieldRules.url() to be updated
     * @requires DataKey in  { orderOptionsData: {} } in initialState
     */
    static orderEventLink = {
        [OrderUtil.orderTypes.funeralWithCeremony.value]: {
            isFuneral: true,
            isService: true,
            isRepatriation: false,
            events: [
                {
                    eventType: OrderUtil.eventTypes.ceremony.value,
                    display: true,
                    loadProductCategories: true
                },
                {
                    eventType: OrderUtil.eventTypes.entombment.value,
                    display: true,
                    loadProductCategories: true
                },
                {
                    eventType: OrderUtil.eventTypes.reception.value,
                    display: true,
                    loadProductCategories: true
                },
                {
                    eventType: OrderUtil.eventTypes.preparation.value,
                    display: true,
                    loadProductCategories: true
                }
            ]
        },
        [OrderUtil.orderTypes.funeralWithoutCeremony.value]: {
            isFuneral: true,
            isService: false,
            isRepatriation: false,
            events: [
                {
                    eventType: OrderUtil.eventTypes.ceremony.value,
                    display: true,
                    loadProductCategories: true
                },
                {
                    eventType: OrderUtil.eventTypes.entombment.value,
                    display: true,
                    loadProductCategories: true
                },
                {
                    eventType: OrderUtil.eventTypes.reception.value,
                    display: false,
                    isDeletable: true,
                    alternateDisplay: true,
                    loadProductCategories: false
                },
                {
                    eventType: OrderUtil.eventTypes.preparation.value,
                    display: true,
                    loadProductCategories: true
                }
            ]
        },
        [OrderUtil.orderTypes.attendedFuneral.value]: {
            isFuneral: true,
            isService: false,
            isRepatriation: false,
            events: [
                {
                    eventType: OrderUtil.eventTypes.preFuneral.value,
                    display: true,
                    loadProductCategories: true
                },
                {
                    eventType: OrderUtil.eventTypes.funeralService.value,
                    display: true,
                    loadProductCategories: true
                },
                {
                    eventType: OrderUtil.eventTypes.postFuneral.value,
                    display: true,
                    loadProductCategories: true
                },
                {
                    eventType: OrderUtil.eventTypes.disbursements.value,
                    display: true,
                    loadProductCategories: true
                }
            ]
        },
        [OrderUtil.orderTypes.unattendedFuneral.value]: {
            isFuneral: true,
            isService: false,
            isRepatriation: false,
            events: [
                {
                    eventType: OrderUtil.eventTypes.preFuneral.value,
                    display: true,
                    loadProductCategories: true
                },
                {
                    eventType: OrderUtil.eventTypes.funeralService.value,
                    display: true,
                    loadProductCategories: true
                },
                {
                    eventType: OrderUtil.eventTypes.postFuneral.value,
                    display: true,
                    loadProductCategories: true
                },
                {
                    eventType: OrderUtil.eventTypes.disbursements.value,
                    display: true,
                    loadProductCategories: true
                }
            ]
        },
        [OrderUtil.orderTypes.repatriation.value]: {
            isFuneral: false,
            isService: false,
            isRepatriation: true,
            events: [
                {
                    eventType: OrderUtil.eventTypes.repatriation.value,
                    display: true,
                    loadProductCategories: true
                },
                {
                    eventType: OrderUtil.eventTypes.disbursements.value,
                    display: true,
                    loadProductCategories: true
                }
            ]
        },
        none: {
            isFuneral: false,
            isService: false,
            isRepatriation: false,
            events: [
                {
                    eventType: OrderUtil.eventTypes.anonymous.value,
                    display: false,
                    loadProductCategories: false
                }
            ]
        }
    };

    static getAttributeFieldType(attributeType, attributeTypeText) {
        let attributeRule = this.orderEventTypeAttributesRules[attributeType];
        if (AppUtil.isAvailable(attributeRule)) {
            attributeRule.label = attributeTypeText;
            return attributeRule;
        }
        return this.orderEventTypeAttributesRules['none'];
    }

    /**
     * Currently available on handle one attribute check... for multiple attributes check add mord in availableOn
     * and make changes in this function.
     */
    static enableFieldAttribute(...args) {
        const [event, eventAttribute, order, orders, orderCase] = args;
        let attribute = this.getAttributeFieldType(eventAttribute.attributeType, eventAttribute.attributeTypeText);

        if (AppUtil.isAvailable(attribute.disableIfFuneral) && attribute.disableIfFuneral) {
            if (OrderFieldRules.caseHasFuneralOrders(orders, orderCase)) {
                return false;
            } else if (AppUtil.isAvailable(attribute.availableOn)) {
                return this.evaluateAttributeAvailableOn(event, order, attribute);
            }
        } else if (AppUtil.isAvailable(attribute.availableOn)) {
            return this.evaluateAttributeAvailableOn(event, order, attribute);
        }
        return true;
    }

    static evaluateAttributeAvailableOn(event, order, attribute) {
        function isFieldAttributeEnabled(attributeList) {
            //Supports, multiple condition check to enable/disable field
            let isEnabledConditions = [];
            attribute.availableOn.forEach((availableOn) => {
                const matched = attributeList.filter(obj => {
                    return obj.attributeType === availableOn.attributeType;
                });
                const isEnabled = AppUtil.isAvailable(matched[0]) && this.match(matched[0].selectedValue, availableOn.value);
                isEnabledConditions.push(isEnabled);
            });

            return (AppUtil.isAvailable(attribute.everyAvailableOnSatisfied) && attribute.everyAvailableOnSatisfied) ?
                isEnabledConditions.every((e) => e === true) : isEnabledConditions.some((e) => e === true);
        }

        if (AppUtil.isAvailable(attribute.availableOn)) {
            let attributeList = Object.assign([], event.eventAttributes);
            this.updateEventAttributeIfPostFuneral(attributeList, event.eventType, order);
            const resultStatus = isFieldAttributeEnabled.call(this, attributeList);
            return resultStatus;
        }
    }

    static updateEventAttributeIfPostFuneral(eventAttributes, eventTypeToMatch, order) {
        if (eventTypeToMatch === OrderUtil.eventTypes.postFuneral.value) {
            const attribute = OrderUtil.getFuneralServiceEventAttributeToMatch(OrderUtil.getTypeOfService(order.orderType), order.events);
            if (AppUtil.isAvailable(attribute)) {
                eventAttributes.push(attribute);
            }
        }
    }

    static findAttribute(event, attributeType) {
        let attributeList = event.eventAttributes;
        let matched = attributeList.filter(obj => {
            return obj.attributeType === attributeType;
        });
        return matched;
    }

    static match(selectedValue, values) {
        let i;
        for (i = 0; i < values.length; i++) {
            if (selectedValue === values[i]) {
                return true;
            }
        }
        return false;
    }

    static orderToDisplayEvent(orderType, eventType) {
        if (!AppUtil.isAvailable(this.orderEventLink[orderType])) {
            return this.orderEventLink.none.events[0];
        }
        const result = this.extractedEvent(orderType, eventType);
        return result.length > 0 ? result[0] : this.orderEventLink.none.events[0];
    }

    static loadAdditionalProductCategories(orderType, eventType) {
        if (!AppUtil.isAvailable(this.orderEventLink[orderType])) {
            return this.orderEventLink.none.events[0].loadProductCategories;
        }
        let result = this.extractedEvent(orderType, eventType);
        if (result.length === 0) {
            return false;
        }
        return result[0].loadProductCategories;
    }

    static isDeletableEvent(orderType, eventType) {
        if (!AppUtil.isAvailable(this.orderEventLink[orderType])) {
            return false;
        }
        let result = this.extractedEvent(orderType, eventType);
        return result[0].isDeletable === undefined ? false : result[0].isDeletable;
    }

    static eventCategoryDataDisplayRuleIsSelect(categoryDataSize) {
        return categoryDataSize > 5;
    }

    static extractedEvent(orderType, eventType) {
        let result = this.orderEventLink[orderType].events.filter(obj => {
            return obj.eventType === eventType
        });
        return result;
    }

    static transformArrayIntoSelectOptions(arr) {
        let a = [];
        _.each(arr, (data) => {
            a.push({
                label: data.name,
                value: data.id + ""
            });
        });
        return a;
    }

    static url(id, options) {
        switch (id) {
            case EventAttribute.preparationMemorialFund:
            case EventAttribute.preFuneralMemorialFund:
            case EventAttribute.repatriationMemorialFund:
                return ApiCollection.properties.fetchOrderPreparationMemorialFunds;
            case EventAttribute.entombmentType:
                return ApiCollection.properties.fetchBurialTypes;
            case EventAttribute.ceremonyType:
                return ApiCollection.properties.fetchCeremonyTypes;
            case DataKey.LOCATIONS:
                return ApiCollection.properties.locationSearchUrl.replace("{id}", options.locationId).replace("{input}", options.searchValue);
            case EventAttribute.funeralServiceDeceasedBoroughResidency:
            case EventAttribute.funeralServicePurchaserBoroughResidency:
                return ApiCollection.properties.fetchBoroughs;
            case EventAttribute.funeralServiceCelebrantContact:
                return ApiCollection.properties.fetchCelebrantContactsOptions.replace("{caseId}", options.caseId);
            case EventAttribute.funeralServiceChurchServiceContact:
                return ApiCollection.properties.fetchChurchServiceContactsOptions.replace("{caseId}", options.caseId);
            case EventAttribute.preparationTransporter:
                return ApiCollection.properties.fetchOrderPreparationTransporters;
            default:
                return null;
        }
    }

    static selectionOptionsForMemorialFunds(dataForSelectOption) {
        function getReactSelectTitle(key) {
            return (key === "true") ? "" : Utility.getLang().cases.orderTabContent.funeralCaseOrderContent.allMemorialFunds;
        }

        const groupedOptionsHashMap = _.groupBy(dataForSelectOption, (memorialFund) => {
            return parseInt(memorialFund.value, 10) >= OrderUtil.memorialFund.notDecided;
        });

        return _.map(groupedOptionsHashMap, (value, key) => ({
            label: getReactSelectTitle(key),
            options: value
        })).reverse();
    }

    static isFuneral(orderType) {
        if (!AppUtil.isAvailable(this.orderEventLink[orderType])) {
            return false;
        }
        return this.orderEventLink[orderType].isFuneral;
    }

    static isService(orderType) {
        if (!AppUtil.isAvailable(this.orderEventLink[orderType])) {
            return false;
        }
        return this.orderEventLink[orderType].isService;
    }

    static isRepatriation(orderType) {
        if (AppUtil.isEmpty(this.orderEventLink[orderType])) {
            return false;
        }
        return this.orderEventLink[orderType].isRepatriation;
    }

    static disableAllFuneralOrder(orderTypeList, value) {
        for (let i = 0; i < orderTypeList.length; i++) {
            if (this.isFuneral(orderTypeList[i].id)) {
                orderTypeList[i].disable = value;
            }
        }
    }

    static disableRepatriationOrderButton(orderTypeList, value) {
        const repatriationOrder = orderTypeList.find((order) => {
            return this.isRepatriation(order.id);
        });
        if (AppUtil.isAvailable(repatriationOrder)) {
            repatriationOrder.disable = value;
        }
    }

    static disableFuneralOrderInDeleteStage(orderList, value) {
        orderList.forEach(function (order) {
            if (OrderFieldRules.isFuneral(order.orderType) && order.stage === OrderUtil.orderStages.deleted.value) {
                order.disableOrderStage = value;
            }
        });
    }

    static disableRepatriationOrderInDeleteStage(orderList, value) {
        orderList.forEach((order) => {
            if (OrderFieldRules.isRepatriation(order.orderType) && order.stage === OrderUtil.orderStages.deleted.value) {
                order.disableOrderStage = value;
            }
        });
    }

    /***
     * @description: Funeral Order/Repatriation Orders -> Rules to enable or disable order stages dropdown in popup
     * all deleted  stage funeral/repatriation orders -> stage dropdown -> must be enabled
     * If funeral/repatriation orders has both deleted and other stages orders -> then disable -> deleted order [only] stage dropdown
     */
    static enableOrDisableFuneralOrderStageDropDown(orderList) {
        const funeralOrders = orderList.filter((order) => OrderFieldRules.isFuneral(order.orderType));
        const isDeletedFuneralOrder = funeralOrders.every((order) => order.stage === OrderUtil.orderStages.deleted.value);
        OrderFieldRules.disableFuneralOrderInDeleteStage(orderList, isDeletedFuneralOrder ? false : true);
    }

    /***
     * @description: Repatriation Order
     */
    static enableOrDisableRepatriationOrderStageDropDown(orderList) {
        const isDeletedRepatriationOrder = OrderFieldRules.ifEveryRepatriationOrderIsDeletedStage(orderList);
        OrderFieldRules.disableRepatriationOrderInDeleteStage(orderList, isDeletedRepatriationOrder ? false : true);
    }

    static ifEveryRepatriationOrderIsDeletedStage(orderList) {
        const repatriationOrders = orderList.filter((order) => OrderFieldRules.isRepatriation(order.orderType));
        return repatriationOrders.every((order) => order.stage === OrderUtil.orderStages.deleted.value);
    }

    static eventHasData(event) {
        if (event.categories.length > 0) {
            return true;
        }
        let attributeList = event.eventAttributes;
        let matched = attributeList.filter(obj => {
            return AppUtil.isAvailable(obj.selectedValue);
        });
        if (matched.length > 0) {
            return true;
        }
        return false;
    }

    /**
     *@description:
     * - orders : list of of orders avaialble on case -> used in case tab
     * - orderCase : case associated with selected order -> in order details
     */
    static caseHasFuneralOrders(orders, orderCase) {
        let caseHasFuneralOrder = false;
        if (AppUtil.isAvailable(orders)) {
            caseHasFuneralOrder = OrderFieldRules.ifOrdersInCaseHaveFuneralOrders(orders);
        } else {
            caseHasFuneralOrder = AppUtil.isAvailable(orderCase) && AppUtil.isAvailable(orderCase.hasFuneralOrders) ? orderCase.hasFuneralOrders : caseHasFuneralOrder;
        }
        return caseHasFuneralOrder;
    }

    static ifOrdersInCaseHaveFuneralOrders(orders) {
        const funeralOrder = orders.find((item) => {
            return OrderFieldRules.isFuneral(item.orderType);
        });
        return AppUtil.isAvailable(funeralOrder) && funeralOrder.stage !== OrderUtil.orderStages.deleted.value
    }
}

export default OrderFieldRules;
