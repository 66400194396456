import {addMonths, format, subMonths} from "date-fns"
import DateUtilExt, {MONTH_YEAR_FORMAT, TimestampFormat} from "../../common/dateUtil/dateUtilExt";
import {getLangStatus} from "../../common/localStorageUtil";
import {enGB, enUS, sv} from "date-fns/locale";

const NUMBER_OF_COMPENSATION_MONTHS = 6;

class MyAccountUtil {

    /***
     * @description: to show months for compensation report
     * only after month of november 2020
     */
    static summaryReportDate() {
        return new Date(2020, 10, 30);
    }

    static getLocale(currentLang) {
        let locale = sv;
        switch (currentLang) {
            case "en-US":
                locale = enUS;
                break;
            case "en-GB":
                locale = enGB;
                break;
            default:
                locale = sv;
                break;
        }
        return locale;
    }

    /***
     * @description: to show months for compensation reports in dropdown
     */
    static getCompensationSummaryMonths() {
        const currentDate = new Date();
        let nextMonth = addMonths(currentDate, 1);
        let previousMonth = subMonths(nextMonth, NUMBER_OF_COMPENSATION_MONTHS);

        const currentLang = getLangStatus();
        let locale = MyAccountUtil.getLocale(currentLang);
        let months = [];
        while (nextMonth > previousMonth) {
            if (previousMonth > MyAccountUtil.summaryReportDate()) {
                months.push({
                    value: DateUtilExt.dateString(previousMonth, TimestampFormat.DATE),
                    label: format(previousMonth, MONTH_YEAR_FORMAT, {locale: locale}),
                    isDisabled: format(previousMonth, MONTH_YEAR_FORMAT) !== format(currentDate, MONTH_YEAR_FORMAT) ? false : true
                });
            }
            previousMonth = addMonths(previousMonth, 1);
        }
        months = months.reverse()
        return months;
    }

}

export default MyAccountUtil;
