import React from "react";
import Placeholders, {Placeholder} from "../../../../common/placeholder";

export const DocumentVisibleToCustomerCheckbox = ({
                                                      documentId,
                                                      checkedValue,
                                                      document,
                                                      showVisibleToCustomerPopupAction,
                                                      isLoading,
                                                  }) => {
    const stopLabelPropagation = (e) => {
        e.stopPropagation();
    };

    return (
        <div className="option option--checkbox">
            <input
                type="checkbox"
                id={"visibleToCustomer" + documentId}
                name={"visibleToCustomer" + documentId}
                onChange={(e) => showVisibleToCustomerPopupAction(e.target.checked, document)}
                onClick={e => e.stopPropagation()}
                checked={checkedValue !== undefined ? checkedValue : false}
                disabled={isLoading}
            />
            <label
                htmlFor={"visibleToCustomer" + documentId}
                className="option__description option__description--checkbox is-hidden control-label checkbox-label"
                onClick={stopLabelPropagation}
            ></label>
            {
                isLoading && <Placeholders>
                    <Placeholder minWidth/>
                </Placeholders>
            }
        </div>
    );
}
