import {useEffect, useReducer} from "react";
import AppUtil, {NOT_FOUND} from "../../../../../common/appUtil";
import produce from "immer";
import {QAProps} from "./swedishQARules";

const INITIALIZE_QUESTIONNAIRES = 'INITIALIZE_QUESTIONNAIRES';
const UPDATE_PROPERTY = 'UPDATE_PROPERTY';
const UPDATE_ITEM = 'UPDATE_ITEM';
const ADD_TO_BENEFICIARY_IDS = 'ADD_TO_BENEFICIARY_IDS';
const REMOVE_FROM_BENEFICIARY_IDS = 'REMOVE_FROM_BENEFICIARY_IDS';

/**
 * @description: here state value 'lProbateQAs' is maintained by using 'useReducer' hook and relevant Actions
 */
const questionnaireReducer = (state, action) => produce(state, (draft) => {
    switch (action.type) {
        case INITIALIZE_QUESTIONNAIRES:
            draft = action.payload;
            //to instantiate quickly
            return action.payload;
        case UPDATE_PROPERTY: {
            const matchedIndex = draft.findIndex(questionnaire => questionnaire.questionId === action.questionId);
            if (matchedIndex !== NOT_FOUND) {
                draft[matchedIndex][action.property] = action.payload;
            }
            break;
        }
        case UPDATE_ITEM: {
            const matchedIndex = draft.findIndex(questionnaire => questionnaire.questionId === action.questionId);
            if (matchedIndex !== NOT_FOUND) {
                draft[matchedIndex] = action.payload;
            }
            break;
        }
        case ADD_TO_BENEFICIARY_IDS: {
            const {questionId, property, payload, onTop = false} = action;
            const matchedIndex = draft.findIndex(questionnaire => questionnaire.questionId === questionId);
            if (matchedIndex !== NOT_FOUND) {
                draft[matchedIndex][property] = onTop ? [payload, ...state[matchedIndex][property]] : [...state[matchedIndex][property], payload];
            }
            break;
        }
        case REMOVE_FROM_BENEFICIARY_IDS: {
            const {property, beneficiaryId, questionId} = action;
            const matchedIndex = draft.findIndex(questionnaire => questionnaire.questionId === questionId);
            if (matchedIndex !== NOT_FOUND) {
                const matchedProbateQA = draft[matchedIndex];
                const matchedQBeneficiariesIds = matchedProbateQA[property];
                const matchedId = matchedQBeneficiariesIds.findIndex(id => (id === beneficiaryId));
                if (matchedId !== NOT_FOUND) {
                    // Removes from matchedIndex
                    matchedQBeneficiariesIds.splice(matchedId, 1);
                }
            } else {
                console.log("[Debug]:: Failure remove QA Beneficiaries by ids");
            }
            break;
        }
        default:
            break;
    }
});

export default function useQuestionnairePropsUpdate(initialProbateQAs) {

    const [probateQAs, dispatch] = useReducer(questionnaireReducer, initialProbateQAs);
    /**
     * @description: Actions Added
     */
    const updateQuestionnaireProperty = (payload, questionId, property) => {
        dispatch({type: UPDATE_PROPERTY, payload, questionId, property})
    };

    const updateQuestionnaireItem = (payload, questionId) => {
        dispatch({type: UPDATE_ITEM, payload, questionId})
    };

    const initializeQuestionnaires = (payload) => {
        dispatch({type: INITIALIZE_QUESTIONNAIRES, payload})
    };


    const addToBeneficiaryIds = (payload, questionId) => {
        dispatch({type: ADD_TO_BENEFICIARY_IDS, property: QAProps.questionnaireBeneficiaryIds, payload, questionId})
    }

    const removeFromBeneficiaryIds = (beneficiaryId, questionId) => {
        dispatch({
            type: REMOVE_FROM_BENEFICIARY_IDS,
            property: QAProps.questionnaireBeneficiaryIds,
            beneficiaryId,
            questionId
        })
    }
    /**
     * @description: Loading 'initialProbateQAs'
     */
    useEffect(() => {
        if (AppUtil.isAvailable(initialProbateQAs) && (initialProbateQAs.length > 0)) {
            initializeQuestionnaires(initialProbateQAs);
        }
    }, [initialProbateQAs]);

    return {
        currentProbateQAs: probateQAs,
        updateQuestionnaireProperty,
        updateQuestionnaireItem,
        initializeQuestionnaires,
        addToBeneficiaryIds,
        removeFromBeneficiaryIds
    }
}