import React from "react";
import PropTypes from 'prop-types';
import Utility from "../../../../api/utilLanguage";
import AppUtil from "../../../common/appUtil";
import {bindActionCreators} from "redux";
import connect from 'react-redux/es/connect/connect';
import Icon from "../../../common/icon";
import Select, {components} from "react-select";
import PermalinkWithImage from "../../../common/link/permalinkWithImage";
import * as documentAction from "../../../../actions/documentAction";
import {bottomCenter} from "../../../common/customToastr";
import DocumentPreviewManager from "../documentFilePreview/documentPreviewManager";
import AppLoader from "../documentUtil/appLoader";
import Button from "../../../common/button";
import DocumentUtil from "../documentUtil/documentUtil";
import {AppElement} from "../documentUtil/appElement";
import DocumentPreviewHOC from "../documentGenerateOrUpload/documentPreviewHOC";
import UploadDocumentPreviewOverlay from "../documentGenerateOrUpload/uploadDocumentPreviewOverlay";
import AddDocumentPreviewOverlay from "../documentGenerateOrUpload/addDocumentPreviewOverlay"
import LockCaseUtil from "../../lockCase/util/lockCaseUtil";
import {withRouter} from "react-router";
import {DocType} from "../caseDocuments";

const DocumentPreviewContainerOfUploadType = DocumentPreviewHOC(UploadDocumentPreviewOverlay);
const DocumentPreviewContainerOfGeneratedType = DocumentPreviewHOC(AddDocumentPreviewOverlay);

class DocumentDetails extends React.Component {

    static propTypes = {
        document: PropTypes.any.isRequired,
        onClickOfEmail: PropTypes.func.isRequired,
        onClickOfViewLog: PropTypes.func.isRequired,
        onClickOfDelete: PropTypes.func.isRequired,
        downloadDocument: PropTypes.func.isRequired,
        downloadDocx: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.dataSource = {
            versions: [],
            isOrderOfDocumentDeleted: false
        };
        //Default final doc version
        this.state = {
            versionSelected: null,
            isLoading: false,
            responseBase64Document: null,
            showUploadDocumentPreviewOverlay: false,
            showGeneratedDocumentPreviewOverlay: false,
            generatedEdit: false,
            document: this.props.document
        };
    }

    componentDidMount = () => {
        this.setup();
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (JSON.stringify(nextProps.generatedBase64Document) !== JSON.stringify(this.props.generatedBase64Document)) {
            // console.log("DocumentDetails, componentWillReceiveProps generatedBase64Document = ", nextProps.generatedBase64Document);
            this.updateResponseBase64Document(nextProps.generatedBase64Document);
        }
        if (JSON.stringify(nextProps.document) !== JSON.stringify(this.props.document)) {
            this.updateDataSource(nextProps.document);
        }
    };

    /***
     * @Description: Initializers
     */
    setup() {
        const {state} = this.props.history.location
        const {match, location, history} = this.props;

        if (AppUtil.isEmpty(this.props.caseDocuments)) {
            this.props.documentAction.getCaseDocuments(this.props.selectedCaseData.id).then(responseData => {
                const docObject = responseData.object.find(docs => docs.id === parseInt(match.params.detailsId))
                this.setState({document: docObject});

                this.updateDataSource(docObject);
            }).catch(error => {
                console.log(" getCaseDocuments error = ", error);
            })
        } else {
            const {document} = this.props;
            this.updateDataSource(document);
        }
    }

    updateDataSource(document) {
        const {selectedCaseData} = this.props;
        this.dataSource.isOrderOfDocumentDeleted = this.isOrderOfDocumentDeleted(document.orderId, selectedCaseData.orders);

        if (AppUtil.isAvailable(document.versions) && document.versions.length > 0) {
            //Version in Desc order of id's
            this.dataSource.versions = document.versions.sort((ver1, ver2) => (ver2.id - ver1.id));

            //Set default version
            const defaultVersion = this.getDefaultVersion(this.dataSource.versions);
            this.updateVersionSelected(defaultVersion);

            // console.log("defaultVersion = ", defaultVersion);
            this.clearAndGetDocumentByVersion(defaultVersion);
        }
    }

    isOrderOfDocumentDeleted = (documentOrderId, orders) => {
        let result = false;
        if (AppUtil.isAvailable(documentOrderId)) {
            const matchedOrder = AppUtil.getMatchedOrUndefinedObject(documentOrderId, orders);
            result = AppUtil.isEmpty(matchedOrder);
        }
        return result;
    };

    /***
     * @Description: update datasource
     */
    updateVersionSelected = (version) => {
        this.setState({versionSelected: version});
    };

    /***
     * @Description: Helpers
     */

    getDefaultVersion = (versions) => {
        return ((AppUtil.isAvailable(versions) && (versions.length > 0)) ? versions[0] : {});
    };

    getSelectedOption(document) {
        return this.props.options.selectionOption(document);
    }

    onVersionSelectionChange = (version) => {
        this.updateVersionSelected(version);

        this.clearAndGetDocumentByVersion(version);
    };
    /***
     *
     * @description webservice call
     */
    clearAndGetDocumentByVersion = (version) => {

        this.clearDocumentPreviewContent();
        const {selectedCaseData} = this.props;
        this.getDocumentByVersionApiCall(selectedCaseData.id, version.documentId, version.id);
    };

    clearDocumentPreviewContent = () => {
        this.props.documentAction.clearBase64GeneratedDocument();
    };

    updateResponseBase64Document = (responseBase64Document) => {
        this.setState({responseBase64Document: responseBase64Document});
    };

    getDocumentByVersionApiCall(caseId, documentId, versionId) {

        this.setState({isLoading: true});
        AppUtil.isAvailable(this.appLoader) && this.appLoader.showMessage(Utility.getLang().common.document.loader.docFetching);
        this.props.documentAction.getBase64DocumentByVersion(caseId, documentId, versionId).then(responseData => {
            this.processGetBase64DocumentByVersionResponse(responseData);
        }).catch(error => {
            console.log("get Document by version api Call = ", error);
        }).finally(() => {
            AppUtil.isAvailable(this.appLoader) && this.appLoader.hideMessage();
            this.setState({isLoading: false});
        });
    }

    processGetBase64DocumentByVersionResponse = (responseData) => {
        if (responseData.success === false) {
            bottomCenter().error(responseData.message);
        }
    };

    /***
     * @description: React select helper
     * @param inputBool
     * @returns {*}
     */
    showReactSelectDropDown = (inputBool) => {
        return inputBool ? components.DropdownIndicator : {DropdownIndicator: () => null};
    };

    onClickOfEditOrNewVersion = (e, document, isEditable) => {
        e.stopPropagation();
        if (document.generateType === DocumentUtil.docGenerateType.uploaded) {
            this.setState({
                showUploadDocumentPreviewOverlay: true,
                showGeneratedDocumentPreviewOverlay: false
            });
        } else if (document.generateType === DocumentUtil.docGenerateType.generated || document.generateType === DocumentUtil.docGenerateType.filledUp) {
            this.setState({
                showUploadDocumentPreviewOverlay: false,
                showGeneratedDocumentPreviewOverlay: true,
                generatedEdit: isEditable ? true : false
            });
        }
    };

    /**
     * Return action
     */
    gotoDocumentDetails = () => {
        this.setState({
            showUploadDocumentPreviewOverlay: false,
            showGeneratedDocumentPreviewOverlay: false
        });
    };

    saveClickOnDocUpload = (responseJson) => {
        if (AppUtil.isAvailable(responseJson.object)) {
            this.props.updateDocumentDetails(responseJson.object);
            this.props.updateDocumentOverview(this.props.caseDocuments);
        }
        this.gotoDocumentDetails();
    };
    /***
     * @description: Btn helper
     */
    isDisabledEditOrNewVersionBtn = (document, versionSelected, isLoading, isOrderOfDocumentDeleted) => {
        if (document.generateType === DocumentUtil.docGenerateType.uploaded && AppUtil.isAvailable(versionSelected)) {
            return (versionSelected.id < document.latestDocumentVersionId) || isLoading || isOrderOfDocumentDeleted;
        } else {
            return isLoading || isOrderOfDocumentDeleted;
        }
    };

    /***
     * @Description: Rendering
     */
    render() {
        const {
            backToScreen,
            onClickOfEmail,
            onClickOfViewLog,
            onClickOfDelete,
            downloadDocument,
            downloadDocx,
            downloadingDocumentType
        } = this.props;

        const {document} = this.state;
        const {versionSelected, responseBase64Document, isLoading} = this.state || {};
        const documentIcon = (AppUtil.isAvailable(document)) ? document.getDocumentIconForCategory(document.categoryId) : "document-na";
        const isDisabledEditOrNewVersionBtn = this.isDisabledEditOrNewVersionBtn(document, versionSelected, isLoading, this.dataSource.isOrderOfDocumentDeleted);
        console.log("responseBase64Document", responseBase64Document)
        return (
            <React.Fragment>
                {/* Commented as gives overlay effect not necessary here*/}
                {/* <div className="overlay overlay--temp">
                    <div className="overlay__toolbar"> 
                <div id="documentDetails" className="section__content section__content--case_documents"> */}
                <div className="inner">
                    <a className="link link--nav" onClick={() => backToScreen()} tabIndex="0">
                        <Icon type="arrow_left"/>
                        <span>
                                {Utility.getLang().common.document.documents}
                        </span>
                    </a>
                    <div className="btn-toolbar align_right"
                         disabled={(LockCaseUtil.isDisabledView(this.props.disabledView))}>
                        <Button
                            onClick={(e) => onClickOfDelete(e, document.id)}
                            isTertiary
                            isDestructive
                        >
                            {Utility.getLang().common.document.delete}
                        </Button>
                        <Button
                            onClick={(e) => downloadDocument(e, document.id, versionSelected.id)}
                            icon="download"
                            disabled={(downloadingDocumentType === DocType.document)}
                        >
                            {Utility.getLang().common.document.download}
                        </Button>
                        {document.allowDownloadAsDocx &&
                        <Button
                            onClick={(e) => downloadDocx(e, document.id, versionSelected.id)}
                            icon="download"
                            disabled={(downloadingDocumentType === DocType.docx)}
                        >
                            {Utility.getLang().common.document.downloadDocx}
                        </Button>
                        }
                        {AppUtil.isAvailable(versionSelected) &&
                        <Button
                            onClick={(e) => onClickOfEmail(e, document.id)}
                            disabled={(versionSelected.id < document.latestDocumentVersionId)}
                            icon="email"
                        >
                            {Utility.getLang().common.document.email}
                        </Button>
                        }
                        <Button onClick={(e) => onClickOfViewLog(e, document.id)}>
                            {Utility.getLang().common.document.viewLog}
                        </Button>
                        {AppUtil.isAvailable(versionSelected) &&
                        <Button
                            onClick={(e) => this.onClickOfEditOrNewVersion(e, document, true)}
                            isPrimary
                            disabled={isDisabledEditOrNewVersionBtn}
                        >
                            {Utility.getLang().common.document.edit}
                        </Button>
                        }
                        {AppUtil.isAvailable(versionSelected) && (document.generateType !== DocumentUtil.docGenerateType.uploaded) &&
                        <Button
                            onClick={(e) => this.onClickOfEditOrNewVersion(e, document, false)}
                            isPrimary
                            disabled={isDisabledEditOrNewVersionBtn}
                        >
                            {Utility.getLang().common.document.newVersion}
                        </Button>
                        }
                    </div>
                    <div className="section__head">
                        <div className="section__columns">
                            <div className="section__column doc-details">
                                <h3 className="section__title">
                                    <Icon type={documentIcon} isColor/>
                                    <span className="description">
                                            {document.documentTypeName}
                                        </span>
                                    <span>
                                            {
                                                AppUtil.isAvailable(document.orderId) &&
                                                <PermalinkWithImage
                                                    imgFileName={"order"}
                                                    id={document.orderId}
                                                    path1={AppUtil.linkPaths.order.basePath}
                                                    path2={AppUtil.linkPaths.order.details}
                                                    title={document.orderId}
                                                />
                                            }
                                        {
                                            AppUtil.isAvailable(versionSelected) && (document.generateType === DocumentUtil.docGenerateType.uploaded) &&
                                            <p className="section__subtitle has-icon">
                                                {versionSelected.name}
                                            </p>
                                        }
                                        </span>
                                </h3>
                                {
                                    AppUtil.isAvailable(versionSelected) &&
                                    <p className="section__subtitle has-icon p-wrap">
                                        {AppUtil.isAvailable(versionSelected.freeTextNote) ? versionSelected.freeTextNote : ""}
                                    </p>
                                }
                            </div>
                            <div className="section__column">
                                <div className="btn-toolbar btn-toolbar--right nowrap">
                                    {
                                        this.dataSource.versions.length > 1 &&
                                        <div className="field field--small">
                                            <label htmlFor="caseDocumentIdType">
                                                {Utility.getLang().common.version}
                                            </label>
                                            <Select
                                                inputId="caseDocumentIdType"
                                                options={this.dataSource.versions}
                                                getOptionLabel={({versionText}) => versionText}
                                                getOptionValue={({id}) => id}
                                                placeholder={Utility.getLang().common.dropDownOptionPleaseSelect}
                                                formatCreateLabel=""
                                                className={"multiselect"}
                                                classNamePrefix={"multiselect"}
                                                noOptionsMessage=""
                                                value={versionSelected}
                                                onChange={this.onVersionSelectionChange}
                                                components={(this.dataSource.versions.length > 1) ? this.showReactSelectDropDown(true) : this.showReactSelectDropDown(false)}
                                                isDisabled={this.dataSource.versions.length > 1 ? false : true}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DocumentPreviewManager
                    responseBase64Document={responseBase64Document}
                    divComponent={<AppLoader type="grey" onRef={ref => this.appLoader = ref}/>}
                    isLoading={isLoading}
                />
                {/* </div> */}
                {
                    this.state.showUploadDocumentPreviewOverlay &&
                    <AppElement>
                        <DocumentPreviewContainerOfUploadType
                            options={this.props.caseDocumentTypes}
                            orders={this.props.selectedCaseData.orders}
                            backToScreen={this.gotoDocumentDetails}
                            saveBtnAction={this.saveClickOnDocUpload}
                            documentScreenType={DocumentUtil.screenType.uploadedEdit}
                            document={document}
                            versionSelected={this.state.versionSelected}
                        />
                    </AppElement>
                }
                {
                    this.state.showGeneratedDocumentPreviewOverlay &&
                    <AppElement>
                        <DocumentPreviewContainerOfGeneratedType
                            options={this.props.caseDocumentTypes}
                            orders={this.props.selectedCaseData.orders}
                            backToScreen={this.gotoDocumentDetails}
                            saveBtnAction={this.saveClickOnDocUpload}
                            documentScreenType={this.state.generatedEdit ? DocumentUtil.screenType.generatedEdit : DocumentUtil.screenType.generatedNewVersion}
                            document={document}
                            versionSelected={this.state.versionSelected}
                        />
                    </AppElement>
                }
                {/* </div>
                </div> */}
            </React.Fragment>);
    }
}

function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {documentAction: bindActionCreators(documentAction, dispatch)};
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DocumentDetails));

/***
 * @description: Extending Array with without 'selectionOption' documentType
 */
//eslint-disable-next-line no-extend-native
Object.defineProperty(Array.prototype, 'selectionOption', {
    value: function (document) {
        return this.filter((documentType) => {
            return (documentType.id === document.documentTypeId);
        });
    }
});