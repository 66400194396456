import React from 'react';
import Utility from "../../../api/utilLanguage";
import connect from 'react-redux/es/connect/connect';
import {reduxForm} from "redux-form";

class Spinner extends React.Component {

    render = () => {
        const {
            message,
            className,
            noMessage
        } = this.props

        let classNames = "spinner";

        // Add any custom class names
        if (className !== undefined) classNames += ` ${className}`;

        return (
            <div className="spinner__wrapper">
                <div className={classNames}>
                    <div className="spinner__content">
                        <span className="spinner__animation"></span>
                        { noMessage ? null :
                            <p className="spinner__description">
                                {message}
                            </p>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: "DisplayNothing"
})(Spinner));

Spinner.defaultProps = {
    message: Utility.getLang().common.loading
}
