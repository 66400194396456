import React from 'react';
import PropTypes from 'prop-types';

class Tooltip extends React.Component {

    render = () => {
        const {
            children,
            shortcut,
            inline,
            multiline,
            alignRight,
            alignLeft,
            alignTop
        } = this.props

        let classNames = "fnxtooltip";

        if (inline) classNames += " fnxtooltip--inline";

        if (multiline) classNames += " fnxtooltip--multiline";

        if (alignRight) classNames += " fnxtooltip--align_right";

        if (alignLeft) classNames += " fnxtooltip--align_left" ;
        
        if (alignTop) classNames += " fnxtooltip--align_top" ;

        return (
            <span className={classNames}>
                <span className="fnxtooltip__inner">
                    <span className="fnxtooltip__description">
                        {children}
                    </span>
                    { shortcut ? (
                        <span className="shortcut_icon">
                            {shortcut}
                        </span>
                        )
                    : null }
                </span>
            </span>
        )
    }
}

Tooltip.propTypes = {
    children: PropTypes.any.isRequired,
    shortcut: PropTypes.any,
    inline: PropTypes.bool,
    multiline: PropTypes.bool,
    alignRight: PropTypes.bool,
    alignLeft: PropTypes.bool,
    alignTop: PropTypes.bool,
};

export default Tooltip;
