import React from "react";
import DetailedItemContent from "./detailedItemContent";

const DetailedItem = ({imgFileName, description, details}) => {

    return (
        <span className="item__meta item__meta--deceased">
            <DetailedItemContent
                imgFileName={imgFileName}
                description={description}
                details = {details}
            />
        </span>
    );
};

export default DetailedItem;
