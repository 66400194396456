import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actionMaster from "../../actions/uiAction";
import * as dashboardAction from "../../actions/dashboardAction";
import * as wsAction from "../../actions/wsAction";
import {withRouter} from "react-router";
import Utility from "../../api/utilLanguage";
import config from "../../api/config";
import Hotkeys from "react-hot-keys";
import WebSocketUtil from "./webSocket/webSocketUtil";
import CaseUtil from "./caseUtil";
import OrderUtil from "./orderUtil";
import ErrorUtil from "./errorUtil";
import ThemeUtil from "./themeUtil";
import $ from "jquery";
import RefreshToken from "../../api/validateToken";
import AccessControl from "../../api/accessControl";
import AppUtil, {Lang} from "./appUtil";
import {getApiToken, getIdaTheme, getProfileImageUrl, removeAllPageVisibility} from "./localStorageUtil";
import * as lockCaseAction from "../case/lockCase/redux/lockCaseAction";
import LockCaseUtil from "../case/lockCase/util/lockCaseUtil";
import * as columnAction from "./masterDetailView/column/redux/columnAction";
import LogoutUtil from "../logout/logoutUtil";
import * as caseAction from "../../actions/caseAction";
import HeaderMenuItem from "./header/headerMenuItem";
import * as newDashboardAction from "../dashboard/redux/dashboardAction";

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.profileimageurl = "";
        this.props.wsAction.registerListeners([
            {
                id: "header_convert",
                hooks: [
                    {
                        type: WebSocketUtil.webSocketNotificationTypes.newItemAddedCase.value,
                        types: [
                            WebSocketUtil.webSocketNotificationTypes.newItemAddedCase.value,
                            WebSocketUtil.webSocketNotificationTypes.lockCase.caseOpen.value,
                            WebSocketUtil.webSocketNotificationTypes.lockCase.caseDataUpdated.value,
                            WebSocketUtil.webSocketNotificationTypes.lockCase.caseStageUpdated.value,
                            WebSocketUtil.webSocketNotificationTypes.lockCase.caseDeleted.value,
                            WebSocketUtil.webSocketNotificationTypes.lockCase.editorChanged.value
                        ],
                        stages: [
                            CaseUtil.caseStages.newStage.value,
                            CaseUtil.caseStages.lead.value,
                            CaseUtil.caseStages.deal.value,
                            CaseUtil.caseStages.assigned.value,
                            CaseUtil.caseStages.prePlanning.value,
                            CaseUtil.caseStages.notACase.value,
                            CaseUtil.caseStages.lost.value
                        ]
                    }
                ]
            },
            {
                id: "header_organise",
                hooks: [
                    {
                        type: WebSocketUtil.webSocketNotificationTypes.newItemAddedCase.value,
                        types: [
                            WebSocketUtil.webSocketNotificationTypes.newItemAddedCase.value,
                            WebSocketUtil.webSocketNotificationTypes.lockCase.caseOpen.value,
                            WebSocketUtil.webSocketNotificationTypes.lockCase.caseDataUpdated.value,
                            WebSocketUtil.webSocketNotificationTypes.lockCase.caseStageUpdated.value,
                            WebSocketUtil.webSocketNotificationTypes.lockCase.caseDeleted.value,
                            WebSocketUtil.webSocketNotificationTypes.lockCase.editorChanged.value
                        ],
                        stages: [
                            CaseUtil.caseStages.assigned.value,
                            CaseUtil.caseStages.book.value,
                            CaseUtil.caseStages.monitor.value,
                            CaseUtil.caseStages.deliver.value,
                            CaseUtil.caseStages.close.value,
                            CaseUtil.caseStages.archived.value,
                            CaseUtil.caseStages.lost.value
                        ]
                    }
                ]
            },
            {
                id: "header_bill",
                hooks: [
                    {
                        type: WebSocketUtil.webSocketNotificationTypes.newItemAddedOrder.value,
                        types: [WebSocketUtil.webSocketNotificationTypes.newItemAddedOrder.value],
                        stages: [
                            OrderUtil.orderStages.proposal.value,
                            OrderUtil.orderStages.order.value,
                            OrderUtil.orderStages.invoice.value,
                            OrderUtil.orderStages.archived.value
                        ]
                    }
                ]
            },
            {
                id: "header_dashboard",
                hooks: []
            },
            {
                id: "header_customer",
                hooks: []
            },
            {
                id: "header_cases",
                hooks: [
                    {
                        type: WebSocketUtil.webSocketNotificationTypes.newItemAddedCase.value,
                        types: [
                            WebSocketUtil.webSocketNotificationTypes.newItemAddedCase.value,
                            WebSocketUtil.webSocketNotificationTypes.lockCase.caseOpen.value,
                            WebSocketUtil.webSocketNotificationTypes.lockCase.caseDataUpdated.value,
                            WebSocketUtil.webSocketNotificationTypes.lockCase.caseStageUpdated.value,
                            WebSocketUtil.webSocketNotificationTypes.lockCase.caseDeleted.value,
                            WebSocketUtil.webSocketNotificationTypes.lockCase.editorChanged.value
                        ],
                        stages: [
                            CaseUtil.caseStages.newStage.value,
                            CaseUtil.caseStages.lead.value,
                            CaseUtil.caseStages.deal.value,
                            CaseUtil.caseStages.assigned.value,
                            CaseUtil.caseStages.book.value,
                            CaseUtil.caseStages.monitor.value,
                            CaseUtil.caseStages.deliver.value,
                            CaseUtil.caseStages.close.value,
                            CaseUtil.caseStages.archived.value,
                            CaseUtil.caseStages.prePlanning.value,
                            CaseUtil.caseStages.notACase.value,
                            CaseUtil.caseStages.lost.value
                        ]
                    }
                ]
            },
            {
                id: "header_orders",
                hooks: [
                    {
                        type: WebSocketUtil.webSocketNotificationTypes.newItemAddedOrder.value,
                        types: [WebSocketUtil.webSocketNotificationTypes.newItemAddedOrder.value],
                        stages: [
                            OrderUtil.orderStages.proposal.value,
                            OrderUtil.orderStages.order.value,
                            OrderUtil.orderStages.invoice.value,
                            OrderUtil.orderStages.archived.value,
                            OrderUtil.orderStages.deleted.value
                        ]
                    }
                ]
            },
            {
                id: "header_calls",
                hooks: [
                    {
                        type: WebSocketUtil.webSocketNotificationTypes.newItemAddedCall.value,
                        types: [WebSocketUtil.webSocketNotificationTypes.newItemAddedCall.value],
                        stages: [
                            1
                        ]
                    }
                ]
            }
        ]);
    }

    UNSAFE_componentWillMount() {
        this.getNotificationsCount();
        this.profileimageurl = getProfileImageUrl()
    }

    getNotificationsCount = () => {
        setTimeout(function () {
            if (AppUtil.jwtTokenIsExpired()) {
                RefreshToken.validateRefreshToken().then(newData => {
                    this.props.actionMaster.afterRefreshToken(newData);
                    this.fetchNotificationCount(newData.idToken.jwtToken).then(function (data) {
                        if (data !== undefined && data.object !== undefined) {
                            this.props.actionMaster.setUnreadNotificationCount(data.object);
                        }
                    }.bind(this)).catch(error => console.log("[Debug]:: fetch notification count, error = ", ErrorUtil.getAjaxOrFetchFailureMessage(error)));
                })
            } else {
                this.fetchNotificationCount(getApiToken()).then(function (data) {
                    if (data !== undefined && data.object !== undefined) {
                        this.props.actionMaster.setUnreadNotificationCount(data.object);
                    }
                }.bind(this)).catch(error => console.log("[Debug]:: fetch notification count, error = ", ErrorUtil.getAjaxOrFetchFailureMessage(error)));
            }
        }.bind(this), 100);
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        let idaTheme = getIdaTheme();
        if (idaTheme === ThemeUtil.DARK_THEME) {
            document.documentElement.setAttribute("data-theme", idaTheme);
        }
    };

    fetchNotificationCount = (token) => {
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: config.baseURL + "api/v1/case/pushnotification/count/unread",
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    };

    onKeyUp = (keyName, e, handle) => {
        if (e.srcElement.tagName === "INPUT" || e.srcElement.tagName === "SELECT" || e.srcElement.tagName === "TEXTAREA") {
            return
        } else {
            if (keyName === "1") {
                this.onHeaderMenuItemClick("/dashboardOld");
            } else if (keyName === "2") {
                this.onHeaderMenuItemClick(AppUtil.linkPaths.convert.pathToRoute);
            } else if (keyName === "3") {
                this.onHeaderMenuItemClick(AppUtil.linkPaths.organise.pathToRoute);
            } else if (keyName === "4") {
                this.onHeaderMenuItemClick(AppUtil.linkPaths.bill.pathToRoute);
            } else if (keyName === "5") {
                this.onHeaderMenuItemClick(AppUtil.linkPaths.dashboard.pathToRoute);
            } else if (keyName === "q") {
                this.onHeaderMenuItemClick(AppUtil.linkPaths.customer.pathToRoute);
            } else if (keyName === "w") {
                this.onHeaderMenuItemClick(AppUtil.linkPaths.case.pathToRoute);
            } else if (keyName === "e") {
                this.onHeaderMenuItemClick(AppUtil.linkPaths.order.pathToRoute);
            } else if (keyName === "r") {
                this.onHeaderMenuItemClick(AppUtil.linkPaths.call.pathToRoute);
            } else if (keyName === "t") {
                this.onHeaderMenuItemClick(AppUtil.linkPaths.product.pathToRoute);
            } else if (keyName === "y") {
                this.onHeaderMenuItemClick(AppUtil.linkPaths.location.pathToRoute);
            } else if (keyName === "u") {
                this.onHeaderMenuItemClick(AppUtil.linkPaths.contact.pathToRoute);
            } else if (keyName === "9") {
                this.onClickSearchPanel(AppUtil.linkPaths.search.pathToRoute);
            } else if (keyName === "0") {
                this.onClickNotificationPanel(AppUtil.linkPaths.notification.pathToRoute)
            }
        }
    };

    componentDidMount = () => {
        if (this.props.showEscapeKey === false) {
            this.props.actionMaster.onHeaderMenuItemClicked(this.props.history.location.pathname);
        }
    };

    onHeaderMenuItemClick = (pathToRoute) => {
        const newPath = pathToRoute;
        const oldPath = this.props.history.location.pathname;

        if (this.props.hasUnsavedData) {
            this.props.actionMaster.showUnsavedPopup();
        } else {
            this.props.history.push(pathToRoute)
            if (newPath !== oldPath) {
                // console.log(`[Debug]:: Different path:: oldPath = ${oldPath}, newPath = ${newPath}`);
                this.cleanup(newPath);
            }
        }
        this.props.dashboardAction.setEscapeKey(false);
        this.props.actionMaster.onHeaderMenuItemClicked(pathToRoute, oldPath);
    };

    cleanup(newPath) {
        this.cleanupWebSocketListener(newPath);
        this.props.columnAction.clearColumnPageData();
        this.props.columnAction.clearGlobalColumnLoadingTracker();
        this.cleanupLockDataBannerAndMode();
        removeAllPageVisibility();
        this.props.caseAction.setManuallySelectedChildComponent(null);
        this.props.newDashboardAction.clearDashboardDetails();

    }

    cleanupLockDataBannerAndMode = () => {
        this.props.wsAction.clearReloadToSeeLatestBanner();
        this.props.wsAction.clearLockCaseAccessUserList();
        this.updateLockDataToCloseMode();
    };

    updateLockDataToCloseModeByPromise = async () => {
        await this.updateLockDataToCloseMode();
    };

    updateLockDataToCloseMode = () => {
        if (LockCaseUtil.isLockDataOpen(this.props.lockCase)) {
            //Trigger [Close api call]
            this.props.lockCaseAction.updateCaseDataMode(LockCaseUtil.viewMode.close);
        }
    };

    cleanupWebSocketListener(pathToRoute) {
        switch (pathToRoute) {
            case AppUtil.linkPaths.convert.pathToRoute:
                this.props.wsAction.clearListener("header_convert");
                break;
            case AppUtil.linkPaths.organise.pathToRoute:
                this.props.wsAction.clearListener("header_organise");
                break;
            case AppUtil.linkPaths.bill.pathToRoute:
                this.props.wsAction.clearListener("header_bill");
                break;
            case AppUtil.linkPaths.case.pathToRoute:
                this.props.wsAction.clearListener("header_cases");
                break;
            case AppUtil.linkPaths.order.pathToRoute:
                this.props.wsAction.clearListener("header_orders");
                break;
            default:
                break;
        }
    }

    logOut = () => {
        this.props.wsAction.clearLockCaseAccessUserList();
        this.updateLockDataToCloseModeByPromise().then(() => {
            const {updateLocalStorageOnLogOut} = LogoutUtil();
            updateLocalStorageOnLogOut();
            this.props.actionMaster.logOutUser();
        });
    };

    onClickSearchPanel = (btn) => {
        this.props.actionMaster.showSearchPanel(btn);
    };

    onClickNotificationPanel = (btn) => {
        this.getNotificationsCount();
        this.props.wsAction.newNotificationsAlert(false);
        this.props.actionMaster.showNotificationPanel(btn);
    };

    hasUpdatedItems = (id, headerItem) => {
        let _that = this;
        const listener = this.props.registeredListeners.filter(function (listener) {
            if (listener.id === id && headerItem === _that.props.headerMenuItemClicked) {
                listener.hasUpdatedItem = false;
            }
            return listener.id === id
        });
        return listener !== undefined && listener.length === 1 && listener[0].hasUpdatedItem === true ? " has-content" : ""
    };


    render() {
        return (
            <Hotkeys
                keyName="2,3,4,5,q,w,e,r,t,y,9,0"
                onKeyDown={this.onKeyUp}
            >
                <nav className="navbar panel__head">
                    <div className="container-fluid">
                        <ul className="navbar__section navbar__section--workflows navbar__section--left">
                            <HeaderMenuItem title={Utility.getLang().header.convert}
                                            pathToRoute={AppUtil.linkPaths.convert.pathToRoute}
                                            onItemClick={this.onHeaderMenuItemClick}
                                            isActive={this.props.headerMenuItemClicked === AppUtil.linkPaths.convert.pathToRoute}
                                            className={"navbar__item"}
                                            additionalClassName={this.hasUpdatedItems("header_convert", AppUtil.linkPaths.convert.pathToRoute)}
                                            toolAlignLeft
                            />
                            <HeaderMenuItem title={Utility.getLang().header.organise}
                                            pathToRoute={AppUtil.linkPaths.organise.pathToRoute}
                                            onItemClick={this.onHeaderMenuItemClick}
                                            isActive={this.props.headerMenuItemClicked === AppUtil.linkPaths.organise.pathToRoute}
                                            className={"navbar__item"}
                                            additionalClassName={this.hasUpdatedItems("header_organise", AppUtil.linkPaths.organise.pathToRoute)}
                            />
                            <HeaderMenuItem title={Utility.getLang().header.bill}
                                            pathToRoute={AppUtil.linkPaths.bill.pathToRoute}
                                            onItemClick={this.onHeaderMenuItemClick}
                                            isActive={this.props.headerMenuItemClicked === AppUtil.linkPaths.bill.pathToRoute}
                                            className={"navbar__item"}
                                            additionalClassName={this.hasUpdatedItems("header_bill", AppUtil.linkPaths.bill.pathToRoute)}
                            />
                            {
                                (AccessControl.revokedAccess().showDashboardPage === false) &&
                                <HeaderMenuItem title={Lang().header.dashboard}
                                                pathToRoute={AppUtil.linkPaths.dashboard.pathToRoute}
                                                onItemClick={this.onHeaderMenuItemClick}
                                                isActive={this.props.headerMenuItemClicked === AppUtil.linkPaths.dashboard.pathToRoute}
                                                className={"navbar__item"}
                                />
                            }
                        </ul>
                        <div className="navbar__section--spacer"/>
                        <ul className="navbar__section navbar__section--objects navbar__section--left">
                            <HeaderMenuItem title={Lang().header.customers}
                                            pathToRoute={AppUtil.linkPaths.customer.pathToRoute}
                                            onItemClick={this.onHeaderMenuItemClick}
                                            isActive={this.props.headerMenuItemClicked === AppUtil.linkPaths.customer.pathToRoute}
                                            className={"navbar__item"}
                            />
                            <HeaderMenuItem title={Utility.getLang().header.cases}
                                            pathToRoute={AppUtil.linkPaths.case.pathToRoute}
                                            onItemClick={this.onHeaderMenuItemClick}
                                            isActive={this.props.headerMenuItemClicked === AppUtil.linkPaths.case.pathToRoute}
                                            className={"navbar__item"}
                                            additionalClassName={this.hasUpdatedItems("header_cases", AppUtil.linkPaths.case.pathToRoute)}
                            />
                            <HeaderMenuItem title={Utility.getLang().header.orders}
                                            pathToRoute={AppUtil.linkPaths.order.pathToRoute}
                                            onItemClick={this.onHeaderMenuItemClick}
                                            isActive={this.props.headerMenuItemClicked === AppUtil.linkPaths.order.pathToRoute}
                                            className={"navbar__item"}
                                            additionalClassName={this.hasUpdatedItems("header_orders", AppUtil.linkPaths.order.pathToRoute)}
                            />
                            <HeaderMenuItem title={Utility.getLang().header.calls}
                                            pathToRoute={AppUtil.linkPaths.call.pathToRoute}
                                            onItemClick={this.onHeaderMenuItemClick}
                                            isActive={this.props.headerMenuItemClicked === AppUtil.linkPaths.call.pathToRoute}
                                            className={"navbar__item"}
                                            additionalClassName={this.hasUpdatedItems("header_calls", AppUtil.linkPaths.call.pathToRoute)}
                            />
                            <HeaderMenuItem title={Utility.getLang().header.products}
                                            pathToRoute={AppUtil.linkPaths.product.pathToRoute}
                                            onItemClick={this.onHeaderMenuItemClick}
                                            isActive={this.props.headerMenuItemClicked === AppUtil.linkPaths.product.pathToRoute}
                                            className={"navbar__item"}
                            />
                            <HeaderMenuItem title={Utility.getLang().header.locations}
                                            pathToRoute={AppUtil.linkPaths.location.pathToRoute}
                                            onItemClick={this.onHeaderMenuItemClick}
                                            isActive={this.props.headerMenuItemClicked === AppUtil.linkPaths.location.pathToRoute}
                                            className={"navbar__item"}
                            />
                            <HeaderMenuItem title={Utility.getLang().header.contacts}
                                            pathToRoute={AppUtil.linkPaths.contact.pathToRoute}
                                            onItemClick={this.onHeaderMenuItemClick}
                                            isActive={this.props.headerMenuItemClicked === AppUtil.linkPaths.contact.pathToRoute}
                                            className={"navbar__item"}
                            />
                        </ul>
                        <div className="navbar__section--spacer"/>
                        <ul className="navbar__section navbar__section--panels navbar__section--right">
                            <HeaderMenuItem title={Utility.getLang().header.search}
                                            pathToRoute={AppUtil.linkPaths.search.pathToRoute}
                                            onItemClick={this.onClickSearchPanel}
                                            isActive={this.props.highLightIconSearch === AppUtil.linkPaths.search.pathToRoute && this.props.showSearchPanel === true}
                                            className={"navbar__item"}
                                            skipAnchor
                            />
                            <HeaderMenuItem title={Utility.getLang().header.notifications}
                                            pathToRoute={AppUtil.linkPaths.notification.pathToRoute}
                                            onItemClick={this.onClickNotificationPanel}
                                            isActive={(this.props.highLightIconNotification === AppUtil.linkPaths.notification.pathToRoute) && this.props.showNotificationPanel}
                                            className={"navbar__item"}
                                            additionalClassName={this.props.unreadNotificationCount ? 'wiggle' : ''}
                                            count={this.props.unreadNotificationCount}
                                            skipAnchor
                            />
                        </ul>
                        <div className="navbar__section--spacer"/>
                        <ul className="navbar__section navbar__section--account navbar__section--settings navbar__section--right">
                            <HeaderMenuItem title={Utility.getLang().header.help}
                                            pathToRoute={AppUtil.linkPaths.help.pathToRoute}
                                            onItemClick={this.onHeaderMenuItemClick}
                                            isActive={this.props.headerMenuItemClicked === AppUtil.linkPaths.help.pathToRoute}
                                            className={"navbar__item"}
                            />
                            {
                                AccessControl.revokedAccess().settingRoute === true ? null :
                                    <HeaderMenuItem title={Utility.getLang().header.settings}
                                                    pathToRoute={AppUtil.linkPaths.settings.users}
                                                    onItemClick={this.onHeaderMenuItemClick}
                                                    isActive={this.props.headerMenuItemClicked === AppUtil.linkPaths.settings.pathToRoute}
                                                    className={"navbar__item"}
                                    />
                            }
                            <HeaderMenuItem title={Utility.getLang().header.myAccount}
                                            pathToRoute={AppUtil.linkPaths.myAccount.pathToRoute}
                                            onItemClick={this.onHeaderMenuItemClick}
                                            isActive={this.props.headerMenuItemClicked === AppUtil.linkPaths.myAccount.pathToRoute}
                                            className={"navbar__item"}
                                            imageSource={AppUtil.isAvailable(this.props.selectedAuthUser.profileimageurl) ? this.props.selectedAuthUser.profileimageurl : this.profileimageurl}
                            />
                            <HeaderMenuItem title={Utility.getLang().header.logOut}
                                            pathToRoute={"logout"}
                                            onItemClick={this.logOut}
                                            isActive={false}
                                            className={"navbar__item"}
                                            toolAlignRight
                                            skipAnchor
                            />
                        </ul>
                    </div>
                </nav>
            </Hotkeys>
        );
    }
}

function mapStateToProps(state) {
    return {
        showEscapeKey: state.application.showEscapeKey,
        registeredListeners: state.application.registeredListeners,
        headerMenuItemClicked: state.application.headerMenuItemClicked,
        highLightIconSearch: state.application.highLightIconSearch,
        highLightIconNotification: state.application.highLightIconNotification,
        showSearchPanel: state.application.showSearchPanel,
        showNotificationPanel: state.application.showNotificationPanel,
        unreadNotificationCount: state.application.unreadNotificationCount,
        selectedAuthUser: state.application.selectedAuthUser,
        lockCase: state.lockCaseR.lockCase,
        hasUnsavedData: state.application.hasUnsavedData
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        dashboardAction: bindActionCreators(dashboardAction, dispatch),
        wsAction: bindActionCreators(wsAction, dispatch),
        lockCaseAction: bindActionCreators(lockCaseAction, dispatch),
        columnAction: bindActionCreators(columnAction, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch),
        newDashboardAction: bindActionCreators(newDashboardAction, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));

