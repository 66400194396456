import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import AppUtil from "./appUtil";
import SVGInline from "react-svg-inline";
import SvgCache from "../../api/svgCache";

class EmbeddedSvg extends React.Component {

    static addPx(input) {
        return input + "px";
    }

    render() {
        const {
            fileName,
            className,
            width,
            height,
            type
        } = this.props;

        let fileContent = '';
        let classNames = `${type} ${type}__${fileName}`;
        
        if (AppUtil.isAvailable(className)) {
            classNames += ` ${className}`;
        }

        for (let i = 0; i < SvgCache.svgCache.length; i++) {
            if (SvgCache.svgCache[i].name === fileName) {
                fileContent = SvgCache.svgCache[i].content;
                break;
            }
        }

        return (
            <SVGInline
                svg={fileContent}
                width={EmbeddedSvg.addPx(width.toString())}
                height={EmbeddedSvg.addPx(height.toString())}
                className={classNames}
                classSuffix={""}
                onClick={this.props.onClick}
            />
        );
    }
}

EmbeddedSvg.defaultProps = {
    width: 24,
    height: 24,
    type: "icon"
};

EmbeddedSvg.propTypes = {
    fileName: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    onClick: PropTypes.func,
    className: PropTypes.string
};

function mapStateToProps(state, ownProps) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EmbeddedSvg);
