import $ from "jquery";
import config from "./config"
import ErrorUtil from "../components/common/errorUtil";
import AppUtil from "../components/common/appUtil";
import ApiCollection from "./apiCollections";
import {API} from "../components/case/memorialPage/api/memorialApis";

class CaseApi {

    static updateCaseStage(token, url, body) {
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: url,
                method: "PUT",
                data: JSON.stringify(body),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    }

    static updateOrderStage(token, url) {
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: url,
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    }

    static mergeCaseApiCall(token, currentCaseId, targetCaseId) {
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: config.baseURL + 'api/v1/case/' + currentCaseId + "/merge/" + targetCaseId,
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    }

    static emailSummary(token, id, bodyData) {
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: config.baseURL + 'api/v1/case/sendemail',
                method: "POST",
                data: JSON.stringify(bodyData),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    }

    static emailDocuments(token, id, bodyData) {
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                //url: config.baseURL + 'api/v1/case/sendemail',
                url: config.baseURL + 'api/v1/case/' + id + '/document/sendemail',
                method: "POST",
                data: JSON.stringify(bodyData),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    }

    static orderEmailSummary(token, relativeId, orderId) {
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: config.baseURL + 'api/v1/order/' + orderId + '/sendemail_ordersummary?relativeId=' + relativeId,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    }

    static getCounterURL(headerClicked, Id) {
        switch (headerClicked) {
            case AppUtil.linkPaths.case.pathToRoute:
            case AppUtil.linkPaths.convert.pathToRoute:
            case AppUtil.linkPaths.organise.pathToRoute:
                return ApiCollection.properties.countersById.replace("{object}", "case").replace("{objectId}", Id);
            case AppUtil.linkPaths.bill.pathToRoute:
            case AppUtil.linkPaths.order.pathToRoute:
                return ApiCollection.properties.countersById.replace("{object}", "order").replace("{objectId}", Id);
            case AppUtil.linkPaths.call.pathToRoute:
                return ApiCollection.properties.countersById.replace("{object}", "case/call").replace("{objectId}", Id);
            default:
                return "";
        }
    }

    /**
     * @description: TODO: to be used instead of 'getCounters ajax call
     */
    static getCounters_Axios(token, url) {
        return API.get(token, url);
    }

    static getCounters(token, url) {
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: url,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    }

    static parishNameFromFlax(token, deceasedData) {
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: config.baseURL + 'api/v1/case/getparishnamefromflax?address=' + deceasedData.address + '&postalCode=' + deceasedData.postalCode + '&city=' + deceasedData.city,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    }

    static fetchOrderJson(token, urlInfo, id) {
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: urlInfo.replace("{orderTypeId}", id),
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    }

    /***
     * @description: FENIX-4350: Todo template api call will be deprecated
     */
    static fetchCaseTodoTemplates(token, urlInfo) {
        return this.fetchDataWithGivenUrl(token, urlInfo);
    }

    static fetchTodoTemplatesItems(token, urlInfo, caseId, templateId) {
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: urlInfo.replace("{caseId}", caseId).replace("{id}", templateId),
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    }

    static fetchTenantBasedOrderTypes(token, urlInfo) {
        return this.fetchDataWithGivenUrl(token, urlInfo);
    }

    static fetchDataWithGivenUrl(token, urlInfo) {
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: urlInfo,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR, textStatus) {
                ErrorUtil.setUserOnSentry(jqXHR, textStatus);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    }

    static fetchFuneralData(token, urlInfo, bodyData) {
        return new Promise((resolve, reject) => {
            let request = $.ajax({
                url: urlInfo,
                method: "POST",
                data: JSON.stringify(bodyData),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR, textStatus) {
                ErrorUtil.setUserOnSentry(jqXHR, textStatus);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    }
}

export default CaseApi;
