import React from "react";
import Utility from "../../../../../../api/utilLanguage";
import {messageUtils} from "../../../util/util";

function MessageBody({message, editedMessageValue, setMessageInputValue}) {
    const messageContent = message.id !== editedMessageValue.id ? message.messageText : null;
    const editTextarea = messageUtils.advisorType === message.senderType ? (message.id === editedMessageValue.id ? true : false) : false;
    return <>
        <div className={`content ${messageContent === null ? "hide" : ""}`}>
            {messageContent}
        </div>
        {editTextarea &&
        <textarea
            id="updateNote"
            cols="40"
            rows="3"
            value={editedMessageValue.messageText}
            onChange={e => setMessageInputValue(e, messageUtils.fromMessage)}
            className="textarea textarea--max_width"
            placeholder={Utility.getLang().cases.messages.messagePlaceholder}
        />
        }
    </>
}

export default MessageBody;
