import React from "react";

const DBTableHead = ({columns}) => {
    return <thead>
    <tr className="tr">
        {
            columns.map((col, index) => {
                return (
                    <th key={index}
                        className="th"
                        scope="col"
                    >
                        {col.displayName}
                    </th>
                );
            })
        }
        <th
            className="th th--actions"
            scope="col"
        ></th>
    </tr>
    </thead>
}
export default DBTableHead;