import React from "react";

const DBTableBody = ({dataList, columns, getSubview, style = null}) => {
    return <tbody>
    {dataList.map((data, i) => {
        return (
            <tr className="log_item tr tr--button" key={i}>
                {
                    columns.map((col, j) => {
                        return (<td className={`td ${style ? style : ''}`} key={`${col.id}_${j}`}
                                // onClick={() => this.onView(data)}
                            >
                                {getSubview(col.id, data, col?.parentProperty)
                                }
                            </td>
                        );
                    })
                }
            </tr>
        );
    })
    }
    </tbody>
}
export default DBTableBody;