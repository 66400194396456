import AppUtil from "../../appUtil";
import Icon from "../../icon";
import React from "react";
import Utility from "../../../../api/utilLanguage";
import Trace from "../trace";
import LogUtil, {LogTraceObjectType} from "./logUtil";
import {ClickableOrNot} from "../../link/clickableOrNot";

/***
 * @description: simple way of assigning value
 * (there could never have been a previous value, this is used for "sent emails" and "notes").
 * @param key
 * @param value
 * @param isLong
 * @returns {*}
 */
function keyValue(key, value, isLong = false) {
    return (
        <React.Fragment key={key}>
            {
                AppUtil.isAvailable(key) &&
                <dt className="dt">
                    {key}
                </dt>
            }
            {setValue(value, isLong)}
        </React.Fragment>
    );
}

function setValue(value, isLong = false) {
    return (
        <dd className={`dd${isLong ? ' dd--long' : ''}`}>
            {AppUtil.isAvailable(value) ?
                <span className="value">
                    {value}
                </span>
                :
                <span className="value value--placeholder">
                    {Utility.getLang().log.value.unknown}
                </span>
            }
        </dd>
    );
}

function keyValueUpdated(key, valueOld, valueNew, isLong = false) {
    if (AppUtil.isAvailable(valueOld) || AppUtil.isAvailable(valueNew)) {
        return (
            <React.Fragment key={key}>
                {
                    AppUtil.isAvailable(key) &&
                    <dt className="dt">
                        {key}
                    </dt>
                }
                <dd className={`dd${isLong ? ' dd--long' : ''}`}>
                    {AppUtil.isAvailable(valueOld) ?
                        <span
                            className={`value value--old${AppUtil.isAvailable(valueOld) === false ? ' value--placeholder' : ''}`}>
                            {(AppUtil.isAvailable(valueOld)) ? valueOld : Utility.getLang().log.value.unknown}
                        </span>
                        : null}
                    {AppUtil.isAvailable(valueOld) && AppUtil.isAvailable(valueNew) ?
                        <Icon
                            type="chevron_right"
                            className="value value--change"
                            tiny
                        />
                        : null}
                    {AppUtil.isAvailable(valueNew) ?
                        <span className="value value--new">
                            {(AppUtil.isAvailable(valueNew)) ? valueNew : Utility.getLang().log.value.unknown}
                        </span>
                        : null}
                </dd>
            </React.Fragment>
        );
    }
}

/***
 * @description: type > 0, is a trace object
 */
function getTraceOrText(valueDetail, type, logsForObject) {
    if (AppUtil.isAvailable(valueDetail)) {
        if (type > 0) {
            const traceObject = {"id": valueDetail.id, "text": valueDetail.text};
            return getOldOrNewTraceObject(traceObject, type, logsForObject);
        } else {
            return valueDetail.text;
        }
    } else {
        return null;
    }
}

/**
 * @description: Log details UI helper, mapping based on Log type
 */
function componentMappedOnLogType(logType, type, valueDetail, logsForObject, indexedAt, additionalCss = '') {
    // console.log(`[DEBUG]::: oldOrNewValue IndexedAt = ${indexedAt}, Type = ${type}, logType = ${logType}, Id = ${valueDetail.id}, Text = ${valueDetail.text}`);
    switch (logType) {
        case LogUtil.logEvents.stageUpdated:
            return getStage(valueDetail.text, indexedAt);
        case LogUtil.logEvents.caseAssignedUpdated:
        case LogUtil.logEvents.probate_Reviewer_Updated:
        case LogUtil.logEvents.caseCollaboratorUpdated:
        case LogUtil.logEvents.caseCollaboratorDeleted:
            return getUser(valueDetail.text, valueDetail.attribute, indexedAt);
        case LogUtil.logEvents.labelAdded:
        case LogUtil.logEvents.labelDeleted:
            const label = {
                colorCode: valueDetail.attribute,
                title: valueDetail.text
            };
            return labelTags([label], additionalCss);
        default:
            return getTraceOrText(valueDetail, type, logsForObject);
    }
}

function getComponentMappedFromValueDetails(oldOrNewValueDetails, type, logsForObject, indexedAt, {
    logType = 0,
    additionalCss = ''
} = {}) {
    const result = oldOrNewValueDetails.map((valueDetail) => {
        return AppUtil.isEmpty(valueDetail) ? null : componentMappedOnLogType(logType, type, valueDetail, logsForObject, indexedAt, additionalCss)
    });
    /*Handle response [null] avoids unnecessary ">" symbol*/
    if (AppUtil.isAvailable(result) && result.length === 1 && AppUtil.isEmpty(result[0])) {
        return null;
    }
    return result;
}

/**
 * @description: Trace object UI helpers
 */
const getOldOrNewTraceObject = (traceObject, type, logsForObject) => {
    const style = LogUtil.getTraceObjectStyleParameter(LogUtil.getOldOrNewTraceObjectStyle(type));
    traceObject.type = type;
    traceObject.logsForObject = logsForObject;
    return <Trace {...style} object={traceObject} key={traceObject.id}/>;
};

export const getTraceObject = (traceObject, logsForObject) => {
    if ((traceObject.id > 0) || (traceObject.logTraceType === LogTraceObjectType.product)) {
        const style = LogUtil.getTraceObjectStyleParameter(LogUtil.getTraceObjectStyle(traceObject.logType));
        traceObject.type = traceObject.logTraceType;
        traceObject.logsForObject = logsForObject;

        switch (traceObject.logType) {
            case LogUtil.logEvents.caseRelativeAdded:
            case LogUtil.logEvents.caseRelativeDeleted:
            case LogUtil.logEvents.caseRelativeUpdated:
            case LogUtil.logEvents.mainContactUpdated:
            case LogUtil.logEvents.caseOrderCreated:
            case LogUtil.logEvents.caseOrderDeleted:
            case LogUtil.logEvents.caseOrderUpdated:
            case LogUtil.logEvents.orderEventCreated:
            case LogUtil.logEvents.orderEventDeleted:
            case LogUtil.logEvents.orderEventUpdated:
            case LogUtil.logEvents.orderEventAttributeCreated:
            case LogUtil.logEvents.orderEventAttributeDeleted:
            case LogUtil.logEvents.orderEventAttributeUpdated:
            case LogUtil.logEvents.orderEventProductCreated:
            case LogUtil.logEvents.orderEventProductDeleted:
            case LogUtil.logEvents.orderEventProductUpdated:
            case LogUtil.logEvents.noteAdded:
            case LogUtil.logEvents.noteDeleted:
            case LogUtil.logEvents.noteUpdated:
            case LogUtil.logEvents.caseGuestAdded:
            case LogUtil.logEvents.caseGuestDeleted:
            case LogUtil.logEvents.caseGuestUpdated:
            case LogUtil.logEvents.caseGuestListEmailSent:
            case LogUtil.logEvents.caseGuestListEmailSentFailed:
            case LogUtil.logEvents.emailSent:
            case LogUtil.logEvents.emailSentFailed:
            case LogUtil.logEvents.todoAdded:
            case LogUtil.logEvents.probateCaseConnected:
            case LogUtil.logEvents.todoDeleted:
            case LogUtil.logEvents.todoUpdated:
            case LogUtil.logEvents.todoStatusChangedDone:
            case LogUtil.logEvents.todoStatusChangedNotDone:
            case LogUtil.logEvents.documentCreated:
            case LogUtil.logEvents.documentUpdated:
            case LogUtil.logEvents.documentEmailSent:
            case LogUtil.logEvents.documentEmailSentFailed:
            case LogUtil.logEvents.documentDeleted:
            case LogUtil.logEvents.documentVersionCreated:
            case LogUtil.logEvents.caseDocumentCreated:
            case LogUtil.logEvents.caseDocumentDeleted:
            case LogUtil.logEvents.caseDocumentEmailSent:
            case LogUtil.logEvents.caseDocumentEmailSentFailed:
            case LogUtil.logEvents.documentUploaded:
            case LogUtil.logEvents.caseDocumentUploaded:
            case LogUtil.logEvents.documentTypeEdited:
            case LogUtil.logEvents.caseCallAdded:
            case LogUtil.logEvents.caseCallDeleted:
            case LogUtil.logEvents.probate_Note_Added:
            case LogUtil.logEvents.probate_Beneficiary_Deleted:
                return (
                    <React.Fragment key={traceObject.id}>
                        <Trace object="case"/>
                        <Trace {...style} object={traceObject} key={traceObject.id}/>
                    </React.Fragment>
                );
            case LogUtil.logEvents.created:
            case LogUtil.logEvents.deleted:
            case LogUtil.logEvents.updated:
            case LogUtil.logEvents.caseDeceasedUpdated:
            case LogUtil.logEvents.caseAssignedUpdated:
            case LogUtil.logEvents.stageUpdated:
            case LogUtil.logEvents.labelAdded:
            case LogUtil.logEvents.labelDeleted:
            case LogUtil.logEvents.labelUpdated:
            case LogUtil.logEvents.bCCustomer:
            case LogUtil.logEvents.bCSales:
            case LogUtil.logEvents.customMessage:
            case LogUtil.logEvents.npsWebHookResponse:
            case LogUtil.logEvents.bcError:
            case LogUtil.logEvents.probate_Reviewer_Updated:
            case LogUtil.logEvents.caseCollaboratorUpdated:
            case LogUtil.logEvents.caseCollaboratorDeleted:
                return <Trace {...style} object={traceObject} key={traceObject.id}/>;
            default:
                return <Trace {...style} object={traceObject} key={traceObject.id}/>;
        }
    } else {
        return traceObject.text;
    }
};

/**
 * @description: Separate UI components
 */
export const avatar = (img) => {
    return (
        <span className="avatar avatar--16">
            {
                AppUtil.isAvailable(img) ?
                    <img
                        src={img}
                        width="16"
                        height="16"
                        alt={Utility.getLang().common.avatar}
                    /> : <Icon type="avatar" tiny/>
            }

    </span>
    )
};

export function timestamp(time) {
    return (
        <td className="td td--timestamp">
            <span className="timestamp">
                {AppUtil.isAvailable(time) ? time : "-"}
            </span>
        </td>
    );
}

export function author(img, content) {
    return (
        <td className="td td--author">
            <span className="log_item__action log_item__action--author">
                {avatar(img)}
                <span>{AppUtil.isAvailable(content) ? content : "-"}</span>
            </span>
        </td>
    );
}

export const caseAdded = (logCollection, logsForObject, indexedAt) => {
    return <div className="content">
        <dl className="changeset changeset--case">
            {
                logCollection.map((rowDetail) => {
                    return keyValueUpdated(
                        rowDetail.fieldName,
                        getComponentMappedFromValueDetails(rowDetail.oldValueDetails, rowDetail.type, logsForObject, indexedAt),
                        getComponentMappedFromValueDetails(rowDetail.newValueDetails, rowDetail.type, logsForObject, indexedAt)
                    )
                })
            }
        </dl>
    </div>
};

function getStage(value, key) {
    return <span className="tag tag--type tag--status tag--small" key={key}>{value}</span>;
}

export const statusUpdated = (logCollection, logsForObject, indexedAt) => {
    return <div className="content">
        <dl className="changeset changeset--case">
            {
                logCollection.map((rowDetail) => {
                    return keyValueUpdated(
                        rowDetail.fieldName,
                        getComponentMappedFromValueDetails(rowDetail.oldValueDetails, rowDetail.type, logsForObject, indexedAt, {
                            logType: LogUtil.logEvents.stageUpdated
                        }),
                        getComponentMappedFromValueDetails(rowDetail.newValueDetails, rowDetail.type, logsForObject, indexedAt, {
                            logType: LogUtil.logEvents.stageUpdated
                        })
                    )
                })
            }
        </dl>
    </div>
};

function getUser(content, profileUrl, key, additionalCss = 'tag--added') {
    return <span className={`tag tag--type tag--owner tag--small ${additionalCss}`} key={key}>
        {avatar(profileUrl)}
        <span>{content}</span>
        </span>
}

export const caseAssigned = (logCollection, logsForObject, indexedAt) => {
    return <div className="content">
        <dl className="changeset changeset--case">
            {
                logCollection.map((rowDetail) => {
                    return keyValueUpdated(
                        rowDetail.fieldName,
                        getComponentMappedFromValueDetails(rowDetail.oldValueDetails, rowDetail.type, logsForObject, indexedAt, {
                            logType: LogUtil.logEvents.caseAssignedUpdated
                        }),
                        getComponentMappedFromValueDetails(rowDetail.newValueDetails, rowDetail.type, logsForObject, indexedAt, {
                            logType: LogUtil.logEvents.caseAssignedUpdated
                        })
                    )
                })
            }
        </dl>
    </div>
};

const commonContent = (logCollection, logsForObject, indexedAt, logType) => {
    return <div className="content">
        <dl className="changeset changeset--case">
            {
                logCollection.map((rowDetail) => {
                    return keyValueUpdated(
                        rowDetail.fieldName,
                        getComponentMappedFromValueDetails(rowDetail.oldValueDetails, rowDetail.type, logsForObject, indexedAt, {
                            logType: logType
                        }),
                        getComponentMappedFromValueDetails(rowDetail.newValueDetails, rowDetail.type, logsForObject, indexedAt, {
                            logType: logType
                        })
                    )
                })
            }
        </dl>
    </div>
}

export const caseCollaborator = (logCollection, logsForObject, indexedAt) => {
    return commonContent(logCollection, logsForObject, indexedAt, LogUtil.logEvents.caseCollaboratorUpdated);
}

export const caseCollaboratorRemoved = (logCollection, logsForObject, indexedAt) => {
    return commonContent(logCollection, logsForObject, indexedAt, LogUtil.logEvents.caseCollaboratorDeleted);
}

export const probateReviewer = (logCollection, logsForObject, indexedAt) => {
    return <div className="content">
        <dl className="changeset changeset--case">
            {
                logCollection.map((rowDetail) => {
                    return keyValueUpdated(
                        rowDetail.fieldName,
                        getComponentMappedFromValueDetails(rowDetail.oldValueDetails, rowDetail.type, logsForObject, indexedAt, {
                            logType: LogUtil.logEvents.probate_Reviewer_Updated
                        }),
                        getComponentMappedFromValueDetails(rowDetail.newValueDetails, rowDetail.type, logsForObject, indexedAt, {
                            logType: LogUtil.logEvents.probate_Reviewer_Updated
                        })
                    )
                })
            }
        </dl>
    </div>
};

function getLabelTag(colorCode, title, index, additionalCss) {
    return (
        <span
            key={index}
            className={`tag tag--label tag--small ${colorCode ? `tag--${colorCode}` : ''} ${AppUtil.isAvailable(additionalCss) ? additionalCss : ''}`}
        >
            {title}
        </span>
    );
};

export function labelTags(value, additionalCss) {
    return (
        <span className="tags tags--labels">
            {
                value.map((label, i) => {
                    return (getLabelTag(label.colorCode, label.title, i, additionalCss));
                })
            }
        </span>
    );
}

export const labelAdded = (logCollection, logsForObject, indexedAt) => {
    return <div className="content">
        <dl className="changeset changeset--case">
            {
                logCollection.map((rowDetail) => {
                    return getComponentMappedFromValueDetails(rowDetail.newValueDetails, rowDetail.type, logsForObject, indexedAt, {
                        logType: LogUtil.logEvents.labelAdded
                    });
                })
            }
        </dl>
    </div>
};

export const labelRemoved = (logCollection, logsForObject, indexedAt) => {
    return <div className="content">
        <dl className="changeset changeset--case">
            {
                logCollection.map((rowDetail) => {
                    return getComponentMappedFromValueDetails(rowDetail.newValueDetails, rowDetail.type, logsForObject, indexedAt, {
                        logType: LogUtil.logEvents.labelDeleted,
                        additionalCss: 'tag--removed'
                    })
                })
            }
        </dl>
    </div>
};

export const deceasedUpdated = (logCollection, logsForObject, indexedAt) => {
    return <div className="content">
        <dl className="changeset changeset--case">
            {
                logCollection.map((rowDetail) => {
                    return keyValueUpdated(
                        rowDetail.fieldName,
                        getComponentMappedFromValueDetails(rowDetail.oldValueDetails, rowDetail.type, logsForObject, indexedAt),
                        getComponentMappedFromValueDetails(rowDetail.newValueDetails, rowDetail.type, logsForObject, indexedAt)
                    )
                })
            }
        </dl>
    </div>
};

export const contactUpdated = (logCollection, logsForObject, indexedAt) => {
    return <div className="content">
        <dl className="changeset changeset--contact">
            {
                logCollection.map((rowDetail) => {
                    return keyValueUpdated(
                        rowDetail.fieldName,
                        getComponentMappedFromValueDetails(rowDetail.oldValueDetails, rowDetail.type, logsForObject, indexedAt),
                        getComponentMappedFromValueDetails(rowDetail.newValueDetails, rowDetail.type, logsForObject, indexedAt)
                    )
                })
            }
        </dl>
    </div>
};

export const mainContactUpdated = (logCollection, logsForObject, indexedAt) => {
    return <div className="content">
        <dl className="changeset changeset--case">
            {
                logCollection.map((rowDetail) => {
                    return keyValueUpdated(
                        rowDetail.fieldName,
                        getComponentMappedFromValueDetails(rowDetail.oldValueDetails, rowDetail.type, logsForObject, indexedAt),
                        getComponentMappedFromValueDetails(rowDetail.newValueDetails, rowDetail.type, logsForObject, indexedAt)
                    )
                })
            }
        </dl>
    </div>
};

export const registrationUpdated = (logCollection, logsForObject, indexedAt) => {
    return <div className="content">
        <dl className="changeset  changeset--registration">
            {
                logCollection.map((rowDetail) => {
                    return keyValueUpdated(
                        rowDetail.fieldName,
                        getComponentMappedFromValueDetails(rowDetail.oldValueDetails, rowDetail.type, logsForObject, indexedAt),
                        getComponentMappedFromValueDetails(rowDetail.newValueDetails, rowDetail.type, logsForObject, indexedAt)
                    )
                })
            }
        </dl>
    </div>
};

export const emailSent = (logCollection, logsForObject, indexedAt) => {
    return <div className="content">
        <dl className="changeset changeset--email">
            {
                logCollection.map((rowDetail) => {
                    return keyValue(rowDetail.fieldName,
                        getComponentMappedFromValueDetails(rowDetail.newValueDetails, rowDetail.type, logsForObject, indexedAt))
                })
            }
        </dl>
    </div>
};

//TODO: - Will be removed after testing
//FYI:Similar to emailSent expect order tag
export const emailSent2 = (logCollection, logsForObject, indexedAt) => {
    return <div className="content">
        <dl className="changeset changeset--email">
            {keyValue(
                Utility.getLang().log.key.order,
                (
                    <span className="tag tag--small tag--button tag--order">
                        <Icon type="order-funeral" tiny/>
                        <span>Funeral with ceremony – 1002</span>
                        <Icon type="external" tiny/>
                    </span>
                )
            )}
            {keyValue(
                Utility.getLang().log.key.recipient,
                (
                    <span className="tag tag--small tag--type tag--user tag--button">
                        <Icon type="user" tiny/>
                        <span>Mattias Vikstrand</span>
                        <Icon type="external" tiny/>
                    </span>
                )
            )}
            {keyValue(
                Utility.getLang().log.key.email,
                (
                    <a
                        href="/"
                        className="link is-external"
                    >
                        anotheradress@gmail.se
                    </a>
                )
            )}
        </dl>
    </div>
};

export const todoAdded = (logCollection, logsForObject, indexedAt) => {
    return <div className="content">
        <dl className="changeset changeset--case">
            {logCollection.map((rowDetail) => {
                return setValue(
                    getComponentMappedFromValueDetails(rowDetail.newValueDetails, rowDetail.type, logsForObject, indexedAt)
                );
            })}
        </dl>
    </div>
};

export const todoUpdated = (logCollection, logsForObject, indexedAt) => {
    return <div className="content">
        <dl className="changeset changeset--case">
            {logCollection.map((rowDetail) => {
                return keyValueUpdated(
                    rowDetail.fieldName,
                    getComponentMappedFromValueDetails(rowDetail.oldValueDetails, rowDetail.type, logsForObject, indexedAt),
                    getComponentMappedFromValueDetails(rowDetail.newValueDetails, rowDetail.type, logsForObject, indexedAt)
                );
            })}
        </dl>
    </div>
};

export const orderUpdated = (logCollection, logsForObject, indexedAt) => {
    return <div className="content">
        <dl className="changeset changeset--order">
            {
                logCollection.map((rowDetail) => {
                    return keyValueUpdated(
                        rowDetail.fieldName,
                        getComponentMappedFromValueDetails(rowDetail.oldValueDetails, rowDetail.type, logsForObject, indexedAt),
                        getComponentMappedFromValueDetails(rowDetail.newValueDetails, rowDetail.type, logsForObject, indexedAt)
                    )
                })
            }
        </dl>
    </div>
};

export const noteAdded = (logCollection, logsForObject, indexedAt) => {
    return <div className="content">
        <dl className="changeset changeset--case">
            {
                logCollection.map((rowDetail) => {
                    return setValue(
                        getComponentMappedFromValueDetails(rowDetail.newValueDetails, rowDetail.type, logsForObject, indexedAt),
                        true
                    )
                })
            }
        </dl>
    </div>
};

export const noteRemoved = (logCollection, logsForObject, indexedAt) => {
    return <div className="content content--removed">
        <dl className="changeset changeset--case">
            {
                logCollection.map((rowDetail) => {
                    return setValue(
                        getComponentMappedFromValueDetails(rowDetail.newValueDetails, rowDetail.type, logsForObject, indexedAt),
                        true
                    )
                })
            }
        </dl>
    </div>
};

export const messageAdded = (logCollection, logsForObject, indexedAt) => {
    return <div className="content">
        <dl className="changeset changeset--case">
            {
                logCollection.map((rowDetail) => {
                    return setValue(
                        getComponentMappedFromValueDetails(rowDetail.newValueDetails, rowDetail.type, logsForObject, indexedAt),
                        true
                    )
                })
            }
        </dl>
    </div>
};

export const documentUpdated = (logCollection, logsForObject, indexedAt) => {
    return <div className="content">
        <dl className="changeset changeset--document">
            {
                logCollection.map((rowDetail) => {
                    return keyValueUpdated(
                        rowDetail.fieldName,
                        getComponentMappedFromValueDetails(rowDetail.oldValueDetails, (rowDetail.type === LogTraceObjectType.version) ? rowDetail.type : 0, (rowDetail.type === LogTraceObjectType.version) ? logsForObject : null, indexedAt),
                        getComponentMappedFromValueDetails(rowDetail.newValueDetails, (rowDetail.type === LogTraceObjectType.version) ? rowDetail.type : 0, (rowDetail.type === LogTraceObjectType.version) ? logsForObject : null, indexedAt)
                    )
                })
            }
        </dl>
    </div>
};
export const documentAdded = (logCollection, logsForObject, indexedAt) => {
    return <div className="content">
        <dl className="changeset changeset--document">
            {
                logCollection.map((rowDetail) => {
                    return keyValueUpdated(
                        rowDetail.fieldName,
                        getComponentMappedFromValueDetails(rowDetail.oldValueDetails, rowDetail.type, logsForObject, indexedAt),
                        getComponentMappedFromValueDetails(rowDetail.newValueDetails, rowDetail.type, logsForObject, indexedAt),
                    )
                })
            }
        </dl>
    </div>
};

export const bcUpdated = (logCollection, logsForObject, indexedAt) => {
    return <div className="content">
        <dl className="changeset changeset--bc">
            {
                logCollection.map((rowDetail) => {
                    return keyValueUpdated(
                        rowDetail.fieldName,
                        getComponentMappedFromValueDetails(rowDetail.oldValueDetails, rowDetail.type, logsForObject, indexedAt),
                        getComponentMappedFromValueDetails(rowDetail.newValueDetails, rowDetail.type, logsForObject, indexedAt)
                    )
                })
            }
        </dl>
    </div>
};

export const lossReason = (logCollection, logsForObject, indexedAt) => {
    return <div className="content">
        {
            logCollection.map((rowDetail) => {
                return keyValue(
                    rowDetail.fieldName,
                    getComponentMappedFromValueDetails(rowDetail.newValueDetails, rowDetail.type, logsForObject, indexedAt),
                    true
                )
            })
        }
    </div>
};

export const bcError = (logCollection, logsForObject, indexedAt) => {
    return <div className="content">
        {
            logCollection.map((rowDetail) => {
                return keyValue(
                    rowDetail.fieldName,
                    getComponentMappedFromValueDetails(rowDetail.newValueDetails, rowDetail.type, logsForObject, indexedAt),
                    true
                )
            })
        }
    </div>
};


/**
 * @description: Trace logic helpers
 */
function mailToRef(value) {
    return "mailto:" + value;
}

export function clickablePath(logTraceType, logType, value, id, logsForObjectId) {
    if (logTraceType === LogTraceObjectType.email) {
        return mailToRef(value);
    } else if (logTraceType === LogTraceObjectType.case && logType == LogUtil.logEvents.probateCaseConnected) {
        const linkableId = id;
        return LogUtil.getClickablePath(logTraceType, linkableId);
    } else {
        const linkableId = LogUtil.getRoutingId(logTraceType, id, logsForObjectId);
        return LogUtil.getClickablePath(logTraceType, linkableId);
    }
};

export function mailTo(value) {
    function doNothing(e) {
        e.stopPropagation();
    }

    return <a
        href={mailToRef(value)}
        onClick={doNothing}
        className="link is-external">
        {value}
    </a>
}

export function versionTo(object, styleType) {
    function getVersionChildElement(value, isRemoved) {
        return <React.Fragment>
            <span>{value}</span>
            {
                !isRemoved && <Icon type="external" tiny/>
            }
        </React.Fragment>;
    }

    const isRemoved = LogUtil.isRemoved(styleType);
    return (
        <ClickableOrNot isClickable={!isRemoved}
                        link={clickablePath(object.type, object.logType, object.text, object.id, AppUtil.isAvailable(object.logsForObject) ? object.logsForObject.id : null)}
                        className={"is-external"}
        >
            {getVersionChildElement(object.text || object.id, isRemoved)}
        </ClickableOrNot>
    );
}

export const LogToMessages = (props) => {
    const {href, traceObjects, chooseMessageTab} = props;
    if (traceObjects !== null && (traceObjects.length > 0)) {
        const messageLink = traceObjects[0];
        return <a
            target="_blank"
            href={href}
            onClick={() => chooseMessageTab(messageLink.id)}
            className="tag tag--small tag--button tag--button--primary tag--order">
            <Icon type="message" tiny/> {messageLink.text} <Icon type="external" tiny/>
        </a>
    } else {
        return null;
    }
}
