import React from 'react';
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as caseOrderAction from "../../actions/caseOrderAction";
import * as caseAction from "../../actions/caseAction";
import connect from "react-redux/lib/connect/connect";
import {reduxForm} from "redux-form";
import CaseHeaderSection from "./caseHeaderSection";
import CaseOrderList from "./caseOrderSubComponents/caseOrderList";
import OrderContainer from "./caseTenantBasedOrder/OrderContainer";
import PopupForEmailSend from "../common/popups/popupForEmailSend";
import PopupForOrderEmail from "../common/popups/popupForOrderEmail";
import {bottomCenter} from "../common/customToastr";
import Utility from "../../api/utilLanguage";
import PreLoader from "../common/preloader";
import {ValidationErrors} from "../common/validationUtil";
import AppUtil from "../common/appUtil";
import {getValidationRules} from '../common/localStorageUtil';

class CaseOrderTenantBasedComponent extends React.Component {

    constructor(props) {
        super(props);
        this.sendEmailType = 'case';
        this.tenantCode = JSON.parse(getValidationRules());
    }

    UNSAFE_componentWillMount = () => {
        if (this.props.orderTypes.length === 0) {
            this.props.caseOrderAction.getTenantBasedOrderTypesApi().then();
        }
    };

    componentDidMount = () => {
        this.props.caseAction.getAllUserDetails();
    };

    openOrderForm = (clickedOrder) => {
        this.props.caseAction.getOrderJson(clickedOrder.id);
    };


    fetchFuneralPackage = (data) => {
        this.props.caseAction.getFuneralPackage(data);
    }

    componentDidUpdate = (prevProps) => {
        if (JSON.stringify(prevProps.selectedCaseData) !== JSON.stringify(this.props.selectedCaseData)) {
            const errorsInfo = ValidationErrors.info(this.props.selectedCaseData);
            if (ValidationErrors.isAvailable(errorsInfo)) {
                this.props.caseAction.updateValidationErrorsInfo(errorsInfo);
            } else {
                this.props.caseAction.updateValidationErrorsInfo({});
            }
        }
    };

    changeEmailTypeToSend = (value) => {
        this.sendEmailType = value;
    };

    sendOrderEmailSummary = (relativeId, orderId, bodyData) => {
        if (relativeId !== null && parseInt(relativeId, 10) !== 0) {
            let orderIds = [];
            orderIds.push(orderId);
            bodyData.caseId = this.props.selectedCaseData.id;
            bodyData.orderIds = orderIds;
            this.props.caseAction.sendEmailSummary(orderId, bodyData);
        } else {
            bottomCenter().warning(Utility.getLang().warningMessages.case.selectRelative);
        }
    };

    onConfirmSend = () => {
        this.hidePopup()
    };

    hidePopupOnCancel = () => {
        this.sendEmailType = 'case';
        this.props.actionMaster.hidePopupSection();
    };

    hidePopup = () => {
        this.props.actionMaster.hidePopupSection();
    };

    render = () => {
        const {selectedCaseData} = this.props;
        return (
            <React.Fragment>
                <PreLoader onRef={ref => this.preLoader = ref} showPreload={false}
                           container={this.node} blockContainer={true} type="Oval"/>
                <div className="section section--detail section--scroll" ref={node => this.node = node}>
                    <CaseHeaderSection wsLockCaseData={this.props.wsLockCaseData}
                                       selectedCaseData={this.props.selectedCaseData}/>
                    <OrderContainer
                        {...this.props}
                        sendOrderEmailSummary={this.sendOrderEmailSummary}
                        changeEmailTypeToSend={this.changeEmailTypeToSend}
                        hideEmailPopup={this.hidePopupOnCancel}
                        emailSendConfirm={this.onConfirmSend}
                    />
                    {
                        this.props.orderTypes.length > 0 ?
                            <CaseOrderList
                                orders={AppUtil.isAvailable(selectedCaseData) && AppUtil.isAvailable(selectedCaseData.orders) ? selectedCaseData.orders : []}
                                clickedOrder={this.openOrderForm}
                                orderTypes={this.props.orderTypes}
                                disabledView={this.props.disabledView}/>
                            : null
                    }
                </div>
                <PopupForEmailSend
                    openModal={this.props.openOnSend}
                    onConfirmation={this.onConfirmSend}
                    closePopup={this.hidePopupOnCancel}
                    source={this.sendEmailType}
                    sendCaseEmailSummary={(relativeId, data, emailTemplateType) => this.sendEmailSummary(relativeId, data, emailTemplateType)}
                />
                <PopupForOrderEmail
                    openModal={this.props.openEmailPopupOnSend}
                    closePopup={this.hidePopup}
                    billingContactIdOrders={""}
                    relativesOfOrder={this.props.selectedCaseData.relatives}
                    sendEmailSummary={(relativeID, data, orderId) => this.sendOrderEmailSummary(relativeID, orderId, data)}
                />
                {/* TODO: Causing unnecessary re-rendering, at 'funeralButtonStatusUpdate'*/}
                {
                    /*isFieldEnabled(ValidationAndVisibilityRule.order, ValidationAndVisibilityRule.funeralButton) &&
                    <FuneralPopup
                        openModal={this.props.openFuneralPackagePopup}
                        orderTypes={this.props.orderTypes}
                        clickedOrder={this.openOrderForm}
                        closePopup={this.hidePopup}
                        callFuneralPackage={this.fetchFuneralPackage}
                    />*/
                }
            </React.Fragment>

        );
    }
}

CaseOrderTenantBasedComponent.propTypes = {
    columnDetailViewTabCollection: PropTypes.array,
};

function mapStateToProps(state) {
    return {
        selectedCaseData: state.application.selectedCaseData,
        selectedOrderData: state.application.selectedOrderData,
        orderTypes: state.application.orderTypes,
        wsLockCaseData: state.application.wsLockCaseData,
        openOnSend: state.application.openOnSend,
        openEmailPopupOnSend: state.application.openEmailPopupOnSend,
        openFuneralPackagePopup: state.application.openFuneralPackagePopup,

        headerMenuItemClicked: state.application.headerMenuItemClicked,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        caseOrderAction: bindActionCreators(caseOrderAction, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: "CaseOrderTenantBasedComponent"
})(CaseOrderTenantBasedComponent));
