import AppUtil, {ArrayUtil, NOT_FOUND} from "../../../appUtil";
import CaseUtil from "../../../caseUtil";
import OrderUtil from "../../../orderUtil";
import ConvertService from "../../../../../api/convertService";
import OrganiseService from "../../../../../api/organiseService";
import BillService from "../../../../../api/billService";
import {ColumnPageMapperProps, CRUDOperation} from "../redux/types";
import {CardUtil} from "../../details/util/cardDetailUtil";
import {cloneDeep} from "lodash";
import Enum from "../../../enum";

export default class ColumnUtil {
    static showColumnView = true;//TODO: Remove after columnComponent is removed

    /***
     * @description: Column configuration helper
     */
    static getDefaultColumnConfig(pathToRoute) {
        if (pathToRoute === AppUtil.linkPaths.convert.pathToRoute) {
            return [...ConvertService.getConvertPageColumnsConfiguration().columns];
        } else if (pathToRoute === AppUtil.linkPaths.organise.pathToRoute) {
            return [...OrganiseService.getOrganisePageColumnConfiguration().columns];
        } else if (pathToRoute === AppUtil.linkPaths.bill.pathToRoute) {
            return [...BillService.getBillPageColumnsConfiguration().columns];
        } else {
            return [];
        }
    };

    /***
     * @description: Column data url helper
     */
    static defaultUrlOfColumnData = (column) => {
        return column.dataSourceUrl + column.dataSourceUrlRequestParameters;
    };

    static urlOfColumnData(filterData, column) {
        let url = "";
        if (AppUtil.isAvailable(filterData) && (filterData.length > 0)) {
            const resultFilterParam = URLQuery.getFilterParameters(filterData, column.filter, column.sort);
            url = column.dataSourceUrl + resultFilterParam.filterResult + resultFilterParam.sortResult;
        } else {
            url = this.defaultUrlOfColumnData(column);
        }
        return url;
    };

    // reset the url with search values
    static resetUrlWithSearchData(url, requestParameters,) {
        const psiObj = requestParameters.filter((item) => item.key === Enum.PaginationStartIndex);
        const urlStartValue = parseInt(psiObj[0].value);
        return url.replace(`${Enum.PaginationStartIndex}=${urlStartValue}`, `${Enum.PaginationStartIndex}=0`);
    }

    /**
     * Moves an item from one list to another list.
     */
    static moveItem(sourceColumn, destColumn, sourceDroppable, destDroppable, extraParams, callbackResult) {

        const sourceColumnClone = Array.from(sourceColumn);
        const destColumnClone = Array.from(destColumn);
        const [movedCaseOrOrderData] = sourceColumnClone.splice(sourceDroppable.index, 1);

        const oldStage = ColumnUtil.value(sourceDroppable.droppableId);
        const newStage = ColumnUtil.value(destDroppable.droppableId);

        const result = {};

        if (AppUtil.isAvailable(movedCaseOrOrderData)) {
            if (extraParams.shouldUpdateOrderDetails) {
                ColumnUtil.updateMovedOrderData(movedCaseOrOrderData, newStage, extraParams.billingContactId);
            } else {
                ColumnUtil.updateMovedCaseData(movedCaseOrOrderData, newStage, extraParams.users, extraParams.owner, extraParams.order);
            }
        }
        //Insert moved Card at the top
        destColumnClone.splice(0, 0, movedCaseOrOrderData);

        result[oldStage] = sourceColumnClone;
        result[newStage] = destColumnClone;

        callbackResult(result);
    };

    static updateMovedCaseData(data, newStageId, users, assignedId, order) {
        const flatCaseStages = CaseUtil.getFlatCaseStages();
        const matchedStage = flatCaseStages.find(stage => {
            return (stage.value === newStageId);
        });
        if (matchedStage !== undefined) {
            CaseUtil.updateDataWithStage(data, matchedStage);
        }

        if (AppUtil.isAvailable(assignedId) && (AppUtil.isAvailable(users) && users.length > 0)) {
            const matchedAssignedUser = users.find(user => {
                return (user.id === assignedId);
            });
            if (matchedAssignedUser !== undefined) {
                CaseUtil.updateDataWithAssignee(data, matchedAssignedUser);
            }
        }

        data.order = order;
    };

    static updateMovedOrderData(data, newStageId, billingContactId) {
        const flatOrderStages = OrderUtil.getFlatOrderStages();
        const matchedStage = flatOrderStages.find(stage => {
            return (stage.value === newStageId);
        });
        if (matchedStage !== undefined) {
            CaseUtil.updateDataWithStage(data, matchedStage);
            data.billingContactId = billingContactId;
        }
    }

    static isColumnHighlighted(columnInfo, ruleMatrix, sourceColumnId) {
        const isDroppable = ColumnUtil.isDroppable(columnInfo, ruleMatrix, sourceColumnId);
        const isDragging = ColumnUtil.isDragging(columnInfo, sourceColumnId);
        const isHighlighted = isDragging && isDroppable;
        // console.log("[Debug]:: isHighlighted :: sourceColumnId = %s, isDroppable = %s, isDragging = %s, isHighlighted = %s,", sourceColumnId, isDroppable, isDragging, isHighlighted);
        return isHighlighted;
    }

    static isDroppable(columnInfo, ruleMatrix, sourceColumnId) {
        const destColumnId = AppUtil.isAvailable(columnInfo.stage) ? columnInfo.stage.id : 0;
        // console.log("[isDroppable] :: sourceColumnId = %s, destColumnId = %s, ruleMatrix = %o", sourceColumnId, destColumnId, ruleMatrix);
        const configSettingDrop = (AppUtil.isAvailable(columnInfo.draganddrop) && AppUtil.isAvailable(columnInfo.draganddrop.drop)) ? columnInfo.draganddrop.drop.possible : false;
        const isDroppable = AppUtil.isAvailable(ruleMatrix[destColumnId]) ? (ruleMatrix[destColumnId].includes(sourceColumnId) && configSettingDrop) : false;
        return isDroppable;
    }

    static isDragging(columnInfo, sourceColumnId) {
        return AppUtil.isAvailable(columnInfo.stage) ? (columnInfo.stage.id !== sourceColumnId) : false;
    }

    static columnId(columnInfo) {
        return this.storingKey(columnInfo);
    }

    static getStageUpdateExtraParams(headerMenuItemClicked, popupCase, orderDragDrop) {
        let extraParam = {};
        extraParam.owner = popupCase.owner;
        extraParam.lossReason = popupCase.lossReason;
        extraParam.collaboratorId = popupCase.collaboratorId;
        extraParam.countyId = popupCase.countyId;
        switch (headerMenuItemClicked) {
            case AppUtil.linkPaths.convert.pathToRoute:
            case AppUtil.linkPaths.organise.pathToRoute:
                extraParam.orderDetails = popupCase.orderDetails;
                break;
            case AppUtil.linkPaths.bill.pathToRoute:
                extraParam.billingContactId = orderDragDrop.billingContactId;
                extraParam.deliveryMethod = orderDragDrop.deliveryMethod;
                break;
            default:
                break;
        }
        return extraParam;
    }

    /***
     * @description: used for DraggableId & DroppableId, as are strictly strings
     */
    static string(value) {
        return JSON.stringify(value);
    }

    static value(value) {
        return JSON.parse(value);
    }

    /***
     * @description: Auto updating column card
     */
    static storingKey(columnInfo) {
        if (AppUtil.isAvailable(columnInfo)) {
            return (this.isColumnWithUniqueStage(columnInfo)) ? columnInfo.stage.id : columnInfo.itemsPropertyName;
        } else {
            console.log("%c [Debug]:: Failure to read 'columnId' as columnInfo = ", 'color: orange;font-size:12px;', columnInfo);
            return NOT_FOUND * 1000;
        }
    }

    /***
     * @description: whether columns are from sell/deliver/bill
     */
    static isColumnWithUniqueStage = (columnInfo) => {
        return AppUtil.isAvailable(columnInfo) && CardUtil.canBeDraggable(columnInfo.draganddrop) && AppUtil.isAvailable(columnInfo.stage);
    };

    /***
     * @description: Supports CRUD operation and updates 'columnPageDataMapper'
     */
    static updateColumnPageMapper(CRUDOperation, payload, columnPageDataMapper, storingKey, idToMatch, isColumnWithUniqueStage) {
        const resultCrudOperation = ArrayUtil.getCrudOperation(CRUDOperation, idToMatch, columnPageDataMapper[storingKey])
        const {
            matchedIndex,
            crudOperation
        } = ColumnUtil.updateColumnPageListItem(resultCrudOperation, columnPageDataMapper[storingKey], payload, idToMatch, columnPageDataMapper, isColumnWithUniqueStage, storingKey);
        if (matchedIndex !== NOT_FOUND) {
            ColumnUtil.updateSelectionInfoToColumnPage(columnPageDataMapper, storingKey, matchedIndex, crudOperation);
        }
        return matchedIndex;
    }

    static updateColumnPageListItem = (crudOperationType, list, payload, idToMatch, columnPageDataMapper, isColumnWithUniqueStage, storingKey) => {
        const returnedInfo = {matchedIndex: NOT_FOUND, crudOperation: crudOperationType};
        if (AppUtil.isAvailable(list)) {
            switch (crudOperationType) {
                case CRUDOperation.CREATE: {
                    const updatedPayload = CardUtil.getTransformedDataFromResponse(payload, crudOperationType);
                    returnedInfo.matchedIndex = ArrayUtil.addOrUpdateNewInList(list, updatedPayload);
                    return returnedInfo;
                }
                case CRUDOperation.UPDATE: {
                    const updatedPayload = CardUtil.getTransformedDataFromResponse(payload, crudOperationType);
                    if (isColumnWithUniqueStage) {
                        const {
                            matchedIndex,
                            crudOperation
                        } = ColumnUtil.getInfoForUpdateIfStageChanges(idToMatch, updatedPayload, columnPageDataMapper, storingKey);
                        returnedInfo.matchedIndex = matchedIndex;
                        returnedInfo.crudOperation = crudOperation;
                    } else {
                        returnedInfo.matchedIndex = ArrayUtil.updateList(list, updatedPayload, idToMatch);
                    }
                    return returnedInfo;
                }
                case CRUDOperation.DELETE: {
                    returnedInfo.matchedIndex = ArrayUtil.deleteList(list, idToMatch);
                    return returnedInfo;
                }
                default:
                    console.log("%c [DEBUG]:: Not handled (%s) CRUD type", 'color: orange;font-size:12px;', crudOperationType);
                    returnedInfo.matchedIndex = NOT_FOUND;
                    return returnedInfo;
            }
        } else {
            return returnedInfo;
        }
    }

    static getInfoForUpdateIfStageChanges(idToMatch, updatedPayload, columnPageDataMapper, storingKey) {
        const returnedInfo = {matchedIndex: NOT_FOUND, crudOperation: CRUDOperation.UPDATE};

        const list = columnPageDataMapper[storingKey];
        const matchedIndex = ArrayUtil.matchedIndex(list, idToMatch);
        if (matchedIndex === NOT_FOUND) {
            return returnedInfo;
        }
        const oldPayload = list[matchedIndex];

        const oldStage = oldPayload.stage.id;
        const newStage = updatedPayload.stage.id;
        if (oldStage !== newStage) {
            if (AppUtil.isAvailable(columnPageDataMapper[newStage])) {
                //Update at newStage[0]
                columnPageDataMapper[newStage].unshift(updatedPayload);
            }
            //Delete at oldStage[matchedIndex])
            returnedInfo.matchedIndex = ArrayUtil.deleteList(list, idToMatch);
            returnedInfo.crudOperation = CRUDOperation.DELETE_WITH_NO_SELECTION;
        } else {
            returnedInfo.matchedIndex = ArrayUtil.updateList(list, updatedPayload, idToMatch);
        }
        return returnedInfo;
    }

    /***
     * @description: Logic to track SelectedItem within
     */
    static updateSelectionInfoToColumnPage(columnPageDataMapper, storingKey, matchedIndex, CRUDOperation) {
        const rootProperty = ColumnPageMapperProps.SELECTION_INFO;
        if (AppUtil.isEmpty(columnPageDataMapper[rootProperty])) {
            columnPageDataMapper[rootProperty] = {};
        }
        columnPageDataMapper[rootProperty][ColumnPageMapperProps.ITEM] = this.getSelectedCardOfColumn(CRUDOperation, columnPageDataMapper[storingKey], matchedIndex);
        columnPageDataMapper[rootProperty][ColumnPageMapperProps.CRUD_OPERATION] = CRUDOperation;
    };

    static getSelectedCardOfColumn(crudOperationType, list, matchedIndex) {
        let item = null;
        if (AppUtil.isAvailable(list) && (list.length > 0)) {
            const listCopy = cloneDeep(list);
            switch (crudOperationType) {
                case CRUDOperation.CREATE:
                case CRUDOperation.UPDATE:
                    item = listCopy[matchedIndex];
                    break;
                case CRUDOperation.DELETE: {
                    if (matchedIndex === (list.length - 1)) {//if last item
                        item = listCopy[matchedIndex - 1];
                    } else {
                        item = listCopy[matchedIndex];
                    }
                    break;
                }
                case CRUDOperation.DELETE_WITH_NO_SELECTION: {
                    item = {};
                    break;
                }
                default://GET or other new operations
                    item = listCopy[matchedIndex];
                    break;
            }
        } else {//if list is empty
            item = {};
        }
        return item;
    }

    static isEmptySelectedCard = (card) => {
        return card === undefined
            || card === null
            || card === "undefined"
            || card === "null"
            || card.id === "undefined"
            || card.id === "null"
            || card.id === undefined
            || card.id === null;
    };

    static getMatchedColumn = (columns, card) => {
        const matchedColumns = columns.filter((column) => {
            return (column.stage.id === card.stage.id)
        });
        return (matchedColumns.length > 0) ? matchedColumns[0] : null;
    };

    static isCaseColumn = (identifier) => {
        return (identifier === ItemsProperty.cases);
    };

    static isCustomerProfileColumn = (identifier) => {
        return (identifier === ItemsProperty.profiles);
    };
}

export class ColumnPageMapperUtil {

    static selectedItem(column) {
        return this.selectionProperty(ColumnPageMapperProps.ITEM, column);
    }

    static crudOperation(columnPage) {
        const value = this.selectionProperty(ColumnPageMapperProps.CRUD_OPERATION, columnPage);
        return AppUtil.isAvailable(value) ? value : "";
    }

    static selectionProperty(property, columnPage) {
        const selectedDetails = columnPage[ColumnPageMapperProps.SELECTION_INFO];
        return AppUtil.isAvailable(selectedDetails) ? selectedDetails[property] : null;
    }

    static isCrudOperation(crudOperationToMatch, columnPage) {
        const crudOperation = this.crudOperation(columnPage);
        return (AppUtil.isAvailable(crudOperation) && (crudOperation === crudOperationToMatch));
    }
}

class URLQuery {
    static getFilterParameters(filterData, columnFilter, columnSort) {
        let filterResult = "";
        let sortResult = "";
        for (let l = 0; l < filterData.length; l++) {
            let keys = Object.keys(filterData[l]);
            for (let m = 0; m < columnFilter.length; m++) {
                if (parseInt(keys[0], 10) === parseInt(columnFilter[m].filterId, 10)) {
                    let urlKey = columnFilter[m].key;
                    if (filterData[l][keys[0]].length > 0) {
                        for (let s = 0; s < filterData[l][keys[0]].length; s++) {
                            if (filterData[l][keys[0]][s].valueId !== undefined) {
                                filterResult += "&" + urlKey + "=" + filterData[l][keys[0]][s].value;
                            } else if (filterData[l][keys[0]][s].id !== undefined) {
                                filterResult += "&" + urlKey + "=" + filterData[l][keys[0]][s].id;
                            } else {
                                filterResult += "&" + urlKey + "=" + filterData[l][keys[0]];
                                break;
                            }
                        }
                    }
                } else {
                    if (filterData[l][keys[0]] !== undefined && filterData[l][keys[0]].value !== undefined) {
                        let urlKey = columnSort.key;
                        sortResult = "&" + urlKey + "=" + filterData[l][keys[0]].value;
                    }

                }
            }
        }
        return {filterResult, sortResult};
    }
}

export class ColumnRuleMatrix {

    static getOnDrag(pathToRoute, orders, sourceColumnId, destColumnId) {
        return ColumnRuleMatrix.get(pathToRoute, true, orders, sourceColumnId, destColumnId);
    };

    static getOnInitialLoad(pathToRoute) {
        return ColumnRuleMatrix.get(pathToRoute, false, null, null, null);
    };

    static get(pathToRoute, isOnDrag, orders, sourceColumnId, destColumnId) {
        let configurationData = ColumnUtil.getDefaultColumnConfig(pathToRoute);
        /***
         * Mapping
         * For key [Source stage id] = [All Destination stage Id's can be moved]
         */
        let configRuleMatrix = {};
        configurationData.forEach(column => {
            const dropConfig = column.draganddrop.drop;
            configRuleMatrix[dropConfig.action.value] = [...dropConfig.objectValues];
        });

        if (isOnDrag && (pathToRoute === AppUtil.linkPaths.convert.pathToRoute || pathToRoute === AppUtil.linkPaths.organise.pathToRoute)) {
            configRuleMatrix = ColumnRuleMatrix.getOnValidatingSourceItemOrders(orders, sourceColumnId, configRuleMatrix);
        }
        // console.log("columns configuration = ", configurationData, configRuleMatrix);
        return configRuleMatrix;
    };

    static getOnValidatingSourceItemOrders(orders, sourceStageId, ruleMatrix) {
        this.invoiceOrArchiveOrderSourceItem(ruleMatrix, sourceStageId, orders);
        this.noOrderSourceItem(ruleMatrix, sourceStageId, orders);
        return ruleMatrix;
    }

    static noOrderSourceItem(ruleMatrix, sourceStageId, orders) {
        if (orders !== undefined && (orders === null || orders.length === 0)) {

            /***
             * @description : If no order, of sourceItem, then remove, sourceItem.id from all ruleMatrix[stageId]
             */
            let valueAsIncomingStages = Object.keys(ruleMatrix).map(function (key) {
                return ruleMatrix[key];
            });
            let Keys = Object.keys(ruleMatrix);
            valueAsIncomingStages.forEach((value) => {
                const index = value.indexOf(sourceStageId);
                if (index !== -1) value.splice(index, 1);
            });

            /***
             * Except for "not a case", sourceItem.id is removed fromm all ruleMatrix[stageId]
             */
            Keys.forEach((key, i) => {
                //console.log("KeyOrderZero",key,valueAsIncomingStages[i]);
                if (parseInt(key, 10) === CaseUtil.caseStages.notACase.value) {
                    valueAsIncomingStages[i].push(CaseUtil.caseStages.newStage.value);
                }
                ruleMatrix[parseInt(key, 10)] = valueAsIncomingStages[i];
            });
        }
    }

    static invoiceOrArchiveOrderSourceItem(ruleMatrix, sourceStageId, orders) {
        let count = 0;
        if (orders !== undefined && orders !== null && orders.length > 0) {
            orders.forEach((order) => {
                if (order.stage === OrderUtil.orderStages.invoice.value || order.stage === OrderUtil.orderStages.archived.value) {
                    count++;
                }
            })
        }
        /***
         * when Case stageId = preplanning and Order stageId = (invoice|archive), update ruleMatrix[sourceId] = can move to default stages except 'sourceId'
         */
        if (count > 0) {
            let valueAsIncomingStages = ruleMatrix[CaseUtil.caseStages.prePlanning.value];
            if (valueAsIncomingStages !== undefined) {
                const index = valueAsIncomingStages.indexOf(sourceStageId);
                if (index !== -1) valueAsIncomingStages.splice(index, 1);
                ruleMatrix[CaseUtil.caseStages.prePlanning.value] = valueAsIncomingStages;
            }
        }
    }
}

/***
 * @description: All Styling
 */

export function ColumnStyle(hasDropZone, loaderStatus) {
    return `column is-stale ${hasDropZone ? 'has-dropzone' : 'column--single'} ${loaderStatus}`;
}

export const ItemsProperty = {
    cases: "cases",
    calls: "calls",
    profiles: "profiles",
};

/***
 * @description: "Global column loading" not empty and every "column loading" == false
 */
export function hasLoadedAllColumn(globalColumnLoadingTracker) {
    return (Object.keys(globalColumnLoadingTracker).length > 0) && Object.keys(globalColumnLoadingTracker).every((key) => {
        return globalColumnLoadingTracker[key] === false
    });
}
