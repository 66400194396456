import useConnectExistingProbateCase from "../../hooks/useConnectExistingProbateCase";
import {ProbateProps} from "../../../tabProbateMeeting/util";
import React from "react";
import {AppAsyncSelect} from "../../../../../../common/utils/useCommonElement";
import {Lang} from "../../../../../../common/appUtil";

const ProbateExistingCasesPopupContent = ({dispatch}) => {
    const {
        getExistingProbateCasesWithDebounce,
        isLoadingExistingProbateCases,
        onSelectChange
    } = useConnectExistingProbateCase(dispatch);

    return <AppAsyncSelect
        id={ProbateProps.probateCases}
        title={Lang().cases.probates.connectAnExistingProbateCase.popupInfo.probateCases}
        loadOptions={getExistingProbateCasesWithDebounce}
        onChange={(option) => onSelectChange(option)}
        isClearable={true}
        isLoading={isLoadingExistingProbateCases}
        optionLabelProperty={ProbateProps.customName}
    />
}
export default ProbateExistingCasesPopupContent;
