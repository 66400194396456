import {combineReducers} from "redux";
import application from "./applicationReducers";
import {reducer as reduxFormReducer} from "redux-form";
import {routerReducer} from "react-router-redux";
import thirdPartySettingsReducer from "../components/thirdPartySettings/thirdPartySettingsReducer";
import validationRulesReducer from "../components/validationRules/validationRulesReducer";
import lockCaseReducer from "../components/case/lockCase/redux/lockCaseReducer";
import orderEventAttributeSubtypeReducer from "../components/orderEventAttributeSubtype/orderEventAttributeReducer";
import orderDeliveryMethodsReducer from "../components/orderDeliveryMethods/orderDeliveryMethodsReducer";
import memorialReducer from "../components/case/memorialPage/redux/memorialReducer";
import columnReducer from "../components/common/masterDetailView/column/redux/columnReducer";
import {messageReducer} from "../components/case/message/redux/messageReducer";
import {probateReducer} from "../components/case/probates/redux/probateReducer";
import {dashboardReducer} from "../components/dashboard/redux/dashboardReducer";
import {profileReducer} from "../components/customers/customerProfile/redux/profileReducer";
import {customerPackageReducer} from "../components/customers/customerPackage/redux/customerPackageReducer";

const rootReducer = combineReducers({
    router: routerReducer,
    application,
    thirdPartySettings: thirdPartySettingsReducer,
    validationRules: validationRulesReducer,
    lockCaseR: lockCaseReducer,
    orderEventAttributeSubtype: orderEventAttributeSubtypeReducer,
    orderDeliveryMethods: orderDeliveryMethodsReducer,
    memorialReducer,
    columnReducer,
    messageReducer,
    probateReducer,
    dashboardReducer,
    profileReducer,
    customerPackageReducer,
    form: reduxFormReducer
});

export default rootReducer;
