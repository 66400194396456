import React from "react";
import Button from "../../../../common/button";
import Utility from "../../../../../api/utilLanguage";
import {OrderTitlePlaceholder} from "../placeholder";

function refreshMessage({refreshMessageList, selectedOrderForMessageFilter, isOrderListLoading}) {

    return <div className="inner message--refresh-list section__toolbar--filters">
        <div className="section__row">
            <div className="currentOrderInfo">
                {
                    isOrderListLoading === false
                        ? selectedOrderForMessageFilter && <h5>{selectedOrderForMessageFilter.orderTypeText} - {selectedOrderForMessageFilter.orderId} </h5>
                        : <OrderTitlePlaceholder/>
                }
            </div>
            <div className="refreshButton">
                <Button
                    id="callFieldLocationSearch"
                    onClick={refreshMessageList}
                    icon="reload"
                    tooltip={Utility.getLang().cases.messages.refreshMessageList}
                    noLabel
                />
            </div>

        </div>
    </div>
}

export default (refreshMessage);