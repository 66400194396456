import Utility from "../../api/utilLanguage";
import CaseUtil from "./caseUtil";
import OrderUtil from "./orderUtil";
import AppUtil, {ArrayUtil} from "./appUtil";
import RelativeUtil from "../case/relative/relativeUtil";
import React from "react";
import ValidationAndVisibilityRule, {validationRule} from "./ValidationAndVisibilityRule";
import * as localStorageUtil from "./localStorageUtil"
import Enum from "./enum";
import {ProductTypes} from "./productUtil";

class ValidationUtil {
    //eslint-disable-next-line
    static emailRegex = /^\w+([.-]+\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
    //eslint-disable-next-line
    static phoneNumberRegex = /^([0|+[0-9]{1,9})?([\w.\-])?([1-9][0-9]{3,9})$/;
    //eslint-disable-next-line
    static nonNegativeNumber = /^[0-9\b]+$/;
    static postalCodeRegex = /\d{3}\s?\d{2}/;
    static personNumberCodeRegex = /^(18|19|20|21)[0-9]{6}[-][0-9]{4}$/;
    static percentageRegex = /(^100([.]0{1,2})?)$|(^\d{1,2}([.]\d{1,2})?)$/i;
    static passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/;
    static onlyNumbers = /^\d+$/;

    // Validate postal code only if tenant and country are the same.
    static isRequiredPostalCodeValidation = (countryCode) => {
        const tenantId = localStorageUtil.getTenantId();
        return (
            (countryCode === "SE" && tenantId === localStorageUtil.SE_TENANT) ||
            (countryCode === "GB" && tenantId === localStorageUtil.UK_TENANT)
        );
    };

    static validateRelatives(relatives) {
        let validationMessages = [];
        const tenantId = localStorageUtil.getTenantId();

        if (AppUtil.isAvailable(relatives)) {
            for (const relative of relatives) {
                //Handled empty contact by skip loop if any
                if (RelativeUtil.isEmptyRelative(relative)) {
                    continue;
                }
                if (relative.firstName === null || relative.firstName === undefined || relative.firstName === "") {
                    validationMessages.push(Utility.getLang().warningMessages.case.addName);
                }
                if (relative.lastName === null || relative.lastName === undefined || relative.lastName === "") {
                    validationMessages.push(Utility.getLang().warningMessages.case.addName);
                }
                if (ValidationUtil.isInvalidEmail(relative.email)) {
                    validationMessages.push(Utility.getLang().warningMessages.case.addCorrectEmail);
                }
                if (ValidationUtil.isInvalidPhone(relative.phoneNumber)) {
                    validationMessages.push(Utility.getLang().warningMessages.case.addCorrectPhone);
                }
                if (ValidationUtil.hasPreOrPostSpace(relative.firstName)) {
                    validationMessages.push(Utility.getLang().warningMessages.case.addNameWithNoSpaces);
                }
                if (ValidationUtil.hasPreOrPostSpace(relative.personNumber)) {
                    validationMessages.push(Utility.getLang().warningMessages.case.addPersonNumberWithNoSpaces);
                }
                if (AppUtil.isAvailable(relative.personNumber) && !ValidationUtil.isValidPersonNumber(relative.personNumber)) {
                    validationMessages.push(Utility.getLang().warningMessages.case.personNumberError);
                }
                if (AppUtil.isAvailable(relative.address)) {
                    if (ValidationUtil.hasPreOrPostSpace(relative.address.address) || ValidationUtil.hasPreOrPostSpace(relative.address.address2)) {
                        validationMessages.push(Utility.getLang().warningMessages.case.addAddressWithNoSpaces);
                    }
                    if (ValidationUtil.hasPreOrPostSpace(relative.address.city)) {
                        validationMessages.push(Utility.getLang().warningMessages.case.addCityWithNoSpaces);
                    }
                    if (ValidationUtil.hasPreOrPostSpace(relative.address.postalCode)) {
                        validationMessages.push(Utility.getLang().warningMessages.case.addPostalCodeWithNoSpacesAndNumbersOnly);
                    }
                    if (ValidationUtil.isRequiredPostalCodeValidation(relative.address.country.countryCode)) {
                        if (AppUtil.isAvailable(relative.address.postalCode) && (ValidationUtil.checkPostalCodeWithGivenRegex(relative.address.postalCode, new RegExp(validationRule(ValidationAndVisibilityRule.common, ValidationAndVisibilityRule.postCode).regex)))) {
                            validationMessages.push(
                                tenantId === localStorageUtil.UK_TENANT ?
                                    Utility.getLang().warningMessages.case.ukPostalCodeError :
                                    Utility.getLang().warningMessages.case.postalCodeError);
                        }
                    }
                    if (AppUtil.isAvailable(relative.address) && (!ValidationUtil.isValidRelativeAddressLength(relative.address))) {
                        validationMessages.push(Utility.getLang().warningMessages.case.addressLengthRestriction);
                    }
                }
            }
        }
        return validationMessages;
    }

    static validateDeceasedPerson(deceased) {
        let validationMessages = [];
        const tenantId = localStorageUtil.getTenantId();

        if (deceased !== null && deceased !== undefined) {

            if (AppUtil.isAvailable(deceased.firstName) && ValidationUtil.checkSpaceInFirstAndLastInString(deceased.firstName)) {
                validationMessages.push(Utility.getLang().warningMessages.case.addNameWithNoSpaces);
            }
            if (AppUtil.isAvailable(deceased.lastName) && ValidationUtil.checkSpaceInFirstAndLastInString(deceased.lastName)) {
                validationMessages.push(Utility.getLang().warningMessages.case.addNameWithNoSpaces);
            }
            if (AppUtil.isAvailable(deceased.personNumber) && ValidationUtil.checkSpaceInFirstAndLastInString(deceased.personNumber)) {
                validationMessages.push(Utility.getLang().warningMessages.case.addPersonNumberWithNoSpaces);
            }
            if (AppUtil.isAvailable(deceased.personNumber) && !ValidationUtil.isValidPersonNumber(deceased.personNumber)) {
                validationMessages.push(Utility.getLang().warningMessages.case.personNumberError);
            }
            if (AppUtil.isAvailable(deceased.address.address) && ValidationUtil.checkSpaceInFirstAndLastInString(deceased.address.address)) {
                validationMessages.push(Utility.getLang().warningMessages.case.addAddressWithNoSpaces);
            }
            if (AppUtil.isAvailable(deceased.address.city) && ValidationUtil.checkSpaceInFirstAndLastInString(deceased.address.city)) {
                validationMessages.push(Utility.getLang().warningMessages.case.addCityWithNoSpaces);
            }
            if (AppUtil.isAvailable(deceased.address.postalCode) && (ValidationUtil.checkSpaceInFirstAndLastInString(deceased.address.postalCode))) {
                validationMessages.push(Utility.getLang().warningMessages.case.addPostalCodeWithNoSpacesAndNumbersOnly);
            }
            if (ValidationUtil.isRequiredPostalCodeValidation(deceased.address.country.countryCode)) {
                if (AppUtil.isAvailable(deceased.address.postalCode) && (ValidationUtil.checkPostalCodeWithGivenRegex(deceased.address.postalCode, new RegExp(validationRule(ValidationAndVisibilityRule.common, ValidationAndVisibilityRule.postCode).regex)))) {
                    validationMessages.push(tenantId === localStorageUtil.UK_TENANT ?
                        Utility.getLang().warningMessages.case.ukPostalCodeError :
                        Utility.getLang().warningMessages.case.postalCodeError);
                }
            }
        }
        return validationMessages;
    }

    static validateGuests(guests) {
        let validationMessages = [];
        if (AppUtil.isAvailable(guests)) {
            guests.forEach(function (guest) {
                if (AppUtil.isEmpty(guest.firstName)) {
                    validationMessages.push(Utility.getLang().warningMessages.case.addGuestName);
                }
                if (ValidationUtil.hasPreOrPostSpace(guest.firstName)) {
                    validationMessages.push(Utility.getLang().warningMessages.case.addNameWithNoSpaces);
                }
                if (AppUtil.isAvailable(guest.lastName) && ValidationUtil.checkSpaceInFirstAndLastInString(guest.lastName)) {
                    validationMessages.push(Utility.getLang().warningMessages.case.addNameWithNoSpaces);
                }
                if (ValidationUtil.isInvalidEmail(guest.email)) {
                    validationMessages.push(Utility.getLang().warningMessages.case.addCorrectEmailGuest);
                }
                if (ValidationUtil.isInvalidPhone(guest.phoneNumber)) {
                    validationMessages.push(Utility.getLang().warningMessages.case.addCorrectPhoneGuest);
                }
            });
        }
        return validationMessages;
    }

    static isInvalidGuests = (guests) => {
        return (ValidationUtil.validateGuests(guests).length > 0);
    };

    static validateFoodRestrictionGuests(guests) {
        var validationMessages = [];
        if (guests !== null && guests !== undefined) {
            guests.forEach(function (guest) {
                if (guest.foodRestrictions !== null || guest.foodRestrictions !== undefined || guest.foodRestrictions.length > 0) {
                    guest.foodRestrictions.forEach((foodrestriction) => {
                        if (foodrestriction.restrictions.length === 0) {
                            validationMessages.push(Utility.getLang().warningMessages.case.addFoodRestriction);
                        }
                    });
                }
            });
        }
        return validationMessages;
    }

    static validateContactPersonType(contactData) {
        var validationMessages = [];
        if (contactData !== null && contactData !== undefined) {
            if (contactData.personTypeId === null || contactData.personTypeId === undefined) {
                validationMessages.push(Utility.getLang().warningMessages.contact.selectPersonType);
            }
        }
        return validationMessages;
    }

    static validateImageSize(height, width) {
        var validationMessages = [];
        if (height < 96 || width < 96) {
            validationMessages.push(Utility.getLang().warningMessages.imageSizeWarning);
        }
        return validationMessages;
    }

    static validateUserEmail(email) {
        var validationMessages = [];
        if (!this.emailRegex.test(email)) {
            validationMessages.push(Utility.getLang().errorMessages.user.invalidEmailAddress);
        }
        return validationMessages;
    }

    static caseStageChangeValidationDetailsView(stages, orders) {
        let count = 0;
        let valueToChange = stages;
        if (orders !== undefined && orders !== null && orders.length > 0) {
            orders.forEach((order) => {
                if (order.stage === OrderUtil.orderStages.invoice.value || order.stage === OrderUtil.orderStages.archived.value) {
                    count++;
                }
            });
        }
        if (count > 0) {
            valueToChange.forEach((value) => {
                if (value.key === CaseUtil.caseStages.prePlanning.value) {
                    value.disabled = true;
                }
            });
        }

        if (orders !== null && orders !== undefined && orders.length === 0) {
            valueToChange.forEach((value) => {
                if (value.key === CaseUtil.caseStages.notACase.value) {
                    value.disabled = false;
                } else {
                    value.disabled = true;
                }
            });
        }
        return valueToChange;
    }

    static validateIfProductsExists(orders) {
        let count = 0;
        let validationMessages = [];
        if (orders !== undefined && orders !== null && orders.length > 0) {
            orders.forEach((order) => {
                if (order.events !== undefined) {
                    let noCategories = 0;
                    order.events.forEach((event, j) => {
                        if (event.categories !== undefined && event.categories.length > 0) {
                            let noProductInOrder = 0;
                            event.categories.forEach((category, k) => {
                                let noProducts = 0;
                                category.eventProducts.forEach((products, l) => {
                                    if (products.productId === 0) {
                                        noProducts++;
                                    }
                                });
                                if (noProducts === category.eventProducts.length) {
                                    noProductInOrder++;
                                }
                            });
                            if (noProductInOrder === event.categories.length) {
                                noCategories++;
                            }
                        } else {
                            noCategories++;
                        }
                    });
                    if (noCategories >= order.events.length) {
                        count++;
                    }
                }
            });
        }
        if (count > 0) {
            validationMessages.push(Utility.getLang().warningMessages.productMissingInOrder);
        }
        return validationMessages;
    }

    static validateProductQuantity(orders) {
        let count = 0;
        let priceCount = 0;
        let validationMessages = [];
        if (orders !== undefined && orders !== null && orders.length > 0) {
            console.log("ordersToCheck", orders);
            orders.forEach((order) => {
                if (order.events !== undefined) {
                    order.events.forEach((event, j) => {
                        if (event.categories !== undefined && event.categories.length > 0) {
                            event.categories.forEach((category, k) => {
                                category.eventProducts.forEach((products, l) => {
                                    if (products.productId !== 0 && (products.quantity === "" || products.quantity === null)) {
                                        count++;
                                    }
                                    if (products.productId !== 0 && (products.finalPrice === "" || products.finalPrice === null)) {
                                        priceCount++;
                                    }
                                });
                            });
                        }
                    });
                }
            });
        }

        if (count > 0) {
            validationMessages.push(Utility.getLang().warningMessages.quantityMissing);
        }
        if (priceCount > 0) {
            validationMessages.push(Utility.getLang().warningMessages.priceMissing);
        }
        return validationMessages;
    }

    static hasNoNameCustomProduct(data) {
        let hasNoNameCustomProduct = false;
        let validationMessages = [];

        if (data.orders !== undefined) {
            data.orders.forEach((orders) => {
                //delete orders.prices;
                if (orders.events !== undefined) {
                    orders.events.forEach((event) => {
                        //delete event.categoryData;
                        if (event.categories !== undefined && event.categories.length > 0) {
                            event.categories.forEach((category) => {
                                // delete category.catPrice;
                                //delete category.catTotal;
                                //delete category.data;
                                category.eventProducts.forEach((products, l) => {
                                    if (category.eventProducts[l].typeId === ProductTypes.customProduct && (category.eventProducts[l].customName === "" || category.eventProducts[l].customName === undefined || category.eventProducts[l].customName === null)) {
                                        hasNoNameCustomProduct = true;
                                    } else if (category.eventProducts[l].typeId === ProductTypes.customProduct && AppUtil.isAvailable(category.eventProducts[l].customName)) {
                                        let check = ValidationUtil.checkSpaceInFirstAndLastInString(category.eventProducts[l].customName);
                                        if (check) {
                                            hasNoNameCustomProduct = true;
                                        }
                                    }
                                });
                            });
                        }
                    });
                }
            });
        }

        if (hasNoNameCustomProduct) {
            validationMessages.push(Utility.getLang().warningMessages.case.addCustomName);
        }

        return validationMessages;
    }

    static checkSpaceInString(value) {
        return value.indexOf(" ") !== -1;
    }

    static checkSpaceInFirstAndLastInString(value) {
        let errorValue = false;
        if (value.indexOf(" ") === 0 || (value.lastIndexOf(" ") === (value.length - 1))) {
            errorValue = true;
        }
        return errorValue;
    }

    static checkOnlyNumbers(value) {
        return isNaN(value);
    }

    static checkPostalCode(value) {
        return value && ValidationUtil.checkPostalCodeWithGivenRegex(value, new RegExp(validationRule(ValidationAndVisibilityRule.common, ValidationAndVisibilityRule.postCode).regex));
    }

    static checkPostalCodeWithGivenRegex(value, regex) {
        return !regex.test(value);
    }

    static isValidPersonNumber(value) {
        return this.personNumberCodeRegex.test(value);
    }

    static checkValidPercentage(value) {
        return !this.percentageRegex.test(value);
    }

    static checkOnlyEmail(value) {
        return !this.emailRegex.test(value);
    }

    static checkOnlyPhoneNumber(value) {
        return !this.phoneNumberRegex.test(value);
    }

    static checkPhoneNumberWithGivenRegex(value, regex) {
        if (regex === undefined) {
            return false;
        }
        return !regex.test(value);
    }

    static checkMobileNumberWithGivenRegex(value, regex) {
        if (regex === undefined) {
            return false;
        }
        return !regex.test(value);
    }

    static checkNumberOnly(value) {
        let charCode = (value.which) ? value.which : value.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    static checkNumberAndSpace(value) {
        let charCode = (value.which) ? value.which : value.keyCode;
        if (charCode > 31 && charCode !== 32 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    static checkNumberAndDash(value) {
        let charCode = (value.which) ? value.which : value.keyCode;
        if (charCode > 31 && charCode !== 45 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    static checkNumberPlusAndDash(value) {
        let charCode = (value.which) ? value.which : value.keyCode;
        if (charCode > 31 && charCode !== 43 && charCode !== 45 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    static checkNumberAndAlphabets(value) {
        let charCode = (value.which) ? value.which : value.keyCode;
        if (charCode > 31 && charCode !== 32 && (charCode < 48 || charCode > 57) && (65 <= charCode && charCode <= 90) && (97 <= charCode && charCode <= 122)) {
            return false;
        }
        return true;
    }

    static isValidPassword(value) {
        return this.passwordRegex.test(value);
    }

    /***
     * @description: check isValid relative address length <= 90
     * @returns Boolean value
     * @type {number}
     */
    static ADDRESS_CHARACTER_LENGTH_LIMIT = 90;
    static isValidRelativeAddressLength = (relativeAddress) => {
        let addressLength = 0;
        if (AppUtil.isAvailable(relativeAddress.address)) {
            addressLength += relativeAddress.address.length;
        }
        if (AppUtil.isAvailable(relativeAddress.address2)) {
            addressLength += relativeAddress.address2.length;
        }
        return (addressLength <= ValidationUtil.ADDRESS_CHARACTER_LENGTH_LIMIT);
    };

    static isInvalidName = (content) => {
        return (AppUtil.isEmpty(content) || ValidationUtil.hasPreOrPostSpace(content));
    };

    static isInvalidLastName = (content) => {
        return ValidationUtil.hasPreOrPostSpace(content);
    };

    static isInvalidEmail = (content) => {
        if (AppUtil.isAvailable(content)) {
            const spaceInError = ValidationUtil.checkSpaceInString(content);
            const emailError = ValidationUtil.checkOnlyEmail(content);
            const hasPreOrPostSpaceError = ValidationUtil.hasPreOrPostSpace(content);
            return (spaceInError || emailError || hasPreOrPostSpaceError);
        } else {
            return false;
        }
    };

    static isInvalidPhone = (content) => {
        if (AppUtil.isAvailable(content)) {
            const spaceInError = ValidationUtil.checkSpaceInString(content);
            const phoneNoError = ValidationUtil.checkOnlyPhoneNumber(content);
            const hasPreOrPostSpaceError = ValidationUtil.hasPreOrPostSpace(content);
            return (spaceInError || phoneNoError || hasPreOrPostSpaceError);
        } else {
            return false;
        }
    };

    static isInvalidPhoneWithGivenRegex = (content, regex) => {
        if (AppUtil.isAvailable(content)) {
            const spaceInError = ValidationUtil.checkSpaceInString(content);
            const phoneNoError = ValidationUtil.checkPhoneNumberWithGivenRegex(content, regex);
            const hasPreOrPostSpaceError = ValidationUtil.hasPreOrPostSpace(content);
            return (spaceInError || phoneNoError || hasPreOrPostSpaceError);
        } else {
            return false;
        }
    };

    static isInvalidMobileWithGivenRegex = (content, regex) => {
        if (AppUtil.isAvailable(content)) {
            //const spaceInError = ValidationUtil.checkSpaceInString(content);
            const phoneNoError = ValidationUtil.checkMobileNumberWithGivenRegex(content, regex);
            const hasPreOrPostSpaceError = ValidationUtil.hasPreOrPostSpace(content);
            return (phoneNoError || hasPreOrPostSpaceError);
        } else {
            return false;
        }
    };

    static hasPreOrPostSpace = (content) => {
        return (AppUtil.isAvailable(content) && ValidationUtil.checkSpaceInFirstAndLastInString(content));
    };

    /***
     * @description: Validation UI helper
     * @param message
     * @returns {*}
     */
    static inlineErrorMessage = (message) => {
        return <div className="message is-error">{message}</div>;
    };

    static getNameError = (content, fieldName) => {
        if (AppUtil.isEmpty(content)) {
            return ValidationUtil.inlineErrorMessage(Utility.getLang().warningMessages.case.requiredField.replace("{fieldName}", fieldName));
        }
        if (AppUtil.isAvailable(content)) {
            return ValidationUtil.inlineErrorMessage(Utility.getLang().warningMessages.case.noSpaceError);
        } else {
            return "";
        }
    };

    static requiredFieldError = (content, fieldName) => {
        return AppUtil.isEmpty(content) ? ValidationUtil.inlineErrorMessage(Utility.getLang().warningMessages.case.requiredField.replace('{fieldName}', fieldName)) : "";
    };

    static getLastNameError = (content) => {
        if (AppUtil.isAvailable(content)) {
            return ValidationUtil.inlineErrorMessage(Utility.getLang().warningMessages.case.noSpaceError);
        } else {
            return "";
        }
    };

    static getEmailError = () => {
        return ValidationUtil.inlineErrorMessage(Utility.getLang().warningMessages.case.emailError);
    };

    static getPhoneNumberError = () => {
        return ValidationUtil.inlineErrorMessage(Utility.getLang().warningMessages.case.onlyNumberAndNoSpaceError);
    };

    static getValidPercent = () => {
        return ValidationUtil.inlineErrorMessage(Utility.getLang().warningMessages.invalidPercentage);
    };

    static getMobileNumberError = () => {
        return ValidationUtil.inlineErrorMessage(Utility.getLang().warningMessages.case.mobileNumberError);
    };

    static allowNumberPlusAndDash = (event) => {
        const checked = ValidationUtil.checkNumberPlusAndDash(event);
        if (!checked) {
            event.preventDefault();
        }
    };

    static allowNumberAndAlphabets = (event) => {
        const checked = ValidationUtil.checkNumberAndAlphabets(event);
        if (!checked) {
            event.preventDefault();
        }
    };

    static isInputEmpty = (content) => {
        return AppUtil.isEmpty(content) ? 'is-error' : '';
    };

    static isSelectEmpty = (content) => {
        return content === 0 || content === null ? 'is-error' : '';
    }

    static isAllInputsFilled = (assetDebts) => {
        let isFilled = true;
        AppUtil.isAvailable(assetDebts) && assetDebts.map(inputValues => {
            if ((AppUtil.isEmpty(inputValues.name)
                || AppUtil.isEmpty(inputValues.propertySubTypeId)
                || AppUtil.isEmpty(inputValues.propertyOwner)
                || AppUtil.isEmpty(inputValues.value))
            ) {
                isFilled = false;
            }
        });
        return isFilled;
    }

    static isAssetsAndDebtsFilled = (keyValues) => {
        let status = false;
        if (ValidationUtil.isAllInputsFilled(keyValues.assets)
            && ValidationUtil.isAllInputsFilled(keyValues.debts)
            && ValidationUtil.isAllInputsFilled(keyValues.deductions)) {
            status = true;
        }
        return status;
    }
}

export default ValidationUtil;


export class ValidationErrors {
    static infoForKey(componentId, data, errorInfo) {
        switch (componentId) {
            case Enum.CaseRelativesComponent: {
                const invalidMessages = ValidationUtil.validateRelatives(data.relatives);
                if (AppUtil.isAvailable(invalidMessages) && (invalidMessages.length > 0)) {
                    errorInfo[componentId] = invalidMessages;
                }
                return errorInfo;
            }
            case Enum.CaseDeceasedComponent: {
                const invalidMessages = ValidationUtil.validateDeceasedPerson(data.deceased);
                if (AppUtil.isAvailable(invalidMessages) && (invalidMessages.length > 0)) {
                    errorInfo[componentId] = invalidMessages;
                }
                return errorInfo;
            }
            case Enum.CaseGuestsComponent: {
                let invalidMessages = ValidationUtil.validateGuests(data.guests);
                if (AppUtil.isAvailable(invalidMessages) && (invalidMessages.length > 0)) {
                    errorInfo[componentId] = invalidMessages;
                }

                invalidMessages = ValidationUtil.validateFoodRestrictionGuests(data.guests);
                if (AppUtil.isAvailable(invalidMessages) && (invalidMessages.length > 0)) {
                    errorInfo[componentId] = ArrayUtil.addArray(errorInfo[componentId], invalidMessages);
                }
                return errorInfo;
            }
            case Enum.CaseOrderTenantBasedComponent: {
                let invalidMessages = ValidationUtil.hasNoNameCustomProduct(data);
                if (AppUtil.isAvailable(invalidMessages) && (invalidMessages.length > 0)) {
                    errorInfo[componentId] = invalidMessages;
                }

                invalidMessages = ValidationUtil.validateIfProductsExists(data.orders);
                if (AppUtil.isAvailable(invalidMessages) && (invalidMessages.length > 0)) {
                    errorInfo[componentId] = ArrayUtil.addArray(errorInfo[componentId], invalidMessages);
                }

                invalidMessages = ValidationUtil.validateProductQuantity(data.orders);
                if (AppUtil.isAvailable(invalidMessages) && (invalidMessages.length > 0)) {
                    errorInfo[componentId] = ArrayUtil.addArray(errorInfo[componentId], invalidMessages);
                }
                return errorInfo;
            }
            default:
                console.log("%c componentId = %o , not handled", 'color: orange;font-size:12px;', componentId);
                break;

        }
    }

    static info(data) {
        let errorInfo = {};
        errorInfo = this.infoForKey(Enum.CaseRelativesComponent, data, errorInfo);
        errorInfo = this.infoForKey(Enum.CaseDeceasedComponent, data, errorInfo);
        errorInfo = this.infoForKey(Enum.CaseGuestsComponent, data, errorInfo);
        errorInfo = this.infoForKey(Enum.CaseOrderTenantBasedComponent, data, errorInfo);
        //console.log("info :: errorInfo = %o, keys = %o, values = %o ", errorInfo, Object.keys(errorInfo), Object.values(errorInfo));

        return errorInfo;
    }

    static isAvailable(info) {
        return (Object.keys(info).length > 0);
    }

    static isEmpty(info) {
        return (Object.keys(info).length === 0);
    }

    static getComponents(info) {
        return Object.keys(info);
    }

    static getMessages(info) {
        return Object.values(info);
    }
}

export function allowNumberAndSpace(evt) {
    let checked = ValidationUtil.checkNumberAndSpace(evt);
    if (!checked) {
        evt.preventDefault();
    }
}

export function allowNumberAndDash(evt) {
    let checked = ValidationUtil.checkNumberAndDash(evt);
    if (!checked) {
        evt.preventDefault();
    }
}

export function checkValidation(data) {
    let cssToAdd = "";
    if (AppUtil.isAvailable(data)) {
        let errorInString = ValidationUtil.checkSpaceInFirstAndLastInString(data);
        if (errorInString) {
            cssToAdd = "is-error";
        }
    }
    return cssToAdd;
}

export function checkPostalCodeFormat(data) {
    let cssToAdd = "";
    if (AppUtil.isAvailable(data)) {
        let regex = new RegExp(validationRule(ValidationAndVisibilityRule.common, ValidationAndVisibilityRule.postCode).regex);
        let errorInString = !regex.test(data.toUpperCase());//ValidationUtil.checkPostalCode(data);
        if (errorInString) {
            cssToAdd = " is-error";
        }
    }
    return cssToAdd;
}

export function getPostalCodeFormatError() {
    // TODO: REMOVE HARDCODED TENANT ID
    if (localStorageUtil.getTenantId() === localStorageUtil.UK_TENANT) {
        return <div className="message is-error">{Utility.getLang().warningMessages.case.ukPostalCodeError}</div>;
    }
    return <div className="message is-error">{Utility.getLang().warningMessages.case.postalCodeError}</div>;
}

export function checkPersonNumberFormat(data) {
    let cssToAdd = "";
    if (data !== null) {
        /*  not working properly , need to check
         let regex = new RegExp(validationRule(ValidationAndVisibilityRule.common, ValidationAndVisibilityRule.personNumber).regex);
         let isValidExpression = !regex.test(data);
         console.log("PersonNumberTestFromDB", data, isValidExpression);
        */

        let isValidPersonNumber = ValidationUtil.isValidPersonNumber(data);
        if (!isValidPersonNumber) {
            cssToAdd = " is-error";
        }
    }
    return cssToAdd;
}

export function getPersonNumberFormatError() {
    return <div className="message is-error">{Utility.getLang().warningMessages.case.personNumberError}
    </div>;
}

export function getNoSpaceError() {
    return <div className="message is-error">{Utility.getLang().warningMessages.case.noSpaceError}
    </div>;
}

export function characterLimitExceededError() {
    return <div className="message is-error">{Utility.getLang().warningMessages.case.addressLengthRestriction}
    </div>;
}
