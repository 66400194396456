import React from "react";
import AppUtil, {Lang} from "../../../common/appUtil";
import {withRouter} from "react-router";
import Button from "../../../common/button";
import {DBListInfo} from "../../api/dashboardService";

const DbFooter = (props) => {
    const {
        history,
        id,
        pageLimit,
        onInitialClick,
        onPreviousClick,
        onNextClick,
        navigation,
        filter,
        showFullList
    } = props;

    const isDisabledNextBtn = navigation?.isLoading || !(navigation?.next);
    const isDisabledInitialBtn = (navigation?.pageStartIndex === 0);
    const isDisabledPrevBtn = navigation?.isLoading || isDisabledInitialBtn;

    const pageRangeText = (pageStartIndex, totalCount) => {
        const pageStartRange = (pageStartIndex + 1);
        const pageEnd = (pageStartIndex + pageLimit);
        const pageEndRange = pageEnd <= totalCount ? pageEnd : totalCount;
        return `${pageStartRange} - ${pageEndRange} ${Lang().cases.dashboard.of} ${totalCount}`;
    }

    const onClickFullList = (id) => {
        history.push(AppUtil.linkPaths.dashboard.pathToRoute + '/' + DBListInfo[id].url);
    };

    return <div className="btn-toolbar">
        <div className="column__head dashboard-buttons--head">
            <Button
                isTertiary
                onClick={() => onInitialClick(id, filter)}
                disabled={isDisabledInitialBtn}
                tooltip={!isDisabledInitialBtn ? Lang().cases.dashboard.button.initial.replace("{COUNT}", pageLimit) : null}
                tooltipAlignLeft
            >
                {"|<"}
            </Button>
            <Button
                isTertiary
                onClick={() => onPreviousClick(id, navigation?.pageStartIndex, filter)}
                disabled={isDisabledPrevBtn}
                tooltip={!isDisabledPrevBtn ? Lang().cases.dashboard.button.previous.replace("{COUNT}", pageLimit) : null}
            >
                {"<"}
            </Button>
            <Button
                isTertiary
                onClick={() => onNextClick(id, navigation?.pageStartIndex, filter)}
                disabled={isDisabledNextBtn}
                tooltip={!isDisabledNextBtn ? Lang().cases.dashboard.button.next.replace("{COUNT}", pageLimit) : null}
                tooltipAlignRight
            >
                {">"}
            </Button>
            <div className="item__meta item__title">
                <span className="description">
                    {pageRangeText(navigation?.pageStartIndex, navigation?.count)}
                </span>
            </div>
        </div>
        {
            showFullList && <div className={"dashboard-buttons--align_right"}>
                <Button
                    icon={"arrow_right"}
                    isTertiary
                    onClick={() => onClickFullList(id)}
                    tooltip={Lang().cases.dashboard.button.fullList}
                    tooltipAlignRight
                />
            </div>
        }
    </div>
}
export default withRouter(DbFooter);