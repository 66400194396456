import ApiCollection from "../../../../api/apiCollections";
import {API} from "../../memorialPage/api/memorialApis";
import {getApiToken} from "../../../common/localStorageUtil";
import {cloneDeep} from "lodash";

/***
 * @description: Create probate by sourceCaseId and empty body
 * @param sourceCaseId
 * @returns {Promise<*>} response|error
 */
export const createProbateApi = async (sourceCaseId) => {
    try {
        const urlSuffix = ApiCollection.properties.createProbate.replace(
            "{sourceCaseId}",
            sourceCaseId
        );
        const response = await API.post(getApiToken(), urlSuffix, {});
        return await response.data;
    } catch (err) {
        //console.log(`Error: ${err}`);
        throw err;
    }
};
/***
 * @description: GET probate by probateId
 * @param probateId
 * @returns {Promise<*>} response|error
 */
export const getDefaultProbateDataApi = async (probateId) => {
    const urlSuffix = ApiCollection.properties.fetchProbateInfo.replace("{probateId}", probateId);
    try {
        const response = await API.get(getApiToken(), urlSuffix);
        return await response.data;
    } catch (err) {
        throw err;
    }
};

export const downloadProbateZipDataApi = async (probateId) => {
    const urlSuffix = ApiCollection.properties.downloadProbateZip.replace("{probateId}", probateId);
    try {
        const response = await API.get(getApiToken(), urlSuffix, {responseType: 'blob'});
        return await response;
    } catch (err) {
        throw err;
    }
};

/***
 * @description: PUT probate by probateId, changed probateInfo
 * @param probateId
 * @returns {Promise<*>} response|error
 */
export const updateProbateApi = async (probateId, body = {}) => {
    try {
        const urlSuffix = ApiCollection.properties.updateProbateInfo.replace("{probateId}", probateId);
        const response = await API.put(getApiToken(), urlSuffix, body);
        return await response.data;
    } catch (err) {
        throw err;
    }
};

export const getProbateEnums = async () => {
    try {
        const urlSuffix = ApiCollection.properties.fetchProbateEnums;
        const response = await API.get(getApiToken(), urlSuffix);
        return await response.data;
    } catch (err) {
        throw err;
    }
}

export const getDocumentsByType = async (caseId, documentTypeId) => {
    try {
        const urlSuffix = ApiCollection.properties.fetchDocumentsByType.replace("{caseId}", caseId)
            .replace("{documentTypeId}", documentTypeId);
        const response = await API.get(getApiToken(), urlSuffix);
        return await response.data;
    } catch (err) {
        throw err;
    }
}

export const uploadDocumentByType = async (formData, caseId, documentTypeId) => {
    try {
        const urlSuffix = ApiCollection.properties.uploadDocumentByType.replace("{caseId}", caseId)
            .replace("{documentTypeId}", documentTypeId)
            .replace("{isProbate}", true);
        const body = formData;
        const response = await API.post(getApiToken(), urlSuffix, body);
        return await response.data;
    } catch (err) {
        throw err;
    }
}

export const getFuneralOrderTotal = async (probateId) => {
    try {
        const urlSuffix = ApiCollection.properties.fetchFuneralOrderTotal.replace("{probateId}", probateId);
        const response = await API.get(getApiToken(), urlSuffix);
        return await response.data;
    } catch (err) {
        throw err;
    }
}

export const getDetailsByPersonalNumber = async (personalNumber) => {
    const urlSuffix = ApiCollection.properties.fetchFromSparPersonalNumber.replace("{pn}", personalNumber);
    try {
        const response = await API.get(getApiToken(), urlSuffix);
        return await response;
    } catch (err) {
        throw err;
    }
};

export const getCommonNotes = async () => {
    const urlSuffix = ApiCollection.properties.fetchProbateCommonNotes;
    try {
        const response = await API.get(getApiToken(), urlSuffix);
        return await response;
    } catch (err) {
        throw err;
    }
};

export const getExistingProbateCases = async (inputValue) => {
    const urlSuffix = ApiCollection.properties.fetchExistingProbateCase.replace("{INPUT}", inputValue);
    try {
        const response = await API.get(getApiToken(), urlSuffix);
        return await response;
    } catch (err) {
        throw err;
    }
};

export const connectExistingProbateCases = async (caseId, probateCaseId) => {
    const urlSuffix = ApiCollection.properties.connectExistingProbateCase.replace("{CASE_ID}", caseId)
        .replace("{PROBATE_CASE_ID}", probateCaseId);
    try {
        const response = await API.put(getApiToken(), urlSuffix, {});
        return await response;
    } catch (err) {
        throw err;
    }
};

export function ProbateRequest() {

    function getNotes(probateInfo, payload) {
        const tempNote = cloneDeep(probateInfo.tabTempNotes);
        const notes = cloneDeep(probateInfo.notes);
        if (tempNote && tempNote.note.length > 0) {
            notes.push(tempNote)
        }
        return notes;
    }

    const getUpdatePayload = (probateInfo, version) => {
        const payload = cloneDeep(probateInfo);
        payload.notes = getNotes(probateInfo, payload);
        //Remove tempNote
        delete payload.tabTempNotes;
        payload.version = version;

        payload.informations = payload.informations.filter(information => information.isChecked);

        removeLocalParameters(payload);
        return payload;
    };

    /**
     * @description: local params removed
     */
    const removeLocalParameters = (payload) => {
        updateMeetingInfo(payload.meetingInfo);
    };

    const updateMeetingInfo = (meetingInfo) => {
        if (meetingInfo && meetingInfo.dueDateInfo) {
            if (meetingInfo.dueDateInfo.editButton) {
                delete meetingInfo.dueDateInfo.editButton;
            }
            if (meetingInfo.dueDateInfo.resetButton) {
                delete meetingInfo.dueDateInfo.resetButton;
            }
        }
    };

    return {getUpdatePayload, removeLocalParameters};
}
