import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as dashboardAction from '../../actions/dashboardAction';
import * as actionMaster from '../../actions/uiAction';
import {Link} from 'react-router-dom';
import {withRouter} from "react-router";
import Utility from "../../api/utilLanguage";
import AppUtil from "./appUtil";

class SubHeaderForSetting extends React.Component {

    componentDidMount() {
        this.props.actionMaster.subHeaderItemClickedSetting(this.props.history.location.pathname);
    }

    onSubHeaderMenuItemClickInSetting = (btn) => {
        this.props.actionMaster.subHeaderItemClickedSetting(btn);
    };

    render() {
        return (
            <React.Fragment>
                <div className="section">
                    <div className="section__head">
                        <div className="inner">
                            <h2 className="section__title">{Utility.getLang().settings.settings}</h2>
                            <nav className="section__nav section__nav--settings">
                                <Link
                                    onClick={() => this.onSubHeaderMenuItemClickInSetting(AppUtil.linkPaths.settings.users)}
                                    to={AppUtil.linkPaths.settings.users}
                                    className={this.props.subheaderMenuInSetting === AppUtil.linkPaths.settings.users ? "section__nav__item section__nav__item--users is-active" : "section__nav__item section__nav__item--users"}>
                                    {Utility.getLang().settings.users.users}
                                </Link>
                                <Link
                                    onClick={() => this.onSubHeaderMenuItemClickInSetting(AppUtil.linkPaths.settings.productCategories)}
                                    to={AppUtil.linkPaths.settings.productCategories}
                                    className={this.props.subheaderMenuInSetting === AppUtil.linkPaths.settings.productCategories ? "section__nav__item section__nav__item--product_category is-active" : "section__nav__item section__nav__item--product_category"}>
                                    {Utility.getLang().settings.productCategory.productCategory}
                                </Link>
                                <Link
                                    onClick={() => this.onSubHeaderMenuItemClickInSetting(AppUtil.linkPaths.settings.token)}
                                    to={AppUtil.linkPaths.settings.token}
                                    className={this.props.subheaderMenuInSetting === AppUtil.linkPaths.settings.token ? "section__nav__item section__nav__item--api is-active" : "section__nav__item section__nav__item--api"}>
                                    {Utility.getLang().settings.token}
                                </Link>
                                <Link
                                    onClick={() => this.onSubHeaderMenuItemClickInSetting(AppUtil.linkPaths.settings.pathToRoute)}
                                    to={AppUtil.linkPaths.settings.pathToRoute}
                                    className={this.props.subheaderMenuInSetting === AppUtil.linkPaths.settings.pathToRoute ? "section__nav__item section__nav__item--organisation is-active coming_soon" : "section__nav__item  section__nav__item--organisation coming_soon"}>
                                    {Utility.getLang().settings.organisation.organisation}
                                </Link>

                                <Link
                                    onClick={() => this.onSubHeaderMenuItemClickInSetting(AppUtil.linkPaths.settings.export)}
                                    to={AppUtil.linkPaths.settings.export}
                                    className={this.props.subheaderMenuInSetting === AppUtil.linkPaths.settings.export ? "section__nav__item section__nav__item--export is-active coming_soon" : "section__nav__item section__nav__item--export coming_soon"}>
                                    {Utility.getLang().settings.export}

                                    {/*<Link onClick={() => this.onSubHeaderMenuItemClickInSetting('/export')} to="/export"
                                      className={this.props.subheaderMenuInSetting === '/export' ? "section__nav__item section__nav__item--export is-active coming_soon" : "section__nav__item section__nav__item--export coming_soon"}>
                                    {Utility.getLang().settings.export}*/}
                                </Link>
                            </nav>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        dashboardAction: bindActionCreators(dashboardAction, dispatch)
    };
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SubHeaderForSetting));
