import {allDashboardLists} from "../api";
import AppUtil from "../../common/appUtil";
import {useCallback, useEffect, useState} from "react";
import DashboardService, {DashboardListType} from "../api/dashboardService";
import {getTenantId, SE_TENANT, UK_TENANT} from "../../common/localStorageUtil";
import {updateDashboardCases, updateDashboardFilter, updateDashboardNavigation} from "../redux/dashboardAction";
import {DashboardProps} from "../ui/dbUtil";
import {getAllUserDetails} from "../../../actions/caseAction";
import useDashboardFilter from "./useDashboardFilter";

const useDashboard = (pageId, selectedDashboardType, pageStartIndex, pageLimit, users, selectedUserId, dispatch) => {
    const [isLoadingDashboardList, setIsLoadingDashboardList] = useState(false);
    const {ukDashboardList, svDashboardList} = DashboardService();

    /***
     * @description: API call helpers
     */
    const dashboardListApi = (urls, rootProperty, pageStartIndex) => {
        if (urls && urls.length > 0) {
            getAllDashboardLists(urls, rootProperty, pageStartIndex);
        }
    }

    const dashboardListApiForType = (dashboardType, rootProperty, _pageStartIndex, _pageLimit, optionalFilter) => {
        const urls = getUrlsForDashboardType(dashboardType, _pageStartIndex, _pageLimit, optionalFilter);
        dashboardListApi(urls, rootProperty, _pageStartIndex);
    }

    const onFilterChange = (optionalFilter) => {
        const urls = getUpdatedUrl(optionalFilter);
        dashboardListApi(urls, rootProperty, pageStartIndex);
        updateFilter(urls, optionalFilter);
    }

    const {
        ownerDropdownSelectedValue,
        ownerDropdownOptions,
        ashesInstructionOptions,
        ashesInstructionDropdownSelectedValue,
        onSelectionChange,
        debounceOnTextChange
    } = useDashboardFilter(selectedDashboardType, users, selectedUserId, dashboardListApiForType, onFilterChange);
    const rootProperty = (pageId === DashboardProps.dbMainPage) ? DashboardProps.dashboard : DashboardProps.fullDashboard;


    const getTenantBasedDashboardUrls = useCallback((pageStartIndex, pageLimit, optionalFilter = null) => {
        const currentTenant = getTenantId();
        switch (currentTenant) {
            case UK_TENANT:
                const list = ukDashboardList(pageStartIndex, pageLimit, optionalFilter);
                return Object.values(list);
            case SE_TENANT:
                return Object.values(svDashboardList(pageStartIndex, pageLimit, optionalFilter));
            default:
                console.log("[Debug]:: Dashboard Urls not handled, Unknown tenant = ", currentTenant)
                return [];
        }
    }, []);

    // console.log("[Debug]:: ukDashboardList = ", urls);
    const getUrlsForDashboardType = useCallback((dashboardType, pageStartIndex, pageLimit, optionalFilter = null) => {
        const result = getTenantBasedDashboardUrls(pageStartIndex, pageLimit, optionalFilter).filter(item => item.id === dashboardType);
        if (result.length === 0) {
            console.log("%c Failure:: URL not found for dashboardType = %s, typeof = %s", 'color: orange;font-size:12px;', dashboardType, typeof dashboardType);
        }
        return result;
    }, []);

    useEffect(() => {
        //Fetch User details api
        dispatch(getAllUserDetails());

        const urls = (pageId === DashboardProps.dbMainPage)
            ? getTenantBasedDashboardUrls(pageStartIndex, pageLimit)
            : getUrlsForDashboardType(selectedDashboardType, pageStartIndex, pageLimit);
        dashboardListApi(urls, rootProperty, pageStartIndex);
    }, []);

    /***
     * @description: "isLoading" for each dashboardType
     */
    function updateIsLoading(isLoading, urlsData, rootProperty) {
        setIsLoadingDashboardList(isLoading);
        urlsData.forEach((urlData) => {
            const keyValues = {[DashboardProps.isLoading]: isLoading};
            dispatch(updateDashboardNavigation(rootProperty, urlData.id, keyValues));
        })
    }

    const getAllDashboardLists = (urlsData, rootProperty, pageStartIndex) => {
        updateIsLoading(true, urlsData, rootProperty);
        allDashboardLists(urlsData).then(responses => {
            responses.forEach((resp, index) => {
                if (resp.status === "fulfilled") {
                    const respValue = resp.value;
                    if (respValue.status === 200 && AppUtil.isAvailable(respValue.data) && respValue.data.success) {
                        const dataObj = respValue.data.object;
                        const urlData = urlsData[index];
                        // console.log("URL ::  ", urlData.sourceUrl, "result = ", dataObj);
                        dispatch(updateDashboardCases(rootProperty, urlData.id, dataObj?.cases));
                        const keyValues = {
                            [DashboardProps.pageStartIndex]: pageStartIndex,
                            [DashboardProps.next]: dataObj.navigation?.next,
                            [DashboardProps.count]: dataObj.count
                        };
                        dispatch(updateDashboardNavigation(rootProperty, urlData.id, keyValues));
                    }
                }
            })
        }).finally(() => {
            updateIsLoading(false, urlsData, rootProperty);
        })
    };

    /**
     * @description: Button actions
     */
    const onNextClick = (_selectedDashboardType, _prevPageStartIndex, filter) => {
        const _pageStartIndex = _prevPageStartIndex + pageLimit;
        dashboardListApiForType(_selectedDashboardType, rootProperty, _pageStartIndex, pageLimit, filter);
    };

    const onPreviousClick = (_selectedDashboardType, _prevPageStartIndex, filter) => {
        const _pageStartIndex = _prevPageStartIndex - pageLimit;
        if (_pageStartIndex >= 0) {
            dashboardListApiForType(_selectedDashboardType, rootProperty, _pageStartIndex, pageLimit, filter);
        }
    };

    const onInitialClick = (_selectedDashboardType, filter) => {
        const _pageStartIndex = 0;
        dashboardListApiForType(_selectedDashboardType, rootProperty, _pageStartIndex, pageLimit, filter);
    }

    const updateFilter = (urls, keyValuesForFilter) => {
        urls.forEach((urlData) => {
            dispatch(updateDashboardFilter(rootProperty, urlData.id, keyValuesForFilter));
        });
    };

    const getUpdatedUrl = (optionalFilter) => {
        const urls = (pageId === DashboardProps.dbMainPage)
            ? getTenantBasedDashboardUrls(pageStartIndex, pageLimit, optionalFilter)
            : getUrlsForDashboardType(selectedDashboardType, pageStartIndex, pageLimit, optionalFilter);
        return urls;
    }
    /***
     * @description: Util
     */
    const isAshesReturnDashboardListType = ((selectedDashboardType === DashboardListType.attendedAshesReturn) || (selectedDashboardType === DashboardListType.unattendedAshesReturn));

    return {
        isLoadingDashboardList,
        onInitialClick,
        onPreviousClick,
        onNextClick,
        ownerDropdownSelectedValue,
        ownerDropdownOptions,
        onSelectionChange,
        debounceOnTextChange,
        ashesInstructionOptions,
        ashesInstructionDropdownSelectedValue,
        isAshesReturnDashboardListType
    };
};

export default useDashboard;
