import Utility from "../../../../../api/utilLanguage";
import Icon from "../../../../common/icon";
import React from "react";

export function Avatar({url, onChange}) {
    return <>
        <div className="current_avatar">
            <div className="current_avatar__outline">
                {url !== null
                    ? <img
                        src={url}
                        width="96"
                        height="96"
                        alt={Utility.getLang().common.avatar}
                    />
                    : <Icon type="avatar" huge/>
                }
            </div>
        </div>
        <label
            htmlFor="accountProfilePicture">{Utility.getLang().myAccount.profile.update_profile_picture}</label>
        <input
            id="accountProfilePicture"
            name="accountProfilePicture"
            className="text file"
            type="file"
            accept="image/png, image/jpeg"
            onChange={onChange}
        />
        <p className="help_text">
            {Utility.getLang().myAccount.profile.square_jpg_png}
        </p>
    </>;
}

export function CultureDropdown({onChange, culture, options}) {
    return <>
        <label>{Utility.getLang().settings.users.add_user_form.set_culture}</label>
        <select
            name="culture"
            placeholder={Utility.getLang().settings.users.add_user_form.set_culture}
            onChange={onChange}
            value={culture}
        >
            {
                options.map((culture, i) => {
                    return (
                        <option
                            key={i}
                            value={culture.name}
                        >
                            {culture.title}
                        </option>
                    );
                })
            }
        </select>
    </>;
}

export function BackArrow({onClick}) {
    return <a className="link link--nav"
              onClick={onClick}
    >
        <Icon type="arrow_left"/>
        <span>
            {Utility.getLang().settings.users.users}
        </span>
    </a>;
}