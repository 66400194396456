import React from 'react';
import Icon from "../../common/icon";
import Permalink from "../../common/link/permalink";
import AppUtil from "../../common/appUtil";
import OrderUtil from "../../common/orderUtil";
import Button from "../../common/button";
import Utility from "../../../api/utilLanguage";
import OrderFieldRules from "../caseTenantBasedOrder/OrderFieldRules";

export default function CaseOrderHeaderTitle(props) {
    return (
        <>
        <h3 className="section__title message--link">
            <Icon
                type={OrderUtil.getOrderIconBasedOnOrderType(props.orderType)}
                className={props.orderType !== OrderUtil.orderTypes.otherOrder.value ? ' is-color' : ''}
            />
            <span className="description">
                {props.headerText}
            </span>
            <Permalink
                id={props.orderTypeId}
                path1={AppUtil.linkPaths.order.basePath}
                path2={AppUtil.linkPaths.order.details}
            />
            { props.functionSource === OrderUtil.ORDER_DEFAULT_SOURCE 
                && OrderFieldRules.isFuneral(props.orderType) && props.orderTypeId!==OrderUtil.ORDER_INITIAL_VALUE && 
            <Button
                type="submit"
                isTertiary
                isSmall
                icon="message"
                tooltip={Utility.getLang().cases.messages.messageTooltip}
                onClick={()=>props.chooseMessageTab(props.orderTypeId)} />
            }
        </h3>
        
        </>
    );
}
