import React from "react";

class CustomTag extends React.Component {

    getLabel = (colorCode, title, index) => {
        return (
            <span
                key={index}
                className={`tag tag--label tag--${colorCode}`}
            >
                <span className="description">
                    {title}
                </span>
            </span>
        );
    };

    render() {
        return (
            <span className="tags tags--labels tags--small">
                {
                    this.props.labels.map((label, i) => {
                        return this.getLabel(label.colorCode, label.title, i)
                    } )
                }
            </span>
        );
    }
}

export default CustomTag;
