import React from "react";
import PropTypes from "prop-types";
import Icon from "../common/icon";
import {bindActionCreators} from "redux";
import * as actionMaster from "../../actions/uiAction";
import * as locationAction from "../../actions/locationAction";
import * as caseAction from "../../actions/caseAction";
import connect from "react-redux/es/connect/connect";
import Utility from "../../api/utilLanguage";
import FooterWithSave from "../common/footer";
import Popup from "../common/popups/popup";
import {reduxForm} from "redux-form";
import {withRouter} from "react-router-dom";
import * as contactAction from "../../actions/contactAction";
import Permalink from "../common/link/permalink";
import DisplayNothing from "../common/nothingToDisplay";
import MapDetailViewComponent from "../common/mapDetailView";
import ValidationUtil from "../common/validationUtil";
import {bottomCenter} from "../common/customToastr";
import PreLoader from "../common/preloader";
import AppUtil from "../common/appUtil";

class ContactMapComponent extends React.Component {

    constructor(props) {
        super(props);
        this.lat = '';
        this.lng = '';
        this.disableSave = false;
        this.state = {
            lat: '',
            lng: '',
            disableSave: false
        }
    }

    componentDidUpdate = () => {
        if (this.props.selectedDataById.id !== undefined && this.props.selectedContactData.id !== undefined) {
            if (JSON.stringify(this.props.selectedDataById) === JSON.stringify(this.props.selectedContactData)) {
                console.log("Same");
                this.props.actionMaster.hideUnsavedPopupLazily();
            } else {
                this.props.actionMaster.showUnsavedPopupLazily();
                console.log("Different")
            }
        }
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.contactUpdated !== undefined && (nextProps.contactUpdated !== this.props.contactUpdated)) {
            if (nextProps.contactUpdated === true) {
                this.loadingActionByValue(false);
            }
        }
    };

    loadingActionByValue = (value) => {
        this.disableSave = value;
        this.setState({disableSave: value});
    };

    onChangeCordinates = (e, id) => {
        this.props.contactAction.changeContactTextField(e.target.value, id);
    };

    onConfirmDelete = (d) => {
        this.toDeleteData = d;
        console.log("this.toDeleteData", this.toDeleteData);
        this.props.actionMaster.showPopup();
    };

    hidePopup = () => {
        this.props.actionMaster.hidePopupSection();
    };

    onDelete = () => {
        this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isDeleting);
        this.props.contactAction.deleteContactInfoApiCall(this.props.selectedContactData, this.props.storingKey).then().finally(() => {
            setTimeout(() => {
                this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
            }, 500);
        });
        this.hidePopup();
    };

    onMapClicked = (mapProps, map, clickEvent) => {
        mapProps.google.maps.event.addListener(map, 'click', function (event) {
            this.lat = event.latLng.lat();
            this.lng = event.latLng.lng();
            this.props.contactAction.changeContactTextField(this.lat, "latitude");
            this.props.contactAction.changeContactTextField(this.lng, "longitude");
        }.bind(this));
    };

    onMarkerClick = (props, marker, e) => {
        console.log("props", props);
        console.log("marker", marker);
        console.log("e", e);
    };

    saveContact = () => {
        this.loadingActionByValue(true);
        this.props.contactAction.updateContactUpdatingStatus(false);
        var validationMessages = ValidationUtil.validateContactPersonType(this.props.selectedContactData);
        if (validationMessages.length > 0) {
            validationMessages.forEach(function (validationMessage) {
                bottomCenter().warning(validationMessage);
            });
            this.loadingActionByValue(false);
            return
        }
        let newData = {
            "id": this.props.selectedContactData.id !== undefined ? this.props.selectedContactData.id : 0,
            "name": this.props.selectedContactData.name,
            "email": this.props.selectedContactData.email,
            "phoneNumber": this.props.selectedContactData.phoneNumber,
            "personType": this.props.selectedContactData.personTypeId,
            "isActive": this.props.selectedContactData.isActive,
            "adminPersonId": this.props.selectedContactData.adminPersonId,
            "address": this.props.selectedContactData.contactAddress.address,
            "postalAddress": this.props.selectedContactData.contactAddress.postalAddress,
            "longitude": this.props.selectedContactData.contactAddress.longitude !== "" ? parseFloat(this.props.selectedContactData.contactAddress.longitude) : 0,
            "latitude": this.props.selectedContactData.contactAddress.latitude !== "" ? parseFloat(this.props.selectedContactData.contactAddress.latitude) : 0,
            "placeId": 0,
            "comment": this.props.selectedContactData.comment,
            "mailingAddress": {
                "address": this.props.selectedContactData.mailingAddress.address !== undefined && this.props.selectedContactData.mailingAddress.address !== null ? this.props.selectedContactData.mailingAddress.address : null,
                "postalAddress": this.props.selectedContactData.mailingAddress.postalAddress !== undefined && this.props.selectedContactData.mailingAddress.address !== null ? this.props.selectedContactData.mailingAddress.postalAddress : null,
                "city": this.props.selectedContactData.mailingAddress.city !== undefined && this.props.selectedContactData.mailingAddress.address !== null ? this.props.selectedContactData.mailingAddress.city : null
            }
        };
        this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isUpdating);
        this.props.contactAction.updateContactInfoApiCall(newData, this.props.storingKey).then().finally(() => {
            setTimeout(() => {
                this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
            }, 500);
        });
    };

    toMoveNewRoute = () => {
        this.props.actionMaster.clearRouteCondition();
        if (this.props.headerMenuItemClicked !== this.props.history.location.pathname) {
            this.props.history.push(this.props.headerMenuItemClicked);
        }
    };

    currentRoute = () => {
        this.props.actionMaster.onHeaderMenuItemClicked(this.props.history.location.pathname);
        this.props.actionMaster.closePopupWithSameRoute();
    };

    selectLatitudeLongitude = (event) => event.target.select();

    render = () => {
        console.log("this.props.selectedLocationData.formDataToUpload", this.props.selectedContactData);

        return (
            <React.Fragment>
                <PreLoader onRef={ref => this.preLoader = ref} showPreload={false}
                           container={this.node} blockContainer={true} type="Oval"/>
                <Popup openModal={this.props.showUnsavedPopup}
                       headerInfo={Utility.getLang().infoMessages.popup.unsavedChanges}
                       onConfirmation={this.toMoveNewRoute}
                       closePopup={this.currentRoute}/>
                {
                    this.props.displayNothing === false ?
                        <div className="section section--detail section--scroll">
                            <div className="section__head">
                                <div className="inner">
                                    <h3 className="section__title">
                                        <Icon type="contact"/>
                                        <span className="description">
                                    {this.props.selectedContactData.name}
                                </span>
                                        <Permalink id={this.props.selectedContactData.id} path1={"/contacts/"}
                                                   path2={"/ContactMap"}/>
                                    </h3>
                                </div>
                            </div>
                            <div className="section__content section__content--location_map">
                                <div className="inner">
                                    <div className="section__columns">
                                        <div className="section__column">
                                            <div className="form-group">
                                                <label htmlFor="contactMapLatitude">
                                                    {Utility.getLang().locations.mapTabContent.latitude}
                                                </label>
                                                <input
                                                    id="contactMapLatitude"
                                                    name="latitude"
                                                    placeholder={Utility.getLang().locations.mapTabContent.latitude}
                                                    value={this.props.selectedContactData.contactAddress !== undefined && this.props.selectedContactData.contactAddress.latitude !== undefined ? this.props.selectedContactData.contactAddress.latitude : ""}
                                                    onChange={(e) => this.onChangeCordinates(e, "latitude")}
                                                    className="text text--max_width"
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                        <div className="section__column">
                                            <div className="form-group">
                                                <label htmlFor="contactMapLongitude">
                                                    {Utility.getLang().locations.mapTabContent.longitude}
                                                </label>
                                                <input
                                                    id="contactMapLongitude"
                                                    name="longitude"
                                                    placeholder={Utility.getLang().locations.mapTabContent.longitude}
                                                    value={this.props.selectedContactData.contactAddress !== undefined && this.props.selectedContactData.contactAddress.longitude !== undefined ? this.props.selectedContactData.contactAddress.longitude : ""}
                                                    onChange={(e) => this.onChangeCordinates(e, "longitude")}
                                                    className="text text--max_width"
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                        <div className="section__column">
                                            <div className="form-group">
                                                <label htmlFor="contactMapLatitudeAndLongitude">
                                                    {Utility.getLang().locations.mapTabContent.latitudeAndLongitude}
                                                </label>
                                                <input
                                                    id="contactMapLatitudeAndLongitude"
                                                    value={(this.props.selectedContactData.contactAddress !== undefined && this.props.selectedContactData.contactAddress.latitude !== undefined ? this.props.selectedContactData.contactAddress.latitude : "") + ", " + (this.props.selectedContactData.contactAddress !== undefined && this.props.selectedContactData.contactAddress.longitude !== undefined ? this.props.selectedContactData.contactAddress.longitude : "")}
                                                    className="text text--max_width"
                                                    type="text"
                                                    readOnly
                                                    onFocus={this.selectLatitudeLongitude}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.props.selectedContactData.contactAddress !== undefined && this.props.selectedContactData.contactAddress.longitude !== undefined && this.props.selectedContactData.contactAddress.latitude !== undefined && (this.props.selectedContactData.contactAddress.longitude !== 0 && this.props.selectedContactData.contactAddress.longitude !== "0") && (this.props.selectedContactData.contactAddress.latitude !== 0 && this.props.selectedContactData.contactAddress.latitude !== "0") ?
                                            <MapDetailViewComponent
                                                lat={this.props.selectedContactData.contactAddress !== undefined && this.props.selectedContactData.contactAddress.latitude !== undefined ? this.props.selectedContactData.contactAddress.latitude : ""}
                                                lng={this.props.selectedContactData.contactAddress !== undefined && this.props.selectedContactData.contactAddress.longitude !== undefined ? this.props.selectedContactData.contactAddress.longitude : ""}
                                                onMarkerClick={this.onMarkerClick}
                                                onMapClick={this.onMapClicked}
                                            />
                                            : null
                                    }

                                    {/*<div className="map map--full_width">*/}

                                    {/*<Map onClick={this.onMapClicked} mousemove={this.mouseMove}*/}
                                    {/*google={this.props.google}*/}
                                    {/*style={{width: '100%', height: '360px'}}*/}
                                    {/*//className={'map'}*/}
                                    {/*zoom={14} initialCenter={{*/}
                                    {/*lat: this.props.selectedContactData.contactAddress !== undefined && this.props.selectedContactData.contactAddress.latitude !== undefined ? this.props.selectedContactData.contactAddress.latitude : "",*/}
                                    {/*lng: this.props.selectedContactData.contactAddress !== undefined && this.props.selectedContactData.contactAddress.longitude !== undefined ? this.props.selectedContactData.contactAddress.longitude : ""*/}
                                    {/*}}*/}
                                    {/*center={{*/}
                                    {/*lat: this.props.selectedContactData.contactAddress !== undefined && this.props.selectedContactData.contactAddress.latitude !== undefined ? this.props.selectedContactData.contactAddress.latitude : "",*/}
                                    {/*lng: this.props.selectedContactData.contactAddress !== undefined && this.props.selectedContactData.contactAddress.longitude !== undefined ? this.props.selectedContactData.contactAddress.longitude : ""*/}
                                    {/*}}>*/}
                                    {/*<Marker onClick={this.onMarkerClick}*/}
                                    {/*title={'WTC'}*/}
                                    {/*name={'WTC'}*/}
                                    {/*position={{*/}
                                    {/*lat: this.props.selectedContactData.contactAddress !== undefined && this.props.selectedContactData.contactAddress.latitude !== undefined ? this.props.selectedContactData.contactAddress.latitude : "",*/}
                                    {/*lng: this.props.selectedContactData.contactAddress !== undefined && this.props.selectedContactData.contactAddress.longitude !== undefined ? this.props.selectedContactData.contactAddress.longitude : ""*/}
                                    {/*}}/>*/}
                                    {/*</Map>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div> : <DisplayNothing/>
                }
                <FooterWithSave SaveData={this.saveContact} hideFooter={this.props.displayNothing}
                                buttonText={Utility.getLang().contacts.footer.save}
                                deleteButtonText={Utility.getLang().contacts.footer.delete}
                                deleteCase={this.onConfirmDelete}
                                disableSaveButton={this.disableSave}
                                hideRelativeDropdown={true}
                />
                <Popup
                    openModal={this.props.open}
                    headerInfo={Utility.getLang().infoMessages.popup.deleteContact + ` ` + this.props.selectedDataById.name + `?`}
                    onConfirmation={this.onDelete}
                    closePopup={this.hidePopup}
                    isDestructive
                    withTimeOut
                />
            </React.Fragment>

        )
    }
}

ContactMapComponent.propTypes = {
    selectedCard: PropTypes.object,
    columnDetailViewTabCollection: PropTypes.array,
};

function mapStateToProps(state) {
    return {
        initialValues: state.application.selectedDataById,
        selectedContactData: state.application.selectedContactData,
        selectedDataById: state.application.selectedDataById,
        headerMenuItemClicked: state.application.headerMenuItemClicked,
        productWithVariantData: state.application.productWithVariantData,
        open: state.application.open,
        showUnsavedPopup: state.application.showUnsavedPopup,
        displayNothing: state.application.displayNothing,
        contactUpdated: state.application.contactUpdated,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        locationAction: bindActionCreators(locationAction, dispatch),
        contactAction: bindActionCreators(contactAction, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: "ContactMapComponent"
})(withRouter(ContactMapComponent)));
