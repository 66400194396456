import connect from "react-redux/es/connect/connect";
import {useEffect} from "react";
import {updateIsQABeneficiariesChanged} from "../../../redux/probateAction";
import AppUtil, {ArrayUtil} from "../../../../../common/appUtil";
import {ProbateProps} from "../../tabProbateMeeting/util";
import {omitFromArray} from "../../../../../common/objectUtils/lodashUtil";

const QuestionnaireBeneficiaryChangeListener = ({
                                                    beneficiaries,
                                                    initialBeneficiaries,
                                                    isQABeneficiariesChanged,
                                                    dispatch
                                                }) => {
    /***
     * @description: Mandatory all beneficiaries[].isValid = true
     */
    const isValidQABeneficiaries = (_beneficiaries) => {
        return !ArrayUtil.includesObject(false, ProbateProps.isValid, _beneficiaries);
    };
    /***
     * @description: skip locally changed '.isValid' in beneficiaries
     */
    useEffect(() => {
        const isValidQABeneficiariesStatus = isValidQABeneficiaries(beneficiaries);
        const filteredBeneficiaries = omitFromArray(beneficiaries, [ProbateProps.isValid]);
        const filteredInitialBeneficiaries = omitFromArray(initialBeneficiaries, [ProbateProps.isValid]);

        const isQABeneficiariesChangedStatus = (JSON.stringify(filteredInitialBeneficiaries) !== JSON.stringify(filteredBeneficiaries))
            && isValidQABeneficiariesStatus

        if (isQABeneficiariesChanged !== isQABeneficiariesChangedStatus) {
            dispatch(updateIsQABeneficiariesChanged(isQABeneficiariesChangedStatus));
            // debugIfUnsavedPopupShown(filteredBeneficiaries, initialBeneficiaries, "QuestionnaireBeneficiaryChangeListener");
        }
    }, [beneficiaries, initialBeneficiaries]);

    return null;
}

function mapStateToProps(state) {
    return {
        beneficiaries: AppUtil.isAvailable(state.probateReducer.probateInfo) && state.probateReducer.probateInfo?.beneficiaries ? state.probateReducer.probateInfo?.beneficiaries : [],
        initialBeneficiaries: AppUtil.isAvailable(state.probateReducer.initialProbateInfo) && state.probateReducer.initialProbateInfo?.beneficiaries ? state.probateReducer.initialProbateInfo?.beneficiaries : [],
        isQABeneficiariesChanged: state.probateReducer.isQABeneficiariesChanged,
    }
}

export default connect(mapStateToProps)(QuestionnaireBeneficiaryChangeListener);