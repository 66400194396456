import {useCallback, useState} from "react";
import {showDeleteQuestionnaireBeneficiaryPopup} from "../../../redux/probateAction";
import AppUtil, {Lang} from "../../../../../common/appUtil";

const useDeleteQuestionnaireBeneficiaryPopup = (dispatch) => {
    const [crudBeneficiaryTracker, setCrudBeneficiaryTracker] = useState({
        data: null,
        removeBeneficiary: null,
        commonBeneficiaryIdsActions: null
    });

    const deletePopupContent = (name) => {
        return Lang().cases.probates.deleteBeneficiaryPopup.content.replace('{name}', AppUtil.isAvailable(name) ? name : "this beneficiary")
    }

    const showDeleteQuestionnairePopup = useCallback((data, removeBeneficiary, commonBeneficiaryIdsActions) => {
        setCrudBeneficiaryTracker({
            data: data,
            removeBeneficiary: removeBeneficiary,
            commonBeneficiaryIdsActions: commonBeneficiaryIdsActions
        })
        //Enable popup flag
        dispatch(showDeleteQuestionnaireBeneficiaryPopup());
    }, []);

    const onDeleteQuestionnairePopupConfirmation = useCallback((dataToDelete) => {
        const {data, removeBeneficiary, commonBeneficiaryIdsActions} = dataToDelete;
        if (removeBeneficiary && commonBeneficiaryIdsActions) {
            removeBeneficiary(data.id, data.questionId, commonBeneficiaryIdsActions);
        }
    }, []);

    return {
        showDeleteQuestionnairePopup,
        onDeleteQuestionnairePopupConfirmation,
        crudBeneficiaryTracker,
        deletePopupContent
    }
}

export default useDeleteQuestionnaireBeneficiaryPopup;