import React from "react";
import AppUtil, {Lang} from "../../../common/appUtil";
import Button from "../../../common/button";
import PermalinkWithImage from "../../../common/link/permalinkWithImage"

export default function DocumentAccessView({linkableId, caseId, onClick, tooltip = Lang().common.document.document}) {
    return <>
        <div className="form-group form-group--columns">
            <div className="td--order">
                {
                    linkableId
                        ? <PermalinkWithImage
                            id={`${caseId}`}
                            path1={AppUtil.linkPaths.case.basePath}
                            path2={AppUtil.linkPaths.case.documentDetailsId.replace("{detailId}", linkableId)}
                            title={linkableId}
                            imgFileName="document-order"
                        />
                        : null
                }
            </div>
            {onClick && <div className={`form-group__column`}>
                <Button onClick={onClick}
                        icon={'document-na'}
                        isSmall/>
            </div>
            }
        </div>
    </>;
}