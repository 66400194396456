export const messageUtils = {
    //messageInfo properties
    advisorType: 1,
    initialMessageInput: {id: 0, messageText: ""},
    deleteStatus: {
        showDeletePopup: false,
        setDeleteID: null
    },
    fromMessage: "fromMessage",
    fromHeaderTextArea: "fromHeaderTextArea"
}

export const MessageProps = {
    tempMessage : 'tempMessage',
    editMessageInfo : 'editMessageInfo',
    initialEditMessageInfo : 'initialEditMessageInfo'
}
