import React from "react";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import Icon from "../common/icon";
import FormFieldForLogin from "../common/formFieldForLogin";
import {Field, reduxForm} from "redux-form";
import {bindActionCreators} from "redux";
import * as actionMaster from "../../actions/uiAction";
import * as locationAction from "../../actions/locationAction";
import {getLocationCreateOrUpdateRequest} from "../../actions/locationAction";
import * as caseAction from "../../actions/caseAction";
import Popup from "../common/popups/popup";
import {withRouter} from "react-router-dom";
import Utility from "../../api/utilLanguage";
import {bottomCenter} from "../common/customToastr";
import FooterWithSave from "../common/footer";
import PreLoader from "../common/preloader";
import AccessControl from "../../api/accessControl";
import AsyncSelect from "react-select/lib/Async";
import $ from "jquery";
import config from "../../api/config";
import Permalink from "../common/link/permalink";
import DisplayNothing from "../common/nothingToDisplay";
import locationService from "../../api/locationService";
import ErrorUtil from "../common/errorUtil";
import AppUtil from "../common/appUtil";
import {getApiToken} from "../common/localStorageUtil";
import DatePicker from "react-datepicker";
import DateUtilExt, {YEAR_FORMAT} from "../common/dateUtil/dateUtilExt";
import {datePickerCustomInput} from "../common/datePicker/datePickerUtil";

class LocationDetailsComponent extends React.Component {

    constructor(props) {
        super(props);
        this.FormData = '';
        this.altText = '';
        this.disableSave = false;
        this.state = {
            image: '',
            altText: '',
            formdataUpload: null,
            selectedOption: null,
            resultData: [],
            selectedPlaces: [],
            disableSave: false,
            selectedDateFromYear: this.defaultBuiltYear()
        }
    }

    defaultBuiltYear = () => {
        const {selectedLocationData} = this.props;
        if (AppUtil.isAvailable(selectedLocationData) && AppUtil.isAvailable(selectedLocationData.yearBuilt)) {
            const date = DateUtilExt.dateFromYear(selectedLocationData.yearBuilt);
            return DateUtilExt.dateToUTCString(date);
        } else {
            return null;
        }
    };

    componentDidUpdate = () => {
        if (this.props.selectedDataById.id !== undefined && this.props.selectedLocationData.id !== undefined) {
            if (JSON.stringify(this.props.selectedDataById) === JSON.stringify(this.props.selectedLocationData)) {
                console.log("Same");
                this.props.actionMaster.hideUnsavedPopupLazily();
            } else {
                this.props.actionMaster.showUnsavedPopupLazily();
                console.log("Different")
            }
        }
    };

    componentDidMount = () => {
        this.props.locationAction.getlocationTypes();
        this.props.locationAction.changeAltTextValue(false);
        this.props.locationAction.changeLocationTextField(undefined, "imageUrl");
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.locationUpdated !== undefined && (nextProps.locationUpdated !== this.props.locationUpdated)) {
            if (nextProps.locationUpdated === true) {
                this.loadingActionByValue(false);
            }
        }
    };

    onSelected = () => {
        this.props.onCardSelected(this.props.card);
    };

    onAltTextChange = (e) => {
        console.log("onAltTextChange", e.target.value);
        this.altText = e.target.value;
    };

    promisePlaceData = inputValue =>
        new Promise(resolve => {
            setTimeout(() => {
                resolve(this.loadOptionsForPlaces(inputValue));
            }, 1000);
        });

    loadOptionsForPlaces = async (inputValue) => {
        console.log("loadOption", inputValue);
        if (inputValue) {
            await this.loadPlacesData(inputValue).then(data => {
                let arrayOFPlaces = data.object.places;
                for (let h = 0; h < arrayOFPlaces.length; h++) {
                    if (arrayOFPlaces[h].typeId !== undefined) {
                        if (arrayOFPlaces[h].typeId === locationService.locationTypes.county.value) {
                            arrayOFPlaces[h].name = arrayOFPlaces[h].name + " (" + locationService.locationTypes.county.text + ")";

                        } else if (arrayOFPlaces[h].typeId === locationService.locationTypes.municipality.value) {
                            arrayOFPlaces[h].name = arrayOFPlaces[h].name + " (" + locationService.locationTypes.municipality.text + ")";
                        } else if (arrayOFPlaces[h].typeId === locationService.locationTypes.country.value) {
                            arrayOFPlaces[h].name = arrayOFPlaces[h].name + " (" + locationService.locationTypes.country.text + ")";
                        } else if (arrayOFPlaces[h].typeId === locationService.locationTypes.city.value) {
                            arrayOFPlaces[h].name = arrayOFPlaces[h].name + " (" + locationService.locationTypes.city.text + ")";
                        }
                    }
                }
                this.setState({
                    resultData: arrayOFPlaces
                })
            });
            return this.state.resultData;
        } else {
            return [];
        }
    };

    loadPlacesData = (inputValue) => {
        return new Promise((resolve, reject) => {
            let token = getApiToken();
            let request = $.ajax({
                url: config.baseURL + `api/v1/location/place/list?f_pn=${inputValue}`,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    };

    handleItemSelectChangeForPlaces = (data) => {
        console.log("data", data)
        this.props.locationAction.changeLocationTextField(data, "places");
    };

    onImageChange(event) {
        if (event.target !== undefined) {
            this.props.locationAction.changeAltTextValue(true);
            let file = event.target.files[0];
            this.setState({formdataUpload: event.target.files[0]});
            if (event.target.files && event.target.files[0]) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    this.setState({image: e.target.result});
                    this.props.locationAction.changeLocationTextField(e.target.result, "imageUrl");
                };
                reader.readAsDataURL(event.target.files[0]);
            }
            this.FormData = new FormData();
            this.FormData.append('file', file);
            this.props.locationAction.changeLocationTextField(file, "formDataUpload");
        } else {
            this.props.locationAction.changeAltTextValue(false);
        }


    }

    saveImageInfoApiCall = (fileData, altText, storingKey) => {
        const {initialValues, altTextInfo} = this.props;
        if (altTextInfo === false) {
            // this.props.locationAction.updateLocationAltTextApiCall(initialValues.locationMedias.id, this.altText, storingKey);
        } else {
            if (initialValues.name !== undefined) {
                this.props.locationAction.uploadLocationImageApiCall(fileData, initialValues.id, altText, storingKey);
                this.props.locationAction.changeAltTextValue(false);
            }
        }

    };

    loadingActionByValue = (value) => {
        this.disableSave = value;
        this.setState({disableSave: value});
    };

    onSaveClick = () => {
        this.loadingActionByValue(true);
        this.props.locationAction.updateLocationUpdatingStatus(false);
        const {selectedLocationData} = this.props;
        if (selectedLocationData.id !== undefined) {
            let data = getLocationCreateOrUpdateRequest(selectedLocationData);
            console.log("On save", data);
            this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isUpdating);
            this.props.locationAction.updateLocationInfoApiCall(data, this.props.storingKey).then(() => {
            }).finally(() => {
                //Call to save image info api
                const altText = selectedLocationData.mediaAltText !== undefined ? selectedLocationData.mediaAltText : "";
                this.saveImageInfoApiCall(this.FormData, altText, this.props.storingKey);
                this.FormData = '';
                this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
            })

            $("#accountProfilePicture").val('');

        } else {

            let data = getLocationCreateOrUpdateRequest(selectedLocationData);
            console.log("On save", data);
            if (data.name === '' || data.name === undefined || data.name === null) {
                this.loadingActionByValue(false);
                bottomCenter().warning(Utility.getLang().warningMessages.locationName.selectLocationName);
                return
            }
            if (data.locationtype === '' || data.locationtype === undefined || data.locationtype === null) {
                this.loadingActionByValue(false);
                bottomCenter().warning(Utility.getLang().warningMessages.location.selectLocationType);
                return
            }
            if (data.placeid === '' || data.placeid === undefined || data.placeid === null) {
                this.loadingActionByValue(false);
                bottomCenter().warning(Utility.getLang().warningMessages.placeId.selectPlace);
                return
            }
            this.props.locationAction.createLocationApiCall(data, this.props.storingKey);

        }

    };

    changeLocationActiveStatus = (e) => {
        this.props.locationAction.changeActiveStatusForLocation(e.target.checked);
    };

    onConfirmDelete = (d) => {
        this.toDeleteData = d;
        console.log("this.toDeleteData", this.toDeleteData);
        this.props.actionMaster.showPopup();
    };

    hidePopup = () => {
        this.props.actionMaster.hidePopupSection();
    };

    onDelete = () => {
        this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isDeleting);
        this.props.locationAction.deleteLocationApiCall(this.props.initialValues, this.props.storingKey);
        setTimeout(function () {
            this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
        }.bind(this), 1000);
        this.hidePopup();
    };

    changeLocationTextField = (e, id) => {
        if (AccessControl.revokedAccess().crudProduct === true) {
            bottomCenter().warning(Utility.getLang().warningMessages.noPermissionToDoChanges);
            return
        }
        console.log("Location Name", e.target.value, id);
        this.props.locationAction.changeLocationTextField(e.target.value, id);
    };

    onChangeDate = (date) => {
        const yyyyString = DateUtilExt.yyyyString(date);
        const yearBuilt = parseInt(yyyyString, 10);
        const dateStr = DateUtilExt.dateToUTCString(date);
        this.setState({selectedDateFromYear: dateStr});
        this.props.locationAction.updateYearBuilt(yearBuilt);
    };

    toMoveNewRoute = () => {
        this.props.actionMaster.clearRouteCondition();
        if (this.props.headerMenuItemClicked !== this.props.history.location.pathname) {
            this.props.history.push(this.props.headerMenuItemClicked);
        }
    };

    currentRoute = () => {
        this.props.actionMaster.onHeaderMenuItemClicked(this.props.history.location.pathname);
        this.props.actionMaster.closePopupWithSameRoute();
    };
    render = () => {
        const {selectedDateFromYear} = this.state;
        console.log("[Debug]:: location this.props.selectedLocationData", this.props.selectedLocationData, selectedDateFromYear);
        return (
            <React.Fragment>
                <PreLoader onRef={ref => this.preLoader = ref} showPreload={false}
                           container={this.node} blockContainer={true} type="Oval"/>
                <Popup openModal={this.props.showUnsavedPopup}
                       headerInfo={Utility.getLang().infoMessages.popup.unsavedChanges}
                       onConfirmation={this.toMoveNewRoute}
                       closePopup={this.currentRoute}/>
                {
                    this.props.displayNothing === false ?
                        <div className="section section--detail section--scroll" ref={node => this.node = node}>
                            <div className="section__head">
                                <div className="inner">
                                    <h3 className="section__title">
                                        <Icon type="location"/>
                                        <span className="description">
                                    {AppUtil.isAvailable(this.props.selectedCard) ? this.props.selectedCard.name : null}
                                </span>
                                        {
                                            this.props.selectedCard !== undefined && this.props.selectedCard !== null ?
                                                <Permalink id={this.props.selectedCard.id}
                                                           path1={AppUtil.linkPaths.location.basePath}
                                                           path2={AppUtil.linkPaths.location.details}/> : null
                                        }
                                    </h3>
                                </div>
                            </div>
                            <div className="section__content section__content--location_details">
                                <div className="inner">
                                    <form>
                                        <div className="section__columns">
                                            <div className="section__column">
                                                <div className="form-group">
                                                    <label
                                                        htmlFor="locationDetailsName">{Utility.getLang().locations.detailsTabContent.locationName}</label>
                                                    <input
                                                        defaultValue={this.props.selectedLocationData.name}
                                                        id="locationDetailsName"
                                                        placeholder={Utility.getLang().locations.detailsTabContent.locationName}
                                                        className="text text--max_width"
                                                        type="text"
                                                        onBlur={(e) => this.changeLocationTextField(e, "name")}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label
                                                        htmlFor="#">{Utility.getLang().locations.detailsTabContent.comment}</label>
                                                    <textarea
                                                        defaultValue={this.props.selectedLocationData.comment}
                                                        id="locationDetailsDescription"
                                                        placeholder={Utility.getLang().locations.detailsTabContent.comment}
                                                        className="textarea textarea--max_width textarea--tall"
                                                        type="textarea"
                                                        onBlur={(e) => this.changeLocationTextField(e, "comment")}
                                                    />
                                                </div>
                                                <div className="form-group form-group--columns">
                                                    <div className="form-group__column">
                                                        <label
                                                            htmlFor="locationDetailsAddress">{Utility.getLang().locations.detailsTabContent.address}</label>
                                                        <input
                                                            defaultValue={AppUtil.isAvailable(this.props.selectedLocationData.contactAddress) && AppUtil.isAvailable(this.props.selectedLocationData.contactAddress.address) ? this.props.selectedLocationData.contactAddress.address : null}
                                                            id="locationDetailsAddress"
                                                            placeholder={Utility.getLang().locations.detailsTabContent.address}
                                                            className="text text--max_width"
                                                            type="text"
                                                            onBlur={(e) => this.changeLocationTextField(e, "address")}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group form-group--columns">
                                                    <div className="form-group__column">
                                                        <label
                                                            htmlFor="#">{Utility.getLang().locations.detailsTabContent.postalCode}</label>
                                                        <input
                                                            defaultValue={AppUtil.isAvailable(this.props.selectedLocationData.contactAddress) && AppUtil.isAvailable(this.props.selectedLocationData.contactAddress.postalAddress) ? this.props.selectedLocationData.contactAddress.postalAddress : null}
                                                            id="locationDetailsPostalCode"
                                                            placeholder={Utility.getLang().locations.detailsTabContent.postalCode}
                                                            type="text"
                                                            onBlur={(e) => this.changeLocationTextField(e, "postalAddress")}
                                                            className="text text--zip"
                                                        />
                                                    </div>
                                                    <div className="form-group__column">
                                                        <label
                                                            htmlFor="locationDetailsCity">{Utility.getLang().locations.detailsTabContent.city}</label>
                                                        <input
                                                            id="locationDetailsCity"
                                                            placeholder={Utility.getLang().locations.detailsTabContent.city}
                                                            className="text text--max_width"
                                                            type="text"
                                                            defaultValue={AppUtil.isAvailable(this.props.selectedLocationData.contactAddress) && AppUtil.isAvailable(this.props.selectedLocationData.contactAddress.city) ? this.props.selectedLocationData.contactAddress.city : null}
                                                            onBlur={(e) => this.changeLocationTextField(e, "city")}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label
                                                        htmlFor="locationDetailsSlug">{Utility.getLang().locations.detailsTabContent.slug}</label>
                                                    <input
                                                        id="locationDetailsSlug"
                                                        placeholder={Utility.getLang().locations.detailsTabContent.slug}
                                                        className="text text--max_width"
                                                        type="text"
                                                        defaultValue={this.props.selectedLocationData.slug !== undefined ? this.props.selectedLocationData.slug : null}
                                                        onBlur={(e) => this.changeLocationTextField(e, "slug")}
                                                    />

                                                </div>
                                                <div className="form-group">
                                                    <label
                                                        htmlFor="locationDetailsSEOTitle">{Utility.getLang().locations.detailsTabContent.seoTitle}</label>
                                                    <input
                                                        id="locationDetailsSEOTitle"
                                                        placeholder={Utility.getLang().locations.detailsTabContent.seoTitle}
                                                        className="text text--max_width"
                                                        type="text"
                                                        defaultValue={this.props.selectedLocationData.seoTitle !== undefined ? this.props.selectedLocationData.seoTitle : null}
                                                        onBlur={(e) => this.changeLocationTextField(e, "seoTitle")}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="locationDetailsSEODescription">
                                                        {Utility.getLang().locations.detailsTabContent.seoDescription}
                                                    </label>
                                                    <textarea
                                                        id="locationDetailsSEODescription"
                                                        placeholder={Utility.getLang().locations.detailsTabContent.seoDescription}
                                                        className="textarea textarea--max_width"
                                                        type="textarea"
                                                        defaultValue={this.props.selectedLocationData.seoMetaDescription !== undefined ? this.props.selectedLocationData.seoMetaDescription : null}
                                                        onBlur={(e) => this.changeLocationTextField(e, "seoMetaDescription")}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <div className="option option--checkbox">
                                                        <input
                                                            id="isActive"
                                                            name="isActive"
                                                            checked={this.props.selectedLocationData.isActive !== undefined ? this.props.selectedLocationData.isActive : false}
                                                            disabled={AccessControl.revokedAccess().crudLocation}
                                                            type="checkbox"
                                                            onChange={(e) => this.changeLocationActiveStatus(e)}
                                                        />
                                                        <label
                                                            htmlFor="isActive"
                                                            className="option__description option__description--checkbox"
                                                        >
                                                            {Utility.getLang().locations.detailsTabContent.showlocationinpubliclistings}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="field field--text">
                                                    <h5 className="title">{Utility.getLang().locations.detailsTabContent.yearBuilt}</h5>
                                                    <DatePicker
                                                        // id={id}
                                                        dateFormat={YEAR_FORMAT}
                                                        showYearPicker
                                                        placeholderText={Utility.getLang().locations.detailsTabContent.placeholder}
                                                        selected={DateUtilExt.pickerDate(AppUtil.isAvailable(selectedDateFromYear) ? selectedDateFromYear : null)}
                                                        onChange={(date) => this.onChangeDate(date)}
                                                        autoComplete="off"
                                                        data-lpignore="true"
                                                        className="text text--date"
                                                        customInput={datePickerCustomInput(YEAR_FORMAT)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="section__column section__column--small">
                                                <div className="form-group">
                                                    <label>{Utility.getLang().locations.detailsTabContent.type}</label>
                                                    <select
                                                        placeholder={Utility.getLang().locations.detailsTabContent.type}
                                                        disabled={false}
                                                        type="select"
                                                        value={this.props.selectedLocationData.typeId !== undefined ? this.props.selectedLocationData.typeId : null}
                                                        onChange={(e) => this.changeLocationTextField(e, "typeId")}
                                                    >
                                                        <option>{Utility.getLang().common.dropDownOptionPleaseSelect}</option>
                                                        {
                                                            this.props.locationTypes.map((typeName, i) => {
                                                                    return (<option key={i}
                                                                                    value={typeName.key}>{typeName.value}</option>);
                                                                }
                                                            )
                                                        }
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label
                                                        htmlFor="#">{Utility.getLang().locations.detailsTabContent.place}</label>
                                                    <AsyncSelect
                                                        getOptionLabel={({name}) => name}
                                                        getOptionValue={({id}) => id}
                                                        placeholder={AppUtil.isAvailable(this.props.selectedLocationData.contactAddress) ? this.props.selectedLocationData.contactAddress.placeName : Utility.getLang().common.dropDownOptionPleaseSelect}
                                                        loadOptions={this.promisePlaceData}
                                                        onChange={(option) => this.handleItemSelectChangeForPlaces(option)}
                                                        defaultOptions
                                                        className={"multiselect"}
                                                        classNamePrefix={"multiselect"}
                                                        noOptionsMessage={() => Utility.getLang().common.noOptionMessage}
                                                        loadingMessage={() => Utility.getLang().common.dropDownOptionLoadingMessage}
                                                    />
                                                </div>

                                                {
                                                    this.props.selectedLocationData.id !== null && this.props.selectedLocationData.id !== undefined ?
                                                        <h5>{Utility.getLang().locations.detailsTabContent.picture}</h5> : null
                                                }

                                                {
                                                    this.props.selectedLocationData.id !== null && this.props.selectedLocationData.id !== undefined ?

                                                        <div className="assets">
                                                            <div className="asset">
                                                                <div className="asset__preview">
                                                                    <a
                                                                        href={this.props.initialValues.locationMedias !== undefined && this.props.initialValues.locationMedias !== null ? this.props.initialValues.locationMedias.url : null}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                    >
                                                                        <img
                                                                            src={this.props.initialValues.locationMedias !== undefined && this.props.initialValues.locationMedias !== null && this.props.selectedLocationData.imageUrl === undefined ? this.props.initialValues.locationMedias.url : this.props.selectedLocationData.imageUrl}
                                                                            height="300"
                                                                            className={'image_ratio'}
                                                                            alt={this.props.initialValues.locationMedias !== undefined && this.props.initialValues.locationMedias !== null && this.state.image === '' ? this.props.initialValues.locationMedias.altText : this.state.altText}
                                                                        />
                                                                    </a>
                                                                </div>

                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="locationDetailsMediaUrl">{Utility.getLang().locations.detailsTabContent.imageURL}</label>
                                                                    <Field
                                                                        name="locationMedias.url"
                                                                        id="locationDetailsMediaUrl"
                                                                        placeholder={Utility.getLang().locations.detailsTabContent.imageURL}
                                                                        className="text text--max_width"
                                                                        component={FormFieldForLogin}
                                                                        type="text"
                                                                        disabled
                                                                    />
                                                                </div>
                                                                <div className="form-group">
                                                                    <label
                                                                        htmlFor="altText">{Utility.getLang().locations.detailsTabContent.altText}</label>
                                                                    <input
                                                                        id="locationDetailsAltText"
                                                                        placeholder={Utility.getLang().locations.detailsTabContent.altText}
                                                                        disabled={AccessControl.revokedAccess().crudLocation}
                                                                        className="text text--max_width"
                                                                        type="text"
                                                                        defaultValue={this.props.selectedLocationData.locationMedias !== null && this.props.selectedLocationData.locationMedias !== undefined ? this.props.selectedLocationData.locationMedias.altText : ''}
                                                                        onBlur={(e) => this.changeLocationTextField(e, "altText")}
                                                                    />
                                                                </div>
                                                                {
                                                                    AccessControl.revokedAccess().crudLocation === true ?
                                                                        null :
                                                                        <div className="form-group">
                                                                            <label htmlFor="accountProfilePicture">
                                                                                {Utility.getLang().common.uploadPicture}
                                                                            </label>
                                                                            <input
                                                                                id="accountProfilePicture"
                                                                                name="locationMedias.url"
                                                                                className="text file"
                                                                                type="file"
                                                                                accept="image/png, image/jpeg"
                                                                                onChange={this.onImageChange.bind(this)}
                                                                            />
                                                                        </div>
                                                                }

                                                            </div>
                                                        </div> : null
                                                }
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div> : <DisplayNothing/>

                }

                {
                    AccessControl.revokedAccess().crudLocation === true ?
                        null :
                        <FooterWithSave
                            SaveData={this.onSaveClick}
                            hideFooter={this.props.displayNothing}
                            buttonText={Utility.getLang().locations.detailsTabContent.updateDetails}
                            deleteButtonText={Utility.getLang().locations.detailsTabContent.deletelocation}
                            deleteCase={this.onConfirmDelete}
                            hideRelativeDropdown={true}
                            disableSaveButton={this.disableSave}
                        />
                }

                <Popup
                    openModal={this.props.open}
                    headerInfo={Utility.getLang().infoMessages.popup.deleteLocation + ` ` + this.props.initialValues.name + `?`}
                    onConfirmation={this.onDelete}
                    closePopup={this.hidePopup}
                    isDestructive
                    withTimeOut
                />
            </React.Fragment>
        )
    }
}

LocationDetailsComponent.propTypes = {
    selectedCard: PropTypes.object,
    columnDetailViewTabCollection: PropTypes.array,
};

function mapStateToProps(state) {
    return {
        initialValues: state.application.selectedDataById,
        selectedDataById: state.application.selectedDataById,
        selectedLocationData: state.application.selectedLocationData,
        headerMenuItemClicked: state.application.headerMenuItemClicked,
        showUnsavedPopup: state.application.showUnsavedPopup,
        open: state.application.open,
        locationActiveStatus: state.application.locationActiveStatus,
        locationTypes: state.application.locationTypes,
        altTextInfo: state.application.altTextInfo,
        displayNothing: state.application.displayNothing,
        locationUpdated: state.application.locationUpdated,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        locationAction: bindActionCreators(locationAction, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: "LocationDetailsComponent"
})(withRouter(LocationDetailsComponent)));
