import AppUtil from "../common/appUtil";
import RefreshToken from "../../api/validateToken";
import {api} from "../common/axios/apiCallerInterceptor";
import ApiCollection from "../../api/apiCollections";
import {push} from "react-router-redux";
import {getApiToken} from "../common/localStorageUtil";
import {CLEAR_ORDER_DELIVERY_METHODS, GET_ORDER_DELIVERY_METHODS} from "./types";

function getOrderDeliveryMethodsApiCommon(token, urlSuffix, dispatch) {
    try {
        const config = {headers: {'Authorization': `Bearer ${token}`}};
        api.get(`${urlSuffix}`, config).then((res) => {
            if (res.status === 200) {
                dispatch(updateOrderDeliveryMethods(res.data));
            }
        }).catch((error) => {
            console.log("[Debug]:: get order delivery methods, error = ", error);
        });
    } catch (e) {
        console.log("[Debug]:: get order delivery methods, error = ", e);
    }
}

export const getOrderDeliveryMethodsApi = () => dispatch => {
    const urlSuffix = ApiCollection.properties.fetchOrderDeliveryMethods;
    if (AppUtil.jwtTokenIsExpired()) {
        RefreshToken.validateRefreshToken().then(resp => {
            getOrderDeliveryMethodsApiCommon(resp.idToken.jwtToken, urlSuffix, dispatch);
        }).catch(err => {
            console.log("validate refresh token error = ", err);
            push('/');
        });
    } else {
        getOrderDeliveryMethodsApiCommon(getApiToken(), urlSuffix, dispatch);
    }
}

export function updateOrderDeliveryMethods(payload) {
    return {type: GET_ORDER_DELIVERY_METHODS, payload: payload};
}

export function clearOrderDeliveryMethods() {
    return {type: CLEAR_ORDER_DELIVERY_METHODS, payload: []};
}
