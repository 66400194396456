import {getTenantId, getTenantThirdPartySettings, getValidationRules, SE_TENANT, UK_TENANT} from "./localStorageUtil";
import CaseUtil from "./caseUtil";
import AppUtil, {isTrue} from "./appUtil";

class ValidationAndVisibilityRule {
    static personNumber = "personNumber";
    static dateOfBirth = "dateOfBirth";
    static postCode = "postCode";
    static date = "date";
    static country = "country";
    static county = "county";
    static phone = "phone";
    static mobile = "mobile";
    static deceased = "deceased";
    static relative = "relative";
    static common = "common";
    static referredToCoroner = "referredToCoroner";
    static readyForCollection = "readyForCollection";
    static deathRegistered = "deathRegistered";
    static greenFormWith = "greenFormWith";
    static validationRules = "validationRules";
    static memberOfSwedishChurch = "memberOfSwedishChurch";
    static otherDetails = "otherDetails";
    static parishName = "parishName";
    static spar = "spar";
    static flax = "flax";
    static hitta = "hitta";
    static currencyPlacement = "currencyPlacement";
    static sex = "sex";
    static maritalStatus = "maritalStatus";
    static infectionStatus = "infectionStatus";
    static lastOccupation = "lastOccupation";
    static timeOfPassing = "timeOfPassing";
    static locationOfPassing = "locationOfPassing";
    static furtherExaminationRequired = "furtherExaminationRequired";
    static detailsOfFurtherExamination = "detailsOfFurtherExamination";
    static releaseForm = "releaseForm";
    static causeOfDeath = "causeOfDeath";
    static cremationPapers = "cremationPapers";
    static cremationPaperPaidBy = "cremationPaperPaidBy";
    static paymentDetailsForCremationPapers = "paymentDetailsForCremationPapers";
    static addressOfPassing = "addressOfPassing";
    static phoneOfPassing = "phoneOfPassing";
    static ukPaymentMethod = "ukPaymentMethod";
    static nationalInsuranceNumber = "nationalInsuranceNumber";
    static financialAssistanceDetails = "financialAssistanceDetails";
    static ukContactProperties = "ukContactProperties"
    static product = "product";
    static enableDisbursement = "enableDisbursement";
    static relativesAndExecutorsInformed = "relativesAndExecutorsInformed";
    static notInformedDetails = "notInformedDetails";
    static cremationObjections = "cremationObjections";
    static objectionsDetails = "objectionsDetails";
    static ashesDisposalTypeForCrem1 = "ashesDisposalTypeForCrem1";
    static ashesInstructionsForCrem1 = "ashesInstructionsForCrem1";

    static dateFormat = "dateFormat";
    static shortTimeFormat = "shortTimeFormat";
    static locale = "locale";

    static companyName = "companyName";

    static isDeceased = "isDeceased";
    static legalStatus = "legalStatus";

    static order = "order";
    static funeralButton = "funeralButton";

    static parentId = "parentId";
    static passingCountyId = "passingCountyId";
}

export function isFieldEnabled(root, child) {
    const property = propertyOfValidationRule(root, child);
    return AppUtil.isAvailable(property) ? isTrue(property.visible) : false;
}

export function validationRule(root, child) {
    const property = propertyOfValidationRule(root, child);
    const {validationRules} = ValidationAndVisibilityRule;
    return AppUtil.isAvailable(property) ? (property[validationRules] !== undefined ? property[validationRules] : property) : {};
}

export function propertyOfValidationRule(root, child) {
    try {
        const visibility = JSON.parse(getValidationRules());
        const property = (AppUtil.isAvailable(visibility) && AppUtil.isAvailable(visibility[root]) && AppUtil.isAvailable(visibility[root][child])) ? visibility[root][child] : null;
        return property;
    } catch (e) {
        console.log("Error: ", e);
        return null;
    }
}

export function concatenateDateChildProperties(property1, property2, concatenatedBy) {
    const {date} = ValidationAndVisibilityRule;
    const string1 = getNestedPropertyFromValidationRules(property1, date);
    const string2 = getNestedPropertyFromValidationRules(property2, date);
    const result = concatenate(string1, string2, concatenatedBy);
    return result;
}

function concatenate(string1, string2, concatenatedBy) {
    let result = "";
    if (AppUtil.isAvailable(string1)) {
        result = string1;
        if (AppUtil.isAvailable(string2)) {
            result = result + concatenatedBy + string2;
        }
    } else if (AppUtil.isAvailable(string2)) {
        result = string2;
    }
    return result;
}

export function getNestedPropertyFromValidationRules(nestedProp, fromChildProp) {
    const childProperty = validationRule(ValidationAndVisibilityRule.validationRules, fromChildProp);
    let result = "";
    if (AppUtil.isAvailable(childProperty) && AppUtil.isAvailable(childProperty[nestedProp])) {
        result = childProperty[nestedProp];
    } else {
        result = "";
    }
    return result;
}

//TODO: Optimise similar code "concatenateDateChildProperties"
export function _concatenateDateChildProperties(property1, property2, concatenatedBy, validationRules) {
    const {date} = ValidationAndVisibilityRule;
    const string1 = _getNestedPropertyFromValidationRules(property1, date, validationRules);
    const string2 = _getNestedPropertyFromValidationRules(property2, date, validationRules);
    const result = concatenate(string1, string2, concatenatedBy);
    return result;
}

function _getNestedPropertyFromValidationRules(nestedProp, childProp, validationRules) {
    const childProperty = validationRules[childProp];
    return AppUtil.isAvailable(childProperty) && AppUtil.isAvailable(childProperty[nestedProp]) ? childProperty[nestedProp] : "";
}

export function sparLinkVisible() {
    return getThirdPartyVisibility(ValidationAndVisibilityRule.spar)
}

export function flaxLinkVisible() {
    return getThirdPartyVisibility(ValidationAndVisibilityRule.flax)
}

export function invoiceBeforeDeliveryPopup(destinationId) {
    if (getTenantThirdPartySettings() === null || parseInt(destinationId, 10) !== CaseUtil.caseStages.deliver.value) {
        return false;
    }
    return getTenantThirdPartySettings()["multiResolutionPopUp"]["invoiceBeforeDelivery"]
}

function getThirdPartyVisibility(target) {
    return getTenantThirdPartySettings()[target]["enable"];
}

const UK_COMMISSION_COEFFICIENT = 10;
const SE_COMMISSION_COEFFICIENT = 9;
const getDefaultCommissionCoefficient = (tenantId) => {
    switch (tenantId) {
        case UK_TENANT:
            return UK_COMMISSION_COEFFICIENT;
        case SE_TENANT:
            return SE_COMMISSION_COEFFICIENT;
        default:
            console.log("[Debug]: Unhandled tenant, setting default");
            return SE_COMMISSION_COEFFICIENT;
    }
}

export const commissionCoefficient = () => {
    const commissionCoefficient = getTenantThirdPartySettings()?.compensation?.commissionCoefficient;
    return commissionCoefficient ? parseInt(commissionCoefficient, 10) : getDefaultCommissionCoefficient(getTenantId());
};

export function isCurrencyPlacedOnRight(root, child) {
    const defaultCurrencyPlacement = true;
    const visibility = JSON.parse(getValidationRules());
    if (visibility === null) {
        return defaultCurrencyPlacement;
    }
    const currencyPlacement = visibility[root][child];
    return AppUtil.isAvailable(currencyPlacement) ? (currencyPlacement.right === "true") : defaultCurrencyPlacement;
}

export default ValidationAndVisibilityRule;
