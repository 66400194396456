import Utility from "../../../../api/utilLanguage";
import React from "react";
import {LoadMoreOption} from "../loadMoreOption";
import {NoData} from "../noData";
import Tooltip from "../../tooltip";
import Icon from "../../icon";
import LogItem from "../logItem";
import AppUtil from "../../appUtil";
import LogUtil from "../util/logUtil";
import DisplayNothing from "../../nothingToDisplay";
import InfiniteScroll from 'react-infinite-scroller';

class EventTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            expanded: false
        };
    }

    toggleAll = () => {
        this.setState({expanded: !this.state.expanded})
    };

    getLogType = (log) => {
        let logType = "generic";
        if (AppUtil.isAvailable(log)) {
            const eventId = log.logType;
            const event = LogUtil.eventMapper(eventId);
            logType = AppUtil.isAvailable(event) ? event.type : logType;
        }
        // console.log("[DEBUG]:::logType = ", logType);
        return logType;
    };

    render = () => {
        const currentObject = this.props;
        const {showLoadMoreOption, loadMore, logData, scrollParentRef} = this.props;

        let classNames = "fnxtable fnxtable--flat fnxtable--log fnxtable--log--case";

        if (this.state.expanded) classNames += ' fnxtable--expanded';

        return (
            <div className="table__wrapper">
                {(AppUtil.isAvailable(logData) && logData.length > 0) ?
                    <table className={classNames}>
                        <thead>
                            <tr className="tr">
                                <th className="th th--toggle_row">
                                        <span
                                            onClick={() => this.toggleAll()}
                                            className="log_item__toggle"
                                            data-tip="true"
                                        >
                                            <Icon type="expand" small/>
                                            <Tooltip alignLeft>
                                                {this.state.expanded ? Utility.getLang().common.collapseAll : Utility.getLang().common.expandAll}
                                            </Tooltip>
                                        </span>
                                </th>
                                <th className="th th--event">
                                    {Utility.getLang().log.column.event}
                                </th>
                                <th className="th th--main th--description th--details">
                                    {Utility.getLang().log.column.details}
                                </th>
                                <th className="th th--author">
                                    {Utility.getLang().log.column.user}
                                </th>
                                <th className="th th--timestamp">
                                    {Utility.getLang().log.column.timestamp}
                                </th>
                            </tr>
                        </thead>
                        {/*Table supports Infinite scrolling by adding scroll listener of parent to it*/}
                        <InfiniteScroll
                            element="tbody"
                            pageStart={0}
                            loadMore={loadMore}
                            initialLoad={false}
                            threshold={1000}
                            hasMore={showLoadMoreOption}
                            useWindow={false}
                            getScrollParent={() => scrollParentRef}
                        >
                            {
                                logData.map((log, i) => {
                                    return (
                                            <LogItem
                                                key={`${i} ${log.logType} ${log.created}`}
                                                logType={this.getLogType(log)}
                                                currentObject={currentObject}
                                                logObject={log}
                                                expanded={this.state.expanded}
                                                indexedAt={i}
                                            />
                                    );
                                })
                            }
                            {
                                showLoadMoreOption ?
                                    <LoadMoreOption onClickAction={loadMore}/>
                                :
                                    <NoData/>
                            }
                        </InfiniteScroll>
                    </table>
                :
                    <DisplayNothing />
                }
            </div>
        );
    }
}

export default EventTable;
