import React from 'react';
import Button from "../../common/button";
import Utility from "../../../api/utilLanguage";
import OrderFieldRules from "../caseTenantBasedOrder/OrderFieldRules";
import LockCaseUtil from "../lockCase/util/lockCaseUtil";
import {isTrue} from "../../common/appUtil";
import * as Proptypes from "prop-types";
import OrderFuneralButton, {OrderRepatriationButton} from "../caseTenantBasedOrder/OrderFuneralButton";
import ValidationAndVisibilityRule, {propertyOfValidationRule} from "../../common/ValidationAndVisibilityRule";
import {bindActionCreators} from "redux";
import * as actionMaster from "../../../actions/uiAction";
import {connect} from 'react-redux';

const {buttonStatusUpdate} = OrderRepatriationButton();

class CaseOrderList extends React.Component {

    constructor(props) {
        super(props);
        this.orderList = props.orderTypes;
        this.funeralButton = propertyOfValidationRule(ValidationAndVisibilityRule.order, ValidationAndVisibilityRule.funeralButton);
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        OrderFuneralButton.funeralButtonStatusUpdate(nextProps.orders, this.orderList);
        buttonStatusUpdate(nextProps.orders, this.orderList);
    };

    clickedOrder = (order) => {
        this.props.clickedOrder(order);
    };

    openFuneralPopup = (e) => {
        this.props.actionMaster.showPackageFuneralPopup();
    }

    hideFuneralButton = (funeralBtnStatus, orderType) => {
        return isTrue(funeralBtnStatus) ? !OrderFieldRules.isFuneral(orderType) : true;
    }

    disableFuneralButton = (funeralBtnStatus) => {
        let buttonStatus = false;
        if (isTrue(funeralBtnStatus)) {
            this.orderList.map((order) => {
                if (OrderFieldRules.isFuneral(order.id)) {
                    buttonStatus = order.disable;
                }
            });
        }
        return buttonStatus;
    }

    render() {
        const funeralBtnStatus = this.funeralButton ? this.funeralButton.visible : false;
        return (
            <div className="drawer" disabled={LockCaseUtil.isDisabledView(this.props.disabledView)}>
                <div className="inner">
                    <h4>{Utility.getLang().cases.orderTabContent.funeralCaseOrderContent.addOrder}</h4>
                    <div className="section__toolbar btn-toolbar">
                        {isTrue(funeralBtnStatus) ? <Button disabled={this.disableFuneralButton(funeralBtnStatus)}
                                                            onClick={() => this.openFuneralPopup()}>
                            {Utility.getLang().orders.funeralPopupPackage.funeralLabel} </Button> : ''}
                        {this.orderList.map((order, i) => {
                            return (
                                this.hideFuneralButton(funeralBtnStatus, order.id) ?
                                    <Button
                                        key={i}
                                        id={order.id}
                                        ref={order.id}
                                        disabled={order.disable}
                                        onClick={(e) => this.clickedOrder(order)}
                                    >
                                        {order.name}
                                    </Button> : null
                            )
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {actionMaster: bindActionCreators(actionMaster, dispatch)};
}

export default connect(null, mapDispatchToProps)(CaseOrderList);

CaseOrderList.proptypes = {
    orders: Proptypes.any.isRequired,
    clickedOrder: Proptypes.func.isRequired,
    orderTypes: Proptypes.array.isRequired,
    disabledView: Proptypes.bool.isRequired
}