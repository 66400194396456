import React from "react";
import connect from "react-redux/es/connect/connect";
import AppUtil, {Lang} from "../../../../common/appUtil";
import useDocumentHandlerPopup from "../documentHandlerPopup/hooks/useDocumentHandlerPopup";
import PropertyGroup from "./propertyGroup";
import {ProbateProps} from "../tabProbateMeeting/util";
import DocumentHandlerPopup from "../documentHandlerPopup";
import Button from "../../../../common/button";
import useProbateUtil from "../../util/useProbateUtil";
import {useAssetDebtsUtil} from "./utils";
import PropertySummaryTable from "./propertySummaryTable";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";

import "react-tabs/style/react-tabs.css";
import DeletePopup from "../../../memorialPage/ui/memories/popup/deletePopup";
import useDeletePropertyPopup from "./propertyGroup/useDeletePropertyPopup";
import {PropertyOwnerEnum} from "./hooks";

function TabAssetDebts(props) {

    const {dispatch, Enum} = props;
    const {onClickShowDocumentHandlerPopup, selectedDataTracker} = useDocumentHandlerPopup(dispatch);
    const {
        caseId,
        probateId,
        orders,
        showDocumentHandlerPopup,
        showDeleteProbateDeceasedPropertyPopup,
        showDeleteProbateSpousePropertyPopup
    } = props;
    const {
        deceasedPropertySummary,
        spousePropertySummary,
        deceasedPropertyGroupedDetails,
        spousePropertyGroupedDetails,
        assetsOfEstate
    } = props.property || {};

    const {downloadAssetsAndDebtsZip, isDownloadingProbateZip} = useProbateUtil();

    const {enableDownloadButton} = useAssetDebtsUtil(props.property);
    const propertySubGroups = [ProbateProps.matrimonialProperties, ProbateProps.privateProperties];

    const {
        crudAssetDebtsTracker,
        popupTitle,
        popupContent,
        onDeleteConfirmation,
        removeProperty
    } = useDeletePropertyPopup(dispatch);

    const deletePopupId = (crudAssetDebtsTracker?.data?.propertyOwner === PropertyOwnerEnum.DECEASED) ? DeletePopup.PROBATE_DECEASED_PROPERTY_DELETE_POPUP : DeletePopup.PROBATE_SPOUSE_PROPERTY_DELETE_POPUP;
    const setPopupTitle = crudAssetDebtsTracker ? crudAssetDebtsTracker?.key : "";
    return (
        <>
            <DocumentHandlerPopup
                show={showDocumentHandlerPopup}
                title={Lang().cases.probates.selectOrUploadDocument}
                selectedData={selectedDataTracker}
                caseId={caseId}
                documentTypeId={selectedDataTracker.documentType}
                dispatch={dispatch}
            />
            <DeletePopup popupId={deletePopupId}
                         showDeletePopup={showDeleteProbateDeceasedPropertyPopup || showDeleteProbateSpousePropertyPopup}
                         title={popupTitle(setPopupTitle)}
                         content={popupContent(crudAssetDebtsTracker?.data?.name, crudAssetDebtsTracker?.data?.valueAsText)}
                         onConfirmation={() => onDeleteConfirmation(crudAssetDebtsTracker)}
            />
            <div className="probate-assets-debts">
                <div className="section__columns">
                    <div className="section__column">
                        <div className="summary-assets summary--assets-alignment">
                            <h5>{Lang().cases.probates.summary}</h5>
                            <PropertySummaryTable title={Lang().cases.probates.matrimonialProperty}
                                                  deceasedPropertySummary={deceasedPropertySummary?.matrimonialPropertySummary
                                                  }
                                                  spousePropertySummary={spousePropertySummary?.matrimonialPropertySummary
                                                  }/>
                            <PropertySummaryTable title={Lang().cases.probates.privateProperty}
                                                  deceasedPropertySummary={deceasedPropertySummary?.privatePropertySummary}
                                                  spousePropertySummary={spousePropertySummary?.privatePropertySummary}/>
                            <h5 className={"h_line"}>{`${Lang().cases.probates.estateHolding}: ${assetsOfEstate}`}</h5>
                        </div>
                    </div>
                </div>
                <Tabs defaultIndex={0}
                      selectedTabClassName={'react-tabs__tab--selected is-active'}>
                    <TabList>
                        <Tab>{Lang().cases.probates.headerTitle.deceasedDetails}</Tab>
                        <Tab>{Lang().cases.probates.headerTitle.spouseDetails}</Tab>
                    </TabList>
                    <TabPanel>
                        {
                            propertySubGroups.map((propertySubGroupKey, index) => {
                                return <div className="section__columns" key={index}>
                                    <PropertyGroup propertyGroupKey={ProbateProps.deceasedPropertyGroupedDetails}
                                                   propertySubGroupKey={propertySubGroupKey}
                                                   propertyTypeDetails={deceasedPropertyGroupedDetails?.[propertySubGroupKey]?.propertyDetailsDto}
                                                   caseId={caseId}
                                                   Enum={props.Enum}
                                                   onClickShowDocumentHandlerPopup={onClickShowDocumentHandlerPopup}
                                                   probateId={probateId}
                                                   orders={orders}
                                                   removeProperty={removeProperty}
                                                   dispatch={dispatch}/>
                                </div>

                            })
                        }
                    </TabPanel>
                    <TabPanel>
                        {
                            propertySubGroups.map((propertySubGroupKey, index) => {
                                return <div className="section__columns" key={index}>
                                    <PropertyGroup propertyGroupKey={ProbateProps.spousePropertyGroupedDetails}
                                                   propertySubGroupKey={propertySubGroupKey}
                                                   propertyTypeDetails={spousePropertyGroupedDetails?.[propertySubGroupKey]?.propertyDetailsDto}
                                                   caseId={caseId}
                                                   Enum={props.Enum}
                                                   onClickShowDocumentHandlerPopup={onClickShowDocumentHandlerPopup}
                                                   removeProperty={removeProperty}
                                                   dispatch={dispatch}/>
                                </div>
                            })
                        }
                    </TabPanel>
                </Tabs>
                <Button id={'DownloadAssetAndDebts'}
                        onClick={() => downloadAssetsAndDebtsZip(probateId, dispatch)}
                        isSecondary
                        disabled={isDownloadingProbateZip || (enableDownloadButton === false)}
                >
                    {Lang().cases.probates.downloadAssetsAndDebts}
                </Button>
            </div>
        </>
    );
}

function mapStateToProps(state) {
    return {
        Enum: state.probateReducer.enums,
        showDeleteProbateDeceasedPropertyPopup: state.probateReducer.showDeleteProbateDeceasedPropertyPopup,
        showDeleteProbateSpousePropertyPopup: state.probateReducer.showDeleteProbateSpousePropertyPopup,
        property: state.probateReducer.probateInfo.property,
        probateId: state.probateReducer.probateInfo.id,
        caseId: AppUtil.isAvailable(state.application.selectedCaseData)
            ? state.application.selectedCaseData.id
            : null,
        orders: AppUtil.isAvailable(state.application.selectedCaseData)
            ? state.application.selectedCaseData.orders
            : [],
        showDocumentHandlerPopup: state.probateReducer.showDocumentHandlerPopup,
    };
}

export default connect(mapStateToProps)(TabAssetDebts);