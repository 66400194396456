import React from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import Icon from "../common/icon";
import {bindActionCreators} from "redux";
import * as actionMaster from "../../actions/uiAction";
import * as caseAction from "../../actions/caseAction";
import {reduxForm} from "redux-form";
import FormValidator from "../common/formValidator";
import Utility from "../../api/utilLanguage";
import AsyncSelect from "react-select/lib/Async";
import $ from "jquery";
import config from "../../api/config";
import DisplayNothing from "../common/nothingToDisplay";
import PopupForEmailSend from "../common/popups/popupForEmailSend";
import ErrorUtil from "../common/errorUtil";
import AppUtil from "../common/appUtil";
import CaseCallsCardTitle from "./card/caseCallsCardTitle";
import CaseHeaderSection from './caseHeaderSection';
import {getApiToken} from "../common/localStorageUtil";
import LockCaseUtil from "./lockCase/util/lockCaseUtil";
import {ValidationErrors} from "../common/validationUtil";

class CaseCallsComponent extends React.Component {

    constructor(props) {
        super(props);
        this.currentRelativeId = null;
        this.state = {
            inputValue: '',
            resultData: [],
            initialData: [],
            defaultUser: [],
            selectedRelative: null,
            selectedStage: null
        }
    }

    componentDidUpdate = (prevProps) => {
        if (JSON.stringify(prevProps.selectedCaseData) !== JSON.stringify(this.props.selectedCaseData)) {
            const errorsInfo = ValidationErrors.info(this.props.selectedCaseData);
            if (ValidationErrors.isAvailable(errorsInfo)) {
                this.props.caseAction.updateValidationErrorsInfo(errorsInfo);
            } else {
                this.props.caseAction.updateValidationErrorsInfo({});
            }
        }
    };

    componentDidMount = () => {

        if (this.props.initialValues.relatives !== undefined && this.props.initialValues.relatives.length > 0) {
            this.setState({
                selectedRelative: this.props.initialValues.relatives[0].relationshipId
            })
        }
        if (this.props.selectedCaseData.id !== undefined) {
            if (this.state.resultData.length === 0) {
                let temp = [];
                temp.push({
                    id: this.props.selectedCaseData.deceased !== undefined && this.props.selectedCaseData.deceased.hospitalId ? this.props.selectedCaseData.deceased.hospitalId : "",
                    name: this.props.selectedCaseData.deceased !== undefined && this.props.selectedCaseData.deceased.hospitalName ? this.props.selectedCaseData.deceased.hospitalName : "",
                });
                this.setState({
                    initialData: temp
                })
            }
        }
    };

    hidePopupOnCancel = () => {
        this.props.actionMaster.hidePopupSection();
    };

    promiseOptions = inputValue =>
        new Promise(resolve => {
            setTimeout(() => {
                resolve(this.loadOptions(inputValue));
            }, 1000);
        });

    loadOptions = async (inputValue) => {
        if (inputValue) {
            await this.loadData(inputValue).then(data =>
                this.setState({
                    resultData: data.object.calls
                })
            );
            return this.state.resultData;
        } else {
            return [];
        }
    };

    loadData = (inputValue) => {
        return new Promise((resolve, reject) => {
            let token = getApiToken();
            let request = $.ajax({
                url: config.baseURL + `/api/v1/case/call/list?f_tx=${inputValue}`,
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            request.done(function (responseData) {
                resolve(responseData);
            });
            request.fail(function (jqXHR) {
                ErrorUtil.setUserOnSentry(jqXHR);
                ErrorUtil.checkRequestFailMessage(jqXHR, reject);
            });
        });
    };

    handleItemSelectChange = (data) => {
        if (data.length !== 0) {
            this.props.caseAction.setCallDetails(data)
        }
    };

    removeLabel = (labels, i) => {
        this.props.caseAction.removeLabelFromView(labels, i)
    };

    onSelectRelativeName = (e) => {
        this.currentRelativeId = null;
        this.currentRelativeId = e.target.value;
        for (let n = 0; n < this.props.initialValues.relatives.length; n++) {
            if (this.props.initialValues.relatives[n].id === parseInt(e.target.value, 10)) {
                this.setState({
                    selectedRelative: this.props.initialValues.relatives[n].relationshipId
                })
            }
        }
    };

    sendEmailSummary = (relativeId, bodyData, emailTemplateType) => {
        // Sending the email
        bodyData.caseId = this.props.selectedCaseData.id;
        this.props.caseAction.sendEmailSummary(this.props.selectedCaseData.id, bodyData);
    };

    removeCall = (e, index) => {
        this.props.caseAction.removeCallFromCase(e, index);
    };

    render = () => {
        return (
            <React.Fragment>
                {this.props.displayNothing === false ?
                    <div className="section section--detail section--scroll">
                        <CaseHeaderSection wsLockCaseData={this.props.wsLockCaseData}
                                           selectedCaseData={this.props.selectedCaseData}/>
                        {
                            this.props.selectedCaseData !== undefined && this.props.selectedCaseData.id !== undefined ?
                                <div className="section__content section__content--case_calls drawer"
                                     disabled={(LockCaseUtil.isDisabledView(this.props.disabledView))}>
                                    {
                                        this.props.selectedCaseData.calls.map((calls, i) => {
                                            return (
                                                <div className="item__spacer">
                                                    <div
                                                        className="item item--button item--call"
                                                        tabIndex="0"
                                                        key={i}
                                                    >
                                                        <CaseCallsCardTitle
                                                            data={calls}
                                                            infoType={AppUtil.infoKeys.call}
                                                        />
                                                        <span
                                                            className="item__meta item__meta--phone item__meta--number">
                                                            <Icon type="phone" tiny/>
                                                            <span className="description">
                                                                {calls.callerNumber !== undefined ? calls.callerNumber : null}
                                                            </span>
                                                        </span>
                                                        <span
                                                            className="item__meta item__meta--contact item__meta--email">
                                                            <Icon type="contact" tiny/>
                                                            <span className="description">
                                                                {calls.callerName}
                                                            </span>
                                                        </span>
                                                        <span
                                                            className="item__meta item__meta--timestamp item__meta--number">
                                                            <Icon type="time" tiny/>
                                                            <span className="description">
                                                                {calls.createdText}
                                                            </span>
                                                        </span>
                                                        {calls.labels !== undefined ?
                                                            calls.labels.map((label, j) => {
                                                                return (
                                                                    <div className="item__footer" key={j}>
                                                                        <div className="item__tags">
                                                                            <span
                                                                                className="tags tags--labels tags--small">
                                                                                 <span
                                                                                     className={`tag tag--label tag--${label.colorCode}`}>
                                                                                     <span
                                                                                         className="description">{label.title}</span>
                                                                                  </span>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }) : null
                                                        }
                                                        <div className="item__toolbar">
                                                            <div className="btn-toolbar">
                                                                <a
                                                                    className="link is-destructive"
                                                                    onClick={(e) => this.removeCall(calls, i)}
                                                                >
                                                                    <Icon small type="trash"/>
                                                                    <span>
                                                                        {Utility.getLang().cases.callsTabContent.removeCall}
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className="form-group">
                                        <label htmlFor="#">
                                            {Utility.getLang().cases.callsTabContent.header}
                                        </label>
                                        <AsyncSelect
                                            getOptionLabel={({callerName}) => callerName}
                                            getOptionValue={({id}) => id}
                                            placeholder={Utility.getLang().cases.callsTabContent.search}
                                            loadOptions={this.promiseOptions}
                                            onChange={(option) => this.handleItemSelectChange(option)}
                                            defaultOptions
                                            className={"multiselect"}
                                            classNamePrefix={"multiselect"}
                                            noOptionsMessage={() => Utility.getLang().common.noOptionMessage}
                                            loadingMessage={() => Utility.getLang().common.dropDownOptionLoadingMessage}
                                        />
                                    </div>
                                </div>
                                :
                                <DisplayNothing/>
                        }
                    </div> : <DisplayNothing/>
                }
                <PopupForEmailSend
                    openModal={this.props.openOnSend}
                    onConfirmation={this.onConfirmSend}
                    closePopup={this.hidePopupOnCancel}
                    source={'case'}
                    sendCaseEmailSummary={(relativeId, data, emailTemplateType) => this.sendEmailSummary(relativeId, data, emailTemplateType)}
                />
            </React.Fragment>
        )
    }
}

CaseCallsComponent.propTypes = {
    columnDetailViewTabCollection: PropTypes.array,
};

function mapStateToProps(state) {
    return {
        selectedCaseData: state.application.selectedCaseData,
        initialValues: state.application.selectedCaseData,
        selectedDataById: state.application.selectedDataById,

        open: state.application.open,
        openOnSend: state.application.openOnSend,
        openOnSaveCase: state.application.openOnSaveCase,
        openOnOrderDrag: state.application.openOnOrderDrag,

        allUserDetailsForCases: state.application.allUserDetailsForCases,
        allRelationshipInfo: state.application.allRelationshipInfo,
        headerMenuItemClicked: state.application.headerMenuItemClicked,
        displayNothing: state.application.displayNothing,
        caseUpdated: state.application.caseUpdated,
        tempNote: state.application.tempNote,

        wsLockCaseData: state.application.wsLockCaseData,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: "CaseCallsComponent",
    validate: FormValidator({
        name: {presence: true},
    })
})(CaseCallsComponent));