import {useState} from "react";
import RouteUtil from "../../common/routeUtil";
import {getPageConfiguration} from "../utils";

const useCustomerContainer = (match, headerMenuItemClicked, dispatch) => {
    const [pageDataLoaded, setPageDataLoaded] = useState(false);
    const [componentValue, setComponentValue] = useState(undefined);

    const loadPageData = () => {
        if (match.params.id !== undefined && match.params.component !== undefined) {
            const value = RouteUtil.getChildComponentFromUrlParameters(match?.params?.component, headerMenuItemClicked)
            setComponentValue(value);
        }
        dispatch(getPageConfiguration());
        setTimeout(() => {
            setPageDataLoaded(true);
        }, 0)
    }

    const onFailure = () => {
        console.log("[DEBUG]: ValidateOrRefreshToken failed")
    }
    return {pageDataLoaded, componentValue, loadPageData, onFailure};
}

export default useCustomerContainer;