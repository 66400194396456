import AppUtil from "../../../common/appUtil";
import Popup from "../../../common/popups/popup";
import Utility from "../../../../api/utilLanguage";
import {bindActionCreators} from "redux";
import * as actionMaster from "../../../../actions/uiAction";
import * as caseAction from "../../../../actions/caseAction";
import React from "react";
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import CaseUtil from "../../../common/caseUtil";
import GuestUtil from "../../caseGuestsSubComponents/guestUtil";
import Button from "../../../common/button";

class GuestMailManager extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoadingSendGuestMail: false,
            selectedGuest: null
        };
        this.isServerEmailId = false;
    }

    componentDidMount = () => {
        const {guest, initialCaseData} = this.props || {};
        this.isServerEmailId = GuestUtil.isServerEmailId(guest, initialCaseData);
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.caseUpdated !== undefined && (nextProps.caseUpdated !== this.props.caseUpdated)) {
            const initialCaseData = nextProps.initialCaseData;
            const {guest} = nextProps || {};
            this.isServerEmailId = GuestUtil.isServerEmailId(guest, initialCaseData);
        }
    };

    /***
     * @description: Email popup
     */
    showSendGuestEmailPopup = (e, guest) => {
        e.stopPropagation();
        this.props.actionMaster.showPopupForGuestEmailAction();
        this.updateDataSourceGuest(guest);
    };

    hidePopupForGuestEmailAction = () => {
        this.updateDataSourceGuest(null);
        this.props.actionMaster.hidePopupForGuestEmailAction();
    };

    /***
     * @description: Email api
     */
    sendGuestEmail = (e) => {
        console.log(e);
        const {selectedCaseData} = this.props;
        const {selectedGuest} = this.state;

        console.log("guest.id, guest.firstName = ", selectedGuest.id, selectedGuest.firstName);
        if (AppUtil.isAvailable(selectedCaseData.id) && AppUtil.isAvailable(selectedGuest.id)) {
            this.sendGuestEmailApi(selectedCaseData.id, selectedGuest.id);
        } else {
            console.log("Failure: send guest email api");
        }
    };

    sendGuestEmailApi = (caseId, guestId) => {
        this.hidePopupForGuestEmailAction();

        this.setState({isLoadingSendGuestMail: true});
        this.props.caseAction.sendGuestMail(caseId, guestId).then((respJson) => {
            if (AppUtil.isAvailable(respJson) && respJson.success) {
                this.updateIsConfirmMailSent(true);
            }
        }).catch(error => {
            console.log("Preparation Memorial Fund for OrderType error", error);
        }).finally(() => {
            this.setState({isLoadingSendGuestMail: false});
        })
    };

    /***
     *
     * @description : Helper methods
     */
    updateIsConfirmMailSent = (value) => {
        const {guestIndexToEdit} = this.props;
        this.props.caseAction.changeGuestDetails(value, guestIndexToEdit, 'isConfirmationMailSent');
    };

    updateDataSourceGuest = (guest) => {
        this.setState({selectedGuest: guest});
    };

    buttonTitle = (guest) => {
        switch (this.props.componentIdentifier) {
            case CaseUtil.GuestComponents.guest:
                return (guest.getSendOrResendText());
            default:
                return (guest.isConfirmationMailSent ? Utility.getLang().common.emailSendInvitationAgain : Utility.getLang().common.emailSendInvitation);
        }
    };

    guestEmailPopupTitle = (guest) => {
        let title = "";
        if (AppUtil.isAvailable(guest)) {
            title = Utility.getLang().cases.guestTabContent.emailSendConfirmationTitle
                .replace("{send}", `${guest.getSendOrResendText().toLowerCase()}`)
                .replace("{guest}", `${AppUtil.isAvailable(guest.firstName) ? guest.firstName : Utility.getLang().cases.relativeTabContent.guest.toLowerCase()}`)
        } else {
            title = Utility.getLang().cases.guestTabContent.emailSendConfirmationTitle;
        }
        return title;
    };

    isDisabledGuestEmailPopupBtn = (guest, isLoadingSendGuestMail, isServerEmailId) => {
        const isServerGuestId = GuestUtil.isServerGuestId(guest);
        return (!isServerGuestId) || isLoadingSendGuestMail || (!isServerEmailId);
    };

    render = () => {
        const {openForGuestEmailAction, guest} = this.props || {};
        const {selectedGuest, isLoadingSendGuestMail} = this.state;
        return (
            <React.Fragment>
                { AppUtil.isAvailable(selectedGuest) &&
                    <Popup
                        openModal={openForGuestEmailAction}
                        headerInfo={this.guestEmailPopupTitle(selectedGuest)}
                        onConfirmation={this.sendGuestEmail}
                        closePopup={this.hidePopupForGuestEmailAction}
                    />
                }
                {
                    <Button
                        icon="email"
                        onClick={(e) => this.showSendGuestEmailPopup(e, guest)}
                        disabled={this.isDisabledGuestEmailPopupBtn(guest, isLoadingSendGuestMail, this.isServerEmailId)}
                    >
                        {this.buttonTitle(guest)}
                    </Button>
                }
            </React.Fragment>
        );
    }
}

GuestMailManager.propTypes = {
    componentIdentifier: PropTypes.number.isRequired,
    guest: PropTypes.any.isRequired,
    guestIndexToEdit: PropTypes.number.isRequired
};

function mapStateToProps(state) {
    return {
        selectedCaseData: state.application.selectedCaseData,
        openForGuestEmailAction: state.application.openForGuestEmailAction,
        initialCaseData: state.application.selectedDataById,
        caseUpdated: state.application.caseUpdated
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(GuestMailManager);
