import React from "react";
import Utility from "../../../../../../api/utilLanguage";
import Icon from "../../../../../common/icon";

function MessageTimeStamp({message}) {
    return <div className="timestamp">
        {message.lastModifiedText}
        {message.seen &&
        <span className="messageSeen">
                <Icon type="read"/>
            </span>
        }
        <span className="editedStatus">
            {message.edited && Utility.getLang().cases.messages.messageEditedText}
        </span>
    </div>
}

export default MessageTimeStamp;