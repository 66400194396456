import React from "react";
import AppUtil from "../../appUtil";
import Icon from "../../icon";
import Select from 'react-select';
import Utility from "../../../../api/utilLanguage";

const EmailContainer = ({ordersChangedForSendMail, changeCheckStatus, relatives, handleSelectedUser, changeEmailMessage}) => (
    <React.Fragment>
        {
            AppUtil.isAvailable(ordersChangedForSendMail) && (ordersChangedForSendMail.length > 0) && ordersChangedForSendMail.map((order, index) => {
                return (
                    <React.Fragment key={order.id}>
                        {/*<tr className="tr">
                            <td className="td td--actions td--select">
                                <div className="option option--checkbox option--no_option">
                                    <input
                                        id="emailCaseOrderChange"
                                        name="emailCaseOrderChange"
                                        type="checkbox"
                                    />
                                    <label
                                        htmlFor="emailCaseOrderChange"
                                        className="option__description option__description--checkbox"
                                    >
                                    </label>
                                </div>
                            </td>
                            <td className="td">
                                <h5>
                                    <Icon type="order" small />
                                    <a
                                        href={order.id !== 0 ? AppUtil.linkPaths.order.basePath + order.id + AppUtil.linkPaths.order.details : null}
                                        className="link is-external"
                                        target="_blank"
                                    >
                                        {order.id !== 0 ? `${order.id} - ${order.orderTypeText}` : `${order.orderTypeText}`}
                                    </a>
                                </h5>
                            </td>
                        </tr>*/}

                        {/* Checked – show form */}

                        <tr className="tr">
                            <td className="td td--actions td--select">
                                <div className="option option--checkbox option--no_option">
                                    <input
                                        id={"emailCaseOrderChange" + index}
                                        //id="emailCaseOrderChange"
                                        key={order.id}
                                        name="emailCaseOrderChange"
                                        type="checkbox"
                                        checked={order.checked}
                                        onChange={(e) => changeCheckStatus(e, index)}
                                        // defaultChecked
                                    />
                                    <label
                                        htmlFor={"emailCaseOrderChange" + index}
                                        className="option__description option__description--checkbox"
                                    >
                                    </label>
                                </div>
                            </td>
                            <td className="td">
                                <h5>
                                    <Icon type="order" small />
                                    <a
                                        href={order.id !== 0 ? AppUtil.linkPaths.order.basePath + order.id + AppUtil.linkPaths.order.details : null}
                                        className="link is-external"
                                        target="_blank"
                                    >
                                        {order.id !== 0 ? `${order.id} - ${order.orderTypeText}` : `${order.orderTypeText}`}
                                    </a>
                                </h5>
                                {
                                    order.checked ? <div className="snippet">
                                        <div className="form-group">
                                            <label htmlFor={"emailCaseOrderChangeRecipient" + order.id}>
                                                {Utility.getLang().common.changeStagePopup.billingContact}
                                            </label>
                                            <Select
                                                inputId={"emailCaseOrderChangeRecipient" + order.id}
                                                getOptionLabel={(option) => AppUtil.concatenateFullName(option)}
                                                getOptionValue={({id}) => id}
                                                options={relatives}
                                                formatCreateLabel=""
                                                className={"multiselect"}
                                                classNamePrefix={"multiselect"}
                                                placeholder={Utility.getLang().common.changeStagePopup.billingContact}
                                                onChange={(option) => handleSelectedUser(option, index)}
                                                value={order.selectedBillingContact}
                                            />
                                        </div>
                                        <div className="form-group">
                                        <textarea
                                            id={"emailCaseOrderChangeRecipient" + order.id}
                                            cols="40"
                                            rows="5"
                                            className="textarea textarea--max_width textarea--short"
                                            placeholder={Utility.getLang().common.messageOptional}
                                            onBlur={(e) => changeEmailMessage(e, index)}
                                        />
                                        </div>
                                    </div> : null
                                }

                            </td>
                        </tr>
                    </React.Fragment>
                )
            })
        }
    </React.Fragment>
);

export default EmailContainer;
