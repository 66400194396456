import React from 'react';
import Utility from "../../../../api/utilLanguage";
import {PdfViewer} from "./pdfViewer";
import AppLoader from "../documentUtil/appLoader";
import AppUtil from "../../../common/appUtil";
import {FileFormatType, PreviewFileFormat} from "./util";

export class DocumentPreviewContent extends React.Component {

    constructor(props) {
        super(props);
        this.initializeUnknownPreviewFormatInstance();
    }

    componentDidMount() {
        this.setup();
    }

    setup = () => {
        this.updateUnknownPreviewMessage(Utility.getLang().common.document.loader.docFetchingFailure, this.props.responseBase64Document);
    };

    initializeUnknownPreviewFormatInstance = () => {
        this.onRef = ref => this.appLoader = ref;
    };

    updateUnknownPreviewMessage = (message, responseBase64Document) => {
        if ((AppUtil.isAvailable(responseBase64Document) && AppUtil.isAvailable(responseBase64Document.contentType)) && PreviewFileFormat(responseBase64Document.contentType) === FileFormatType.unknown) {
            this.appLoader.showMessage(message);
        }
    };
    
    /***
     * @description: isResponseAvailable helper
     * @param responseBase64Document
     * @returns {*}
     */
    isResponseAvailable = (responseBase64Document) => {
        return AppUtil.isAvailable(responseBase64Document) && AppUtil.isAvailable(responseBase64Document.contentType);
    };

    render() {
        const {isLoading, responseBase64Document} = this.props;

        switch (this.isResponseAvailable(responseBase64Document) && PreviewFileFormat(responseBase64Document.contentType)) {
            case FileFormatType.image:
                return (
                    <div className={AppUtil.getClassName("file_preview__content", isLoading)}>
                        <div className="file_preview__page file_preview__page--image">
                            <img
                                src={responseBase64Document.base64Content}
                                alt={Utility.getLang().common.document.loader.docPreview}
                            />
                        </div>
                    </div>
                );
            case FileFormatType.pdf:
                return (
                    <PdfViewer
                        fileSource={responseBase64Document.base64Content}
                        hasScaling
                        isLoading={isLoading}
                    />
                );
            default:
                return (
                    <React.Fragment>
                        <div className={AppUtil.getClassName("file_preview__content", isLoading)}>
                            <AppLoader onRef={this.onRef}/>
                        </div>
                    </React.Fragment>
                );
        }
    }
};

DocumentPreviewContent.defaultProps = {
    isLoading: false
};
