import React from "react";
import {connect} from "react-redux";
import Utility from "../../../api/utilLanguage";
import AsyncSelect from "react-select/lib/Async";
import LabelComponent, {LabelType} from "../../common/labelsControl";
import OrderUtil from "../../common/orderUtil";
import billService from "../../../api/billService";
import NPSSurveyList from "../../common/nps/npsSurveyList";
import AppUtil from "../../common/appUtil";
import Permalink from "../../common/link/permalink";
import {bindActionCreators} from "redux";
import * as caseAction from "../../../actions/caseAction";
import DateUtilExt, {TimestampFormat} from "../../common/dateUtil/dateUtilExt";
import BillingContactComponent from "./billingContact";
import RelativeUtil from "../relative/relativeUtil";
import PauseInvoiceContainer from "./pauseInvoiceContainer";

class HeaderAndStageSection extends React.Component {

    constructor(props) {
        super(props);
        this.selectedDate = "";
        this.orderStagesToShow = [];
        this.disableDatePicker = true;
        this.timePeriod = [
            {id: 1, name: "10"},
            {id: 2, name: "30"},
            {id: 3, name: Utility.getLang().orders.invoice.custom}
        ];
        this.state = {
            billingContacts: [],
        };
    }

    updateBillingContacts(contacts) {
        this.setState({billingContacts: RelativeUtil.getBillingContacts(contacts)});
    }

    componentDidMount = () => {
        const {billingData} = this.props;
        if (AppUtil.isAvailable(billingData)) {
            this.updateBillingContacts(billingData);
        }
        if (this.props.orderOriginalStage !== undefined) {
            this.props.orderStatusData.forEach((status) => {
                status.disabled = true;
                if (status.key === this.props.orderOriginalStage) {
                    status.disabled = false;
                    this.orderStagesToShow.push(status);
                } else {
                    this.orderStagesToShow.push(status);
                }
            });
            let columnsToCheck = billService.getBillPageColumnsConfiguration(this.props.selectedOrderData.disableInvoiceStage).columns;
            for (let i = 0; i < columnsToCheck.length; i++) {
                let values = columnsToCheck[i].draganddrop.drop.objectValues;
                let stageValues = columnsToCheck[i].draganddrop.drop.action.value;
                if (values.includes(this.props.orderOriginalStage)) {
                    this.orderStagesToShow.forEach((status) => {
                        if (status.key === stageValues) {
                            status.disabled = false;
                            //this.orderStagesToShow.push(status);
                        }
                    });
                }
            }

            console.log(" this.orderStagesToShow", this.orderStagesToShow);
        }
    };

    changeStageForOrder = (e) => {
        this.props.changeStageForOrder(e.target.value);
    };

    changeTimePeriodOrCreateCustom = (e, salesId) => {
        console.log("e", e.target.value, salesId)
        if (e.target.value !== Utility.getLang().orders.invoice.custom) {
            this.disableDatePicker = true;
            this.props.setTimePeriod(e.target.value, salesId)
        } else {
            this.disableDatePicker = false;
            this.forceUpdate()
        }
    };

    getInvoiceDateAndDueDate = (invoiceDateText, expiryDateText, dueDateText) => {
        console.log("invoiceDate, expiryDate", invoiceDateText, expiryDateText);
        let invoiceDateInfo = AppUtil.isAvailable(invoiceDateText) ? invoiceDateText : Utility.getLang().orders.invoice.notInvoicedYet;
        let expiryDateInfo = AppUtil.isAvailable(expiryDateText) ? expiryDateText : Utility.getLang().orders.invoice.notSetYet;
        return <div className="form-group form-group--columns">
            <div className="form-group__column">
                <label htmlFor="orderDateInvoice">
                    {Utility.getLang().orders.invoice.invoiceDate}
                </label>
                <p className="static static--field">{invoiceDateInfo}</p>
                <p className="help_text">{Utility.getLang().orders.invoice.invoiceHelpText}</p>
            </div>
            <div className="form-group__column">
                <label htmlFor="orderDateInvoiceExpiry">
                    {Utility.getLang().orders.invoice.dueDate}
                </label>
                <p className="static static--field">{expiryDateInfo}</p>
                <p className="help_text">{dueDateText}</p>
            </div>
        </div>
    };

    changeInvoiceStatus = (salesOrder) => {
        if (salesOrder.status === OrderUtil.orderBillingStatus.active.id) {
            this.props.changeInvoiceStatus(salesOrder, OrderUtil.orderBillingStatus.paused.id);
        } else {
            this.props.changeInvoiceStatus(salesOrder, OrderUtil.orderBillingStatus.active.id);
        }
    };

    /**
     *@description: Get all label details
     * @param inputValue
     * @returns {Promise<Array|*>}
     */
    loadLabelOptions = async (inputValue) => {
        if (inputValue) {
            await this.props.caseAction.getLabelDetails(LabelType.order, inputValue).then(responseData => {
            }).catch(error => {
                console.log("getLabelDetails LabelType.order = ", error);
            });
            return this.props.labelsOptions;
        } else {
            return [];
        }
    };


    render() {
        const {selectedBillingContact} = this.props;
        const {billingContacts} = this.state;
        const oldOrder = Object.assign({stage: this.props.orderOriginalStage, salesOrders: this.props.salesOrders});
        const newOrder = Object.assign({stage: this.props.orderStage});
        return (
            <React.Fragment>
                {this.props.functionSource !== 'case' ?
                    <div className="section__columns">
                        <div className="section__column">
                            {AppUtil.isAvailable(this.props.npsData) && <NPSSurveyList npsData={this.props.npsData}/>}
                            <div className="form-group form-group--columns">
                                <div className="form-group__column">
                                    <div className="form-group">
                                        <label htmlFor="#">
                                            {Utility.getLang().cases.orderTabContent.funeralCaseOrderContent.case}
                                            &nbsp;
                                            <Permalink
                                                id={this.props.initialCaseData[0] !== undefined && this.props.initialCaseData[0].id !== undefined ? this.props.initialCaseData[0].id : null}
                                                path1={AppUtil.linkPaths.case.basePath}
                                                path2={AppUtil.linkPaths.case.details}
                                            />
                                        </label>

                                        <AsyncSelect
                                            getOptionLabel={({deceasedName}) => deceasedName}
                                            getOptionValue={({id}) => id}
                                            loadOptions={this.props.promiseOptionForOrderOnly}
                                            onChange={(option) => this.props.caseChange(option)}
                                            defaultOptions
                                            className={"multiselect"}
                                            classNamePrefix={"multiselect"}
                                            value={this.props.initialCaseData}
                                            isDisabled={(this.props.selectedStage === OrderUtil.orderStages.archived.value) || (this.props.selectedStage === OrderUtil.orderStages.deleted.value) ? true : false}
                                            placeholder={Utility.getLang().common.dropDownOptionTypeToSearch}
                                            noOptionsMessage={() => Utility.getLang().common.noOptionMessage}
                                            loadingMessage={() => Utility.getLang().common.dropDownOptionLoadingMessage}
                                        />
                                    </div>
                                </div>
                            </div>
                            <h4 className="section__title">
                                {Utility.getLang().orders.invoice.billing}
                            </h4>
                            <BillingContactComponent
                                contacts={billingContacts}
                                source={'case'}
                                orderStage={this.props.selectedStage}
                                changeDropdownItemForBillingContact={this.props.changeDropdownItemInfo}
                                selectedItem={AppUtil.isAvailable(selectedBillingContact) ? selectedBillingContact : ""}
                                oldOrder={oldOrder}
                                order={newOrder}
                            />
                            {this.props.selectedBillingContact !== null && this.props.selectedBillingContact !== undefined ?
                                this.props.salesOrders !== null && this.props.salesOrders !== undefined && this.props.salesOrders.length > 0 ?
                                    this.props.salesOrders.map((salesOrder, i) => {
                                        console.log("salesOrder", salesOrder);
                                        {
                                            let pausedUntilDate = AppUtil.isAvailable(salesOrder.pausedUntilDateText) ? salesOrder.pausedUntilDateText : null;
                                            {
                                                return ((salesOrder.status === OrderUtil.salesOrderStatus.active) || (salesOrder.status === OrderUtil.salesOrderStatus.paused))
                                                && (salesOrder.deliveryMethod <= OrderUtil.deliveryMethods.efaktura.value)
                                                    ? (
                                                        <React.Fragment key={i}>
                                                            {salesOrder.bcSalesInvoiceId !== undefined && salesOrder.bcSalesInvoiceId !== null ?
                                                                <p>{Utility.getLang().orders.invoice.invoiceSentBy}</p>
                                                                : null}
                                                            {OrderUtil.orderStages.invoice.value === this.props.orderStage ? this.getInvoiceDateAndDueDate(salesOrder.invoiceDateText, salesOrder.expiryDateText, salesOrder.invoiceDueDateInformationText) : null}
                                                            {OrderUtil.orderStages.invoice.value === this.props.orderStage || OrderUtil.orderStages.archived.value === this.props.orderStage
                                                                ? <div className="form-group">
                                                                    <PauseInvoiceContainer
                                                                        onChangeTimePeriod={(e) => this.changeTimePeriodOrCreateCustom(e, salesOrder.id)}
                                                                        orderStage={this.props.orderStage}
                                                                        timePeriods={this.timePeriod}
                                                                        pausedInvoiceDate={this.getPausedInvoiceDate(salesOrder)}
                                                                        onDateChange={(e) => this.props.dateChange(e, salesOrder)}
                                                                        disableDatePicker={this.disableDatePicker}
                                                                        onClickChangeInvoiceStatus={(e) => this.changeInvoiceStatus(salesOrder)}
                                                                        salesOrder={salesOrder}
                                                                        pausedUntilDate={pausedUntilDate}
                                                                    />
                                                                </div>
                                                                : null
                                                            }
                                                        </React.Fragment>
                                                    )
                                                    : null
                                            }

                                        }
                                    })
                                    : null
                                : null}
                        </div>
                        <div className="section__column section__column--small">
                            <div className="form-group">
                                <label htmlFor="orderStatus">
                                    {Utility.getLang().cases.orderTabContent.funeralCaseOrderContent.status}
                                </label>
                                <select
                                    id="orderStatus"
                                    name="orderStatus"
                                    className="dropdown dropdown--type"
                                    value={this.props.selectedStage}
                                    onChange={this.changeStageForOrder}
                                    disabled={this.props.disableOrderStage}
                                >
                                    {this.orderStagesToShow !== undefined && this.orderStagesToShow !== null ?
                                        this.orderStagesToShow.map((stage, i) => {
                                            return (
                                                <option
                                                    key={i}
                                                    value={stage.key}
                                                    disabled={stage.disabled}
                                                >
                                                    {stage.value}
                                                </option>
                                            );
                                        })
                                        : null}
                                </select>
                            </div>
                            <div className="form-group">
                                <label>
                                    {Utility.getLang().common.type}
                                </label>
                                <p>
                                    {this.props.orderTypeText}
                                </p>
                            </div>
                            <LabelComponent
                                data={this.props.labelsData}
                                loadOptions={this.loadLabelOptions}
                                handleChangeForlabel={this.props.handleChangeForlabel}
                                removeLabel={this.props.removeLabel}
                            />
                            {this.props.bcId !== undefined && this.props.bcId !== null ?
                                <div className="form-group form-group--static">
                                    <h5 className="title">
                                        {Utility.getLang().common.microsoftNavId}
                                    </h5>
                                    <p className="description">
                                        <a target="_blank">
                                            {this.props.bcId}
                                        </a>
                                    </p>
                                </div>
                                : null}
                            {this.props.salesOrders !== undefined && this.props.salesOrders !== null && this.props.salesOrders.length > 0 ?
                                this.props.salesOrders.map((orders, i) => {
                                    return orders.status === 1 || orders.status === 2 ? (
                                        <div className="form-group form-group--static" key={i}>
                                            <h5 className="title">{Utility.getLang().common.microsoftNavId}</h5>
                                            <p className="description">
                                                <a target="_blank">
                                                    {orders.bcSalesOrderId} {orders.bcSalesInvoiceId !== null ? " (" + orders.bcSalesInvoiceId + ")" : ""}
                                                </a>
                                            </p>
                                        </div>
                                    ) : null
                                })
                                : null
                            }
                        </div>
                    </div>
                    :
                    <div className="btn-toolbar">
                        <div className="form-group">
                            <div className="field">
                                <label htmlFor="orderStatus">
                                    {Utility.getLang().cases.orderTabContent.funeralCaseOrderContent.status}
                                </label>
                                <select
                                    id="orderStatus"
                                    name="orderStatus"
                                    className="dropdown dropdown--type"
                                    value={this.props.selectedStage}
                                    onChange={this.changeStageForOrder}
                                    disabled={this.props.disableOrderStage}
                                >
                                    {
                                        this.orderStagesToShow !== undefined && this.orderStagesToShow !== null ?
                                            this.orderStagesToShow.map((stage, i) => {
                                                return (
                                                    <option
                                                        key={i}
                                                        value={stage.key}
                                                        disabled={stage.disabled}
                                                    >
                                                        {stage.value}
                                                    </option>
                                                );
                                            })
                                            : null
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }

    getPausedInvoiceDate(salesOrders) {
        if (AppUtil.isAvailable(salesOrders.nextDate)) {
            return DateUtilExt.pickerDate(salesOrders.nextDate);
        } else {
            const daysToAdd = 10;
            const dateStr = AppUtil.isAvailable(salesOrders.expiryDate) ? salesOrders.expiryDate : DateUtilExt.nowDateString();
            return DateUtilExt.dateByAddingDays(daysToAdd, dateStr, TimestampFormat.DATE);
        }
    }
}

function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {caseAction: bindActionCreators(caseAction, dispatch)};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderAndStageSection);
