import React from "react";
import {Lang} from "../../common/appUtil";
import connect from "react-redux/es/connect/connect";
import {useCustomerProfile} from "./hooks/useCustomerProfile";
import Icon from "../../common/icon";
import FooterWithSave from "../../common/footer";
import {ProfileContent} from "./utils/profileContent"
import CustomerProfileChangeListener from "./utils/customerProfileChangeListener";

export function ProfileHeader({title}) {
    return <div className="section__head">
        <div className="inner">
            <h3 className="section__title">
                <Icon type="contact"/>
                <span className="description">{title}</span>
            </h3>
        </div>
    </div>;
}

function CustomProfile(props) {

    const {profile, initialProfile, counties, countries, dispatch} = props;

    const {
        debounceOnTextChange,
        checkKeysOnCommon,
        onChangePersonNumber,
        updateCustomerProfile,
        disableSave,
        onSelectChange,
        getPersonNumberDetailsButton,
        loadingPersonalNumber,
        isLoadingEditProfile
    } = useCustomerProfile(dispatch, profile, initialProfile);
    return (<>
            <CustomerProfileChangeListener customerProfile={profile} initialCustomerProfile={initialProfile}/>
            <div className="section section--detail section--scroll">
                <ProfileHeader title={profile?.name}/>
                <div className="section__content section__content--call_details">
                    <div className="inner">
                        <ProfileContent
                            profile={profile}
                            counties={counties}
                            countries={countries}
                            onTextChange={debounceOnTextChange}
                            checkKeysOnCommon={checkKeysOnCommon}
                            onChangePersonNumber={onChangePersonNumber}
                            onSelectChange={onSelectChange}
                            getPersonNumberDetailsButton={getPersonNumberDetailsButton}
                            loadingPersonalNumber={loadingPersonalNumber}
                            isLoading={isLoadingEditProfile}
                        />
                    </div>
                </div>
            </div>
            <FooterWithSave
                buttonText={Lang().common.save}
                hideRelativeDropdown={true}
                hideDeleteButton={true}
                disableSaveButton={disableSave}
                SaveData={() => updateCustomerProfile(profile.id, profile)}
            />
        </>
    );
}

function mapStateToProps(state) {
    return {
        countries: state.application.countries,
        counties: state.application.counties,
        profile: state.profileReducer.customerProfile,
        initialProfile: state.profileReducer.initialCustomerProfile
    };
}

export default connect(mapStateToProps)(CustomProfile);
