import {useCallback, useState} from "react";
import {hideExistingProbateCasePopup, showExistingProbateCasePopup} from "../../../redux/probateAction";
import {connectExistingProbateCases} from "../../../api";
import AppUtil from "../../../../../common/appUtil";
import {updateCaseProbateInfo} from "../../../../../../actions/caseAction";

const useConnectExistingProbateCasePopup = (sourceCaseId, dispatch) => {
    const [isLoadingConnectExistingProbateCases, setIsLoadingConnectExistingProbateCases] = useState(false);
    const showPopup = useCallback(() => {
        dispatch(showExistingProbateCasePopup());
    }, []);

    const connectExistingProbateCasesApiCall = (caseId, probateCaseId) => {
        setIsLoadingConnectExistingProbateCases(true);
        connectExistingProbateCases(caseId, probateCaseId).then((resp) => {
            if (resp.status === 200 && AppUtil.isAvailable(resp.data) && resp.data.success) {
                const dataObj = resp.data.object;
                dispatch(updateCaseProbateInfo(dataObj.probate));
            }
        }).finally(() => {
            setIsLoadingConnectExistingProbateCases(false);
        })
    };

    const onPopupConfirmation = useCallback((caseId, probateCaseId) => {
        connectExistingProbateCasesApiCall(caseId, probateCaseId);
        hidePopup();
    }, []);

    const hidePopup = () => {
        dispatch(hideExistingProbateCasePopup());
    };

    return {
        showPopup,
        onPopupConfirmation,
        isLoadingConnectExistingProbateCases,
        hidePopup
    }
}
export default useConnectExistingProbateCasePopup