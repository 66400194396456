import React from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import Icon from "../common/icon";
import Permalink from "../common/link/permalink";
import AppUtil from "../common/appUtil";

class LocationColumnItemCard extends React.Component {

    onSelected = () => {
        this.props.onCardSelected(this.props.card);
    };

    render = () => {
        return (
            <div className="item__spacer">
                <div
                    className={`item item--button item--location ${this.props.isActive === true ? 'is-active' : ''}`}
                    tabIndex="0"
                    onClick={this.onSelected}
                >
                    <span className="item__image">
                        <img
                            src={this.props.card.locationMedias !== undefined && this.props.card.locationMedias !== null ? this.props.card.locationMedias.awsUrl + this.props.card.locationMedias.thumbName : null}
                            className={'image_ratio'}
                            height="40"
                            width="40"
                            alt={this.props.card.locationMedias !== undefined && this.props.card.locationMedias !== null ? this.props.card.locationMedias.altText : ""}
                        />
                    </span>
                    <h4 className="item__meta item__title item__meta--title">
                        <Icon type="location" tiny />
                        <span className="description">
                            {this.props.card.name !== undefined ? this.props.card.name : "........"}
                        </span>
                        <a className="detail detail--id">
                            <Permalink id={this.props.card.id} path1={AppUtil.linkPaths.location.basePath} path2={AppUtil.linkPaths.location.details}/>
                        </a>
                    </h4>
                    <div className="item__footer">
                        <div className="item__tags">
                            <span className="tags tags--types tags--small">
                                <span className="tag tag--type">
                                    {this.props.card.typeName !== undefined ? this.props.card.typeName : "........"}
                                </span>
                            </span>
                            <span className="tags tags--linked tags--locations tags--small">
                                <span className="tag tag--linked tag--location">
                                    <Icon type="location" tiny />
                                    {AppUtil.isAvailable(this.props.card.contactAddress) && AppUtil.isAvailable(this.props.card.contactAddress.placeName) ? this.props.card.contactAddress.placeName : "........"}
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

LocationColumnItemCard.propTypes = {
    card: PropTypes.object,
    onCardSelected: PropTypes.func,
    isActive: PropTypes.bool
};

function mapStateToProps(state, ownProps) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationColumnItemCard);
