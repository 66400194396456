import React from "react";
import PropTypes from "prop-types";
import CustomPopup from "../../case/probates/ui/documentHandlerPopup/ui/customPopup";

const AppPopup = ({
                      title,
                      content,
                      show,
                      confirmationButtonTitle,
                      onConfirmation,
                      disableConfirmButton,
                      cancelButtonTitle = undefined,
                      onCancel,
                      children,
                      modalClassName,
                      hidePopup
                  }) => {

    /***
     * @description: hide and control back to callee
     */
    const onCloseAction = () => {
        hidePopup();
        if ((onCancel !== undefined) && (onCancel !== null)) {
            onCancel();
        }
    }

    /***
     * @description: hide and control back to callee
     */
    const onConfirmationAction = () => {
        hidePopup();
        if ((onConfirmation !== undefined) && (onConfirmation !== null)) {
            onConfirmation();
        }
    };

    return <CustomPopup openModal={show}
                        title={title}
                        headerInfo={content}
                        valueOk={confirmationButtonTitle}
                        valueCancel={cancelButtonTitle}
                        onConfirmation={onConfirmationAction}
                        closePopup={onCloseAction}
                        modalClassName={modalClassName}
                        disableConfirmButton={disableConfirmButton}
    >
        {children}
    </CustomPopup>
}

AppPopup.defaultProps = {
    title: "",
    content: "",
    disableConfirmButton: false,
}

AppPopup.propTypes = {
    show: PropTypes.bool.isRequired,
    onConfirmation: PropTypes.func.isRequired,
    hidePopup: PropTypes.func.isRequired
}
export default AppPopup;