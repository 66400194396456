import {
    resetProbateBeneficiaries,
    showQuestionnaireProbatePopup,
    updateProbateRootProperty
} from "../../../redux/probateAction";
import {useCallback} from "react";
import {ProbateProps} from "../../tabProbateMeeting/util";
import {cloneDeep} from "lodash";

export function useQuestionnaireProbatePopup(onSaveClick, dispatch) {
    const showPopup = useCallback(() => {
        //Enable popup flag
        dispatch(showQuestionnaireProbatePopup());
    }, []);

    /**
     * @description: Update properties only after save button press
     */
    const delaySaveClickInMs = 1000;

    /**
     * @description; Update probateInfo.beneficiaries and
     * - Send only valid beneficiaries to server
     */
    const updateProbateBeneficiaries = (beneficiaries) => {
        if (beneficiaries && beneficiaries.length > 0) {
            const beneficiariesCopy = cloneDeep(beneficiaries);
            const validBeneficiaries = beneficiariesCopy.filter(beneficiary => beneficiary.isValid === true);
            dispatch(updateProbateRootProperty(ProbateProps.beneficiaries, validBeneficiaries));
        }
    };

    const onPopupConfirmation = useCallback((probateQAs, beneficiaries) => {
        updateProbateBeneficiaries(beneficiaries);
        dispatch(updateProbateRootProperty(ProbateProps.probateQAs, probateQAs));
        setTimeout(() => {
            onSaveClick();
        }, delaySaveClickInMs);
    }, []);
    /**
     * @description: 1. Reset with initial ProbateQAs ie: ProbateQAs.questionnaireBeneficiaryIds,
     * 2. Reset probateInfo.beneficiaries
     */
    const onPopupCancel = useCallback((isProbateQAsChanged, probateQAs, initializeFunc, initialBeneficiaries) => {
        if (isProbateQAsChanged) {
            dispatch(resetProbateBeneficiaries(initialBeneficiaries))
            initializeFunc(probateQAs);
        }
    }, []);

    return {
        showPopup,
        onPopupConfirmation,
        onPopupCancel
    }
}