import React from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import {bindActionCreators} from "redux";
import * as actionMaster from "../../actions/uiAction";
import * as caseAction from "../../actions/caseAction";
import * as callAction from "../../actions/callAction";
import {reduxForm} from "redux-form";
import {withRouter} from "react-router-dom";
import Icon from "../common/icon";
import Utility from "../../api/utilLanguage";
import Permalink from "../common/link/permalink";
import Popup from "../common/popups/popup";
import FooterWithSave from "../common/footer";
import DisplayNothing from "../common/nothingToDisplay";
import LogDisplay from "../common/logDisplay";
import CallColumnUtil from "../common/callColumnUtil";
import AppUtil from "../common/appUtil";

class CallLogComponent extends React.Component {

    constructor(props) {
        super(props);
        this.disableSave = false;
        this.state = {
            disableSave: false
        }
    }

    componentDidMount = () => {
        this.props.callAction.getCalllogs(this.props.selectedCallData.id);
    };

    componentDidUpdate = () => {
        this.shouldShowConfirmationAlert();
    };

    shouldShowConfirmationAlert = () => {
        if (this.props.selectedDataById.id !== undefined && this.props.selectedCallData.id !== undefined) {
            const initialDataStr = JSON.stringify(this.props.selectedDataById);
            const newDataStr = JSON.stringify(this.props.selectedCallData);
            (initialDataStr === newDataStr) ? this.props.actionMaster.hideUnsavedPopupLazily() : this.props.actionMaster.showUnsavedPopupLazily();
        }
        if (AppUtil.isAvailable(this.props.tempNote)) {
            this.props.actionMaster.showUnsavedPopupLazily();
        }
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.callUpdated !== undefined && (nextProps.callUpdated !== this.props.callUpdated)) {
            if (nextProps.callUpdated === true) {
                this.disableSave = false;
                this.setState({disableSave: false});
            }
        }
    };

    currentRoute = () => {
        this.props.actionMaster.onHeaderMenuItemClicked(this.props.history.location.pathname);
        this.props.actionMaster.closePopupWithSameRoute();
    };

    toMoveNewRoute = () => {
        this.props.actionMaster.clearRouteCondition();
        if (this.props.headerMenuItemClicked !== this.props.history.location.pathname) {
            this.props.history.push(this.props.headerMenuItemClicked);
        }
    };

    onDeleteClick = () => {
        this.props.actionMaster.showPopup();
    };

    hidePopup = () => {
        this.props.actionMaster.hidePopupSection();
    };

    onDelete = () => {
        this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isDeleting);
        this.props.callAction.deleteCallDataApi(this.props.selectedCallData, this.props.storingKey);
        setTimeout(function () {
            this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
        }.bind(this), 1000);
        this.hidePopup();
    };

    onSaveCall = () => {
        this.disableSave = true;
        this.props.callAction.updateCallUpdatingStatus(false);
        this.setState({disableSave: true});
        let data = {};
        data = this.props.selectedCallData;
        this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.isUpdating);
        setTimeout(function () {
            data = CallColumnUtil.getUpdatedNotes(this.props.tempNote, data);
            this.props.callAction.saveCallDataApi(data, this.props.storingKey);

            setTimeout(function () {
                this.props.caseAction.loadLoadingView(AppUtil.loadingStatus.finishedLoading);
            }.bind(this), 1000)
        }.bind(this), 0);
    };

    render = () => {
        return (
            <React.Fragment>
                <Popup openModal={this.props.showUnsavedPopup}
                       headerInfo={Utility.getLang().infoMessages.popup.unsavedChanges}
                       onConfirmation={this.toMoveNewRoute}
                       closePopup={this.currentRoute}/>
                {
                    this.props.displayNothing === false ?
                        <div className="section section--detail section--scroll" ref={node => this.node = node}>
                            <div className="section__head">
                                <div className="inner">
                                    <h3 className="section__title">
                                        <Icon type="call"/>
                                        <span className="description">
                                    {
                                        this.props.selectedCallData.labels !== undefined ?
                                            this.props.selectedCallData.labels.map((label, i) => {
                                                var capitalizedLabelTitle = label.title.charAt(0).toUpperCase() + label.title.slice(1);
                                                return i === 0 ? capitalizedLabelTitle : ", " + capitalizedLabelTitle;
                                            }) : null
                                    }
                                </span>
                                        <Permalink id={this.props.selectedCallData.id}
                                                   path1={AppUtil.linkPaths.call.basePath}
                                                   path2={AppUtil.linkPaths.call.details}/>
                                    </h3>
                                </div>
                            </div>
                            {
                                this.props.selectedCallData !== undefined && this.props.selectedCallData.id !== undefined ?
                                    this.props.callDetailsLogs !== null && this.props.callDetailsLogs !== undefined && this.props.callDetailsLogs.length > 0 ?
                                        <LogDisplay logData={this.props.callDetailsLogs}/>
                                        : <DisplayNothing/>
                                    : <DisplayNothing/>
                            }
                        </div> : <DisplayNothing/>
                }

                <Popup
                    openModal={this.props.open}
                    headerInfo={Utility.getLang().infoMessages.popup.deleteCall + ` ` + (this.props.selectedCallData.id !== undefined ? this.props.selectedCallData.id : "---") + `?`}
                    onConfirmation={this.onDelete}
                    closePopup={this.hidePopup}
                    isDestructive
                    withTimeOut
                />
                <FooterWithSave SaveData={this.onSaveCall}
                                hideFooter={this.props.displayNothing}
                                buttonText={Utility.getLang().calls.footer.save}
                                disableSaveButton={this.disableSave}
                                deleteButtonText={Utility.getLang().calls.footer.delete}
                                deleteCase={this.onDeleteClick}
                                hideRelativeDropdown={true}/>
            </React.Fragment>
        )
    }
}

CallLogComponent.propTypes = {
    columnDetailViewTabCollection: PropTypes.array,
};

function mapStateToProps(state) {
    return {
        selectedCallData: state.application.selectedCallData,
        selectedDataById: state.application.selectedDataById,
        initialValues: state.application.selectedCallData,
        caseAllLabels: state.application.caseAllLabels,
        allUserDetailsForCases: state.application.allUserDetailsForCases,
        callDetailsLogs: state.application.callDetailsLogs,
        open: state.application.open,
        showUnsavedPopup: state.application.showUnsavedPopup,
        headerMenuItemClicked: state.application.headerMenuItemClicked,
        displayNothing: state.application.displayNothing,
        callUpdated: state.application.callUpdated,
        tempNote: state.application.tempNote
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        callAction: bindActionCreators(callAction, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: "CallLogComponent"
})(withRouter(CallLogComponent)));