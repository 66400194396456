import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as dashboardAction from '../../../actions/dashboardAction';
import * as actionMaster from '../../../actions/uiAction';
import * as caseAction from '../../../actions/caseAction';
import * as orderAction from '../../../actions/orderAction';
import Modal from "react-responsive-modal";
import Select from 'react-select';
import Utility from "../../../api/utilLanguage";
import {bottomCenter} from "../customToastr";
import CaseUtil from "../caseUtil";
import OrderUtil from "../orderUtil";
import Icon from "../icon";
import Button from "../button";
import CaseDraggingUtil from "../caseDraggingUtil";
import BillingContactForPopup from "../../case/billingContactForPopup";
import billService from "../../../api/billService"
import AppUtil, {getMessage, Lang} from "../appUtil";
import OrderDraggingUtil from "../orderDraggingUtil";
import {UserUtil} from '../../user/userUtil';
import * as billViewAction from "../../../actions/billViewAction";
import SharedCase from "../../case/shared/sharedCase";
import {showToastErrorMessage} from "../toastify/toastify";
import County from "../county";
import ValidationAndVisibilityRule, {isFieldEnabled} from "../../common/ValidationAndVisibilityRule";
import RelativeUtil from "../../case/relative/relativeUtil";

class PopupForStageChange extends React.Component {

    constructor(props) {
        super(props);
        this.destinationName = null;
        this.assignedId = null;
        this.assignedUser = null;
        this.destinationId = null;
        this.previousStateOfOrder = null;
        this.typeOfPopup = null;
        this.enableConfirmation = false;
        this.enableConfirmationWithOrders = false;
        this.orderEmailWillBeSend = false;
        this.allUsersByRole = [];
        this.orderStagesToShow = [];
        this.orderData = [];
        this.allRelativesOfCase = [];
        this.ownerRequiredCss = "";
        this.nonSalesExternalOwnerRequired = false;
        this.stages = CaseUtil.getFlatCaseStages();
        this.primaryContact = null;
        this.countyId = null;
        this.state = {
            defaultUser: null,
            typeOfPopup: null,
            orderChange: false,
            disablePopUpOnClick: false,
            selectedLossOrNoCaseReason: null
        }
    }

    UNSAFE_componentWillMount = () => {
        if ((this.props.counties.length === 0) && isFieldEnabled(ValidationAndVisibilityRule.common, ValidationAndVisibilityRule.county)) {
            const countyParentId = RelativeUtil.getCountyParentId();
            this.props.caseAction.getAllCounties(countyParentId);
        }
        this.props.caseAction.getAllUserDetails();
        this.props.orderAction.getOrderStagesApi().then().finally(() => {
            this.refreshVisiblePage();
        });
        this.props.caseAction.clearOwnerAndLossReason();

        this.getLostOrNoCaseReasonsApiCall(this.props.destinationId);
    };

    refreshVisiblePage() {
        if (this.props.headerMenuItemClicked === AppUtil.linkPaths.bill.pathToRoute) {
            this.props.billViewAction.getPageConfiguration();
        }
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {

        /*if (nextProps.destinationId !== null && (this.props.destinationId !== nextProps.destinationId)) {
            if(countCheck === 0){
                countCheck++;
                this.checkStageName(nextProps.destinationId, nextProps.assignedId, nextProps.sourceId);
                setTimeout(function () {
                    countCheck = 0;
                }, 0);
            }

        }*/
        if (nextProps.destinationId !== null) {
            this.checkStageName(nextProps.destinationId, nextProps.assignedId, nextProps.sourceId);
        }

        if (AppUtil.isAvailable(nextProps.destinationId) && (nextProps.destinationId !== this.props.destinationId)) {
            this.getLostOrNoCaseReasonsApiCall(nextProps.destinationId);
        }

        // console.log("propsAssignedId", this.assignedId)
        if (nextProps.assignedId !== null && nextProps.assignedId !== "") {

            this.assignedId = nextProps.assignedId;
            for (let j = 0; j < this.props.allUserDetailsForCases.length; j++) {
                if (nextProps.assignedId === this.props.allUserDetailsForCases[j].id) {
                    this.assignedUser = this.props.allUserDetailsForCases[j];

                    if (CaseUtil.SalesExternalCannotAssignHimselfAsOwner(nextProps.assignedId, nextProps.sourceId, nextProps.destinationId)) {
                        this.ownerRequiredCss = "is-error";
                        this.nonSalesExternalOwnerRequired = true;
                    } else {
                        this.ownerRequiredCss = "is-success";
                        this.nonSalesExternalOwnerRequired = false;
                    }
                    break;
                }
            }
            this.setState({defaultUser: nextProps.assignedId});
        } else {
            this.assignedId = null;
            this.assignedUser = null;
            this.ownerRequiredCss = "is-error";
        }

        if (nextProps.relativesOfCase !== undefined && nextProps.relativesOfCase !== null) {
            this.allRelativesOfCase = [];
            nextProps.relativesOfCase.forEach((relative) => {
                if (relative.isBillingContact) {
                    this.allRelativesOfCase.push(relative);
                }
            })

            this.getPrimaryContactAndCounty(nextProps.relativesOfCase);
        }

        if (nextProps.ordersData !== undefined && nextProps.ordersData !== null && nextProps.ordersData.length > 0) {
            let validationCount = 0;
            this.orderData = nextProps.ordersData;
            nextProps.ordersData.forEach((order) => {
                if (order.stage === OrderUtil.orderStages.proposal.value || order.stage === OrderUtil.orderStages.order.value || order.stage === OrderUtil.orderStages.invoice.value) {
                    if (order.billingContactId === null || order.billingContactId === "") {
                        validationCount++;
                    }
                }
            });
            // console.log("validationCount", validationCount);
            if (validationCount !== 0) {
                this.enableConfirmationWithOrders = false;
            } else {
                this.enableConfirmationWithOrders = true;
            }
        }
    };

    getPrimaryContactAndCounty = (relativesOfCase) => {
        this.primaryContact = relativesOfCase.find(item => item.isPrimary === true);
        this.countyId = this.props.popupCase.countyId || this.primaryContact.address.countyId;
    }

    onCloseModal = () => {
        this.setState({defaultUser: null, selectedLossOrNoCaseReason: null});
        this.assignedId = null;
        this.assignedUser = null;
        this.destinationId = null;
        this.enableConfirmation = false;
        this.enableConfirmationWithOrders = false;
        this.orderEmailWillBeSend = false;
        this.props.closePopup();
    };

    onConfirm = () => {
        const {selectedLossOrNoCaseReason} = this.state;
        const result = SharedCase.orderHasAttributeToChangeCaseStage(this.orderData, this.destinationId);
        if (result.isValid === false) {
            showToastErrorMessage(result.message);
        } else if (this.assignedId === null) {
            bottomCenter().error(Utility.getLang().common.changeStagePopup.error.selectOwner);
        } else if ((selectedLossOrNoCaseReason === null || this.countyId === null)
            && (this.destinationId === CaseUtil.caseStages.lost.value || this.destinationId === CaseUtil.caseStages.notACase.value)) {
            bottomCenter().error((selectedLossOrNoCaseReason === null)
                ? Lang().common.changeStagePopup.error.lossReason
                : Lang().common.changeStagePopup.error.selectCounty);
        } else {
            this.setState({disablePopUpOnClick: true});
            const reason = this.getLossOrNoCaseReason(selectedLossOrNoCaseReason);
            console.log(`[DEBUG]: assignedUser = ${this.assignedUser}, selected reason =  ${reason}`);
            this.props.caseAction.setOwnerAndLossReason(this.assignedUser, reason);
            setTimeout(function () {
                this.props.onConfirmation();
                this.assignedId = null;
                this.assignedUser = null;
                this.destinationId = null;
                this.enableConfirmation = false;
                this.enableConfirmationWithOrders = false;
                this.orderEmailWillBeSend = false;
                this.setState({
                    defaultUser: null,
                    disablePopUpOnClick: false,
                    selectedLossOrNoCaseReason: null
                });
            }.bind(this), 500)
        }
    };

    changeOrderStatusOfOrder = (e, index, oldStage) => {
        console.log("orderStatusValue", e.target.value, e.target.name, index);
        this.previousStateOfOrder = oldStage;
        if (this.props.sourceType !== undefined && this.props.sourceType === 'dragAndDrop') {
            this.props.orderStatus.forEach((status, i) => {
                if (status.key === parseInt(e.target.value, 10)) {
                    this.orderData[index].stageText = status.value;
                }
            });
            this.orderData[index].stage = parseInt(e.target.value, 10);
            let dataToSend = {
                "id": this.props.caseId,
                "order": this.props.ordersData
            };
            this.checkButtonToDisplay(this.orderData, this.props.backupOrdersData);
            this.props.orderAction.setOrderDataToSendFromPopup(dataToSend, this.assignedId);
            this.forceUpdate();
            //this.setState({orderChange: true});
            console.log("Sourcetype", this.props.ordersData, this.orderData, this.props.backupOrdersData);

        } else {
            this.props.orderAction.changeStageForOrderFromPopup(e.target.value, index);
            setTimeout(function () {
                this.checkButtonToDisplay(this.props.ordersData, this.props.backupOrdersData);
            }.bind(this), 500);
        }
        setTimeout(function () {
            this.showPopupType(this.props.sourceId, this.props.destinationId);
            this.setState({orderChange: true});
        }.bind(this), 500);


    };

    changeBillingDetails = (value, index) => {
        if (this.props.sourceType !== undefined && this.props.sourceType === 'dragAndDrop') {
            this.orderData[index].billingContactId = parseInt(value, 10);
        }
    };

    checkButtonToDisplay = (orderData, oldOrderData) => {
        let count = 0;
        orderData.forEach((order, i) => {
            let checkStatus = OrderDraggingUtil.checkEmailWillBeSent(oldOrderData[i].stage, order.stage);
            if (checkStatus) {
                count++;
            }
        });
        if (count === 0) {
            this.orderEmailWillBeSend = false;
        } else {
            this.orderEmailWillBeSend = true;
        }
    };

    checkEmailWillBeSend = (newStageOrder, oldStageOrder) => {
        let checkStatus = OrderDraggingUtil.checkEmailWillBeSent(oldStageOrder.stage, newStageOrder.stage);
        if (checkStatus) {
            this.orderEmailWillBeSend = true;
            return getMessage(Utility.getLang().infoMessages.popup.emailWillBeSent);
        } else {
            return null
        }
    };

    handleSelectedUser = (selectedOption) => {
        console.log("SelectedUser", selectedOption);
        const {selectedLossOrNoCaseReason} = this.state;
        this.assignedId = selectedOption.id;
        this.assignedUser = selectedOption;
        this.props.changeUser(selectedOption);
        this.props.caseAction.setOwnerAndLossReason(this.assignedUser, this.getLossOrNoCaseReason(selectedLossOrNoCaseReason));
        if (this.typeOfPopup === CaseDraggingUtil.popupTypes.none) {
            this.enableConfirmation = true;
            this.enableConfirmationWithOrders = true;
        }
        if (this.typeOfPopup === CaseDraggingUtil.popupTypes.unknown) {
            this.enableConfirmation = true;
        }
        this.ownerRequiredCss = "is-success";
        this.setState({defaultUser: selectedOption});
    };

    showPopupType = (sourceId, destinationId) => {
        let type = CaseDraggingUtil.showPopupTypeDragging(sourceId, destinationId);
        // console.log("TypeToShow", type, this.props.backupOrdersData);
        this.typeOfPopup = type;
        if (this.props.ordersData !== undefined && this.props.ordersData !== null && this.props.ordersData.length > 0) {
            if (type === CaseDraggingUtil.popupTypes.none) {
                this.enableConfirmation = true;
                this.enableConfirmationWithOrders = true;
            } else if (type === CaseDraggingUtil.popupTypes.orderInvoiceArchived) {
                let count = 0;
                this.props.ordersData.forEach((order, i) => {
                    if ((order.stage === OrderUtil.orderStages.order.value) || (order.stage === OrderUtil.orderStages.invoice.value) || (order.stage === OrderUtil.orderStages.archived.value)) {
                        count++;
                    }
                });
                if (count !== 0) {
                    this.enableConfirmation = true;
                } else {
                    this.enableConfirmation = false;
                }
            } else if (type === CaseDraggingUtil.popupTypes.invoiceArchived) {
                let count = 0;
                this.props.ordersData.forEach((order, i) => {
                    if ((order.stage === OrderUtil.orderStages.invoice.value) || (order.stage === OrderUtil.orderStages.archived.value)) {
                        count++;
                    }
                });
                if (count !== 0) {
                    this.enableConfirmation = true;
                } else {
                    this.enableConfirmation = false;
                }
            } else if (type === CaseDraggingUtil.popupTypes.archivedDeleted) {
                let count = 0;
                this.props.ordersData.forEach((order, i) => {
                    if ((order.stage === OrderUtil.orderStages.archived.value) || (order.stage === OrderUtil.orderStages.deleted.value)) {
                        count++;
                    }
                });
                if (count === this.props.ordersData.length) {
                    this.enableConfirmation = true;
                } else {
                    this.enableConfirmation = false;
                }
            } else if (type === CaseDraggingUtil.popupTypes.invoiceArchivedDeleted) {
                let count = 0;
                this.props.ordersData.forEach((order, i) => {
                    if ((order.stage === OrderUtil.orderStages.invoice.value) || (order.stage === OrderUtil.orderStages.archived.value) || (order.stage === OrderUtil.orderStages.deleted.value)) {
                        count++;
                    }
                });
                if (count === this.props.ordersData.length) {
                    this.enableConfirmation = true;
                } else {
                    this.enableConfirmation = false;
                }

            } else if (type === CaseDraggingUtil.popupTypes.deleted) {
                let count = 0;
                this.props.ordersData.forEach((order, i) => {
                    if ((order.stage === OrderUtil.orderStages.deleted.value)) {
                        count++;
                    }
                });
                if (count === this.props.ordersData.length) {
                    this.enableConfirmation = true;
                } else {
                    this.enableConfirmation = false;
                }
            } else if (type === CaseDraggingUtil.popupTypes.unknown) {
                this.enableConfirmation = true;
            }
        } else {
            if (type === CaseDraggingUtil.popupTypes.deleted) {
                this.enableConfirmation = true;
                this.enableConfirmationWithOrders = true;
            }
        }

        if (this.props.backupOrdersData !== undefined && this.props.backupOrdersData !== null && this.props.backupOrdersData.length > 0) {
            var stageArray = [];

            function combine(orderData, stageData) {
                var result1 = Object.assign({}, orderData);
                var result2 = Object.assign({}, stageData);
                if (result1.stage === result2.key) {
                    result2['disabled'] = false;
                } else {
                    result2['disabled'] = true;
                }
                let columnsToCheck = billService.getBillPageColumnsConfiguration(orderData.disableInvoiceStage).columns;
                for (let i = 0; i < columnsToCheck.length; i++) {
                    let values = columnsToCheck[i].draganddrop.drop.objectValues;
                    let stageValues = columnsToCheck[i].draganddrop.drop.action.value;
                    if (values.includes(result1.stage)) {
                        if (result2.key === stageValues) {
                            result2['disabled'] = false;
                        }
                    }
                }
                return result2;
            }

            this.props.backupOrdersData.forEach((order, i) => {
                stageArray[i] = [];
                // eslint-disable-next-line
                this.props.orderStatus.map((stage, j) => {
                    var result3 = combine(order, stage);
                    stageArray[i].push(result3);
                });
            });


            this.orderStagesToShow = stageArray;
        }
    };

    /***
     * @description: Get old order by orderId
     */
    getOldOrder = (orderId) => {
        let oldOrderValue = {};
        if (AppUtil.isAvailable(this.props.backupOrdersData)) {
            // eslint-disable-next-line
            this.props.backupOrdersData.some((oldOrder) => {
                if (oldOrder.id === orderId) {
                    oldOrderValue = oldOrder;
                    return true
                }
            });
        }
        return oldOrderValue;
    };

    /***
     * @description: Get old order by index
     */
    getOldOrderAtIndex = (indexedAt, orders) => {
        return orders && (orders.length > indexedAt) ? orders[indexedAt] : null;
    };

    checkStageName = (stageId, assignedId, sourceId) => {
        // console.log("allUserDetailsForCases", this.props.allUserDetailsForCases, this.state.defaultUser);
        this.allUsersByRole = [];
        this.destinationId = stageId;
        this.showPopupType(sourceId, stageId);

        for (let i = 0; i < this.stages.length; i++) {
            if (stageId === this.stages[i].valueId) {
                this.destinationName = this.stages[i].text;
            }
        }

        UserUtil.updateAllUsersByRole(this.allUsersByRole, this.props.allUserDetailsForCases, this.stages, stageId);

        if (assignedId !== null) {
            // console.log("assignedId", assignedId)
            for (let j = 0; j < this.props.allUserDetailsForCases.length; j++) {
                if (assignedId === this.props.allUserDetailsForCases[j].id) {
                    this.assignedId = this.props.allUserDetailsForCases[j];
                    this.assignedUser = this.props.allUserDetailsForCases[j];
                    break;
                }
            }
        }
    };

    /***
     * @description: Loss or No case reason helper methods
     */
    getLossOrNoCaseText = (destinationId) => {
        return (destinationId === CaseUtil.caseStages.lost.value) ? Utility.getLang().common.changeStagePopup.lossReason : Utility.getLang().common.changeStagePopup.cause;
    };

    getLossOrNoCaseSelectOptions = (destinationId) => {
        const lossOrNoCase = (destinationId === CaseUtil.caseStages.lost.value) ? this.props.lostReasons : this.props.noCaseReasons;
        console.log("lossOrNoCase = ", lossOrNoCase);
        return lossOrNoCase;
    };

    onChangeLossOrNoCaseReason = (option) => {
        console.log("Option selected = ", option);
        this.updateSelectedLossOrNoCaseReason(option);
    };

    updateSelectedLossOrNoCaseReason = (value) => {
        this.setState({selectedLossOrNoCaseReason: value});
    };

    getLossOrNoCaseReason = (reason) => {
        const reasonResult = AppUtil.isAvailable(reason) && AppUtil.isAvailable(reason.key) ? reason.key : null;
        return reasonResult;
    };

    getLostOrNoCaseReasonsApiCall = (stage) => {
        this.props.caseAction.getLostOrNoCaseReasons(stage).then(responseData => {
        }).catch(error => {
            console.log("get LostOrNoCase Reasons api Call = ", error);
        });
    };

    render() {
        const {selectedLossOrNoCaseReason} = this.state;
        const {showLossReason, popupCase} = this.props;
        this.countyId = popupCase.countyId || this.countyId;
        const isBtnDisabledOnDeliveryMethod = AppUtil.isAvailable(this.props.ordersData) ? (OrderUtil.hasOrdersWithMandatoryDeliveryMethod(this.props.ordersData) === false) : false;
        // console.log("backuporderData", this.enableConfirmationWithOrders, this.enableConfirmation, this.state.defaultUser, isBtnDisabledOnDeliveryMethod)
        return (
            <Modal
                open={this.props.openModal}
                showCloseIcon={false}
                onClose={this.onCloseModal}
                container={document.getElementById('app')}
                center
                classNames={{
                    overlay: 'overlay',
                    modal: 'modal__wrapper',
                    closeButton: 'button button-close'
                }}
            >
                <div className="card card--modal">
                    <h3 className="card__title">{Utility.getLang().common.changeStagePopup.changeStage}</h3>
                    <p>{this.nonSalesExternalOwnerRequired ? Utility.getLang().common.changeStagePopup.infoSalesExternal : Utility.getLang().common.changeStagePopup.info}
                        <b>{this.destinationName}</b>
                    </p>
                    <div className="form-group">
                        <label htmlFor="selectOwner">
                            {Utility.getLang().common.changeStagePopup.owner}
                        </label>
                        <Select
                            getOptionLabel={({name}) => name}
                            getOptionValue={({id}) => id}
                            placeholder={Utility.getLang().common.changeStagePopup.ownerPlaceholder}
                            className={"multiselect " + this.ownerRequiredCss}
                            classNamePrefix={"multiselect"}
                            options={this.allUsersByRole}
                            onChange={this.handleSelectedUser}
                            defaultValue={this.assignedUser}
                        />
                    </div>
                    {
                        showLossReason === true ?
                            <div className="form-group">
                                <label htmlFor="lossReason">
                                    {this.getLossOrNoCaseText(this.props.destinationId)}
                                </label>
                                <Select
                                    getOptionLabel={({value}) => value}
                                    getOptionValue={({key}) => key}
                                    placeholder={this.getLossOrNoCaseText(this.props.destinationId)}
                                    className={`multiselect ${AppUtil.isAvailable(this.getLossOrNoCaseReason(selectedLossOrNoCaseReason)) ? 'is-success' : 'is-error'}`}
                                    classNamePrefix={"multiselect"}
                                    options={this.getLossOrNoCaseSelectOptions(this.props.destinationId)}
                                    onChange={this.onChangeLossOrNoCaseReason}
                                    defaultValue={selectedLossOrNoCaseReason}
                                />
                                <County options={this.props.counties} primaryContact={this.primaryContact}></County>
                            </div>
                            : null
                    }
                    {/* Resolution popup 1: */}
                    {
                        this.typeOfPopup !== null && this.typeOfPopup === CaseDraggingUtil.popupTypes.orderInvoiceArchived ?

                            this.props.ordersData !== undefined && this.props.ordersData !== null && this.props.ordersData.length > 0 ?
                                <div>
                                    <p>{Utility.getLang().common.changeStagePopup.infoCaseOrder1}</p>
                                    <div className="table__wrapper table__wrapper--change_stage">
                                        <table className="fnxtable fnxtable--flat">
                                            <thead>
                                            <tr className="tr tr--head">
                                                <th
                                                    className="th"
                                                    scope="col"
                                                >
                                                    {Utility.getLang().common.objectType.order}
                                                </th>
                                                <th
                                                    className="th th--action"
                                                    scope="col"
                                                >
                                                    {Utility.getLang().common.changeStagePopup.changeStage}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.props.ordersData.map((order, index) => {
                                                    return <tr className="tr" key={order.id}>
                                                        <td className="td">
                                                            <h5>
                                                                <Icon type="order" small/>
                                                                <a
                                                                    href={order.id !== 0 ? AppUtil.linkPaths.order.basePath + order.id + AppUtil.linkPaths.order.details : null}
                                                                    className="link is-external"
                                                                    target="_blank"
                                                                >
                                                                    {order.id !== 0 ? `${order.id} - ${order.orderTypeText}` : `${order.orderTypeText}`}
                                                                </a>
                                                            </h5>
                                                            <BillingContactForPopup
                                                                order={order}
                                                                orderIndex={index}
                                                                oldOrder={this.getOldOrderAtIndex(index, this.props.backupOrdersData)}
                                                                source={this.props.sourceType}
                                                                caseRelatives={this.allRelativesOfCase}
                                                                changeBillingContact={(value, index) => this.changeBillingDetails(value, index)}
                                                            />

                                                            {
                                                                this.checkEmailWillBeSend(order, this.getOldOrder(order.id))
                                                            }
                                                        </td>
                                                        <td className="td td--actions">
                                                            <select
                                                                name={"stage" + index}
                                                                id={"changeOrderStage" + index}
                                                                placeholder={Utility.getLang().cases.statusTabContent.status}
                                                                // className="select is-error"
                                                                className={`select ${order.stage === OrderUtil.orderStages.order.value || order.stage === OrderUtil.orderStages.invoice.value || order.stage === OrderUtil.orderStages.archived.value || order.disableOrderStage ? 'is-success' : 'is-error'}`}
                                                                value={order.stage}
                                                                onChange={(e) => this.changeOrderStatusOfOrder(e, index, order.stage)}
                                                                disabled={order.disableOrderStage}
                                                            >
                                                                {
                                                                    this.orderStagesToShow[index] !== undefined && this.orderStagesToShow[index] !== null ?
                                                                        this.orderStagesToShow[index].map((stages, i) => {
                                                                            return (
                                                                                <option
                                                                                    key={i}
                                                                                    value={stages.key}
                                                                                    disabled={stages.disabled}
                                                                                >
                                                                                    {stages.value}
                                                                                </option>
                                                                            );
                                                                        }) : null
                                                                }
                                                            </select>
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div> : null : null
                    }

                    {/* Resolution popup 2: */}
                    {
                        this.typeOfPopup !== null && this.typeOfPopup === CaseDraggingUtil.popupTypes.invoiceArchived ?
                            this.props.ordersData !== undefined && this.props.ordersData !== null && this.props.ordersData.length > 0 ?
                                <div>
                                    <p>{Utility.getLang().common.changeStagePopup.infoCaseOrder2}</p>
                                    <div className="table__wrapper table__wrapper--change_stage">
                                        <table className="fnxtable fnxtable--flat">
                                            <thead>
                                            <tr className="tr tr--head">
                                                <th
                                                    className="th"
                                                    scope="col"
                                                >
                                                    {Utility.getLang().common.objectType.order}
                                                </th>
                                                <th
                                                    className="th th--action"
                                                    scope="col"
                                                >
                                                    {Utility.getLang().common.changeStagePopup.changeStage}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.props.ordersData.map((order, index) => {
                                                    return <tr className="tr">
                                                        <td className="td">
                                                            <h5>
                                                                <Icon type="order" small/>
                                                                <a
                                                                    href={order.id !== 0 ? AppUtil.linkPaths.order.basePath + order.id + AppUtil.linkPaths.order.details : null}
                                                                    className="link is-external"
                                                                    target="_blank"
                                                                >
                                                                    {order.id !== 0 ? `${order.id} - ${order.orderTypeText}` : `${order.orderTypeText}`}
                                                                </a>
                                                            </h5>
                                                            <BillingContactForPopup
                                                                order={order}
                                                                orderIndex={index}
                                                                oldOrder={this.getOldOrderAtIndex(index, this.props.backupOrdersData)}
                                                                source={this.props.sourceType}
                                                                caseRelatives={this.allRelativesOfCase}
                                                                changeBillingContact={(value, index) => this.changeBillingDetails(value, index)}
                                                            />
                                                            {
                                                                this.checkEmailWillBeSend(order, this.getOldOrder(order.id))
                                                            }
                                                        </td>
                                                        <td className="td td--actions">
                                                            <select
                                                                name={"stage" + index}
                                                                id={"changeOrderStage" + index}
                                                                placeholder={Utility.getLang().cases.statusTabContent.status}
                                                                className={`select ${order.stage === OrderUtil.orderStages.invoice.value || order.stage === OrderUtil.orderStages.archived.value || order.disableOrderStage ? 'is-success' : 'is-error'}`}
                                                                value={order.stage}
                                                                onChange={(e) => this.changeOrderStatusOfOrder(e, index, order.stage)}
                                                                disabled={order.disableOrderStage}
                                                            >
                                                                {
                                                                    this.orderStagesToShow[index] !== undefined ?
                                                                        this.orderStagesToShow[index].map((stages, i) => {
                                                                            return (
                                                                                <option
                                                                                    key={i}
                                                                                    value={stages.key}
                                                                                    disabled={stages.disabled}
                                                                                >
                                                                                    {stages.value}
                                                                                </option>
                                                                            );
                                                                        }) : null
                                                                }
                                                            </select>
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div> : null : null
                    }

                    {/* Resolution popup 3: */}
                    {
                        this.typeOfPopup !== null && this.typeOfPopup === CaseDraggingUtil.popupTypes.archivedDeleted ?
                            this.props.ordersData !== undefined && this.props.ordersData !== null && this.props.ordersData.length > 0 ?
                                <div>
                                    <p>{Utility.getLang().common.changeStagePopup.infoCaseOrder3}</p>
                                    <div className="table__wrapper table__wrapper--change_stage">
                                        <table className="fnxtable fnxtable--flat">
                                            <thead>
                                            <tr className="tr tr--head">
                                                <th
                                                    className="th"
                                                    scope="col"
                                                >
                                                    {Utility.getLang().common.objectType.order}
                                                </th>
                                                <th
                                                    className="th th--action"
                                                    scope="col"
                                                >
                                                    {Utility.getLang().common.changeStagePopup.changeStage}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.props.ordersData.map((order, index) => {
                                                    return <tr className="tr">
                                                        <td className="td">
                                                            <h5>
                                                                <Icon type="order" small/>
                                                                <a
                                                                    href={order.id !== 0 ? AppUtil.linkPaths.order.basePath + order.id + AppUtil.linkPaths.order.details : null}
                                                                    className="link is-external"
                                                                    target="_blank"
                                                                >
                                                                    {order.id !== 0 ? `${order.id} - ${order.orderTypeText}` : `${order.orderTypeText}`}
                                                                </a>
                                                            </h5>
                                                            <BillingContactForPopup
                                                                order={order}
                                                                orderIndex={index}
                                                                oldOrder={this.getOldOrderAtIndex(index, this.props.backupOrdersData)}
                                                                source={this.props.sourceType}
                                                                caseRelatives={this.allRelativesOfCase}
                                                                changeBillingContact={(value, index) => this.changeBillingDetails(value, index)}
                                                            />
                                                            {
                                                                this.checkEmailWillBeSend(order, this.getOldOrder(order.id))
                                                            }
                                                        </td>
                                                        <td className="td td--actions">
                                                            <select
                                                                name={"stage" + index}
                                                                id={"changeOrderStage" + index}
                                                                placeholder={Utility.getLang().cases.statusTabContent.status}
                                                                // className="select is-error"
                                                                className={`select ${order.stage === OrderUtil.orderStages.archived.value || order.stage === OrderUtil.orderStages.deleted.value || order.disableOrderStage ? 'is-success' : 'is-error'}`}
                                                                value={order.stage}
                                                                onChange={(e) => this.changeOrderStatusOfOrder(e, index, order.stage)}
                                                                disabled={order.disableOrderStage}
                                                            >
                                                                {
                                                                    this.orderStagesToShow[index] !== undefined ?
                                                                        this.orderStagesToShow[index].map((stages, i) => {
                                                                            return (
                                                                                <option
                                                                                    key={i}
                                                                                    value={stages.key}
                                                                                    disabled={stages.disabled}
                                                                                >
                                                                                    {stages.value}
                                                                                </option>
                                                                            );
                                                                        }) : null
                                                                }
                                                            </select>
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div> : null : null
                    }

                    {/* Resolution popup 4: */}
                    {
                        this.typeOfPopup !== null && this.typeOfPopup === CaseDraggingUtil.popupTypes.invoiceArchivedDeleted ?
                            this.props.ordersData !== undefined && this.props.ordersData !== null && this.props.ordersData.length > 0 ?
                                <div>
                                    <p>{Utility.getLang().common.changeStagePopup.infoCaseOrder4}</p>
                                    <div className="table__wrapper table__wrapper--change_stage">
                                        <table className="fnxtable fnxtable--flat">
                                            <thead>
                                            <tr className="tr tr--head">
                                                <th
                                                    className="th"
                                                    scope="col"
                                                >
                                                    {Utility.getLang().common.objectType.order}
                                                </th>
                                                <th
                                                    className="th th--action"
                                                    scope="col"
                                                >
                                                    {Utility.getLang().common.changeStagePopup.changeStage}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.props.ordersData.map((order, index) => {
                                                    return <tr className="tr">
                                                        <td className="td">
                                                            <h5>
                                                                <Icon type="order" small/>
                                                                <a
                                                                    href={order.id !== 0 ? AppUtil.linkPaths.order.basePath + order.id + AppUtil.linkPaths.order.details : null}
                                                                    className="link is-external"
                                                                    target="_blank"
                                                                >
                                                                    {order.id !== 0 ? `${order.id} - ${order.orderTypeText}` : `${order.orderTypeText}`}
                                                                </a>
                                                            </h5>
                                                            <BillingContactForPopup
                                                                order={order}
                                                                orderIndex={index}
                                                                oldOrder={this.getOldOrderAtIndex(index, this.props.backupOrdersData)}
                                                                source={this.props.sourceType}
                                                                caseRelatives={this.allRelativesOfCase}
                                                                changeBillingContact={(value, index) => this.changeBillingDetails(value, index)}
                                                            />
                                                            {
                                                                this.checkEmailWillBeSend(order, this.getOldOrder(order.id))
                                                            }
                                                        </td>
                                                        <td className="td td--actions">
                                                            <select
                                                                name={"stage" + index}
                                                                id={"changeOrderStage" + index}
                                                                placeholder={Utility.getLang().cases.statusTabContent.status}
                                                                // className="select is-error"
                                                                className={`select ${order.stage === OrderUtil.orderStages.invoice.value || order.stage === OrderUtil.orderStages.archived.value || order.stage === OrderUtil.orderStages.deleted.value || order.disableOrderStage ? 'is-success' : 'is-error'}`}
                                                                value={order.stage}
                                                                onChange={(e) => this.changeOrderStatusOfOrder(e, index, order.stage)}
                                                                disabled={order.disableOrderStage}
                                                            >
                                                                {
                                                                    this.orderStagesToShow[index] !== undefined ?
                                                                        this.orderStagesToShow[index].map((stages, i) => {
                                                                            return (
                                                                                <option
                                                                                    key={i}
                                                                                    value={stages.key}
                                                                                    disabled={stages.disabled}
                                                                                >
                                                                                    {stages.value}
                                                                                </option>
                                                                            );
                                                                        }) : null
                                                                }
                                                            </select>
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div> : null : null
                    }

                    {/* Resolution popup 5: */}
                    {
                        this.typeOfPopup !== null && this.typeOfPopup === CaseDraggingUtil.popupTypes.deleted ?
                            this.props.ordersData !== undefined && this.props.ordersData !== null && this.props.ordersData.length > 0 ?
                                <div>
                                    <p>{Utility.getLang().common.changeStagePopup.infoCaseOrder5}</p>
                                    <div className="table__wrapper table__wrapper--change_stage">
                                        <table className="fnxtable fnxtable--flat">
                                            <thead>
                                            <tr className="tr tr--head">
                                                <th
                                                    className="th"
                                                    scope="col"
                                                >
                                                    {Utility.getLang().common.objectType.order}
                                                </th>
                                                <th
                                                    className="th th--action"
                                                    scope="col"
                                                >
                                                    {Utility.getLang().common.changeStagePopup.changeStage}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.props.ordersData.map((order, index) => {
                                                    return <tr className="tr">
                                                        <td className="td">
                                                            <h5>
                                                                <Icon type="order" small/>
                                                                <a
                                                                    href={order.id !== 0 ? AppUtil.linkPaths.order.basePath + order.id + AppUtil.linkPaths.order.details : null}
                                                                    className="link is-external"
                                                                    target="_blank"
                                                                >
                                                                    {order.id !== 0 ? `${order.id} - ${order.orderTypeText}` : `${order.orderTypeText}`}
                                                                </a>
                                                            </h5>
                                                            <BillingContactForPopup
                                                                order={order}
                                                                orderIndex={index}
                                                                oldOrder={this.getOldOrderAtIndex(index, this.props.backupOrdersData)}
                                                                source={this.props.sourceType}
                                                                caseRelatives={this.allRelativesOfCase}
                                                                changeBillingContact={(value, index) => this.changeBillingDetails(value, index)}
                                                            />
                                                            {
                                                                this.checkEmailWillBeSend(order, this.getOldOrder(order.id))
                                                            }
                                                        </td>
                                                        <td className="td td--actions">
                                                            <select
                                                                name={"stage" + index}
                                                                id={"changeOrderStage" + index}
                                                                placeholder={Utility.getLang().cases.statusTabContent.status}
                                                                // className="select is-error"
                                                                className={`select ${order.stage === OrderUtil.orderStages.deleted.value || order.disableOrderStage ? 'is-success' : 'is-error'}`}
                                                                value={order.stage}
                                                                onChange={(e) => this.changeOrderStatusOfOrder(e, index, order.stage)}
                                                                disabled={order.disableOrderStage}
                                                            >
                                                                {
                                                                    this.orderStagesToShow[index] !== undefined ?
                                                                        this.orderStagesToShow[index].map((stages, i) => {
                                                                            return (
                                                                                <option
                                                                                    key={i}
                                                                                    value={stages.key}
                                                                                    disabled={stages.disabled}
                                                                                >
                                                                                    {stages.value}
                                                                                </option>
                                                                            );
                                                                        }) : null
                                                                }
                                                            </select>
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div> : null : null
                    }
                    {/* Resolution popup 6: */}
                    {
                        this.typeOfPopup !== null && this.typeOfPopup === CaseDraggingUtil.popupTypes.unknown ?
                            this.props.ordersData !== undefined && this.props.ordersData !== null && this.props.ordersData.length > 0 ?
                                <div>
                                    {/*<p>{Utility.getLang().common.changeStagePopup.infoCaseOrder5}</p>*/}
                                    <div className="table__wrapper table__wrapper--change_stage">
                                        <table className="fnxtable fnxtable--flat">
                                            <thead>
                                            <tr className="tr tr--head">
                                                <th
                                                    className="th"
                                                    scope="col"
                                                >
                                                    {Utility.getLang().common.objectType.order}
                                                </th>
                                                <th
                                                    className="th th--action"
                                                    scope="col"
                                                >
                                                    {Utility.getLang().common.changeStagePopup.changeStage}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.props.ordersData.map((order, index) => {
                                                    return <tr className="tr" key={index}>
                                                        <td className="td">
                                                            <h5>
                                                                <Icon type="order" small/>
                                                                <a
                                                                    href={order.id !== 0 ? AppUtil.linkPaths.order.basePath + order.id + AppUtil.linkPaths.order.details : null}
                                                                    className="link is-external"
                                                                    target="_blank"
                                                                >
                                                                    {order.id !== 0 ? `${order.id} - ${order.orderTypeText}` : `${order.orderTypeText}`}
                                                                </a>
                                                            </h5>
                                                            <BillingContactForPopup
                                                                order={order}
                                                                orderIndex={index}
                                                                oldOrder={this.getOldOrderAtIndex(index, this.props.backupOrdersData)}
                                                                source={this.props.sourceType}
                                                                caseRelatives={this.allRelativesOfCase}
                                                                changeBillingContact={(value, index) => this.changeBillingDetails(value, index)}
                                                            />
                                                            {
                                                                this.checkEmailWillBeSend(order, this.getOldOrder(order.id))
                                                            }
                                                        </td>
                                                        <td className="td td--actions">
                                                            <select
                                                                name={"stage" + index}
                                                                id={"changeOrderStage" + index}
                                                                placeholder={Utility.getLang().cases.statusTabContent.status}
                                                                // className="select is-error"
                                                                //className={`select ${order.stage === CaseUtil.orderStages.deleted.value ? 'is-success' : 'is-error'}`}
                                                                className={`select is-success`}
                                                                value={order.stage}
                                                                onChange={(e) => this.changeOrderStatusOfOrder(e, index, order.stage)}
                                                                disabled={order.disableOrderStage}
                                                            >
                                                                {
                                                                    this.orderStagesToShow[index] !== undefined ?
                                                                        this.orderStagesToShow[index].map((stages, i) => {
                                                                            return (
                                                                                <option
                                                                                    key={i}
                                                                                    value={stages.key}
                                                                                    disabled={stages.disabled}
                                                                                >
                                                                                    {stages.value}
                                                                                </option>
                                                                            );
                                                                        }) : null
                                                                }
                                                            </select>
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div> : null : null
                    }

                    {/* Save and <delivery method> */}

                    {this.orderEmailWillBeSend ?
                        <div className="btn-toolbar btn-toolbar--modal">
                            <Button
                                onClick={this.onConfirm}
                                isPrimary
                                disabled={(showLossReason && (AppUtil.isEmpty(selectedLossOrNoCaseReason) || this.countyId === null)) || this.state.defaultUser === null || this.state.disablePopUpOnClick || ((this.typeOfPopup !== undefined && this.typeOfPopup !== null) ? (this.enableConfirmation === false) : false) || ((this.typeOfPopup !== undefined && this.typeOfPopup !== null) ? (this.enableConfirmationWithOrders === false) : false) || isBtnDisabledOnDeliveryMethod}
                                icon="email"
                            >
                                {Utility.getLang().common.changeStagePopup.changeStageBtnAndEmail}
                            </Button>
                            <Button onClick={this.onCloseModal}>
                                {Utility.getLang().common.changeStagePopup.cancelBtn}
                            </Button>
                        </div>
                        :
                        <div className="btn-toolbar btn-toolbar--modal">
                            <Button
                                onClick={this.onConfirm}
                                isPrimary
                                disabled={(showLossReason && (AppUtil.isEmpty(selectedLossOrNoCaseReason) || this.countyId === null)) || this.state.defaultUser === null || this.state.disablePopUpOnClick || ((this.typeOfPopup !== undefined && this.typeOfPopup !== null) ? (this.enableConfirmation === false) : false) || ((this.typeOfPopup !== undefined && this.typeOfPopup !== null) ? (this.enableConfirmationWithOrders === false) : false) || isBtnDisabledOnDeliveryMethod || this.nonSalesExternalOwnerRequired}
                            >
                                {Utility.getLang().common.changeStagePopup.changeStageBtn}
                            </Button>
                            <Button onClick={this.onCloseModal}>
                                {Utility.getLang().common.changeStagePopup.cancelBtn}
                            </Button>
                        </div>
                    }
                </div>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return state.application;
}

function mapDispatchToProps(dispatch) {
    return {
        actionMaster: bindActionCreators(actionMaster, dispatch),
        dashboardAction: bindActionCreators(dashboardAction, dispatch),
        caseAction: bindActionCreators(caseAction, dispatch),
        orderAction: bindActionCreators(orderAction, dispatch),
        billViewAction: bindActionCreators(billViewAction, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PopupForStageChange);
