import Permalink from "../../link/permalink";
import React from "react";
import AppUtil from "../../appUtil";

class Clickable extends React.Component {

    render() {
        if (AppUtil.isAvailable(this.props.details)) {
            if (this.props.details.enableLink) {
                return (
                    AppUtil.isAvailable(this.props.details.linkId) ?
                        <Permalink
                            id={this.props.details.linkId}
                            content={this.props.details.content}
                            path1={this.props.details.link.basePath}
                            path2={this.props.details.link.details}
                        />
                    :
                        <Permalink
                            id={this.props.details.content}
                            path1={this.props.details.link.basePath}
                            path2={this.props.details.link.details}
                        />
                );
            } else {
                return (
                    <a className={this.props.details.className}>
                        {this.props.details.content}
                    </a>
                )
            }

        } else {
            return null;
        }
    }
}

export default Clickable;
